var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var _a;
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { PaymentStatus } from 'types/codegen/hooks';
import { Typography } from 'designSystem';
import { pluralize } from '../../../utils/pluralize';
import { SingleInvoiceItem } from './SingleInvoiceItem';
import { getInvoiceStatus } from '../constants';
import { addMobileStyles } from '../../../utils/addMobileStyles';
import { useScreenSizing } from '../../../hooks';
var LabelByStatus = (_a = {},
    _a[PaymentStatus.Pending] = 'Pending',
    _a[PaymentStatus.Accepted] = 'Settled',
    _a[PaymentStatus.Declined] = 'Open',
    _a);
export var InvoicesSettledTable = function (_a) {
    var invoices = _a.invoices, invoicesApplied = _a.invoicesApplied, status = _a.status;
    var isMobile = useScreenSizing().isMobile;
    var renderHeader = function () {
        if (status) {
            return "".concat(invoices.length, " Invoice").concat(pluralize(invoices.length), " ").concat(LabelByStatus[status]);
        }
        return 'Invoices Due';
    };
    var appliedByInvoiceId = useMemo(function () {
        var byId = {};
        invoicesApplied.forEach(function (ia) {
            byId[ia.invoiceId] = ia.applied;
        });
        return byId;
    }, [invoicesApplied]);
    return (React.createElement(Container, null,
        React.createElement("div", null,
            React.createElement(Typography, { variant: "title", color: isMobile ? 'alt5' : 'alt' }, renderHeader())),
        React.createElement("div", null, invoices.map(function (i, index) {
            var _a, _b, _c;
            var invoiceStatus = getInvoiceStatus(i);
            var amount = (_a = appliedByInvoiceId[i.invoiceId]) !== null && _a !== void 0 ? _a : i.amountDue;
            return (React.createElement(SingleInvoiceItem, { key: index, invoiceId: i.invoiceId, status: invoiceStatus, amount: amount !== null && amount !== void 0 ? amount : 0, date: i.date, dueDate: i.dueDate, facilityName: (_b = i.facility) === null || _b === void 0 ? void 0 : _b.name, quantity: (_c = i.jeuveauQuantity) !== null && _c !== void 0 ? _c : 0 }));
        }))));
};
var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  & > div:first-of-type {\n    padding: 20px 24px;\n    ", ";\n  }\n  & > div:last-of-type {\n    display: flex;\n    flex-direction: column;\n    padding-top: 16px;\n  }\n  ", "\n"], ["\n  ", ";\n  & > div:first-of-type {\n    padding: 20px 24px;\n    ", ";\n  }\n  & > div:last-of-type {\n    display: flex;\n    flex-direction: column;\n    padding-top: 16px;\n  }\n  ", "\n"])), function (props) { return props.theme.borders.base; }, function (props) { return props.theme.borders.base; }, addMobileStyles(css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    border: none;\n    & > div:first-of-type {\n      padding: 0;\n      border: none;\n    }\n  "], ["\n    border: none;\n    & > div:first-of-type {\n      padding: 0;\n      border: none;\n    }\n  "])))));
var templateObject_1, templateObject_2;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import MaskInput from 'react-maskinput';
import './input.css';
import ErrorIcon from './input-error.png';
import { Icon } from '../Icon';
import styled, { useTheme } from 'styled-components';
import { Typography } from '../Typography';
export var Input = function (_a) {
    var _b = _a.alwaysShowMask, alwaysShowMask = _b === void 0 ? false : _b, _c = _a.disabled, disabled = _c === void 0 ? false : _c, _d = _a.footerLabel, footerLabel = _d === void 0 ? undefined : _d, _e = _a.fullWidth, fullWidth = _e === void 0 ? false : _e, _f = _a.hasError, hasError = _f === void 0 ? false : _f, label = _a.label, mask = _a.mask, maskChar = _a.maskChar, _g = _a.masked, masked = _g === void 0 ? false : _g, maskString = _a.maskString, name = _a.name, onBlur = _a.onBlur, _h = _a.onChange, onChange = _h === void 0 ? function () { } : _h, onFocus = _a.onFocus, _j = _a.onKeyPress, onKeyPress = _j === void 0 ? function () { } : _j, _k = _a.onRightIconClicked, onRightIconClicked = _k === void 0 ? function () { } : _k, placeholder = _a.placeholder, rightIcon = _a.rightIcon, _l = _a.showMask, showMask = _l === void 0 ? false : _l, _m = _a.size, size = _m === void 0 ? 'medium' : _m, _o = _a.type, type = _o === void 0 ? 'text' : _o, value = _a.value, _p = _a.variant, variant = _p === void 0 ? 'default' : _p, _q = _a.endAdornment, endAdornment = _q === void 0 ? null : _q, automationId = _a["data-automation-id"], errorAutomationId = _a.errorAutomationId, inputMode = _a.inputMode, maxLength = _a.maxLength, onSelect = _a.onSelect;
    var variantStyling = {
        default: '',
        outline: 'storybook-input-base-outline',
    };
    var theme = useTheme();
    return (React.createElement(OuterContainer, null,
        label && (React.createElement(Typography, { variant: "headline", color: "alt", "data-automation-id": automationId }, label)),
        React.createElement("div", { className: "storybook-input--input-container ".concat(fullWidth && 'storybook-input--full-width') },
            masked ? (React.createElement(MaskInput, { className: "storybook-input-base " +
                    "".concat(!disabled && variantStyling[variant], " ") +
                    "".concat(hasError && 'storybook-input--error', " ") +
                    "".concat(fullWidth && 'storybook-input--full-width', " ") +
                    "".concat(disabled && 'storybook-input--disabled', " ") +
                    "storybook-input-base--".concat(size), placeholder: placeholder, disabled: disabled, onChange: onChange, onBlur: onBlur, onFocus: onFocus, onSelect: onSelect, value: value, name: name, type: type, alwaysShowMask: alwaysShowMask, maskChar: maskChar && maskChar, mask: mask && mask, maskString: maskString && maskString, showMask: showMask, "data-automation-id": automationId, inputMode: inputMode })) : (React.createElement("input", { className: "storybook-input-base " +
                    "".concat(!disabled && variantStyling[variant], " ") +
                    "".concat(hasError && 'storybook-input--error', " ") +
                    "".concat(fullWidth && 'storybook-input--full-width', " ") +
                    "".concat(disabled && 'storybook-input--disabled', " ") +
                    "storybook-input-base--".concat(size), placeholder: placeholder, disabled: disabled, onChange: onChange, onBlur: onBlur, onSelect: onSelect, onFocus: onFocus, onKeyPress: onKeyPress, value: value, name: name, type: type, "data-automation-id": automationId, inputMode: inputMode, maxLength: maxLength })),
            endAdornment,
            hasError && (React.createElement(RightIconContainer, null,
                React.createElement(ErrorIconImage, { src: ErrorIcon, alt: "input-error-image", className: "storybook-input--error-icon" }))),
            rightIcon && (React.createElement(RightIconButton, { onClick: onRightIconClicked },
                React.createElement(Icon, { name: rightIcon, size: 24, color: theme.colors.text.alt4 })))),
        footerLabel && (React.createElement("span", { className: 'storybook-input--footer-label-text ' +
                "".concat(hasError && 'footer-label--error'), "data-automation-id": errorAutomationId }, footerLabel))));
};
var RightIconButton = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  all: unset;\n  cursor: pointer;\n  position: absolute;\n  right: 1rem;\n"], ["\n  all: unset;\n  cursor: pointer;\n  position: absolute;\n  right: 1rem;\n"])));
var RightIconContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  right: 1rem;\n"], ["\n  position: absolute;\n  right: 1rem;\n"])));
var ErrorIconImage = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 1.5rem;\n  height: 1.5rem;\n"], ["\n  width: 1.5rem;\n  height: 1.5rem;\n"])));
var OuterContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n"], ["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;

export var PaymentSteps;
(function (PaymentSteps) {
    PaymentSteps["PaymentOptions"] = "?step=paymentOptions";
    PaymentSteps["PayEntireBalance"] = "?step=payEntireBalance";
    PaymentSteps["PayDueThisWeek"] = "?step=payDueThisWeek";
    PaymentSteps["SelectInvoices"] = "?step=selectInvoices";
    PaymentSteps["CustomPayment"] = "?step=customPayment";
    PaymentSteps["SelectPayment"] = "?step=selectPayment";
    PaymentSteps["ReviewPayment"] = "?step=reviewPayment";
    PaymentSteps["PaymentOverview"] = "?step=paymentOverview";
})(PaymentSteps || (PaymentSteps = {}));

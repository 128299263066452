var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from 'styled-components';
export var card = {
    default: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex: 1;\n    background-color: white;\n    position: relative;\n\n    margin: 16px;\n    ", ";\n    padding: 32px;\n  "], ["\n    display: flex;\n    flex: 1;\n    background-color: white;\n    position: relative;\n\n    margin: 16px;\n    ", ";\n    padding: 32px;\n  "])), function (props) { return props.theme.elevation.base.elevation1; }),
    alt: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;\n    flex: 1;\n    background-color: ", ";\n    border: 1px solid ", ";\n  "], ["\n    display: flex;\n    flex: 1;\n    background-color: ", ";\n    border: 1px solid ", ";\n  "])), function (props) { return props.theme.colors.bg.list; }, function (props) { return props.theme.colors.border.base; }),
};
var templateObject_1, templateObject_2;

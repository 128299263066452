import React from 'react';
import './avatar.css';
import placeholder from './placeholder-profile-picture.png';
export var AvatarGroup = function (_a) {
    var _b = _a.users, users = _b === void 0 ? [] : _b, _c = _a.size, size = _c === void 0 ? 'medium' : _c;
    return (React.createElement("div", { className: "storybook-avatar-group--container" }, users === null || users === void 0 ? void 0 : users.map(function (user, i) {
        if (i < 4) {
            return (React.createElement(Avatar, { showOnlineBadge: false, size: size, user: user, hasGroupSpacing: i > 0, style: { zIndex: -i }, hasDropdown: false }));
        }
        else {
            return (React.createElement("div", { className: "storybook-avatar--group-overflow--container " +
                    "storybook-avatar--".concat(size, " ") +
                    "storybook-avatar--container-group--".concat(size), style: { zIndex: -i } },
                React.createElement("span", { className: 'storybook-avatar--group-overflow--text ' +
                        "storybook-avatar--group-overflow--text--".concat(size) }, "+".concat(users.length - i))));
        }
    })));
};
export var Avatar = function (_a) {
    var _b = _a.size, size = _b === void 0 ? 'medium' : _b, _c = _a.user, user = _c === void 0 ? {} : _c, _d = _a.showOnlineBadge, showOnlineBadge = _d === void 0 ? false : _d, _e = _a.hasGroupSpacing, hasGroupSpacing = _e === void 0 ? false : _e, _f = _a.style, style = _f === void 0 ? {} : _f;
    var AvatarComponent = function () {
        return (React.createElement("div", { className: 'storybook-avatar--container ' +
                "".concat(hasGroupSpacing ? "storybook-avatar--container-group--".concat(size) : '', " "), style: style },
            React.createElement("div", { className: "storybook-avatar--container" },
                (user === null || user === void 0 ? void 0 : user.hasImage) ? (React.createElement("img", { alt: "user", className: "storybook-avatar storybook-avatar--".concat(size), src: user === null || user === void 0 ? void 0 : user.url })) : (React.createElement("div", { className: "storybook-avatar--placeholder--container storybook-avatar--".concat(size) },
                    React.createElement("img", { alt: "user-placeholder", className: "storybook-avatar--placeholder--image storybook-avatar--placeholder--image--".concat(size), src: placeholder }))),
                showOnlineBadge && (user === null || user === void 0 ? void 0 : user.isOnline) ? (React.createElement("div", { className: "storybook-avatar--online-badge storybook-avatar--online-badge--".concat(size) })) : null)));
    };
    return React.createElement(AvatarComponent, null);
};

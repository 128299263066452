var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import AlertIcon from 'assets/alert-circle.png';
import { useNavigate } from 'react-router';
export var PaymentBannerComponent = function () {
    var navigate = useNavigate();
    return (React.createElement(PaymentBanner, null,
        React.createElement(PaymentBannerContent, null,
            React.createElement(PaymentBannerTextContainer, null,
                React.createElement(AlertIconImage, { src: AlertIcon, alt: "past-due-warning" }),
                React.createElement("b", null, "Please note: To ship this order, please settle outstanding payments.")),
            React.createElement(PaymentBannerCTA, { onClick: function () { return navigate('/invoices/make-payment'); } }, "Pay Invoice"))));
};
var PaymentBanner = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  flex-direction: column;\n  align-items: center;\n  padding: 16px 48px;\n  align-items: center;\n  gap: 16px;\n  flex-shrink: 0;\n  background-color: ", ";\n  @media only screen and (max-width: ", "px) {\n    padding: 16px 32px;\n  }\n"], ["\n  display: flex;\n  width: 100%;\n  flex-direction: column;\n  align-items: center;\n  padding: 16px 48px;\n  align-items: center;\n  gap: 16px;\n  flex-shrink: 0;\n  background-color: ", ";\n  @media only screen and (max-width: ", "px) {\n    padding: 16px 32px;\n  }\n"])), function (props) { return props.theme.colors.bg.alert; }, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var PaymentBannerContent = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 16px;\n  flex: 1 0 0;\n  justify-content: space-between;\n  width: 100%;\n  color: ", ";\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 16px;\n  flex: 1 0 0;\n  justify-content: space-between;\n  width: 100%;\n  color: ", ";\n"])), function (props) { return props.theme.colors.text.alert; });
var PaymentBannerTextContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  @media only screen and (max-width: ", "px) {\n    font-size: 12px;\n  }\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  @media only screen and (max-width: ", "px) {\n    font-size: 12px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var PaymentBannerCTA = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  padding: 6px 8px;\n  flex-direction: column;\n  align-items: center;\n  gap: 10px;\n  align-self: stretch;\n  color: white;\n  background-color: ", ";\n  border: none;\n  box-shadow: 0px 6px 14px 0px rgba(242, 76, 70, 0.5);\n  text-align: center;\n  font-weight: 600;\n  line-height: 12px;\n  font-family: 'Euclid Circular B';\n  cursor: pointer;\n  @media only screen and (max-width: ", "px) {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    min-width: 70px;\n    max-height: 24px;\n    font-size: 10px;\n    align-self: center;\n  }\n"], ["\n  display: flex;\n  padding: 6px 8px;\n  flex-direction: column;\n  align-items: center;\n  gap: 10px;\n  align-self: stretch;\n  color: white;\n  background-color: ", ";\n  border: none;\n  box-shadow: 0px 6px 14px 0px rgba(242, 76, 70, 0.5);\n  text-align: center;\n  font-weight: 600;\n  line-height: 12px;\n  font-family: 'Euclid Circular B';\n  cursor: pointer;\n  @media only screen and (max-width: ", "px) {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    min-width: 70px;\n    max-height: 24px;\n    font-size: 10px;\n    align-self: center;\n  }\n"])), function (props) { return props.theme.colors.coral.coral500; }, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var AlertIconImage = styled.img(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-right: 16px;\n"], ["\n  margin-right: 16px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;

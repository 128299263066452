var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Button, PlaceholderLoading, Typography } from 'designSystem';
import React, { useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { UsersTable } from './components/UsersTable';
import { useNavigate } from 'react-router';
import { useScreenSizing } from '../../../../hooks';
import { UserMobileCard } from './components/UserMobileCard';
var UserTableType;
(function (UserTableType) {
    UserTableType["ACTIVE"] = "ACTIVE";
    UserTableType["INVITED"] = "INVITED";
})(UserTableType || (UserTableType = {}));
export var UserPermissionsView = function (_a) {
    var loading = _a.loading, users = _a.users, mainContactId = _a.mainContactId, selectedUserId = _a.selectedUserId, isUserInvite = _a.isUserInvite, onUpdate = _a.onUpdate, facilities = _a.facilities;
    var navigate = useNavigate();
    var isMobile = useScreenSizing().isMobile;
    var _b = __read(useState(UserTableType.ACTIVE), 2), selectedTab = _b[0], setSelectedTab = _b[1];
    var selectedUser = useMemo(function () {
        if (!(users === null || users === void 0 ? void 0 : users.length)) {
            return null;
        }
        return users.find(function (u) { return u.userId === selectedUserId; });
    }, [users, selectedUserId]);
    console.log(selectedUser);
    var _c = useMemo(function () {
        var activeUsers = [];
        var pendingUsers = [];
        users === null || users === void 0 ? void 0 : users.forEach(function (user) {
            if (user.user.hasAcceptedTerms) {
                activeUsers.push(user);
            }
            else {
                pendingUsers.push(user);
            }
        });
        return {
            activeUsers: activeUsers,
            pendingUsers: pendingUsers,
        };
    }, [users]), activeUsers = _c.activeUsers, pendingUsers = _c.pendingUsers;
    var renderContent = function () {
        if (loading) {
            return (React.createElement(LoadingContainer, null,
                React.createElement(PlaceholderLoading, { size: "small" })));
        }
        if (isMobile) {
            var showingUsers = selectedTab === UserTableType.ACTIVE ? activeUsers : pendingUsers;
            var sortedUsers = showingUsers.reduce(function (acc, cv) {
                if (cv.userId === mainContactId) {
                    return __spreadArray([cv], __read(acc), false);
                }
                return __spreadArray(__spreadArray([], __read(acc), false), [cv], false);
            }, []);
            return (React.createElement(React.Fragment, null,
                React.createElement(TabsContainer, null,
                    React.createElement(Tab, { onClick: function () { return setSelectedTab(UserTableType.ACTIVE); }, active: selectedTab === UserTableType.ACTIVE }, "Users"),
                    React.createElement(Tab, { onClick: function () { return setSelectedTab(UserTableType.INVITED); }, active: selectedTab === UserTableType.INVITED }, "Invited Users")),
                React.createElement(TablesContainer, null, sortedUsers.map(function (u) { return (React.createElement(UserMobileCard, { firstName: u.user.firstName, lastName: u.user.lastName, email: u.user.email, id: u.userId, isMainContact: u.userId === mainContactId, role: u.role })); }))));
        }
        return (React.createElement(TablesContainer, null,
            React.createElement(UsersTable, { mainContactId: mainContactId, users: activeUsers }),
            React.createElement(UsersTable, { isPendingUsers: true, users: pendingUsers })));
    };
    var renderTables = !selectedUser && !isUserInvite;
    return (React.createElement(React.Fragment, null,
        renderTables && (React.createElement(OuterContainer, null,
            React.createElement(InnerContainer, null,
                React.createElement(HeaderContainer, null,
                    React.createElement("div", null,
                        React.createElement(Typography, { mb: 16, variant: "titleLarge", display: "block" }, "User Permissions"),
                        React.createElement(Typography, { variant: "body", color: "alt" }, "Manage user permissions")),
                    React.createElement(Button, { size: isMobile ? 'large' : 'medium', disabled: loading, variant: "outlined", onClick: function () {
                            navigate('/settings/user-permissions/new');
                        } }, "+ Invite New User")),
                renderContent()))),
        React.createElement(Outlet, { context: { selectedUser: selectedUser, onUpdate: onUpdate, facilities: facilities } })));
};
var OuterContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  box-sizing: border-box;\n  position: relative;\n  padding: 48px 48px 60px;\n  margin-top: 20px;\n  flex: 1;\n  width: 100%;\n  @media only screen and (max-width: ", "px) {\n    padding: 32px;\n    margin: 16px 0 0 0;\n  }\n"], ["\n  ", ";\n  box-sizing: border-box;\n  position: relative;\n  padding: 48px 48px 60px;\n  margin-top: 20px;\n  flex: 1;\n  width: 100%;\n  @media only screen and (max-width: ", "px) {\n    padding: 32px;\n    margin: 16px 0 0 0;\n  }\n"])), function (props) { return props.theme.card.default; }, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var HeaderContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  flex: 1;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n    align-items: flex-start;\n    gap: 16px;\n    & > button {\n      width: 100%;\n    }\n  }\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  flex: 1;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n    align-items: flex-start;\n    gap: 16px;\n    & > button {\n      width: 100%;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var InnerContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 48px;\n  ", ";\n\n  width: 100%;\n  @media only screen and (max-width: ", "px) {\n    border: none;\n    padding: 0;\n  }\n"], ["\n  padding: 48px;\n  ", ";\n\n  width: 100%;\n  @media only screen and (max-width: ", "px) {\n    border: none;\n    padding: 0;\n  }\n"])), function (props) { return props.theme.borders.base; }, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var LoadingContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex: 1;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex: 1;\n"])));
var TablesContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 32px;\n  margin-top: 32px;\n  @media only screen and (max-width: ", "px) {\n    gap: 8px;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 32px;\n  margin-top: 32px;\n  @media only screen and (max-width: ", "px) {\n    gap: 8px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var TabsContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  margin-top: 32px;\n  gap: 24px;\n"], ["\n  display: flex;\n  align-items: center;\n  margin-top: 32px;\n  gap: 24px;\n"])));
var Tab = styled.button(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  all: unset;\n  cursor: ", ";\n  color: ", ";\n  padding-bottom: 5px;\n  border-bottom: ", ";\n  white-space: nowrap;\n"], ["\n  all: unset;\n  cursor: ", ";\n  color: ", ";\n  padding-bottom: 5px;\n  border-bottom: ", ";\n  white-space: nowrap;\n"])), function (props) { return (props.disabled ? 'inherit' : 'pointer'); }, function (props) {
    return (props === null || props === void 0 ? void 0 : props.active)
        ? props === null || props === void 0 ? void 0 : props.theme.colors.text.link
        : props === null || props === void 0 ? void 0 : props.theme.colors.text.alt;
}, function (props) { return ((props === null || props === void 0 ? void 0 : props.active) ? '2.1px solid red' : 'none'); });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var _a;
import { PaymentStatus } from '../../../types/codegen/hooks';
import { Typography } from '../../../designSystem';
import styled, { css } from 'styled-components';
import React from 'react';
import { addMobileStyles } from '../../../utils/addMobileStyles';
var StatusConfig = (_a = {},
    _a[PaymentStatus.Accepted] = {
        label: 'Accepted',
        typoColor: 'success',
        bgColor: 'success2',
        borderColor: 'success2',
    },
    _a[PaymentStatus.Pending] = {
        label: 'Pending',
        typoColor: 'alert',
        bgColor: 'alert`',
        borderColor: 'alert2',
    },
    _a[PaymentStatus.Declined] = {
        label: 'Declined',
        typoColor: 'link',
        bgColor: 'error',
        borderColor: 'error2',
    },
    _a);
export var StatusChip = function (_a) {
    var status = _a.status;
    if (!status) {
        return null;
    }
    var _b = StatusConfig[status], label = _b.label, borderColor = _b.borderColor, bgColor = _b.bgColor, typoColor = _b.typoColor;
    return (React.createElement(ChipContainer, { borderColor: borderColor, bgColor: bgColor },
        React.createElement(Typography, { variant: "caption", color: typoColor }, label)));
};
var ChipContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border: 1px solid ", ";\n  background: ", ";\n  padding: 8px 12px;\n  ", "\n"], ["\n  border: 1px solid ", ";\n  background: ", ";\n  padding: 8px 12px;\n  ", "\n"])), function (props) { return props.theme.colors.border[props.borderColor]; }, function (props) { return props.theme.colors.bg[props.bgColor]; }, addMobileStyles(css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    padding: 4px 8px;\n  "], ["\n    padding: 4px 8px;\n  "])))));
var templateObject_1, templateObject_2;

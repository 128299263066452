import { Button, Icon } from 'designSystem';
import React from 'react';
import { useTheme } from 'styled-components';
import { useScreenSizing } from '../../../../../../hooks';
export var CSVDownloadButtonView = function (_a) {
    var isFiltered = _a.isFiltered, onDownload = _a.onDownload, loading = _a.loading;
    var theme = useTheme();
    var isMobile = useScreenSizing().isMobile;
    var shiftLeft = !isMobile && !isFiltered;
    return (React.createElement(Button, { variant: "outlinedSecondary", size: "small", startIcon: React.createElement(Icon, { name: loading ? 'loading' : 'download', size: 12, color: theme.colors.text.base }), onClick: onDownload, style: { marginRight: shiftLeft ? 16 : 0 } }, "CSV"));
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useCallback, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import styled, { useTheme } from 'styled-components';
import { OrderStatus } from 'types/codegen/hooks';
import { Card, Menu, MenuItem, MenuItemText, Typography, Icon, RadioButton, } from 'designSystem';
import { useScreenSizing } from 'hooks';
export var View = function (_a) {
    var selected = _a.selected, closeOnSelect = _a.closeOnSelect, onChange = _a.onChange;
    var _b = __read(useState(false), 2), showMenu = _b[0], setShowMenu = _b[1];
    var theme = useTheme();
    var isMobile = useScreenSizing().isMobile;
    var options = [
        {
            label: 'Placed',
            value: OrderStatus.Submitted,
        },
        {
            label: 'Received',
            value: OrderStatus.Accepted,
        },
        {
            label: 'Shipped',
            value: OrderStatus.Shipped,
        },
        {
            label: 'On Hold',
            value: OrderStatus.OnHold,
        },
        {
            label: 'Rejected',
            value: OrderStatus.Rejected,
        },
        {
            label: 'Delivered',
            value: OrderStatus.Completed,
        },
    ];
    var handleChange = useCallback(function (option) {
        onChange([option]);
        if (closeOnSelect) {
            setShowMenu(false);
        }
    }, [selected]);
    var onClear = useCallback(function () {
        onChange([]);
        if (closeOnSelect) {
            setShowMenu(false);
        }
    }, []);
    return (React.createElement(OutsideClickHandler, { onOutsideClick: function () { return setShowMenu(false); } },
        React.createElement(DropdownWrapper, null,
            React.createElement(Dropdown, { onClick: function () { return setShowMenu(function (show) { return !show; }); } },
                React.createElement(Typography, null, isMobile ? 'Status' : 'Order Status'),
                React.createElement(Icon, { name: showMenu ? 'chevron-up' : 'chevron-down', size: 16 })),
            showMenu && (React.createElement(Root, null,
                React.createElement(Card, { direction: "horizontal", fullWidth: true },
                    React.createElement(Menu, null,
                        options.map(function (option) { return (React.createElement(MenuItem, { key: option.value, onClick: function () { return handleChange(option); } },
                            React.createElement(RadioButton, { checked: !!selected.find(function (entry) { return entry.value === option.value; }), onChange: function () { return null; }, size: 16 }),
                            React.createElement(MenuItemText, { style: { marginLeft: -8, color: theme.colors.text.base } }, option.label))); }),
                        React.createElement(MenuItem, { onClick: function () { return onClear(); } },
                            React.createElement(MenuItemText, { style: { color: theme.colors.coral.coral500 } }, "Clear All")))))))));
};
export default View;
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  top: 60px;\n  right: 0;\n  width: 330px;\n  z-index: 1;\n\n  @media only screen and (max-width: ", "px) {\n    right: auto;\n  }\n"], ["\n  position: absolute;\n  top: 60px;\n  right: 0;\n  width: 330px;\n  z-index: 1;\n\n  @media only screen and (max-width: ", "px) {\n    right: auto;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var Dropdown = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 330px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 12px 16px;\n\n  border: 1px solid ", ";\n  cursor: pointer;\n\n  @media only screen and (max-width: ", "px) {\n    width: 100%;\n  }\n"], ["\n  width: 330px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 12px 16px;\n\n  border: 1px solid ", ";\n  cursor: pointer;\n\n  @media only screen and (max-width: ", "px) {\n    width: 100%;\n  }\n"])), function (props) { return props.theme.colors.white.white600; }, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var DropdownWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: relative;\n"], ["\n  position: relative;\n"])));
var templateObject_1, templateObject_2, templateObject_3;

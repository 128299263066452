var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { Button, Typography } from 'designSystem';
import ProspectQ1 from 'assets/prospect-q1.svg';
import ProspectQ2 from 'assets/prospect-q2.svg';
import ProspectQ3 from 'assets/prospect-q3.svg';
import ProspectQ4 from 'assets/prospect-q4.svg';
import ProspectQ1Mobile from 'assets/prospect-q1-m.svg';
import ProspectQ2Mobile from 'assets/prospect-q2-m.svg';
import ProspectQ3Mobile from 'assets/prospect-q3-m.svg';
import ProspectQ4Mobile from 'assets/prospect-q4-m.svg';
import { useNavigate } from 'react-router';
import { useScreenSizing } from 'hooks';
import { stackedCardStyles } from 'components/StackedMobileCard';
import { addMobileStyles } from 'utils/addMobileStyles';
var prospectOfferImagesByPromoId = {
    desktop: {
        JAN_2023_PROSPECT_Q1: ProspectQ1,
        JAN_2023_PROSPECT_Q2: ProspectQ2,
        JAN_2023_PROSPECT_Q3: ProspectQ3,
        JAN_2023_PROSPECT_Q4: ProspectQ4,
    },
    mobile: {
        JAN_2023_PROSPECT_Q1: ProspectQ1Mobile,
        JAN_2023_PROSPECT_Q2: ProspectQ2Mobile,
        JAN_2023_PROSPECT_Q3: ProspectQ3Mobile,
        JAN_2023_PROSPECT_Q4: ProspectQ4Mobile,
    },
};
export var ProspectCard = function (_a) {
    var index = _a.index, meta = _a.meta, promoId = _a.promoId, standardDiscount = _a.standardDiscount;
    var body = meta.body, cta = meta.cta, subTitle = meta.subTitle, terms = meta.terms, title = meta.title, promoCode = meta.promoCode;
    var navigate = useNavigate();
    var isMobile = useScreenSizing().isMobile;
    var imageSrc = prospectOfferImagesByPromoId[isMobile ? 'mobile' : 'desktop'][promoId];
    var onApply = function () {
        navigate('/orders/buy?step=quantitySelection', {
            state: {
                autoContinue: true,
                preFilledQuantity: standardDiscount.maxDiscountedQuantity,
                preapplyCode: promoCode,
            },
        });
    };
    var renderContent = function () {
        if (isMobile) {
            return (React.createElement(React.Fragment, null,
                React.createElement("div", null,
                    React.createElement("div", null,
                        React.createElement(Typography, { display: "block", variant: "sectionHeader", color: "love" }, subTitle),
                        React.createElement(Typography, { display: "block", mt: 2, variant: "headline" }, title)),
                    React.createElement("img", { alt: "prospect-offer", src: imageSrc })),
                React.createElement(BodyText, { display: "block", mt: 8, mb: 16, color: "alt", variant: "bodySmall", dangerouslySetInnerHTML: { __html: body } }),
                React.createElement(Typography, { mb: 32, display: "block", variant: "footnote", color: "alt5" }, terms),
                React.createElement(Button, { fullWidth: true, size: "xlarge", onClick: onApply }, cta)));
        }
        return (React.createElement(React.Fragment, null,
            React.createElement("div", null,
                React.createElement(Typography, { display: "block", variant: "caption", color: "love" }, subTitle),
                React.createElement(Typography, { display: "block", mt: 2, variant: "titleLarge" }, title),
                React.createElement(BodyText, { display: "block", mt: 8, mb: 8, color: "alt", variant: "bodySmall", dangerouslySetInnerHTML: { __html: body } }),
                React.createElement(Typography, { display: "block", variant: "footnote", color: "alt5" }, terms),
                React.createElement(Button, { size: "xlarge", onClick: onApply }, cta)),
            React.createElement("div", null,
                React.createElement("img", { alt: "prospect-offer", src: imageSrc }))));
    };
    return React.createElement(CardContainer, { zIndex: index }, renderContent());
};
var CardContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  display: flex;\n  align-items: center;\n  gap: 32px;\n  & > div > img {\n    width: 100%;\n    max-width: 180px;\n  }\n  & > div:first-of-type {\n    display: flex;\n    flex-direction: column;\n    flex: 1;\n    height: 100%;\n    & > button {\n      margin-top: auto;\n      max-width: 244px;\n    }\n  }\n  ", ";\n"], ["\n  ", ";\n  display: flex;\n  align-items: center;\n  gap: 32px;\n  & > div > img {\n    width: 100%;\n    max-width: 180px;\n  }\n  & > div:first-of-type {\n    display: flex;\n    flex-direction: column;\n    flex: 1;\n    height: 100%;\n    & > button {\n      margin-top: auto;\n      max-width: 244px;\n    }\n  }\n  ", ";\n"])), function (props) { return props.theme.card.default; }, addMobileStyles("\n    margin: unset;\n    gap: unset;\n    z-index: ".concat(function (props) { return props.zIndex; }, ";\n    ").concat(stackedCardStyles, "\n    & > div:first-of-type {\n      display: flex;\n      align-items: center;\n      flex-direction: row;\n      flex: 1;\n      width: 100%;\n      & > div {\n        flex: 1;\n      }\n    }\n    & > div > img {\n      width: unset;\n      max-width: unset;\n      height: 100%;\n      max-height: 80px;\n    }\n    & > span {\n      width: 100%;  \n    }\n  ")));
var BodyText = styled(Typography)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  & > b {\n    color: ", ";\n  }\n"], ["\n  & > b {\n    color: ", ";\n  }\n"])), function (props) { return props.theme.colors.text.base; });
var templateObject_1, templateObject_2;

import React from 'react';
export var GummyBear = function (_a) {
    var color = _a.color;
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 48 48", width: "48", height: "48", fill: "none" },
        React.createElement("g", { stroke: color, strokeWidth: 3, clipPath: "url(#gummy-svg-clip)" },
            React.createElement("path", { strokeLinecap: "round", strokeLinejoin: "round", d: "M39 6a4.496 4.496 0 0 0-8.88-1 10.364 10.364 0 0 0-12.24 0 4.496 4.496 0 1 0-4.38 5.5c.168-.008.334-.024.5-.05a10.334 10.334 0 0 0-.5 3.05 10.5 10.5 0 1 0 21 0 10.327 10.327 0 0 0-.5-3.05c.168.026.336.042.504.05A4.5 4.5 0 0 0 39 6ZM14.554 34.6A10.478 10.478 0 0 1 13.5 30c.002-.566.04-1.13.118-1.69M28.6 39.446a10.53 10.53 0 0 1-9.184 0M34.38 28.294c.076.566.118 1.136.12 1.706a10.48 10.48 0 0 1-1.054 4.6" }),
            React.createElement("path", { strokeLinecap: "round", strokeLinejoin: "round", d: "M13.5 46.5a6 6 0 1 0 0-12 6 6 0 0 0 0 12ZM34.5 46.5a6 6 0 1 0 0-12 6 6 0 0 0 0 12ZM13.618 28.31l-8.67-3.862a4.5 4.5 0 1 1 3.662-8.222M34.382 28.31l8.67-3.862a4.5 4.5 0 1 0-3.652-8.222" }),
            React.createElement("path", { d: "M19.5 12.75a.75.75 0 1 1 0-1.5M19.5 12.75a.75.75 0 1 0 0-1.5M28.5 12.75a.75.75 0 1 1 0-1.5M28.5 12.75a.75.75 0 1 0 0-1.5" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "gummy-svg-clip" },
                React.createElement("path", { fill: "#fff", d: "M0 0h48v48H0z" })))));
};

import { Icon, Typography } from 'designSystem';
import React from 'react';
import { ListItemCard } from '../components/ListItemCard';
import { pluralize } from 'utils/pluralize';
export var VialsRedeemedDetails = function (_a) {
    var _b, _c, _d;
    var details = _a.details;
    return (React.createElement(React.Fragment, null,
        React.createElement(ListItemCard, null,
            React.createElement("div", null,
                React.createElement(Icon, { name: "shooting-star" }),
                React.createElement(Typography, { variant: "headlineSmall", display: "inline" },
                    details.redeemedForQuantity,
                    " vial",
                    pluralize((_b = details.redeemedForQuantity) !== null && _b !== void 0 ? _b : 0),
                    " redeemed")),
            React.createElement(Typography, { variant: "headlineSmall", display: "inline", color: "link" },
                "-",
                details.redeemedForPoints,
                " Credit",
                pluralize((_c = details.redeemedForPoints) !== null && _c !== void 0 ? _c : 0))),
        React.createElement(ListItemCard, null,
            React.createElement("div", null,
                React.createElement(Icon, { name: "user" }),
                React.createElement(Typography, { variant: "headlineSmall" },
                    "Redeemed by ",
                    details.staffFirstName,
                    " ",
                    details.staffLastName))),
        React.createElement(ListItemCard, null,
            React.createElement("div", null,
                React.createElement(Icon, { name: "orders" }),
                React.createElement(Typography, { variant: "headlineSmall" },
                    "Order ID: ", (_d = details.orderId) !== null && _d !== void 0 ? _d : 'Unknown')))));
};

import React from 'react';
import { DashboardView } from './View';
import query from './index.graphql';
import { useQuery } from '@apollo/client';
import { useUser } from '../../../../context';
export var DashboardContainer = function () {
    var _a, _b, _c, _d;
    var account = useUser().account;
    var _e = useQuery(query, {
        variables: {
            accountId: account.id,
        },
    }), data = _e.data, loading = _e.loading, called = _e.called;
    return (React.createElement(DashboardView, { loading: loading || !called, invoicePayments: (_a = data === null || data === void 0 ? void 0 : data.getInvoicePaymentsByAccount) === null || _a === void 0 ? void 0 : _a.payments, paymentMethods: (_b = data === null || data === void 0 ? void 0 : data.getPaymentMethodsByAccount) === null || _b === void 0 ? void 0 : _b.paymentMethods, currentBalance: (_c = data === null || data === void 0 ? void 0 : data.getOpenInvoicesByAccount) === null || _c === void 0 ? void 0 : _c.totalAmountDue, invoices: (_d = data === null || data === void 0 ? void 0 : data.getOpenInvoicesByAccount) === null || _d === void 0 ? void 0 : _d.invoices }));
};

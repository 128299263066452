var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var _a, _b;
import React from 'react';
import { Typography } from 'designSystem';
import styled from 'styled-components';
import { UserRole } from 'types/codegen/hooks';
import AdminIcon from 'assets/admin-icon.svg';
import LimitedIcon from 'assets/limited-icon.svg';
var LabelByRole = (_a = {},
    _a[UserRole.AccountAdmin] = 'Admin',
    _a[UserRole.Custom] = 'Limited',
    _a);
var IconByRole = (_b = {},
    _b[UserRole.AccountAdmin] = AdminIcon,
    _b[UserRole.Custom] = LimitedIcon,
    _b);
export var PermissionSelect = function (_a) {
    var selected = _a.selected, onSelect = _a.onSelect;
    return (React.createElement(Container, null, Object.values(UserRole).map(function (r, i) {
        var isSelected = r === selected;
        return (React.createElement(SelectableRoleCard, { key: i, onClick: function () { return onSelect(r); }, selected: isSelected },
            React.createElement(RoleIcon, null,
                React.createElement("img", { src: IconByRole[r], alt: "".concat(LabelByRole[r], "-icon") })),
            React.createElement("div", null,
                React.createElement(Typography, { display: "block", color: "alt4" }, "Role"),
                React.createElement(Typography, { color: isSelected ? 'alt3' : 'base' }, LabelByRole[r]))));
    })));
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 24px;\n  @media only screen and (max-width: ", "px) {\n    gap: 8px;\n  }\n"], ["\n  display: flex;\n  flex: 1;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 24px;\n  @media only screen and (max-width: ", "px) {\n    gap: 8px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var SelectableRoleCard = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  flex-direction: row;\n  background: ", ";\n\n  padding: 24px 36px;\n  gap: 36px;\n  border: none;\n  flex: 1;\n  &:hover {\n    background: ", ";\n  }\n  @media only screen and (max-width: ", "px) {\n    padding: 16px;\n    gap: 12px;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  flex-direction: row;\n  background: ", ";\n\n  padding: 24px 36px;\n  gap: 36px;\n  border: none;\n  flex: 1;\n  &:hover {\n    background: ", ";\n  }\n  @media only screen and (max-width: ", "px) {\n    padding: 16px;\n    gap: 12px;\n  }\n"])), function (props) {
    return props.selected
        ? props.theme.colors.teal.teal500
        : props.theme.colors.bg.base;
}, function (props) {
    return props.selected
        ? props.theme.colors.teal.teal600
        : props.theme.colors.white.white600;
}, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var RoleIcon = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: ", ";\n  height: 72px;\n  width: 72px;\n  padding: 18px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  @media only screen and (max-width: ", "px) {\n    height: 40px;\n    width: 40px;\n    padding: 10px;\n    & > img {\n      max-width: 20px;\n    }\n  }\n"], ["\n  background: ", ";\n  height: 72px;\n  width: 72px;\n  padding: 18px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  @media only screen and (max-width: ", "px) {\n    height: 40px;\n    width: 40px;\n    padding: 10px;\n    & > img {\n      max-width: 20px;\n    }\n  }\n"])), function (props) { return props.theme.colors.bg.alt; }, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var templateObject_1, templateObject_2, templateObject_3;

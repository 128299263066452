export var currencyFormatter = function (amount, places) {
    var MONEY_FORMATTER = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: places,
        maximumFractionDigits: places,
    });
    var numAmount = amount;
    if (typeof amount === 'string') {
        numAmount = +amount;
    }
    return MONEY_FORMATTER.format(numAmount);
};

import React from 'react';
export var Evolux = function (_a) {
    var color = _a.color;
    return (React.createElement("svg", { width: "17", height: "17", viewBox: "0 0 17 17", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0_895_8964)" },
            React.createElement("path", { d: "M6.58846 1.46501L7.87046 4.22835H10.3671C10.4928 4.22357 10.617 4.25769 10.7226 4.32605C10.8282 4.39442 10.9101 4.49371 10.9572 4.61036C11.0043 4.72702 11.0143 4.85537 10.9858 4.9779C10.9572 5.10043 10.8916 5.21117 10.7978 5.29501L8.6338 7.42568L9.8338 10.1817C9.88683 10.3082 9.89871 10.4481 9.86775 10.5818C9.83679 10.7154 9.76456 10.8359 9.6613 10.9261C9.55804 11.0164 9.42899 11.0719 9.29243 11.0848C9.15587 11.0976 9.01874 11.0671 8.90046 10.9977L6.0038 9.36701L3.10046 10.9977C2.98227 11.0663 2.8455 11.0961 2.70946 11.0829C2.57342 11.0697 2.44497 11.014 2.34222 10.9239C2.23948 10.8337 2.16763 10.7136 2.13682 10.5804C2.106 10.4473 2.11777 10.3078 2.17046 10.1817L3.37046 7.42701L1.2078 5.29701C1.11398 5.21359 1.04815 5.10327 1.01929 4.98108C0.990424 4.8589 0.99992 4.73078 1.04648 4.61419C1.09305 4.4976 1.17442 4.39819 1.27951 4.3295C1.38461 4.26082 1.50832 4.22619 1.6338 4.23035H4.12846L5.40913 1.46501C5.46574 1.35768 5.55058 1.26784 5.65449 1.20517C5.7584 1.1425 5.87745 1.10938 5.9988 1.10938C6.12015 1.10938 6.23919 1.1425 6.3431 1.20517C6.44702 1.26784 6.53185 1.35768 6.58846 1.46501V1.46501Z", stroke: color, strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { d: "M14.334 10.918V13.2513", stroke: color, strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { d: "M13.167 12.0859H15.5003", stroke: color, strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { d: "M5.83398 13.418V16.0846", stroke: color, strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { d: "M4.5 14.7539H7.16667", stroke: color, strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { d: "M14.667 1.08594V3.7526", stroke: color, strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { d: "M13.334 2.41797H16.0007", stroke: color, strokeLinecap: "round", strokeLinejoin: "round" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_895_8964" },
                React.createElement("rect", { width: "16", height: "16", fill: "white", transform: "translate(0.5 0.585938)" })))));
};

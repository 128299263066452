var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Alert, AlertRed, Calendar, Candy, Cherry, ChevronDown, ChevronLeft, ChevronRight, ChevronUp, Clear, Close, ClubSavings, Club1kBenefits, ConsumerRewards, Cookie, Cupcake, Delete, Discount, Download, EliteSummit, EditProfile, EvolusProfile, EvolusRewards, Evolux, EvoluxBoost, EvoluxLVBX, EvoluxMarketing, Filter, Grapes, GummyBear, HidePassword, Home, Help, IceCream, Injector, Intercom, Kebab, Loading, Lock, Logout, Lollipop, Menu, Notification, OfficeMarketing, Orders, Pear, Phone, Pineapple, Play, Present, Rewards, RoundCheck, Savings, Search, Settings, ShootingStar, ShowPassword, SpeechBubble, Strawberry, Switch, Tick, ToastPending, ToastSuccess, User, Watermelon, Info, } from './icons';
import { Photograph } from './icons/Photograph';
import { GiveCard } from './icons/GiveCard';
var Icons = {
    alert: Alert,
    'alert-red': AlertRed,
    calendar: Calendar,
    candy: Candy,
    'chevron-down': ChevronDown,
    'chevron-left': ChevronLeft,
    'chevron-right': ChevronRight,
    'chevron-up': ChevronUp,
    clear: Clear,
    close: Close,
    'club-savings': ClubSavings,
    'club-1k-benefits': Club1kBenefits,
    'consumer-rewards': ConsumerRewards,
    cookie: Cookie,
    cupcake: Cupcake,
    'edit-profile': EditProfile,
    'elite-summit': EliteSummit,
    'evolus-profile': EvolusProfile,
    'evolus-rewards': EvolusRewards,
    'evolux-boost': EvoluxBoost,
    'evolux-lvbx': EvoluxLVBX,
    'evolux-marketing': EvoluxMarketing,
    'ice-cream': IceCream,
    lock: Lock,
    cherry: Cherry,
    delete: Delete,
    discount: Discount,
    download: Download,
    evolux: Evolux,
    filter: Filter,
    'give-card': GiveCard,
    grapes: Grapes,
    'gummy-bear': GummyBear,
    'hide-password': HidePassword,
    home: Home,
    help: Help,
    info: Info,
    injector: Injector,
    intercom: Intercom,
    kebab: Kebab,
    loading: Loading,
    logout: Logout,
    lollipop: Lollipop,
    menu: Menu,
    notification: Notification,
    'office-marketing': OfficeMarketing,
    orders: Orders,
    pear: Pear,
    phone: Phone,
    photograph: Photograph,
    pineapple: Pineapple,
    play: Play,
    present: Present,
    rewards: Rewards,
    'round-check': RoundCheck,
    savings: Savings,
    search: Search,
    settings: Settings,
    'shooting-star': ShootingStar,
    'show-password': ShowPassword,
    'speech-bubble': SpeechBubble,
    strawberry: Strawberry,
    switch: Switch,
    tick: Tick,
    'toast-pending': ToastPending,
    'toast-success': ToastSuccess,
    user: User,
    watermelon: Watermelon,
};
export var Icon = function (_a) {
    var size = _a.size, color = _a.color, name = _a.name, rest = __rest(_a, ["size", "color", "name"]);
    var theme = useTheme();
    var Icon = Icons[name];
    return (React.createElement(Root, __assign({ size: size }, rest),
        React.createElement(Icon, { color: color !== null && color !== void 0 ? color : theme.colors.text.base })));
};
var Root = styled.span.attrs({
    alt: 'Icon',
})(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: ", "px;\n  height: ", "px;\n\n  svg {\n    width: ", "px;\n    height: ", "px;\n  }\n"], ["\n  width: ", "px;\n  height: ", "px;\n\n  svg {\n    width: ", "px;\n    height: ", "px;\n  }\n"])), function (props) { var _a; return (_a = props === null || props === void 0 ? void 0 : props.size) !== null && _a !== void 0 ? _a : 16; }, function (props) { var _a; return (_a = props === null || props === void 0 ? void 0 : props.size) !== null && _a !== void 0 ? _a : 16; }, function (props) { var _a; return (_a = props === null || props === void 0 ? void 0 : props.size) !== null && _a !== void 0 ? _a : 16; }, function (props) { var _a; return (_a = props === null || props === void 0 ? void 0 : props.size) !== null && _a !== void 0 ? _a : 16; });
var templateObject_1;

import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';
export var useScreenSizing = function (custom) {
    var theme = useTheme();
    var isMobile = useMediaQuery({ maxWidth: theme.breakpoints.md });
    var isLarge = useMediaQuery({ maxWidth: theme.breakpoints.lg });
    var isCustom = useMediaQuery({ maxWidth: custom || theme.breakpoints.sm });
    return {
        isMobile: isMobile,
        isLarge: isLarge,
        isCustom: isCustom,
    };
};

export var StepEnum;
(function (StepEnum) {
    StepEnum["PhoneInput"] = "?step=mobileNumberInput";
    StepEnum["NumberNotSupported"] = "?step=numberNotSupported";
    StepEnum["EnrollPatient"] = "?step=enrollPatient";
    StepEnum["PatientConsent"] = "?step=patientConsent";
    StepEnum["SendingMessage"] = "?step=sendingMessage";
    StepEnum["ConsentNotGiven"] = "?step=patientConsentNotGiven";
    StepEnum["EmailInput"] = "?step=emailAddressInput";
    StepEnum["EnrollPatientForm"] = "?step=enrollPatientForm";
    StepEnum["EnrollmentProgress"] = "?step=enrollmentProgress";
    StepEnum["RewardStatus"] = "?step=patientRewardStatus";
    StepEnum["RewardReminderSent"] = "?step=rewardReminderSent";
    StepEnum["SpecialistSelect"] = "?step=selectSpecialist";
    StepEnum["CheckInAgreement"] = "?step=checkInAgreement";
    StepEnum["SendMagicPhrase"] = "?step=sendMagicPhrase";
    StepEnum["ValidateMagicPhrase"] = "?step=validateMagicPhrase";
    StepEnum["CheckInComplete"] = "?step=checkInComplete";
})(StepEnum || (StepEnum = {}));

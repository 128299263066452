import { InvoiceStatus, SensitivePaymentType, } from 'types/codegen/hooks';
export var getInvoiceStatus = function (_a) {
    var status = _a.status, isPastDue = _a.isPastDue;
    if (status === InvoiceStatus.Open && isPastDue) {
        return 'PAST_DUE';
    }
    return status;
};
export var renderPaymentName = function (pd) {
    if (!pd) {
        return null;
    }
    if (pd.__typename === 'ExternalPayment') {
        return pd.displayName;
    }
    if (pd.__typename === 'PaymentMethod') {
        var _a = pd.sensitive, type = _a.type, cc = _a.cc, ach = _a.ach;
        if (type === SensitivePaymentType.Cc) {
            return "".concat(cc.network, " ending in ").concat(cc.number.substring(cc.number.length - 4));
        }
        return "".concat(ach === null || ach === void 0 ? void 0 : ach.accountName, " ").concat(ach === null || ach === void 0 ? void 0 : ach.accountNumber);
    }
};

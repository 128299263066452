var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import ErrorIcon from 'assets/error-circle.png';
import { Typography } from 'designSystem';
import styled, { css } from 'styled-components';
import { addMobileStyles } from 'utils/addMobileStyles';
export var PastDueCard = function () {
    return (React.createElement(Chip, null,
        React.createElement("img", { src: ErrorIcon, alt: "past-due-warning" }),
        React.createElement(Typography, { color: "link" }, "You have invoices that are past due.")));
};
var Chip = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 16px;\n  background: ", ";\n  border: 2px solid ", ";\n  padding: 16px 20px;\n  ", "\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 16px;\n  background: ", ";\n  border: 2px solid ", ";\n  padding: 16px 20px;\n  ", "\n"])), function (props) { return props.theme.colors.bg.error; }, function (props) { return props.theme.colors.border.error2; }, addMobileStyles(css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    margin: -16px 16px 36px;\n  "], ["\n    margin: -16px 16px 36px;\n  "])))));
var templateObject_1, templateObject_2;

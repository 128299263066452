var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useMemo } from 'react';
import { format } from 'date-fns';
import { pluralize } from 'utils/pluralize';
import { useTable, usePagination } from 'react-table';
import styled, { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Typography } from 'designSystem/components/Typography';
import { OrderStatus } from 'types/codegen/hooks';
import { Table, TableBody, TableHead, TableRow, TableColumn, TableHeadColumn, Icon, Tag, Desktop, Mobile, Button, } from 'designSystem';
import { PlaceholderLoading } from 'designSystem/components/PlaceholderLoading';
var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
});
var getTagVariant = function (status) {
    switch (status) {
        case OrderStatus.Completed:
            return 'alt';
        case OrderStatus.OnHold:
        case OrderStatus.PendingEvolusPointAuth:
            return 'alert';
        case OrderStatus.Rejected:
            return 'danger';
        case OrderStatus.Shipped:
            return 'success';
        case OrderStatus.Submitted:
            return 'base';
        default:
            return 'alt3';
    }
};
var getTagMessage = function (status) {
    switch (status) {
        case OrderStatus.Completed:
            return 'Delivered';
        case OrderStatus.OnHold:
        case OrderStatus.PendingEvolusPointAuth:
            return 'On Hold';
        case OrderStatus.Rejected:
            return 'Rejected';
        case OrderStatus.Shipped:
            return 'Shipped';
        case OrderStatus.Submitted:
            return 'Placed';
        default:
            return 'Received';
    }
};
export var OrderHistoryWidgetView = function (_a) {
    var orders = _a.orders, accountId = _a.accountId, isLoading = _a.loading;
    var navigate = useNavigate();
    var theme = useTheme();
    var columns = useMemo(function () { return [
        {
            id: 'facility.name',
            Header: 'Facility',
            accessor: 'facility.name',
            defaultCanSort: true,
            Cell: function (_a) {
                var value = _a.value;
                return (React.createElement(Typography, { variant: "headline" }, value));
            },
        },
        {
            id: 'id.keyword',
            Header: 'Order Number',
            accessor: 'id',
            defaultCanSort: true,
        },
        {
            id: 'orderDate',
            Header: 'Date',
            accessor: function (order) { return format(new Date(order.orderDate), 'MMM dd, yyyy'); },
            defaultCanSort: true,
        },
        {
            id: 'totalEstimated',
            Header: 'Amount',
            accessor: function (order) { return ({
                amount: order.totalEstimated,
                quantity: order.lineItems[0].product.quantity,
            }); },
            Cell: function (_a) {
                var _b;
                var value = _a.value;
                return (React.createElement(React.Fragment, null,
                    formatter.format((_b = value === null || value === void 0 ? void 0 : value.amount) !== null && _b !== void 0 ? _b : 0),
                    " (",
                    value.quantity,
                    " Vial",
                    pluralize(value.quantity),
                    ")"));
            },
            defaultCanSort: true,
        },
        {
            id: 'status',
            Header: 'Order Status',
            accessor: 'status',
            Cell: function (_a) {
                var value = _a.value;
                return (React.createElement(Tag, { variant: getTagVariant(value), type: "secondary", text: getTagMessage(value), width: 84 }));
            },
            defaultCanSort: true,
            maxWidth: 150,
            width: 100,
        },
        {
            Header: '',
            accessor: 'id',
            Cell: function () { return (React.createElement("div", { style: { textAlign: 'right' } },
                React.createElement(Icon, { name: "chevron-right", color: theme.colors.coral.coral500 }))); },
            defaultCanSort: false,
            disableSortBy: true,
            width: 30,
        },
    ]; }, []);
    var _b = useTable({
        columns: columns,
        data: orders,
        initialState: {
            pageIndex: 0,
            pageSize: 3,
            sortBy: [
                {
                    id: 'orderDate',
                    desc: true,
                },
            ],
        },
    }, usePagination), getTableProps = _b.getTableProps, getTableBodyProps = _b.getTableBodyProps, prepareRow = _b.prepareRow, page = _b.page;
    var RecordsTable = function () { return (React.createElement(Table, __assign({ fullWidth: true, style: { marginTop: 48 } }, getTableProps()),
        React.createElement(TableHead, null,
            React.createElement(TableRow, null,
                React.createElement(TableHeadColumn, { colSpan: 6 },
                    React.createElement(Typography, null, "Recent Orders")))),
        React.createElement(TableBody, __assign({}, getTableBodyProps(), { stripped: true, selectable: true }), page.map(function (row, i) {
            prepareRow(row);
            return (React.createElement(TableRow, __assign({}, row.getRowProps(), { onClick: function () { return navigate("/orders/".concat(row.original.id)); } }), row.cells.map(function (cell) {
                return (React.createElement(TableColumn, __assign({}, cell.getCellProps(), { style: {
                        width: cell.column.width !== 150 ? cell.column.width : 'auto',
                    } }), cell.render('Cell')));
            })));
        })))); };
    var RecordCard = function (_a) {
        var _b;
        var order = _a.order;
        return (React.createElement(OrderCard, { onClick: function () { return navigate("/orders/".concat(order.id)); } },
            React.createElement(OrderDetails, null,
                React.createElement(Typography, { variant: "headlineSmall" }, order.facility.name),
                React.createElement(Typography, { variant: "footnote" }, order.id),
                React.createElement(Typography, { variant: "footnote" },
                    formatter.format((_b = order === null || order === void 0 ? void 0 : order.totalEstimated) !== null && _b !== void 0 ? _b : 0),
                    " (",
                    order.lineItems[0].product.quantity,
                    " Vial",
                    pluralize(order.lineItems[0].product.quantity),
                    ")"),
                React.createElement(Typography, { variant: "footnote" }, format(new Date(order.orderDate), 'MMM dd, yyyy'))),
            React.createElement(OrderStatusContent, null,
                React.createElement(Tag, { variant: getTagVariant(order.status), type: "secondary", text: getTagMessage(order.status), width: 76, size: "small" }),
                React.createElement(Icon, { name: "chevron-right", color: theme.colors.coral.coral500, style: { marginLeft: 16 }, size: 14 }))));
    };
    var RecordsCards = function () { return (React.createElement(Records, null, orders.map(function (order) { return (React.createElement(RecordCard, { order: order })); }))); };
    var Content = function () { return (React.createElement(OrderHistoryContainer, null,
        React.createElement(OrderHistoryHeaderContainer, null,
            React.createElement(Mobile, null,
                React.createElement(Typography, { variant: "headline" }, "Order History"),
                React.createElement(Button, { onClick: function () { return navigate('/orders/history'); }, variant: "tertiary", noPadding: true, size: "small" }, "View All Statements")),
            React.createElement(Desktop, null,
                React.createElement(Typography, { variant: "titleLarge" }, "Order History"),
                React.createElement("div", null,
                    React.createElement(Button, { onClick: function () { return navigate('/orders/history'); }, variant: "outlined" }, "View All Statements")))),
        React.createElement(Desktop, null,
            React.createElement(OrderHistorySubtext, null, "View all account transactions.")),
        isLoading ? (React.createElement(LoadingOuterContainer, null,
            React.createElement(LoadingInnerContainer, null,
                React.createElement(PlaceholderLoading, { size: "small" })))) : (React.createElement(React.Fragment, null,
            React.createElement(Desktop, null,
                React.createElement(RecordsTable, null)),
            React.createElement(Mobile, null,
                React.createElement(RecordsCards, null),
                React.createElement(Button, { onClick: function () { return navigate('/orders/buy'); }, fullWidth: true, size: "large", startIcon: React.createElement(Icon, { name: "orders", color: "white" }) }, "Order Jeuveau")))))); };
    return (React.createElement(React.Fragment, null,
        React.createElement(Desktop, null,
            React.createElement(Content, null)),
        React.createElement(Mobile, null,
            React.createElement(MobileContainer, null,
                React.createElement(Content, null)))));
};
var MobileContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  z-index: 1;\n"], ["\n  display: flex;\n  z-index: 1;\n"])));
var Records = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: 16px;\n  margin-bottom: 32px;\n"], ["\n  margin-top: 16px;\n  margin-bottom: 32px;\n"])));
var OrderCard = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  padding: 12px 20px;\n  background: ", ";\n  border: 1px solid ", ";\n\n  flex-direction: row;\n  align-items: center;\n  flex-wrap: wrap;\n  justify-content: space-between;\n\n  &:not(:last-child) {\n    margin-bottom: 8px;\n  }\n"], ["\n  display: flex;\n  padding: 12px 20px;\n  background: ", ";\n  border: 1px solid ", ";\n\n  flex-direction: row;\n  align-items: center;\n  flex-wrap: wrap;\n  justify-content: space-between;\n\n  &:not(:last-child) {\n    margin-bottom: 8px;\n  }\n"])), function (props) { return props.theme.colors.white.white50; }, function (props) { return props.theme.colors.border.base; });
var OrderDetails = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  width: calc(50% - 8px);\n"], ["\n  display: flex;\n  flex-direction: column;\n  width: calc(50% - 8px);\n"])));
var OrderStatusContent = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  width: calc(50% - 8px);\n  justify-content: flex-end;\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  width: calc(50% - 8px);\n  justify-content: flex-end;\n"])));
var OrderHistoryContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  background-color: white;\n  padding: 48px;\n  flex-direction: column;\n\n  margin: 16px;\n\n  @media only screen and (min-width: ", "px) {\n    ", ";\n  }\n\n  @media only screen and (max-width: ", "px) {\n    margin: 0;\n    margin-top: -20px;\n    padding: 32px;\n    ", ";\n  }\n"], ["\n  display: flex;\n  flex: 1;\n  background-color: white;\n  padding: 48px;\n  flex-direction: column;\n\n  margin: 16px;\n\n  @media only screen and (min-width: ", "px) {\n    ", ";\n  }\n\n  @media only screen and (max-width: ", "px) {\n    margin: 0;\n    margin-top: -20px;\n    padding: 32px;\n    ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
}, function (props) { return props.theme.elevation.base.elevation1; }, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
}, function (props) { return props.theme.elevation.base.elevation2; });
var OrderHistoryHeaderContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  flex-direction: row;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  flex: 1;\n  flex-direction: row;\n  justify-content: space-between;\n"])));
var OrderHistorySubtext = styled.span(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-size: 1rem;\n  padding-top: 16px;\n  color: ", ";\n"], ["\n  font-size: 1rem;\n  padding-top: 16px;\n  color: ", ";\n"])), function (props) { return props.theme.colors.text.alt; });
var LoadingOuterContainer = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  height: 300px;\n"], ["\n  display: flex;\n  width: 100%;\n  height: 300px;\n"])));
var LoadingInnerContainer = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 300px;\n"], ["\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 300px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;

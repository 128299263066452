var loyaltyRankLevelNames = {
    LEVEL_1: 'Member',
    LEVEL_2: 'Silver',
    LEVEL_3: 'Gold',
    LEVEL_4: 'Titanium',
    LEVEL_5: 'Platinum',
    LEVEL_6: 'Diamond',
    LEVEL_7: 'Elite',
    LEVEL_8: 'Elite+',
};
export default loyaltyRankLevelNames;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { UserPermissionsView } from './View';
import { useUser } from 'context';
import { FacilityStatus } from 'types/types';
export var UserPermissionsContainer = function (props) {
    var account = useUser().account;
    return (React.createElement(UserPermissionsView, __assign({}, props, { onUpdate: function () { }, mainContactId: account === null || account === void 0 ? void 0 : account.mainContactUserId, users: account === null || account === void 0 ? void 0 : account.users, loading: false, facilities: account === null || account === void 0 ? void 0 : account.facilities.filter(function (f) { return f.status !== FacilityStatus.Inactive; }) })));
};

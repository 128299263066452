var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { TableRow } from './TableRow';
export var TableBody = styled.tbody(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 16px 0;\n  position: relative;\n  top: 16px;\n\n  ", "\n\n  ", "\n"], ["\n  padding: 16px 0;\n  position: relative;\n  top: 16px;\n\n  ", "\n\n  ", "\n"])), function (props) {
    return props.stripped &&
        "\n    ".concat(TableRow, ":nth-child(odd) {\n      background: ").concat(props.theme.colors.bg.alt3, ";\n    }\n\n  ");
}, function (props) {
    return props.selectable &&
        "\n    ".concat(TableRow, " {\n      cursor: pointer;\n\n      &:hover {\n        background: ").concat(props.theme.colors.bg.alt, ";\n      }\n    }\n\n  ");
});
var templateObject_1;

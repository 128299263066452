import React from 'react';
export var Pear = function (_a) {
    var color = _a.color;
    return (React.createElement("svg", { width: "48", height: "48", viewBox: "0 0 48 48", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0_339_86555)" },
            React.createElement("path", { d: "M23.9989 10.44C22.4198 10.3231 20.8539 10.7995 19.6074 11.776C18.3609 12.7524 17.5235 14.1588 17.2589 15.72C16.6821 19.6313 14.8336 23.244 11.9989 26C9.31756 28.6588 7.73289 32.2279 7.55891 36C7.49891 44 15.9989 46.68 23.9989 46.48C31.9989 46.68 40.4989 43.9 40.4989 36.02C40.3139 32.2325 38.7069 28.6543 35.9989 26C33.5737 23.5709 31.8526 20.5296 31.0189 17.2", stroke: color, strokeWidth: "3", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { d: "M36.1388 9.15997C34.691 10.5115 32.8866 11.4206 30.9388 11.78C30.6094 11.8889 30.2562 11.9045 29.9184 11.8251C29.5806 11.7456 29.2714 11.5743 29.025 11.3299C28.7786 11.0856 28.6046 10.7779 28.5224 10.4407C28.4401 10.1036 28.4527 9.75034 28.5588 9.41997C28.9135 7.46471 29.823 5.65269 31.1788 4.19997C32.6341 2.84779 34.445 1.93888 36.3988 1.57997C36.7341 1.46059 37.0965 1.43987 37.4431 1.52027C37.7898 1.60067 38.106 1.77881 38.3545 2.03356C38.6029 2.28831 38.7731 2.60898 38.8447 2.95753C38.9164 3.30607 38.8866 3.66786 38.7588 3.99997C38.3934 5.93392 37.4846 7.72376 36.1388 9.15997Z", stroke: color, strokeWidth: "3", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { d: "M16.499 1.5199C18.4881 1.5199 20.3958 2.31007 21.8023 3.7166C23.2088 5.12312 23.999 7.03077 23.999 9.0199V15.0199", stroke: color, strokeWidth: "3", strokeLinecap: "round", strokeLinejoin: "round" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_339_86555" },
                React.createElement("rect", { width: "48", height: "48", fill: "white" })))));
};

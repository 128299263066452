var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { pluralize } from 'utils/pluralize';
import { useTable, useSortBy, usePagination } from 'react-table';
import styled, { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { OrderStatus } from 'types/codegen/hooks';
import { Card, CardBody, Table, TableBody, TableHead, TableFooter, TableRow, TableColumn, TableHeadColumn, Pagination, Icon, Tag, Row, Column, Desktop, Typography, Mobile, } from 'designSystem';
import { SearchView as Search } from './components/Search';
import Dropdown from './components/OrderStatus';
import { PlaceholderLoading } from 'designSystem/components/PlaceholderLoading';
var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
});
var getTagVariant = function (status) {
    switch (status) {
        case OrderStatus.Completed:
            return 'alt';
        case OrderStatus.OnHold:
        case OrderStatus.PendingEvolusPointAuth:
            return 'alert';
        case OrderStatus.Rejected:
            return 'danger';
        case OrderStatus.Shipped:
            return 'success';
        case OrderStatus.Submitted:
            return 'base';
        default:
            return 'alt3';
    }
};
var getTagMessage = function (status) {
    switch (status) {
        case OrderStatus.Completed:
            return 'Delivered';
        case OrderStatus.OnHold:
        case OrderStatus.PendingEvolusPointAuth:
            return 'On Hold';
        case OrderStatus.Rejected:
            return 'Rejected';
        case OrderStatus.Shipped:
            return 'Shipped';
        case OrderStatus.Submitted:
            return 'Placed';
        default:
            return 'Received';
    }
};
export var OrderHistoryView = function (_a) {
    var orders = _a.orders, totalOrders = _a.totalOrders, isLoading = _a.loading, setSearchText = _a.setSearchText, setFilters = _a.setFilters, setSort = _a.setSort, setPage = _a.setPage, page = _a.page, error = _a.error, hasDue = _a.hasDue;
    var navigate = useNavigate();
    var theme = useTheme();
    var _b = __read(useState(undefined), 2), phrase = _b[0], setPhrase = _b[1];
    var _c = __read(useState([]), 2), statuses = _c[0], setStatuses = _c[1];
    var columns = useMemo(function () { return [
        {
            id: 'facility.name',
            Header: 'Facility',
            accessor: 'facility.name',
            defaultCanSort: false,
            disableSortBy: true,
            Cell: function (_a) {
                var value = _a.value;
                return (React.createElement(Typography, { variant: "headline" }, value));
            },
        },
        {
            id: 'id.keyword',
            Header: 'Order Number',
            accessor: 'id',
            defaultCanSort: true,
        },
        {
            id: 'orderDate',
            Header: 'Date',
            accessor: function (order) { return format(new Date(order.orderDate), 'MMM dd, yyyy'); },
            defaultCanSort: true,
        },
        {
            id: 'totalEstimated',
            Header: 'Amount',
            accessor: function (order) { return ({
                amount: order.totalEstimated,
                quantity: order.lineItems[0].product.quantity,
            }); },
            Cell: function (_a) {
                var _b;
                var value = _a.value;
                return (React.createElement(React.Fragment, null,
                    formatter.format((_b = value === null || value === void 0 ? void 0 : value.amount) !== null && _b !== void 0 ? _b : 0),
                    " (",
                    value.quantity,
                    " Vial",
                    pluralize(value.quantity),
                    ")"));
            },
            defaultCanSort: true,
        },
        {
            id: 'status',
            Header: 'Order Status',
            accessor: 'status',
            Cell: function (_a) {
                var value = _a.value;
                return (React.createElement(Tag, { variant: getTagVariant(value), type: "secondary", text: getTagMessage(value), width: 84, size: "large" }));
            },
            defaultCanSort: true,
            maxWidth: 150,
            width: 145,
        },
        {
            Header: '',
            accessor: 'id',
            Cell: function () { return (React.createElement("div", { style: { textAlign: 'right' } },
                React.createElement(Icon, { name: "chevron-right", color: theme.colors.coral.coral500 }))); },
            defaultCanSort: false,
            disableSortBy: true,
            width: 30,
        },
    ]; }, []);
    var _d = useTable({
        columns: columns,
        data: orders,
        manualSortBy: true,
        initialState: {
            pageIndex: 0,
            pageSize: 10,
            sortBy: [
                {
                    id: 'orderDate',
                    desc: true,
                },
            ],
        },
    }, useSortBy, usePagination), getTableProps = _d.getTableProps, getTableBodyProps = _d.getTableBodyProps, headerGroups = _d.headerGroups, prepareRow = _d.prepareRow, currentPage = _d.page, _e = _d.state, pageSize = _e.pageSize, sortBy = _e.sortBy;
    var setupFilters = function (values) {
        if (values === null || values === void 0 ? void 0 : values.length) {
            setFilters([{ field: 'status.keyword', equals: values[0].value }]);
        }
        else {
            setFilters([]);
        }
    };
    var getSortByField = useCallback(function () {
        switch (sortBy[0].id) {
            case 'status':
                return 'status.keyword';
            case 'facility.name':
                return 'facility.name';
            default:
                return sortBy[0].id;
        }
    }, [sortBy]);
    useEffect(function () {
        if (sortBy[0]) {
            setSort([
                {
                    field: getSortByField(),
                    direction: sortBy[0].desc ? 'DESC' : 'ASC',
                },
            ]);
        }
        else {
            setSort([]);
        }
    }, [sortBy]);
    var TableOrders = function () { return (React.createElement(Table, __assign({ fullWidth: true, style: { marginTop: 48 } }, getTableProps()),
        React.createElement(TableHead, null, headerGroups.map(function (headerGroup) { return (React.createElement(TableRow, __assign({}, headerGroup.getHeaderGroupProps()), headerGroup.headers.map(function (column) {
            return (React.createElement(TableHeadColumn, __assign({}, column.getHeaderProps(column.getSortByToggleProps()), { style: {
                    width: column.width !== 150 ? column.width : 'auto',
                    cursor: column.defaultCanSort ? 'pointer' : 'inherit',
                } }),
                column.render('Header'),
                column.defaultCanSort && (React.createElement(SortWrapper, null,
                    React.createElement(Icon, { name: "chevron-up", size: 8, color: column.isSorted && !column.isSortedDesc
                            ? theme.colors.coral.coral500
                            : theme.colors.text.alt }),
                    React.createElement(Icon, { name: "chevron-down", size: 8, color: column.isSorted && column.isSortedDesc
                            ? theme.colors.coral.coral500
                            : theme.colors.text.alt, style: { marginTop: 4 } })))));
        }))); })),
        React.createElement(TableBody, __assign({}, getTableBodyProps(), { stripped: true, selectable: true }), currentPage.map(function (row, i) {
            prepareRow(row);
            return (React.createElement(TableRow, __assign({}, row.getRowProps(), { onClick: function () { return navigate("/orders/".concat(row.original.id)); } }), row.cells.map(function (cell) { return (React.createElement(TableColumn, __assign({}, cell.getCellProps()), cell.render('Cell'))); })));
        })),
        React.createElement(TableFooter, null,
            React.createElement(TableRow, null,
                React.createElement(TableColumn, { colSpan: 6 },
                    React.createElement(Pagination, { page: page, limit: pageSize, total: totalOrders, onPageChange: function (page) { return setPage(page); }, buttonNavigation: true, size: "small", justifyContent: "end" })))))); };
    var RecordCard = function (_a) {
        var _b;
        var order = _a.order;
        return (React.createElement(OrderCard, { onClick: function () { return navigate("/orders/".concat(order.id)); } },
            React.createElement(OrderDetails, null,
                React.createElement(Typography, { variant: "headlineSmall" }, order.facility.name),
                React.createElement(Typography, { variant: "footnote" }, order.id),
                React.createElement(Typography, { variant: "footnote" },
                    formatter.format((_b = order === null || order === void 0 ? void 0 : order.totalEstimated) !== null && _b !== void 0 ? _b : 0),
                    " (",
                    order.lineItems[0].product.quantity,
                    " Vial",
                    pluralize(order.lineItems[0].product.quantity),
                    ")"),
                React.createElement(Typography, { variant: "footnote" }, format(new Date(order.orderDate), 'MMM dd, yyyy'))),
            React.createElement(OrderStatusContent, null,
                React.createElement(Tag, { variant: getTagVariant(order.status), type: "secondary", text: getTagMessage(order.status), width: 76, size: "small" }),
                React.createElement(Icon, { name: "chevron-right", color: theme.colors.coral.coral500, style: { marginLeft: 16 }, size: 14 }))));
    };
    var RecordsCards = function () { return (React.createElement(Records, null, orders.map(function (order) { return (React.createElement(RecordCard, { order: order, key: order.id })); }))); };
    var ErrorDisplay = function (_a) {
        var error = _a.error;
        return React.createElement(Records, null, error === null || error === void 0 ? void 0 : error.message);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Container, null,
            React.createElement(HistoryCard, { direction: "horizontal", fullWidth: true },
                React.createElement(HistoryCardBody, null,
                    React.createElement(Typography, { variant: "titleLarge", mb: 16 }, "Order History"),
                    React.createElement(Typography, { variant: "body", mb: 24 }, "View all account transactions."),
                    React.createElement(Row, { alignItems: "center" },
                        React.createElement(Column, { md: 6 },
                            React.createElement(Dropdown, { onChange: function (values) {
                                    setupFilters(values);
                                    setStatuses(values);
                                }, selected: statuses, closeOnSelect: true })),
                        React.createElement(Column, { md: 6, justifyContent: "flex-end" },
                            React.createElement(Search, { value: phrase, onChange: function (newPhrase) { return setPhrase(newPhrase); }, onSearch: function () { return null; }, setSearchText: setSearchText }))),
                    isLoading ? (React.createElement(LoadingOuterContainer, null,
                        React.createElement(LoadingInnerContainer, null,
                            React.createElement(PlaceholderLoading, { size: "small" })))) : (React.createElement(React.Fragment, null,
                        React.createElement(Desktop, null, error ? React.createElement(ErrorDisplay, { error: error }) : React.createElement(TableOrders, null)),
                        React.createElement(Mobile, null,
                            error ? React.createElement(ErrorDisplay, { error: error }) : React.createElement(RecordsCards, null),
                            React.createElement(Pagination, { page: page, limit: pageSize, total: totalOrders, onPageChange: function (page) { return setPage(page); }, buttonNavigation: true, size: "small", justifyContent: "end" })))))))));
};
export default OrderHistoryView;
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 16px;\n  flex: 1;\n  display: flex;\n  @media only screen and (max-width: ", "px) {\n    margin: 0;\n  }\n"], ["\n  margin: 16px;\n  flex: 1;\n  display: flex;\n  @media only screen and (max-width: ", "px) {\n    margin: 0;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var HistoryCard = styled(Card)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  @media only screen and (max-width: ", "px) {\n    margin: 0;\n    margin-top: 20px;\n    border-bottom: none;\n    ", ";\n  }\n"], ["\n  @media only screen and (max-width: ", "px) {\n    margin: 0;\n    margin-top: 20px;\n    border-bottom: none;\n    ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
}, function (props) { return props.theme.elevation.base.elevation2; });
var HistoryCardBody = styled(CardBody)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  @media only screen and (max-width: ", "px) {\n    padding: 32px;\n  }\n"], ["\n  @media only screen and (max-width: ", "px) {\n    padding: 32px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var SortWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: inline-flex;\n  flex-direction: column;\n  align-items: center;\n  margin-left: 12px;\n  top: -4px;\n  position: relative;\n"], ["\n  display: inline-flex;\n  flex-direction: column;\n  align-items: center;\n  margin-left: 12px;\n  top: -4px;\n  position: relative;\n"])));
var LoadingOuterContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-top: 64px;\n  display: flex;\n  height: 40vh;\n  width: 100%;\n  border: 1px solid ", ";\n\n  justify-content: center;\n  align-items: center;\n"], ["\n  margin-top: 64px;\n  display: flex;\n  height: 40vh;\n  width: 100%;\n  border: 1px solid ", ";\n\n  justify-content: center;\n  align-items: center;\n"])), function (props) { return props.theme.colors.border.base; });
var LoadingInnerContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 50%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"], ["\n  width: 50%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"])));
var Records = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin-top: 16px;\n  margin-bottom: 32px;\n  width: 100%;\n"], ["\n  margin-top: 16px;\n  margin-bottom: 32px;\n  width: 100%;\n"])));
var OrderCard = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  padding: 12px 20px;\n  background: ", ";\n  border: 1px solid ", ";\n\n  flex-direction: row;\n  align-items: center;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  width: 100%;\n\n  &:not(:last-child) {\n    margin-bottom: 8px;\n  }\n"], ["\n  display: flex;\n  padding: 12px 20px;\n  background: ", ";\n  border: 1px solid ", ";\n\n  flex-direction: row;\n  align-items: center;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  width: 100%;\n\n  &:not(:last-child) {\n    margin-bottom: 8px;\n  }\n"])), function (props) { return props.theme.colors.white.white50; }, function (props) { return props.theme.colors.border.base; });
var OrderDetails = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  width: calc(50% - 8px);\n"], ["\n  display: flex;\n  flex-direction: column;\n  width: calc(50% - 8px);\n"])));
var OrderStatusContent = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  width: calc(50% - 8px);\n  justify-content: flex-end;\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  width: calc(50% - 8px);\n  justify-content: flex-end;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;

var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useQueryParams } from 'hooks/useQueryParams';
import { useLocalStorage } from "../../../../hooks";
export var Authenticated = function () {
    var _a;
    var _b = __read(useLocalStorage('loggedIn', false), 1), isAuthed = _b[0];
    var params = useQueryParams().params;
    if (isAuthed) {
        return React.createElement(Navigate, { to: (_a = params.redirect) !== null && _a !== void 0 ? _a : '/home' });
    }
    return React.createElement(Outlet, null);
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal } from '../Modal';
import { Button, Checkbox, Dropdown, Icon, Menu, MenuItem, MenuItemText, Pagination, Typography, } from 'designSystem';
import { useModal } from 'context';
import { useScreenSizing } from '../../../hooks';
import theme from 'designSystem/theme/theme';
var searchOptions = [
    { value: 'ALL', label: 'All' },
    { value: 'SELECTED', label: 'Selected' },
    { value: 'UNSELECTED', label: 'Unselected' },
];
export var FacilitySelection = function (_a) {
    var onConfirm = _a.onConfirm, facilities = _a.facilities, facilitiesSelected = _a.facilitiesSelected;
    var closeModal = useModal().closeModal;
    var isMobile = useScreenSizing().isMobile;
    var _b = __read(useState(''), 2), searchTerm = _b[0], setSearchTerm = _b[1];
    var _c = __read(useState(0), 2), currentPage = _c[0], setCurrentPage = _c[1];
    var itemsperPage = 10;
    var _d = __read(useState(facilitiesSelected !== null && facilitiesSelected !== void 0 ? facilitiesSelected : []), 2), selectedFacilities = _d[0], setSelectedFacilities = _d[1];
    var _e = __read(useState(searchOptions[0]), 2), dropdownValue = _e[0], setDropdownValue = _e[1];
    var toggleCheckbox = function (value) {
        if (selectedFacilities === null || selectedFacilities === void 0 ? void 0 : selectedFacilities.includes(value)) {
            setSelectedFacilities(selectedFacilities === null || selectedFacilities === void 0 ? void 0 : selectedFacilities.filter(function (facility) { return facility !== value; }));
        }
        else {
            setSelectedFacilities(__spreadArray(__spreadArray([], __read(selectedFacilities), false), [value], false));
        }
    };
    var handleDropdownSelect = function (value) {
        setDropdownValue(value);
        setCurrentPage(0);
    };
    var filteredMenuItems = facilities
        .filter(function (item) {
        if (dropdownValue.value === 'SELECTED') {
            return ((selectedFacilities === null || selectedFacilities === void 0 ? void 0 : selectedFacilities.includes(item.id)) &&
                item.name.toLowerCase().includes(searchTerm.toLowerCase()));
        }
        else if (dropdownValue.value === 'UNSELECTED') {
            return (!(selectedFacilities === null || selectedFacilities === void 0 ? void 0 : selectedFacilities.includes(item.id)) &&
                item.name.toLowerCase().includes(searchTerm.toLowerCase()));
        }
        else {
            return item.name.toLowerCase().includes(searchTerm.toLowerCase());
        }
    })
        .sort(function (a, b) { return a.name.localeCompare(b.name); });
    var paginatedItems = filteredMenuItems.slice(currentPage * itemsperPage, (currentPage + 1) * itemsperPage);
    return (React.createElement(Modal, { size: "small" },
        React.createElement(ContentContainer, null,
            React.createElement("div", null,
                React.createElement(Typography, { variant: 'titleLarge', color: 'base', style: { textAlign: 'left' } }, "Assigned Facilities"),
                React.createElement(Typography, { display: "block", color: "alt", variant: "body", style: { textAlign: 'left' } }, "Please select the facilities the user will have access to."),
                React.createElement(SearchAndDropdownContainer, null,
                    React.createElement(StyledSearchInputContainer, null,
                        React.createElement(StyledSearchInput, { placeholder: "Search", value: searchTerm, onChange: function (e) { return setSearchTerm(e.target.value); } }),
                        searchTerm ? (React.createElement(CloseIcon, { name: 'close', onClick: function () { return setSearchTerm(''); } })) : (React.createElement(SearchIcon, { searchTerm: searchTerm, name: 'search' }))),
                    React.createElement(CustomDropDown, null,
                        React.createElement(StyledDropdown, { onSelect: handleDropdownSelect, selected: dropdownValue, options: searchOptions }))),
                React.createElement(Menu, null, paginatedItems === null || paginatedItems === void 0 ? void 0 : paginatedItems.map(function (f, i) {
                    return (React.createElement(MenuItm, { key: f.id, onClick: function () { return toggleCheckbox(f.id); }, style: {
                            background: (selectedFacilities === null || selectedFacilities === void 0 ? void 0 : selectedFacilities.includes(f.id))
                                ? '#F9F9F9'
                                : 'inherit',
                        } },
                        React.createElement(Checkbox, { checked: selectedFacilities === null || selectedFacilities === void 0 ? void 0 : selectedFacilities.includes(f.id), onChange: function () { } }),
                        React.createElement(MenuItemTxt, { style: { marginLeft: 8, color: theme.colors.text.base } }, f.name)));
                })),
                filteredMenuItems.length > 10 && (React.createElement(Pagination, { page: currentPage, limit: itemsperPage, total: filteredMenuItems.length, onPageChange: function (page) { return setCurrentPage(page); }, buttonNavigation: true, size: "small", justifyContent: "end" })),
                React.createElement(ActionsContainer, null,
                    React.createElement(Button, { variant: "primary", size: "xlarge", onClick: function () { return onConfirm(selectedFacilities); }, disabled: !((selectedFacilities === null || selectedFacilities === void 0 ? void 0 : selectedFacilities.length) > 0 ||
                            (facilitiesSelected === null || facilitiesSelected === void 0 ? void 0 : facilitiesSelected.length) > 0) }, "Update Selection"),
                    React.createElement(Button, { variant: isMobile ? 'tertiary' : 'outlined', size: "xlarge", onClick: function () { return closeModal(); } }, "Cancel"))))));
};
var ContentContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  width: 100%;\n  & > div:first-of-type {\n    display: flex;\n    flex-direction: column;\n    flex: 1;\n    gap: 16px;\n    & > div:last-of-type {\n      margin-top: 66px;\n    }\n  }\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column-reverse;\n    align-items: stretch;\n    & > div:first-of-type {\n      justify-content: flex-end;\n      & > span {\n        text-align: center;\n      }\n    }\n  }\n"], ["\n  display: flex;\n  flex: 1;\n  width: 100%;\n  & > div:first-of-type {\n    display: flex;\n    flex-direction: column;\n    flex: 1;\n    gap: 16px;\n    & > div:last-of-type {\n      margin-top: 66px;\n    }\n  }\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column-reverse;\n    align-items: stretch;\n    & > div:first-of-type {\n      justify-content: flex-end;\n      & > span {\n        text-align: center;\n      }\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var ActionsContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row-reverse;\n  justify-content: end;\n  align-items: center;\n  gap: 12px;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n    & > button {\n      width: 100%;\n    }\n  }\n"], ["\n  display: flex;\n  flex-direction: row-reverse;\n  justify-content: end;\n  align-items: center;\n  gap: 12px;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n    & > button {\n      width: 100%;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var MenuItm = styled(MenuItem)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border-bottom: 1px solid rgba(128, 128, 128, 0.2);\n  padding: 19.5px 16px;\n  margin-bottom: 8px;\n  font-weight: 500;\n"], ["\n  border-bottom: 1px solid rgba(128, 128, 128, 0.2);\n  padding: 19.5px 16px;\n  margin-bottom: 8px;\n  font-weight: 500;\n"])));
var MenuItemTxt = styled(MenuItemText)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-weight: 500;\n"], ["\n  font-weight: 500;\n"])));
var StyledDropdown = styled(Dropdown)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 160px !important;\n  @media only screen and (max-width: ", "px) {\n    width: 150px;\n  }\n  @media only screen and (max-width: ", "px) {\n    width: 150px;\n  }\n"], ["\n  width: 160px !important;\n  @media only screen and (max-width: ", "px) {\n    width: 150px;\n  }\n  @media only screen and (max-width: ", "px) {\n    width: 150px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
}, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.lg;
});
var StyledSearchInputContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
var SearchAndDropdownContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  gap: 8px;\n  @media only screen and (max-width: ", "px) {\n    gap: 0px;\n  }\n"], ["\n  display: flex;\n  gap: 8px;\n  @media only screen and (max-width: ", "px) {\n    gap: 0px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var SearchIcon = styled(Icon)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  position: relative;\n  right: 30px;\n  bottom: 8px;\n  color: red;\n  /* search close */\n  ", "\n"], ["\n  position: relative;\n  right: 30px;\n  bottom: 8px;\n  color: red;\n  /* search close */\n  ", "\n"])), function (props) { return (props.searchTerm ? '' : 'color: inherit;'); });
var CloseIcon = styled(Icon)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  /* Styles for CloseIcon component */\n  position: relative;\n  right: 30px;\n  bottom: 8px;\n  color: red;\n"], ["\n  /* Styles for CloseIcon component */\n  position: relative;\n  right: 30px;\n  bottom: 8px;\n  color: red;\n"])));
var StyledSearchInput = styled.input(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  width: 300px;\n  font-size: 14px;\n  line-height: 24px;\n  padding: 12px 20px;\n  border: 1px solid #cfcfcf;\n  border-radius: 40px;\n  box-sizing: border-box;\n  height: 48px;\n  margin-bottom: 16px;\n  outline-color: #cfcfcf;\n  @media only screen and (max-width: ", "px) {\n    width: 150px;\n  }\n  @media only screen and (max-width: ", "px) {\n    width: 150px;\n  }\n"], ["\n  width: 300px;\n  font-size: 14px;\n  line-height: 24px;\n  padding: 12px 20px;\n  border: 1px solid #cfcfcf;\n  border-radius: 40px;\n  box-sizing: border-box;\n  height: 48px;\n  margin-bottom: 16px;\n  outline-color: #cfcfcf;\n  @media only screen and (max-width: ", "px) {\n    width: 150px;\n  }\n  @media only screen and (max-width: ", "px) {\n    width: 150px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
}, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.lg;
});
var CustomDropDown = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  width: 160px;\n"], ["\n  width: 160px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Typography, Desktop, Mobile, Icon } from 'designSystem';
import { LargeInput } from 'designSystem/components/LargeInput/LargeInput';
import React from 'react';
import styled from 'styled-components';
import { useScreenSizing } from 'hooks';
import jeaveauVial from 'assets/jeuveau-shadow.png';
var grid = [
    {
        value: 1,
    },
    {
        value: 2,
    },
    {
        value: 3,
    },
    {
        value: 4,
    },
    {
        value: 5,
    },
    {
        value: 6,
    },
    {
        value: 7,
    },
    {
        value: 8,
    },
    {
        value: 9,
    },
    {
        value: null,
    },
    {
        value: 0,
    },
    {
        value: null,
        clear: true,
    },
];
export var AlACarteSelector = function (_a) {
    var msrp = _a.msrp, defaultRate = _a.defaultRate, _b = _a.selectedQuantity, selectedQuantity = _b === void 0 ? '' : _b, setSelectedQuantity = _a.setSelectedQuantity;
    var isMobile = useScreenSizing().isMobile;
    var handleQuantitySelect = function (quantity) {
        if (!quantity) {
            setSelectedQuantity(undefined);
            return;
        }
        var newQuantity = +quantity.toString().replace(/\D/g, '');
        setSelectedQuantity(Math.ceil(newQuantity));
    };
    var chunks = grid.reduce(function (resultArray, item, index) {
        var chunkIndex = Math.floor(index / 3);
        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [];
        }
        resultArray[chunkIndex].push(item);
        return resultArray;
    }, []);
    var onGridClick = function (grid) {
        if (grid.value || typeof grid.value === 'number') {
            if (selectedQuantity === '0' || !selectedQuantity) {
                handleQuantitySelect(grid.value);
            }
            else {
                var newQuantity = parseInt([selectedQuantity, grid.value].join(''));
                handleQuantitySelect(newQuantity);
            }
            return;
        }
        if (grid.clear) {
            var clearedQuantity = parseInt("".concat(selectedQuantity).slice(0, -1));
            handleQuantitySelect(isNaN(clearedQuantity) ? undefined : clearedQuantity);
        }
    };
    var getPadText = function (grid) {
        if (grid.value || grid.value === 0) {
            return React.createElement(Typography, { variant: "titleLarge" }, grid.value);
        }
        if (grid.clear) {
            return React.createElement(Icon, { name: "clear" });
        }
        return null;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(QuantitySelectionContainer, null,
            React.createElement(ContainerBody, null,
                React.createElement(JeaveauVialImageContainer, null,
                    React.createElement(JeaveauVialImage, { src: jeaveauVial })),
                React.createElement(PricingInfoContainer, null,
                    React.createElement(PricingInfoInnerContainer, null,
                        React.createElement(Typography, { variant: "caption", color: "alt5" }, "YOUR PRICE:"),
                        React.createElement(Typography, { variant: isMobile ? 'caption' : 'headline', color: "link", style: { position: 'relative', marginTop: 4 }, "data-automation-id": "order-a-la-carte-price" },
                            React.createElement(Mobile, null,
                                React.createElement(DollarSign, null, "$")),
                            React.createElement(Typography, { variant: !isMobile ? 'displayLarge' : 'titleLarge', color: "link" },
                                React.createElement(Desktop, null, "$"),
                                defaultRate),
                            ' ',
                            "/ 100 unit vial"),
                        React.createElement(Typography, { variant: "title", color: "alt5", mt: 4 },
                            "List Price: ",
                            React.createElement(CrossOut, null,
                                "$",
                                msrp))),
                    React.createElement(LargeInput, { maxLength: 3, value: selectedQuantity, onChange: function (value) { return handleQuantitySelect(+value); }, label: "Vials", placeholder: "0", readOnly: isMobile, "data-automation-id": "order-a-la-carte-quantity" })))),
        React.createElement(Mobile, null,
            React.createElement(Typography, { variant: "footnote", align: "center", mt: 32, mb: 12 }, "Order by 4pm PST to ship same day (M \u2014 Th)"),
            React.createElement(Pad, null, chunks.map(function (chunk) { return (React.createElement(PadRow, null, chunk.map(function (entry) { return (React.createElement(PadKey, { onClick: function () { return onGridClick(entry); } }, getPadText(entry))); }))); })))));
};
var Pad = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
var PadRow = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  margin-bottom: 8px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  margin-bottom: 8px;\n"])));
var PadKey = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 8px 42px;\n  background: ", ";\n\n  border: 1px solid ", ";\n  width: 99px;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 8px 42px;\n  background: ", ";\n\n  border: 1px solid ", ";\n  width: 99px;\n"])), function (props) { return props.theme.colors.bg.alt3; }, function (props) { return props.theme.colors.border.base; });
var QuantitySelectionContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  background-color: ", ";\n\n  padding: 64px;\n  border: 1px solid ", ";\n\n  @media only screen and (max-width: ", "px) {\n    border: none;\n    background-color: transparent;\n    padding: 0;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  background-color: ", ";\n\n  padding: 64px;\n  border: 1px solid ", ";\n\n  @media only screen and (max-width: ", "px) {\n    border: none;\n    background-color: transparent;\n    padding: 0;\n  }\n"])), function (props) { return props.theme.colors.bg.alt3; }, function (props) { return props.theme.colors.border.base; }, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var JeaveauVialImageContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: white;\n  margin-right: 68px;\n\n  @media only screen and (min-width: ", "px) {\n    padding: 28px 98px;\n\n    ", ";\n  }\n\n  @media only screen and (max-width: ", "px) {\n    margin-right: 44px;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: white;\n  margin-right: 68px;\n\n  @media only screen and (min-width: ", "px) {\n    padding: 28px 98px;\n\n    ", ";\n  }\n\n  @media only screen and (max-width: ", "px) {\n    margin-right: 44px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
}, function (props) { return props.theme.elevation.base.elevation1; }, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var JeaveauVialImage = styled.img(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 84px;\n\n  @media only screen and (max-width: ", "px) {\n    margin: 0;\n    width: 76px;\n  }\n"], ["\n  width: 84px;\n\n  @media only screen and (max-width: ", "px) {\n    margin: 0;\n    width: 76px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var PricingInfoContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n"])));
var PricingInfoInnerContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 28px;\n\n  @media only screen and (max-width: ", "px) {\n    margin-bottom: 20px;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 28px;\n\n  @media only screen and (max-width: ", "px) {\n    margin-bottom: 20px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var DollarSign = styled.span(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  position: relative;\n  top: -7px;\n"], ["\n  position: relative;\n  top: -7px;\n"])));
var CrossOut = styled.span(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  text-decoration: line-through;\n"], ["\n  text-decoration: line-through;\n"])));
var ContainerBody = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  display: flex;\n"], ["\n  display: flex;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components';
var CardImageContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: auto;\n  ", "\n  ", "\n    ", "\n    ", "\n"], ["\n  width: 100%;\n  height: auto;\n  ", "\n  ", "\n    ", "\n    ", "\n"])), function (_a) {
    var marginRight = _a.marginRight;
    return (marginRight ? "padding-right: 12px" : "");
}, function (_a) {
    var marginLeft = _a.marginLeft;
    return (marginLeft ? "padding-left: 12px" : "");
}, function (_a) {
    var marginTop = _a.marginTop;
    return (marginTop ? "padding-top: 12px" : "");
}, function (_a) {
    var marginBottom = _a.marginBottom;
    return (marginBottom ? "padding-bottom: 12px" : "");
});
var CardImageComponent = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  height: auto;\n"], ["\n  width: 100%;\n  height: auto;\n"])));
export var CardImage = function (_a) {
    var marginRight = _a.marginRight, marginBottom = _a.marginBottom, marginLeft = _a.marginLeft, marginTop = _a.marginTop, props = __rest(_a, ["marginRight", "marginBottom", "marginLeft", "marginTop"]);
    return (React.createElement(CardImageContainer, { marginRight: marginRight, marginBottom: marginBottom, marginLeft: marginLeft, marginTop: marginTop },
        React.createElement(CardImageComponent, __assign({}, props))));
};
CardImageContainer.displayName = 'CardImg';
export default CardImage;
var templateObject_1, templateObject_2;

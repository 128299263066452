var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useEffect } from 'react';
import styled from 'styled-components';
import CheckInAgreementGraphic from 'assets/check-in-graphic-5.svg';
import { Typography } from 'designSystem';
import { useModal, useUser } from 'context';
import { StepContainer } from '../../StepContainer';
import CheckInProgress from '../../CheckInProgress';
import { useNavigate } from 'react-router';
import { StepEnum } from '../../../Enums/StepEnum';
import { OptInStatus } from 'types/codegen/hooks';
import ModalTypeEnum from 'types/enums/ModalTypeEnum';
import { useScreenSizing } from 'hooks';
export var CheckInAgreement = function (_a) {
    var rewardAmount = _a.rewardAmount, handleRestart = _a.handleRestart, optInStatus = _a.optInStatus;
    var navigate = useNavigate();
    var isMobile = useScreenSizing().isMobile;
    var openModal = useModal().openModal;
    var facility = useUser().facility;
    useEffect(function () {
        if (optInStatus === OptInStatus.OptedOut) {
            openModal(ModalTypeEnum.SMS_OPTED_OUT, {});
        }
    }, [optInStatus, openModal]);
    return (React.createElement(React.Fragment, null,
        React.createElement(StepContainer, { handleRestart: handleRestart, label: "Check-In Agreement", headerText: "Only patients that have been treated with Evolus Product are eligible to receive a $".concat(rewardAmount, " discount from their treatment."), subHeader: "Please confirm this is accurate before proceeding." },
            React.createElement(OuterContainer, null,
                React.createElement(LeftContainer, null,
                    React.createElement(Typography, { variant: "titleLarge", color: "link" },
                        "This patient has received Evolus Product today at ", facility === null || facility === void 0 ? void 0 :
                        facility.name,
                        ".")),
                React.createElement(LeftContainer, null,
                    React.createElement(Image, { src: CheckInAgreementGraphic })))),
        React.createElement(CheckInProgress, { primaryButtonText: "Confirm and Proceed", onContinue: function () {
                navigate(StepEnum.SendMagicPhrase);
            }, tertiaryButtonText: isMobile && 'Restart Check In', tertiaryAction: handleRestart })));
};
var OuterContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  background-color: ", ";\n\n  padding: 40px;\n  @media screen and (max-width: ", "px) {\n    padding: 0;\n    flex-direction: column-reverse;\n    background: transparent;\n    margin-bottom: 24px;\n  }\n"], ["\n  display: flex;\n  background-color: ", ";\n\n  padding: 40px;\n  @media screen and (max-width: ", "px) {\n    padding: 0;\n    flex-direction: column-reverse;\n    background: transparent;\n    margin-bottom: 24px;\n  }\n"])), function (props) { return props.theme.colors.bg.base; }, function (props) { return props.theme.breakpoints.md; });
var LeftContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  justify-content: center;\n  align-items: center;\n"], ["\n  display: flex;\n  flex: 1;\n  justify-content: center;\n  align-items: center;\n"])));
var Image = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  min-height: 100%;\n  width: 100%;\n  @media screen and (max-width: ", "px) {\n    margin: 16px 0 32px;\n  }\n"], ["\n  min-height: 100%;\n  width: 100%;\n  @media screen and (max-width: ", "px) {\n    margin: 16px 0 32px;\n  }\n"])), function (props) { return props.theme.breakpoints.md; });
var templateObject_1, templateObject_2, templateObject_3;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { format } from 'date-fns';
import download from 'downloadjs';
import { useLazyQuery } from '@apollo/client';
import pdfQuery from '../../getPDFQuery.graphql';
import { useUser } from '../../../../../../context';
import { PDFDownloadButtonView } from './View';
export var PDFDownloadButtonContainer = function (_a) {
    var date = _a.date;
    var _b = useUser(), account = _b.account, facility = _b.facility, user = _b.user;
    var _c = __read(useLazyQuery(pdfQuery), 2), getPDFStatement = _c[0], pdfResult = _c[1];
    var onGetPDFStatement = function () { return __awaiter(void 0, void 0, void 0, function () {
        var payload, response, statementBuffer, bytes, blob;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    payload = {
                        accountId: account.id,
                        statementDate: format(date, 'yyyy-MM'),
                        userId: user.id,
                        userFirstName: user.firstName,
                        userLastName: user.lastName,
                        facilityId: facility === null || facility === void 0 ? void 0 : facility.id,
                    };
                    return [4, getPDFStatement({
                            variables: {
                                input: payload,
                            },
                        })];
                case 1:
                    response = _d.sent();
                    statementBuffer = (_c = (_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.getTransactionHistoryStatement) === null || _b === void 0 ? void 0 : _b.imageBuffer) !== null && _c !== void 0 ? _c : null;
                    if (!statementBuffer) {
                        return [2];
                    }
                    bytes = new Uint8Array(statementBuffer.Body.data);
                    blob = new Blob([bytes], {
                        type: statementBuffer.ContentType,
                    });
                    download(blob, "statement.pdf", statementBuffer.ContentType);
                    return [2];
            }
        });
    }); };
    return (React.createElement(PDFDownloadButtonView, { loading: pdfResult.loading, onDownload: onGetPDFStatement }));
};

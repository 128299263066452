var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from 'styled-components';
import { colors } from './colors';
export var borders = {
    base: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    border: 1px solid ", ";\n  "], ["\n    border: 1px solid ", ";\n  "])), colors.bg.alt),
    alt2: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    border: 1px solid ", ";\n  "], ["\n    border: 1px solid ", ";\n  "])), colors.border.alt2),
    selected: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    border: 1px solid ", ";\n  "], ["\n    border: 1px solid ", ";\n  "])), colors.coral.coral100),
};
var templateObject_1, templateObject_2, templateObject_3;

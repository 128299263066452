import React from 'react';
import './largeinput.css';
import { Typography } from '../Typography';
export var LargeInput = function (_a) {
    var _b = _a.label, label = _b === void 0 ? 'Placeholder Label' : _b, _c = _a.disabled, disabled = _c === void 0 ? false : _c, _d = _a.placeholder, placeholder = _d === void 0 ? '0' : _d, value = _a.value, readOnly = _a.readOnly, onChange = _a.onChange, maxLength = _a.maxLength, automationId = _a["data-automation-id"];
    return (React.createElement("div", { className: "storybook-large-input--outer-container" },
        React.createElement("div", { className: "storybook-large-input--input-container" },
            React.createElement("div", { style: { flexDirection: 'column', display: 'flex' } },
                React.createElement("input", { className: "storybook-large-input-base ", disabled: disabled, placeholder: placeholder, value: value, onChange: function (e) {
                        return e.target.value.length <= maxLength && onChange(e.target.value);
                    }, readOnly: readOnly, "data-automation-id": automationId }),
                React.createElement("div", { className: "storybook-large-input--base-underline " }))),
        label && (React.createElement(Typography, { variant: "title", color: "alt", style: { marginLeft: 16 }, "data-automation-id": automationId }, label))));
};

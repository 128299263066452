var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useMemo, useState } from 'react';
import { Modal } from '../Modal';
import { Button, Icon, Input, Typography } from 'designSystem';
import { SensitivePaymentType } from 'types/codegen/hooks';
import { useModal } from '../../../context';
import styled from 'styled-components';
import { useScreenSizing } from '../../../hooks';
export var EditPaymentMethodModalView = function (_a) {
    var paymentMethod = _a.paymentMethod, onSubmit = _a.onSubmit, loading = _a.loading;
    var closeModal = useModal().closeModal;
    var isMobile = useScreenSizing().isMobile;
    var _b = __read(useState(paymentMethod.name), 2), name = _b[0], setName = _b[1];
    var _c = useMemo(function () {
        var _a = paymentMethod.sensitive, type = _a.type, cc = _a.cc, ach = _a.ach;
        if (type === SensitivePaymentType.Cc) {
            return {
                secondaryDetails: {
                    label: 'Card Number',
                    value: cc.number,
                },
                tertiaryDetails: {
                    label: 'Expires',
                    value: "".concat(cc.expMonth, "/").concat(cc.expYear),
                },
            };
        }
        return {
            secondaryDetails: {
                label: 'Routing Number',
                value: ach.routingNumber,
            },
            tertiaryDetails: {
                label: 'Account Number',
                value: ach.accountNumber,
            },
        };
    }, [paymentMethod]), secondaryDetails = _c.secondaryDetails, tertiaryDetails = _c.tertiaryDetails;
    var isCC = paymentMethod.sensitive.type === SensitivePaymentType.Cc;
    return (React.createElement(Modal, null,
        React.createElement(ContentContainer, null,
            React.createElement(Typography, { variant: "display", align: "center" }, "Edit Payment Name"),
            React.createElement(InputsContainer, { isCC: isCC },
                React.createElement(Input, { variant: "outline", fullWidth: true, onChange: function (_a) {
                        var target = _a.target;
                        setName(target.value);
                    }, value: name, label: "Name" }),
                React.createElement(Input, { variant: "outline", disabled: true, fullWidth: true, value: secondaryDetails.value, label: secondaryDetails.label }),
                React.createElement(Input, { variant: "outline", disabled: true, value: tertiaryDetails.value, label: tertiaryDetails.label })),
            React.createElement(ActionsContainer, null,
                React.createElement(Button, { size: "xlarge", endIcon: loading && React.createElement(Icon, { color: "inherit", name: "loading" }), disabled: loading, variant: "primary", onClick: function () {
                        onSubmit({
                            id: paymentMethod.paymentMethodId,
                            name: name,
                        });
                    } }, "Update Card"),
                React.createElement(Button, { size: "xlarge", variant: isMobile ? 'tertiary' : 'outlined', onClick: closeModal }, "Cancel")))));
};
var ContentContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  justify-content: flex-start;\n"], ["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  justify-content: flex-start;\n"])));
var InputsContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n  margin-top: 48px;\n  & > div:last-of-type {\n    width: 100%;\n    max-width: ", ";\n  }\n\n  @media only screen and (max-width: ", "px) {\n    & > div:last-of-type {\n      width: 100%;\n      max-width: 100%;\n    }\n  }\n"], ["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n  margin-top: 48px;\n  & > div:last-of-type {\n    width: 100%;\n    max-width: ", ";\n  }\n\n  @media only screen and (max-width: ", "px) {\n    & > div:last-of-type {\n      width: 100%;\n      max-width: 100%;\n    }\n  }\n"])), function (props) { return (props.isCC ? '50%' : '100%'); }, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var ActionsContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-top: 64px;\n  width: 100%;\n  display: flex;\n  flex-direction: row-reverse;\n  align-items: center;\n  justify-content: flex-start;\n  gap: 12px;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n    flex: 1;\n    justify-content: flex-end;\n    & > button {\n      width: 100%;\n    }\n  }\n"], ["\n  margin-top: 64px;\n  width: 100%;\n  display: flex;\n  flex-direction: row-reverse;\n  align-items: center;\n  justify-content: flex-start;\n  gap: 12px;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n    flex: 1;\n    justify-content: flex-end;\n    & > button {\n      width: 100%;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var templateObject_1, templateObject_2, templateObject_3;

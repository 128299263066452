var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { Typography } from 'designSystem';
import styled from 'styled-components';
export var EmailMessageBlock = function (_a) {
    var message = _a.message, _b = _a.fontSize, fontSize = _b === void 0 ? 'normal' : _b;
    return (React.createElement(EmailMessageContainer, null,
        React.createElement(Typography, { variant: fontSize === 'normal' ? 'footnote' : 'headline' }, message)));
};
var EmailMessageContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  background: ", ";\n  padding: 32px;\n"], ["\n  ", ";\n  background: ", ";\n  padding: 32px;\n"])), function (props) { return props.theme.borders.base; }, function (props) { return props.theme.colors.bg.alt3; });
var templateObject_1;

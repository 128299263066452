var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import { Dropdown, PlaceholderLoading } from 'designSystem';
import { StepContainer } from '../../StepContainer';
import styled from 'styled-components';
import CheckInProgress from '../../CheckInProgress';
import { useNavigate } from 'react-router';
import { StepEnum } from '../../../Enums/StepEnum';
import { useScreenSizing } from 'hooks';
export var SpecialistSelectView = function (_a) {
    var injectors = _a.injectors, initialVal = _a.initialVal, onSelect = _a.onSelect, loading = _a.loading, handleRestart = _a.handleRestart;
    var navigate = useNavigate();
    var _b = __read(useState(initialVal !== null && initialVal !== void 0 ? initialVal : null), 2), selectedInjector = _b[0], setSelectedInjector = _b[1];
    var _c = __read(useState([]), 2), options = _c[0], setOptions = _c[1];
    var isMobile = useScreenSizing().isMobile;
    useEffect(function () {
        if (!(injectors === null || injectors === void 0 ? void 0 : injectors.length)) {
            setOptions([]);
            return;
        }
        setOptions(injectors.map(function (injector) { return ({
            value: injector === null || injector === void 0 ? void 0 : injector.uniqueId,
            label: injector === null || injector === void 0 ? void 0 : injector.name,
        }); }));
    }, [injectors]);
    var handleSelect = function (_a) {
        var label = _a.label, value = _a.value;
        setSelectedInjector({
            name: label,
            uniqueId: value,
        });
    };
    var handleCreate = function (name) {
        var newOption = {
            value: undefined,
            label: name,
        };
        var newOptions = __spreadArray(__spreadArray([], __read(options.filter(function (i) { return !!i.value; })), false), [newOption], false);
        setOptions(newOptions);
        handleSelect(newOption);
    };
    var renderContent = function () {
        if (loading) {
            return React.createElement(PlaceholderLoading, { size: "extra-small" });
        }
        return (React.createElement(OuterContainer, null,
            React.createElement(Dropdown, { searchable: true, selected: selectedInjector, formatAddLabel: "Add new Specialist", label: "Specialists", placeholder: "Specialist Name", options: options, onAddOption: function (value) { return handleCreate(value); }, onSelect: handleSelect }),
            React.createElement(FooterContainer, null,
                "Don't see the specialist you're looking for?",
                ' ',
                React.createElement(Link, { href: "#", target: "_blank", rel: "noopener noreferrer" }, "Add them to the Evolus directory."))));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(StepContainer, { handleRestart: handleRestart, label: "Select Specialist", headerText: "Select the specialist who provided this treatment." }, renderContent()),
        React.createElement(CheckInProgress, { disabled: !selectedInjector, isLoading: loading, primaryButtonText: "Select Specialist", secondaryButtonText: "Skip this Step", onContinue: function () {
                onSelect(selectedInjector);
                navigate(StepEnum.CheckInAgreement);
            }, onSecondaryContinue: function () {
                navigate(StepEnum.CheckInAgreement);
            }, tertiaryButtonText: isMobile && 'Restart Check In', tertiaryAction: handleRestart })));
};
var OuterContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 28px;\n  display: flex;\n  flex-direction: column;\n  @media screen and (max-width: ", "px) {\n    margin-bottom: auto;\n  }\n"], ["\n  margin-top: 28px;\n  display: flex;\n  flex-direction: column;\n  @media screen and (max-width: ", "px) {\n    margin-bottom: auto;\n  }\n"])), function (props) { return props.theme.breakpoints.md; });
var FooterContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  margin-top: 16px;\n  @media screen and (max-width: ", "px) {\n    flex-direction: column;\n  }\n"], ["\n  display: flex;\n  margin-top: 16px;\n  @media screen and (max-width: ", "px) {\n    flex-direction: column;\n  }\n"])), function (props) { return props.theme.breakpoints.md; });
var Link = styled.a(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  all: unset;\n  cursor: pointer;\n  color: ", ";\n  margin-left: 10px;\n  &:hover {\n    text-decoration: underline;\n  }\n  @media screen and (max-width: ", "px) {\n    margin-left: 0;\n  }\n"], ["\n  all: unset;\n  cursor: pointer;\n  color: ", ";\n  margin-left: 10px;\n  &:hover {\n    text-decoration: underline;\n  }\n  @media screen and (max-width: ", "px) {\n    margin-left: 0;\n  }\n"])), function (props) { return props.theme.colors.text.link; }, function (props) { return props.theme.breakpoints.md; });
var templateObject_1, templateObject_2, templateObject_3;

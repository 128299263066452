import React from 'react';
export var EvoluxMarketing = function (_a) {
    var color = _a.color;
    return (React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0_358_9128)" },
            React.createElement("path", { d: "M4.375 18.125C4.81533 18.1399 5.25207 18.2099 5.675 18.3333C9.00833 19.2833 9.19167 19.3917 10.075 19.3917H14.1667C15.4105 19.3979 16.612 18.9403 17.5365 18.1082C18.4611 17.2761 19.0423 16.1293 19.1667 14.8917L19.4167 10.6417C19.4617 9.77436 19.2036 8.91839 18.6867 8.22052C18.1697 7.52264 17.4261 7.02633 16.5833 6.81667C15.6833 6.59167 14.425 6.59167 14.425 5.33333V2.5C14.425 2.00272 14.2275 1.52581 13.8758 1.17417C13.5242 0.822544 13.0473 0.625 12.55 0.625C12.0527 0.625 11.5758 0.822544 11.2242 1.17417C10.8725 1.52581 10.675 2.00272 10.675 2.5C10.675 2.58333 11.225 10.0417 4.425 10.0417L4.375 18.125Z", stroke: color, strokeWidth: "1.5625", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { d: "M4.375 8.125H0.625V19.375H4.375V8.125Z", stroke: color, strokeWidth: "1.5625", strokeLinecap: "round", strokeLinejoin: "round" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_358_9128" },
                React.createElement("rect", { width: "20", height: "20", fill: "white" })))));
};

import React from 'react';
import { useLocation } from 'react-router';
import { IntercomButtonView } from './View';
var noShowRoutes = new Set(['evolux']);
export var RoutedIntercomButton = function () {
    var location = useLocation();
    var mainRouteDir = location.pathname.split('/')[1];
    if (noShowRoutes.has(mainRouteDir)) {
        return null;
    }
    return React.createElement(IntercomButtonView, null);
};

import React from 'react';
export var EvoluxBoost = function (_a) {
    var color = _a.color;
    return (React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0_358_8920)" },
            React.createElement("path", { d: "M5.83398 16.876L7.08398 6.87598H12.0832L13.334 16.876", stroke: color, strokeWidth: "1.25", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { d: "M12.084 6.87563H7.08398V5.77563C7.08412 4.99954 7.26471 4.23411 7.61148 3.5398L8.83898 1.08397C8.90827 0.945733 9.01464 0.8295 9.14621 0.748272C9.27778 0.667044 9.42936 0.624023 9.58398 0.624023C9.73861 0.624023 9.89018 0.667044 10.0218 0.748272C10.1533 0.8295 10.2597 0.945733 10.329 1.08397L11.5565 3.5373C11.9036 4.23237 12.0842 4.9987 12.084 5.77563V6.87563Z", stroke: color, strokeWidth: "1.25", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { d: "M10.6145 18.4429C10.5893 18.6988 10.4698 18.9361 10.2792 19.1088C10.0887 19.2814 9.84078 19.3771 9.58366 19.3771C9.32654 19.3771 9.07861 19.2814 8.88808 19.1088C8.69756 18.9361 8.57806 18.6988 8.55283 18.4429L8.17699 14.6804C8.15324 14.4816 8.17191 14.28 8.23175 14.0889C8.2916 13.8979 8.39126 13.7217 8.52418 13.5719C8.6571 13.4222 8.82025 13.3023 9.00287 13.2202C9.18549 13.1381 9.38344 13.0957 9.58366 13.0957C9.78388 13.0957 9.98183 13.1381 10.1644 13.2202C10.3471 13.3023 10.5102 13.4222 10.6431 13.5719C10.7761 13.7217 10.8757 13.8979 10.9356 14.0889C10.9954 14.28 11.0141 14.4816 10.9903 14.6804L10.6145 18.4429Z", stroke: color, strokeWidth: "1.25", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { d: "M6.29732 13.1673C5.93599 13.0992 5.56407 13.1116 5.20808 13.2036C4.85209 13.2957 4.52078 13.4652 4.23778 13.6999C3.95479 13.9347 3.72706 14.229 3.57084 14.5619C3.41462 14.8947 3.33374 15.258 3.33398 15.6257L3.81732 18.5273C3.85854 18.7792 3.99311 19.0062 4.19423 19.1633C4.39536 19.3204 4.64826 19.3959 4.90259 19.3749C5.15691 19.3539 5.39399 19.2379 5.56659 19.0499C5.7392 18.8619 5.83467 18.6159 5.83399 18.3607V16.8757H8.39648", stroke: color, strokeWidth: "1.25", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { d: "M12.8707 13.1673C13.232 13.0992 13.6039 13.1116 13.9599 13.2036C14.3159 13.2957 14.6472 13.4652 14.9302 13.6999C15.2132 13.9347 15.4409 14.229 15.5971 14.5619C15.7534 14.8947 15.8342 15.258 15.834 15.6257L15.3507 18.5273C15.3094 18.7792 15.1749 19.0062 14.9737 19.1633C14.7726 19.3204 14.5197 19.3959 14.2654 19.3749C14.0111 19.3539 13.774 19.2379 13.6014 19.0499C13.4288 18.8619 13.3333 18.6159 13.334 18.3607V16.8757H10.7715", stroke: color, strokeWidth: "1.25", strokeLinecap: "round", strokeLinejoin: "round" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_358_8920" },
                React.createElement("rect", { width: "20", height: "20", fill: "white" })))));
};

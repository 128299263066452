var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled, { css } from 'styled-components';
import { Typography } from 'designSystem';
import { useScreenSizing } from '../../../../../../../hooks';
import { addMobileStyles } from '../../../../../../../utils/addMobileStyles';
export var MakePaymentOption = function (_a) {
    var title = _a.title, endLabel = _a.endLabel, selected = _a.selected, onSelect = _a.onSelect, disabled = _a.disabled;
    var isMobile = useScreenSizing().isMobile;
    var renderContent = function () {
        if (isMobile) {
            return (React.createElement(React.Fragment, null,
                React.createElement(RadioIcon, { active: selected }),
                React.createElement("div", null,
                    React.createElement(Typography, { variant: "headline" }, title),
                    endLabel && (React.createElement(Typography, { variant: "headlineSmall", color: "alt" }, endLabel)))));
        }
        return (React.createElement(React.Fragment, null,
            React.createElement("div", null,
                React.createElement(RadioIcon, { active: selected }),
                React.createElement(Typography, { variant: "headlineSmall" }, title)),
            endLabel && (React.createElement(Typography, { variant: "headlineSmall" }, endLabel))));
    };
    return (React.createElement(Container, { disabled: !!disabled, active: selected, onClick: onSelect }, renderContent()));
};
var Container = styled.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  background: ", ";\n  padding: 20px 24px;\n  display: flex;\n  align-items: center;\n  flex: 1;\n  gap: 20px;\n  ", "\n  :hover {\n    cursor: pointer;\n  }\n  :disabled {\n    cursor: not-allowed;\n  }\n  & > div {\n    display: flex;\n    align-items: center;\n    gap: 16px;\n    flex: 1;\n  }\n  ", "\n"], ["\n  ", ";\n  background: ", ";\n  padding: 20px 24px;\n  display: flex;\n  align-items: center;\n  flex: 1;\n  gap: 20px;\n  ", "\n  :hover {\n    cursor: pointer;\n  }\n  :disabled {\n    cursor: not-allowed;\n  }\n  & > div {\n    display: flex;\n    align-items: center;\n    gap: 16px;\n    flex: 1;\n  }\n  ", "\n"])), function (props) { return props.theme.borders.base; }, function (props) { return props.theme.colors.bg.body; }, function (props) {
    return props.active && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      border: 2px solid ", ";\n    "], ["\n      border: 2px solid ", ";\n    "])), function (props) { return props.theme.colors.border.error2; });
}, addMobileStyles(css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    padding: 16px 20px;\n    & > div:first-of-type {\n      display: grid;\n      gap: unset;\n      flex: none;\n    }\n    & > div:last-of-type {\n      display: flex;\n      flex-direction: column;\n      gap: unset;\n      align-items: flex-start;\n    }\n  "], ["\n    padding: 16px 20px;\n    & > div:first-of-type {\n      display: grid;\n      gap: unset;\n      flex: none;\n    }\n    & > div:last-of-type {\n      display: flex;\n      flex-direction: column;\n      gap: unset;\n      align-items: flex-start;\n    }\n  "])))));
var RadioIcon = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 16px;\n  height: 16px;\n  border-radius: 50%;\n  display: grid;\n  align-items: center;\n  justify-items: center;\n  ", ";\n  background: ", ";\n  :before {\n    content: '';\n    width: ", ";\n    height: ", ";\n    border-radius: 50%;\n    background-color: ", ";\n    grid-column: 1;\n    grid-row: 1;\n    transition: width 0.2s ease-in, height 0.2s ease-in;\n  }\n  :after {\n    content: '';\n    display: ", ";\n    width: ", ";\n    height: ", ";\n    border-radius: 50%;\n    background-color: ", ";\n    grid-column: 1;\n    grid-row: 1;\n    transition: width 0.2s ease-in, height 0.2s ease-in;\n  }\n"], ["\n  width: 16px;\n  height: 16px;\n  border-radius: 50%;\n  display: grid;\n  align-items: center;\n  justify-items: center;\n  ", ";\n  background: ", ";\n  :before {\n    content: '';\n    width: ", ";\n    height: ", ";\n    border-radius: 50%;\n    background-color: ", ";\n    grid-column: 1;\n    grid-row: 1;\n    transition: width 0.2s ease-in, height 0.2s ease-in;\n  }\n  :after {\n    content: '';\n    display: ", ";\n    width: ", ";\n    height: ", ";\n    border-radius: 50%;\n    background-color: ", ";\n    grid-column: 1;\n    grid-row: 1;\n    transition: width 0.2s ease-in, height 0.2s ease-in;\n  }\n"])), function (props) { return props.theme.borders.alt2; }, function (props) { return props.theme.colors.bg.body; }, function (props) { return (props.active ? '12px' : '0'); }, function (props) { return (props.active ? '12px' : '0'); }, function (props) { return props.theme.colors.bg.accent; }, function (props) { return (props.active ? 'default' : 'none'); }, function (props) { return (props.active ? '4px' : '0'); }, function (props) { return (props.active ? '4px' : '0'); }, function (props) { return props.theme.colors.bg.body; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import styled, { useTheme } from 'styled-components';
import { stackedCardStyles } from '../../../RewardsDashboard/components/StackedMobileCard';
import { Button, Icon, IconButton, LoadingBubbles, Pagination, Typography, } from '../../../../../../designSystem';
import { TransactionDetailItem } from '../TransactionDetailItem';
import getCSVQuery from './getCSVQuery.graphql';
import { useLazyQuery } from '@apollo/client';
import { format } from 'date-fns';
import download from 'downloadjs';
import { useUser } from 'context';
export function AllTransactionsCard(props) {
    var _this = this;
    var isLoading = props.isLoading, transactionHistory = props.transactionHistory, onClose = props.onClose, fromFacility = props.fromFacility, page = props.page, recordCount = props.recordCount, onPageChange = props.onPageChange, onOpenFilters = props.onOpenFilters, onSelectCard = props.onSelectCard, fullHistoryOpen = props.fullHistoryOpen, currentDateRange = props.currentDateRange, currentInjector = props.currentInjector, currentTypes = props.currentTypes, selectedFacility = props.selectedFacility;
    var colors = useTheme().colors;
    var account = useUser().account;
    var _a = __read(useLazyQuery(getCSVQuery), 2), downloadCSV = _a[0], loading = _a[1].loading;
    var csvOnDownload = function (request) { return __awaiter(_this, void 0, void 0, function () {
        var data, date, fileName;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    request.accountId = account.id;
                    return [4, downloadCSV({
                            variables: {
                                input: __assign({}, request),
                            },
                        })];
                case 1:
                    data = (_a.sent()).data;
                    date = format(new Date(), 'MM-dd-yyyy');
                    fileName = "".concat(date, "-transaction-details.csv");
                    download(data.getTransactionHistoryCSV.csvResponse, fileName, 'text/csv');
                    return [2];
            }
        });
    }); };
    var handleDownloadCSV = function () {
        var request = {
            accountId: '',
            startDate: '',
            activityFilter: [],
        };
        if (selectedFacility) {
            request.facilityId = [selectedFacility];
        }
        if (currentDateRange) {
            request.startDate = currentDateRange[0].toString();
            request.endDate = currentDateRange[1].toString();
        }
        if (currentInjector) {
            request.injectorId = [currentInjector.value];
        }
        request.activityFilter = currentTypes || [];
        csvOnDownload(request);
    };
    return (React.createElement(StackedCard, null,
        React.createElement(CloseContainer, null,
            React.createElement(Button, { onClick: onClose, variant: "tertiary" },
                React.createElement(Icon, { name: "close", color: colors.text.link }))),
        React.createElement(HeaderContainer, null,
            React.createElement(Typography, { variant: "titleLarge" }, "Transaction Details"),
            React.createElement(IconButton, { variant: "secondary", onClick: onOpenFilters },
                React.createElement(Icon, { name: "filter" }))),
        fromFacility && (React.createElement(Typography, { variant: "titleLarge", color: "alt", mt: 4 }, fromFacility.name)),
        React.createElement(Typography, { variant: "body", mb: 32, mt: 16 }, "View recent transactional reports"),
        React.createElement("div", null,
            React.createElement(Button, { variant: "outlinedSecondary", color: "primary", onClick: handleDownloadCSV, disabled: loading, startIcon: React.createElement(Icon, { name: "download", color: "secondary" }) }, loading ? (React.createElement(LoadingBubbles, null)) : (React.createElement(Typography, { variant: "caption", color: "alt" }, "Download CSV")))),
        React.createElement(TransactionHistoryContainer, null,
            isLoading && React.createElement(LoadingBubbles, null), transactionHistory === null || transactionHistory === void 0 ? void 0 :
            transactionHistory.map(function (th) {
                return (React.createElement(TransactionDetailItem, { onSelect: function () { return onSelectCard(th); }, details: th, fullHistoryOpen: fullHistoryOpen }));
            })),
        React.createElement(Pagination, { buttonNavigation: true, page: page || 0, total: recordCount || 0, limit: 8, onPageChange: onPageChange })));
}
var StackedCard = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  margin-top: 28px;\n"], ["\n  ", ";\n  margin-top: 28px;\n"])), stackedCardStyles);
var CloseContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n  margin: -32px -24px 0 0;\n"], ["\n  display: flex;\n  justify-content: flex-end;\n  margin: -32px -24px 0 0;\n"])));
var HeaderContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n"])));
var TransactionHistoryContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  gap: 8px;\n  margin-bottom: 8px;\n  margin-top: 24px;\n"], ["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  gap: 8px;\n  margin-bottom: 8px;\n  margin-top: 24px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;

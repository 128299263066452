var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
export var ReceiptLineItem = function (_a) {
    var label = _a.label, value = _a.value, labelSubtext = _a.labelSubtext, _b = _a.variant, variant = _b === void 0 ? 'default' : _b, hasExtraSpacing = _a.hasExtraSpacing;
    return (React.createElement(ReceiptLineItemContainer, { variant: variant, hasExtraSpacing: hasExtraSpacing || false },
        React.createElement(LabelContainer, null,
            React.createElement("span", null, label),
            React.createElement("span", null,
                variant === 'discount' && '-',
                value)),
        React.createElement(LabelSubtext, null, labelSubtext)));
};
var ReceiptLineItemContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  font-size: 0.875rem;\n  font-weight: 500;\n  margin-top: ", ";\n  color: ", ";\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  font-size: 0.875rem;\n  font-weight: 500;\n  margin-top: ", ";\n  color: ", ";\n"])), function (props) { return (props.hasExtraSpacing ? '12px' : 0); }, function (props) {
    return props.variant === 'discount'
        ? props.theme.colors.text.success
        : props.theme.colors.text.base;
});
var LabelContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  flex: 1;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  flex-direction: row;\n  flex: 1;\n  justify-content: space-between;\n"])));
var LabelSubtext = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-weight: 400;\n  margin-top: 5px;\n"], ["\n  font-weight: 400;\n  margin-top: 5px;\n"])));
var templateObject_1, templateObject_2, templateObject_3;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled, { css } from 'styled-components';
var getBorderColor = function (_a) {
    var error = _a.error, disabled = _a.disabled;
    if (disabled)
        return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      border: 1px solid ", ";\n    "], ["\n      border: 1px solid ", ";\n    "])), function (props) { return props.theme.colors.text.alt2; });
    if (error)
        return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      border: 1px solid ", ";\n      box-shadow: 0px 0px 0px 3px rgba(229, 85, 79, 0.25);\n    "], ["\n      border: 1px solid ", ";\n      box-shadow: 0px 0px 0px 3px rgba(229, 85, 79, 0.25);\n    "])), function (props) { return props.theme.colors.coral.coral500; });
    return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    border: 1px solid ", ";\n  "], ["\n    border: 1px solid ", ";\n  "])), function (props) { return props.theme.colors.border.alt2; });
};
var CheckboxElement = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: inline-block;\n  vertical-align: middle;\n  margin-top: auto;\n  margin-bottom: auto;\n"], ["\n  display: inline-block;\n  vertical-align: middle;\n  margin-top: auto;\n  margin-bottom: auto;\n"])));
var Icon = styled.svg(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  fill: none;\n  stroke: ", ";\n  stroke-width: 2px;\n"], ["\n  fill: none;\n  stroke: ", ";\n  stroke-width: 2px;\n"])), function (props) { return props.theme.colors.coral.coral500; });
var HiddenCheckbox = styled.input.attrs({ type: 'checkbox', disable: false })(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  border: 0;\n  clip: rect(0 0 0 0);\n  clip-path: inset(50%);\n  height: 1px;\n  margin: -1px;\n  overflow: hidden;\n  padding: 0;\n  position: absolute;\n  white-space: nowrap;\n  width: 1px;\n"], ["\n  border: 0;\n  clip: rect(0 0 0 0);\n  clip-path: inset(50%);\n  height: 1px;\n  margin: -1px;\n  overflow: hidden;\n  padding: 0;\n  position: absolute;\n  white-space: nowrap;\n  width: 1px;\n"])));
var CheckboxLabel = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject([""], [""])));
var StyledCheckbox = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: inline-block;\n  width: 16px;\n  height: 16px;\n\n  ", ";\n  transition: all 150ms;\n\n  ", ":focus + & {\n    box-shadow: 0 0 0 3px pink;\n  }\n\n  ", " {\n    visibility: ", ";\n  }\n"], ["\n  display: inline-block;\n  width: 16px;\n  height: 16px;\n\n  ", ";\n  transition: all 150ms;\n\n  ", ":focus + & {\n    box-shadow: 0 0 0 3px pink;\n  }\n\n  ", " {\n    visibility: ", ";\n  }\n"])), function (props) { return getBorderColor(props); }, HiddenCheckbox, Icon, function (props) { return (props.checked ? 'visible' : 'hidden'); });
var StyledTitle = styled.p(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  margin: 0 0 0 8px;\n"], ["\n  margin: 0 0 0 8px;\n"])));
var CheckboxContainer = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  cursor: pointer;\n  display: inline-flex;\n  line-height: 1;\n  vertical-align: middle;\n  ", "\n"], ["\n  cursor: pointer;\n  display: inline-flex;\n  line-height: 1;\n  vertical-align: middle;\n  ", "\n"])), function (props) {
    return !props.disabled &&
        "\n            &:hover {\n                ".concat(HiddenCheckbox, " {\n                    box-shadow: 0 0 0 3px pink;\n                }\n                ").concat(StyledCheckbox, " {\n                    border: 2px solid ").concat(function (props) {
            return props.theme.colors.white.white600;
        }, ";\n                }\n            }\n        ");
});
export var Checkbox = function (_a) {
    var checked = _a.checked, className = _a.className, disabled = _a.disabled, error = _a.error, onChange = _a.onChange, subTitle = _a.subTitle, title = _a.title, props = __rest(_a, ["checked", "className", "disabled", "error", "onChange", "subTitle", "title"]);
    return (React.createElement(CheckboxContainer, { disabled: disabled, onClick: function () { return onChange(); } },
        React.createElement(CheckboxElement, { className: className },
            React.createElement(HiddenCheckbox, __assign({ disabled: disabled, checked: checked }, props, { type: "checkbox" })),
            React.createElement(StyledCheckbox, { disabled: disabled, error: error, checked: checked },
                React.createElement(Icon, { viewBox: "0 0 24 24" },
                    React.createElement("polyline", { points: "20 6 9 17 4 12" })))),
        React.createElement(CheckboxLabel, null,
            title && React.createElement(StyledTitle, null, title),
            subTitle && React.createElement(StyledTitle, null, subTitle))));
};
export default Checkbox;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Typography } from '../Typography';
import { Icon } from '../Icon';
import { StepperHeader, StepperHeaderItem } from './StepperHeader';
import { VerticalStepperHeader, VerticalStepperHeaderItem } from '.';
import { Tick } from '../Icon/icons';
function getClassName(currentStep, index, steps) {
    if (currentStep > index || currentStep === steps - 1) {
        return 'completed';
    }
    if (currentStep === index) {
        return 'current';
    }
    return '';
}
export var VerticalStepper = function (_a) {
    var steps = _a.steps, currentStep = _a.currentStep, onlyShowCurrent = _a.onlyShowCurrent;
    var renderSteps = onlyShowCurrent ? steps.slice(0, currentStep + 1) : steps;
    return (React.createElement(StepperContainer, { vertical: true },
        React.createElement(VerticalStepperHeader, null, renderSteps.length
            ? renderSteps.map(function (step, index) {
                var className = getClassName(currentStep, index, steps.length);
                return (React.createElement(React.Fragment, { key: index },
                    React.createElement(VerticalStepperHeaderItem, { key: step.id, className: className },
                        React.createElement("div", { className: "step-counter" }, className === 'completed' && React.createElement(Tick, { color: "#fff" })),
                        React.createElement(Typography, { variant: "footnote", color: "alt" }, step.name)),
                    index !== renderSteps.length - 1 && React.createElement(StepSeparator, null)));
            })
            : null)));
};
var StepSeparator = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  content: '';\n  border: 1px dashed ", ";\n  z-index: 2;\n  width: 0;\n  height: 42px;\n  margin: 5px 0 5px 9px;\n"], ["\n  content: '';\n  border: 1px dashed ", ";\n  z-index: 2;\n  width: 0;\n  height: 42px;\n  margin: 5px 0 5px 9px;\n"])), function (props) { return props.theme.colors.coral.coral50; });
export var Stepper = function (_a) {
    var steps = _a.steps, currentStep = _a.currentStep;
    var theme = useTheme();
    return (React.createElement(StepperContainer, null,
        React.createElement(StepperHeader, null, steps.length
            ? steps.map(function (step, index) { return (React.createElement(StepperHeaderItem, { key: step.id, className: getClassName(currentStep, index, steps.length) },
                React.createElement("div", { className: "step-counter" }, getClassName(currentStep, index, steps.length) ===
                    'completed' && (React.createElement(Icon, { name: "tick", size: 10, color: theme.colors.text.alt3 }))),
                React.createElement(Typography, { variant: "headlineSmall" }, step.name))); })
            : null)));
};
var StepperContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: ", "};\n"], ["\n  display: flex;\n  flex-direction: ", "};\n"])), function (props) { return (props.vertical ? 'row' : 'column'); });
var templateObject_1, templateObject_2;

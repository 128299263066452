var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useEffect, useCallback } from 'react';
import { Modal } from '../Modal';
import { Typography, Button, Input, LoadingBubbles } from 'designSystem';
import styled, { useTheme } from 'styled-components';
import { NUMBERS_ONLY } from 'regex/numbersOnly';
import { useUser } from 'context';
import MyEvoLogo from 'assets/evolus-logo-coral.svg';
import { useScreenSizing } from '../../../hooks';
export var CompleteProfileView = function (_a) {
    var _b, _c, _d;
    var onCompleteProfile = _a.onCompleteProfile, loading = _a.loading;
    var user = useUser().user;
    var theme = useTheme();
    var isMobile = useScreenSizing().isMobile;
    var _e = __read(useState((_b = user === null || user === void 0 ? void 0 : user.firstName) !== null && _b !== void 0 ? _b : ''), 2), firstName = _e[0], setFirstName = _e[1];
    var _f = __read(useState((_c = user === null || user === void 0 ? void 0 : user.lastName) !== null && _c !== void 0 ? _c : ''), 2), lastName = _f[0], setLastName = _f[1];
    var _g = __read(useState((_d = user === null || user === void 0 ? void 0 : user.phoneNumber) !== null && _d !== void 0 ? _d : ''), 2), phoneNumber = _g[0], setPhoneNumber = _g[1];
    var allFieldsCompleted = firstName && lastName && phoneNumber && phoneNumber.length === 14;
    var handleSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var number;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!allFieldsCompleted) {
                        return [2];
                    }
                    number = phoneNumber.match(NUMBERS_ONLY).join('');
                    return [4, onCompleteProfile({
                            firstName: firstName,
                            lastName: lastName,
                            phoneNumber: number,
                            userId: (_a = user === null || user === void 0 ? void 0 : user.id) !== null && _a !== void 0 ? _a : '',
                        })];
                case 1:
                    _b.sent();
                    return [2];
            }
        });
    }); };
    var handleParsePhoneNumber = useCallback(function () {
        var newNum = phoneNumber.replace(/[^\d]/g, '');
        var numAsArray = newNum.split('');
        if (numAsArray.length === 11) {
            numAsArray.shift();
            newNum = numAsArray.join('').replace(/[^\d]/g, '');
            setPhoneNumber(handleFormatPhoneNumber(newNum));
        }
        else {
            setPhoneNumber(handleFormatPhoneNumber(newNum));
        }
    }, [phoneNumber]);
    useEffect(function () {
        handleParsePhoneNumber();
    }, [phoneNumber, handleParsePhoneNumber]);
    var handleFormatPhoneNumber = function (userPhoneNumber) {
        var userNumericPhoneNumber = userPhoneNumber.replace(/[^\d]/g, '');
        var formattedPhoneNumber;
        if (userNumericPhoneNumber.length < 4) {
            formattedPhoneNumber = userNumericPhoneNumber.replace(/(\d{1,3})/, "($1");
        }
        else if (userNumericPhoneNumber.length < 7) {
            formattedPhoneNumber = userNumericPhoneNumber.replace(/(\d{3})(\d{1,3})/, "($1) $2");
        }
        else {
            formattedPhoneNumber = userNumericPhoneNumber.replace(/(\d{3})(\d{3})(\d{1,4})/, "($1) $2-$3");
        }
        return formattedPhoneNumber;
    };
    var handlePhoneNumberInput = function (e) {
        var phoneNumber = e.target.value.replace(/[^\d]/g, '');
        var cursorPosition = e.target.selectionStart;
        if (cursorPosition === 1) {
            cursorPosition = 2;
        }
        else if (cursorPosition === 5) {
            cursorPosition = 7;
        }
        else if (cursorPosition === 10 && phoneNumber.length === 7) {
            cursorPosition = 11;
        }
        else if (cursorPosition === 10 && phoneNumber.length === 12) {
            cursorPosition = 12;
        }
        setPhoneNumber(handleFormatPhoneNumber(phoneNumber));
        setTimeout(function () {
            e.target.selectionStart = cursorPosition;
            e.target.selectionEnd = cursorPosition;
        }, 0);
    };
    return (React.createElement(Modal, { backdropColor: theme.colors.bg.accent },
        !isMobile && React.createElement("img", { src: MyEvoLogo, alt: "my-evolus-logo" }),
        React.createElement(Typography, { mt: isMobile ? 0 : 56, mb: 32, variant: "display", display: "block" }, "Complete your profile"),
        React.createElement(Typography, { color: "alt", mb: 32 }, "Enter your details below to complete your user profile."),
        React.createElement(InputsContainer, null,
            React.createElement(Input, { label: "First Name", placeholder: "First Name", value: firstName, onChange: function (e) { return setFirstName(e.target.value); } }),
            React.createElement(Input, { label: "Last Name", placeholder: "Last Name", value: lastName, onChange: function (e) { return setLastName(e.target.value); } }),
            React.createElement(CustomPhoneContainer, null,
                React.createElement(CustomPhoneFieldLabel, null, "Phone Number"),
                React.createElement(PhoneNumberInput, { type: "text", maxLength: 14, placeholder: "(xxx) xxx-xxxx", value: phoneNumber, onChange: function (e) { return handlePhoneNumberInput(e); } }))),
        React.createElement(ActionButton, { disabled: !allFieldsCompleted || loading, size: "xlarge", onClick: function () { return handleSubmit(); } }, !loading ? 'Save and Continue' : React.createElement(LoadingBubbles, null))));
};
var InputsContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  margin-bottom: 56px;\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n"], ["\n  width: 100%;\n  margin-bottom: 56px;\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n"])));
var CustomPhoneContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
var CustomPhoneFieldLabel = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 24px;\n  color: #808080;\n  text-align: left;\n  margin-bottom: 0px;\n  margin-top: 0px;\n  display: inline;\n"], ["\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 24px;\n  color: #808080;\n  text-align: left;\n  margin-bottom: 0px;\n  margin-top: 0px;\n  display: inline;\n"])));
var PhoneNumberInput = styled.input(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding: 12.75px 2px 12.75px 16px;\n  background-color: #f3f6f6;\n  outline: none;\n  border: 1px solid #f3f6f6;\n  display: flex;\n  flex: 1 1;\n  font-size: 1rem;\n  padding-left: 1rem;\n  color: #165050;\n  display: flex;\n  align-items: center;\n  flex: 1 1;\n  margin: 0.5rem 0;\n  position: relative;\n"], ["\n  padding: 12.75px 2px 12.75px 16px;\n  background-color: #f3f6f6;\n  outline: none;\n  border: 1px solid #f3f6f6;\n  display: flex;\n  flex: 1 1;\n  font-size: 1rem;\n  padding-left: 1rem;\n  color: #165050;\n  display: flex;\n  align-items: center;\n  flex: 1 1;\n  margin: 0.5rem 0;\n  position: relative;\n"])));
var ActionButton = styled(Button)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  @media only screen and (max-width: ", "px) {\n    margin-top: auto;\n    width: 100%;\n  }\n"], ["\n  @media only screen and (max-width: ", "px) {\n    margin-top: auto;\n    width: 100%;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;

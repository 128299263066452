var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useIsMounted } from './useIsMounted';
export var useQueryParams = function () {
    var _a;
    var location = useLocation();
    var search = location.search;
    var isMounted = useIsMounted();
    var refresh = useCallback(function () {
        var e_1, _a;
        var newParams = new URLSearchParams(search);
        var objParams = {};
        try {
            for (var _b = __values(newParams.entries()), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = __read(_c.value, 2), key = _d[0], value = _d[1];
                objParams[key] = value;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        if (!isMounted) {
            return;
        }
        return objParams;
    }, [isMounted, search]);
    var _b = __read(useState((_a = refresh()) !== null && _a !== void 0 ? _a : {}), 2), params = _b[0], setParams = _b[1];
    var navigate = useNavigate();
    useEffect(function () {
        var _a;
        setParams((_a = refresh()) !== null && _a !== void 0 ? _a : {});
    }, [refresh, setParams]);
    var updateParams = useCallback(function (newParams) {
        var updated = '?';
        var entriesArray = Object.entries(newParams);
        entriesArray.forEach(function (_a, index) {
            var _b = __read(_a, 2), key = _b[0], val = _b[1];
            if (!val) {
                return;
            }
            updated += "".concat(key, "=").concat(val).concat(index === entriesArray.length - 1 ? '' : '&');
        });
        navigate(updated);
    }, [navigate]);
    return {
        params: params,
        updateParams: updateParams,
    };
};

import { blur } from './blur';
import { borders } from './borders';
import { colors } from './colors';
import { elevation } from './elevation';
import { space } from './space';
import { typography } from './typography';
import { breakpoints } from './breakpoints';
import { card } from './card';
export var theme = {
    blur: blur,
    borders: borders,
    breakpoints: breakpoints,
    card: card,
    colors: colors,
    elevation: elevation,
    space: space,
    typography: typography,
};
export default theme;

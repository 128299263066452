import React from 'react';
import { SinglePaymentView } from './View';
import query from './index.graphql';
import { useQuery } from '@apollo/client';
import { useUser } from '../../../../context';
export var SinglePaymentContainer = function (_a) {
    var _b;
    var invoicePaymentId = _a.invoicePaymentId;
    var account = useUser().account;
    var _c = useQuery(query, {
        variables: {
            input: {
                accountId: account === null || account === void 0 ? void 0 : account.id,
                paymentId: invoicePaymentId,
            },
        },
    }), data = _c.data, loading = _c.loading, called = _c.called;
    return (React.createElement(SinglePaymentView, { payment: (_b = data === null || data === void 0 ? void 0 : data.getInvoicePaymentById) === null || _b === void 0 ? void 0 : _b.payment, loading: loading || !called }));
};

import React from 'react';
import { Icon, Typography } from 'designSystem';
import { ListItemCard } from '../components/ListItemCard';
import phoneNumberFormatter from '../../../../utils/phoneNumberFormatter';
import { pluralize } from 'utils/pluralize';
export var ManualCreditReceivedDetails = function (_a) {
    var _b;
    var details = _a.details;
    return (React.createElement(React.Fragment, null,
        React.createElement(ListItemCard, null,
            React.createElement("div", null,
                React.createElement(Icon, { name: "shooting-star" }),
                React.createElement(Typography, { variant: "headlineSmall", display: "inline" }, "Manual Credit Allocation")),
            React.createElement(Typography, { variant: "headlineSmall", display: "inline", color: "success" },
                "+",
                details.creditsEarned,
                " Credit",
                pluralize((_b = details.creditsEarned) !== null && _b !== void 0 ? _b : 0))),
        React.createElement(ListItemCard, null,
            React.createElement("div", null,
                React.createElement(Icon, { name: "phone" }),
                React.createElement(Typography, { variant: "headlineSmall", display: "inline" },
                    "+",
                    phoneNumberFormatter(details.phoneNumber))))));
};

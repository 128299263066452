var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Typography } from '../Typography';
export var MenuItemText = styled(Typography).attrs({
    color: 'alt',
})(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var templateObject_1;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { Modal } from '../Modal';
import { Button, Typography } from '../../../designSystem';
import { currencyFormatter } from '../../../utils/currencyFormatter';
import { pluralize } from '../../../utils/pluralize';
import styled, { css } from 'styled-components';
import { useModal } from '../../../context';
import SurprisedAccountant from 'assets/surprised-accountant.svg';
import { addMobileStyles } from '../../../utils/addMobileStyles';
import { useScreenSizing } from '../../../hooks';
export var PaymentExceedsBalance = function (_a) {
    var paymentAmount = _a.paymentAmount, balance = _a.balance, numberOfInvoices = _a.numberOfInvoices;
    var closeModal = useModal().closeModal;
    var isMobile = useScreenSizing().isMobile;
    return (React.createElement(Modal, { size: "large" },
        React.createElement(Container, null,
            React.createElement(LeftContainer, null,
                React.createElement(Typography, { align: isMobile ? 'center' : 'left', color: "link", variant: "titleLarge" },
                    currencyFormatter(paymentAmount, 2),
                    " payment exceeds invoice balance."),
                React.createElement(SelectedContainer, null,
                    React.createElement(Typography, { variant: "caption" },
                        numberOfInvoices,
                        " Invoice",
                        pluralize(numberOfInvoices),
                        " Selected"),
                    React.createElement(Typography, { variant: "caption" },
                        "Balance: ",
                        currencyFormatter(balance, 2))),
                React.createElement(Typography, { align: isMobile ? 'center' : 'left', variant: isMobile ? 'body' : 'bodySmall', color: "alt" },
                    "Payment amount must be equal to or less than total selected invoice balance.",
                    ' '),
                React.createElement(Typography, { align: isMobile ? 'center' : 'left', mb: 24, mt: 12, variant: isMobile ? 'body' : 'bodySmall', color: "alt" }, "Select additional invoices or change payment amount"),
                React.createElement("div", null,
                    React.createElement(Button, { size: "xlarge", variant: "primary", onClick: function () { return closeModal(); } }, "Okay"))),
            React.createElement(Image, { src: SurprisedAccountant, alt: "surprised-accountant" }))));
};
var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-content: center;\n  gap: 20px;\n  ", "\n"], ["\n  display: flex;\n  align-content: center;\n  gap: 20px;\n  ", "\n"])), addMobileStyles(css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    flex-direction: column-reverse;\n    justify-content: flex-end;\n    align-items: center;\n    flex: 1;\n    & > div {\n      flex: 1;\n    }\n    & > div > div:last-of-type {\n      margin-top: auto;\n      width: 100%;\n      & > button {\n        width: 100%;\n      }\n    }\n  "], ["\n    flex-direction: column-reverse;\n    justify-content: flex-end;\n    align-items: center;\n    flex: 1;\n    & > div {\n      flex: 1;\n    }\n    & > div > div:last-of-type {\n      margin-top: auto;\n      width: 100%;\n      & > button {\n        width: 100%;\n      }\n    }\n  "])))));
var LeftContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-content: flex-start;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-content: flex-start;\n"])));
var SelectedContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", ";\n  background: ", ";\n  display: flex;\n  justify-content: space-between;\n  align-content: center;\n  gap: 20px;\n  padding: 12px 24px;\n  margin: 24px 0 20px;\n  ", "\n"], ["\n  ", ";\n  background: ", ";\n  display: flex;\n  justify-content: space-between;\n  align-content: center;\n  gap: 20px;\n  padding: 12px 24px;\n  margin: 24px 0 20px;\n  ", "\n"])), function (props) { return props.theme.borders.base; }, function (props) { return props.theme.colors.bg.list; }, addMobileStyles(css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    margin: 32px 0;\n  "], ["\n    margin: 32px 0;\n  "])))));
var Image = styled.img(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 100%;\n  max-width: 280px;\n"], ["\n  width: 100%;\n  max-width: 280px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;

import React from 'react';
export var Strawberry = function (_a) {
    var color = _a.color;
    return (React.createElement("svg", { width: "48", height: "48", viewBox: "0 0 48 48", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0_339_86628)" },
            React.createElement("path", { d: "M24.0009 46.5C34.5469 46.5 48.3749 18.386 31.5009 16.5H16.5009C-0.375094 18.386 13.4529 46.5 24.0009 46.5Z", stroke: color, strokeWidth: "3", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { d: "M14.251 8.24929C15.4445 9.44278 16.8261 10.3503 18.092 10.7723C19.3579 11.1942 20.4043 11.096 21.001 10.4993C21.5978 9.90257 21.696 8.85616 21.274 7.59028C20.8521 6.3244 19.9445 4.94275 18.751 3.74927C17.5575 2.55579 16.1759 1.64824 14.91 1.22628C13.6441 0.804322 12.5977 0.902513 12.001 1.49925C11.4042 2.09599 11.3061 3.1424 11.728 4.40828C12.15 5.67416 13.0575 7.05581 14.251 8.24929V8.24929Z", stroke: color, strokeWidth: "3", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { d: "M27.002 10.4987C27.2974 10.7941 27.7064 10.9703 28.2056 11.0172C28.7047 11.064 29.2842 10.9806 29.911 10.7716C30.5378 10.5627 31.1996 10.2324 31.8586 9.79947C32.5177 9.36658 33.1611 8.83961 33.752 8.24866C34.343 7.65771 34.8699 7.01434 35.3028 6.3553C35.7357 5.69626 36.0661 5.03444 36.275 4.40764C36.4839 3.78084 36.5674 3.20133 36.5205 2.7022C36.4737 2.20307 36.2975 1.79409 36.002 1.49862C35.7065 1.20314 35.2976 1.02695 34.7984 0.980117C34.2993 0.933281 33.7198 1.01671 33.093 1.22564C32.4662 1.43458 31.8044 1.76492 31.1453 2.19782C30.4863 2.63071 29.8429 3.15768 29.252 3.74863C28.661 4.33958 28.1341 4.98295 27.7012 5.64199C27.2683 6.30103 26.9379 6.96284 26.729 7.58964C26.5201 8.21644 26.4366 8.79595 26.4835 9.29508C26.5303 9.79422 26.7065 10.2032 27.002 10.4987V10.4987Z", stroke: color, strokeWidth: "3", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { d: "M15.001 22.5L16.501 24", stroke: color, strokeWidth: "3", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { d: "M19.501 30L21.001 31.5", stroke: color, strokeWidth: "3", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { d: "M27.001 30L28.501 31.5", stroke: color, strokeWidth: "3", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { d: "M23.251 37.5L24.751 39", stroke: color, strokeWidth: "3", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { d: "M23.251 22.5L24.751 24", stroke: color, strokeWidth: "3", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { d: "M31.501 22.5L33.001 24", stroke: color, strokeWidth: "3", strokeLinecap: "round", strokeLinejoin: "round" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_339_86628" },
                React.createElement("rect", { width: "48", height: "48", fill: "white" })))));
};
export default Strawberry;

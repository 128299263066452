import React from 'react';
export var EliteSummit = function (_a) {
    var color = _a.color;
    return (React.createElement("svg", { width: "36", height: "36", viewBox: "0 0 36 36", fill: "none", xmlns: "http://www.w3.org/2000/svg", color: color },
        React.createElement("rect", { x: "0.5", y: "0.5", width: "35", height: "35", rx: "17.5", fill: "white" }),
        React.createElement("rect", { x: "0.5", y: "0.5", width: "35", height: "35", rx: "17.5", stroke: "#E5554F" }),
        React.createElement("g", { clipPath: "url(#clip0_712_7837)" },
            React.createElement("path", { d: "M11.5415 27.3574L18.6248 16.1074C18.723 15.9797 18.8492 15.8762 18.9937 15.805C19.1381 15.7338 19.2971 15.6968 19.4582 15.6968C19.6193 15.6968 19.7782 15.7338 19.9227 15.805C20.0672 15.8762 20.1934 15.9797 20.2915 16.1074L27.3748 27.3574H11.5415Z", stroke: "#E5554F", strokeWidth: "1.25", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { d: "M11.5417 27.3574H8.625L11.75 21.7324L13.5625 24.1491", stroke: "#E5554F", strokeWidth: "1.25", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { d: "M15.259 21.4531L17.2065 23.1123C17.3661 23.2473 17.5705 23.3175 17.7794 23.3088C17.9882 23.3002 18.1862 23.2134 18.334 23.0656L18.869 22.5306C19.0253 22.3744 19.2372 22.2866 19.4582 22.2866C19.6792 22.2866 19.8911 22.3744 20.0474 22.5306L20.5824 23.0656C20.7302 23.2134 20.9282 23.3002 21.137 23.3088C21.3459 23.3175 21.5503 23.2473 21.7099 23.1123L23.6707 21.474", stroke: "#E5554F", strokeWidth: "1.25", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { d: "M19.25 15.7224V8.60742", stroke: "#E5554F", strokeWidth: "1.25", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { d: "M19.25 8.60742H22.375V9.85742H26.125L24.875 11.7324L26.125 13.6074H22.375V12.9824H19.25", stroke: "#E5554F", strokeWidth: "1.25", strokeLinecap: "round", strokeLinejoin: "round" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_712_7837" },
                React.createElement("rect", { width: "20", height: "20", fill: "white", transform: "translate(8 8)" })))));
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { Typography } from 'designSystem';
import styled from 'styled-components';
export var ChipSelector = function (_a) {
    var label = _a.label, options = _a.options, onChange = _a.onChange, value = _a.value;
    return (React.createElement(Container, null,
        React.createElement(Typography, { variant: "headline", display: "block", color: "alt", mb: 16 }, label),
        React.createElement("div", null, options.map(function (o) {
            var selected = value === o.value;
            return (React.createElement(Chip, { selected: selected, onClick: function () { return onChange(o.value); } },
                React.createElement(Typography, { align: "center", variant: "headlineSmall", color: selected ? 'alt3' : 'base' }, o.label)));
        }))));
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  & > div {\n    display: grid;\n    grid-template-columns: repeat(4, 1fr);\n    gap: 16px;\n  }\n"], ["\n  & > div {\n    display: grid;\n    grid-template-columns: repeat(4, 1fr);\n    gap: 16px;\n  }\n"])));
var Chip = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border: none;\n  height: 48px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background: ", ";\n  & > span {\n    line-height: 16px;\n  }\n"], ["\n  border: none;\n  height: 48px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background: ", ";\n  & > span {\n    line-height: 16px;\n  }\n"])), function (props) {
    return props.selected
        ? props.theme.colors.selectable.active
        : props.theme.colors.bg.base;
});
var templateObject_1, templateObject_2;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components';
var SeparatorWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 12px;\n  background: ", ";\n"], ["\n  padding: 12px;\n  background: ", ";\n"])), function (props) { return props.backgroundColor; });
var SeparatorStyled = styled.hr(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border: 1px solid ", ";\n"], ["\n  border: 1px solid ", ";\n"])), function (props) { return props.color; });
export var Separator = function (_a) {
    var backgroundColor = _a.backgroundColor, color = _a.color, props = __rest(_a, ["backgroundColor", "color"]);
    return (React.createElement(SeparatorWrapper, { backgroundColor: backgroundColor },
        React.createElement(SeparatorStyled, { color: color })));
};
export default Separator;
var templateObject_1, templateObject_2;

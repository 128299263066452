import React from 'react';
export var Grapes = function (_a) {
    var color = _a.color;
    return (React.createElement("svg", { width: "48", height: "48", viewBox: "0 0 48 48", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M27.0002 1.5C25.8406 1.97028 24.8779 2.82486 24.2735 3.92051C23.669 5.01616 23.4596 6.28628 23.6802 7.518", stroke: color, strokeWidth: "3", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M6 18C6 18.5909 6.1164 19.1761 6.34254 19.7221C6.56869 20.268 6.90016 20.7641 7.31802 21.182C7.73588 21.5998 8.23196 21.9313 8.77792 22.1575C9.32389 22.3836 9.90905 22.5 10.5 22.5C11.0909 22.5 11.6761 22.3836 12.2221 22.1575C12.768 21.9313 13.2641 21.5998 13.682 21.182C14.0998 20.7641 14.4313 20.268 14.6575 19.7221C14.8836 19.1761 15 18.5909 15 18C15 17.4091 14.8836 16.8239 14.6575 16.2779C14.4313 15.732 14.0998 15.2359 13.682 14.818C13.2641 14.4002 12.768 14.0687 12.2221 13.8425C11.6761 13.6164 11.0909 13.5 10.5 13.5C9.90905 13.5 9.32389 13.6164 8.77792 13.8425C8.23196 14.0687 7.73588 14.4002 7.31802 14.818C6.90016 15.2359 6.56869 15.732 6.34254 16.2779C6.1164 16.8239 6 17.4091 6 18V18Z", stroke: color, strokeWidth: "3", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M19.5 18C19.5 19.1935 19.9741 20.3381 20.818 21.182C21.6619 22.0259 22.8065 22.5 24 22.5C25.1935 22.5 26.3381 22.0259 27.182 21.182C28.0259 20.3381 28.5 19.1935 28.5 18C28.5 16.8065 28.0259 15.6619 27.182 14.818C26.3381 13.9741 25.1935 13.5 24 13.5C22.8065 13.5 21.6619 13.9741 20.818 14.818C19.9741 15.6619 19.5 16.8065 19.5 18V18Z", stroke: color, strokeWidth: "3", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M19.5 42C19.5 43.1935 19.9741 44.3381 20.818 45.182C21.6619 46.0259 22.8065 46.5 24 46.5C25.1935 46.5 26.3381 46.0259 27.182 45.182C28.0259 44.3381 28.5 43.1935 28.5 42C28.5 40.8065 28.0259 39.6619 27.182 38.818C26.3381 37.9741 25.1935 37.5 24 37.5C22.8065 37.5 21.6619 37.9741 20.818 38.818C19.9741 39.6619 19.5 40.8065 19.5 42V42Z", stroke: color, strokeWidth: "3", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M12 30C12 30.5909 12.1164 31.1761 12.3425 31.7221C12.5687 32.268 12.9002 32.7641 13.318 33.182C13.7359 33.5998 14.232 33.9313 14.7779 34.1575C15.3239 34.3836 15.9091 34.5 16.5 34.5C17.0909 34.5 17.6761 34.3836 18.2221 34.1575C18.768 33.9313 19.2641 33.5998 19.682 33.182C20.0998 32.7641 20.4313 32.268 20.6575 31.7221C20.8836 31.1761 21 30.5909 21 30C21 29.4091 20.8836 28.8239 20.6575 28.2779C20.4313 27.732 20.0998 27.2359 19.682 26.818C19.2641 26.4002 18.768 26.0687 18.2221 25.8425C17.6761 25.6164 17.0909 25.5 16.5 25.5C15.9091 25.5 15.3239 25.6164 14.7779 25.8425C14.232 26.0687 13.7359 26.4002 13.318 26.818C12.9002 27.2359 12.5687 27.732 12.3425 28.2779C12.1164 28.8239 12 29.4091 12 30V30Z", stroke: color, strokeWidth: "3", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M27 30C27 31.1935 27.4741 32.3381 28.318 33.182C29.1619 34.0259 30.3065 34.5 31.5 34.5C32.6935 34.5 33.8381 34.0259 34.682 33.182C35.5259 32.3381 36 31.1935 36 30C36 28.8065 35.5259 27.6619 34.682 26.818C33.8381 25.9741 32.6935 25.5 31.5 25.5C30.3065 25.5 29.1619 25.9741 28.318 26.818C27.4741 27.6619 27 28.8065 27 30V30Z", stroke: color, strokeWidth: "3", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M33 18C33 18.5909 33.1164 19.1761 33.3425 19.7221C33.5687 20.268 33.9002 20.7641 34.318 21.182C34.7359 21.5998 35.232 21.9313 35.7779 22.1575C36.3239 22.3836 36.9091 22.5 37.5 22.5C38.0909 22.5 38.6761 22.3836 39.2221 22.1575C39.768 21.9313 40.2641 21.5998 40.682 21.182C41.0998 20.7641 41.4313 20.268 41.6575 19.7221C41.8836 19.1761 42 18.5909 42 18C42 17.4091 41.8836 16.8239 41.6575 16.2779C41.4313 15.732 41.0998 15.2359 40.682 14.818C40.2641 14.4002 39.768 14.0687 39.2221 13.8425C38.6761 13.6164 38.0909 13.5 37.5 13.5C36.9091 13.5 36.3239 13.6164 35.7779 13.8425C35.232 14.0687 34.7359 14.4002 34.318 14.818C33.9002 15.2359 33.5687 15.732 33.3425 16.2779C33.1164 16.8239 33 17.4091 33 18V18Z", stroke: color, strokeWidth: "3", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M19.5 1.5H31.5", stroke: color, strokeWidth: "3", strokeLinecap: "round", strokeLinejoin: "round" })));
};

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect } from 'react';
import { useUser } from '../../../../../../../context';
import { useNavigate } from 'react-router';
import { useMutation } from '@apollo/client';
import createMutation from './createProfile.graphql';
import resendMutation from './resendLink.graphql';
import { StepEnum } from '../../../Enums/StepEnum';
import { SendingMessageView } from './View';
export var SendingMessageContainer = function (_a) {
    var retryLink = _a.retryLink, phoneNumber = _a.phoneNumber, email = _a.email, handleRestart = _a.handleRestart;
    var _b = useUser(), account = _b.account, facility = _b.facility;
    var navigate = useNavigate();
    var _c = __read(useMutation(createMutation, {
        onCompleted: function () {
            navigate(StepEnum.EnrollmentProgress);
        },
    }), 2), createConsumerProfile = _c[0], error = _c[1].error;
    var _d = __read(useMutation(resendMutation, {
        onCompleted: function () {
            navigate(StepEnum.EnrollmentProgress);
        },
    }), 2), resendLink = _d[0], resendError = _d[1].error;
    useEffect(function () {
        (function () { return __awaiter(void 0, void 0, void 0, function () {
            var e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        if (!retryLink) return [3, 2];
                        return [4, resendLink({
                                variables: {
                                    input: {
                                        accountId: account === null || account === void 0 ? void 0 : account.id,
                                        email: email,
                                        phoneNumber: phoneNumber,
                                    },
                                },
                            })];
                    case 1:
                        _a.sent();
                        return [3, 4];
                    case 2: return [4, createConsumerProfile({
                            variables: {
                                input: {
                                    accountId: account === null || account === void 0 ? void 0 : account.id,
                                    email: email,
                                    facilityId: facility === null || facility === void 0 ? void 0 : facility.id,
                                    isOptedIn: true,
                                    phoneNumber: phoneNumber,
                                    createMetadata: {
                                        Enrollment_Source: 'Self Enrollment Link in PORTAL_APP',
                                    },
                                },
                            },
                        })];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [3, 6];
                    case 5:
                        e_1 = _a.sent();
                        console.error(e_1);
                        if (e_1.message.includes('email must be a valid email')) {
                            navigate(StepEnum.EmailInput);
                            return [2];
                        }
                        return [3, 6];
                    case 6: return [2];
                }
            });
        }); })();
    }, [
        account,
        createConsumerProfile,
        email,
        facility,
        phoneNumber,
        resendLink,
        retryLink,
        navigate,
    ]);
    return (React.createElement(SendingMessageView, { handleRestart: handleRestart, phoneNumber: phoneNumber, email: email, error: resendError || error }));
};

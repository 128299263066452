var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useMemo, useState, useContext, createContext, useCallback, } from 'react';
var ModalContext = createContext({
    currentModals: [],
    openModal: function () { },
    closeModal: function () { },
});
var ModalConsumer = ModalContext.Consumer;
var ModalProvider = function (props) {
    var _a = __read(useState([]), 2), currentModals = _a[0], setCurrentDrawes = _a[1];
    var openModal = useCallback(function (modalType, modalProps) {
        setCurrentDrawes(function (state) {
            return state.concat({
                modalType: modalType,
                modalProps: modalProps,
            });
        });
    }, []);
    var closeModal = useCallback(function () {
        setCurrentDrawes(function (currentModals) {
            var updatedModals = currentModals.slice();
            updatedModals.pop();
            return updatedModals;
        });
    }, []);
    var values = useMemo(function () { return ({ currentModals: currentModals, openModal: openModal, closeModal: closeModal }); }, [currentModals, openModal, closeModal]);
    return (React.createElement(ModalContext.Provider, { value: values }, props.children));
};
var useModal = function () {
    var context = useContext(ModalContext);
    if (context === undefined) {
        throw new Error('useModal must be used within an ModalProvider');
    }
    return context;
};
export { ModalProvider, ModalConsumer, useModal };

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import CheckInGraphic from 'assets/check-in-graphic-1.svg';
import { Desktop, Input } from 'designSystem';
import { StepContainer } from '../../StepContainer';
import CheckInProgress from '../../CheckInProgress';
import { useNavigate } from 'react-router';
import { StepEnum } from '../../../Enums/StepEnum';
import { useScreenSizing } from 'hooks';
export var EmailAddressInput = function (_a) {
    var handleRestart = _a.handleRestart, initialVal = _a.initialVal, onForward = _a.onForward;
    var navigate = useNavigate();
    var isMobile = useScreenSizing().isMobile;
    var _b = __read(useState(initialVal !== null && initialVal !== void 0 ? initialVal : ''), 2), emailAddress = _b[0], setEmailAddress = _b[1];
    var isValidEmail = function (email) {
        var emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}.*$/;
        return emailRegex.test(email);
    };
    var isNotValidEmail = !isValidEmail(emailAddress);
    return (React.createElement(React.Fragment, null,
        React.createElement(StepContainer, { handleRestart: handleRestart },
            React.createElement(InnerContainer, null,
                React.createElement(Desktop, null,
                    React.createElement(LeftContainer, null,
                        React.createElement(CheckInImage, { src: CheckInGraphic }))),
                React.createElement(RightContainer, null,
                    React.createElement(HeaderText, null, "Enter Email Address"),
                    React.createElement(Subtext, null, "Enter the email address of the patient you want to reward."),
                    React.createElement(Input, { placeholder: "Email Address", label: "Email Address", onChange: function (e) { return setEmailAddress(e.target.value); } })))),
        React.createElement(CheckInProgress, { primaryButtonText: "Continue to Enroll", disabled: isNotValidEmail, onContinue: function () {
                onForward(emailAddress);
                navigate(StepEnum.SendingMessage);
            }, tertiaryAction: handleRestart, tertiaryButtonText: isMobile && 'Restart Enrollment' })));
};
var InnerContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n\n  border: 1px solid ", ";\n  padding: 32px;\n  max-height: 40vh;\n  align-items: center;\n  @media screen and (max-width: ", "px) {\n    max-height: none;\n    padding: 0;\n    flex-direction: column;\n    margin-bottom: auto;\n    border: none;\n  }\n"], ["\n  display: flex;\n\n  border: 1px solid ", ";\n  padding: 32px;\n  max-height: 40vh;\n  align-items: center;\n  @media screen and (max-width: ", "px) {\n    max-height: none;\n    padding: 0;\n    flex-direction: column;\n    margin-bottom: auto;\n    border: none;\n  }\n"])), function (props) { return props.theme.colors.border.base; }, function (props) { return props.theme.breakpoints.md; });
var LeftContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  align-items: center;\n  justify-content: center;\n"], ["\n  display: flex;\n  flex: 1;\n  align-items: center;\n  justify-content: center;\n"])));
var HeaderText = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 1.5rem;\n  margin-bottom: 16px;\n"], ["\n  font-size: 1.5rem;\n  margin-bottom: 16px;\n"])));
var Subtext = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 1rem;\n  color: ", ";\n  margin-bottom: 32px;\n  @media screen and (max-width: ", "px) {\n    margin-bottom: 64px;\n  }\n"], ["\n  font-size: 1rem;\n  color: ", ";\n  margin-bottom: 32px;\n  @media screen and (max-width: ", "px) {\n    margin-bottom: 64px;\n  }\n"])), function (props) { return props.theme.colors.text.alt; }, function (props) { return props.theme.breakpoints.md; });
var RightContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex: 2;\n  justify-content: center;\n  flex-direction: column;\n  margin-left: 32px;\n  @media screen and (max-width: ", "px) {\n    margin: 0;\n    align-items: flex-start;\n  }\n"], ["\n  display: flex;\n  flex: 2;\n  justify-content: center;\n  flex-direction: column;\n  margin-left: 32px;\n  @media screen and (max-width: ", "px) {\n    margin: 0;\n    align-items: flex-start;\n  }\n"])), function (props) { return props.theme.breakpoints.md; });
var CheckInImage = styled.img(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  max-width: 100%;\n  max-height: 100%;\n  transform: scaleX(-1);\n"], ["\n  max-width: 100%;\n  max-height: 100%;\n  transform: scaleX(-1);\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Icon, Typography } from 'designSystem';
import React from 'react';
import styled from 'styled-components';
import { PhoneNumber } from '../../../PhoneNumber';
var EnrollmentStatusItem = function (_a) {
    var phoneNumber = _a.phoneNumber;
    return (React.createElement(EnrollmentStatusContainer, null,
        React.createElement(PhoneNumberContainer, null,
            React.createElement(Icon, { name: "phone", size: 16, color: "#6E9191" }),
            React.createElement(Typography, { variant: "title", style: { marginLeft: 16 } },
                React.createElement(PhoneNumber, { phoneNumber: phoneNumber }))),
        React.createElement(StatusContainer, null,
            React.createElement(Typography, { variant: "headlineSmall", color: "link" }, "Not Enrolled"))));
};
var EnrollmentStatusContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  padding: 20px 24px;\n  background-color: ", ";\n  border: 1px solid ", ";\n"], ["\n  display: flex;\n  padding: 20px 24px;\n  background-color: ", ";\n  border: 1px solid ", ";\n"])), function (props) { return props.theme.colors.bg.list; }, function (props) { return props.theme.colors.border.base; });
var PhoneNumberContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  align-items: center;\n"], ["\n  display: flex;\n  flex: 1;\n  align-items: center;\n"])));
var StatusContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
export default EnrollmentStatusItem;
var templateObject_1, templateObject_2, templateObject_3;

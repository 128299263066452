var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Card } from 'designSystem/components/Card';
import { Menu, MenuDivider, MenuItem, MenuItemIcon, MenuItemText, } from 'designSystem/components/Menu';
import { useUser } from 'context/UserContext';
import { useModal } from 'context/ModalContext';
import ModalTypeEnum from 'types/enums/ModalTypeEnum';
import pkg from '../../../../package.json';
var version = pkg.version;
export var NavigationDropdownMenu = function (_a) {
    var _b;
    var setShowMenu = _a.setShowMenu;
    var _c = useUser(), user = _c.user, isAdmin = _c.isAdmin;
    var navigate = useNavigate();
    var openModal = useModal().openModal;
    var hasMultipleAccounts = ((_b = user === null || user === void 0 ? void 0 : user.accounts) === null || _b === void 0 ? void 0 : _b.length) !== 1;
    return (React.createElement(Root, null,
        React.createElement(Card, { direction: "horizontal", fullWidth: true },
            React.createElement(Menu, null,
                React.createElement(MenuItem, { onClick: function () { }, "data-automation-id": 'navigation-profile' },
                    React.createElement(AccountIcon, null, "A"),
                    React.createElement(AccountName, null, user === null || user === void 0 ? void 0 :
                        user.firstName,
                        " ", user === null || user === void 0 ? void 0 :
                        user.lastName)),
                isAdmin && (React.createElement(MenuItem, { onClick: function () {
                        setShowMenu(false);
                        navigate('/settings');
                    }, "data-automation-id": "navigation-settings" },
                    React.createElement(MenuItemIcon, { size: 16, name: "settings" }),
                    React.createElement(MenuItemText, null, "Account Settings"))),
                React.createElement(MenuItem, { onClick: function () {
                        setShowMenu(false);
                        navigate('/profile-settings');
                    }, "data-automation-id": 'navigation-profile-settings' },
                    React.createElement(MenuItemIcon, { size: 16, name: "user" }),
                    React.createElement(MenuItemText, null, "User Profile Settings")),
                hasMultipleAccounts && (React.createElement(MenuItem, { onClick: function () {
                        setShowMenu(false);
                        openModal(ModalTypeEnum.ACCOUNT_SELECTION);
                    }, "data-automation-id": "navigation-switch" },
                    React.createElement(MenuItemIcon, { size: 16, name: "switch" }),
                    React.createElement(MenuItemText, null, "Switch account"))),
                React.createElement(MenuDivider, null),
                React.createElement(MenuItem, { onClick: function () {
                        localStorage.setItem('loggedIn', 'false');
                        navigate('/auth/sign-in');
                    }, "data-automation-id": "navigation-logout" },
                    React.createElement(MenuItemIcon, { size: 16, name: "logout" }),
                    React.createElement(MenuItemText, null, "Log out")),
                React.createElement(VersionContainer, null,
                    React.createElement(VersionText, null,
                        "Version ",
                        version))))));
};
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  top: 60px;\n  right: 0;\n  width: 300px;\n  z-index: 9999;\n"], ["\n  position: absolute;\n  top: 60px;\n  right: 0;\n  width: 300px;\n  z-index: 9999;\n"])));
var AccountIcon = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 16px;\n  height: 16px;\n  border-radius: 100%;\n  background-color: ", ";\n  font-size: 10px;\n  margin-right: 16px;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 16px;\n  height: 16px;\n  border-radius: 100%;\n  background-color: ", ";\n  font-size: 10px;\n  margin-right: 16px;\n"])), function (props) { return props.theme.colors.bg.alt; });
var AccountName = styled(MenuItemText)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-weight: 600;\n"], ["\n  font-weight: 600;\n"])));
var VersionText = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 14px;\n  color: ", ";\n"], ["\n  font-size: 14px;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text.alt4;
});
var VersionContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin: 12px 0 0 16px;\n"], ["\n  margin: 12px 0 0 16px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;

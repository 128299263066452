import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useUser } from 'context/UserContext';
import ModalTypeEnum from 'types/enums/ModalTypeEnum';
import { useModal } from 'context';
var noRedirectRoutes = ['selectAccount', 'home'];
export var SelectAccount = function () {
    var _a = useUser(), account = _a.account, loaded = _a.loaded;
    var _b = useModal(), openModal = _b.openModal, currentModals = _b.currentModals;
    var location = useLocation();
    var unsignedModalOpen = currentModals.some(function (m) { return m.modalType === ModalTypeEnum.UNSIGNED_AGREEMENTS; });
    useEffect(function () {
        var hasUnsignedAgreement = !(account === null || account === void 0 ? void 0 : account.hasSignedFinancialAgreement) ||
            !(account === null || account === void 0 ? void 0 : account.hasSignedMedicalLiabilityAgreement);
        if (loaded && account && hasUnsignedAgreement && !unsignedModalOpen) {
            openModal(ModalTypeEnum.UNSIGNED_AGREEMENTS, {
                accountId: account.id,
            });
        }
    }, [loaded, account, openModal, unsignedModalOpen]);
    if (!account && loaded) {
        var navTo = '/selectAccount';
        if (!noRedirectRoutes.some(function (route) { return location.pathname.includes(route); })) {
            navTo += "?redirect=".concat(location.pathname + location.search);
        }
        return React.createElement(Navigate, { to: navTo, replace: true, state: { from: location } });
    }
    if (!account && !loaded) {
        return null;
    }
    return React.createElement(Outlet, null);
};

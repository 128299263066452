var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import CheckInProgress from '../../CheckInProgress';
import { StepContainer } from '../../StepContainer';
import styled, { css } from 'styled-components';
import { Button, Icon, Input, Typography } from 'designSystem';
import { ChipSelector } from './components/ChipSelector';
import { ConsumerProfileGender } from 'types/codegen/hooks';
import { useFormik } from 'formik';
import { validationSchema } from './validation';
import { useNavigate } from 'react-router';
import { StepEnum } from '../../../Enums/StepEnum';
import EnrollPatientGraphic from 'assets/enroll-patient-graphic-1.svg';
import EnrollPatientConfirmationGraphic from 'assets/enroll-patient-graphic-1b.svg';
import { useScreenSizing } from 'hooks';
import { addMobileStyles } from 'utils/addMobileStyles';
import { PhoneNumber } from '../../PhoneNumber';
var genderOptions = [
    {
        label: 'Woman',
        value: ConsumerProfileGender.Woman,
    },
    {
        label: 'Man',
        value: ConsumerProfileGender.Man,
    },
    {
        label: 'Other',
        value: ConsumerProfileGender.Other,
    },
    {
        label: 'Prefer not to answer',
        value: ConsumerProfileGender.PreferNotToAnswer,
    },
];
var hasReceivedOptions = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
];
var toxins = [
    { value: 'Xeomin', label: 'Xeomin®' },
    { value: 'Jeuveau', label: 'Jeuveau®' },
    { value: 'Dysport', label: 'Dysport®' },
    { value: 'Daxxify', label: 'Daxxify®' },
    { value: 'BOTOX', label: 'Botox® Cosmetic' },
    { value: "Don\u2019t know", label: "Don\u2019t know" },
];
export var InlineEnrollmentView = function (_a) {
    var _b, _c, _d, _e, _f;
    var phoneNumber = _a.phoneNumber, prefillEmail = _a.prefillEmail, onEnroll = _a.onEnroll, loading = _a.loading, handleRestart = _a.handleRestart, existingEmail = _a.existingEmail;
    var navigate = useNavigate();
    var isMobile = useScreenSizing().isMobile;
    var _g = __read(useState(false), 2), messageDismissed = _g[0], setMessageDismissed = _g[1];
    var _h = __read(useState(false), 2), confirmationMessageDismissed = _h[0], setConfirmationMessageDismissed = _h[1];
    var _j = __read(useState(false), 2), showConfirmation = _j[0], setShowConfirmation = _j[1];
    var _k = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: prefillEmail,
            zipCode: '',
            gender: '',
            birthday: '',
            hasReceivedToxinBefore: null,
            toxinUsed: null,
        },
        isInitialValid: false,
        onSubmit: function (values) { return onEnroll(values); },
        validationSchema: validationSchema,
        validateOnBlur: true,
        validateOnChange: false,
    }), values = _k.values, submitForm = _k.submitForm, handleChange = _k.handleChange, setFieldValue = _k.setFieldValue, validateField = _k.validateField, errors = _k.errors, isValid = _k.isValid, setFieldError = _k.setFieldError;
    var email = values.email, gender = values.gender, hasReceivedToxinBefore = values.hasReceivedToxinBefore, toxinUsed = values.toxinUsed;
    if (email === existingEmail && !errors.email) {
        setFieldError('email', 'Email already exists');
    }
    useEffect(function () {
        validateField('toxinUsed');
    }, [hasReceivedToxinBefore, validateField]);
    useEffect(function () {
        validateField('gender');
    }, [gender, validateField]);
    useEffect(function () {
        validateField('hasReceivedToxinBefore');
    }, [hasReceivedToxinBefore, validateField]);
    useEffect(function () {
        validateField('toxinUsed');
    }, [toxinUsed, validateField]);
    return (React.createElement(React.Fragment, null,
        React.createElement(StepContainer, { handleRestart: handleRestart },
            React.createElement(BorderedContainer, { showConfirmation: showConfirmation },
                React.createElement("div", null,
                    React.createElement("div", null,
                        !showConfirmation && (React.createElement(React.Fragment, null,
                            React.createElement(Typography, { display: "block", variant: "titleLarge" }, "Enroll Patient"),
                            React.createElement(Typography, { display: "block", mb: 16, mt: 16, color: "alt" }, "Fill out the form below to enroll your patient into the Evolus Rewards program."),
                            !messageDismissed && (React.createElement(EnrollmentMessageContainer, null,
                                React.createElement(Typography, { variant: "footnote" }, "If the patient wants to enroll on their own device, click on the \u201CEnroll on Patient Device\u201D button on the right."),
                                React.createElement(Button, { variant: "quaternary", onClick: function () { return setMessageDismissed(true); } },
                                    React.createElement(Icon, { name: "close" })))))),
                        showConfirmation && (React.createElement(React.Fragment, null,
                            React.createElement(Typography, { display: "block", variant: "titleLarge", color: "alert" }, "Please Confirm Information"),
                            React.createElement(Typography, { display: "block", mb: 16, mt: 16, color: "alt" }, "Go over the information with your patient to ensure all information is accurate."),
                            !confirmationMessageDismissed && (React.createElement(ConfirmationMessageContainer, null,
                                React.createElement(Typography, { variant: "footnote" }, "Please go over the information with your patient to ensure all information is accurate."),
                                React.createElement(Button, { variant: "quaternary", onClick: function () { return setConfirmationMessageDismissed(true); } },
                                    React.createElement(Icon, { name: "close" })))))),
                        React.createElement(PhoneContainer, null,
                            React.createElement("div", null,
                                React.createElement(Icon, { name: "phone" }),
                                React.createElement(Typography, null,
                                    React.createElement(PhoneNumber, { phoneNumber: phoneNumber }))),
                            React.createElement(Typography, { color: "link" }, "Not Enrolled"))),
                    !isMobile && !showConfirmation && (React.createElement("img", { src: EnrollPatientGraphic, alt: "enroll-patient" })),
                    !isMobile && showConfirmation && (React.createElement("img", { src: EnrollPatientConfirmationGraphic, alt: "enroll-patient-confirmation" }))),
                React.createElement("div", null,
                    React.createElement(Typography, { display: "block", mb: 24, variant: "title" }, "1. Patient Personal Details"),
                    React.createElement(InputsContainer, null,
                        React.createElement(Input, { onBlur: function () { return validateField('firstName'); }, hasError: !!errors['firstName'], footerLabel: (_b = errors['firstName']) !== null && _b !== void 0 ? _b : null, label: "First Name", name: "firstName", value: values.firstName, onChange: handleChange }),
                        React.createElement(Input, { onBlur: function () { return validateField('lastName'); }, hasError: !!errors['lastName'], footerLabel: (_c = errors['lastName']) !== null && _c !== void 0 ? _c : null, label: "Last Name", name: "lastName", value: values.lastName, onChange: handleChange }),
                        !prefillEmail && (React.createElement(Input, { onBlur: function () { return validateField('email'); }, hasError: !!errors['email'], footerLabel: (_d = errors['email']) !== null && _d !== void 0 ? _d : null, label: "Email", name: "email", value: values.email, onChange: handleChange })),
                        React.createElement(Input, { masked: true, alwaysShowMask: true, maskString: "mm/dd/yyyy", mask: "00/00/0000", onBlur: function () { return validateField('birthday'); }, hasError: !!errors['birthday'], footerLabel: (_e = errors['birthday']) !== null && _e !== void 0 ? _e : null, label: "Birthday (mm/dd/yyyy)", name: "birthday", onSelect: function (e) {
                                var target = e.target;
                                if (target.value === 'mm/dd/yyyy') {
                                    target.setSelectionRange(0, 0);
                                }
                            }, value: values.birthday, onChange: handleChange }),
                        React.createElement(Input, { onBlur: function () { return validateField('zipCode'); }, hasError: !!errors['zipCode'], footerLabel: (_f = errors['zipCode']) !== null && _f !== void 0 ? _f : null, label: "Zip Code", name: "zipCode", value: values.zipCode, onChange: handleChange, maxLength: 5 }),
                        React.createElement(ChipSelector, { label: "What is the patient's gender identity?", options: genderOptions, onChange: function (v) { return setFieldValue('gender', v); }, value: values.gender })),
                    React.createElement(Typography, { mb: 24, display: "block", mt: 48, variant: "title" }, "2. Patient Treatment Details"),
                    React.createElement(InputsContainer, null,
                        React.createElement(ChipSelector, { label: "Has the patient received a toxin treatment before?", options: hasReceivedOptions, onChange: function (v) {
                                setFieldValue('hasReceivedToxinBefore', v);
                                if (!v) {
                                    setFieldValue('toxinUsed', null);
                                }
                            }, value: values.hasReceivedToxinBefore }),
                        hasReceivedToxinBefore && (React.createElement(ChipSelector, { label: "Which Toxin has the patient been treated with before?", options: toxins, onChange: function (v) { return setFieldValue('toxinUsed', v); }, value: values.toxinUsed })))))),
        !showConfirmation && (React.createElement(CheckInProgress, { disabled: !isValid, primaryButtonText: "Continue", isLoading: loading, onContinue: function () {
                setShowConfirmation(true);
                window.scrollTo(0, 0);
            }, onSecondaryContinue: function () {
                navigate(StepEnum.SendingMessage);
            }, secondaryButtonText: "Enroll on Patient Device", tertiaryAction: handleRestart, tertiaryButtonText: isMobile && 'Restart Enrollment' })),
        showConfirmation && (React.createElement(CheckInProgress, { disabled: !isValid, primaryButtonText: "Enroll Patient", isLoading: loading, onContinue: function () {
                submitForm();
            }, tertiaryAction: handleRestart, tertiaryButtonText: isMobile && 'Restart Enrollment' }))));
};
var BorderedContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  padding: 32px;\n  & > div:first-of-type {\n    display: flex;\n    gap: 32px;\n    align-items: flex-start;\n    justify-content: space-between;\n\n    div {\n      order: ", ";\n    }\n\n    & > img {\n      width: 100%;\n      max-width: 175px;\n      order: ", ";\n    }\n  }\n  ", "\n"], ["\n  ", ";\n  padding: 32px;\n  & > div:first-of-type {\n    display: flex;\n    gap: 32px;\n    align-items: flex-start;\n    justify-content: space-between;\n\n    div {\n      order: ", ";\n    }\n\n    & > img {\n      width: 100%;\n      max-width: 175px;\n      order: ", ";\n    }\n  }\n  ", "\n"])), function (props) { return props.theme.borders.base; }, function (props) { return (props.showConfirmation ? 2 : 1); }, function (props) { return (props.showConfirmation ? 1 : 2); }, addMobileStyles(css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    padding: 0;\n    border: none;\n  "], ["\n    padding: 0;\n    border: none;\n  "])))));
var EnrollmentMessageContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: #ebf9fa;\n  border: 2px solid ", ";\n  padding: 16px 20px;\n  display: flex;\n  align-items: center;\n  gap: 18px;\n  margin-bottom: 16px;\n"], ["\n  background: #ebf9fa;\n  border: 2px solid ", ";\n  padding: 16px 20px;\n  display: flex;\n  align-items: center;\n  gap: 18px;\n  margin-bottom: 16px;\n"])), function (props) { return props.theme.colors.teal.teal50; });
var ConfirmationMessageContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background: #fff4e5;\n  border: 2px solid #ffe0b7;\n  padding: 16px 20px;\n  display: flex;\n  align-items: center;\n  gap: 18px;\n  margin-bottom: 16px;\n  margin-top: 16px;\n"], ["\n  background: #fff4e5;\n  border: 2px solid #ffe0b7;\n  padding: 16px 20px;\n  display: flex;\n  align-items: center;\n  gap: 18px;\n  margin-bottom: 16px;\n  margin-top: 16px;\n"])));
var PhoneContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin: 16px 0 48px;\n  padding: 20px 24px;\n  display: flex;\n  flex-direction: row-reverse;\n  align-items: center;\n  justify-content: space-between;\n  ", ";\n  background: ", ";\n  & > div:first-of-type {\n    display: flex;\n    align-items: center;\n    gap: 12px;\n  }\n"], ["\n  margin: 16px 0 48px;\n  padding: 20px 24px;\n  display: flex;\n  flex-direction: row-reverse;\n  align-items: center;\n  justify-content: space-between;\n  ", ";\n  background: ", ";\n  & > div:first-of-type {\n    display: flex;\n    align-items: center;\n    gap: 12px;\n  }\n"])), function (props) { return props.theme.borders.base; }, function (props) { return props.theme.colors.white.white50; });
var InputsContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  column-gap: 24px;\n  row-gap: 12px;\n  & > div > div {\n    flex: none;\n  }\n  ", "\n"], ["\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  column-gap: 24px;\n  row-gap: 12px;\n  & > div > div {\n    flex: none;\n  }\n  ", "\n"])), addMobileStyles(css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    grid-template-columns: 1fr;\n    & > div > div {\n      flex: 1 1;\n    }\n  "], ["\n    grid-template-columns: 1fr;\n    & > div > div {\n      flex: 1 1;\n    }\n  "])))));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import { useNavigate } from 'react-router';
import { Button, Desktop, Mobile, Typography, Icon, Tag, LoadingBubbles, } from 'designSystem';
import { ConsumerRewardOptInStatus, } from 'types/codegen/hooks';
import { useModal, useUser } from 'context';
import rewardsInviteStaff from 'assets/rewards-invite-staff.svg';
import rewardsNotOptedIn from 'assets/rewards-not-opted-in.svg';
import rewardsOptedOut from 'assets/rewards-opted-out.svg';
import LockedGraphic from 'assets/locked-out.svg';
import CircularProgress from 'scenes/Rewards/Tabs/RewardsDashboard/CircularProgress';
import { stackedCardStyles } from 'scenes/Rewards/Tabs/RewardsDashboard/components/StackedMobileCard';
import ModalTypeEnum from 'types/enums/ModalTypeEnum';
var consumerRewardsVideo = '';
var VIALS_TO_PARTICIPATION = 10;
var RewardsProgressView = function (_a) {
    var fullWidth = _a.fullWidth, isLoading = _a.isLoading, rewardVialProgress = _a.rewardVialProgress, isInactive = _a.isInactive, status = _a.status, isLocked = _a.isLocked, vialsPurchased = _a.vialsPurchased;
    var navigate = useNavigate();
    var theme = useTheme();
    var isAdmin = useUser().isAdmin;
    var openModal = useModal().openModal;
    var optedIn = (status === null || status === void 0 ? void 0 : status.status) === ConsumerRewardOptInStatus.OptedIn;
    var inviteStaff = false;
    var neededVials = (rewardVialProgress === null || rewardVialProgress === void 0 ? void 0 : rewardVialProgress.rewardsRequiredForNextFreeVial) -
        (rewardVialProgress === null || rewardVialProgress === void 0 ? void 0 : rewardVialProgress.rewardsRedeemedTowardsNextFreeVial) || 0;
    var getStatus = function () {
        if (isLocked)
            return 'Deactivated';
        if (isInactive)
            return 'Inactive';
        if (!status)
            return 'Not Activated';
        if (inviteStaff)
            return 'Activated';
        return optedIn ? 'Activated' : 'Not Activated';
    };
    var getStatusVariant = function () {
        if (isLocked)
            return 'alt';
        if (isInactive)
            return 'danger';
        if (!status)
            return 'danger';
        if (inviteStaff)
            return 'success';
        return optedIn ? 'success' : 'danger';
    };
    var getHeadline = function () {
        if (isLocked)
            return 'Deactivated';
        if (isInactive)
            return 'Inactive Account';
        if (!status)
            return 'Rewards Program';
        if (!optedIn)
            return 'We Really Miss You';
        if (inviteStaff)
            return 'Invite Your Staff!';
        return "Reward ".concat(neededVials, " More Patients!");
    };
    var getCopy = function () {
        if (isLocked) {
            return (React.createElement(React.Fragment, null,
                React.createElement(Typography, { variant: "bodySmall", color: "alt", mb: 8 }, "Note: Due to suspicious activity, Evolus Rewards has been locked.")));
        }
        if (isInactive) {
            return (React.createElement(React.Fragment, null,
                React.createElement(Typography, { variant: "bodySmall", color: "alt", mb: 8 },
                    "You need to purchase ",
                    VIALS_TO_PARTICIPATION - vialsPurchased,
                    " more vials to participate in Evolus Rewards."),
                React.createElement(Typography, { variant: "footnote", color: "alt" }, "*Account inactive given no purchases within the last 12 months.")));
        }
        if (!status)
            return (React.createElement(React.Fragment, null,
                React.createElement(Typography, { variant: "bodySmall", color: "alt", mb: 8 }, "Modern Patient Loyalty for your Practice awaits!"),
                React.createElement(Button, { variant: "tertiary", onClick: function () {
                        return openModal(ModalTypeEnum.BENEFIT_VIDEO_PLAYER, {
                            vimeoUrl: consumerRewardsVideo,
                        });
                    }, endIcon: React.createElement(Icon, { name: "play", color: theme.colors.teal.teal900 }) }, "Learn More")));
        if (!optedIn)
            return (React.createElement(React.Fragment, null,
                React.createElement(Typography, { variant: "bodySmall", color: "alt", mb: 8 }, "You can easily come back to Evolus Rewards today!"),
                React.createElement(Button, { variant: "tertiary", onClick: function () {
                        return openModal(ModalTypeEnum.BENEFIT_VIDEO_PLAYER, {
                            vimeoUrl: consumerRewardsVideo,
                        });
                    }, endIcon: React.createElement(Icon, { name: "play", color: theme.colors.teal.teal900 }) }, "Learn More")));
        if (inviteStaff)
            return (React.createElement(Typography, { variant: "bodySmall", color: "alt" }, "The Highest Value Reward Program"));
        return (React.createElement(Typography, { variant: "bodySmall", color: "alt" }, "Each patient you reward will allow you to earn towards a reward vial."));
    };
    var getAction = function () {
        if (isLocked) {
            return null;
        }
        if (isInactive) {
            return isAdmin ? (React.createElement(Button, { size: "large", onClick: function () { return navigate('/orders/buy'); }, variant: "outlined", fullWidth: true, startIcon: React.createElement(Icon, { name: "present", color: theme.colors.coral.coral500 }) },
                "Buy ",
                VIALS_TO_PARTICIPATION - vialsPurchased,
                " Vials")) : (React.createElement(Typography, { variant: "action" }, "Contact your Admin to purchase more vials."));
        }
        if (!status || !optedIn) {
            return isAdmin ? (React.createElement(Button, { size: "xlarge", onClick: function () { return navigate('/settings/rewards'); }, variant: "outlined", fullWidth: true, startIcon: React.createElement(Icon, { name: "present", color: theme.colors.coral.coral500 }) }, "Opt In to Evolus Rewards")) : (React.createElement(Typography, { variant: "action" }, "Contact your Admin to opt into Evolus Rewards."));
        }
        if (inviteStaff) {
            return isAdmin ? (React.createElement(Button, { size: "large", onClick: function () { return navigate('/rewards/check-in/'); }, variant: "outlined", fullWidth: true }, "Invite Your Staff")) : (React.createElement(Typography, { variant: "action" }, "Contact your Admin to invite staff."));
        }
        return (React.createElement(Button, { size: "xlarge", onClick: function () {
                navigate('/rewards/check-in?step=mobileNumberInput');
            }, variant: "outlined", fullWidth: true, startIcon: React.createElement(Icon, { name: "present", color: theme.colors.coral.coral500 }) }, "Check In Patient"));
    };
    var getMedia = function () {
        if (isLocked) {
            return React.createElement(Image, { src: LockedGraphic });
        }
        if (isInactive)
            return (React.createElement(CircularProgress, { isLoading: isLoading, radius: 75, width: 162, vialsRequired: VIALS_TO_PARTICIPATION, vialsRedeemed: vialsPurchased },
                React.createElement(ProgressTextContainer, null,
                    React.createElement(ProgressText, { variant: "display" }, vialsPurchased),
                    React.createElement(ProgressText, { variant: "headline" },
                        "Out of ",
                        VIALS_TO_PARTICIPATION))));
        if (!status)
            return React.createElement(Image, { src: rewardsNotOptedIn });
        if (!optedIn)
            return React.createElement(Image, { src: rewardsOptedOut });
        if (inviteStaff)
            return React.createElement(Image, { src: rewardsInviteStaff });
        return (React.createElement(CircularProgress, { isLoading: isLoading, radius: 75, width: 162, vialsRequired: (rewardVialProgress === null || rewardVialProgress === void 0 ? void 0 : rewardVialProgress.rewardsRequiredForNextFreeVial) || 0, vialsRedeemed: (rewardVialProgress === null || rewardVialProgress === void 0 ? void 0 : rewardVialProgress.rewardsRedeemedTowardsNextFreeVial) || 0 },
            React.createElement(ProgressTextContainer, null,
                React.createElement(ProgressText, { variant: "display" }, (rewardVialProgress === null || rewardVialProgress === void 0 ? void 0 : rewardVialProgress.rewardsRedeemedTowardsNextFreeVial) || 0),
                React.createElement(ProgressText, { variant: "headline" },
                    "Out of ",
                    (rewardVialProgress === null || rewardVialProgress === void 0 ? void 0 : rewardVialProgress.rewardsRequiredForNextFreeVial) || 0))));
    };
    return (React.createElement(ProgressContainer, { fullWidth: fullWidth, isLoading: isLoading },
        isLoading && (React.createElement(LoadingContainer, null,
            React.createElement(LoadingBubbles, null))),
        React.createElement(ProgressHead, null,
            React.createElement(Typography, { variant: "sectionHeader", color: "alt5" }, "Evolus Rewards"),
            React.createElement(Tag, { text: getStatus(), variant: getStatusVariant() })),
        React.createElement(ProgressBody, null,
            React.createElement(ProgressLeftContainer, null,
                React.createElement(ProgressLeftTextContainer, null,
                    React.createElement(Typography, { variant: "headline", mb: 8 }, getHeadline()),
                    getCopy()),
                React.createElement(Desktop, null,
                    React.createElement(ProgressCheckInButtonContainer, null, getAction()))),
            React.createElement(ProgressRightContainer, null,
                getMedia(),
                React.createElement(Mobile, null,
                    React.createElement(ProgressCheckInButtonContainer, null, getAction()))))));
};
export default RewardsProgressView;
var ProgressTextContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  justify-content: center;\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n"], ["\n  position: absolute;\n  justify-content: center;\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n"])));
var ProgressContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  padding: 32px;\n  flex-direction: column;\n  @media screen and (max-width: ", "px) {\n    ", ";\n    z-index: 4;\n    margin: -16px 0 0;\n    flex-grow: 0;\n  }\n  ", "\n  ", "\n"], ["\n  ", ";\n  padding: 32px;\n  flex-direction: column;\n  @media screen and (max-width: ", "px) {\n    ", ";\n    z-index: 4;\n    margin: -16px 0 0;\n    flex-grow: 0;\n  }\n  ", "\n  ", "\n"])), function (props) { return props.theme.card.default; }, function (props) { return props.theme.breakpoints.md; }, stackedCardStyles, function (props) { return props.isLoading && props.theme.blur.loading; }, function (props) {
    return props.fullWidth && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      & > div:last-of-type > div {\n        flex: 1;\n      }\n    "], ["\n      & > div:last-of-type > div {\n        flex: 1;\n      }\n    "])));
});
var LoadingContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: absolute;\n  top: 0;\n  left: 0;\n"], ["\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: absolute;\n  top: 0;\n  left: 0;\n"])));
var ProgressLeftContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  flex: 1;\n\n  @media screen and (max-width: ", "px) {\n    justify-content: center;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  flex: 1;\n\n  @media screen and (max-width: ", "px) {\n    justify-content: center;\n  }\n"])), function (props) { return props.theme.breakpoints.md; });
var ProgressRightContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex: 1;\n\n  @media screen and (max-width: ", "px) {\n    width: auto;\n    margin-right: 0px;\n    margin-left: 0;\n    margin-top: 24px;\n\n    flex-direction: column;\n    & > div:first-of-type {\n      margin-bottom: 24px;\n    }\n    & > span:first-of-type {\n      margin-bottom: 8px;\n    }\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex: 1;\n\n  @media screen and (max-width: ", "px) {\n    width: auto;\n    margin-right: 0px;\n    margin-left: 0;\n    margin-top: 24px;\n\n    flex-direction: column;\n    & > div:first-of-type {\n      margin-bottom: 24px;\n    }\n    & > span:first-of-type {\n      margin-bottom: 8px;\n    }\n  }\n"])), function (props) { return props.theme.breakpoints.md; });
var ProgressLeftTextContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n"])));
var ProgressCheckInButtonContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  align-items: flex-end;\n  width: 100%;\n"], ["\n  display: flex;\n  align-items: flex-end;\n  width: 100%;\n"])));
var ProgressText = styled(Typography)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
var ProgressHead = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  margin-bottom: 16px;\n  align-items: center;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  margin-bottom: 16px;\n  align-items: center;\n  justify-content: space-between;\n"])));
var ProgressBody = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  display: flex;\n  height: 100%;\n\n  @media screen and (max-width: ", "px) {\n    flex-direction: column;\n  }\n"], ["\n  display: flex;\n  height: 100%;\n\n  @media screen and (max-width: ", "px) {\n    flex-direction: column;\n  }\n"])), function (props) { return props.theme.breakpoints.md; });
var Image = styled.img(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  max-width: 170px;\n"], ["\n  max-width: 170px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;

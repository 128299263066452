var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { Button, Typography } from 'designSystem';
import { useModal } from 'context';
import MessageSentGraphic from 'assets/message-sent.svg';
import { useScreenSizing } from 'hooks/useScreenSizing';
export var TemporaryPasswordSent = function () {
    var closeModal = useModal().closeModal;
    var isMobile = useScreenSizing().isMobile;
    return (React.createElement(Container, null,
        React.createElement("div", null,
            React.createElement(Typography, { color: isMobile ? 'link' : 'base', mb: isMobile ? 16 : 32, mt: isMobile && 32, display: "block", variant: isMobile ? 'display' : 'titleLarge' }, "Temporary Password sent!"),
            React.createElement(Typography, { color: "alt" }, "You may close this window. Follow the instructions in the email to finish setting up your account."),
            React.createElement(Button, { size: "xlarge", onClick: function () { return closeModal(); } }, "Close Window")),
        React.createElement(Image, { src: MessageSentGraphic, alt: "temporary-password-resent" })));
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  z-index: 10000;\n  position: relative;\n  flex-direction: row;\n  justify-content: space-between;\n  & > div:first-of-type {\n    display: flex;\n    flex-direction: column;\n    flex: 1;\n    align-items: flex-start;\n    & > button {\n      margin-top: auto;\n      width: fit-content;\n    }\n  }\n  @media only screen and (max-width: ", "px) {\n    padding: 0;\n    align-items: center;\n    flex-direction: column-reverse;\n    & > div:first-of-type {\n      & > span {\n        text-align: center;\n      }\n      & > button {\n        width: 100%;\n      }\n    }\n  }\n"], ["\n  display: flex;\n  flex: 1;\n  z-index: 10000;\n  position: relative;\n  flex-direction: row;\n  justify-content: space-between;\n  & > div:first-of-type {\n    display: flex;\n    flex-direction: column;\n    flex: 1;\n    align-items: flex-start;\n    & > button {\n      margin-top: auto;\n      width: fit-content;\n    }\n  }\n  @media only screen and (max-width: ", "px) {\n    padding: 0;\n    align-items: center;\n    flex-direction: column-reverse;\n    & > div:first-of-type {\n      & > span {\n        text-align: center;\n      }\n      & > button {\n        width: 100%;\n      }\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var Image = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  max-width: 280px;\n  margin-left: 44px;\n  transform: scaleX(-1);\n  @media only screen and (max-width: ", "px) {\n    margin-left: 0;\n  }\n"], ["\n  width: 100%;\n  max-width: 280px;\n  margin-left: 44px;\n  transform: scaleX(-1);\n  @media only screen and (max-width: ", "px) {\n    margin-left: 0;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var templateObject_1, templateObject_2;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a, _b;
import React from 'react';
import { Typography } from 'designSystem';
import styled from 'styled-components';
import { UserRole } from 'types/codegen/hooks';
import { PrivilegeCard } from './PrivilegeCard';
var PrivilegesByRole = (_a = {},
    _a[UserRole.AccountAdmin] = [
        {
            header: 'Manage & Claim Public Profile',
            description: 'This user can access and manage public facing profile.',
        },
        {
            header: 'Facilities',
            description: 'This user has access to buy at all locations.',
        },
        {
            header: 'Add / Edit Other Users',
            description: 'This user can add and edit staff and their permissions.',
        },
        {
            header: 'Can Order & Pay',
            description: 'This user has access to buy product with all payment methods.',
        },
        {
            header: 'Add Payment Methods',
            description: 'This user can add, delete, or edit payment methods.',
        },
        {
            header: 'Loyalty Program Support',
            description: 'This user has access to sign up patients, view reward statement history, upcoming rewards, and manage reward redemption.',
        },
    ],
    _a[UserRole.Custom] = [
        {
            header: 'Rewards Program Support',
            description: 'This user has access to sign up patients and manage reward redemption in Evolus Rewards Web App.',
        },
    ],
    _a);
var LabelByRole = (_b = {},
    _b[UserRole.AccountAdmin] = 'Admin Level',
    _b[UserRole.Custom] = 'Limited Access',
    _b);
export var PrivilegesWidget = function (_a) {
    var role = _a.role;
    return (React.createElement(InnerContainer, null,
        React.createElement(Typography, { mb: 16, display: "block", variant: "titleLarge" },
            LabelByRole[role],
            " Privileges"),
        React.createElement(Typography, { display: "block", mb: 32, color: "alt" }, "The current role and privilege that the user has with your practice."),
        React.createElement(PrivilegeContainer, null, PrivilegesByRole[role].map(function (p, i) { return (React.createElement(PrivilegeCard, __assign({}, p, { key: i }))); }))));
};
var InnerContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 48px;\n  ", ";\n\n  @media only screen and (max-width: ", "px) {\n    border: none;\n    padding: 0;\n  }\n"], ["\n  padding: 48px;\n  ", ";\n\n  @media only screen and (max-width: ", "px) {\n    border: none;\n    padding: 0;\n  }\n"])), function (props) { return props.theme.borders.base; }, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var PrivilegeContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  row-gap: 12px;\n  column-gap: 32px;\n  @media only screen and (max-width: ", "px) {\n    grid-template-columns: 1fr;\n    column-gap: 0;\n    row-gap: 16px;\n  }\n"], ["\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  row-gap: 12px;\n  column-gap: 32px;\n  @media only screen and (max-width: ", "px) {\n    grid-template-columns: 1fr;\n    column-gap: 0;\n    row-gap: 16px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var templateObject_1, templateObject_2;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useMemo, useState } from 'react';
import { StepContainer } from '../StepContainer';
import { PaymentProgress } from '../PaymentProgress';
import styled, { css } from 'styled-components';
import { Button, getVariant, Typography } from 'designSystem';
import MakePaymentGraphic from 'assets/bills-payment.svg';
import { NUMBERS_ONLY } from 'regex/numbersOnly';
import { PaymentOption } from '../../enums/PaymentOptions';
import { useModal } from 'context';
import ModalTypeEnum from 'types/enums/ModalTypeEnum';
import { SingleInvoiceItem } from '../../../../components/SingleInvoiceItem';
import { pluralize } from 'utils/pluralize';
import { currencyFormatter } from '../../../../../../utils/currencyFormatter';
import { useScreenSizing } from '../../../../../../hooks';
import { addMobileStyles } from '../../../../../../utils/addMobileStyles';
import { MobileContinueButton } from '../MobileContinueButton';
var getNumCommas = function (inputs) {
    if (inputs.length < 5) {
        return 0;
    }
    var integers = inputs.slice(0, inputs.length - 2);
    return Math.floor((integers.length - 1) / 3);
};
var getNormalizedIndex = function (str, originalIndex) {
    var charsBefore = 0;
    str.split('').forEach(function (c, i) {
        if (i < originalIndex && !c.match(NUMBERS_ONLY)) {
            charsBefore += 1;
        }
    });
    return originalIndex - charsBefore;
};
var renderValue = function (inputs) {
    var arr = inputs;
    if (inputs.length < 3) {
        var zeroPads = new Array(3 - inputs.length).fill(0);
        arr = __spreadArray(__spreadArray([], __read(zeroPads), false), __read(inputs), false);
    }
    var integerDollars = arr.slice(0, arr.length - 2);
    if (integerDollars.length < 3) {
        return "$".concat(integerDollars.join(''), ".").concat(arr.slice(arr.length - 2).join(''));
    }
    var numCommas = getNumCommas(arr);
    var newArray = __spreadArray([], __read(integerDollars), false);
    for (var i = 1; i <= numCommas; i++) {
        newArray.splice(integerDollars.length - i * 3, 0, ',');
    }
    return "$".concat(newArray.join(''), ".").concat(arr.slice(arr.length - 2).join(''));
};
var parseInput = function (asString) {
    return parseFloat(asString.replace(/[^0-9.]/g, ''));
};
export var CustomPayment = function (_a) {
    var onContinue = _a.onContinue, allInvoices = _a.allInvoices;
    var _b = useModal(), openModal = _b.openModal, closeModal = _b.closeModal;
    var isMobile = useScreenSizing().isMobile;
    var _c = __read(useState([]), 2), selected = _c[0], setSelected = _c[1];
    var _d = __read(useState([]), 2), inputs = _d[0], setInputs = _d[1];
    var _e = __read(useState(null), 2), cursorOverride = _e[0], setCursorOverride = _e[1];
    var renderedVal = useMemo(function () { return renderValue(inputs); }, [inputs]);
    var totalInvoicesAmount = useMemo(function () {
        var _a;
        if (selected === null || selected === void 0 ? void 0 : selected.length) {
            return selected.reduce(function (acc, obj) { var _a; return acc + ((_a = obj.amountDue) !== null && _a !== void 0 ? _a : 0); }, 0);
        }
        return ((_a = allInvoices === null || allInvoices === void 0 ? void 0 : allInvoices.reduce(function (acc, obj) { var _a; return acc + ((_a = obj.amountDue) !== null && _a !== void 0 ? _a : 0); }, 0)) !== null && _a !== void 0 ? _a : 0);
    }, [selected, allInvoices]);
    var handleContinue = function () {
        var amountAsNumber = parseInput(renderedVal);
        if (amountAsNumber > totalInvoicesAmount) {
            openModal(ModalTypeEnum.PAYMENT_EXCEEDS_BALANCE, {
                paymentAmount: amountAsNumber,
                balance: totalInvoicesAmount,
                numberOfInvoices: (selected === null || selected === void 0 ? void 0 : selected.length) || allInvoices.length,
            });
            return;
        }
        onContinue({
            customAmount: amountAsNumber,
            invoices: selected.length ? selected : allInvoices,
        });
    };
    var handleKeyPress = function (e) {
        var cursorPos = e.target.selectionEnd;
        if (!e.key.match(NUMBERS_ONLY)) {
            return;
        }
        if (cursorPos !== renderedVal.length) {
            var index = getNormalizedIndex(renderedVal, cursorPos);
            var currentCommas = getNumCommas(inputs);
            var newInputs = __spreadArray(__spreadArray(__spreadArray([], __read(inputs.slice(0, index)), false), [
                e.key
            ], false), __read(inputs.slice(index)), false);
            var newCommas = getNumCommas(newInputs);
            setInputs(newInputs);
            var newPos = cursorPos + (currentCommas < newCommas ? 2 : 1);
            setCursorOverride(newPos);
            return;
        }
        setInputs(__spreadArray(__spreadArray([], __read(inputs.slice(0)), false), [e.key], false));
    };
    var handleDelete = function (e) {
        var cursorPos = e.target.selectionEnd;
        if (e.key !== 'Backspace' || cursorPos === 0 || cursorPos === 1) {
            return;
        }
        if (cursorPos === renderedVal.length) {
            setInputs(inputs.slice(0, inputs.length - 1));
            return;
        }
        if (renderedVal[cursorPos - 1].match(NUMBERS_ONLY)) {
            var arrCopy = inputs.slice();
            var index = getNormalizedIndex(renderedVal, cursorPos - 1);
            arrCopy.splice(index, 1);
            var currentCommas = getNumCommas(inputs);
            var newCommas = getNumCommas(arrCopy);
            setInputs(arrCopy);
            setCursorOverride(cursorPos - (currentCommas > newCommas ? 2 : 1));
        }
        else {
            e.target.setSelectionRange(cursorPos - 1, cursorPos - 1);
            setCursorOverride(cursorPos - 1);
        }
    };
    var handleSelectInvoices = function () {
        openModal(ModalTypeEnum.SPECIFY_INVOICES, {
            invoices: allInvoices,
            preselected: selected.map(function (i) { return i.invoiceId; }),
            onSelect: function (selectedInvoices) {
                setSelected(selectedInvoices);
                closeModal();
            },
        });
    };
    var parsedInput = parseInput(renderedVal);
    var invalidAmount = parsedInput < Math.min(200, totalInvoicesAmount);
    return (React.createElement(React.Fragment, null,
        React.createElement(StepContainer, { subHeader: "Make A Payment", header: "Pay Custom Amount", tertiaryHeader: "Please enter your desired payment amount in the field below." },
            React.createElement(Container, null,
                React.createElement("div", null,
                    React.createElement(Typography, { mb: 16, variant: "buttonLabel", color: isMobile ? 'alt5' : 'base', display: "block" }, "CUSTOM AMOUNT"),
                    React.createElement(CustomInput, { size: renderedVal.length, shrinkText: inputs.length > 4, value: renderedVal, onKeyPress: handleKeyPress, onChange: function (e) {
                            e.preventDefault();
                        }, onSelect: function (e) {
                            e.preventDefault();
                            if (cursorOverride) {
                                e.target.setSelectionRange(cursorOverride, cursorOverride);
                                setCursorOverride(null);
                            }
                        }, onKeyDown: handleDelete, placeholder: "$0.00", inputMode: "numeric" }),
                    React.createElement(Typography, { mt: 32, mb: 8, variant: "caption", display: "block", color: isMobile ? 'alt' : 'base' },
                        "Minimum payment: lesser of $200.00 or outstanding balance",
                        totalInvoicesAmount < 200 && 'of ',
                        totalInvoicesAmount < 200 &&
                            currencyFormatter(totalInvoicesAmount, 2)),
                    React.createElement(Typography, { variant: "footnote", display: "block", color: isMobile ? 'alt' : 'base' }, "Payment is applied to oldest invoices due first."),
                    selected.length < 1 && (React.createElement(Button, { onClick: handleSelectInvoices, variant: "tertiary" }, "Apply to specific invoices only?"))),
                !isMobile && React.createElement("img", { src: MakePaymentGraphic, alt: "custom-payment" })),
            selected.length > 0 && (React.createElement(SelectedContainer, null,
                React.createElement("div", null,
                    React.createElement(Typography, { variant: "headline" },
                        selected.length,
                        " Invoice",
                        pluralize(selected.length),
                        " Selected"),
                    React.createElement(Button, { onClick: handleSelectInvoices, variant: "tertiary" }, "Change Selection")),
                React.createElement("div", null, selected.map(function (s) {
                    var _a, _b, _c;
                    return (React.createElement(SingleInvoiceItem, { invoiceId: s.invoiceId, status: s.status, dueDate: s.dueDate, date: s.date, amount: (_a = s.amountDue) !== null && _a !== void 0 ? _a : 0, quantity: (_b = s.jeuveauQuantity) !== null && _b !== void 0 ? _b : 0, facilityName: (_c = s.facility) === null || _c === void 0 ? void 0 : _c.name }));
                })))),
            isMobile && (React.createElement(MobileContinueButton, { label: "Select Payment Method", onContinue: handleContinue, disabled: invalidAmount }))),
        !isMobile && (React.createElement(PaymentProgress, { paymentOption: PaymentOption.CUSTOM, primaryButtonDisabled: invalidAmount, primaryButtonLabel: "Select Payment Method", primaryAction: function () {
                handleContinue();
            } }))));
};
var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: 32px;\n  ", ";\n  padding: 32px 64px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 36px;\n  & > div:first-of-type > button {\n    padding: 0;\n    margin-top: 16px;\n  }\n  ", "\n"], ["\n  margin-top: 32px;\n  ", ";\n  padding: 32px 64px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 36px;\n  & > div:first-of-type > button {\n    padding: 0;\n    margin-top: 16px;\n  }\n  ", "\n"])), function (props) { return props.theme.borders.base; }, addMobileStyles(css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    border: none;\n    padding: 0;\n    & > div:first-of-type {\n      display: flex;\n      flex-direction: column;\n      & > input {\n        align-self: center;\n      }\n      & > button {\n        align-self: flex-start;\n      }\n    }\n  "], ["\n    border: none;\n    padding: 0;\n    & > div:first-of-type {\n      display: flex;\n      flex-direction: column;\n      & > input {\n        align-self: center;\n      }\n      & > button {\n        align-self: flex-start;\n      }\n    }\n  "])))));
var CustomInput = styled.input(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border: none;\n  border-bottom: 2px solid #cfcfcf;\n  :focus {\n    outline: none;\n  }\n  width: max-content;\n  transition-property: font-size, font-weight, line-height;\n  transition-duration: 0.35s;\n  transition-timing-function: ease-out;\n  ", ";\n"], ["\n  border: none;\n  border-bottom: 2px solid #cfcfcf;\n  :focus {\n    outline: none;\n  }\n  width: max-content;\n  transition-property: font-size, font-weight, line-height;\n  transition-duration: 0.35s;\n  transition-timing-function: ease-out;\n  ", ";\n"])), function (props) {
    return props.shrinkText ? getVariant('titleLarge') : getVariant('display');
});
var SelectedContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-top: 40px;\n  & > div:first-of-type {\n    display: flex;\n    align-items: center;\n    flex: 1;\n    justify-content: space-between;\n    margin-bottom: 32px;\n  }\n  & > div:last-of-type {\n    display: flex;\n    flex: 1;\n    flex-direction: column;\n    gap: 12px;\n    & > button {\n      ", ";\n    }\n  }\n"], ["\n  margin-top: 40px;\n  & > div:first-of-type {\n    display: flex;\n    align-items: center;\n    flex: 1;\n    justify-content: space-between;\n    margin-bottom: 32px;\n  }\n  & > div:last-of-type {\n    display: flex;\n    flex: 1;\n    flex-direction: column;\n    gap: 12px;\n    & > button {\n      ", ";\n    }\n  }\n"])), function (props) { return props.theme.borders.base; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useEffect } from 'react';
import { Input } from '../../designSystem';
import styled from 'styled-components';
export var NewPasswordInputs = function (_a) {
    var password = _a.password, passwordConfirmation = _a.passwordConfirmation, onPasswordChange = _a.onPasswordChange, onConfirmationChange = _a.onConfirmationChange, error = _a.error;
    var _b = __read(useState(false), 2), mismatchPasswordError = _b[0], setMismatchPasswordError = _b[1];
    var _c = __read(useState(false), 2), showPassword = _c[0], setShowPassword = _c[1];
    var _d = __read(useState(false), 2), showConfirmPassword = _d[0], setShowConfirmPassword = _d[1];
    useEffect(function () {
        if (password && passwordConfirmation) {
            setMismatchPasswordError(password !== passwordConfirmation);
        }
    }, [password, passwordConfirmation, setMismatchPasswordError]);
    var renderInputIcon = function () {
        if (mismatchPasswordError) {
            return;
        }
        if (showConfirmPassword) {
            return 'hide-password';
        }
        return 'show-password';
    };
    var getError = function () {
        if (mismatchPasswordError) {
            return 'Passwords do not match';
        }
        if (!!error) {
            return error;
        }
        return null;
    };
    var inputError = getError();
    return (React.createElement(InputsContainer, null,
        React.createElement(Input, { variant: "outline", value: password, type: showPassword ? '' : 'password', label: "New Password", placeholder: "New Password", onChange: function (e) { return onPasswordChange(e.target.value); }, rightIcon: showPassword ? 'hide-password' : 'show-password', onRightIconClicked: function () { return setShowPassword(!showPassword); } }),
        React.createElement(Input, { variant: "outline", value: passwordConfirmation, type: showConfirmPassword ? '' : 'password', label: "Confirm New Password", placeholder: "Confirm New Password", onChange: function (e) { return onConfirmationChange(e.target.value); }, rightIcon: renderInputIcon(), onRightIconClicked: function () { return setShowConfirmPassword(!showConfirmPassword); }, onFocus: function () { return setMismatchPasswordError(false); }, hasError: !!inputError, footerLabel: inputError || '' })));
};
var InputsContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  gap: 24px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  gap: 24px;\n"])));
var templateObject_1;

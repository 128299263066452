var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { Icon, IconButton, Typography } from 'designSystem';
import styled, { useTheme } from 'styled-components';
import { levelDesignConfig } from 'utils/levelDesignConfig';
import { useModal } from 'context';
import ModalTypeEnum from '../../../../../types/enums/ModalTypeEnum';
import { stringReplaceWithJSX } from '../../../../../utils/replaceWithJSX';
export var EvoluxLevel = {
    LEVEL_0: 'LEVEL_0',
    LEVEL_1: 'LEVEL_1',
    LEVEL_2: 'LEVEL_2',
    LEVEL_3: 'LEVEL_3',
    LEVEL_4: 'LEVEL_4',
    LEVEL_5: 'LEVEL_5',
    LEVEL_6: 'LEVEL_6',
    LEVEL_7: 'LEVEL_7',
    LEVEL_8: 'LEVEL_8',
    CUSTOM: 'CUSTOM',
};
export function BenefitItem(_a) {
    var _b;
    var currentLevel = _a.currentLevel, icon = _a.icon, header = _a.header, content = _a.content, url = _a.url, isLocked = _a.isLocked, filled = _a.filled;
    var openModal = useModal().openModal;
    var theme = useTheme();
    var parsedContent = stringReplaceWithJSX(content, [
        { match: /\n/g, replace: React.createElement("br", null) },
        { match: '#LINK#', replace: SwagLinkContent },
    ]);
    return (React.createElement(BenefitItemContainer, null,
        React.createElement(BenefitIconContainer, { isLocked: isLocked, currentLevel: currentLevel, filled: filled },
            React.createElement(Icon, { name: icon, size: filled ? 36 : 20, color: isLocked
                    ? theme.colors.text.alt5
                    : (_b = levelDesignConfig[currentLevel]) === null || _b === void 0 ? void 0 : _b.textColor })),
        React.createElement(BenefitTextContainer, null,
            React.createElement(Typography, { variant: "headlineSmall" }, header),
            React.createElement(Typography, { variant: "footnote", color: "alt" }, parsedContent.map(function (v) { return v; }))),
        url && (React.createElement(PlayButton, { variant: "quaternary", onClick: function () {
                openModal(ModalTypeEnum.BENEFIT_VIDEO_PLAYER, {
                    vimeoUrl: url,
                });
            } },
            React.createElement(Icon, { size: 14, name: "play", color: "inherit" })))));
}
var BenefitItemContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  padding: 14px 24px;\n  align-items: center;\n  margin: 0 0 16px 16px;\n  @media screen and (max-width: ", "px) {\n    margin: 4px 0;\n  }\n  ", ";\n"], ["\n  display: flex;\n  padding: 14px 24px;\n  align-items: center;\n  margin: 0 0 16px 16px;\n  @media screen and (max-width: ", "px) {\n    margin: 4px 0;\n  }\n  ", ";\n"])), function (props) { return props.theme.breakpoints.md; }, function (props) { return props.theme.card.alt; });
var BenefitIconContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-right: 16px;\n  padding: ", ";\n  border-radius: 100%;\n  background-color: ", ";\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-right: 16px;\n  padding: ", ";\n  border-radius: 100%;\n  background-color: ", ";\n"])), function (props) { return (props.filled ? '0px' : '8px'); }, function (props) {
    var _a;
    return props.isLocked
        ? props.theme.colors.bg.alt
        : (_a = levelDesignConfig[props === null || props === void 0 ? void 0 : props.currentLevel]) === null || _a === void 0 ? void 0 : _a.baseColor;
});
var BenefitTextContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
var PlayButton = styled(IconButton)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-left: auto;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 24px;\n  width: 24px;\n\n  background: ", ";\n  color: ", ";\n  & > span {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n"], ["\n  margin-left: auto;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 24px;\n  width: 24px;\n\n  background: ", ";\n  color: ", ";\n  & > span {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n"])), function (props) { return props.theme.colors.basic.black; }, function (props) { return props.theme.colors.basic.white; });
var ExternalLink = styled.a(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  text-decoration: none;\n"], ["\n  text-decoration: none;\n"])));
var SwagLinkContent = (React.createElement(ExternalLink, { rel: "noreferrer", href: "https://custompoint.rrd.com/xs2/prelogin?CMPID=7959&qwerty=23030201", target: "_blank" },
    React.createElement(Typography, { variant: "footnote", color: "link" }, "here")));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;

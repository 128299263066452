import React from 'react';
export var Lock = function (_a) {
    var color = _a.color;
    return (React.createElement("svg", { width: "20", height: "21", viewBox: "0 0 20 21", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0_358_9397)" },
            React.createElement("path", { d: "M15.6256 8.125H4.37418C3.68374 8.125 3.12402 8.68472 3.12402 9.37516V18.1263C3.12402 18.8167 3.68374 19.3764 4.37418 19.3764H15.6256C16.3161 19.3764 16.8758 18.8167 16.8758 18.1263V9.37516C16.8758 8.68472 16.3161 8.125 15.6256 8.125Z", stroke: color, strokeWidth: "1.25016", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { d: "M5.62402 8.12498V4.99958C5.62402 3.83911 6.08502 2.72617 6.90559 1.90559C7.72617 1.08502 8.83911 0.624023 9.99958 0.624023C11.16 0.624023 12.273 1.08502 13.0936 1.90559C13.9141 2.72617 14.3751 3.83911 14.3751 4.99958V8.12498", stroke: color, strokeWidth: "1.25016", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { d: "M10.0006 13.126C9.93867 13.126 9.87814 13.1443 9.82665 13.1787C9.77516 13.2131 9.73503 13.262 9.71133 13.3193C9.68764 13.3765 9.68144 13.4394 9.69352 13.5002C9.7056 13.5609 9.73542 13.6167 9.7792 13.6605C9.82299 13.7043 9.87878 13.7341 9.93951 13.7462C10.0002 13.7582 10.0632 13.752 10.1204 13.7283C10.1776 13.7046 10.2265 13.6645 10.2609 13.613C10.2953 13.5615 10.3137 13.501 10.3137 13.4391C10.3138 13.3979 10.3058 13.3572 10.2901 13.3191C10.2744 13.2811 10.2513 13.2465 10.2222 13.2174C10.1931 13.1883 10.1586 13.1653 10.1205 13.1496C10.0825 13.1339 10.0417 13.1259 10.0006 13.126V13.126Z", stroke: color, strokeWidth: "1.25016", strokeLinecap: "round", strokeLinejoin: "round" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_358_9397" },
                React.createElement("rect", { width: "20.0025", height: "20.0025", fill: "white", transform: "translate(-0.000976562 -0.000976562)" })))));
};

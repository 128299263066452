var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import CompletionLinkSendFailure from '../../CompletionLinkSendFailure';
import { Icon, Typography } from 'designSystem';
import { StepContainer } from '../../StepContainer';
import CheckInProgress from '../../CheckInProgress';
import { useScreenSizing } from 'hooks';
import { PhoneNumber } from '../../PhoneNumber';
export var SendingMessageView = function (_a) {
    var error = _a.error, phoneNumber = _a.phoneNumber, email = _a.email, handleRestart = _a.handleRestart;
    var isMobile = useScreenSizing().isMobile;
    return (React.createElement(React.Fragment, null,
        React.createElement(StepContainer, { label: email ? 'Sending Email' : 'Sending Text Message', handleRestart: handleRestart },
            React.createElement(SendingContainer, null, error ? (React.createElement(CompletionLinkSendFailure, null)) : (React.createElement(React.Fragment, null,
                React.createElement(Icon, { name: "loading", size: 32 }),
                React.createElement(Typography, { mt: 30, variant: "subtitle" },
                    "Sending ",
                    email ? 'email' : 'text message',
                    " to",
                    ' ', email !== null && email !== void 0 ? email : React.createElement(PhoneNumber, { phoneNumber: phoneNumber })))))),
        React.createElement(CheckInProgress, { onContinue: function () { }, hidePrimaryButton: true, tertiaryAction: handleRestart, tertiaryButtonText: isMobile && 'Restart Enrollment' })));
};
var SendingContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 36px;\n  background-color: ", ";\n\n  border: 1px solid ", ";\n  @media screen and (max-width: ", "px) {\n    padding: 0;\n    background: transparent;\n    border: none;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 36px;\n  background-color: ", ";\n\n  border: 1px solid ", ";\n  @media screen and (max-width: ", "px) {\n    padding: 0;\n    background: transparent;\n    border: none;\n  }\n"])), function (props) { return props.theme.colors.bg.alt3; }, function (props) { return props.theme.colors.border.base; }, function (props) { return props.theme.breakpoints.md; });
var templateObject_1;

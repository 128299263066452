var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { StepContainer } from '../StepContainer';
import { SubHeaderByOption } from '../../enums/SubHeaderByOption';
import { AccountPaymentMethods } from 'components/AccountPaymentMethods';
import styled from 'styled-components';
import { PaymentProgress } from '../PaymentProgress';
import { useScreenSizing } from '../../../../../../hooks';
import { MobileContinueButton } from '../MobileContinueButton';
export var SelectPayment = function (_a) {
    var paymentOption = _a.paymentOption, onContinue = _a.onContinue;
    var isMobile = useScreenSizing().isMobile;
    var _b = __read(useState(null), 2), selectedPayment = _b[0], setSelectedPayment = _b[1];
    var handleContinue = function () { return onContinue(selectedPayment); };
    return (React.createElement(React.Fragment, null,
        React.createElement(StepContainer, { subHeader: SubHeaderByOption[paymentOption], header: "Select Payment Method", tertiaryHeader: "Select a payment method below" },
            React.createElement(Container, null,
                React.createElement(AccountPaymentMethods, { selectedPaymentMethod: selectedPayment, onSelect: function (pm) { return setSelectedPayment(pm); }, hideCredit: true, hideDiscount: true, fromSelectPayment: true })),
            isMobile && (React.createElement(MobileContinueButton, { label: "Review Payment", onContinue: handleContinue, disabled: !selectedPayment }))),
        !isMobile && (React.createElement(PaymentProgress, { paymentOption: paymentOption, primaryButtonLabel: "Review Payment", primaryAction: handleContinue, primaryButtonDisabled: !selectedPayment }))));
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 32px;\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n"], ["\n  margin-top: 32px;\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n"])));
var templateObject_1;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { Button, Icon, Typography } from '../../../../../../designSystem';
import styled from 'styled-components';
var filterByValue = function (val) { return function (item) { return item.value !== val; }; };
export var TableFilters = function (_a) {
    var facilities = _a.facilities, injectors = _a.injectors, types = _a.types, setTypes = _a.setTypes, setFacilities = _a.setFacilities, setInjectors = _a.setInjectors;
    return (React.createElement(FiltersContainer, null, facilities === null || facilities === void 0 ? void 0 :
        facilities.map(function (f) {
            return (React.createElement(FilterChip, { key: f.value, name: f.label, onRemove: function () {
                    setFacilities(facilities.filter(filterByValue(f.value)));
                } }));
        }), injectors === null || injectors === void 0 ? void 0 :
        injectors.map(function (i, index) {
            return (React.createElement(FilterChip, { key: index, name: i.label, onRemove: function () {
                    setInjectors(injectors.filter(filterByValue(i.value)));
                } }));
        }), types === null || types === void 0 ? void 0 :
        types.map(function (t) {
            return (React.createElement(FilterChip, { key: t.value, name: t.label, onRemove: function () {
                    setTypes(types.filter(filterByValue(t.value)));
                } }));
        })));
};
var FilterChip = function (props) {
    var name = props.name, onRemove = props.onRemove;
    return (React.createElement(FilterChipContainer, null,
        React.createElement(Typography, { variant: "caption" }, name),
        React.createElement(Button, { noPadding: true, variant: "tertiary", onClick: onRemove, endIcon: React.createElement(Icon, { name: "clear" }) })));
};
var FilterChipContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 8px 12px;\n  display: flex;\n  align-items: center;\n  background: ", ";\n\n  gap: 8px;\n"], ["\n  padding: 8px 12px;\n  display: flex;\n  align-items: center;\n  background: ", ";\n\n  gap: 8px;\n"])), function (props) { return props.theme.colors.bg.base; });
var FiltersContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  gap: 20px;\n  margin-top: 32px;\n"], ["\n  display: flex;\n  gap: 20px;\n  margin-top: 32px;\n"])));
var templateObject_1, templateObject_2;

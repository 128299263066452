var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from 'styled-components';
export var elevation = {
    base: {
        elevation1: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      box-shadow: 0px 2px 4px rgba(207, 208, 208, 0.25),\n        0px 20px 40px rgba(155, 155, 155, 0.11);\n    "], ["\n      box-shadow: 0px 2px 4px rgba(207, 208, 208, 0.25),\n        0px 20px 40px rgba(155, 155, 155, 0.11);\n    "]))),
        elevation2: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      box-shadow: 0px -2px 4px rgba(207, 208, 208, 0.248416),\n        0px -20px 40px rgba(155, 155, 155, 0.11);\n    "], ["\n      box-shadow: 0px -2px 4px rgba(207, 208, 208, 0.248416),\n        0px -20px 40px rgba(155, 155, 155, 0.11);\n    "]))),
        elevation3: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      box-shadow: 0px -20px 40px rgba(155, 155, 155, 0.25);\n    "], ["\n      box-shadow: 0px -20px 40px rgba(155, 155, 155, 0.25);\n    "]))),
        elevation4: css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      box-shadow: 0px -2px 4px rgba(207, 208, 208, 0.25),\n        0px -20px 40px rgba(155, 155, 155, 0.11),\n        0px 2px 4px rgba(207, 208, 208, 0.25),\n        0px 20px 40px rgba(155, 155, 155, 0.11);\n    "], ["\n      box-shadow: 0px -2px 4px rgba(207, 208, 208, 0.25),\n        0px -20px 40px rgba(155, 155, 155, 0.11),\n        0px 2px 4px rgba(207, 208, 208, 0.25),\n        0px 20px 40px rgba(155, 155, 155, 0.11);\n    "]))),
        error: css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      box-shadow: 0px 0px 0px 3px #f9d6d5;\n    "], ["\n      box-shadow: 0px 0px 0px 3px #f9d6d5;\n    "]))),
    },
    button: {
        elevation1: css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n      box-shadow: 0px 6px 14px rgba(242, 76, 70, 0.5);\n    "], ["\n      box-shadow: 0px 6px 14px rgba(242, 76, 70, 0.5);\n    "]))),
        elevation2: css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n      box-shadow: 0px 8px 14px rgba(242, 76, 70, 0.4);\n    "], ["\n      box-shadow: 0px 8px 14px rgba(242, 76, 70, 0.4);\n    "]))),
    },
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { UpcomingRewardsDownloader } from '../RewardsDashboard/components/UpcomingRewardsDownloader';
var UpcomingRewards = function () {
    return (React.createElement(OuterContainer, null,
        React.createElement(UpcomingRewardsDownloader, null)));
};
export default UpcomingRewards;
var OuterContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  justify-content: flex-start;\n  background-color: white;\n  margin: 16px;\n\n  padding: 48px;\n  position: relative;\n  min-height: 65vh;\n  @media only screen and (max-width: ", "px) {\n    min-height: unset;\n    margin: 16px 0 0;\n    padding: 0;\n    border: none;\n  }\n"], ["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  justify-content: flex-start;\n  background-color: white;\n  margin: 16px;\n\n  padding: 48px;\n  position: relative;\n  min-height: 65vh;\n  @media only screen and (max-width: ", "px) {\n    min-height: unset;\n    margin: 16px 0 0;\n    padding: 0;\n    border: none;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var templateObject_1;

var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import { useUser } from 'context/UserContext';
import { OrderHistoryView } from './View';
export var OrderHistoryContainer = function () {
    var _a, _b, _c;
    var account = useUser().account;
    var _d = __read(useState([]), 2), filters = _d[0], setFilters = _d[1];
    var _e = __read(useState([{ field: 'orderDate', direction: 'DESC' }]), 2), sort = _e[0], setSort = _e[1];
    var _f = __read(useState(''), 2), searchText = _f[0], setSearchText = _f[1];
    var _g = __read(useState(0), 2), page = _g[0], setPage = _g[1];
    var hasPastDue = false;
    useEffect(function () {
        setPage(0);
    }, [filters, sort, setPage, searchText]);
    return (React.createElement(OrderHistoryView, { accountId: (account === null || account === void 0 ? void 0 : account.id) || '', error: undefined, loading: false, totalOrders: (_b = (_a = account === null || account === void 0 ? void 0 : account.orders) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0, orders: (_c = account === null || account === void 0 ? void 0 : account.orders) !== null && _c !== void 0 ? _c : [], refetch: function () { }, searchText: searchText, setSearchText: setSearchText, setFilters: setFilters, filters: filters, setPage: setPage, setSort: setSort, page: page, hasDue: hasPastDue }));
};
export default OrderHistoryContainer;

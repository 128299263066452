import React from 'react';
export var Cupcake = function (_a) {
    var color = _a.color;
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 48 48", width: "48", height: "48", fill: "none" },
        React.createElement("g", { stroke: color, strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 3, clipPath: "url(#cupcake-svg-clip)" },
            React.createElement("path", { d: "M38.488 33.716 36.2 44.056a3.122 3.122 0 0 1-3.046 2.444h-18.4a3.118 3.118 0 0 1-3.046-2.444L9.4 33.712" }),
            React.createElement("path", { d: "M18 14.338C10.884 15.786 4.446 19.9 4.446 27a7 7 0 0 0 13.26 3.154 6.969 6.969 0 0 0 12.48 0A7 7 0 0 0 43.446 27c0-7.096-6.438-11.212-13.558-12.662" }),
            React.createElement("path", { d: "M17.946 13.5a6 6 0 1 0 12.002 0 6 6 0 0 0-12.002 0Z" }),
            React.createElement("path", { d: "M23.946 7.5a5.303 5.303 0 0 1 6-6M17.086 31.16l2.36 15.34M30.806 31.16l-2.36 15.34" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "cupcake-svg-clip" },
                React.createElement("path", { fill: "#fff", d: "M0 0h48v48H0z" })))));
};

export var passwordVerification = function (text, oldPassword) {
    var requirements = {
        uppercase: /[A-Z]/,
        lowercase: /[a-z]/,
        minimum: /^.{8,}/,
        number: /[0-9]/,
        full: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
        samePassword: text === oldPassword,
    };
    var passwordRequirements = {};
    passwordRequirements.uppercase = requirements.uppercase.test(text);
    passwordRequirements.lowercase = requirements.lowercase.test(text);
    passwordRequirements.minimum = requirements.minimum.test(text);
    passwordRequirements.number = requirements.number.test(text);
    passwordRequirements.misMatch = !requirements.samePassword;
    return {
        passwordRequirements: passwordRequirements,
        allTestsPassing: requirements.full.test(text) && passwordRequirements.misMatch,
    };
};

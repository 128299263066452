var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var TableColumn = styled.td(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  text-align: ", ";\n  padding: 16px 20px;\n  font-weight: ", ";\n  font-size: ", "px;\n  color: ", ";\n  height: 64px;\n"], ["\n  text-align: ", ";\n  padding: 16px 20px;\n  font-weight: ", ";\n  font-size: ", "px;\n  color: ", ";\n  height: 64px;\n"])), function (props) { var _a; return (_a = props === null || props === void 0 ? void 0 : props.textAlign) !== null && _a !== void 0 ? _a : 'left'; }, function (props) {
    return props.bolded
        ? props.theme.typography.weight.bold
        : props.theme.typography.weight.regular;
}, function (props) { return props.theme.typography.size.m1; }, function (props) { return props.theme.colors.text.alt; });
var templateObject_1;

import { SensitivePaymentType } from 'types/types';
export var PaymentMethodType;
(function (PaymentMethodType) {
    PaymentMethodType["primary"] = "primary";
    PaymentMethodType["secondary"] = "secondary";
})(PaymentMethodType || (PaymentMethodType = {}));
export var getSelectedPaymentMethod = function (paymentMethod, paymentType) {
    if ((paymentMethod === null || paymentMethod === void 0 ? void 0 : paymentMethod.length) && (paymentMethod === null || paymentMethod === void 0 ? void 0 : paymentMethod.length) > 1) {
        return paymentType === PaymentMethodType.primary
            ? paymentMethod === null || paymentMethod === void 0 ? void 0 : paymentMethod.find(function (pm) { return pm.sensitive.type !== SensitivePaymentType.Loc; })
            : paymentMethod === null || paymentMethod === void 0 ? void 0 : paymentMethod.find(function (pm) { return pm.sensitive.type === SensitivePaymentType.Loc; });
    }
    else if ((paymentMethod === null || paymentMethod === void 0 ? void 0 : paymentMethod.length) &&
        paymentType === PaymentMethodType.secondary) {
        return;
    }
    else {
        return paymentMethod && paymentMethod[0];
    }
};

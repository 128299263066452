var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled, { css } from 'styled-components';
import { Button, Typography } from 'designSystem';
import { useNavigate } from 'react-router';
import { cardStyles } from '../constants/CardStyles';
import { PaymentHistoryItem } from '../../../components/PaymentHistoryItem';
import { addMobileStyles } from 'utils/addMobileStyles';
import { stackedCardStyles } from '../../../../../components/StackedMobileCard';
import { useScreenSizing } from 'hooks';
import { EmptyHistory } from '../../../components/EmptyHistory';
export var InvoiceHistoryCard = function (_a) {
    var invoicePayments = _a.invoicePayments;
    var navigate = useNavigate();
    var isMobile = useScreenSizing().isMobile;
    var renderTable = function () {
        if (!(invoicePayments === null || invoicePayments === void 0 ? void 0 : invoicePayments.length)) {
            return React.createElement(EmptyHistory, null);
        }
        return (React.createElement(TableContainer, null,
            React.createElement("div", null,
                React.createElement(Typography, { variant: "headlineSmall", color: isMobile ? 'alt5' : 'alt' }, isMobile ? 'Most Recent' : 'Recent Payments')),
            React.createElement("div", null, invoicePayments.map(function (ip) { return (React.createElement(PaymentHistoryItem, { key: ip.paymentId, invoicePayment: ip })); }))));
    };
    return (React.createElement(Container, null,
        React.createElement("div", null,
            React.createElement(Typography, { variant: isMobile ? 'headline' : 'titleLarge' }, "Invoice Payment History"),
            React.createElement(Button, { variant: isMobile ? 'tertiary' : 'outlined', onClick: function () { return navigate('/invoices/history'); } }, "View All")),
        renderTable()));
};
var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  display: flex;\n  flex-direction: column;\n  padding: 48px;\n  & > div:first-of-type {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    margin-bottom: 32px;\n  }\n  ", "\n"], ["\n  ", ";\n  display: flex;\n  flex-direction: column;\n  padding: 48px;\n  & > div:first-of-type {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    margin-bottom: 32px;\n  }\n  ", "\n"])), cardStyles, function (_a) {
    var theme = _a.theme;
    return addMobileStyles(css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      ", ";\n      padding: 32px;\n      margin-top: -44px;\n      ", ";\n      & > div:first-of-type {\n        margin-bottom: 16px;\n      }\n    "], ["\n      ", ";\n      padding: 32px;\n      margin-top: -44px;\n      ", ";\n      & > div:first-of-type {\n        margin-bottom: 16px;\n      }\n    "])), stackedCardStyles, theme.elevation.base.elevation2));
});
var TableContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  & > div:first-of-type {\n    padding: 20px 24px;\n    margin-bottom: 16px;\n    ", ";\n  }\n  & > div {\n    display: flex;\n    flex-direction: column;\n    flex: 1;\n    width: 100%;\n  }\n\n  ", "\n"], ["\n  ", ";\n  & > div:first-of-type {\n    padding: 20px 24px;\n    margin-bottom: 16px;\n    ", ";\n  }\n  & > div {\n    display: flex;\n    flex-direction: column;\n    flex: 1;\n    width: 100%;\n  }\n\n  ", "\n"])), function (props) { return props.theme.borders.base; }, function (props) { return props.theme.borders.base; }, addMobileStyles(css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    border: none;\n    & > div:first-of-type {\n      border: none;\n      padding: 0;\n    }\n    & > div:last-of-type {\n      gap: 8px;\n    }\n  "], ["\n    border: none;\n    & > div:first-of-type {\n      border: none;\n      padding: 0;\n    }\n    & > div:last-of-type {\n      gap: 8px;\n    }\n  "])))));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Desktop, Mobile, Tag, Typography, RadioButton } from 'designSystem';
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { levelName } from 'utils/levelName';
import { useScreenSizing } from 'hooks';
import jeaveauVial from 'assets/jeuveau-shadow.png';
import { OrderQuantityType } from 'types/enums';
export var BundleSelector = function (_a) {
    var boostEligible = _a.boostEligible, bulkRates = _a.bulkRates, currentLevel = _a.currentLevel, defaultRate = _a.defaultRate, msrp = _a.msrp, orderData = _a.orderData, pricingModel = _a.pricingModel, setSelectedBulkRate = _a.setSelectedBulkRate;
    var isMobile = useScreenSizing().isMobile;
    var renderPromoBadges = function (bulkRate) {
        var quantity = bulkRate.quantity;
        if (!pricingModel.includes('CUSTOM_') && boostEligible) {
            return (React.createElement(BulkRateTagContainer, null,
                React.createElement(Tag, { text: "Evolux Boost", subtext: "Applied at Checkout", variant: "success", size: isMobile ? 'small' : 'large', "data-automation-id": "order-bundle-bulk-rate-boost" })));
        }
        if (quantity === 15) {
            return (React.createElement(BulkRateTagContainer, null,
                React.createElement(Tag, { text: "Go For Gold", size: isMobile ? 'small' : 'large', "data-automation-id": "order-bundle-bulk-rate-gold" })));
        }
        return null;
    };
    var sortedBulkRates = useMemo(function () { return bulkRates.sort(function (a, b) { return b.quantity - a.quantity; }); }, [bulkRates]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Mobile, null,
            React.createElement(JeaveauVials, null,
                React.createElement(JeaveauVialImage, { src: jeaveauVial }),
                React.createElement(JeaveauVialImage, { src: jeaveauVial }),
                React.createElement(JeaveauVialImage, { src: jeaveauVial }),
                React.createElement(JeaveauVialImage, { src: jeaveauVial }),
                React.createElement(JeaveauVialImage, { src: jeaveauVial })),
            React.createElement(Typography, { variant: "footnote", align: "center", style: { marginBottom: 12 } }, "Order by 4pm PST to ship same day (M \u2014 Th)")),
        React.createElement(OuterContainer, null,
            React.createElement(ContainerBody, null,
                React.createElement(Desktop, null,
                    React.createElement(PricingInformationContainer, null,
                        React.createElement(JeaveauVialImageContainer, null,
                            React.createElement(JeaveauVialImage, { src: jeaveauVial })),
                        React.createElement(PricingInfoInnerContainer, null,
                            React.createElement(YourPriceContainer, null,
                                React.createElement(Typography, { variant: "headline", color: "alt" },
                                    "Your Price:",
                                    ' '),
                                React.createElement(Typography, { variant: "titleLarge", color: "link", "data-automation-id": "order-bundle-bulk-rate" },
                                    "$",
                                    defaultRate,
                                    ' '),
                                React.createElement(Typography, { variant: "headline", color: "link" }, "/ 100 unit vial")),
                            React.createElement(Typography, { variant: "headline", color: "alt5" },
                                "List Price: ",
                                React.createElement(CrossOut, null,
                                    "$",
                                    msrp))))),
                React.createElement(BundleSelectorContainer, null,
                    React.createElement(BundleSelectorInnerContainer, { "data-automation-id": "order-bundle-rates-container" }, sortedBulkRates.map(function (rate) {
                        var _a, _b, _c, _d, _e, _f, _g;
                        return (React.createElement(BulkRateItemContainer, { key: rate.quantity, active: (orderData === null || orderData === void 0 ? void 0 : orderData.quantity) === rate.quantity &&
                                (orderData === null || orderData === void 0 ? void 0 : orderData.orderType) === OrderQuantityType.BULK, onClick: function () { return setSelectedBulkRate(rate); }, "data-automation-id": "order-bundle-rate-".concat(rate.quantity), "data-selected": (orderData === null || orderData === void 0 ? void 0 : orderData.quantity) === rate.quantity &&
                                (orderData === null || orderData === void 0 ? void 0 : orderData.orderType) === OrderQuantityType.BULK },
                            React.createElement(BulkRateItemLeft, null,
                                React.createElement(RadioButton, { checked: (orderData === null || orderData === void 0 ? void 0 : orderData.quantity) === rate.quantity &&
                                        (orderData === null || orderData === void 0 ? void 0 : orderData.orderType) === OrderQuantityType.BULK, onChange: function () { return setSelectedBulkRate(rate); } }),
                                React.createElement(QuantityBadge, null,
                                    React.createElement(QuantityValueText, null, rate.quantity),
                                    React.createElement(QuantitySubtext, null, "Vials")),
                                React.createElement(BulkRatePricingContainer, null,
                                    React.createElement(Typography, { variant: isMobile ? 'headlineSmall' : 'headline' },
                                        React.createElement(CrossOut, null,
                                            "$",
                                            msrp),
                                        React.createElement("span", { "data-automation-id": "order-bundle-rate-".concat(rate.quantity, "-rate") },
                                            "$",
                                            rate.rate),
                                        "/vial"),
                                    rate.loyaltyLevel &&
                                        pricingModel.includes('_LOYALTY') &&
                                        currentLevel !== rate.loyaltyLevel && (React.createElement(BulkRateLevelUpgrade, { "data-automation-id": "order-bundle-rate-".concat(rate.quantity, "-instant-upgrade"), "data-upgraded-level": levelName(rate.loyaltyLevel) },
                                        "Instant Upgrade to ",
                                        levelName(rate.loyaltyLevel))))),
                            renderPromoBadges(rate),
                            rate.discount && (React.createElement(BulkRateTagContainer, null,
                                React.createElement(Tag, { text: (_b = (_a = rate === null || rate === void 0 ? void 0 : rate.discount) === null || _a === void 0 ? void 0 : _a.badge) === null || _b === void 0 ? void 0 : _b.text, size: isMobile ? 'small' : 'large', "data-automation-id": "order-bundle-".concat((_c = rate === null || rate === void 0 ? void 0 : rate.discount) === null || _c === void 0 ? void 0 : _c.discountId), textColor: (_e = (_d = rate === null || rate === void 0 ? void 0 : rate.discount) === null || _d === void 0 ? void 0 : _d.badge) === null || _e === void 0 ? void 0 : _e.textColor, badgeColor: (_g = (_f = rate === null || rate === void 0 ? void 0 : rate.discount) === null || _f === void 0 ? void 0 : _f.badge) === null || _g === void 0 ? void 0 : _g.backgroundColor })))));
                    })))))));
};
var OuterContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
var PricingInformationContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  margin-right: 32px;\n\n  @media only screen and (max-width: ", "px) {\n    margin-right: 0;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  margin-right: 32px;\n\n  @media only screen and (max-width: ", "px) {\n    margin-right: 0;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var JeaveauVialImageContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: white;\n  ", ";\n\n  padding: 28px 98px;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: white;\n  ", ";\n\n  padding: 28px 98px;\n"])), function (props) { return props.theme.elevation.base.elevation1; });
var JeaveauVials = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  margin-bottom: 32px;\n  align-items: center;\n  justify-content: center;\n"], ["\n  display: flex;\n  margin-bottom: 32px;\n  align-items: center;\n  justify-content: center;\n"])));
var JeaveauVialImage = styled.img(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 84px;\n\n  @media only screen and (max-width: ", "px) {\n    width: 60px;\n  }\n"], ["\n  width: 84px;\n\n  @media only screen and (max-width: ", "px) {\n    width: 60px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var BundleSelectorContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  width: 100%; ;\n"], ["\n  display: flex;\n  width: 100%; ;\n"])));
var BundleSelectorInnerContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n\n  @media only screen and (max-width: ", "px) {\n    margin-left: -32px;\n    margin-right: -32px;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n\n  @media only screen and (max-width: ", "px) {\n    margin-left: -32px;\n    margin-right: -32px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var PricingInfoInnerContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
var YourPriceContainer = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  margin-top: 32px;\n  margin-bottom: 12px;\n"], ["\n  margin-top: 32px;\n  margin-bottom: 12px;\n"])));
var CrossOut = styled.span(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  text-decoration: line-through;\n  color: ", ";\n  margin-right: 5px;\n"], ["\n  text-decoration: line-through;\n  color: ", ";\n  margin-right: 5px;\n"])), function (props) { return props.theme.colors.text.alt5; });
var BulkRateItemContainer = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  cursor: pointer;\n  border: ", " solid\n    ", ";\n  display: flex;\n  flex: 1;\n  padding: 24px;\n  justify-content: space-between;\n\n  margin-bottom: 24px;\n\n  @media only screen and (max-width: ", "px) {\n    padding: 20px;\n    width: 100vw;\n\n    border: none;\n    border-bottom: 1px solid ", ";\n    margin: 0;\n\n    &:last-child {\n      border-bottom: none;\n    }\n\n    ", "\n  }\n"], ["\n  cursor: pointer;\n  border: ", " solid\n    ", ";\n  display: flex;\n  flex: 1;\n  padding: 24px;\n  justify-content: space-between;\n\n  margin-bottom: 24px;\n\n  @media only screen and (max-width: ", "px) {\n    padding: 20px;\n    width: 100vw;\n\n    border: none;\n    border-bottom: 1px solid ", ";\n    margin: 0;\n\n    &:last-child {\n      border-bottom: none;\n    }\n\n    ", "\n  }\n"])), function (props) { return (props.active ? '2px' : '1px'); }, function (props) {
    return props.active
        ? props.theme.colors.border.error2
        : props.theme.colors.border.base;
}, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
}, function (props) { return props.theme.colors.border.base; }, function (props) {
    return props.active && css(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n        border-top: 2px solid ", ";\n        border-bottom: 2px solid ", ";\n      "], ["\n        border-top: 2px solid ", ";\n        border-bottom: 2px solid ", ";\n      "])), props.theme.colors.border.error2, props.theme.colors.border.error2);
});
var BulkRateItemLeft = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  display: flex;\n  flex: 2;\n  align-items: center;\n"], ["\n  display: flex;\n  flex: 2;\n  align-items: center;\n"])));
var QuantityBadge = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  border: 1px solid ", ";\n  display: flex;\n  flex-direction: column;\n\n  padding: 8px 12px;\n  justify-content: center;\n  align-items: center;\n  margin: 0 16px;\n\n  @media only screen and (max-width: ", "px) {\n    margin: 0 12px 0 0;\n  }\n"], ["\n  border: 1px solid ", ";\n  display: flex;\n  flex-direction: column;\n\n  padding: 8px 12px;\n  justify-content: center;\n  align-items: center;\n  margin: 0 16px;\n\n  @media only screen and (max-width: ", "px) {\n    margin: 0 12px 0 0;\n  }\n"])), function (props) { return props.theme.colors.border.base; }, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var QuantityValueText = styled.span(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  font-size: 1.125rem;\n  color: ", ";\n"], ["\n  font-size: 1.125rem;\n  color: ", ";\n"])), function (props) { return props.theme.colors.text.link; });
var QuantitySubtext = styled.span(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  font-size: 0.625rem;\n  font-weight: 600;\n"], ["\n  font-size: 0.625rem;\n  font-weight: 600;\n"])));
var BulkRatePricingContainer = styled.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  width: 150px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  width: 150px;\n"])));
var BulkRateLevelUpgrade = styled.span(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  color: ", ";\n  margin-top: 5px;\n"], ["\n  color: ", ";\n  margin-top: 5px;\n"])), function (props) { return props.theme.colors.text.alt; });
var BulkRateTagContainer = styled.div(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  justify-content: flex-end;\n"], ["\n  display: flex;\n  flex: 1;\n  justify-content: flex-end;\n"])));
var ContainerBody = styled.div(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  display: flex;\n"], ["\n  display: flex;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20;

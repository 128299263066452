var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var NotActivatedChip = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: ", ";\n  color: ", ";\n  padding: 8px 12px;\n  width: fit-content;\n"], ["\n  background: ", ";\n  color: ", ";\n  padding: 8px 12px;\n  width: fit-content;\n"])), function (props) { return props.theme.colors.bg.error; }, function (props) { return props.theme.colors.text.link; });
export var ActiveChip = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background: ", ";\n  color: ", ";\n  padding: 8px 12px;\n  width: fit-content;\n"], ["\n  background: ", ";\n  color: ", ";\n  padding: 8px 12px;\n  width: fit-content;\n"])), function (props) { return props.theme.colors.bg.success2; }, function (props) { return props.theme.colors.aqua.aqua900; });
export var InactiveChip = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: ", ";\n  color: ", ";\n  padding: 8px 12px;\n  width: fit-content;\n"], ["\n  background: ", ";\n  color: ", ";\n  padding: 8px 12px;\n  width: fit-content;\n"])), function (props) { return props.theme.colors.bg.alert; }, function (props) { return props.theme.colors.text.alert; });
export var DeactivatedChip = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background: ", ";\n  color: ", ";\n  padding: 8px 12px;\n  width: fit-content;\n"], ["\n  background: ", ";\n  color: ", ";\n  padding: 8px 12px;\n  width: fit-content;\n"])), function (props) { return props.theme.colors.bg.alt; }, function (props) { return props.theme.colors.text.alt; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;

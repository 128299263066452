var ModalTypeEnum;
(function (ModalTypeEnum) {
    ModalTypeEnum["ACCOUNT_SELECTION"] = "AccountSelection";
    ModalTypeEnum["ACCOUNT_STATEMENTS_FILTERS"] = "AccountStatementsFilters";
    ModalTypeEnum["ADD_BANK_ACCOUNT"] = "AddBankAccount";
    ModalTypeEnum["ADD_CREDIT_CARD"] = "AddCreditCard";
    ModalTypeEnum["ADMIN_ACCESS_CONFIRMATION"] = "AdminAccessConfirmation";
    ModalTypeEnum["FACILITY_SELECTION"] = "FacilitySelection";
    ModalTypeEnum["ANNUAL_CAMPAIGN"] = "AnnualCampaign";
    ModalTypeEnum["APPLICATION_UPDATE"] = "ApplicationUpdate";
    ModalTypeEnum["AUTOMATIC_LEVEL_UPGRADE"] = "AutomaticLevelUpgrade";
    ModalTypeEnum["BENEFIT_VIDEO_PLAYER"] = "BenefitVideoPlayer";
    ModalTypeEnum["COMPLETE_PROFILE"] = "CompleteProfile";
    ModalTypeEnum["DOWN_FOR_MAINTENANCE"] = "DownForMaintenance";
    ModalTypeEnum["EDIT_PAYMENT_METHOD"] = "EditPaymentMethod";
    ModalTypeEnum["REMOVE_PAYMENT_METHOD"] = "RemovePaymentMethod";
    ModalTypeEnum["REMOVE_USER"] = "RemoveUser";
    ModalTypeEnum["OPT_IN_INSUFFICIENT_VIALS"] = "OptInInsufficientVials";
    ModalTypeEnum["OPT_IN_OUT_EVOLUS_REWARDS"] = "OptInOutEvolusRewards";
    ModalTypeEnum["REMOVE_PROMO_CODE"] = "RemovePromoCode";
    ModalTypeEnum["REQUIRE_NEW_PASSWORD"] = "RequireNewPassword";
    ModalTypeEnum["RESTART_CHECK_IN"] = "RestartCheckIn";
    ModalTypeEnum["RESTART_ORDER"] = "RestartOrder";
    ModalTypeEnum["SYT_UPSELL"] = "SYT2UpsellModal";
    ModalTypeEnum["CHECK_IN_SUSPENDED"] = "CheckInSuspended";
    ModalTypeEnum["EVOLUX_LEVELS_LIST"] = "EvoluxLevelsList";
    ModalTypeEnum["SMS_OPTED_OUT"] = "EvolusSMSOptedOut";
    ModalTypeEnum["FORGOT_PASSWORD"] = "ForgotPassword";
    ModalTypeEnum["LEAVING_CHECK_IN"] = "LeavingCheckIn";
    ModalTypeEnum["PAYMENT_AUTH_ERROR"] = "PaymentAuthError";
    ModalTypeEnum["TRANSACTION_DETAILS_MODAL"] = "TransactionDetailsModal";
    ModalTypeEnum["TRANSACTION_HISTORY_FILTERS"] = "TransactionHistoryFilters";
    ModalTypeEnum["UNDER_CONSTRUCTION"] = "UnderConstruction";
    ModalTypeEnum["UNSIGNED_AGREEMENTS"] = "UnsignedAgreementsModal";
    ModalTypeEnum["SPECIFY_INVOICES"] = "SpecifyInvoices";
    ModalTypeEnum["INVOICE_PDF_VIEWER"] = "InvoicePdfViewer";
    ModalTypeEnum["PAYMENT_EXCEEDS_BALANCE"] = "PaymentExceedsBalance";
})(ModalTypeEnum || (ModalTypeEnum = {}));
export default ModalTypeEnum;

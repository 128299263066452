var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import format from 'date-fns/format';
import set from 'date-fns/set';
import { OrderStatus } from 'types/codegen/hooks';
import { Button, Card, CardBody, Desktop, Icon, Mobile, Stepper, Typography, } from 'designSystem';
import { MapView } from './components/Map';
import OrderPricingDetails from './components/OrderPricingDetails';
import { PlaceholderLoading } from 'designSystem/components/PlaceholderLoading';
import { parseISO } from 'date-fns';
import { downloadFromLink } from 'utils/downloadFromLink';
import { currencyFormatter } from 'utils/currencyFormatter';
function getPaymentMethodDescription(paymentMethod) {
    if (!paymentMethod)
        return null;
    if (paymentMethod.sensitive.ach) {
        return paymentMethod.sensitive.ach.accountNumber.replace(/.(?=.{4,}$)/g, '*');
    }
    if (paymentMethod.sensitive.cc) {
        return paymentMethod.sensitive.cc.number.replace(/.(?=.{4,}$)/g, '*');
    }
    if (paymentMethod.sensitive.loc) {
        return 'Evolus Credit';
    }
    return null;
}
function getCurrentStep(status) {
    switch (status) {
        case OrderStatus.Shipped: {
            return 2;
        }
        case OrderStatus.Accepted: {
            return 1;
        }
        default: {
            return 0;
        }
    }
}
var getTrackingNumberUrl = function (trackingNumber) {
    if (!trackingNumber) {
        return '';
    }
    if (trackingNumber.slice(0, 2) === '1Z') {
        return "https://www.ups.com/track?loc=en_US&tracknum=".concat(trackingNumber);
    }
    return "https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=".concat(trackingNumber, "&cntry_code=us&locale=en_US");
};
var openTrackingLink = function (trackingNumber) {
    window.open(getTrackingNumberUrl(trackingNumber), '_blank');
};
var getTrackingNumber = function (tracking) {
    if (Array.isArray(tracking) && (tracking === null || tracking === void 0 ? void 0 : tracking.length) > 0) {
        return tracking[0];
    }
    return tracking;
};
export var OrderSummaryView = function (_a) {
    var order = _a.order, orderDisplay = _a.orderDisplay, error = _a.error, loading = _a.loading, orderId = _a.orderId, getInvoicePDF = _a.getInvoicePDF, accountId = _a.accountId;
    var navigate = useNavigate();
    var _b = __read(useState(false), 2), invoiceIsLoading = _b[0], setInvoiceIsLoading = _b[1];
    var _c = __read(useState(null), 2), selectedInvoice = _c[0], setSelectedInvoice = _c[1];
    var getStepperStatus = useCallback(function () {
        if (!orderDisplay)
            return '';
        var status = orderDisplay.status;
        if (status === OrderStatus.OnHold)
            return 'On Hold';
        if (status === OrderStatus.Rejected)
            return 'Rejected';
        return 'Placed';
    }, [orderDisplay]);
    var steps = useMemo(function () { return [
        {
            id: 'step-0',
            name: getStepperStatus(),
        },
        {
            id: 'step-1',
            name: 'Received',
        },
        {
            id: 'step-2',
            name: 'Shipped',
        },
    ]; }, [getStepperStatus]);
    var renderLoading = function () {
        return (React.createElement(LoadingOuterContainer, null,
            React.createElement(LoadingInnerContainer, null,
                React.createElement(PlaceholderLoading, { size: "small" }))));
    };
    var generateInvoicePDF = function (invoiceId) { return __awaiter(void 0, void 0, void 0, function () {
        var data, e_1;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    setSelectedInvoice(invoiceId);
                    setInvoiceIsLoading(true);
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 3, 4, 5]);
                    return [4, getInvoicePDF({
                            variables: {
                                input: {
                                    invoiceId: invoiceId,
                                    accountId: accountId,
                                },
                            },
                        })];
                case 2:
                    data = (_c.sent()).data;
                    if ((_a = data === null || data === void 0 ? void 0 : data.getInvoicePdf) === null || _a === void 0 ? void 0 : _a.data) {
                        downloadFromLink({
                            url: "data:application/pdf;base64,".concat((_b = data === null || data === void 0 ? void 0 : data.getInvoicePdf) === null || _b === void 0 ? void 0 : _b.data),
                            downloadName: "".concat(invoiceId, ".pdf"),
                        });
                    }
                    return [3, 5];
                case 3:
                    e_1 = _c.sent();
                    console.log(e_1);
                    return [3, 5];
                case 4:
                    setSelectedInvoice(null);
                    setInvoiceIsLoading(false);
                    return [7];
                case 5: return [2];
            }
        });
    }); };
    var renderPrimaryCardAmount = function () {
        var _a, _b, _c;
        return ((_a = order === null || order === void 0 ? void 0 : order.billing) === null || _a === void 0 ? void 0 : _a.secondaryCardAmount)
            ? currencyFormatter((_b = order === null || order === void 0 ? void 0 : order.billing) === null || _b === void 0 ? void 0 : _b.secondaryCardAmount, false)
            : currencyFormatter((_c = order === null || order === void 0 ? void 0 : order.billing) === null || _c === void 0 ? void 0 : _c.primaryCardAmount, false);
    };
    var renderSecondaryCardAmount = function () {
        var _a, _b;
        return ((_a = order === null || order === void 0 ? void 0 : order.billing) === null || _a === void 0 ? void 0 : _a.secondaryCardAmount)
            ? currencyFormatter((_b = order === null || order === void 0 ? void 0 : order.billing) === null || _b === void 0 ? void 0 : _b.primaryCardAmount, false)
            : '$0.00';
    };
    var renderInvoices = function () {
        var _a, _b;
        if (!((_a = order === null || order === void 0 ? void 0 : order.invoices) === null || _a === void 0 ? void 0 : _a.length)) {
            return null;
        }
        return (React.createElement(OrderLargeLine, null,
            React.createElement(OrderLargeLineLeft, null,
                React.createElement(Typography, { variant: "headline", color: "alt" }, "Invoice Records")),
            React.createElement(InvoiceLinksContainer, null, (_b = order === null || order === void 0 ? void 0 : order.invoices) === null || _b === void 0 ? void 0 : _b.map(function (invoice) { return (React.createElement(InvoiceItem, { onClick: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4, generateInvoicePDF(invoice === null || invoice === void 0 ? void 0 : invoice.invoiceId)];
                        case 1: return [2, _a.sent()];
                    }
                }); }); } },
                React.createElement(Typography, { color: "link" },
                    "Download ",
                    invoice.invoiceId,
                    ".PDF"),
                invoiceIsLoading && (invoice === null || invoice === void 0 ? void 0 : invoice.invoiceId) === selectedInvoice && (React.createElement(LoaderIcon, { name: "loading" })))); }))));
    };
    var renderOrder = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z;
        var currentStep = getCurrentStep(order.status);
        var _0 = __read(('10:30').split(':'), 2), hour = _0[0], minute = _0[1];
        var deliveryTimeDate = set(new Date(), {
            hours: +hour,
            minutes: +minute,
        });
        return (React.createElement(OrderCard, null,
            React.createElement(OrderMap, null,
                React.createElement(MapView, { address: (_a = order === null || order === void 0 ? void 0 : order.shipping) === null || _a === void 0 ? void 0 : _a.address, facility: order.facility })),
            React.createElement(OrderContent, null,
                React.createElement(OrderSection, null,
                    React.createElement(Typography, { variant: "sectionHeader" }, "Order Summary"),
                    React.createElement(Mobile, null,
                        React.createElement(OrderLargeLine, { style: { marginTop: 12 } },
                            React.createElement(OrderLargeLineLeft, null,
                                React.createElement(Typography, { variant: "headline", color: "alt" }, "Shipping")),
                            React.createElement(OrderLargeLineRight, null,
                                React.createElement(Typography, { variant: "headline" }, order.facility.name),
                                React.createElement(Typography, null, (_c = (_b = order === null || order === void 0 ? void 0 : order.shipping) === null || _b === void 0 ? void 0 : _b.address) === null || _c === void 0 ? void 0 :
                                    _c.addressLine1,
                                    ' ', (_e = (_d = order === null || order === void 0 ? void 0 : order.shipping) === null || _d === void 0 ? void 0 : _d.address) === null || _e === void 0 ? void 0 :
                                    _e.addressLine2),
                                React.createElement(Typography, null, (_g = (_f = order === null || order === void 0 ? void 0 : order.shipping) === null || _f === void 0 ? void 0 : _f.address) === null || _g === void 0 ? void 0 :
                                    _g.city,
                                    ",", (_j = (_h = order === null || order === void 0 ? void 0 : order.shipping) === null || _h === void 0 ? void 0 : _h.address) === null || _j === void 0 ? void 0 :
                                    _j.state),
                                React.createElement(Typography, null, (_l = (_k = order === null || order === void 0 ? void 0 : order.shipping) === null || _k === void 0 ? void 0 : _k.address) === null || _l === void 0 ? void 0 : _l.zip)))),
                    React.createElement(Desktop, null,
                        React.createElement(OrderLargeLine, { style: { marginTop: 12 } },
                            React.createElement(OrderLargeLineLeft, null,
                                React.createElement(Typography, { variant: "headline", color: "alt" }, "Order Status")),
                            React.createElement(OrderLineSomething, null,
                                React.createElement(OrderLargeLineRight, null,
                                    React.createElement(Stepper, { steps: steps, currentStep: currentStep }))))),
                    (order === null || order === void 0 ? void 0 : order.shipping) &&
                        ((_m = order === null || order === void 0 ? void 0 : order.shipping) === null || _m === void 0 ? void 0 : _m.trackingNumbers) &&
                        ((_o = order === null || order === void 0 ? void 0 : order.shipping) === null || _o === void 0 ? void 0 : _o.trackingNumbers.length) && (React.createElement(OrderLargeLine, null,
                        React.createElement(OrderLargeLineLeft, null,
                            React.createElement(Typography, { variant: "headline", color: "alt" },
                                ((_q = getTrackingNumber((_p = order === null || order === void 0 ? void 0 : order.shipping) === null || _p === void 0 ? void 0 : _p.trackingNumbers)) === null || _q === void 0 ? void 0 : _q.slice(0, 2)) === '1Z' && React.createElement(React.Fragment, null,
                                    "UPS",
                                    '\n',
                                    "Tracking"),
                                ((_s = getTrackingNumber((_r = order === null || order === void 0 ? void 0 : order.shipping) === null || _r === void 0 ? void 0 : _r.trackingNumbers)) === null || _s === void 0 ? void 0 : _s.slice(0, 2)) !== '1Z' && React.createElement(React.Fragment, null,
                                    "FedEx",
                                    '\n',
                                    "Tracking"))),
                        React.createElement(OrderLargeLineRight, null, order.shipping.trackingNumbers.map(function (trackingNumber) { return (React.createElement(TrackingButton, { onClick: function () { return openTrackingLink(trackingNumber); }, variant: "outlined", size: "medium" }, "Tracking")); })))),
                    React.createElement(OrderLargeLine, null,
                        React.createElement(OrderLargeLineLeft, null,
                            React.createElement(Typography, { variant: "headline", color: "alt" }, "Delivery Date")),
                        React.createElement(OrderLargeLineRight, null,
                            React.createElement(Typography, { variant: "headline" },
                                format(parseISO((_u = (_t = order === null || order === void 0 ? void 0 : order.shipping) === null || _t === void 0 ? void 0 : _t.method) === null || _u === void 0 ? void 0 : _u.requestedDeliveryDate), 'EEEE, LLLL d'),
                                ' ',
                                "by ",
                                format(deliveryTimeDate, 'h:mmaaa')),
                            React.createElement(Typography, null, order.shipping.method.serviceName))),
                    React.createElement(OrderLargeLine, null,
                        React.createElement(OrderLargeLineLeft, null,
                            React.createElement(Typography, { variant: "headline", color: "alt" }, "Order Date")),
                        React.createElement(OrderLargeLineRight, null,
                            React.createElement(Typography, { variant: "headline" }, format(parseISO(order.orderDate), 'EEEE, LLLL d')),
                            React.createElement(Typography, null, format(parseISO(order.orderDate), 'hh:mmaaa zzzz')))),
                    React.createElement(OrderLargeLine, null,
                        React.createElement(OrderLargeLineLeft, null,
                            React.createElement(Typography, { variant: "headline", color: "alt" }, "Payment Method")),
                        React.createElement(OrderLargeLine, null,
                            React.createElement(OrderLargeLineLeft, null,
                                React.createElement(Typography, { variant: "headline" }, (_w = (_v = order === null || order === void 0 ? void 0 : order.billing) === null || _v === void 0 ? void 0 : _v.paymentMethod) === null || _w === void 0 ? void 0 : _w.name),
                                React.createElement(Typography, null, getPaymentMethodDescription((_x = order === null || order === void 0 ? void 0 : order.billing) === null || _x === void 0 ? void 0 : _x.paymentMethod))),
                            ((_y = order === null || order === void 0 ? void 0 : order.billing) === null || _y === void 0 ? void 0 : _y.secondaryPaymentMethodId) && (React.createElement(OrderLargeLineRight, null,
                                React.createElement(Typography, { variant: "headline" }, renderPrimaryCardAmount()))))),
                    ((_z = order === null || order === void 0 ? void 0 : order.billing) === null || _z === void 0 ? void 0 : _z.secondaryPaymentMethodId) && (React.createElement(OrderLargeLine, null,
                        React.createElement(OrderLargeLineLeft, null,
                            React.createElement(Typography, { variant: "headline", color: "alt" }, "Payment Method")),
                        React.createElement(OrderLargeLine, null,
                            React.createElement(OrderLargeLineLeft, null,
                                React.createElement(Typography, { variant: "headline" }, "Evolus Credit")),
                            React.createElement(OrderLargeLineRight, null,
                                React.createElement(Typography, { variant: "headline" }, renderSecondaryCardAmount()))))),
                    order.referenceNumber && (React.createElement(OrderLargeLine, null,
                        React.createElement(OrderLargeLineLeft, null,
                            React.createElement(Typography, { variant: "headline", color: "alt" }, "Reference Number")),
                        React.createElement(OrderLargeLineRight, null,
                            React.createElement(Typography, null, order.referenceNumber)))),
                    renderInvoices(),
                    order.status === OrderStatus.Shipped && (React.createElement(React.Fragment, null,
                        React.createElement(OrderDivider, { style: { margin: '48px 0' } }),
                        React.createElement(Typography, { display: "block", mb: 24, variant: "sectionHeader" }, "DSCSA DETAILS"),
                        React.createElement(Typography, { mb: 24, color: "alt", display: "block", variant: "headlineSmall" },
                            "To help us comply with the Drug Supply Chain Security Act (\u201CDSCSA\u201D), please review/confirm these details. To access DSCSA compliance document log onto:",
                            ' ',
                            React.createElement(AltAnchor, { onClick: function () { }, href: "https://opus.tracelink.com", target: "_blank" }, "opus.tracelink.com"),
                            ". Once logged on, you will be asked for a delivery number and access code which will be your order number (example below of the information you will need). If you are not yet registered with Tracelink, please register",
                            ' ',
                            React.createElement(AltAnchor, { target: "_blank", href: "https://evolus.typeform.com/to/m4d9F4G8" }, "here"),
                            "."),
                        React.createElement(OrderLargeLine, null,
                            React.createElement(OrderLargeLineLeft, null,
                                React.createElement(Typography, { variant: "headline", color: "alt" },
                                    "Supplier/",
                                    React.createElement(Mobile, null,
                                        React.createElement("br", null)),
                                    "Vendor:")),
                            React.createElement(OrderLargeLineRight, null,
                                React.createElement(Typography, null, "Evolus, Inc."))),
                        React.createElement(OrderLargeLine, null,
                            React.createElement(OrderLargeLineLeft, null,
                                React.createElement(Typography, { variant: "headline", color: "alt" }, "Delivery Number")),
                            React.createElement(OrderLargeLineRight, null,
                                React.createElement(Typography, null, orderId))),
                        React.createElement(OrderLargeLine, null,
                            React.createElement(OrderLargeLineLeft, null,
                                React.createElement(Typography, { variant: "headline", color: "alt" }, "Access Code")),
                            React.createElement(OrderLargeLineRight, null,
                                React.createElement(Typography, null, orderId))))),
                    React.createElement(OrderDivider, { style: { margin: '48px 0' } }),
                    React.createElement(OrderPricingDetails, { order: order, orderDisplay: orderDisplay, error: error, loading: loading }),
                    React.createElement(Mobile, null,
                        React.createElement(Button, { onClick: function () { return navigate('/orders/history'); }, variant: "outlined", size: "medium", startIcon: React.createElement(Icon, { name: "chevron-left", color: "link" }), fullWidth: true, style: {
                                marginTop: 48,
                                height: 56,
                            } }, "Back to Order History"))))));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(SummaryCard, { direction: "horizontal", fullWidth: true },
            React.createElement(SummaryCardBody, null,
                React.createElement(Header, null,
                    React.createElement(Desktop, null,
                        React.createElement(ContainerWrapper, null,
                            React.createElement(Button, { onClick: function () { return navigate('/orders/history'); }, variant: "outlined", size: "medium", startIcon: React.createElement(Icon, { name: "chevron-left", color: "link" }) }, "Back to Order History"))),
                    React.createElement(Heading, null,
                        React.createElement(Desktop, null,
                            React.createElement(Typography, { variant: "sectionHeader", color: "alt5", mb: 4 }, "Order History")),
                        React.createElement(Typography, { variant: "titleLarge", color: "base" }, orderId)),
                    React.createElement(Desktop, null,
                        React.createElement(ContainerWrapper, null))),
                React.createElement(OrderWrapper, null, loading ? renderLoading() : renderOrder())))));
};
export default OrderSummaryView;
var TrackingButton = styled(Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  max-width: 120px;\n"], ["\n  max-width: 120px;\n"])));
var SummaryCard = styled(Card)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: 16px;\n  margin-left: 16px;\n  @media only screen and (max-width: ", "px) {\n    margin: 0;\n    margin-top: 20px ", ";\n  }\n"], ["\n  margin-top: 16px;\n  margin-left: 16px;\n  @media only screen and (max-width: ", "px) {\n    margin: 0;\n    margin-top: 20px ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
}, function (props) { return props.theme.elevation.base.elevation2; });
var SummaryCardBody = styled(CardBody)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  @media only screen and (max-width: ", "px) {\n    padding: 32px 0 0;\n    margin-top: 15px;\n  }\n"], ["\n  @media only screen and (max-width: ", "px) {\n    padding: 32px 0 0;\n    margin-top: 15px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var Header = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n  width: 100%;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n  width: 100%;\n"])));
var Heading = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n"])));
var OrderWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  width: 100%;\n  margin-top: 40px;\n"], ["\n  display: flex;\n  justify-content: center;\n  width: 100%;\n  margin-top: 40px;\n"])));
var OrderCard = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  ", "\n  background: ", ";\n  padding: 0;\n  width: 100%;\n  max-width: 916px;\n\n  @media only screen and (max-width: ", "px) {\n    background: ", ";\n  }\n"], ["\n  ", "\n  background: ", ";\n  padding: 0;\n  width: 100%;\n  max-width: 916px;\n\n  @media only screen and (max-width: ", "px) {\n    background: ", ";\n  }\n"])), function (props) { return props.theme.elevation.base.elevation1; }, function (props) { return props.theme.colors.bg.success2; }, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
}, function (props) { return props.theme.colors.basic.white; });
var OrderMap = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
var OrderContent = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  padding: 64px 128px;\n\n  @media only screen and (max-width: ", "px) {\n    padding: 32px;\n  }\n"], ["\n  padding: 64px 128px;\n\n  @media only screen and (max-width: ", "px) {\n    padding: 32px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var OrderSection = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject([""], [""])));
var OrderDivider = styled.hr(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  border: 1px solid ", ";\n"], ["\n  border: 1px solid ", ";\n"])), function (props) { return props.theme.colors.border.success2; });
var OrderLargeLine = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  display: flex;\n  align-items: flex-start;\n  justify-content: flex-start;\n  margin-bottom: 24px;\n  flex: 3;\n\n  @media only screen and (max-width: ", "px) {\n    &:not(:last-child) {\n      margin-bottom: 24px;\n    }\n  }\n"], ["\n  display: flex;\n  align-items: flex-start;\n  justify-content: flex-start;\n  margin-bottom: 24px;\n  flex: 3;\n\n  @media only screen and (max-width: ", "px) {\n    &:not(:last-child) {\n      margin-bottom: 24px;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var OrderLargeLineLeft = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  width: 100%;\n  max-width: 155px;\n  margin-right: 96px;\n  display: flex;\n  flex-direction: column;\n\n  @media only screen and (max-width: ", "px) {\n    max-width: 120px;\n    margin-right: 8px;\n  }\n"], ["\n  width: 100%;\n  max-width: 155px;\n  margin-right: 96px;\n  display: flex;\n  flex-direction: column;\n\n  @media only screen and (max-width: ", "px) {\n    max-width: 120px;\n    margin-right: 8px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var OrderLargeLineRight = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n"], ["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n"])));
var LoadingOuterContainer = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  margin-top: 64px;\n  display: flex;\n  height: 40vh;\n  width: 100%;\n"], ["\n  margin-top: 64px;\n  display: flex;\n  height: 40vh;\n  width: 100%;\n"])));
var LoadingInnerContainer = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"], ["\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"])));
var OrderLineSomething = styled.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  margin-left: -55px;\n"], ["\n  display: flex;\n  flex: 1;\n  margin-left: -55px;\n"])));
var InvoiceLinksContainer = styled.div(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
var InvoiceItem = styled.button(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  all: unset;\n  display: flex;\n  align-items: center;\n  text-decoration: underline;\n  text-decoration-color: ", ";\n  cursor: pointer;\n  margin-bottom: 8px;\n"], ["\n  all: unset;\n  display: flex;\n  align-items: center;\n  text-decoration: underline;\n  text-decoration-color: ", ";\n  cursor: pointer;\n  margin-bottom: 8px;\n"])), function (props) { return props.theme.colors.text.link; });
var LoaderIcon = styled(Icon)(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  margin-left: 8px;\n"], ["\n  margin-left: 8px;\n"])));
var AltAnchor = styled.a(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  font: inherit;\n  color: inherit;\n"], ["\n  font: inherit;\n  color: inherit;\n"])));
var ContainerWrapper = styled.div(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22;

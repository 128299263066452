var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var _a;
import { Button, Dropdown, Icon, Input, Typography } from 'designSystem';
import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { PermissionSelect } from './components/PermissionSelect';
import { PrivilegesWidget } from '../PrivilegesWidget';
import { useNavigate, useOutletContext } from 'react-router';
import { EMAIL_REGEX } from '../../../../../../regex/email';
import { EVO_EMAIL } from '../../../../../../regex/evoEmail';
import { useModal } from 'context';
import ModalTypeEnum from 'types/enums/ModalTypeEnum';
import { useIsMounted } from 'hooks/useIsMounted';
import NewUserGraphic from 'assets/facility-selection-graphic.svg';
import NotificationSentGraphic from 'assets/notification-sent-graphic.svg';
import { useScreenSizing } from 'hooks/useScreenSizing';
import { AssignFacilities } from '../AssignFacilities/View';
import { UserRole } from 'types/types';
var JobOptions = [
    {
        value: 'Physician',
        label: 'Physician',
    },
    {
        value: 'Nurse Injector',
        label: 'Nurse Injector',
    },
    {
        value: 'Physician Assistant',
        label: 'Physician Assistant',
    },
    {
        value: 'Office Manager',
        label: 'Office Manager',
    },
    {
        value: 'Office Staff',
        label: 'Office Staff',
    },
    {
        value: 'Medical Director',
        label: 'Medical Director',
    },
    {
        value: 'Corporate Staff',
        label: 'Corporate Staff',
    },
];
var LoginInstructionByRole = (_a = {},
    _a[UserRole.AccountAdmin] = (React.createElement(React.Fragment, null,
        "Go to ",
        React.createElement(Typography, { color: "link" }, "Evolus Rewards"),
        " in Portal (https://portal.staging.evolus-dev.com/rewards) to access Evolus Rewards.")),
    _a[UserRole.Custom] = (React.createElement(React.Fragment, null, "Download the \"Evolus Practice\" app from the Apple App Store or Google Play Store.")),
    _a);
var validateInputs = function (_a) {
    var email = _a.email, role = _a.role, primaryJob = _a.primaryJob;
    if (!EMAIL_REGEX.test(email) || !EVO_EMAIL.test(email)) {
        return false;
    }
    else if (!role || !primaryJob) {
        return false;
    }
    return true;
};
export var InviteUserView = function (_a) {
    var onSubmit = _a.onSubmit, loading = _a.loading, facility = _a.facility;
    var context = useOutletContext();
    var navigate = useNavigate();
    var _b = useModal(), openModal = _b.openModal, closeModal = _b.closeModal;
    var isMobile = useScreenSizing().isMobile;
    var isMounted = useIsMounted();
    var _c = __read(useState(''), 2), email = _c[0], setEmail = _c[1];
    var _d = __read(useState(null), 2), primaryJob = _d[0], setPrimaryJob = _d[1];
    var _e = __read(useState(UserRole.AccountAdmin), 2), role = _e[0], setRole = _e[1];
    var _f = __read(useState([]), 2), facilitiesAssigned = _f[0], setFacilitiesAssigned = _f[1];
    var _g = __read(useState(null), 2), invitedUser = _g[0], setInvitedUser = _g[1];
    var backToUsers = function () { return navigate('/settings/user-permissions'); };
    var handleSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var inputs, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!validateInputs({ email: email, primaryJob: primaryJob === null || primaryJob === void 0 ? void 0 : primaryJob.value, role: role })) {
                        return [2];
                    }
                    inputs = {
                        email: email,
                        primaryJob: primaryJob.value,
                        role: role,
                        facilities: facilitiesAssigned,
                    };
                    if (role === UserRole.AccountAdmin) {
                        openModal(ModalTypeEnum.ADMIN_ACCESS_CONFIRMATION, {
                            onConfirm: function () { return __awaiter(void 0, void 0, void 0, function () {
                                var res;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            closeModal();
                                            return [4, onSubmit(inputs)];
                                        case 1:
                                            res = _a.sent();
                                            if (isMounted) {
                                                setInvitedUser(res);
                                            }
                                            return [2];
                                    }
                                });
                            }); },
                        });
                        return [2];
                    }
                    return [4, onSubmit(inputs)];
                case 1:
                    res = _a.sent();
                    if (isMounted) {
                        setInvitedUser(res);
                    }
                    return [2];
            }
        });
    }); };
    var submitDisabled = useMemo(function () {
        return (loading ||
            !validateInputs({
                email: email,
                primaryJob: primaryJob === null || primaryJob === void 0 ? void 0 : primaryJob.value,
                role: role,
            }));
    }, [email, primaryJob, role, loading]);
    var renderContent = function () {
        if (invitedUser) {
            return (React.createElement(SentContainer, null,
                React.createElement("div", null,
                    React.createElement(Typography, { mb: 16, display: "block", variant: "titleLarge" }, "Invite Sent!"),
                    React.createElement(Typography, { mb: 32, display: "block", color: "alt" }, "Invite sent to:"),
                    React.createElement(Typography, { mb: isMobile ? 32 : 0, display: "block" }, invitedUser.email),
                    React.createElement(Divider, null),
                    React.createElement(Typography, { mb: 16, display: "block", variant: "titleLarge" }, "Next Steps"),
                    React.createElement(Typography, { mb: 32, display: "block" }, "An email invite was sent to this user. Prompt your user to follow these steps:"),
                    React.createElement(LoginInstruction, null,
                        React.createElement(Typography, { display: "block", color: "alt" }, "1)"),
                        React.createElement(Typography, { mb: 16, display: "block" }, LoginInstructionByRole[invitedUser.role])),
                    React.createElement(LoginInstruction, null,
                        React.createElement(Typography, { display: "block", color: "alt" }, "2)"),
                        React.createElement(Typography, { mb: 16, display: "block" }, "Find the email in their inbox.")),
                    React.createElement(LoginInstruction, null,
                        React.createElement(Typography, { display: "block", color: "alt" }, "3)"),
                        React.createElement(Typography, { mb: 48, display: "block" }, "Log in with the temporary password provided in the email invite.")),
                    React.createElement(Button, { size: "xlarge", variant: "outlined", onClick: function () {
                            backToUsers();
                            context.onUpdate();
                        } }, "Back to Users")),
                React.createElement(SentImage, { src: NotificationSentGraphic, alt: "notification-sent-image" })));
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(UpperContainer, null,
                React.createElement(UpperFormContainer, null,
                    React.createElement(Typography, { display: "block", variant: "titleLarge" }, "Invite New User"),
                    React.createElement(Typography, { display: "block", mb: isMobile ? 8 : 16 }, "Fill out the email address of the staff member you would like to invite to your practice."),
                    React.createElement(Input, { label: "Email", placeholder: "Email", value: email, onChange: function (_a) {
                            var target = _a.target;
                            return setEmail(target.value);
                        } }),
                    React.createElement(Dropdown, { label: "Primary Job", placeholder: "Choose One", onSelect: function (newJob) { return setPrimaryJob(newJob); }, selected: primaryJob, options: JobOptions }),
                    React.createElement(PermissionContainer, null,
                        React.createElement(Typography, { display: "block", variant: "titleLarge" }, "Permissions"),
                        React.createElement(Typography, null, "You can set what role this user will play inside your practice"),
                        React.createElement(PermissionSelect, { selected: role, onSelect: function (r) { return setRole(r); } }))),
                !isMobile && (React.createElement(FormImage, { src: NewUserGraphic, alt: "new-user-graphic" }))),
            React.createElement(PrivilegeContainer, null,
                React.createElement(PrivilegesWidget, { role: role })),
            React.createElement(SelectFacilityContainer, null, role === 'CUSTOM' && (React.createElement(AssignFacilities, { selectedFacilities: facilitiesAssigned, setSelectedFacilities: setFacilitiesAssigned, facilities: facility }))),
            React.createElement(ActionsContainer, null,
                React.createElement(Button, { disabled: submitDisabled, endIcon: loading && React.createElement(Icon, { color: "inherit", name: "loading" }), onClick: handleSubmit, size: "xlarge", variant: "primary" }, "Send Invite"),
                React.createElement(Button, { size: "xlarge", variant: isMobile ? 'tertiary' : 'outlined', onClick: backToUsers }, "Cancel"))));
    };
    return (React.createElement(OuterContainer, null,
        React.createElement(InnerContainer, null, renderContent())));
};
var OuterContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  box-sizing: border-box;\n  position: relative;\n  padding: 48px 48px 60px;\n  margin-top: 20px;\n  flex: 1;\n  width: 100%;\n  @media only screen and (max-width: ", "px) {\n    padding: 32px;\n  }\n"], ["\n  ", ";\n  box-sizing: border-box;\n  position: relative;\n  padding: 48px 48px 60px;\n  margin-top: 20px;\n  flex: 1;\n  width: 100%;\n  @media only screen and (max-width: ", "px) {\n    padding: 32px;\n  }\n"])), function (props) { return props.theme.card.default; }, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var SelectFacilityContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  margin-top: 48px;\n  @media only screen and (max-width: ", "px) {\n    margin-top: 16px;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  margin-top: 48px;\n  @media only screen and (max-width: ", "px) {\n    margin-top: 16px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var InnerContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 48px;\n  ", ";\n\n  width: 100%;\n  @media only screen and (max-width: ", "px) {\n    border: none;\n    padding: 0;\n  }\n"], ["\n  padding: 48px;\n  ", ";\n\n  width: 100%;\n  @media only screen and (max-width: ", "px) {\n    border: none;\n    padding: 0;\n  }\n"])), function (props) { return props.theme.borders.base; }, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var UpperFormContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  flex: 1;\n  & > :nth-child(4) {\n    margin-top: 20px;\n  }\n  @media only screen and (max-width: ", "px) {\n    max-width: 100%;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  flex: 1;\n  & > :nth-child(4) {\n    margin-top: 20px;\n  }\n  @media only screen and (max-width: ", "px) {\n    max-width: 100%;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var PermissionContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  margin-top: 48px;\n  & > div:last-of-type {\n    margin-top: 16px;\n  }\n  @media only screen and (max-width: ", "px) {\n    margin-top: 16px;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  margin-top: 48px;\n  & > div:last-of-type {\n    margin-top: 16px;\n  }\n  @media only screen and (max-width: ", "px) {\n    margin-top: 16px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var PrivilegeContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  margin: 48px 0;\n"], ["\n  display: flex;\n  flex: 1;\n  margin: 48px 0;\n"])));
var ActionsContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row-reverse;\n  justify-content: end;\n  align-items: center;\n  gap: 12px;\n  @media only screen and (max-width: ", "px) {\n    margin-top: 24px;\n    flex-direction: column;\n    flex: 1;\n    & > button {\n      width: 100%;\n    }\n  }\n"], ["\n  display: flex;\n  flex-direction: row-reverse;\n  justify-content: end;\n  align-items: center;\n  gap: 12px;\n  @media only screen and (max-width: ", "px) {\n    margin-top: 24px;\n    flex-direction: column;\n    flex: 1;\n    & > button {\n      width: 100%;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var Divider = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  border: 2px solid ", ";\n  box-sizing: border-box;\n  margin: 32px 0;\n  @media only screen and (max-width: ", "px) {\n    display: none;\n  }\n"], ["\n  border: 2px solid ", ";\n  box-sizing: border-box;\n  margin: 32px 0;\n  @media only screen and (max-width: ", "px) {\n    display: none;\n  }\n"])), function (props) { return props.theme.colors.white.white500; }, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var LoginInstruction = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: flex-start;\n  gap: 8px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: flex-start;\n  gap: 8px;\n"])));
var FormImage = styled.img(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  transform: scaleX(-1);\n  width: 100%;\n  max-width: 248px;\n"], ["\n  transform: scaleX(-1);\n  width: 100%;\n  max-width: 248px;\n"])));
var SentImage = styled.img(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  width: 100%;\n  max-width: 280px;\n"], ["\n  width: 100%;\n  max-width: 280px;\n"])));
var UpperContainer = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  display: flex;\n  align-items: flex-start;\n  justify-content: space-between;\n  flex: 1;\n  gap: 24px;\n"], ["\n  display: flex;\n  align-items: flex-start;\n  justify-content: space-between;\n  flex: 1;\n  gap: 24px;\n"])));
var SentContainer = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  align-items: center;\n  justify-content: space-between;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column-reverse;\n    gap: 24px;\n  }\n"], ["\n  display: flex;\n  flex: 1;\n  align-items: center;\n  justify-content: space-between;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column-reverse;\n    gap: 24px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13;

import React from 'react';
export var Photograph = function (_a) {
    var color = _a.color;
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 20, height: 20, fill: "none", color: color },
        React.createElement("g", { clipPath: "url(#a)", stroke: "currentColor", strokeWidth: 1.25, strokeLinecap: "round", strokeLinejoin: "round" },
            React.createElement("path", { d: "M12.5.625H1.25a.625.625 0 0 0-.625.625V15c0 .345.28.625.625.625H12.5c.345 0 .625-.28.625-.625V1.25A.625.625 0 0 0 12.5.625ZM13.125 11.875H.625" }),
            React.createElement("path", { d: "M15.63 5.193 18.947 6.3a.625.625 0 0 1 .395.791L15.39 18.948a.626.626 0 0 1-.79.395l-5.225-1.74M6.875 7.5a2.188 2.188 0 1 0 0-4.375 2.188 2.188 0 0 0 0 4.375ZM3.125 11.875a3.75 3.75 0 1 1 7.5 0h-7.5Z" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "a" },
                React.createElement("path", { fill: "currentColor", d: "M0 0h20v20H0z" })))));
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { createGlobalStyle, css } from 'styled-components';
import theme from './theme';
import { typography } from './typography';
export var bodyStyles = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-family: 'Euclid';\n  font-size: ", "px;\n  color: ", ";\n  margin: 0;\n  overflow-y: auto;\n  background-color: #ecf0f0;\n  overflow-x: hidden;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  -webkit-tap-highlight-color: ", ";\n  -webkit-tap-highlight-color: transparent;\n  -webkit-overflow-scrolling: touch;\n  * {\n    box-sizing: border-box;\n  }\n  h1,\n  h2,\n  h3,\n  h4,\n  h5,\n  h6 {\n    font-weight: ", ";\n    margin: 0;\n    padding: 0;\n  }\n"], ["\n  font-family: 'Euclid';\n  font-size: ", "px;\n  color: ", ";\n  margin: 0;\n  overflow-y: auto;\n  background-color: #ecf0f0;\n  overflow-x: hidden;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  -webkit-tap-highlight-color: ", ";\n  -webkit-tap-highlight-color: transparent;\n  -webkit-overflow-scrolling: touch;\n  * {\n    box-sizing: border-box;\n  }\n  h1,\n  h2,\n  h3,\n  h4,\n  h5,\n  h6 {\n    font-weight: ", ";\n    margin: 0;\n    padding: 0;\n  }\n"])), typography.size.s3, theme.colors.text.base, theme.colors.basic.white, typography.weight.regular);
export var GlobalStyle = createGlobalStyle(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  body {\n    ", "\n\n    &.overflow-hidden {\n      overflow: hidden;\n\n      #root {\n        overflow: hidden;\n      }\n    }\n  }\n  \n\n"], ["\n  body {\n    ", "\n\n    &.overflow-hidden {\n      overflow: hidden;\n\n      #root {\n        overflow: hidden;\n      }\n    }\n  }\n  \n\n"])), bodyStyles);
var templateObject_1, templateObject_2;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import EvolusLogo from 'assets/evolus-logo-coral.svg';
import { useLocation } from 'react-router';
export var EvoLogo = function (_a) {
    var mb = _a.mb;
    var search = useLocation().search;
    var shouldRender = search !== '?step=orderConfirmation';
    if (!shouldRender) {
        return null;
    }
    return React.createElement(Logo, { mb: mb });
};
var Logo = styled.img.attrs({
    src: EvolusLogo,
})(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  max-width: 80px;\n  margin-bottom: ", "px;\n"], ["\n  max-width: 80px;\n  margin-bottom: ", "px;\n"])), function (props) { var _a; return (_a = props.mb) !== null && _a !== void 0 ? _a : 0; });
var templateObject_1;

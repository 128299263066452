var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Button, Desktop, Icon, Mobile, PlaceholderLoading, Typography, } from 'designSystem';
import { TableCard } from 'designSystem/components/TableCard';
import { Table, TableBody, TableHead, TableRow, TableColumn, TableHeadColumn, } from 'designSystem';
import React, { useCallback, useMemo } from 'react';
import styled, { withTheme } from 'styled-components';
import TransactionDetails from './components/TransactionDetails';
import { renderActivityType, renderCredits, } from './components/RewardHistoryTable/View';
import { format } from 'date-fns';
import { ActivityType, FacilityStatus, } from 'types/codegen/hooks';
import FacilityIcon from 'assets/facility-icon.png';
import { pluralize } from 'utils/pluralize';
import { TransactionInformation } from '../components/TransactionInformation';
import { AllTransactionsCard } from './components/AllTransactionsCard';
import { FacilityCard } from './components/FacilityCard';
import { TransactionDetailItem } from './components/TransactionDetailItem';
import { stackedCardStyles } from '../RewardsDashboard/components/StackedMobileCard';
import { useModal } from '../../../../context';
import ModalTypeEnum from '../../../../types/enums/ModalTypeEnum';
import { RewardsCounter } from '../../../../components/RewardsCounter';
import { ViewEnum } from './Container';
import { useNavigate } from 'react-router-dom';
var columns = ['Facility', 'Activity', 'Credits'];
var renderTableIcon = function (activity) {
    if ((activity === null || activity === void 0 ? void 0 : activity.activityType) === ActivityType.CreditReceived ||
        (activity === null || activity === void 0 ? void 0 : activity.activityType) === ActivityType.ManualCredit) {
        return 'user';
    }
    return 'evolus-rewards';
};
var RewardsHistory = function (_a) {
    var activities = _a.activities, facilities = _a.facilities, theme = _a.theme, isLoading = _a.isLoading, error = _a.error, onInjectorChange = _a.onInjectorChange, onTypesChange = _a.onTypesChange, onPageChange = _a.onPageChange, onDateRangeChange = _a.onDateRangeChange, onSelectedFacilityChange = _a.onSelectedFacilityChange, selectedFacility = _a.selectedFacility, page = _a.page, recordCount = _a.recordCount, injectors = _a.injectors, currentInjector = _a.currentInjector, currentTypes = _a.currentTypes, currentDateRange = _a.currentDateRange, onReset = _a.onReset, onEditDetails = _a.onEditDetails, currentView = _a.currentView, setCurrentView = _a.setCurrentView, showRewardsCounterDetails = _a.showRewardsCounterDetails, showFullHistory = _a.showFullHistory, setShowFullHistory = _a.setShowFullHistory, selectedRewardTypes = _a.selectedRewardTypes, setSelectedRewardTypes = _a.setSelectedRewardTypes;
    var navigate = useNavigate();
    var openModal = useModal().openModal;
    var topThreeTransactions = useMemo(function () {
        var _a;
        return (_a = activities === null || activities === void 0 ? void 0 : activities.slice(0, 3)) !== null && _a !== void 0 ? _a : [];
    }, [activities]);
    var fromFacility = useMemo(function () {
        if (selectedFacility) {
            return facilities === null || facilities === void 0 ? void 0 : facilities.find(function (f) { return f.id === selectedFacility; });
        }
        return null;
    }, [facilities, selectedFacility]);
    var facilitiesWithVials = useMemo(function () {
        var _a, _b;
        return (_b = (_a = facilities === null || facilities === void 0 ? void 0 : facilities.filter(function (f) { return f.status === FacilityStatus.Active; })) === null || _a === void 0 ? void 0 : _a.sort(function (a, b) { return a.name.localeCompare(b.name); })) === null || _b === void 0 ? void 0 : _b.map(function (f) { return (__assign(__assign({}, f), { vialsReceived: Math.floor((activities === null || activities === void 0 ? void 0 : activities.filter(function (v) { return v.facilityId === f.id; }).length) / 8) })); });
    }, [facilities]);
    var injectorOptions = useMemo(function () {
        var types = injectors === null || injectors === void 0 ? void 0 : injectors.map(function (injector) {
            return {
                value: (injector === null || injector === void 0 ? void 0 : injector.uniqueId) || '',
                label: (injector === null || injector === void 0 ? void 0 : injector.name) || '',
            };
        });
        return types;
    }, [injectors]);
    var onOpenFilters = useCallback(function () {
        openModal(ModalTypeEnum.TRANSACTION_HISTORY_FILTERS, {
            specialistsOptions: injectorOptions,
            currentSpecialists: [
                injectorOptions.find(function (i) { return currentInjector === i.value; }),
            ].filter(Boolean),
            currentTypes: currentTypes,
            currentDateRange: currentDateRange,
            onApply: function (_a) {
                var specialists = _a.specialists, dateRange = _a.dateRange, types = _a.types, rewardTypes = _a.rewardTypes;
                onTypesChange((types === null || types === void 0 ? void 0 : types.length) ? types : null);
                onInjectorChange(specialists.length ? specialists[0].value : null);
                onDateRangeChange(dateRange);
                onPageChange(0);
                setSelectedRewardTypes(rewardTypes);
            },
            onReset: onReset,
            selectedRewardTypes: selectedRewardTypes,
            showRewardsCounterDetails: showRewardsCounterDetails,
        });
    }, [
        openModal,
        onReset,
        onPageChange,
        injectorOptions,
        onInjectorChange,
        onTypesChange,
        onDateRangeChange,
        currentTypes,
        currentDateRange,
        currentInjector,
        selectedRewardTypes,
        setSelectedRewardTypes,
        showRewardsCounterDetails,
    ]);
    var onClickDetails = function (details) {
        openModal(ModalTypeEnum.TRANSACTION_DETAILS_MODAL, {
            details: details,
            onEdit: onEditDetails,
        });
    };
    var fullHistoryOpen = !!selectedFacility || showFullHistory;
    return (React.createElement(React.Fragment, null,
        React.createElement(Desktop, null,
            React.createElement(OuterContainer, null,
                currentView === ViewEnum.TRANSACTION_DETAILS && (React.createElement(BreadcrumbContainer, null,
                    React.createElement(Typography, { variant: "headline", color: "alt5", style: { cursor: 'pointer' }, onClick: function () {
                            setCurrentView(ViewEnum.DASHBOARD);
                            navigate('/rewards/history');
                        } }, "Account Rewards History"),
                    React.createElement(Chevron, { name: "chevron-right", size: 16, color: "#A9B9B9" }),
                    React.createElement(Typography, { variant: "headline" }, "Transaction Details"))),
                currentView === ViewEnum.TRANSACTION_DETAILS ? (React.createElement(TransactionDetails, { onSelectItem: onClickDetails, selectedFacility: selectedFacility, selectedActivityTypes: currentTypes, selectedRewardTypes: selectedRewardTypes, setSelectedRewardTypes: setSelectedRewardTypes, showRewardsCounterDetails: showRewardsCounterDetails })) : (React.createElement(DashboardContainer, null,
                    React.createElement(DataItemRow, null,
                        React.createElement(TransactionInformation, null),
                        React.createElement(RewardsCounter, null)),
                    React.createElement(TableCard, { subtext: "View recent transactional reports.", buttonLabel: "View All Details", onButtonPress: function () {
                            return setCurrentView(ViewEnum.TRANSACTION_DETAILS);
                        }, header: "Recent Transaction Details", isLoading: isLoading, error: error }, isLoading || error ? (React.createElement(PlaceholderLoading, { size: "extra-small" })) : (React.createElement(Table, { fullWidth: true, style: { marginTop: 48 } },
                        React.createElement(TableHead, null,
                            React.createElement(TableRow, null, columns === null || columns === void 0 ? void 0 : columns.map(function (label, i) {
                                return (React.createElement(TableHeadColumn, { key: i, colSpan: 6 },
                                    React.createElement(Typography, { variant: "headlineSmall", color: "alt" }, label)));
                            }))),
                        React.createElement(TableBody, { stripped: true, selectable: true }, activities === null || activities === void 0 ? void 0 : activities.map(function (activity, i) {
                            return (React.createElement(TableRow, { key: i, onClick: function () { return onClickDetails(activity); } },
                                React.createElement(NameContainer, { colSpan: 6 },
                                    React.createElement(Icon, { name: renderTableIcon(activity) }),
                                    React.createElement(Typography, { variant: "headline" }, activity === null || activity === void 0 ? void 0 : activity.facilityName)),
                                React.createElement(TableColumn, { colSpan: 6 },
                                    React.createElement(ActivityTypeContainer, null,
                                        React.createElement(Typography, { variant: "headlineSmall" }, renderActivityType(activity)),
                                        React.createElement(Typography, { variant: "footnote", color: "alt" }, format(new Date(activity === null || activity === void 0 ? void 0 : activity.occurredOn), 'MMM d')))),
                                React.createElement(TableColumn, { colSpan: 6 },
                                    React.createElement(Typography, null, renderCredits(activity)))));
                        }))))),
                    React.createElement(TableCard, { subtext: "View transaction details at the facility level.", header: "My Facilities", isLoading: isLoading, error: error }, isLoading || error ? (React.createElement(PlaceholderLoading, { size: "extra-small" })) : (React.createElement(Table, { fullWidth: true, style: { marginTop: 48 } },
                        React.createElement(TableHead, null,
                            React.createElement(TableRow, null,
                                React.createElement(TableHeadColumn, { colSpan: 6 },
                                    React.createElement(Typography, { variant: "headlineSmall", color: "alt" }, "Facilities")))),
                        React.createElement(TableBody, { stripped: true, selectable: true }, facilitiesWithVials === null || facilitiesWithVials === void 0 ? void 0 : facilitiesWithVials.map(function (facility) {
                            var _a, _b;
                            return (React.createElement(TableRow, { key: facility.id, onClick: function () {
                                    onSelectedFacilityChange(facility === null || facility === void 0 ? void 0 : facility.id);
                                    setCurrentView(ViewEnum.TRANSACTION_DETAILS);
                                } },
                                React.createElement(React.Fragment, null,
                                    React.createElement(TableColumn, { colSpan: 6 },
                                        React.createElement(FacilityRowContainer, null,
                                            React.createElement(FacilityItemContainer, null,
                                                React.createElement(FacilityImage, { src: FacilityIcon }),
                                                React.createElement(FacilityContainer, null,
                                                    React.createElement(Typography, { variant: "headline" }, facility === null || facility === void 0 ? void 0 : facility.name),
                                                    React.createElement(Typography, { variant: "bodySmall", color: "alt" }, (_a = facility === null || facility === void 0 ? void 0 : facility.shippingAddress) === null || _a === void 0 ? void 0 :
                                                        _a.city,
                                                        ",",
                                                        ' ', (_b = facility === null || facility === void 0 ? void 0 : facility.shippingAddress) === null || _b === void 0 ? void 0 :
                                                        _b.state))),
                                            React.createElement(FacilityRowContainer, null,
                                                React.createElement(Typography, { variant: "headlineSmall", color: "alt" }, facility === null || facility === void 0 ? void 0 :
                                                    facility.vialsReceived,
                                                    " Vial",
                                                    pluralize(facility === null || facility === void 0 ? void 0 : facility.vialsReceived),
                                                    ' ',
                                                    "Received")),
                                            React.createElement(Icon, { name: "chevron-right", size: 16, color: theme.colors.text.link }))))));
                        }))))))))),
        React.createElement(Mobile, null,
            React.createElement(CardsContainer, null, !fullHistoryOpen ? (React.createElement(React.Fragment, null,
                React.createElement(TransactionInformation, null),
                React.createElement(RewardsCounter, null),
                React.createElement(TransactionDetailsContainer, { isLoading: isLoading },
                    React.createElement(HeaderRow, null,
                        React.createElement(Typography, { variant: "headline" }, "Recent Transaction Details"),
                        React.createElement(Button, { onClick: function () {
                                setShowFullHistory(true);
                            }, variant: "tertiary" }, "View All")),
                    topThreeTransactions.map(function (th, i) { return (React.createElement(TransactionDetailItem, { key: i, onSelect: function () { return onClickDetails(th); }, details: th })); })),
                React.createElement(FacilitiesContainer, { isLoading: isLoading },
                    React.createElement(Typography, { variant: "headline" }, "My Facilities"), facilities === null || facilities === void 0 ? void 0 :
                    facilities.map(function (f) { return (React.createElement(FacilityCard, { key: f.id, facility: f, onSelect: onSelectedFacilityChange })); })))) : (React.createElement(AllTransactionsCard, { onClose: function () {
                    setShowFullHistory(false);
                    onReset();
                }, onSelectCard: onClickDetails, transactionHistory: activities, fromFacility: fromFacility, page: page, selectedFacility: selectedFacility, recordCount: recordCount, onPageChange: onPageChange, onOpenFilters: onOpenFilters, isLoading: isLoading, fullHistoryOpen: fullHistoryOpen, currentInjector: currentInjector, currentTypes: currentTypes, currentDateRange: currentDateRange }))))));
};
export default withTheme(RewardsHistory);
var OuterContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var BreadcrumbContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  padding-left: 16px;\n  margin: 32px 0;\n"], ["\n  display: flex;\n  align-items: center;\n  padding-left: 16px;\n  margin: 32px 0;\n"])));
var Chevron = styled(Icon)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: 0px 12px;\n"], ["\n  margin: 0px 12px;\n"])));
var DashboardContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
var ActivityTypeContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
var FacilityImage = styled.img(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 16px;\n  height: 16px;\n  margin-right: 16px;\n"], ["\n  width: 16px;\n  height: 16px;\n  margin-right: 16px;\n"])));
var FacilityContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n"], ["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n"])));
var FacilityRowContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  flex: 1;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  flex: 1;\n"])));
var FacilityItemContainer = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  flex: 1;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  flex: 1;\n"])));
var CardsContainer = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  position: relative;\n  overflow: hidden;\n  background: inherit;\n"], ["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  position: relative;\n  overflow: hidden;\n  background: inherit;\n"])));
var HeaderRow = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 16px;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 16px;\n"])));
var TransactionDetailsContainer = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  ", ";\n  & > div {\n    margin-bottom: 8px;\n  }\n\n  & > div:last-of-type {\n    margin-bottom: 0;\n  }\n  ", ";\n"], ["\n  ", ";\n  & > div {\n    margin-bottom: 8px;\n  }\n\n  & > div:last-of-type {\n    margin-bottom: 0;\n  }\n  ", ";\n"])), stackedCardStyles, function (props) { return props.isLoading && props.theme.blur.loading; });
var FacilitiesContainer = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  ", ";\n  & > span:first-of-type {\n    margin-bottom: 16px;\n  }\n\n  & > div:last-of-type {\n    margin-bottom: 0;\n  }\n  ", ";\n"], ["\n  ", ";\n  & > span:first-of-type {\n    margin-bottom: 16px;\n  }\n\n  & > div:last-of-type {\n    margin-bottom: 0;\n  }\n  ", ";\n"])), stackedCardStyles, function (props) { return props.isLoading && props.theme.blur.loading; });
var NameContainer = styled(TableColumn)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 16px;\n"], ["\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 16px;\n"])));
var DataItemRow = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n"], ["\n  display: flex;\n  flex: 1;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15;

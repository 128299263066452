var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Typography } from 'designSystem';
import React from 'react';
import styled from 'styled-components';
import { Modal } from '../Modal';
import Graphic from 'assets/enroll-patient-graphic-2.svg';
var UnderConstruction = function () {
    return (React.createElement(Modal, { size: "small" },
        React.createElement(Typography, { variant: "displayLarge" }, "Under Construction"),
        React.createElement(Image, { src: Graphic }),
        React.createElement(Typography, { variant: "headline" }, "This screens Portal Testing Experience\u2122 is not ready :-(")));
};
export default UnderConstruction;
var Image = styled.img(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var templateObject_1;

import { ConsumerRewardOptInStatus, OptInStatus, UserRole, } from 'types/codegen/hooks';
export var accountList = [
    'A-0000001-DEV',
    'A-1801133-DEV',
    'A-2738918-DEV',
    'A-3640319-DEV',
];
export var getTotalConsumersRewardedByFacilityId = {
    totalConsumersRewarded: 0,
    data: [
        {
            rewardAmount: 0,
            count: 0,
        },
    ],
};
export var getTransactionHistorySummary = {
    totalFreeVialsAvailable: 0,
    totalRewardPointsEarned: 0,
    totalRewardsGiven: 0,
    totalVialsEarned: 0,
    totalVialsRedeemed: 0,
};
export var getPromotions = {
    promotions: [
        {
            description: '1K Club Offer',
            meta: {
                counterSubText: 'Qualifying Vials',
                benefitsHeader: 'Unlock 1k Club',
                counterText: 'Vials Needed to Unlock Elite+ Pricing',
                modalBody: 'How to unlock? Purchase 1000 Vials cumulatively in the 2024 calendar year, and lock in Elite+ pricing for the rest of the year regardless of tier!',
                hasCloseableBanner: true,
                body: 'Purchase 1000 Vials in 2024, and lock in Elite+ pricing until the end of next year, regardless of Evolux tier.',
                title: 'Unlock 1 Year of Exclusive Pricing!',
                cta: 'Order Jeuveau',
                modalTitle: 'With 1k Club, you get:',
                terms: 'Offer ends on 12/31/2024',
                assetPath: 'apps-dev-assets',
                upgradeToLevel: 'LEVEL_0',
                benefitsTiles: [
                    {
                        body: 'Exclusive Elite+ Pricing per vial through the end of 2025',
                        icon: 'club-1k',
                        header: '1k Club - $240 Savings',
                    },
                    {
                        body: 'Copy',
                        icon: 'club-1k',
                        header: 'Invitation to Elite Summit 2025',
                    },
                    {
                        body: 'Copy',
                        icon: 'club-1k',
                        header: 'Exclusive Thank You Box',
                    },
                ],
                sortOrder: 0,
                header: 'JOIN THE CLUB AND',
                modalHeader: 'Join the 1k Club and unlock a year of exclusive pricing.',
                modalList: [
                    'Elite+ price guarantee until end of 2025',
                    'An exclusive thank-you box',
                    'An invitation to the Elite Summit 2025',
                ],
            },
            promoId: '2024_1K_CLUB_INTRO',
            __typename: 'Promo',
        },
        {
            description: '2024 Club Evolus Buy-in Offer',
            meta: {
                body: 'Limited time offer! Opt in to The Club, your turnkey patient membership program, when you purchase 70 vials and use promo code: <strong>CLUBPRODUCT</strong> at checkout.',
                title: 'JOIN THE CLUB',
                badgeArt: 'club-evolus-badge',
                termsAndConditions: 'club-evolus',
                badge: {
                    description: 'Opt in to Club',
                    shortTitle: 'CLUB',
                    title: 'Club Evolus',
                    subTitle: '70 VIALS',
                },
                cta: 'Apply Offer',
                subTitle: 'NEW OFFER',
                terms: 'Offer ends on 12/31/24',
                upgradeToLevel: 'LEVEL_0',
                assetPath: 'apps-dev-assets',
                sortOrder: 0,
                learnMoreButton: 'club-evolus-learn-more',
                promoCode: 'THECLUB',
            },
            promoId: '2024_CLUB_70VIAL_OPTIN_OFFER',
            __typename: 'Promo',
        },
        {
            description: 'Tier It Up Elite+ Offer',
            meta: {
                badge: {
                    description: '+ Upgrading to Elite+',
                    shortTitle: 'Elite+',
                    subTitle: '$360/VIAL FOR 300 VIALS',
                    title: 'Elite+',
                },
                cta: 'Apply Offer',
                subTitle: 'LIMITED TIME OFFER',
                terms: 'Offer ends on 6/30/24',
                upgradeToLevel: 'LEVEL_8',
                sortOrder: 1,
                promoCode: 'TIU300',
                body: 'For a limited time, we’re offering a discounted rate of $360/vial when you purchase 300 vials and use Promo Code <strong>TIU300</strong> at checkout.',
                title: 'ELITE+ Upgrade',
            },
            promoId: '2024_TIU_ELITEPLUS_OFFER',
            __typename: 'Promo',
        },
        {
            description: 'Tier It Up Elite Offer',
            meta: {
                badge: {
                    description: '+ Upgrading to Elite',
                    shortTitle: 'Elite',
                    subTitle: '$370/VIAL FOR 220 VIALS',
                    title: 'Elite',
                },
                cta: 'Apply Offer',
                subTitle: 'LIMITED TIME OFFER',
                terms: 'Offer ends on 6/30/24',
                upgradeToLevel: 'LEVEL_7',
                sortOrder: 1,
                promoCode: 'TIU220',
                body: 'For a limited time, we’re offering a discounted rate of $370/vial when you purchase 220 vials and use Promo Code <strong>TIU220</strong> at checkout.',
                title: 'ELITE Upgrade',
            },
            promoId: '2024_TIU_ELITE_OFFER',
            __typename: 'Promo',
        },
        {
            description: 'Dynamic Upsell Tier Vial Count Test1',
            meta: {
                badge: {
                    description: '+ Upgrading to Platinum',
                    shortTitle: 'Platinum',
                    title: 'Platinum',
                    subTitle: '$390/VIAL FOR 30 VIALS',
                },
                cta: 'Buy Now',
                subTitle: 'EXCLUSIVE OFFER',
                terms: 'Offer ends on 6/30/24',
                upgradeToLevel: 'LEVEL_5',
                sortOrder: 1,
                promoCode: 'UPSELLVIALTEST1',
                body: "You're within 30 vials of a discounted rate of $390/vial. Purchase 30 vials and use Promo Code <strong>UPSELLVIALTEST1</strong> at checkout.",
                title: 'PLATINUM Upgrade',
            },
            promoId: 'DYNAMIC_UPSELL_VIAL_COUNT_TEST1',
            __typename: 'Promo',
        },
    ],
    __typename: 'GetPromotionsResponse',
};
var fakeUsersList = [
    {
        userId: 'U-2605414-DEV',
        user: {
            firstName: 'Brandon',
            lastName: 'Hahr',
            email: 'brandon@kingsmendv.com',
            hasAcceptedTerms: true,
        },
        role: UserRole.AccountAdmin,
    },
    {
        userId: 'U-1111111-DEV',
        user: {
            firstName: 'Keith',
            lastName: 'Schmeichel',
            email: 'keith.schmeichel@kingsmendv.com',
            hasAcceptedTerms: true,
        },
        role: UserRole.AccountAdmin,
    },
    {
        userId: 'U-2222222-DEV',
        user: {
            firstName: 'Iram',
            lastName: 'Garcia',
            email: 'iram.garcia@kingsmendv.com',
            hasAcceptedTerms: false,
        },
        role: UserRole.AccountAdmin,
    },
];
export var accounts = [
    {
        account: {
            rewardPointsEarned: 0,
            availableFreeVials: 2,
            users: fakeUsersList,
            rewardVialsRedeemed: 0,
            hasEvolusCredit: false,
            id: 'A-0000001-DEV',
            status: 'ACTIVE',
            name: 'Kingsmen Aesthetics',
            mainContactUserId: 'U-2605414-DEV',
            pricingModel: 'ACCOUNT_LOYALTY',
            hasSignedFinancialAgreement: true,
            hasSignedMedicalLiabilityAgreement: true,
            paymentMethods: [
                {
                    paymentMethodId: '108219691-1996707378',
                    accountId: 'A-000001-DEV',
                    name: 'KDV CC',
                    createdAt: '2022-01-01T00:00:00Z',
                    updatedAt: '2022-01-01T00:00:00Z',
                    sensitive: {
                        ach: null,
                        cc: {
                            network: 'VISA',
                            number: '411111******1111',
                            expMonth: '08',
                            expYear: '24',
                            __typename: 'SensitiveCCDetails',
                        },
                        loc: null,
                        type: 'CC',
                        __typename: 'SensitivePaymentDetails',
                    },
                    type: 'GATEWAY',
                    __typename: 'PaymentMethod',
                },
            ],
            currentQuarterLoyalty: {
                vialsPurchased: 0,
                startOfQuarterPricedWith: {
                    level: 'LEVEL_2',
                },
                next: {
                    name: 'LEVEL_3',
                },
                pricedWith: {
                    level: 'LEVEL_2',
                    quarterId: '2024Q1',
                },
                levels: [
                    {
                        name: 'LEVEL_0',
                        rank: 1,
                        percentOff: 0,
                        vialThreshold: 0,
                        __typename: 'LoyaltyLevel',
                    },
                    {
                        name: 'LEVEL_1',
                        rank: 2,
                        percentOff: 0.016393,
                        vialThreshold: 10,
                        __typename: 'LoyaltyLevel',
                    },
                    {
                        name: 'LEVEL_2',
                        rank: 3,
                        percentOff: 0.14754,
                        vialThreshold: 20,
                        __typename: 'LoyaltyLevel',
                    },
                    {
                        name: 'LEVEL_3',
                        rank: 4,
                        percentOff: 0.2459,
                        vialThreshold: 40,
                        __typename: 'LoyaltyLevel',
                    },
                    {
                        name: 'LEVEL_4',
                        rank: 5,
                        percentOff: 0.278689,
                        vialThreshold: 70,
                        __typename: 'LoyaltyLevel',
                    },
                    {
                        name: 'LEVEL_5',
                        rank: 6,
                        percentOff: 0.31967,
                        vialThreshold: 100,
                        __typename: 'LoyaltyLevel',
                    },
                    {
                        name: 'LEVEL_6',
                        rank: 7,
                        percentOff: 0.33606,
                        vialThreshold: 140,
                        __typename: 'LoyaltyLevel',
                    },
                    {
                        name: 'LEVEL_7',
                        rank: 8,
                        percentOff: 0.36065,
                        vialThreshold: 220,
                        __typename: 'LoyaltyLevel',
                    },
                    {
                        name: 'LEVEL_8',
                        rank: 9,
                        percentOff: 0.39344,
                        vialThreshold: 300,
                        __typename: 'LoyaltyLevel',
                    },
                ],
                __typename: 'AccountLoyalty',
            },
            annualVialsPurchased: {
                presentYearVialsPurchased: 20,
                previousYearVialsPurchased: 110,
                __typename: 'AnnualVialsPurchased',
            },
            facilities: [
                {
                    name: "Jane's MedSpa",
                    id: 'F-8491328-DEV',
                    status: 'ACTIVE',
                    hasSignedMedicalLiabilityAgreement: true,
                    shippingAddress: {
                        addressLine1: '1080 FIRST COLONIAL RD',
                        addressLine2: 'STE 200',
                        city: 'VIRGINIA BCH',
                        state: 'VA',
                        zip: '23454',
                        __typename: 'Address',
                    },
                    __typename: 'Facility',
                },
            ],
            address: {
                addressLine1: '123 Test',
                addressLine2: '',
                city: 'Anaheim',
                state: 'CA',
                zip: '12345',
                __typename: 'Address',
            },
            __typename: 'Account',
        },
    },
    {
        account: {
            rewardPointsEarned: 0,
            rewardVialsRedeemed: 0,
            availableFreeVials: 2,
            users: fakeUsersList,
            hasEvolusCredit: false,
            id: 'A-1801133-DEV',
            status: 'ACTIVE',
            name: 'Healthy MedSpa Association - Unsigned Agreements',
            mainContactUserId: 'U-2605414-DEV',
            pricingModel: 'ACCOUNT_LOYALTY',
            hasSignedFinancialAgreement: false,
            hasSignedMedicalLiabilityAgreement: false,
            paymentMethods: [
                {
                    paymentMethodId: '108219691-1996707378',
                    accountId: 'A-1801133-DEV',
                    name: 'KDV CC',
                    createdAt: '2022-01-01T00:00:00Z',
                    updatedAt: '2022-01-01T00:00:00Z',
                    sensitive: {
                        ach: null,
                        cc: {
                            network: 'VISA',
                            number: '411111******1111',
                            expMonth: '08',
                            expYear: '24',
                            __typename: 'SensitiveCCDetails',
                        },
                        loc: null,
                        type: 'CC',
                        __typename: 'SensitivePaymentDetails',
                    },
                    type: 'GATEWAY',
                    __typename: 'PaymentMethod',
                },
            ],
            currentQuarterLoyalty: {
                vialsPurchased: 0,
                startOfQuarterPricedWith: {
                    level: 'LEVEL_2',
                },
                next: {
                    name: 'LEVEL_3',
                },
                pricedWith: {
                    level: 'LEVEL_2',
                    quarterId: '2024Q1',
                    __typename: 'LoyaltyPricedWith',
                },
                levels: [
                    {
                        name: 'LEVEL_0',
                        rank: 1,
                        percentOff: 0,
                        vialThreshold: 0,
                        __typename: 'LoyaltyLevel',
                    },
                    {
                        name: 'LEVEL_1',
                        rank: 2,
                        percentOff: 0.016393,
                        vialThreshold: 10,
                        __typename: 'LoyaltyLevel',
                    },
                    {
                        name: 'LEVEL_2',
                        rank: 3,
                        percentOff: 0.14754,
                        vialThreshold: 20,
                        __typename: 'LoyaltyLevel',
                    },
                    {
                        name: 'LEVEL_3',
                        rank: 4,
                        percentOff: 0.2459,
                        vialThreshold: 40,
                        __typename: 'LoyaltyLevel',
                    },
                    {
                        name: 'LEVEL_4',
                        rank: 5,
                        percentOff: 0.278689,
                        vialThreshold: 70,
                        __typename: 'LoyaltyLevel',
                    },
                    {
                        name: 'LEVEL_5',
                        rank: 6,
                        percentOff: 0.31967,
                        vialThreshold: 100,
                        __typename: 'LoyaltyLevel',
                    },
                    {
                        name: 'LEVEL_6',
                        rank: 7,
                        percentOff: 0.33606,
                        vialThreshold: 140,
                        __typename: 'LoyaltyLevel',
                    },
                    {
                        name: 'LEVEL_7',
                        rank: 8,
                        percentOff: 0.36065,
                        vialThreshold: 220,
                        __typename: 'LoyaltyLevel',
                    },
                    {
                        name: 'LEVEL_8',
                        rank: 9,
                        percentOff: 0.39344,
                        vialThreshold: 300,
                        __typename: 'LoyaltyLevel',
                    },
                ],
                __typename: 'AccountLoyalty',
            },
            facilities: [
                {
                    name: "Jane's MedSpa",
                    id: 'F-8491328-DEV',
                    status: 'ACTIVE',
                    hasSignedMedicalLiabilityAgreement: true,
                    shippingAddress: {
                        addressLine1: '1080 FIRST COLONIAL RD',
                        addressLine2: 'STE 200',
                        city: 'VIRGINIA BCH',
                        state: 'VA',
                        zip: '23454',
                        __typename: 'Address',
                    },
                    __typename: 'Facility',
                },
            ],
            address: {
                addressLine1: '123 Test',
                addressLine2: '',
                city: 'Anaheim',
                state: 'CA',
                zip: '12345',
                __typename: 'Address',
            },
            __typename: 'Account',
        },
        permissions: ['ACCOUNT_ADMIN', 'BASE_ACCESS', 'CONSUMER_REWARDS_MANAGE'],
        role: 'ACCOUNT_ADMIN',
        status: 'ENABLED',
        __typename: 'UserAccount',
    },
    {
        account: {
            rewardPointsEarned: 0,
            availableFreeVials: 2,
            rewardVialsRedeemed: 0,
            users: fakeUsersList,
            hasEvolusCredit: false,
            id: 'A-2738918-DEV',
            status: 'ACTIVE',
            name: 'MedSpa & Wellness',
            mainContactUserId: 'U-7766421-DEV',
            pricingModel: 'GROUP_LOYALTY',
            hasSignedFinancialAgreement: true,
            hasSignedMedicalLiabilityAgreement: true,
            paymentMethods: [
                {
                    paymentMethodId: '108219691-1996707378',
                    accountId: 'A-2738918-DEV',
                    name: 'KDV CC',
                    createdAt: '2022-01-01T00:00:00Z',
                    updatedAt: '2022-01-01T00:00:00Z',
                    sensitive: {
                        ach: null,
                        cc: {
                            network: 'VISA',
                            number: '411111******1111',
                            expMonth: '08',
                            expYear: '24',
                            __typename: 'SensitiveCCDetails',
                        },
                        loc: null,
                        type: 'CC',
                        __typename: 'SensitivePaymentDetails',
                    },
                    type: 'GATEWAY',
                    __typename: 'PaymentMethod',
                },
            ],
            currentQuarterLoyalty: {
                vialsPurchased: 0,
                startOfQuarterPricedWith: {
                    level: 'LEVEL_2',
                },
                next: {
                    name: 'LEVEL_3',
                },
                pricedWith: {
                    level: 'LEVEL_2',
                    quarterId: '2024Q1',
                    __typename: 'LoyaltyPricedWith',
                },
                levels: [
                    {
                        name: 'LEVEL_0',
                        rank: 1,
                        percentOff: 0,
                        vialThreshold: 0,
                        __typename: 'LoyaltyLevel',
                    },
                    {
                        name: 'LEVEL_1',
                        rank: 2,
                        percentOff: 0.016393,
                        vialThreshold: 10,
                        __typename: 'LoyaltyLevel',
                    },
                    {
                        name: 'LEVEL_2',
                        rank: 3,
                        percentOff: 0.14754,
                        vialThreshold: 20,
                        __typename: 'LoyaltyLevel',
                    },
                    {
                        name: 'LEVEL_3',
                        rank: 4,
                        percentOff: 0.2459,
                        vialThreshold: 40,
                        __typename: 'LoyaltyLevel',
                    },
                    {
                        name: 'LEVEL_4',
                        rank: 5,
                        percentOff: 0.278689,
                        vialThreshold: 70,
                        __typename: 'LoyaltyLevel',
                    },
                    {
                        name: 'LEVEL_5',
                        rank: 6,
                        percentOff: 0.31967,
                        vialThreshold: 100,
                        __typename: 'LoyaltyLevel',
                    },
                    {
                        name: 'LEVEL_6',
                        rank: 7,
                        percentOff: 0.33606,
                        vialThreshold: 140,
                        __typename: 'LoyaltyLevel',
                    },
                    {
                        name: 'LEVEL_7',
                        rank: 8,
                        percentOff: 0.36065,
                        vialThreshold: 220,
                        __typename: 'LoyaltyLevel',
                    },
                    {
                        name: 'LEVEL_8',
                        rank: 9,
                        percentOff: 0.39344,
                        vialThreshold: 300,
                        __typename: 'LoyaltyLevel',
                    },
                ],
                __typename: 'AccountLoyalty',
            },
            facilities: [
                {
                    name: 'test',
                    id: 'F-5900022-DEV',
                    hasSignedMedicalLiabilityAgreement: true,
                    shippingAddress: {
                        addressLine1: 'test',
                        addressLine2: 'test',
                        city: 'test',
                        state: 'VA',
                        zip: '44444',
                        __typename: 'Address',
                    },
                    __typename: 'Facility',
                },
                {
                    name: 'EVO666-TEST-FACILITY2',
                    id: 'F-1963442-DEV',
                    hasSignedMedicalLiabilityAgreement: true,
                    shippingAddress: {
                        addressLine1: '8561 IRVINE CENTER DR',
                        addressLine2: '',
                        city: 'IRVINE',
                        state: 'CA',
                        zip: '92618',
                        __typename: 'Address',
                    },
                    __typename: 'Facility',
                },
                {
                    name: 'EVO666-TEST-FACILITY3',
                    id: 'F-5920730-DEV',
                    shippingAddress: {
                        addressLine1: '8561 IRVINE CENTER DR',
                        addressLine2: '',
                        city: 'IRVINE',
                        state: 'CA',
                        zip: '92618',
                        __typename: 'Address',
                    },
                    __typename: 'Facility',
                },
                {
                    name: 'EVO666-TEST-FACILITY1',
                    id: 'F-2815221-DEV',
                    shippingAddress: {
                        addressLine1: '8561 IRVINE CENTER DR',
                        addressLine2: '',
                        city: 'IRVINE',
                        state: 'CA',
                        zip: '92618',
                        __typename: 'Address',
                    },
                    __typename: 'Facility',
                },
            ],
            address: {
                addressLine1: 'test',
                addressLine2: 'test',
                city: 'catest',
                state: 'AL',
                zip: '55555',
                __typename: 'Address',
            },
            __typename: 'Account',
        },
        permissions: ['ACCOUNT_ADMIN', 'BASE_ACCESS', 'CONSUMER_REWARDS_MANAGE'],
        role: 'ACCOUNT_ADMIN',
        status: 'ENABLED',
        __typename: 'UserAccount',
    },
    {
        account: {
            availableFreeVials: 2,
            annualVialsPurchased: {
                presentYearVialsPurchased: 0,
                previousYearVialsPurchased: 110,
                __typename: 'AnnualVialsPurchased',
            },
            users: fakeUsersList,
            hasEvolusCredit: false,
            id: 'A-3640319-DEV',
            status: 'ACTIVE',
            rewardPointsEarned: 0,
            rewardVialsRedeemed: 0,
            name: 'Newport Aesthetics',
            mainContactUserId: 'U-2605414-DEV',
            pricingModel: 'CUSTOM_ACCOUNT',
            hasSignedFinancialAgreement: true,
            hasSignedMedicalLiabilityAgreement: true,
            paymentMethods: [
                {
                    paymentMethodId: '108219691-1996707378',
                    accountId: 'A-3640319-DEV',
                    name: 'KDV CC',
                    createdAt: '2022-01-01T00:00:00Z',
                    updatedAt: '2022-01-01T00:00:00Z',
                    sensitive: {
                        ach: null,
                        cc: {
                            network: 'VISA',
                            number: '411111******1111',
                            expMonth: '08',
                            expYear: '24',
                            __typename: 'SensitiveCCDetails',
                        },
                        loc: null,
                        type: 'CC',
                        __typename: 'SensitivePaymentDetails',
                    },
                    type: 'GATEWAY',
                    __typename: 'PaymentMethod',
                },
            ],
            currentQuarterLoyalty: {
                vialsPurchased: 0,
                rewardVialsRedeemed: 0,
                startOfQuarterPricedWith: {
                    level: 'LEVEL_2',
                },
                next: {
                    name: 'LEVEL_3',
                },
                pricedWith: {
                    level: 'LEVEL_2',
                    quarterId: '2024Q1',
                    __typename: 'LoyaltyPricedWith',
                },
                levels: [
                    {
                        name: 'LEVEL_0',
                        rank: 1,
                        percentOff: 0,
                        vialThreshold: 0,
                        __typename: 'LoyaltyLevel',
                    },
                    {
                        name: 'LEVEL_1',
                        rank: 2,
                        percentOff: 0.016393,
                        vialThreshold: 10,
                        __typename: 'LoyaltyLevel',
                    },
                    {
                        name: 'LEVEL_2',
                        rank: 3,
                        percentOff: 0.14754,
                        vialThreshold: 20,
                        __typename: 'LoyaltyLevel',
                    },
                    {
                        name: 'LEVEL_3',
                        rank: 4,
                        percentOff: 0.2459,
                        vialThreshold: 40,
                        __typename: 'LoyaltyLevel',
                    },
                    {
                        name: 'LEVEL_4',
                        rank: 5,
                        percentOff: 0.278689,
                        vialThreshold: 70,
                        __typename: 'LoyaltyLevel',
                    },
                    {
                        name: 'LEVEL_5',
                        rank: 6,
                        percentOff: 0.31967,
                        vialThreshold: 100,
                        __typename: 'LoyaltyLevel',
                    },
                    {
                        name: 'LEVEL_6',
                        rank: 7,
                        percentOff: 0.33606,
                        vialThreshold: 140,
                        __typename: 'LoyaltyLevel',
                    },
                    {
                        name: 'LEVEL_7',
                        rank: 8,
                        percentOff: 0.36065,
                        vialThreshold: 220,
                        __typename: 'LoyaltyLevel',
                    },
                    {
                        name: 'LEVEL_8',
                        rank: 9,
                        percentOff: 0.39344,
                        vialThreshold: 300,
                        __typename: 'LoyaltyLevel',
                    },
                ],
                __typename: 'AccountLoyalty',
            },
            facilities: [
                {
                    name: 'Newport Facility - Custom',
                    id: 'F-5159229-DEV',
                    hasSignedMedicalLiabilityAgreement: true,
                    shippingAddress: {
                        addressLine1: '1808 VERDUGO BLVD',
                        addressLine2: 'STE 206',
                        city: 'GLENDALE',
                        state: 'CA',
                        zip: '91208',
                        __typename: 'Address',
                    },
                    __typename: 'Facility',
                },
            ],
            address: {
                addressLine1: '123 Tester St 2',
                addressLine2: '',
                city: 'Anaheim',
                state: 'CA',
                zip: '12345',
                __typename: 'Address',
            },
            __typename: 'Account',
        },
        permissions: ['ACCOUNT_ADMIN', 'BASE_ACCESS', 'CONSUMER_REWARDS_MANAGE'],
        role: 'ACCOUNT_ADMIN',
        status: 'ENABLED',
        __typename: 'UserAccount',
    },
];
export var defaultAccount = {
    hasEvolusCredit: false,
    id: 'A-0000001-DEV',
    status: 'ACTIVE',
    name: 'Example',
    mainContactUserId: 'U-2605414-DEV',
    pricingModel: 'ACCOUNT_LOYALTY',
    hasSignedFinancialAgreement: true,
    hasSignedMedicalLiabilityAgreement: true,
    paymentMethods: [
        {
            paymentMethodId: '108219691-1996707378',
            accountId: 'A-6993420-DEV',
            name: 'test Credit',
            createdAt: '2022-01-01T00:00:00Z',
            updatedAt: '2022-01-01T00:00:00Z',
            sensitive: {
                ach: null,
                cc: {
                    network: 'VISA',
                    number: '411111******1111',
                    expMonth: '08',
                    expYear: '24',
                    __typename: 'SensitiveCCDetails',
                },
                loc: null,
                type: 'CC',
                __typename: 'SensitivePaymentDetails',
            },
            type: 'GATEWAY',
            __typename: 'PaymentMethod',
        },
        {
            paymentMethodId: '1636473553-235689223',
            accountId: 'A-6993420-DEV',
            name: "mommy's bank",
            sensitive: {
                ach: {
                    accountName: 'asdfa',
                    accountNumber: '5xxxx3123',
                    routingNumber: '7xxxx6823',
                    __typename: 'SensitiveACHDetails',
                },
                cc: null,
                loc: null,
                type: 'ACH',
                __typename: 'SensitivePaymentDetails',
            },
            type: 'GATEWAY',
            __typename: 'PaymentMethod',
        },
        {
            paymentMethodId: '445737372-248690971',
            accountId: 'A-6993420-DEV',
            name: 'NMI CC',
            sensitive: {
                ach: null,
                cc: {
                    network: 'VISA',
                    number: '476173******2222',
                    expMonth: '08',
                    expYear: '31',
                    __typename: 'SensitiveCCDetails',
                },
                loc: null,
                type: 'CC',
                __typename: 'SensitivePaymentDetails',
            },
            type: 'GATEWAY',
            __typename: 'PaymentMethod',
        },
    ],
    currentQuarterLoyalty: {
        startOfQuarterPricedWith: {
            level: 'LEVEL_2',
        },
        next: {
            name: 'LEVEL_3',
        },
        pricedWith: {
            level: 'LEVEL_2',
            quarterId: '2024Q1',
            __typename: 'LoyaltyPricedWith',
        },
        levels: [
            {
                name: 'LEVEL_0',
                rank: 1,
                percentOff: 0,
                vialThreshold: 0,
                __typename: 'LoyaltyLevel',
            },
            {
                name: 'LEVEL_1',
                rank: 2,
                percentOff: 0.016393,
                vialThreshold: 10,
                __typename: 'LoyaltyLevel',
            },
            {
                name: 'LEVEL_2',
                rank: 3,
                percentOff: 0.14754,
                vialThreshold: 20,
                __typename: 'LoyaltyLevel',
            },
            {
                name: 'LEVEL_3',
                rank: 4,
                percentOff: 0.2459,
                vialThreshold: 40,
                __typename: 'LoyaltyLevel',
            },
            {
                name: 'LEVEL_4',
                rank: 5,
                percentOff: 0.278689,
                vialThreshold: 70,
                __typename: 'LoyaltyLevel',
            },
            {
                name: 'LEVEL_5',
                rank: 6,
                percentOff: 0.31967,
                vialThreshold: 100,
                __typename: 'LoyaltyLevel',
            },
            {
                name: 'LEVEL_6',
                rank: 7,
                percentOff: 0.33606,
                vialThreshold: 140,
                __typename: 'LoyaltyLevel',
            },
            {
                name: 'LEVEL_7',
                rank: 8,
                percentOff: 0.36065,
                vialThreshold: 220,
                __typename: 'LoyaltyLevel',
            },
            {
                name: 'LEVEL_8',
                rank: 9,
                percentOff: 0.39344,
                vialThreshold: 300,
                __typename: 'LoyaltyLevel',
            },
        ],
        __typename: 'AccountLoyalty',
    },
    annualVialsPurchased: {
        presentYearVialsPurchased: 20,
        previousYearVialsPurchased: 110,
        __typename: 'AnnualVialsPurchased',
    },
    facilities: [
        {
            name: "Jane's MedSpa",
            id: 'F-8491328-DEV',
            status: 'ACTIVE',
            hasSignedMedicalLiabilityAgreement: true,
            shippingAddress: {
                addressLine1: '1080 FIRST COLONIAL RD',
                addressLine2: 'STE 200',
                city: 'VIRGINIA BCH',
                state: 'VA',
                zip: '23454',
                __typename: 'Address',
            },
            __typename: 'Facility',
        },
    ],
    address: {
        addressLine1: '123 Test',
        addressLine2: '',
        city: 'Anaheim',
        state: 'CA',
        zip: '12345',
        __typename: 'Address',
    },
    __typename: 'Account',
};
export var getConsumerRewardOptInStatus = {
    status: {
        status: ConsumerRewardOptInStatus.OptedIn,
    },
    isInactive: false,
    lockStatus: {
        isLocked: false,
    },
    isLocked: false,
    canOptIn: true,
};
export var getConsumerRewardOptInStatusRewards = {
    canOptIn: true,
    status: {
        status: ConsumerRewardOptInStatus.OptedIn,
        updatedAt: '2024-01-01',
    },
    isInactive: false,
    lockStatus: {
        isLocked: false,
        __typename: 'ConsumerRewardLockStatus',
    },
    __typename: 'GetConsumerRewardOptInStatusResponse',
};
export var getFreeVialProgress = {
    rewardsRequiredForNextFreeVial: 9,
    rewardsRedeemedTowardsNextFreeVial: 0,
};
export var getIndividualAccountLoyalty = {
    accountLoyalty: {
        vialsPurchased: 0,
        __typename: 'AccountLoyalty',
    },
    __typename: 'GetAccountLoyaltyResponse',
};
export var userData = {
    id: 'U-2605414-DEV',
    email: 'brandon@kingsmendv.com',
    firstName: 'Brandon',
    createdAt: '2024-01-09T00:28:58.581+00:00',
    lastName: 'Hahr',
    accounts: accounts,
};
export var getPaymentMethodsByAccount = {
    paymentMethods: [
        {
            paymentMethodId: '108219691-1996707378',
            accountId: 'A-6993420-DEV',
            name: 'test Credit',
            createdAt: '2022-01-01T00:00:00Z',
            updatedAt: '2022-01-01T00:00:00Z',
            sensitive: {
                ach: null,
                cc: {
                    network: 'VISA',
                    number: '411111******1111',
                    expMonth: '08',
                    expYear: '24',
                    __typename: 'SensitiveCCDetails',
                },
                loc: null,
                type: 'CC',
                __typename: 'SensitivePaymentDetails',
            },
            type: 'GATEWAY',
            __typename: 'PaymentMethod',
        },
        {
            paymentMethodId: '1636473553-235689223',
            accountId: 'A-6993420-DEV',
            name: "mommy's bank",
            sensitive: {
                ach: {
                    accountName: 'asdfa',
                    accountNumber: '5xxxx3123',
                    routingNumber: '7xxxx6823',
                    __typename: 'SensitiveACHDetails',
                },
                cc: null,
                loc: null,
                type: 'ACH',
                __typename: 'SensitivePaymentDetails',
            },
            type: 'GATEWAY',
            __typename: 'PaymentMethod',
        },
        {
            paymentMethodId: '445737372-248690971',
            accountId: 'A-6993420-DEV',
            name: 'NMI CC',
            sensitive: {
                ach: null,
                cc: {
                    network: 'VISA',
                    number: '476173******2222',
                    expMonth: '08',
                    expYear: '31',
                    __typename: 'SensitiveCCDetails',
                },
                loc: null,
                type: 'CC',
                __typename: 'SensitivePaymentDetails',
            },
            type: 'GATEWAY',
            __typename: 'PaymentMethod',
        },
    ],
    __typename: 'GetPaymentMethodsByAccountResponse',
};
export var promotions = [
    {
        tags: ['MY_OFFERS'],
        startDate: '2024-01-01T00:00:00-07:00',
        promoId: '2024_CLUB_70VIAL_OPTIN_OFFER',
        meta: {
            body: 'Limited time offer! Opt in to The Club, your turnkey patient membership program, when you purchase 70 vials and use promo code: <strong>CLUBPRODUCT</strong> at checkout.',
            title: 'JOIN THE CLUB',
            badgeArt: 'club-evolus-badge',
            termsAndConditions: 'club-evolus',
            badge: {
                description: 'Opt in to Club',
                shortTitle: 'CLUB',
                title: 'Club Evolus',
                subTitle: '70 VIALS',
            },
            cta: 'Apply Offer',
            subTitle: 'NEW OFFER',
            terms: 'Offer ends on 12/31/24',
            upgradeToLevel: 'LEVEL_0',
            assetPath: 'apps-dev-assets',
            sortOrder: 0,
            learnMoreButton: 'club-evolus-learn-more',
            promoCode: 'THECLUB',
        },
        standardDiscount: {
            maxDiscountedQuantity: 70,
            __typename: 'StandardDiscount',
        },
        vialsFromThreshold: 0,
        __typename: 'Promo',
    },
    {
        tags: ['MY_OFFERS'],
        startDate: '2023-12-21T00:00:00-07:00',
        promoId: '2024_FEB_TIU_PLATINUM_OFFER',
        meta: {
            badge: {
                description: '+ Upgrading to Platinum',
                shortTitle: 'Plat.',
                subTitle: '$380/VIAL FOR 100 VIALS',
                title: 'Platinum',
            },
            cta: 'Apply Offer',
            subTitle: 'LIMITED TIME OFFER',
            terms: 'Offer ends on 6/30/24',
            upgradeToLevel: 'LEVEL_5',
            sortOrder: 1,
            promoCode: 'TIU150',
            body: 'For a limited time, we’re offering a discounted rate of $380/vial when you purchase 100 vials and use Promo Code <strong>TIU150</strong> at checkout.',
            title: 'PLATINUM Upgrade',
        },
        standardDiscount: {
            maxDiscountedQuantity: 100,
            __typename: 'StandardDiscount',
        },
        vialsFromThreshold: 0,
        __typename: 'Promo',
    },
    {
        tags: ['MY_OFFERS'],
        startDate: '2023-12-21T00:00:00-07:00',
        promoId: '2024_TIU_ELITEPLUS_OFFER',
        meta: {
            badge: {
                description: '+ Upgrading to Elite+',
                shortTitle: 'Elite+',
                subTitle: '$360/VIAL FOR 300 VIALS',
                title: 'Elite+',
            },
            cta: 'Apply Offer',
            subTitle: 'LIMITED TIME OFFER',
            terms: 'Offer ends on 6/30/24',
            upgradeToLevel: 'LEVEL_8',
            sortOrder: 1,
            promoCode: 'TIU300',
            body: 'For a limited time, we’re offering a discounted rate of $360/vial when you purchase 300 vials and use Promo Code <strong>TIU300</strong> at checkout.',
            title: 'ELITE+ Upgrade',
        },
        standardDiscount: {
            maxDiscountedQuantity: 300,
            __typename: 'StandardDiscount',
        },
        vialsFromThreshold: 0,
        __typename: 'Promo',
    },
    {
        tags: ['MY_OFFERS'],
        startDate: '2023-12-21T00:00:00-07:00',
        promoId: '2024_TIU_ELITE_OFFER',
        meta: {
            badge: {
                description: '+ Upgrading to Elite',
                shortTitle: 'Elite',
                subTitle: '$370/VIAL FOR 220 VIALS',
                title: 'Elite',
            },
            cta: 'Apply Offer',
            subTitle: 'LIMITED TIME OFFER',
            terms: 'Offer ends on 6/30/24',
            upgradeToLevel: 'LEVEL_7',
            sortOrder: 1,
            promoCode: 'TIU220',
            body: 'For a limited time, we’re offering a discounted rate of $370/vial when you purchase 220 vials and use Promo Code <strong>TIU220</strong> at checkout.',
            title: 'ELITE Upgrade',
        },
        standardDiscount: {
            maxDiscountedQuantity: 220,
            __typename: 'StandardDiscount',
        },
        vialsFromThreshold: 0,
        __typename: 'Promo',
    },
    {
        tags: ['MY_OFFERS'],
        startDate: '2023-12-21T00:00:00-07:00',
        promoId: '2024_TIU_GOLD_OFFER',
        meta: {
            badge: {
                description: '+ Upgrading to Gold',
                shortTitle: 'Gold',
                subTitle: '$395/VIAL FOR 40 VIALS',
                title: 'Gold',
            },
            cta: 'Apply Offer',
            subTitle: 'LIMITED TIME OFFER',
            terms: 'Offer ends on 6/30/24',
            upgradeToLevel: 'LEVEL_3',
            sortOrder: 1,
            promoCode: 'TIU40',
            body: 'For a limited time, we’re offering a discounted rate of $395/vial when you purchase 40 vials and use Promo Code <strong>TIU40</strong> at checkout.',
            title: 'GOLD Upgrade',
        },
        standardDiscount: {
            maxDiscountedQuantity: 40,
            __typename: 'StandardDiscount',
        },
        vialsFromThreshold: 0,
        __typename: 'Promo',
    },
    {
        tags: ['MY_OFFERS'],
        startDate: '2023-12-21T00:00:00-07:00',
        promoId: '2024_TIU_PLATINUM_OFFER',
        meta: {
            badge: {
                description: '+ Upgrading to Platinum',
                shortTitle: 'Plat.',
                subTitle: '$380/VIAL FOR 100 VIALS',
                title: 'Platinum',
            },
            cta: 'Apply Offer',
            subTitle: 'LIMITED TIME OFFER',
            terms: 'Offer ends on 6/30/24',
            upgradeToLevel: 'LEVEL_5',
            sortOrder: 1,
            promoCode: 'TIU100',
            body: 'For a limited time, we’re offering a discounted rate of $380/vial when you purchase 100 vials and use Promo Code <strong>TIU100</strong> at checkout.',
            title: 'PLATINUM Upgrade',
        },
        standardDiscount: {
            maxDiscountedQuantity: 100,
            __typename: 'StandardDiscount',
        },
        vialsFromThreshold: 0,
        __typename: 'Promo',
    },
    {
        tags: ['MY_OFFERS'],
        startDate: '2024-01-01T00:00:00-07:00',
        promoId: 'DYNAMIC_REDEMPTION_DATE_TEST1',
        meta: {
            redemptionStartDateISO: '2024-01-01T00:01:00-08:00',
            eligibilityStartDateISO: '2024-01-01T00:01:00-08:00',
            dynamicRedemptionRange: {
                dateRangePart: 'day',
                dateRangeLength: 30,
            },
            description: "Congrats! You've unlocked $80 off for all patients. Earn 2 reward vial credits per patient checked in.",
            eligibilityEndDateISO: '2024-12-31T23:59:00-08:00',
            body: 'Purchase 20 vials to get $80 off for all patients.',
            title: 'Dynamic Redemption',
            qualifyingVialsNeeded: 20,
            badge: {
                description: 'From Purchase Date',
                shortTitle: 'Red.',
                title: 'Redemption',
                subTitle: '30 Day Test',
            },
            cta: 'Buy Now',
            redemptionEndDateISO: '2024-12-31T23:59:00-08:00',
            subTitle: 'EXCLUSIVE OFFER',
            terms: 'Offer ends on 6/30/24',
            upgradeToLevel: 'LEVEL_0',
            sortOrder: 1,
            promoCode: 'DYNAMICREDTEST1',
            rewardAmount: 80,
            couponColor: 'RED',
            qualifyingVialsPurchased: 0,
            isEligibilityPeriod: true,
            isRedemptionPeriod: true,
            isExtendedRedemptionPeriod: false,
            isQualified: false,
            redemptionStartDate: '01/01/2024',
            eligibilityEndDate: '12/31/2024',
            eligibilityStartDate: '01/01/2024',
            redemptionEndDate: '12/31/2024',
        },
        standardDiscount: {
            maxDiscountedQuantity: 20,
            __typename: 'StandardDiscount',
        },
        vialsFromThreshold: 0,
        __typename: 'Promo',
    },
    {
        tags: ['MY_OFFERS'],
        startDate: '2024-01-01T00:00:00-07:00',
        promoId: 'DYNAMIC_UPSELL_VIAL_COUNT_TEST1',
        meta: {
            badge: {
                description: '+ Upgrading to Platinum',
                shortTitle: 'Platinum',
                title: 'Platinum',
                subTitle: '$390/VIAL FOR 30 VIALS',
            },
            cta: 'Buy Now',
            subTitle: 'EXCLUSIVE OFFER',
            terms: 'Offer ends on 6/30/24',
            upgradeToLevel: 'LEVEL_5',
            sortOrder: 1,
            promoCode: 'UPSELLVIALTEST1',
            body: "You're within 30 vials of a discounted rate of $390/vial. Purchase 30 vials and use Promo Code <strong>UPSELLVIALTEST1</strong> at checkout.",
            title: 'PLATINUM Upgrade',
        },
        standardDiscount: {
            maxDiscountedQuantity: 30,
            __typename: 'StandardDiscount',
        },
        vialsFromThreshold: 10,
        __typename: 'Promo',
    },
];
export var facilities = [
    {
        __typename: 'Facility',
        id: 'F-0000001-DEV',
        shippingAddress: '123 Test Street',
        hasSignedMedicalLiabilityAgreement: true,
        name: 'KDV MedSpa Facility',
        type: 'MedSpa',
        accountId: 'A-0000001-DEV',
        account: {
            hasEvolusCredit: false,
            id: 'A-0000001-DEV',
            status: 'ACTIVE',
            name: 'Example',
            mainContactUserId: 'U-2605414-DEV',
            pricingModel: 'ACCOUNT_LOYALTY',
            hasSignedFinancialAgreement: true,
            hasSignedMedicalLiabilityAgreement: true,
            currentQuarterLoyalty: {
                startOfQuarterPricedWith: {
                    level: 'LEVEL_2',
                },
                next: {
                    name: 'LEVEL_3',
                },
                pricedWith: {
                    level: 'LEVEL_2',
                    quarterId: '2024Q1',
                    __typename: 'LoyaltyPricedWith',
                },
                levels: [
                    {
                        name: 'LEVEL_0',
                        rank: 1,
                        percentOff: 0,
                        vialThreshold: 0,
                        __typename: 'LoyaltyLevel',
                    },
                    {
                        name: 'LEVEL_1',
                        rank: 2,
                        percentOff: 0.016393,
                        vialThreshold: 10,
                        __typename: 'LoyaltyLevel',
                    },
                    {
                        name: 'LEVEL_2',
                        rank: 3,
                        percentOff: 0.14754,
                        vialThreshold: 20,
                        __typename: 'LoyaltyLevel',
                    },
                    {
                        name: 'LEVEL_3',
                        rank: 4,
                        percentOff: 0.2459,
                        vialThreshold: 40,
                        __typename: 'LoyaltyLevel',
                    },
                    {
                        name: 'LEVEL_4',
                        rank: 5,
                        percentOff: 0.278689,
                        vialThreshold: 70,
                        __typename: 'LoyaltyLevel',
                    },
                    {
                        name: 'LEVEL_5',
                        rank: 6,
                        percentOff: 0.31967,
                        vialThreshold: 100,
                        __typename: 'LoyaltyLevel',
                    },
                    {
                        name: 'LEVEL_6',
                        rank: 7,
                        percentOff: 0.33606,
                        vialThreshold: 140,
                        __typename: 'LoyaltyLevel',
                    },
                    {
                        name: 'LEVEL_7',
                        rank: 8,
                        percentOff: 0.36065,
                        vialThreshold: 220,
                        __typename: 'LoyaltyLevel',
                    },
                    {
                        name: 'LEVEL_8',
                        rank: 9,
                        percentOff: 0.39344,
                        vialThreshold: 300,
                        __typename: 'LoyaltyLevel',
                    },
                ],
                __typename: 'AccountLoyalty',
            },
            annualVialsPurchased: {
                presentYearVialsPurchased: 20,
                previousYearVialsPurchased: 110,
                __typename: 'AnnualVialsPurchased',
            },
            facilities: [
                {
                    name: "Jane's MedSpa",
                    id: 'F-8491328-DEV',
                    status: 'ACTIVE',
                    hasSignedMedicalLiabilityAgreement: true,
                    shippingAddress: {
                        addressLine1: '1080 FIRST COLONIAL RD',
                        addressLine2: 'STE 200',
                        city: 'VIRGINIA BCH',
                        state: 'VA',
                        zip: '23454',
                        __typename: 'Address',
                    },
                    __typename: 'Facility',
                },
            ],
            address: {
                addressLine1: '123 Test',
                addressLine2: '',
                city: 'Anaheim',
                state: 'CA',
                zip: '12345',
                __typename: 'Address',
            },
            __typename: 'Account',
        },
        medicalLicenseId: 'CA-123456',
        officePhoneNumber: '123-123-1234',
        status: 'active',
        createdAt: '2024-01-01T00:00:00:00',
        updatedAt: '2024-01-01T00:00:00:00',
        agreements: [
            {
                __typename: 'Agreement',
                accountId: 'A-0000001-DEV',
                id: '',
                status: 'ACTIVE',
                type: 'MEDICAL_LIABILITY',
                createdAt: '2024-01-01T00:00:00:00',
                updatedAt: '2024-01-01T00:00:00:00',
                signer: 'Brandon Hahr',
            },
        ],
        medicalLicense: 'CA-123456',
    },
];
export var mockSpecialistResponse = {
    data: {
        getInjectors: {
            externalLookupSuccess: true,
            injectors: [
                {
                    name: 'Pam Beasley',
                    uniqueId: 'KDV-001',
                    externalId: 'KB-001',
                },
                {
                    name: 'Dwayne Johnson',
                    uniqueId: 'KDV-002',
                    externalId: 'KB-002',
                },
            ],
        },
    },
};
export var transactionHistoryResponse = {
    data: {
        getTransactionHistoryCSV: {
            csvResponse: 'id,transaction_date,amount\n1,2022-01-01,100.00\n2,2022-01-02,200.00',
        },
    },
};
export var mockRewardStatementResponse = {
    data: {
        accountById: {
            facilities: [
                {
                    id: 'facility1',
                    name: 'Facility 1',
                    shippingAddress: {
                        addressLine1: '123 Main St',
                        addressLine2: 'Suite 1',
                        city: 'City',
                        state: 'State',
                        zip: '12345',
                    },
                    createdAt: '2022-01-01T00:00:00Z',
                },
            ],
        },
        getConsumerRewardOptInHistory: {
            history: [
                {
                    status: 'optedIn',
                    updatedAt: '2022-01-02T00:00:00Z',
                },
            ],
        },
        getInjectors: {
            externalLookupSuccess: true,
            injectors: [
                {
                    name: 'Pam Beasley',
                    uniqueId: 'KDV-001',
                    externalId: 'KB-001',
                },
            ],
        },
    },
};
export var evolusRewardsMockResponse = {
    data: {
        getConsumerRewardOptInStatus: {
            status: {
                status: 'optedIn',
                updatedAt: '2022-01-01T00:00:00Z',
            },
            lockStatus: {
                isLocked: false,
                updatedAt: '2022-01-01T00:00:00Z',
            },
            canOptIn: true,
            isInactive: false,
        },
        getFreeVialsAvailable: {
            freeVialsAvailable: 5,
        },
        getIndividualAccountLoyalty: {
            accountLoyalty: {
                vialsPurchased: 10,
            },
        },
    },
};
export var mockPhoneNumberInputComponent = {
    data: {
        lookupPhoneNumber: {
            isBlackListed: false,
            status: OptInStatus.OptedIn,
        },
        lookupConsumerProfile: {
            profile: {
                status: 'active',
                enrolledAt: '2022-01-01T00:00:00Z',
                enrollmentMethod: 'online',
                email: 'example@example.com',
                sourceAccountId: '123456',
                sourceFacilityId: '654321',
                isOptedIn: true,
            },
            reward: {
                earnedAt: '2022-01-02T00:00:00Z',
                eligibleAt: '2022-01-03T00:00:00Z',
                expiration: '2022-12-31T00:00:00Z',
                eligibilityStatus: 'eligible',
                rewardSource: 'promotion',
                rewardAmount: 100,
                isExtension: false,
            },
            promotion: {
                amount: 50,
                couponColor: 'red',
            },
        },
    },
};
export var mockTransactionHistoryResponse = {
    data: {
        getTransactionHistoryActivity: {
            pageCount: 1,
            recordCount: 1,
            reportId: 'report1',
            pageNumber: 1,
            activity: [
                {
                    consumerFirstName: 'John',
                    consumerLastName: 'Doe',
                    activityType: 'type1',
                    occurredOn: '2022-01-01',
                    phoneNumber: '1234567890',
                    creditsEarned: 10,
                    facilityName: 'Facility 1',
                    rewardAmount: 100,
                    rewardEligibleAt: '2022-01-02',
                    rewardExpiration: '2022-12-31',
                    rewardPromotion: {
                        accountId: 'account1',
                        amount: 100,
                        endDate: '2022-12-31',
                        terms: 'terms1',
                        campaignId: 'campaign1',
                        promoId: 'promo1',
                        couponColor: 'red',
                        startDate: '2022-01-01',
                    },
                    rewardsGiven: 1,
                    redeemedForQuantity: 1,
                    redeemedForPoints: 10,
                    orderId: 'order1',
                    staffFirstName: 'Staff',
                    staffLastName: 'Member',
                    email: 'staff@company.com',
                    checkInId: 'checkin1',
                    facilityId: 'facility1',
                    accountId: 'account1',
                    injector: {
                        name: 'Pam Beasley',
                        uniqueId: 'KDV-001',
                        externalId: 'KB-001',
                    },
                },
                {
                    consumerFirstName: 'Kobe',
                    consumerLastName: 'Bryant',
                    activityType: 'type2',
                    occurredOn: '2023-01-01',
                    phoneNumber: '1234567890',
                    creditsEarned: 24,
                    facilityName: 'Facility 2',
                    rewardAmount: 100,
                    rewardEligibleAt: '2023-01-02',
                    rewardExpiration: '2023-12-31',
                    rewardPromotion: {
                        accountId: 'account2',
                        amount: 100,
                        endDate: '2023-12-31',
                        terms: 'terms1',
                        campaignId: 'campaign1',
                        promoId: 'promo1',
                        couponColor: 'red',
                        startDate: '2022-01-01',
                    },
                    rewardsGiven: 1,
                    redeemedForQuantity: 1,
                    redeemedForPoints: 10,
                    orderId: 'order1',
                    staffFirstName: 'Staff',
                    staffLastName: 'Member',
                    email: 'staff@company.com',
                    checkInId: 'checkin1',
                    facilityId: 'facility1',
                    accountId: 'account1',
                    injector: {
                        name: 'Pam Beasley',
                        uniqueId: 'KDV-001',
                        externalId: 'KB-001',
                    },
                },
            ],
        },
        accountById: {
            facilities: [
                {
                    name: 'Facility 1',
                    id: 'facility1',
                    hasSignedMedicalLiabilityAgreement: true,
                    shippingAddress: {
                        city: 'City 1',
                        state: 'State 1',
                    },
                    status: 'active',
                },
            ],
        },
        getInjectors: {
            externalLookupSuccess: true,
            injectors: [
                {
                    name: 'Pam Beasley',
                    uniqueId: 'KDV-001',
                    externalId: 'KB-001',
                },
            ],
        },
    },
};
export var facilityList = [
    'F-0000001-DEV',
    'F-1801133-DEV',
    'F-2738918-DEV',
    'F-3640319-DEV',
];
export var staffIdList = [
    'U-2605414-DEV',
    'U-1111111-DEV',
    'U-2222222-DEV',
];

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import ConsentNotGivenGraphic from 'assets/enroll-patient-graphic-4.svg';
import { Typography } from 'designSystem';
import { StepContainer } from '../../StepContainer';
import { useNavigate } from 'react-router';
import CheckInProgress from '../../CheckInProgress';
import { StepEnum } from '../../../Enums/StepEnum';
export var PatientConsentNotGiven = function (_a) {
    var handleRestart = _a.handleRestart;
    var navigate = useNavigate();
    return (React.createElement(React.Fragment, null,
        React.createElement(StepContainer, { handleRestart: handleRestart },
            React.createElement(OuterContainer, null,
                React.createElement(LeftContainer, null,
                    React.createElement(Image, { src: ConsentNotGivenGraphic })),
                React.createElement(RightContainer, null,
                    React.createElement(Typography, { variant: "titleLarge", color: "link" }, "Patient Has Not Agreed"),
                    React.createElement(Typography, { mt: 16, variant: "body", color: "alt" }, "The patient has not agreed, so they will not be able to participate in Evolus Rewards at this time.")))),
        React.createElement(CheckInProgress, { primaryButtonText: "Close", onContinue: function () { return navigate(StepEnum.PhoneInput); } })));
};
var OuterContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  border: 1px solid ", ";\n\n  padding: 32px;\n  @media screen and (max-width: ", "px) {\n    border: none;\n    padding: 0;\n    flex-direction: column;\n    margin-bottom: auto;\n  }\n"], ["\n  display: flex;\n  border: 1px solid ", ";\n\n  padding: 32px;\n  @media screen and (max-width: ", "px) {\n    border: none;\n    padding: 0;\n    flex-direction: column;\n    margin-bottom: auto;\n  }\n"])), function (props) { return props.theme.colors.border.base; }, function (props) { return props.theme.breakpoints.md; });
var LeftContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  justify-content: center;\n  align-items: center;\n  margin-right: 32px;\n  @media screen and (max-width: ", "px) {\n    border: none;\n    padding: 0;\n    flex-direction: column;\n    margin-right: 0;\n  }\n"], ["\n  display: flex;\n  flex: 1;\n  justify-content: center;\n  align-items: center;\n  margin-right: 32px;\n  @media screen and (max-width: ", "px) {\n    border: none;\n    padding: 0;\n    flex-direction: column;\n    margin-right: 0;\n  }\n"])), function (props) { return props.theme.breakpoints.md; });
var Image = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  max-width: 100%;\n  max-height: 100%;\n"], ["\n  max-width: 100%;\n  max-height: 100%;\n"])));
var RightContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex: 2;\n  flex-direction: column;\n  justify-content: center;\n"], ["\n  display: flex;\n  flex: 2;\n  flex-direction: column;\n  justify-content: center;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;

var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import View from './View';
import { mockTransactionHistoryResponse } from '../../../../../../context/mockData/data';
var RewardsHistoryTableContainer = function (_a) {
    var _b, _c, _d, _e;
    var selectedTransactionType = _a.selectedTransactionType, selectedRewardTypes = _a.selectedRewardTypes, dateRange = _a.dateRange, selectedInjectors = _a.selectedInjectors, selectedFacility = _a.selectedFacility, onSelectItem = _a.onSelectItem;
    var _f = __read(useState([]), 2), setActivityFilter = _f[1];
    var _g = __read(useState(0), 2), page = _g[0], setPage = _g[1];
    useEffect(function () {
        setActivityFilter(selectedTransactionType.map(function (type) { return type.value; }));
    }, [selectedTransactionType]);
    useEffect(function () {
        setPage(0);
    }, [
        selectedTransactionType,
        dateRange,
        selectedFacility,
        selectedInjectors,
        selectedRewardTypes,
    ]);
    var mockActivities = (_c = (_b = mockTransactionHistoryResponse === null || mockTransactionHistoryResponse === void 0 ? void 0 : mockTransactionHistoryResponse.data) === null || _b === void 0 ? void 0 : _b.getTransactionHistoryActivity) === null || _c === void 0 ? void 0 : _c.activity;
    var mockRecordCount = (_e = (_d = mockTransactionHistoryResponse === null || mockTransactionHistoryResponse === void 0 ? void 0 : mockTransactionHistoryResponse.data) === null || _d === void 0 ? void 0 : _d.getTransactionHistoryActivity) === null || _e === void 0 ? void 0 : _e.recordCount;
    return (React.createElement(View, { onSelectItem: onSelectItem, transactionHistory: mockActivities, isLoading: false, error: undefined, page: page, setPage: setPage, recordCount: mockRecordCount }));
};
export default RewardsHistoryTableContainer;

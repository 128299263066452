var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { Button, LoadingBubbles } from 'designSystem';
import styled from 'styled-components';
export var MobileContinueButton = function (_a) {
    var label = _a.label, onContinue = _a.onContinue, disabled = _a.disabled, loading = _a.loading;
    return (React.createElement(Wrapper, null,
        React.createElement(Button, { fullWidth: true, mt: 32, disabled: !!disabled, size: "xlarge", onClick: onContinue }, loading ? React.createElement(LoadingBubbles, null) : label)));
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: auto;\n  width: 100%;\n"], ["\n  margin-top: auto;\n  width: 100%;\n"])));
var templateObject_1;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var _a, _b;
import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { StepEnum } from '../Enums/StepEnum';
var steps = [
    {
        id: 'step-0',
        name: 'Enter Mobile Number',
    },
    {
        id: 'step-1',
        name: 'Patient Reward Status',
    },
    {
        id: 'step-2',
        name: 'Select Specialist',
    },
    {
        id: 'step-3',
        name: 'Check-In Agreement',
    },
    {
        id: 'step-4',
        name: 'Validate Magic Phrase',
    },
    {
        id: 'step-5',
        name: 'Complete Check-In',
    },
];
var stepsWhenNoReward = [
    {
        id: 'step-0',
        name: 'Enter Mobile Number',
    },
    {
        id: 'step-1',
        name: 'Patient Reward Status',
    },
];
var pathToStepWhenNotCheckingIn = (_a = {},
    _a[StepEnum.PhoneInput] = 0,
    _a[StepEnum.EnrollPatient] = 0,
    _a[StepEnum.EnrollPatientForm] = 0,
    _a[StepEnum.NumberNotSupported] = 0,
    _a[StepEnum.PatientConsent] = 0,
    _a[StepEnum.SendingMessage] = 0,
    _a[StepEnum.EnrollmentProgress] = 0,
    _a[StepEnum.EmailInput] = 0,
    _a[StepEnum.ConsentNotGiven] = 0,
    _a[StepEnum.RewardStatus] = 1,
    _a[StepEnum.RewardReminderSent] = 1,
    _a);
var pathToStepIndex = (_b = {},
    _b[StepEnum.PhoneInput] = 0,
    _b[StepEnum.EnrollPatient] = 0,
    _b[StepEnum.EnrollPatientForm] = 0,
    _b[StepEnum.NumberNotSupported] = 0,
    _b[StepEnum.PatientConsent] = 0,
    _b[StepEnum.SendingMessage] = 0,
    _b[StepEnum.EnrollmentProgress] = 0,
    _b[StepEnum.EmailInput] = 0,
    _b[StepEnum.ConsentNotGiven] = 0,
    _b[StepEnum.RewardStatus] = 1,
    _b[StepEnum.SpecialistSelect] = 2,
    _b[StepEnum.CheckInAgreement] = 3,
    _b[StepEnum.SendMagicPhrase] = 4,
    _b[StepEnum.ValidateMagicPhrase] = 4,
    _b[StepEnum.CheckInComplete] = 5,
    _b);
export var MobileProgressStepper = function () {
    var location = useLocation();
    var checkingIn = useMemo(function () {
        var yee = false;
        switch (location.search) {
            case StepEnum.SpecialistSelect:
            case StepEnum.CheckInAgreement:
            case StepEnum.SendMagicPhrase:
            case StepEnum.ValidateMagicPhrase:
            case StepEnum.CheckInComplete:
                yee = true;
        }
        return yee;
    }, [location]);
    var allSteps = !checkingIn ? stepsWhenNoReward : steps;
    var currentStep = !checkingIn
        ? pathToStepWhenNotCheckingIn[location.search]
        : pathToStepIndex[location.search];
    return (React.createElement(Container, null, allSteps.map(function (_s, i) { return (React.createElement(ProgressBubble, { completed: currentStep > i, current: i === currentStep })); })));
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 16px;\n  margin-top: 24px;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 16px;\n  margin-top: 24px;\n"])));
var ProgressBubble = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-radius: 4px;\n  height: 8px;\n  width: ", ";\n  background: ", ";\n  border: solid 1px ", ";\n"], ["\n  border-radius: 4px;\n  height: 8px;\n  width: ", ";\n  background: ", ";\n  border: solid 1px ", ";\n"])), function (props) { return (props.current ? '28px' : '8px'); }, function (props) {
    return props.completed || props.current
        ? props.theme.colors.coral.coral500
        : 'transparent';
}, function (props) { return props.theme.colors.coral.coral500; });
var templateObject_1, templateObject_2;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import CarrierNotSupportedGraphic from 'assets/check-in-graphic-7.svg';
import { Typography } from 'designSystem';
import styled from 'styled-components';
import React from 'react';
import CheckInProgress from '../../CheckInProgress';
import { useNavigate } from 'react-router';
import { StepContainer } from '../../StepContainer';
import { StepEnum } from '../../../Enums/StepEnum';
import { useScreenSizing } from 'hooks';
export var NumberNotSupported = function (_a) {
    var handleRestart = _a.handleRestart;
    var navigate = useNavigate();
    var isMobile = useScreenSizing().isMobile;
    return (React.createElement(React.Fragment, null,
        React.createElement(StepContainer, { handleRestart: handleRestart },
            React.createElement(Container, null,
                React.createElement(LeftContainer, null,
                    React.createElement(Image, { src: CarrierNotSupportedGraphic })),
                React.createElement(RightContainer, null,
                    React.createElement(Typography, { variant: "titleLarge", color: "link" }, "Patient's phone carrier not supported"),
                    React.createElement(BodyText, { variant: "body", color: "alt" }, "We are unable to complete the current check-in given the carrier of the patient is not supported. The patient will need to re-enroll in Evolus Rewards using a different phone number (with a supported carrier) in order to proceed."),
                    React.createElement(BodyText, { variant: "body", color: "alt" }, "Please contact customer support if you require further assistance.")))),
        React.createElement(CheckInProgress, { primaryButtonText: "Try a Different Number", onContinue: function () { return navigate(StepEnum.PhoneInput); }, tertiaryButtonText: isMobile && 'Restart Check In', tertiaryAction: handleRestart })));
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  border: 1px solid ", ";\n\n  padding: 32px;\n  @media screen and (max-width: ", "px) {\n    border: none;\n    padding: 0;\n    flex-direction: column;\n    margin-bottom: auto;\n  }\n"], ["\n  display: flex;\n  border: 1px solid ", ";\n\n  padding: 32px;\n  @media screen and (max-width: ", "px) {\n    border: none;\n    padding: 0;\n    flex-direction: column;\n    margin-bottom: auto;\n  }\n"])), function (props) { return props.theme.colors.border.base; }, function (props) { return props.theme.breakpoints.md; });
var LeftContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  justify-content: center;\n  align-items: center;\n  margin-right: 32px;\n  @media screen and (max-width: ", "px) {\n    border: none;\n    padding: 0;\n    flex-direction: column;\n    margin-right: 0;\n  }\n"], ["\n  display: flex;\n  flex: 1;\n  justify-content: center;\n  align-items: center;\n  margin-right: 32px;\n  @media screen and (max-width: ", "px) {\n    border: none;\n    padding: 0;\n    flex-direction: column;\n    margin-right: 0;\n  }\n"])), function (props) { return props.theme.breakpoints.md; });
var Image = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  max-width: 100%;\n  max-height: 100%;\n"], ["\n  max-width: 100%;\n  max-height: 100%;\n"])));
var RightContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex: 2;\n  flex-direction: column;\n  justify-content: center;\n"], ["\n  display: flex;\n  flex: 2;\n  flex-direction: column;\n  justify-content: center;\n"])));
var BodyText = styled(Typography)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-top: 16px;\n"], ["\n  margin-top: 16px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;

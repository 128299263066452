var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { Modal } from '../Modal';
import { Typography, Button } from 'designSystem';
import { useModal } from 'context';
import styled, { css } from 'styled-components';
import { useScreenSizing } from 'hooks';
import ByeGraphic from 'assets/bye.svg';
import { addMobileStyles } from '../../../utils/addMobileStyles';
export var RestartCheckIn = function (_a) {
    var onRestart = _a.onRestart, fromEnrollment = _a.fromEnrollment;
    var closeModal = useModal().closeModal;
    var isMobile = useScreenSizing().isMobile;
    var modalTextVariant = fromEnrollment ? 'Enrollment' : 'Check-In';
    return (React.createElement(Modal, { size: "medium" },
        React.createElement(Container, null,
            React.createElement("div", null,
                React.createElement(Typography, { align: isMobile ? 'center' : 'left', variant: "display" },
                    "Restart ",
                    modalTextVariant),
                React.createElement(Typography, { align: isMobile ? 'center' : 'left', display: "block", mt: 32, mb: 60, color: "alt" },
                    "Are you sure you want to restart",
                    ' ',
                    fromEnrollment ? 'the enrollment' : 'checking in',
                    "? All information will be reset and you will be sent to the beginning."),
                React.createElement(ActionsContainer, null,
                    React.createElement(Button, { fullWidth: isMobile, size: "xlarge", onClick: function () { return closeModal(); }, variant: isMobile ? 'tertiary' : 'outlined' },
                        "No, Continue ",
                        modalTextVariant),
                    React.createElement(Button, { fullWidth: isMobile, size: "xlarge", onClick: function () {
                            onRestart();
                            closeModal();
                        }, variant: "primary" },
                        "Restart ",
                        modalTextVariant))),
            React.createElement(Image, { src: ByeGraphic, alt: "bye-graphic" }))));
};
var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 12px;\n  ", "\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 12px;\n  ", "\n"])), addMobileStyles(css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    flex-direction: column-reverse;\n    justify-content: flex-end;\n    gap: 40px;\n    flex: 1;\n    & > div {\n      height: 100%;\n      display: flex;\n      flex-direction: column;\n      flex: 1;\n    }\n  "], ["\n    flex-direction: column-reverse;\n    justify-content: flex-end;\n    gap: 40px;\n    flex: 1;\n    & > div {\n      height: 100%;\n      display: flex;\n      flex-direction: column;\n      flex: 1;\n    }\n  "])))));
var ActionsContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-start;\n  width: 100%;\n  gap: 12px;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column-reverse;\n    margin-top: auto;\n  }\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-start;\n  width: 100%;\n  gap: 12px;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column-reverse;\n    margin-top: auto;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var Image = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  max-width: 280px;\n"], ["\n  width: 100%;\n  max-width: 280px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;

import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { TabNav } from '../../components/TabNav';
export var Offers = function () {
    var tabs = useMemo(function () { return [
        {
            label: 'Offers Dashboard',
            path: '/offers/dashboard',
        },
    ]; }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(TabNav, { tabs: tabs }),
        React.createElement(Outlet, null)));
};

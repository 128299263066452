var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { Modal } from '../Modal';
import { Typography, Button } from 'designSystem';
import { useModal } from '../../../context';
import styled from 'styled-components';
import { useScreenSizing } from '../../../hooks';
export var RestartOrder = function (_a) {
    var onRestart = _a.onRestart;
    var closeModal = useModal().closeModal;
    var isMobile = useScreenSizing().isMobile;
    return (React.createElement(Modal, null,
        React.createElement(Typography, { variant: "display" }, "Restarting Order"),
        React.createElement(Typography, { display: "block", mt: 32, mb: 60, color: "alt" }, "Are you sure you want to restart your order? All information will be reset and you will be sent to the beginning."),
        React.createElement(ActionsContainer, null,
            React.createElement(Button, { fullWidth: isMobile, size: "xlarge", onClick: function () { return closeModal(); }, variant: isMobile ? 'tertiary' : 'outlined' }, "Continue Order"),
            React.createElement(Button, { fullWidth: isMobile, size: "xlarge", onClick: function () {
                    onRestart();
                    closeModal();
                }, variant: "primary" }, "Restart Order"))));
};
var ActionsContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-end;\n  width: 100%;\n  gap: 12px;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column-reverse;\n    margin-top: auto;\n  }\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-end;\n  width: 100%;\n  gap: 12px;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column-reverse;\n    margin-top: auto;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var templateObject_1;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Icon, Typography } from 'designSystem';
import { UserRole } from 'types/codegen/hooks';
import { useNavigate } from 'react-router';
export var UserMobileCard = function (_a) {
    var firstName = _a.firstName, lastName = _a.lastName, email = _a.email, id = _a.id, isMainContact = _a.isMainContact, role = _a.role;
    var theme = useTheme();
    var navigate = useNavigate();
    return (React.createElement(CardContainer, { onClick: function () { return navigate("/settings/user-permissions/".concat(id)); } },
        React.createElement(Icon, { name: "user", color: theme.colors.text.alt }),
        React.createElement(NameContainer, null,
            React.createElement(NameTypography, { variant: "headlineSmall" }, firstName ? "".concat(firstName, " ").concat(lastName) : email),
            React.createElement(Typography, null,
                React.createElement(Typography, { color: "alt", variant: "footnote" }, role === UserRole.AccountAdmin ? 'Admin Access' : 'Limited Access'),
                isMainContact && (React.createElement(Typography, { variant: "footnote", color: "link" },
                    ' ',
                    "\u2022 Primary Contact")))),
        React.createElement(Icon, { color: theme.colors.text.link, name: "chevron-right" })));
};
var CardContainer = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  ", ";\n  background: ", ";\n\n  padding: 12px 20px;\n  gap: 12px;\n"], ["\n  display: flex;\n  align-items: center;\n  ", ";\n  background: ", ";\n\n  padding: 12px 20px;\n  gap: 12px;\n"])), function (props) { return props.theme.borders.base; }, function (props) { return props.theme.colors.bg.list; });
var NameContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  min-width: 0;\n"], ["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  min-width: 0;\n"])));
var NameTypography = styled(Typography)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  overflow: hidden;\n  text-overflow: ellipsis;\n"], ["\n  overflow: hidden;\n  text-overflow: ellipsis;\n"])));
var templateObject_1, templateObject_2, templateObject_3;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useMemo } from 'react';
import styled, { createGlobalStyle, useTheme } from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUser } from 'context/UserContext';
import { useModal } from 'context/ModalContext';
import { useQuery } from '@apollo/client';
import accountQuery from './../accountInfo.graphql';
import { Icon, Button, Menu, MenuDivider, MenuItem, MenuItemIcon, MenuItemText, } from 'designSystem';
import ModalTypeEnum from 'types/enums/ModalTypeEnum';
import LogoTypeImage from 'assets/evolus-logo-coral.svg';
import { useOpenManageProfileLink } from '../../../hooks/useOpenManageProfileLink';
import pkg from '../../../../package.json';
var version = pkg.version;
export var MobileNavigation = function (_a) {
    var _b, _c;
    var onClose = _a.onClose, open = _a.open;
    var _d = useUser(), user = _d.user, account = _d.account, isAdmin = _d.isAdmin, signOut = _d.signOut;
    var navigate = useNavigate();
    var location = useLocation();
    var openModal = useModal().openModal;
    var theme = useTheme();
    var isLoading = useOpenManageProfileLink(account === null || account === void 0 ? void 0 : account.id).isLoading;
    var _e = useQuery(accountQuery, {
        variables: {
            accountId: account === null || account === void 0 ? void 0 : account.id,
        },
    }), loadingAccount = _e.loading, errorAccount = _e.error, dataAccount = _e.data;
    var hasPastDue = ((_c = (_b = dataAccount === null || dataAccount === void 0 ? void 0 : dataAccount.getOpenInvoicesByAccount) === null || _b === void 0 ? void 0 : _b.invoices) === null || _c === void 0 ? void 0 : _c.filter(function (invoice) { return invoice === null || invoice === void 0 ? void 0 : invoice.isPastDue; }).length) > 0;
    var menu = useMemo(function () {
        var _a;
        return [
            {
                type: 'item',
                icon: 'home',
                path: 'home',
                text: 'Home',
                onClick: function () { return navigate('/home'); },
                hidden: !isAdmin,
            },
            {
                type: 'item',
                icon: 'rewards',
                path: 'rewards',
                text: 'Rewards',
                onClick: function () { return navigate('/rewards'); },
            },
            {
                type: 'item',
                icon: 'orders',
                path: 'orders',
                text: 'Orders',
                onClick: function () { return navigate('/orders'); },
                hidden: !isAdmin,
            },
            {
                type: 'item',
                icon: 'discount',
                path: 'offers',
                text: 'My Offers',
                onClick: function () { return navigate('/offers/dashboard'); },
                hidden: !isAdmin,
            },
            {
                type: 'item',
                icon: 'give-card',
                path: 'invoices',
                text: 'Pay Invoice',
                onClick: function () { return navigate('/invoices/dashboard'); },
                hidden: !(account === null || account === void 0 ? void 0 : account.hasEvolusCredit) || !isAdmin,
                pastDue: hasPastDue && !loadingAccount && !errorAccount,
            },
            {
                type: 'divider',
            },
            {
                type: 'item',
                icon: 'user',
                path: 'profile-settings',
                text: 'User Profile Settings',
                onClick: function () { return navigate('/profile-settings'); },
            },
            {
                type: 'item',
                icon: 'settings',
                path: 'settings',
                text: 'Account Settings',
                onClick: function () { return navigate('/settings'); },
                hidden: !isAdmin,
            },
            {
                type: 'item',
                icon: 'switch',
                path: 'switch',
                text: 'Switch account',
                hidden: ((_a = user === null || user === void 0 ? void 0 : user.accounts) === null || _a === void 0 ? void 0 : _a.length) === 1,
                onClick: function () {
                    openModal(ModalTypeEnum.ACCOUNT_SELECTION);
                },
            },
            {
                type: 'item',
                icon: 'intercom',
                path: 'intercom',
                text: 'Support Chat',
                onClick: function () {
                },
            },
            {
                type: 'divider',
            },
            {
                type: 'item',
                icon: 'logout',
                path: 'logout',
                text: 'Log out',
                onClick: function () {
                    if (location.pathname.includes('rewards/check-in') &&
                        !location.search.includes('mobileNumberInput')) {
                        openModal(ModalTypeEnum.LEAVING_CHECK_IN, {
                            trackCancelled: function (callback) {
                                signOut();
                                callback();
                            },
                            path: location.search,
                        });
                        return;
                    }
                    signOut();
                },
            },
        ];
    }, [user, isAdmin, location, isLoading, account, dataAccount]);
    var renderMenuItem = function (entry, key) {
        if (entry.type === 'divider')
            return React.createElement(MenuDivider, { key: key });
        if (entry.type === 'item' && !entry.hidden) {
            var isActivePath = location.pathname.includes("/".concat(entry.path));
            var color = isActivePath
                ? theme.colors.text.link
                : theme.colors.text.base;
            return (React.createElement(MenuItem, { px: 0, py: 20, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4, entry.onClick()];
                            case 1:
                                _a.sent();
                                onClose();
                                return [2];
                        }
                    });
                }); }, key: key, "data-automation-id": "navigation-".concat(entry.path) },
                React.createElement(MenuItemIcon, { size: 18, name: entry.icon, color: color }),
                (entry === null || entry === void 0 ? void 0 : entry.pastDue) && (React.createElement(PastDue, null,
                    React.createElement(Icon, { name: 'alert-red', size: 16 }))),
                React.createElement(StyledMenuItemText, { active: isActivePath }, entry.text)));
        }
        return null;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(GlobalStyle, { open: open }),
        React.createElement(Container, { open: open },
            React.createElement(Backdrop, null),
            React.createElement(Root, null,
                React.createElement(Button, { onClick: function () { return onClose(); }, variant: "tertiary", noPadding: true, "data-automation-id": 'navigation-close' },
                    React.createElement(Icon, { name: "close", color: theme.colors.coral.coral500, size: 24 })),
                React.createElement(Menu, null,
                    menu.map(function (entry, idx) { return renderMenuItem(entry, idx); }),
                    React.createElement(VersionContainer, null,
                        React.createElement(VersionText, null,
                            "Version ",
                            version))),
                React.createElement(LogotypeWrapper, null,
                    React.createElement(Logotype, null))))));
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  transition: transform ease-in-out 333ms;\n  height: 100vh;\n  top: 0;\n  left: 0;\n  z-index: 9800;\n  position: absolute;\n  ", "\n"], ["\n  transition: transform ease-in-out 333ms;\n  height: 100vh;\n  top: 0;\n  left: 0;\n  z-index: 9800;\n  position: absolute;\n  ", "\n"])), function (props) {
    return props.open
        ? 'transform: translateX(50%);'
        : 'transform: translateX(-100vw);';
});
var GlobalStyle = createGlobalStyle(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  body {\n    overflow: ", ";\n  }\n"], ["\n  body {\n    overflow: ", ";\n  }\n"])), function (props) { return (props.open ? 'hidden !important' : 'auto'); });
var Backdrop = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: fixed;\n  height: 100vh;\n  width: 100vw;\n\n  background: ", ";\n  transition: background-color 0.5s ease;\n"], ["\n  position: fixed;\n  height: 100vh;\n  width: 100vw;\n\n  background: ", ";\n  transition: background-color 0.5s ease;\n"])), function (props) { return props.theme.colors.selectable.active; });
var Root = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: fixed;\n  z-index: 9801;\n  padding: 32px;\n\n  height: 100%;\n  width: 288px;\n\n  background: ", ";\n\n  overflow-y: scroll;\n"], ["\n  position: fixed;\n  z-index: 9801;\n  padding: 32px;\n\n  height: 100%;\n  width: 288px;\n\n  background: ", ";\n\n  overflow-y: scroll;\n"])), function (props) { return props.theme.colors.basic.white; });
var LogotypeWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])));
var Logotype = styled.img.attrs({
    src: LogoTypeImage,
    alt: 'evolus-logo',
})(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  height: 32px;\n"], ["\n  height: 32px;\n"])));
var StyledMenuItemText = styled(MenuItemText)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: ", ";\n"], ["\n  color: ", ";\n"])), function (props) {
    return props.active ? props.theme.colors.text.link : props.theme.colors.text.alt;
});
var VersionContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  padding-bottom: 12px;\n"], ["\n  padding-bottom: 12px;\n"])));
var VersionText = styled.span(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  font-size: 16px;\n  color: ", ";\n"], ["\n  font-size: 16px;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text.alt4;
});
var PastDue = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  position: relative;\n  right: 16px;\n  top: 6px;\n  z-index: 20;\n"], ["\n  position: relative;\n  right: 16px;\n  top: 6px;\n  z-index: 20;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;

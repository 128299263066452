var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import { PlaceholderLoading, Typography } from 'designSystem';
import { useAllowAdminOnly, useScreenSizing } from 'hooks';
export var EvoluxView = function () {
    useAllowAdminOnly();
    var isMobile = useScreenSizing().isMobile;
    var _a = __read(useState(true), 2), isLoading = _a[0], setIsLoading = _a[1];
    return (React.createElement(Container, { isLoading: isLoading },
        React.createElement(Typography, { variant: "titleLarge", align: isMobile ? 'center' : 'left' }, "Media Asset Library"),
        isLoading && (React.createElement(LoadingContainer, null,
            React.createElement(PlaceholderLoading, null))),
        React.createElement(Iframe, { onLoad: function () { return setIsLoading(false); }, hidden: isLoading, src: "#", title: "Media Kit" })));
};
export default EvoluxView;
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 0px 16px 16px;\n  display: flex;\n  flex-direction: column;\n\n  @media only screen and (max-width: ", "px) {\n    margin: 0;\n  }\n"], ["\n  margin: 0px 16px 16px;\n  display: flex;\n  flex-direction: column;\n\n  @media only screen and (max-width: ", "px) {\n    margin: 0;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var LoadingContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  height: 100%;\n  ", ";\n  margin: 16px 0;\n  justify-content: center;\n  align-items: center;\n  min-height: 70vh;\n"], ["\n  display: flex;\n  width: 100%;\n  height: 100%;\n  ", ";\n  margin: 16px 0;\n  justify-content: center;\n  align-items: center;\n  min-height: 70vh;\n"])), function (props) { return props.theme.card.default; });
var Iframe = styled.iframe(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border: none;\n  margin-top: 12px;\n  height: calc(100vh - 254px);\n\n  @media only screen and (max-width: ", "px) {\n    height: calc(100vh - 132px);\n  }\n"], ["\n  border: none;\n  margin-top: 12px;\n  height: calc(100vh - 254px);\n\n  @media only screen and (max-width: ", "px) {\n    height: calc(100vh - 132px);\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var templateObject_1, templateObject_2, templateObject_3;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useMemo } from 'react';
import { RadioButton, Desktop, Mobile, Typography, Icon, Button, } from 'designSystem';
import styled, { css } from 'styled-components';
import { currencyFormatter } from 'utils/currencyFormatter';
import amexIcon from 'assets/amex-icon.png';
import discoverIcon from 'assets/discover-icon.png';
import visaIcon from 'assets/visa-icon.png';
import mastercardIcon from 'assets/mastercard-icon.png';
import bankIcon from 'assets/bank-account-icon.svg';
import creditIcon from 'assets/evolus-credit-icon.svg';
import { SensitivePaymentType } from 'types/types';
import { MenuButton } from '../MenuButton';
import { useState } from 'react';
import { PaymentMethodType, getSelectedPaymentMethod, } from 'utils/getSelectedPaymentMethod';
import CurrencyInput from 'react-currency-input-field';
import { useSessionStorage } from 'hooks';
export var PaymentMethodItem = function (_a) {
    var paymentMethod = _a.paymentMethod, selectedPaymentMethod = _a.selectedPaymentMethod, onSelect = _a.onSelect, selectable = _a.selectable, editable = _a.editable, onEdit = _a.onEdit, onRemove = _a.onRemove, isValidAmount = _a.isValidAmount, hasEvolusCredit = _a.hasEvolusCredit, amountValiation = _a.amountValiation, fromSelectPayment = _a.fromSelectPayment, totalAmount = _a.totalAmount, calculatedPricingLoading = _a.calculatedPricingLoading, isPromoValid = _a.isPromoValid;
    var _b = __read(useSessionStorage('@new_order', {}), 1), orderValue = _b[0];
    var _c = __read(useState(), 2), amountHistory = _c[0], setAmountHistory = _c[1];
    var _d = __read(useState(''), 2), amountToBeCredited = _d[0], setAmountToBeCredited = _d[1];
    useEffect(function () {
        if (totalAmount &&
            hasEvolusCredit &&
            onSelect &&
            (primarySelectedPaymentMethod === null || primarySelectedPaymentMethod === void 0 ? void 0 : primarySelectedPaymentMethod.paymentMethodId) ===
                paymentMethod.paymentMethodId &&
            (paymentMethod === null || paymentMethod === void 0 ? void 0 : paymentMethod.sensitive.type) !== SensitivePaymentType.Loc) {
            var amount = totalAmount.toFixed(2).toString();
            if (amountToBeCredited !== amount) {
                setAmountToBeCredited(amount);
                setAmountHistory(totalAmount);
                onSelect(__assign(__assign({}, paymentMethod), { amount: totalAmount }));
            }
        }
    }, [isPromoValid]);
    useEffect(function () {
        var _a;
        if (orderValue.paymentMethod && totalAmount && onSelect) {
            var val = (_a = orderValue.paymentMethod[0]) === null || _a === void 0 ? void 0 : _a.amount;
            if (val) {
                setAmountToBeCredited(val.toFixed(2).toString());
                setAmountHistory(val);
                onSelect(__assign(__assign({}, orderValue.paymentMethod[0]), { amount: val }));
            }
        }
    }, [orderValue.paymentMethod]);
    var primarySelectedPaymentMethod = getSelectedPaymentMethod(selectedPaymentMethod, PaymentMethodType.primary);
    var secondarySelectedPaymentMethod = getSelectedPaymentMethod(selectedPaymentMethod, PaymentMethodType.secondary);
    var creditCardIcons = {
        '4': visaIcon,
        '3': amexIcon,
        '5': mastercardIcon,
        '6': discoverIcon,
    };
    useEffect(function () {
        if (hasEvolusCredit &&
            !amountToBeCredited &&
            totalAmount &&
            onSelect &&
            (primarySelectedPaymentMethod === null || primarySelectedPaymentMethod === void 0 ? void 0 : primarySelectedPaymentMethod.paymentMethodId) ===
                paymentMethod.paymentMethodId &&
            (paymentMethod === null || paymentMethod === void 0 ? void 0 : paymentMethod.sensitive.type) !== SensitivePaymentType.Loc) {
            setAmountToBeCredited(totalAmount.toFixed(2).toString());
            setAmountHistory(totalAmount);
            onSelect(__assign(__assign({}, paymentMethod), { amount: totalAmount }));
        }
    }, [totalAmount]);
    var formatCCNumber = function (number) {
        var securityFormat = number.replace(/.(?=.{4,}$)/g, '*');
        if (number[0] === '3') {
            return securityFormat.match(/.{1,5}/g).join(' ');
        }
        else {
            return securityFormat.match(/.{1,4}/g).join(' ');
        }
    };
    var creditsUtilized = useMemo(function () {
        return secondarySelectedPaymentMethod === null || secondarySelectedPaymentMethod === void 0 ? void 0 : secondarySelectedPaymentMethod.amount;
    }, [secondarySelectedPaymentMethod]);
    var renderRightSide = function () {
        var _a;
        var _b = (_a = paymentMethod === null || paymentMethod === void 0 ? void 0 : paymentMethod.sensitive) !== null && _a !== void 0 ? _a : {}, type = _b.type, cc = _b.cc, ach = _b.ach, loc = _b.loc;
        if (type === SensitivePaymentType.Cc) {
            return (React.createElement(React.Fragment, null,
                React.createElement(PaymentMethodNumber, { variant: "body", color: "alt" }, formatCCNumber(cc.number)),
                React.createElement(PaymentMethodNumber, { variant: "body", color: "alt" }, "".concat(cc.expMonth, "/").concat(cc.expYear))));
        }
        else if (type === SensitivePaymentType.Ach) {
            return (React.createElement(PaymentMethodNumber, { variant: "body", color: "alt" }, ach.accountNumber.replace(/.(?=.{4,}$)/g, '*')));
        }
        else {
            return (React.createElement(PaymentMethodNumber, { variant: "body", color: "alt" }, "Available Credit: ".concat(currencyFormatter(loc.limit - loc.balance, 2))));
        }
    };
    var renderPaymentMethodIcon = function () {
        var _a;
        var _b = (_a = paymentMethod === null || paymentMethod === void 0 ? void 0 : paymentMethod.sensitive) !== null && _a !== void 0 ? _a : {}, type = _b.type, cc = _b.cc;
        if (type === SensitivePaymentType.Cc) {
            return React.createElement(PaymentMethodImage, { src: creditCardIcons[cc.number[0]] });
        }
        if (type === SensitivePaymentType.Ach) {
            return React.createElement(PaymentMethodImage, { src: bankIcon });
        }
        else {
            return React.createElement(PaymentMethodImage, { src: creditIcon });
        }
    };
    if (!paymentMethod) {
        return null;
    }
    var updateAmount = function (value) {
        if (amountHistory !== value) {
            onSelect &&
                onSelect(__assign(__assign({}, paymentMethod), { amount: formatAmountStringToNumber(value) }));
            setAmountHistory(value);
        }
    };
    var formatAmountStringToNumber = function (value) {
        return Number(value.replace(/[$,]/g, ''));
    };
    var updatedAmount = function (value) {
        setAmountToBeCredited(value);
    };
    var renderSplitAmountInput = function () {
        if ((primarySelectedPaymentMethod === null || primarySelectedPaymentMethod === void 0 ? void 0 : primarySelectedPaymentMethod.paymentMethodId) ===
            paymentMethod.paymentMethodId &&
            (paymentMethod === null || paymentMethod === void 0 ? void 0 : paymentMethod.sensitive.type) !== SensitivePaymentType.Loc &&
            hasEvolusCredit &&
            !fromSelectPayment) {
            return (React.createElement(React.Fragment, null,
                React.createElement(AmountInputContainer, null,
                    React.createElement(EnterAmountContainer, null, "Please input amount to be billed:"),
                    React.createElement(AmountInput, { name: "currencyInput", prefix: "$", decimalsLimit: 2, decimalScale: 2, placeholder: "$0.00", onValueChange: updatedAmount, value: amountToBeCredited, disabled: calculatedPricingLoading, onBlur: function (event) { return updateAmount(event.target.value); } })),
                (isValidAmount === false || !amountToBeCredited) && (React.createElement(AmountValidation, null, amountValiation))));
        }
    };
    var renderAmountTobeCredit = function () {
        if (paymentMethod.sensitive.type === SensitivePaymentType.Loc &&
            (primarySelectedPaymentMethod === null || primarySelectedPaymentMethod === void 0 ? void 0 : primarySelectedPaymentMethod.paymentMethodId) !==
                paymentMethod.paymentMethodId &&
            creditsUtilized) {
            return (React.createElement(AmountInputContainer, null,
                React.createElement(EnterAmountContainer, null, "Amount to be billed:"),
                React.createElement(EnterAmountContainer, null, currencyFormatter(creditsUtilized, 2))));
        }
    };
    var handlePaymentSelection = function () {
        if (paymentMethod.paymentMethodId !==
            (primarySelectedPaymentMethod === null || primarySelectedPaymentMethod === void 0 ? void 0 : primarySelectedPaymentMethod.paymentMethodId)) {
            if (onSelect) {
                onSelect(__assign({}, paymentMethod));
            }
            setAmountToBeCredited('');
        }
    };
    var pMethodCheckedHandler = function (primarySelectedPaymentMethod, paymentMethod) {
        var _a;
        var pMethod = orderValue.paymentMethod;
        if (Array.isArray(pMethod) &&
            pMethod.length >= 1 &&
            pMethod[0].paymentMethodId === (paymentMethod === null || paymentMethod === void 0 ? void 0 : paymentMethod.paymentMethodId) &&
            (primarySelectedPaymentMethod === null || primarySelectedPaymentMethod === void 0 ? void 0 : primarySelectedPaymentMethod.paymentMethodId) ===
                ((_a = pMethod[0]) === null || _a === void 0 ? void 0 : _a.paymentMethodId)) {
            return true;
        }
        return ((primarySelectedPaymentMethod === null || primarySelectedPaymentMethod === void 0 ? void 0 : primarySelectedPaymentMethod.paymentMethodId) ===
            (paymentMethod === null || paymentMethod === void 0 ? void 0 : paymentMethod.paymentMethodId));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(PaymentMethodItemContainer, { onClick: handlePaymentSelection, selected: (primarySelectedPaymentMethod === null || primarySelectedPaymentMethod === void 0 ? void 0 : primarySelectedPaymentMethod.paymentMethodId) ===
                paymentMethod.paymentMethodId, "data-automation-id": "payment-method-".concat(paymentMethod.sensitive.type), "data-type": paymentMethod.sensitive.type, "data-selected": (primarySelectedPaymentMethod === null || primarySelectedPaymentMethod === void 0 ? void 0 : primarySelectedPaymentMethod.paymentMethodId) ===
                paymentMethod.paymentMethodId },
            React.createElement(PaymentMethodNameContainer, null,
                selectable && (React.createElement(RadioButton, { checked: pMethodCheckedHandler(primarySelectedPaymentMethod, paymentMethod), onChange: function () { } })),
                React.createElement(PaymentMethodNameText, null, paymentMethod.name),
                React.createElement(Mobile, null, renderRightSide())),
            React.createElement(PaymentMethodInfoContainer, null,
                React.createElement(Desktop, null, renderRightSide()),
                renderPaymentMethodIcon(),
                editable && (React.createElement(MenuButton, { menuItems: [
                        React.createElement(MenuItemButton, { fullWidth: true, variant: "quaternary", onClick: function () { return onEdit(paymentMethod); } }, "Edit Payment Name"),
                        React.createElement(MenuItemButton, { fullWidth: true, variant: "quaternary", onClick: function () { return onRemove(paymentMethod.paymentMethodId); } }, "Remove"),
                    ] },
                    React.createElement(Icon, { name: "kebab" }))))),
        renderSplitAmountInput(),
        renderAmountTobeCredit()));
};
var MenuItemButton = styled(Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 16px;\n  & > span {\n    width: 100%;\n    text-align: left;\n    font-weight: 400;\n  }\n"], ["\n  padding: 16px;\n  & > span {\n    width: 100%;\n    text-align: left;\n    font-weight: 400;\n  }\n"])));
var PaymentMethodItemContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  cursor: pointer;\n  padding: 16px 24px;\n  border: 1px solid\n    ", ";\n  display: flex;\n  width: 100%;\n  margin-top: 8px;\n  @media only screen and (max-width: ", "px) {\n    ", "\n    padding: 16px 20px;\n  }\n"], ["\n  cursor: pointer;\n  padding: 16px 24px;\n  border: 1px solid\n    ", ";\n  display: flex;\n  width: 100%;\n  margin-top: 8px;\n  @media only screen and (max-width: ", "px) {\n    ", "\n    padding: 16px 20px;\n  }\n"])), function (props) {
    return props.selected
        ? props.theme.colors.text.link
        : props.theme.colors.border.base;
}, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
}, function (props) {
    return props.selected && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        border-color: ", ";\n        border-width: 2px;\n      "], ["\n        border-color: ", ";\n        border-width: 2px;\n      "])), props.theme.colors.border.error2);
});
var PaymentMethodNameText = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 1.125rem;\n  font-weight: 500;\n\n  @media only screen and (max-width: ", "px) {\n    &:first-child {\n      margin-bottom: 8px;\n    }\n  }\n"], ["\n  font-size: 1.125rem;\n  font-weight: 500;\n\n  @media only screen and (max-width: ", "px) {\n    &:first-child {\n      margin-bottom: 8px;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var PaymentMethodNumber = styled(Typography)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-left: 40px;\n  @media only screen and (max-width: ", "px) {\n    margin: 0;\n  }\n"], ["\n  margin-left: 40px;\n  @media only screen and (max-width: ", "px) {\n    margin: 0;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var PaymentMethodImage = styled.img(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 48px;\n  margin-left: 40px;\n  @media only screen and (max-width: ", "px) {\n    align-items: flex-end;\n    justify-self: center;\n    flex-direction: column-reverse;\n    margin-left: 0;\n  }\n"], ["\n  width: 48px;\n  margin-left: 40px;\n  @media only screen and (max-width: ", "px) {\n    align-items: flex-end;\n    justify-self: center;\n    flex-direction: column-reverse;\n    margin-left: 0;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var PaymentMethodNameContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  align-items: center;\n\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n    align-items: flex-start;\n    flex: 1;\n  }\n"], ["\n  display: flex;\n  flex: 1;\n  align-items: center;\n\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n    align-items: flex-start;\n    flex: 1;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var PaymentMethodInfoContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  flex: 2;\n  justify-content: flex-end;\n  align-items: center;\n\n  @media only screen and (max-width: ", "px) {\n    align-items: flex-end;\n    flex-direction: column-reverse;\n    flex: 1;\n    gap: 32px;\n  }\n"], ["\n  display: flex;\n  flex: 2;\n  justify-content: flex-end;\n  align-items: center;\n\n  @media only screen and (max-width: ", "px) {\n    align-items: flex-end;\n    flex-direction: column-reverse;\n    flex: 1;\n    gap: 32px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var AmountInputContainer = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  padding-top: 0.5rem;\n  flex: 1;\n  gap: 8px;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n    margin-bottom: 16px;\n    align-items: start;\n  }\n"], ["\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  padding-top: 0.5rem;\n  flex: 1;\n  gap: 8px;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n    margin-bottom: 16px;\n    align-items: start;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var EnterAmountContainer = styled.span(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  font-size: 1rem;\n  color: ", ";\n  font-weight: 500;\n"], ["\n  font-size: 1rem;\n  color: ", ";\n  font-weight: 500;\n"])), function (props) { return props.theme.colors.text.link; });
var AmountInput = styled(CurrencyInput)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  width: 149px;\n  height: 48px;\n  padding-left: 16px;\n  outline: none;\n  border: 1px solid #f3f6f6;\n  font-size: 1rem;\n  padding-left: 1rem;\n  border-color: ", ";\n  @media only screen and (max-width: ", "px) {\n    ", "\n  }\n"], ["\n  width: 149px;\n  height: 48px;\n  padding-left: 16px;\n  outline: none;\n  border: 1px solid #f3f6f6;\n  font-size: 1rem;\n  padding-left: 1rem;\n  border-color: ", ";\n  @media only screen and (max-width: ", "px) {\n    ", "\n  }\n"])), function (props) { return props.theme.colors.text.link; }, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
}, function (props) {
    return css(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n        border-color: ", ";\n        border-width: 1px;\n        width: 100%;\n      "], ["\n        border-color: ", ";\n        border-width: 1px;\n        width: 100%;\n      "])), props.theme.colors.border.error2);
});
var AmountValidation = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  display: flex;\n  justify-content: end;\n  color: ", ";\n  padding-top: 2px;\n  @media only screen and (max-width: ", "px) {\n    justify-content: start;\n  }\n"], ["\n  display: flex;\n  justify-content: end;\n  color: ", ";\n  padding-top: 2px;\n  @media only screen and (max-width: ", "px) {\n    justify-content: start;\n  }\n"])), function (props) { return props.theme.colors.text.alert; }, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13;

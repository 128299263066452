var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { Typography } from 'designSystem';
import ErrorCircle from 'assets/error-circle.png';
import SuccessCircle from 'assets/success-circle.png';
export var AgreementCard = function (_a) {
    var signed = _a.signed, label = _a.label;
    var tagLabel = signed ? 'Complete' : 'Required';
    return (React.createElement(ItemCard, null,
        React.createElement("div", null,
            React.createElement(Image, { src: signed ? SuccessCircle : ErrorCircle, alt: signed ? 'success-icon' : 'error-icon' }),
            React.createElement(Typography, null, label)),
        React.createElement(Tag, { signed: signed },
            React.createElement(Typography, { variant: "caption", color: "alt3" }, tagLabel))));
};
var ItemCard = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  background: ", ";\n\n  ", ";\n  padding: 16px 24px;\n  & > div:first-of-type {\n    flex: 1;\n    display: flex;\n    align-items: center;\n    gap: 16px;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  background: ", ";\n\n  ", ";\n  padding: 16px 24px;\n  & > div:first-of-type {\n    flex: 1;\n    display: flex;\n    align-items: center;\n    gap: 16px;\n  }\n"])), function (props) { return props.theme.colors.bg.list; }, function (props) { return props.theme.borders.base; });
var Tag = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background: ", ";\n  padding: 8px 12px;\n"], ["\n  background: ", ";\n  padding: 8px 12px;\n"])), function (props) {
    return props.signed
        ? props.theme.colors.aqua.aqua500
        : props.theme.colors.coral.coral500;
});
var Image = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  max-width: 24px;\n  width: 100%;\n"], ["\n  max-width: 24px;\n  width: 100%;\n"])));
var templateObject_1, templateObject_2, templateObject_3;

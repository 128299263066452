var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Desktop, Mobile, Typography } from 'designSystem';
import { LoadingBubbles } from 'designSystem/components/LoadingBubbles';
import React from 'react';
import styled from 'styled-components';
import { stackedCardStyles } from '../../RewardsDashboard/components/StackedMobileCard';
var TransactionInformationView = function (_a) {
    var errors = _a.errors, isLoading = _a.isLoading, transactionSummary = _a.transactionSummary, _b = _a.singleLine, singleLine = _b === void 0 ? false : _b;
    var renderDataItem = function (dataLabel, value, valueColor) {
        return (React.createElement(React.Fragment, null,
            React.createElement(DataItemHeaderText, { style: { textAlign: 'center', marginBottom: '20px' }, variant: "headline", color: "alt" }, dataLabel),
            isLoading ? (React.createElement(LoadingBubbles, null)) : (React.createElement(Typography, { variant: "titleLarge", color: valueColor }, errors ? 'Unavailable' : value))));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Desktop, null,
            React.createElement(DataContainer, null, singleLine ? (React.createElement(React.Fragment, null,
                React.createElement(DataItemRow, null,
                    React.createElement(RewardDataItem, null, renderDataItem('Total Reward Vials Available', transactionSummary === null || transactionSummary === void 0 ? void 0 : transactionSummary.totalFreeVialsAvailable, 'success')),
                    React.createElement(RewardDataItem, null, renderDataItem('Total Reward Vials Redeemed', transactionSummary === null || transactionSummary === void 0 ? void 0 : transactionSummary.totalVialsRedeemed)),
                    React.createElement(RewardDataItem, null, renderDataItem('Total Vial Credits Received', transactionSummary === null || transactionSummary === void 0 ? void 0 : transactionSummary.totalRewardPointsEarned))))) : (React.createElement(React.Fragment, null,
                React.createElement(DataItemRow, null,
                    React.createElement(RewardDataItem, null, renderDataItem('Total Reward Vials Available', transactionSummary === null || transactionSummary === void 0 ? void 0 : transactionSummary.totalFreeVialsAvailable, 'success'))),
                React.createElement(DataItemRow, null,
                    React.createElement(RewardDataItem, null, renderDataItem('Total Reward Vials Redeemed', transactionSummary === null || transactionSummary === void 0 ? void 0 : transactionSummary.totalVialsRedeemed)),
                    React.createElement(RewardDataItem, null, renderDataItem('Total Vial Credits Received', transactionSummary === null || transactionSummary === void 0 ? void 0 : transactionSummary.totalRewardPointsEarned))))))),
        React.createElement(Mobile, null,
            React.createElement(StackedCard, { isLoading: isLoading },
                isLoading && (React.createElement(LoadingContainer, null,
                    React.createElement(LoadingBubbles, null))),
                React.createElement(RewardsHistoryRow, null,
                    React.createElement(Typography, { variant: "headline", color: "base" }, "Rewards History")),
                React.createElement(RewardsHistoryRow, null,
                    React.createElement(Typography, { variant: "title", color: "alt" }, "Total Reward Vials Available"),
                    React.createElement(Typography, { variant: "headline", color: "success" }, transactionSummary === null || transactionSummary === void 0 ? void 0 : transactionSummary.totalFreeVialsAvailable)),
                React.createElement(RewardsHistoryRow, null,
                    React.createElement(Typography, { variant: "title", color: "alt" }, "Total Reward Vials Redeemed"),
                    React.createElement(Typography, { variant: "headline", color: "base" }, transactionSummary === null || transactionSummary === void 0 ? void 0 : transactionSummary.totalVialsRedeemed)),
                React.createElement(RewardsHistoryRow, null,
                    React.createElement(Typography, { variant: "title", color: "alt" }, "Total Vial Credits Received"),
                    React.createElement(Typography, { variant: "headline", color: "base" }, transactionSummary === null || transactionSummary === void 0 ? void 0 : transactionSummary.totalRewardPointsEarned))))));
};
export default TransactionInformationView;
var LoadingContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: absolute;\n  top: 0;\n  left: 0;\n"], ["\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: absolute;\n  top: 0;\n  left: 0;\n"])));
var DataContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n"])));
var StackedCard = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  margin-top: 28px;\n  & > div:first-of-type {\n    margin-bottom: 20px;\n  }\n  & > div {\n    margin-bottom: 16px;\n  }\n  ", "\n"], ["\n  ", ";\n  margin-top: 28px;\n  & > div:first-of-type {\n    margin-bottom: 20px;\n  }\n  & > div {\n    margin-bottom: 16px;\n  }\n  ", "\n"])), stackedCardStyles, function (props) { return props.isLoading && props.theme.blur.loading; });
var RewardsHistoryRow = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  justify-content: space-between;\n  align-items: center;\n"], ["\n  display: flex;\n  flex: 1;\n  justify-content: space-between;\n  align-items: center;\n"])));
var DataItemRow = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n"], ["\n  display: flex;\n  flex: 1;\n"])));
var RewardDataItem = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  background-color: white;\n\n  margin: 16px;\n  ", ";\n  padding: 40px 36px;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex: 1;\n  background-color: white;\n\n  margin: 16px;\n  ", ";\n  padding: 40px 36px;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n"])), function (props) { return props.theme.elevation.base.elevation1; });
var DataItemHeaderText = styled(Typography)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin-bottom: 20px;\n  text-align: center;\n"], ["\n  margin-bottom: 20px;\n  text-align: center;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;

export var stringReplaceWithJSX = function (string, replacers) {
    var replacedMap = [string];
    replacers.forEach(function (_a) {
        var match = _a.match, replace = _a.replace;
        replacedMap = replacedMap.flatMap(function (s) {
            if (typeof s !== 'string') {
                return s;
            }
            var arr = s.split(match);
            return arr.map(function (v, i) {
                if (i === arr.length - 1) {
                    return v;
                }
                return [v, replace];
            });
        });
    });
    return replacedMap;
};

var coral50 = '#F9D6D5';
var coral100 = '#F7C9C7';
var coral200 = '#F4B7B5';
var coral300 = '#F09F9C';
var coral400 = '#EC7F7B';
var coral500 = '#E5554F';
var coral600 = '#CD3E39';
var coral700 = '#AB3834';
var coral800 = '#8C3431';
var coral900 = '#73302E';
var aqua50 = '#DCF3EE';
var aqua100 = '#D1EFE9';
var aqua200 = '#C1EAE1';
var aqua300 = '#ADE3D7';
var aqua400 = '#92DACA';
var aqua500 = '#6DCDB8';
var aqua600 = '#56B59B';
var aqua700 = '#499A7F';
var aqua800 = '#427F68';
var aqua900 = '#3B6955';
var orange50 = '#FFE8C9';
var orange100 = '#FFE0B7';
var orange200 = '#FFD59F';
var orange300 = '#FFC77F';
var orange400 = '#FFB554';
var orange500 = '#FF9E1B';
var orange600 = '#D88218';
var orange700 = '#AF6C1D';
var orange800 = '#8F5A1E';
var orange900 = '#754C1F';
var peach50 = '#FAF1ED';
var peach100 = '#F9ECE7';
var peach200 = '#F7E6DF';
var peach300 = '#F4DED4';
var peach400 = '#F1D2C5';
var peach500 = '#ECC3B2';
var peach600 = '#D6A08A';
var peach700 = '#C38268';
var peach800 = '#B1694D';
var peach900 = '#925B46';
var teal25 = '#EBF9FA';
var teal50 = '#B0EBF2';
var teal100 = '#95E5EE';
var teal200 = '#72DCE8';
var teal300 = '#43D0E0';
var teal400 = '#1FABBB';
var teal500 = '#115E67';
var teal600 = '#164E50';
var teal700 = '#154241';
var teal800 = '#143633';
var teal900 = '#122C29';
var white = '#FFFFFF';
var white50 = '#FCFDFD';
var white100 = '#FBFCFC';
var white200 = '#FAFBFB';
var white300 = '#F8FAFA';
var white400 = '#F6F8F8';
var white500 = '#F3F6F6';
var white600 = '#CAD5D5';
var white700 = '#A9B9B7';
var white800 = '#8CA09C';
var white900 = '#758A85';
var black = '#000000';
export var colors = {
    coral: {
        coral50: coral50,
        coral100: coral100,
        coral200: coral200,
        coral300: coral300,
        coral400: coral400,
        coral500: coral500,
        coral600: coral600,
        coral700: coral700,
        coral800: coral800,
        coral900: coral900,
    },
    aqua: {
        aqua50: aqua50,
        aqua100: aqua100,
        aqua200: aqua200,
        aqua300: aqua300,
        aqua400: aqua400,
        aqua500: aqua500,
        aqua600: aqua600,
        aqua700: aqua700,
        aqua800: aqua800,
        aqua900: aqua900,
    },
    orange: {
        orange50: orange50,
        orange100: orange100,
        orange200: orange200,
        orange300: orange300,
        orange400: orange400,
        orange500: orange500,
        orange600: orange600,
        orange700: orange700,
        orange800: orange800,
        orange900: orange900,
    },
    peach: {
        peach50: peach50,
        peach100: peach100,
        peach200: peach200,
        peach300: peach300,
        peach400: peach400,
        peach500: peach500,
        peach600: peach600,
        peach700: peach700,
        peach800: peach800,
        peach900: peach900,
    },
    teal: {
        teal25: teal25,
        teal50: teal50,
        teal100: teal100,
        teal200: teal200,
        teal300: teal300,
        teal400: teal400,
        teal500: teal500,
        teal600: teal600,
        teal700: teal700,
        teal800: teal800,
        teal900: teal900,
    },
    white: {
        white50: white50,
        white100: white100,
        white200: white200,
        white300: white300,
        white400: white400,
        white500: white500,
        white600: white600,
        white700: white700,
        white800: white800,
        white900: white900,
    },
    basic: {
        white: white,
        black: black,
    },
    bg: {
        base: '#F5F5F5',
        body: '#FFFFFF',
        alt: '#EDEDED',
        alt2: '#E6E6E6',
        alt3: '#F9F9F9',
        accent: '#E5554F',
        success: '#F7FCFB',
        success2: '#ECF8F6',
        alert: '#FFF4E5',
        error: '#FDF2F2',
        list: '#FCFDFD',
        teal: '#115E67',
    },
    text: {
        base: '#333333',
        alt: '#808080',
        alt2: '#EDEDED',
        alt3: '#FFFFFF',
        alt4: '#CFCFCF',
        alt5: '#B1B1B1',
        link: '#E5554F',
        linkAlt: '#1FABBB',
        love: coral300,
        success: '#6DCDB8',
        alert: '#D88218',
    },
    border: {
        base: '#EDEDED',
        alt: '#333333',
        alt2: '#CFCFCF',
        success: '#6DCDB8',
        success2: '#D1EFE9',
        alert: '#D88218',
        alert2: '#FFE0B7',
        error: '#E5554F',
        error2: '#F7C9C7',
    },
    utility: {
        active: '#1FABBB',
        success: '#6DCDB8',
        alert: '#D88218',
    },
    selectable: {
        active: '#333333',
    },
    svg: {
        primary: '#F5F5F5',
        secondary: coral300,
        tertiary: coral500,
    },
};

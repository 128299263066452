var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Button, Icon, Typography } from 'designSystem';
import React from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { ConsumerRewardOptInStatus } from 'types/codegen/hooks';
import { useScreenSizing } from '../../../../../../hooks';
var FacilityInfoHeader = function (_a) {
    var facilityName = _a.facilityName, setShowFacilitySelect = _a.setShowFacilitySelect, canOptIn = _a.canOptIn, status = _a.status, isLocked = _a.isLocked, isInactive = _a.isInactive, _b = _a.showCTA, showCTA = _b === void 0 ? true : _b, hideFacilitySwitch = _a.hideFacilitySwitch;
    var navigate = useNavigate();
    var isMobile = useScreenSizing().isMobile;
    var isOptedIn = status === ConsumerRewardOptInStatus.OptedIn;
    var handleCTAClick = function () {
        if (isOptedIn) {
            navigate('/rewards/check-in');
            return;
        }
        else if (canOptIn) {
            navigate('/settings/rewards');
            return;
        }
    };
    var getCTAText = function () {
        if (isOptedIn && !isMobile) {
            return 'Rewards Status / Check In';
        }
        else if (isOptedIn && isMobile) {
            return 'Check In Patient';
        }
        else if (canOptIn) {
            return 'Opt In to Evolus Rewards';
        }
        return null;
    };
    var invalidCheckInState = isLocked || isInactive;
    var renderCTA = showCTA && !invalidCheckInState && (canOptIn || isOptedIn);
    return (React.createElement(FacilityInfoHeaderContainer, null,
        React.createElement(FacilityInfoLeftContainer, null,
            React.createElement(FacilityName, { variant: "titleLarge" }, facilityName),
            !hideFacilitySwitch && (React.createElement(SwitchFacilityButtonContainer, null,
                React.createElement(SwitchFacilityButton, { onClick: function () {
                        setShowFacilitySelect(true);
                    }, "data-automation-id": "rewards-switch-facility-btn" }, "Switch Facility")))),
        React.createElement(FacilityInfoRightContainer, null, renderCTA && (React.createElement(Button, { size: "xlarge", startIcon: React.createElement(Icon, { color: "inherit", name: "rewards" }), onClick: handleCTAClick, "data-automation-id": "rewards-check-in-btn" }, getCTAText())))));
};
var FacilityInfoLeftContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  justify-content: center;\n  @media screen and (max-width: ", "px) {\n    & > span {\n      text-align: center;\n    }\n    & > div {\n      display: flex;\n      justify-content: center;\n      align-items: center;\n    }\n  }\n"], ["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  justify-content: center;\n  @media screen and (max-width: ", "px) {\n    & > span {\n      text-align: center;\n    }\n    & > div {\n      display: flex;\n      justify-content: center;\n      align-items: center;\n    }\n  }\n"])), function (props) { return props.theme.breakpoints.md; });
var FacilityInfoRightContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"])));
var FacilityName = styled(Typography)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  text-transform: capitalize;\n"], ["\n  text-transform: capitalize;\n"])));
var SwitchFacilityButtonContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n"], ["\n  display: flex;\n"])));
var SwitchFacilityButton = styled.button(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  all: unset;\n  cursor: pointer;\n  color: ", ";\n  text-decoration: underline;\n  margin-top: 12px;\n  font-size: 0.875rem;\n"], ["\n  all: unset;\n  cursor: pointer;\n  color: ", ";\n  text-decoration: underline;\n  margin-top: 12px;\n  font-size: 0.875rem;\n"])), function (props) { return props.theme.colors.text.alt; });
var FacilityInfoHeaderContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  background-color: white;\n\n  margin: 16px;\n  min-height: 156px;\n  ", ";\n  padding: 42px;\n  @media screen and (max-width: ", "px) {\n    flex-direction: column;\n    gap: 32px;\n    padding: 32px;\n    margin-top: 32px;\n    flex: unset;\n  }\n"], ["\n  display: flex;\n  background-color: white;\n\n  margin: 16px;\n  min-height: 156px;\n  ", ";\n  padding: 42px;\n  @media screen and (max-width: ", "px) {\n    flex-direction: column;\n    gap: 32px;\n    padding: 32px;\n    margin-top: 32px;\n    flex: unset;\n  }\n"])), function (props) { return props.theme.elevation.base.elevation1; }, function (props) { return props.theme.breakpoints.md; });
export default FacilityInfoHeader;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;

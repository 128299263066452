var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Typography } from 'designSystem';
import React from 'react';
import styled from 'styled-components';
import { OrderDiscountType, OrderPromotionType, PricingModel, } from 'types/codegen/hooks';
import LoyaltyRankLevelNames from 'constants/LoyaltyRankLevelNames';
var OrderPricingDetails = function (_a) {
    var _b, _c, _d, _e;
    var order = _a.order, orderDisplay = _a.orderDisplay;
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
    });
    var discounts = orderDisplay.discounts;
    var isJet = !!order.discounts.find(function (d) { return d.type === 'JET'; });
    var stimulusDiscount = order.discounts.find(function (d) { return d.type === OrderDiscountType.PracticeApp; });
    var productLine = order.lineItems[0];
    var pricingModel = (_b = productLine.pricingDetail) === null || _b === void 0 ? void 0 : _b.pricingModel;
    var isStimulusOrder = pricingModel === PricingModel.StimulusPrice;
    var promotions = (_d = (_c = productLine === null || productLine === void 0 ? void 0 : productLine.product) === null || _c === void 0 ? void 0 : _c.promotions) !== null && _d !== void 0 ? _d : [];
    var promotion = promotions
        ? promotions.find(function (p) { return p.type === OrderPromotionType.EvolusPointRedemption; })
        : null;
    var rewardVials = promotion
        ? (_e = promotion === null || promotion === void 0 ? void 0 : promotion.evolusPointRedemption) === null || _e === void 0 ? void 0 : _e.redeemedForQuantity
        : 0;
    var totalVials = productLine.product.quantity;
    var hasRewardVials = rewardVials > 0;
    var hasPaidVials = totalVials - rewardVials > 0;
    var calculateTotalSaved = function () {
        var isCustomPricing = pricingModel === PricingModel.CustomAccount ||
            pricingModel === PricingModel.CustomGroup;
        if (stimulusDiscount === null || stimulusDiscount === void 0 ? void 0 : stimulusDiscount.total) {
            return orderDisplay.totalDiscounts.toFixed(2);
        }
        if (rewardVials) {
            var rewardVialDiff = rewardVials * (orderDisplay.listPrice / productLine.product.quantity) -
                rewardVials * productLine.product.pricePerUnit;
            if (order.pricedWithLoyaltyLevel || isCustomPricing) {
                return formatter.format(Math.abs(orderDisplay.discounts.filter(function (d) { return d.type === 'CUSTOM_PRICING'; })[0]
                    .total) +
                    orderDisplay.totalDiscounts +
                    rewardVialDiff);
            }
            return formatter.format(orderDisplay.totalDiscounts + rewardVialDiff);
        }
        if (order.pricedWithLoyaltyLevel || isCustomPricing) {
            return formatter.format(Math.abs(discounts.filter(function (d) { return d.type === 'CUSTOM_PRICING'; })[0].total) + orderDisplay.totalDiscounts);
        }
        return formatter.format(orderDisplay.totalDiscounts);
    };
    var calculateTotalPrice = function () {
        return formatter.format(orderDisplay.totalCharged || orderDisplay.totalEstimated);
    };
    var calculateEstimatedTax = function () {
        return formatter.format(orderDisplay.taxCharged || orderDisplay.taxEstimated || 0);
    };
    var calculateShipping = function () {
        var fee = orderDisplay.shipping.method.fee;
        if (fee === 0)
            return 'Free';
        return formatter.format(fee);
    };
    var calculateSubtotal = function () {
        if (hasRewardVials) {
            return formatter.format(orderDisplay.subtotal -
                (rewardVials *
                    (orderDisplay.listPrice / productLine.product.quantity) -
                    rewardVials * productLine.product.pricePerUnit));
        }
        return formatter.format(orderDisplay.subtotal);
    };
    var getOrderListing = function () {
        var bulleted = productLine.product.quantity - rewardVials > 0;
        return (React.createElement(React.Fragment, null,
            hasRewardVials && (React.createElement(OrderSmallLine, null,
                React.createElement(OrderSmallLineLeft, null,
                    React.createElement(Typography, { variant: "headlineSmall", color: "success" },
                        bulleted && '• ',
                        rewardVials,
                        " Vial",
                        rewardVials > 1 && 's')),
                React.createElement(Typography, { variant: "headlineSmall", color: "success" }, formatter.format(isStimulusOrder
                    ? -rewardVials * productLine.product.pricePerUnit
                    : -rewardVials *
                        (orderDisplay.listPrice / productLine.product.quantity))))),
            hasPaidVials && (React.createElement(OrderSmallLine, null,
                React.createElement(OrderSmallLineLeft, null,
                    React.createElement(Typography, { variant: "headlineSmall" },
                        bulleted && '• ',
                        totalVials - rewardVials,
                        " Vial",
                        totalVials - rewardVials > 1 && 's')),
                React.createElement(Typography, { variant: "headlineSmall" }, formatter.format(isStimulusOrder
                    ? (productLine.product.quantity - rewardVials) *
                        productLine.product.pricePerUnit
                    : (productLine.product.quantity - rewardVials) *
                        (orderDisplay.listPrice / productLine.product.quantity))))),
            discounts
                .filter(function (d) {
                return d.type === OrderDiscountType.CustomPricing && !isStimulusOrder;
            })
                .map(function (price) { return (React.createElement(OrderSmallLine, { key: price.discountId },
                React.createElement(OrderSmallLineLeft, null,
                    React.createElement(Typography, { variant: "headlineSmall", color: "success" },
                        (pricingModel === null || pricingModel === void 0 ? void 0 : pricingModel.includes('_LOYALTY'))
                            ? LoyaltyRankLevelNames[order.pricedWithLoyaltyLevel]
                            : 'Your',
                        ' ',
                        "Discount"),
                    React.createElement(Typography, { variant: "headlineSmall", color: "success" },
                        "(",
                        formatter.format((orderDisplay.listPrice / productLine.product.quantity) *
                            (price.percentage * 0.01)),
                        "/vial)")),
                React.createElement(Typography, { variant: "headlineSmall", color: "success" }, formatter.format(price.total)))); }),
            isStimulusOrder && (stimulusDiscount === null || stimulusDiscount === void 0 ? void 0 : stimulusDiscount.total) && (React.createElement(OrderSmallLine, null,
                React.createElement(OrderSmallLineLeft, null,
                    React.createElement(Typography, { variant: "headlineSmall", color: "success" }, "App Order Discount ($25/vial)")),
                React.createElement(Typography, { variant: "headlineSmall", color: "success" }, formatter.format(stimulusDiscount === null || stimulusDiscount === void 0 ? void 0 : stimulusDiscount.total)))),
            !isJet &&
                discounts
                    .filter(function (d) { return d.type === OrderDiscountType.Jet; })
                    .map(function (pricing) { return (React.createElement(OrderSmallLine, { key: pricing.type },
                    React.createElement(OrderSmallLineLeft, null,
                        React.createElement(Typography, { variant: "headlineSmall", color: "success" }, "J.E.T. Program")),
                    React.createElement(Typography, { variant: "headlineSmall", color: "success" }, formatter.format(pricing.total)))); }),
            discounts
                .filter(function (d) { return d.type === OrderDiscountType.BulkOrder; })
                .map(function (pricing) {
                return pricing.total <= 0 && (React.createElement(OrderSmallLine, { key: pricing.type },
                    React.createElement(OrderSmallLineLeft, null,
                        React.createElement(Typography, { variant: "headlineSmall", color: "success" }, pricing.description || 'Bulk Discount')),
                    React.createElement(Typography, { variant: "headlineSmall", color: "success" }, formatter.format(pricing.total))));
            }),
            discounts
                .filter(function (d) {
                return [
                    OrderDiscountType.Generic,
                    OrderDiscountType.Other,
                    OrderDiscountType.Custom,
                ].includes(d.type);
            })
                .map(function (pricing) {
                return pricing.total <= 0 && (React.createElement(OrderSmallLine, { key: pricing.type },
                    React.createElement(OrderSmallLineLeft, null,
                        React.createElement(Typography, { variant: "headlineSmall", color: "success" }, pricing.description || 'Discount')),
                    React.createElement(Typography, { variant: "headlineSmall", color: "success" }, formatter.format(pricing.total))));
            })));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Typography, { variant: "sectionHeader" }, "Your Order"),
        React.createElement(JeuveauLine, null,
            React.createElement(Jeuveau, null,
                React.createElement(JeuveauImg, null),
                React.createElement(Typography, { variant: "headline" }, "Jeuveau\u00AE")),
            React.createElement(Typography, { variant: "headline" },
                productLine.product.quantity,
                " Vial",
                productLine.product.quantity > 1 && 's')),
        React.createElement(OrderSmallLine, { style: { margin: 0 } },
            React.createElement(Typography, { variant: "headlineSmall" }, "Your Price"),
            React.createElement(Typography, { variant: "headlineSmall" }, formatter.format(productLine.product.pricePerUnit))),
        React.createElement(OrderSmallLine, null,
            React.createElement(OrderSmallLineLeft, null,
                React.createElement(Typography, { variant: "headlineSmall" }, "List Price"),
                React.createElement(Typography, { variant: "bodySmall" }, "Price Per Unit")),
            React.createElement(Typography, { variant: "headlineSmall" }, formatter.format(orderDisplay.listPrice / productLine.product.quantity))),
        React.createElement(OrderDivider, { style: { margin: '24px 0' } }),
        React.createElement(OrderSmallLine, null,
            React.createElement(OrderSmallLineLeft, null,
                React.createElement(Typography, { variant: "headlineSmall" },
                    productLine.product.quantity,
                    " Total Vial",
                    productLine.product.quantity > 1 && 's'),
                React.createElement(Typography, { variant: "bodySmall" },
                    "(",
                    isStimulusOrder ? 'Stimulus Price' : 'List Price',
                    ")")),
            React.createElement(Typography, { variant: "headlineSmall" }, formatter.format(isStimulusOrder
                ? productLine.product.quantity * productLine.product.pricePerUnit
                : productLine.product.quantity *
                    (orderDisplay.listPrice / productLine.product.quantity)))),
        getOrderListing(),
        React.createElement(OrderSmallLine, null,
            React.createElement(OrderSmallLineLeft, null,
                React.createElement(Typography, { variant: "headlineSmall" }, "Subtotal")),
            React.createElement(Typography, { variant: "headlineSmall" }, calculateSubtotal())),
        discounts
            .filter(function (d) { return d.type === OrderDiscountType.PaymentMethod; })
            .map(function (pricing) { return (React.createElement(OrderSmallLine, { key: pricing.type },
            React.createElement(OrderSmallLineLeft, null,
                React.createElement(Typography, { variant: "headlineSmall", color: "success" }, "ACH Discount (".concat(pricing.percentage, "%)"))),
            React.createElement(Typography, { variant: "headlineSmall", color: "success" }, formatter.format(pricing.total)))); }),
        React.createElement(OrderSmallLine, null,
            React.createElement(OrderSmallLineLeft, null,
                React.createElement(Typography, { variant: "headlineSmall" }, "Estimated Tax")),
            React.createElement(Typography, { variant: "headlineSmall" }, calculateEstimatedTax())),
        React.createElement(OrderSmallLine, null,
            React.createElement(OrderSmallLineLeft, null,
                React.createElement(Typography, { variant: "headlineSmall" }, "Shipping")),
            React.createElement(Typography, { variant: "headlineSmall" }, calculateShipping())),
        React.createElement(OrderDivider, { style: { margin: '20px 0' } }),
        React.createElement(OrderSection, null,
            React.createElement(OrderSmallLine, null,
                React.createElement(Typography, { variant: "headline" }, "Total"),
                React.createElement(Typography, { variant: "headline" }, calculateTotalPrice())),
            React.createElement(OrderSmallLine, null,
                React.createElement(Typography, { variant: "headlineSmall", color: "success" }, "Total Saved"),
                React.createElement(Typography, { variant: "headlineSmall", color: "success" }, calculateTotalSaved())))));
};
export default OrderPricingDetails;
var OrderSection = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var OrderDivider = styled.hr(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border: 1px solid ", ";\n"], ["\n  border: 1px solid ", ";\n"])), function (props) { return props.theme.colors.border.success2; });
var JeuveauLine = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin: 24px 0;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin: 24px 0;\n"])));
var Jeuveau = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
var JeuveauImg = styled.img.attrs({
    src: require('assets/Jeuveau-vial.png'),
})(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 26px;\n  margin-right: 20px;\n"], ["\n  width: 26px;\n  margin-right: 20px;\n"])));
var OrderSmallLine = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  align-items: flex-start;\n  justify-content: space-between;\n\n  &:not(:last-child) {\n    margin-bottom: 12px;\n  }\n"], ["\n  display: flex;\n  align-items: flex-start;\n  justify-content: space-between;\n\n  &:not(:last-child) {\n    margin-bottom: 12px;\n  }\n"])));
var OrderSmallLineLeft = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;

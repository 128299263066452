import LevelBenefitEnums from 'types/enums/LevelBenefitsEnum';
import LevelBenefitsEnum from 'types/enums/LevelBenefitsEnum';
export var levelBenefits = {
    LEVEL_0: {
        benefits: [],
        nextLevelBenefits: [
            LevelBenefitsEnum.EVOLUS_REWARDS_NINE,
            LevelBenefitEnums.EVOLUS_PROFILE,
            LevelBenefitsEnum.EXFLUENTIAL,
        ],
    },
    LEVEL_1: {
        benefits: [
            LevelBenefitsEnum.EVOLUS_REWARDS_NINE,
            LevelBenefitEnums.EVOLUS_PROFILE,
            LevelBenefitsEnum.EXFLUENTIAL,
        ],
        nextLevelBenefits: [LevelBenefitEnums.OFFICE_MARKETING],
    },
    LEVEL_2: {
        benefits: [
            LevelBenefitEnums.EVOLUS_REWARDS_NINE,
            LevelBenefitEnums.EVOLUS_PROFILE,
            LevelBenefitEnums.EXFLUENTIAL,
            LevelBenefitEnums.OFFICE_MARKETING,
        ],
        nextLevelBenefits: [],
    },
    LEVEL_3: {
        benefits: [
            LevelBenefitEnums.EVOLUS_REWARDS_NINE,
            LevelBenefitEnums.EVOLUS_PROFILE,
            LevelBenefitEnums.EXFLUENTIAL,
            LevelBenefitEnums.OFFICE_MARKETING,
        ],
        nextLevelBenefits: [],
    },
    LEVEL_4: {
        benefits: [
            LevelBenefitEnums.EVOLUS_REWARDS_NINE,
            LevelBenefitEnums.EVOLUS_PROFILE,
            LevelBenefitEnums.EXFLUENTIAL,
            LevelBenefitEnums.OFFICE_MARKETING,
        ],
        nextLevelBenefits: [],
    },
    LEVEL_5: {
        benefits: [
            LevelBenefitEnums.EVOLUS_REWARDS_NINE,
            LevelBenefitEnums.EVOLUS_PROFILE,
            LevelBenefitEnums.EXFLUENTIAL,
            LevelBenefitEnums.OFFICE_MARKETING,
        ],
        nextLevelBenefits: [LevelBenefitEnums.DIAMOND_MARKETING],
    },
    LEVEL_6: {
        benefits: [
            LevelBenefitEnums.EVOLUS_REWARDS_NINE,
            LevelBenefitEnums.EVOLUS_PROFILE,
            LevelBenefitEnums.EXFLUENTIAL,
            LevelBenefitEnums.OFFICE_MARKETING,
            LevelBenefitEnums.DIAMOND_MARKETING,
        ],
        nextLevelBenefits: [LevelBenefitEnums.ELITE_MARKETING],
    },
    LEVEL_7: {
        benefits: [
            LevelBenefitEnums.EVOLUS_REWARDS_NINE,
            LevelBenefitEnums.EVOLUS_PROFILE,
            LevelBenefitEnums.EXFLUENTIAL,
            LevelBenefitEnums.OFFICE_MARKETING,
            LevelBenefitEnums.ELITE_MARKETING,
        ],
        nextLevelBenefits: [LevelBenefitEnums.ELITE_PLUS_MARKETING],
    },
    LEVEL_8: {
        benefits: [
            LevelBenefitEnums.EVOLUS_REWARDS_NINE,
            LevelBenefitEnums.EVOLUS_PROFILE,
            LevelBenefitEnums.EXFLUENTIAL,
            LevelBenefitEnums.OFFICE_MARKETING,
            LevelBenefitEnums.ELITE_PLUS_MARKETING,
        ],
        nextLevelBenefits: [],
    },
    CUSTOM: {
        benefits: [
            LevelBenefitEnums.MARKETING_INSIGHTS,
            LevelBenefitEnums.EVOLUS_PROFILE,
            LevelBenefitEnums.EVOLUS_REWARDS_EIGHT,
        ],
        nextLevelBenefits: [],
    },
};
export var levelBenefitsById = {
    ELITE_MARKETING: {
        header: 'Elite Marketing Package',
        icon: 'evolux-marketing',
        content: 'CO-BRANDED MARKETING BENEFITS TO HELP GROW YOUR PRACTICE\n' +
            'Choose your preferred marketing package (up to $10,000 value):\n' +
            'Option 1: 6 weeks of Evolus Streaming TV Commercial\n' +
            'Option 2: 10 weeks of Paid Search + Paid Social',
    },
    ELITE_PLUS_MARKETING: {
        header: 'Elite+ Marketing Package',
        icon: 'evolux-marketing',
        content: 'CO-BRANDED MARKETING BENEFITS TO HELP GROW YOUR PRACTICE\n' +
            'Choose your preferred marketing package (up to $12,000 value):\n' +
            'Option 1: 8 weeks of Paid Search + Paid Social + Evolus Streaming TV\n' +
            'Option 2: 4 weeks for your out-of-home Premium Billboard\n' +
            'Option 3: 12 weeks of Paid Search + Paid Social\n' +
            'Option 4: In-office experience w/ Custom Wallpaper (injection room or front office)',
    },
    EVOLUS_PROFILE: {
        header: 'Website Profile',
        icon: 'evolus-profile',
        content: 'Show off your unique practice and its beautiful environment while introducing your team to potential patients.',
    },
    EVOLUS_REWARDS_TWELVE: {
        header: 'Loyalty Rewards',
        icon: 'evolus-rewards',
        content: 'Earn reward vials for every 12 rewards that you provide to patients.',
    },
    EVOLUS_REWARDS_TEN: {
        header: 'Loyalty Rewards',
        icon: 'evolus-rewards',
        content: 'Earn reward vials for every 10 rewards that you provide to patients.',
    },
    EVOLUS_REWARDS_NINE: {
        header: 'Loyalty Rewards',
        icon: 'evolus-rewards',
        content: 'Earn reward vials for every 9 rewards that you provide to patients.',
    },
    EVOLUS_REWARDS_EIGHT: {
        header: 'Loyalty Rewards',
        icon: 'evolus-rewards',
        content: 'Earn reward vials for every 8 rewards that you provide to patients.',
    },
    OFFICE_MARKETING: {
        header: 'In-Office Marketing Collateral*',
        icon: 'office-marketing',
        content: 'Customized merchandise & educational materials designed to help you convert patients and increase loyalty.',
    },
    PLATINUM_MARKETING: {
        header: 'Platinum Marketing Package',
        icon: 'evolux-marketing',
        content: 'Marketing package with an estimated 75,000 impressions valued up to $2,000 per quarter.\n' +
            ' • Digital banner ads\n',
    },
    EXFLUENTIAL: {
        header: 'Exfluential',
        icon: 'evolux-lvbx',
        content: 'Exclusive marketing platforms rich with content and functionality to help you engage and stay connected to patients.',
    },
    DIAMOND_MARKETING: {
        header: 'Diamond Marketing Package',
        icon: 'evolux-marketing',
        content: 'CO-BRANDED MARKETING BENEFITS TO HELP GROW YOUR PRACTICE\n' +
            'Includes Paid Search + Paid Social (Up to $6,000 value)',
    },
    PLACEHOLDER: {
        header: 'Placeholder Text',
        icon: 'evolus-rewards',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
    },
    MARKETING_INSIGHTS: {
        header: 'Marketing Insights',
        icon: 'evolus-rewards',
        content: 'Reach out to a representative for more information.',
    },
    SWAG: {
        header: 'Swag Store',
        icon: 'present',
        content: "Redeem your swag #LINK#",
    },
    DIGITAL_ACCELERATOR: {
        header: 'NEW! DIGITAL Accelerator',
        icon: 'photograph',
        content: 'Optimize visibility on your profile page and improve search engine visibility across the web. Capture high quality content with Evolus photography (annually updated).',
    },
};

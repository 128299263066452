var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import OutsideClickHandler from 'react-outside-click-handler';
import Checkmark from './Check.png';
import { Card, Menu, MenuItem, MenuItemText, Icon } from 'designSystem';
import { Typography } from '../Typography';
export var Dropdown = function (_a) {
    var _b, _c;
    var _d = _a.options, options = _d === void 0 ? [] : _d, _e = _a.clearable, clearable = _e === void 0 ? false : _e, _f = _a.placeholder, placeholder = _f === void 0 ? 'Select an Option...' : _f, label = _a.label, onSelect = _a.onSelect, _g = _a.searchable, searchable = _g === void 0 ? false : _g, selected = _a.selected, overrideLabel = _a.overrideLabel, formatAddLabel = _a.formatAddLabel, onAddOption = _a.onAddOption, automationId = _a["data-automation-id"];
    var _h = __read(useState(false), 2), isDropdownActive = _h[0], setIsDropdownActive = _h[1];
    var _j = __read(useState(options || []), 2), filteredOptions = _j[0], setFilteredOptions = _j[1];
    var _k = __read(useState(''), 2), searchValue = _k[0], setSearchValue = _k[1];
    var theme = useTheme();
    useEffect(function () {
        var results = options.filter(function (o) {
            var _a;
            return (((_a = o.value) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(searchValue.toLowerCase())) ||
                o.label.toLowerCase().includes(searchValue.toLowerCase()));
        });
        setFilteredOptions(results);
    }, [searchValue, options]);
    useEffect(function () {
        if (isDropdownActive) {
            setFilteredOptions(options);
        }
    }, [options, isDropdownActive]);
    var renderResults = function () {
        var canCreateOption = onAddOption && searchValue;
        var bottomResultLabel = canCreateOption
            ? "".concat(formatAddLabel, " - ").concat(searchValue)
            : 'No Results...';
        var renderBottomResult = function () {
            if (canCreateOption || !(filteredOptions === null || filteredOptions === void 0 ? void 0 : filteredOptions.length)) {
                return (React.createElement(MenuItem, { style: { justifyContent: 'space-between' }, onClick: function () {
                        if (!!canCreateOption) {
                            onAddOption(searchValue);
                        }
                        setIsDropdownActive(false);
                    } },
                    React.createElement(DropdownOptionText, null, bottomResultLabel)));
            }
            return null;
        };
        return (React.createElement(React.Fragment, null, filteredOptions === null || filteredOptions === void 0 ? void 0 :
            filteredOptions.map(function (option, id) { return (React.createElement(MenuItem, { style: { justifyContent: 'space-between' }, key: option.value, onClick: function () {
                    onSelect(option);
                    setSearchValue(option === null || option === void 0 ? void 0 : option.label);
                    setIsDropdownActive(false);
                }, "data-automation-id": "".concat(automationId, "-option-").concat(id) },
                overrideLabel ? (overrideLabel(id)) : (React.createElement(DropdownOptionText, null, option === null || option === void 0 ? void 0 : option.label)),
                (option === null || option === void 0 ? void 0 : option.value) === (selected === null || selected === void 0 ? void 0 : selected.value) && (React.createElement(CheckImage, { src: Checkmark })))); }),
            renderBottomResult()));
    };
    return (React.createElement(OuterContainer, null,
        React.createElement(OutsideClickHandler, { onOutsideClick: function () { return setIsDropdownActive(false); } },
            React.createElement(DropdownWrapper, null,
                searchable && (React.createElement(SearchableDropdownContainer, null,
                    React.createElement(SearchableDropdown, { size: 1, value: searchValue, onChange: function (e) { return setSearchValue(e.target.value); }, onFocus: function () {
                            setIsDropdownActive(true);
                        }, placeholder: (_b = selected === null || selected === void 0 ? void 0 : selected.lable) !== null && _b !== void 0 ? _b : placeholder }),
                    React.createElement(SearchableDropdownIconContainer, { onClick: function () { return setIsDropdownActive(function (show) { return !show; }); } },
                        React.createElement(Icon, { name: isDropdownActive ? 'chevron-up' : 'chevron-down', size: 16 })))),
                label && (React.createElement(Typography, { variant: "headline", color: "alt", style: { position: 'absolute', left: 0, top: -28 }, "data-automation-id": "".concat(automationId, "-btn") }, label)),
                !searchable && (React.createElement(DropdownContainer, { onClick: function () { return setIsDropdownActive(function (show) { return !show; }); }, "data-automation-id": "".concat(automationId, "-btn") },
                    React.createElement(Typography, null, (_c = selected === null || selected === void 0 ? void 0 : selected.label) !== null && _c !== void 0 ? _c : placeholder),
                    React.createElement(Icon, { name: isDropdownActive ? 'chevron-up' : 'chevron-down', size: 16 }))),
                isDropdownActive && (React.createElement(Root, null,
                    React.createElement(Card, { direction: "horizontal", fullWidth: true },
                        React.createElement(Menu, { "data-automation-id": "".concat(automationId, "-options-container") },
                            renderResults(),
                            clearable && (React.createElement(MenuItem, { onClick: function () {
                                    onSelect(null);
                                    setIsDropdownActive(false);
                                    setFilteredOptions(options);
                                    setSearchValue('');
                                } },
                                React.createElement(MenuItemText, { style: { color: theme.colors.coral.coral500 }, "data-automation-id": "".concat(automationId, "-clear") }, 'Clear All')))))))))));
};
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  top: 60px;\n  right: 0;\n  width: 100%;\n  z-index: 1;\n"], ["\n  position: absolute;\n  top: 60px;\n  right: 0;\n  width: 100%;\n  z-index: 1;\n"])));
var DropdownContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 12px 16px;\n\n  border: 1px solid ", ";\n  cursor: pointer;\n  flex: 1;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 12px 16px;\n\n  border: 1px solid ", ";\n  cursor: pointer;\n  flex: 1;\n"])), function (props) { return props.theme.colors.white.white600; });
var SearchableDropdown = styled.input(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 12px 16px;\n\n  cursor: pointer;\n  flex: 1;\n  border: none;\n  font-size: 16px;\n  color: ", ";\n  font-family: 'Euclid';\n  &::placeholder {\n    color: ", ";\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 12px 16px;\n\n  cursor: pointer;\n  flex: 1;\n  border: none;\n  font-size: 16px;\n  color: ", ";\n  font-family: 'Euclid';\n  &::placeholder {\n    color: ", ";\n  }\n"])), function (props) { return props.theme.colors.text.base; }, function (props) { return props.theme.colors.text.alt5; });
var SearchableDropdownContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  flex: 1;\n  border: 1px solid ", ";\n\n  justify-content: center;\n  align-items: center;\n"], ["\n  display: flex;\n  flex-direction: row;\n  flex: 1;\n  border: 1px solid ", ";\n\n  justify-content: center;\n  align-items: center;\n"])), function (props) { return props.theme.colors.white.white600; });
var SearchableDropdownIconContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  padding: 0 16px;\n  cursor: pointer;\n"], ["\n  padding: 0 16px;\n  cursor: pointer;\n"])));
var DropdownWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: relative;\n  width: 100%;\n  display: flex;\n  flex: 1;\n"], ["\n  position: relative;\n  width: 100%;\n  display: flex;\n  flex: 1;\n"])));
var OuterContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n"])));
var CheckImage = styled.img(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  width: 20px;\n  height: 20px;\n"], ["\n  width: 20px;\n  height: 20px;\n"])));
var DropdownOptionText = styled(Typography)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  margin-left: 8px;\n  color: ", "\n  text-transform: capitalize;\n"], ["\n  margin-left: 8px;\n  color: ", "\n  text-transform: capitalize;\n"])), function (props) { return props.theme.colors.text.base; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Button, Desktop, getVariant, Icon, LoadingBubbles, Mobile, Typography, } from 'designSystem';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { pluralize } from 'utils/pluralize';
import phoneNumberFormatter from 'utils/phoneNumberFormatter';
import { StepContainer } from '../../StepContainer';
import CheckInProgress from '../../CheckInProgress';
import { useScreenSizing } from 'hooks';
import { addMobileStyles } from 'utils/addMobileStyles';
export var ValidateMagicPhraseView = function (_a) {
    var _b, _c, _d, _e;
    var setRetryMagicPhrase = _a.setRetryMagicPhrase, magicTriesLeft = _a.magicTriesLeft, rewardAmount = _a.rewardAmount, sendLoading = _a.sendLoading, sendError = _a.sendError, setDeliveryMethod = _a.setDeliveryMethod, phoneNumber = _a.phoneNumber, selectedPhrase = _a.selectedPhrase, setSelectedPhrase = _a.setSelectedPhrase, onContinue = _a.onContinue, onResend = _a.onResend, options = _a.options, optionsLoading = _a.optionsLoading, loading = _a.loading, handleRestart = _a.handleRestart;
    var isMobile = useScreenSizing().isMobile;
    var _f = __read(useState(null), 2), phraseSubmitted = _f[0], setPhraseSubmitted = _f[1];
    var invalidPhraseSupplied = magicTriesLeft !== null;
    var phraseSelected = (selectedPhrase === null || selectedPhrase === void 0 ? void 0 : selectedPhrase.symbol) && (selectedPhrase === null || selectedPhrase === void 0 ? void 0 : selectedPhrase.color);
    var hasNotReselected = ((_b = selectedPhrase === null || selectedPhrase === void 0 ? void 0 : selectedPhrase.symbol) === null || _b === void 0 ? void 0 : _b.enum) === ((_c = phraseSubmitted === null || phraseSubmitted === void 0 ? void 0 : phraseSubmitted.symbol) === null || _c === void 0 ? void 0 : _c.enum) &&
        ((_d = selectedPhrase === null || selectedPhrase === void 0 ? void 0 : selectedPhrase.color) === null || _d === void 0 ? void 0 : _d.enum) === ((_e = phraseSubmitted === null || phraseSubmitted === void 0 ? void 0 : phraseSubmitted.color) === null || _e === void 0 ? void 0 : _e.enum);
    var renderPhraseVerification = !hasNotReselected && phraseSelected && !sendLoading && !sendError;
    var formattedPhoneNumber = phoneNumber
        ? phoneNumberFormatter(phoneNumber)
        : '';
    var renderContent = function () {
        if (optionsLoading) {
            return (React.createElement(LoadingContainer, null,
                React.createElement(LoadingBubbles, null)));
        }
        if (sendLoading) {
            return (React.createElement(OuterContainer, null,
                React.createElement(SendingContainer, null,
                    React.createElement(Icon, { name: "loading", size: 32 }),
                    React.createElement(Typography, { mt: 30, variant: "subtitle" },
                        "Sending magic phrase to ",
                        formattedPhoneNumber))));
        }
        if (sendError) {
            return (React.createElement(OuterContainer, null,
                React.createElement(SendingContainer, null,
                    React.createElement(Icon, { name: "alert", size: 32 }),
                    React.createElement(Typography, { mt: 30, variant: "subtitle" },
                        "Error sending magic phrase to ",
                        formattedPhoneNumber),
                    React.createElement(RetryButton, { onClick: function () {
                            onResend();
                            setRetryMagicPhrase(true);
                        } }, "Resend Magic Phrase"))));
        }
        return (React.createElement(OuterContainer, null,
            React.createElement(IssuesContainer, null,
                React.createElement(FooterContainer, null,
                    React.createElement(Typography, { color: isMobile ? 'alt' : 'base', variant: isMobile ? 'footnote' : 'body' }, "Did the magic phrase not arrive?"),
                    React.createElement(Link, { id: "resend-phrase", onClick: function () {
                            onResend();
                            setRetryMagicPhrase(true);
                        } }, "Re-send magic phrase"),
                    React.createElement(Typography, { color: isMobile ? 'alt' : 'base', variant: isMobile ? 'footnote' : 'body' }, "or tell patient to text START to 386587.")),
                React.createElement(FooterContainer, null,
                    React.createElement(Typography, { color: isMobile ? 'alt' : 'base', variant: isMobile ? 'footnote' : 'body' },
                        "Having issues sending magic phrase to ",
                        formattedPhoneNumber,
                        "?"),
                    React.createElement(Link, { id: "text-to-voice", onClick: function () {
                            setRetryMagicPhrase(true);
                            setDeliveryMethod('TEXT_TO_VOICE');
                        } }, "Send magic phrase via a phone call to validate"))),
            React.createElement(HeaderInfoContainer, null,
                React.createElement(PhoneNumberContainer, null,
                    React.createElement(Icon, { style: { marginRight: 15 }, name: "phone", color: "#6E9191" }),
                    React.createElement(Typography, { variant: isMobile ? 'headlineSmall' : 'title' }, formattedPhoneNumber)),
                React.createElement(TotalDiscountContainer, null,
                    React.createElement(Desktop, null,
                        React.createElement(Typography, { variant: "headlineSmall" },
                            "Today's Discount:",
                            ' ',
                            React.createElement(Typography, { color: "link", variant: "headlineSmall" },
                                "$",
                                rewardAmount))),
                    React.createElement(Mobile, null,
                        React.createElement(Typography, { color: "alt", variant: "footnote" }, "Today's Discount"),
                        React.createElement(Typography, { color: "link", variant: "title" },
                            "$",
                            rewardAmount)))),
            React.createElement(SectionContainer, null,
                React.createElement(Typography, { variant: "headline", color: "alt" }, "Select Color"),
                React.createElement(OptionsContainer, null, options === null || options === void 0 ? void 0 : options.colors.map(function (color, i) {
                    var _a;
                    var selected = ((_a = selectedPhrase === null || selectedPhrase === void 0 ? void 0 : selectedPhrase.color) === null || _a === void 0 ? void 0 : _a.enum) === color.enum;
                    return (React.createElement(ColorOptionItem, { active: selected, color: color.hex, onClick: function () {
                            return setSelectedPhrase(__assign(__assign({}, selectedPhrase), { color: color }));
                        }, key: i, "data-automation-id": "rewards-check-in-validation-color-".concat(color.enum), "data-selected": selected },
                        React.createElement(Typography, { variant: "headlineSmall", color: color.textColor }, color.name.toLowerCase())));
                }))),
            React.createElement(SectionContainer, null,
                React.createElement(Typography, { variant: "headline", color: "alt" }, "Select Symbol"),
                React.createElement(OptionsContainer, null, options === null || options === void 0 ? void 0 : options.animals.map(function (symbol, i) {
                    var _a, _b, _c, _d, _e, _f;
                    return (React.createElement(SymbolOptionItem, { active: ((_a = selectedPhrase === null || selectedPhrase === void 0 ? void 0 : selectedPhrase.symbol) === null || _a === void 0 ? void 0 : _a.enum) === symbol.enum, color: (_b = selectedPhrase === null || selectedPhrase === void 0 ? void 0 : selectedPhrase.color) === null || _b === void 0 ? void 0 : _b.hex, onClick: function () {
                            if (selectedPhrase === null || selectedPhrase === void 0 ? void 0 : selectedPhrase.color) {
                                setSelectedPhrase(__assign(__assign({}, selectedPhrase), { symbol: symbol }));
                            }
                        }, key: i, "data-automation-id": "rewards-check-in-validation-symbol-".concat(symbol), "data-selected": (selectedPhrase === null || selectedPhrase === void 0 ? void 0 : selectedPhrase.symbol) === symbol, "data-symbol": symbol },
                        React.createElement(Symbol, { dangerouslySetInnerHTML: {
                                __html: symbol.image,
                            }, color: ((_c = selectedPhrase === null || selectedPhrase === void 0 ? void 0 : selectedPhrase.symbol) === null || _c === void 0 ? void 0 : _c.enum) === symbol.enum
                                ? selectedPhrase.color.textColor
                                : '#6E9191', background: ((_d = selectedPhrase === null || selectedPhrase === void 0 ? void 0 : selectedPhrase.symbol) === null || _d === void 0 ? void 0 : _d.enum) === symbol.enum
                                ? selectedPhrase.color.hex
                                : 'white' }),
                        React.createElement(Typography, { style: { marginTop: 15 }, variant: "headlineSmall", align: "center", color: ((_e = selectedPhrase === null || selectedPhrase === void 0 ? void 0 : selectedPhrase.symbol) === null || _e === void 0 ? void 0 : _e.enum) === symbol.enum
                                ? (_f = selectedPhrase.color) === null || _f === void 0 ? void 0 : _f.textColor
                                : 'alt' }, symbol.name)));
                }))),
            renderPhraseVerification && (React.createElement(SelectedPhraseContainer, { "data-automation-id": "rewards-check-in-validation-phrase-container" },
                React.createElement(Typography, { style: { textTransform: 'capitalize' }, mb: 20, variant: "titleLarge", "data-automation-id": "rewards-check-in-validation-phrase" }, "".concat(selectedPhrase.color.name, " ").concat(selectedPhrase.symbol.name).toLowerCase()),
                React.createElement(Typography, { variant: "body", color: "alt" }, "Is that correct?")))));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Container, null,
            invalidPhraseSupplied && (React.createElement(InvalidPhraseContainer, null,
                React.createElement(Typography, { align: "center", variant: "headline", color: "alt3" },
                    React.createElement(InvalidIcon, null, "!"),
                    "Invalid Phrase -",
                    ' ',
                    magicTriesLeft > 0
                        ? "You have ".concat(magicTriesLeft, " attempt").concat(pluralize(magicTriesLeft), " remaining.")
                        : 'You need to resend magic phrase and start over.'))),
            React.createElement(StepContainer, { handleRestart: handleRestart, label: sendLoading ? 'Sending Magic Phrase' : 'Validate Magic Phrase', headerText: !sendLoading && !sendError
                    ? 'Select the correct symbols to validate your patients magic phrase.'
                    : null }, renderContent())),
        React.createElement(CheckInProgress, { isLoading: loading, disabled: !phraseSelected || hasNotReselected, hidePrimaryButton: sendLoading || !!sendError, primaryButtonText: "Submit Magic Phrase", onContinue: function () { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            setPhraseSubmitted(selectedPhrase);
                            return [4, onContinue()];
                        case 1:
                            _a.sent();
                            return [2];
                    }
                });
            }); }, tertiaryButtonText: isMobile && 'Restart Check In', tertiaryAction: handleRestart })));
};
var RetryButton = styled(Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 30px;\n"], ["\n  margin-top: 30px;\n"])));
var IssuesContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  background: ", ";\n  padding: 20px 32px;\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  margin-bottom: 32px;\n  ", "\n"], ["\n  ", ";\n  background: ", ";\n  padding: 20px 32px;\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  margin-bottom: 32px;\n  ", "\n"])), function (props) { return props.theme.borders.base; }, function (props) { return props.theme.colors.white.white50; }, addMobileStyles(css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    padding: 12px 20px;\n  "], ["\n    padding: 12px 20px;\n  "])))));
var Symbol = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 48px;\n  height: 48px;\n  & > svg {\n    color: ", ";\n    & > path[fill='background'] {\n      fill: ", ";\n    }\n  }\n"], ["\n  width: 48px;\n  height: 48px;\n  & > svg {\n    color: ", ";\n    & > path[fill='background'] {\n      fill: ", ";\n    }\n  }\n"])), function (props) { var _a; return (_a = props.theme.colors.text[props.color]) !== null && _a !== void 0 ? _a : props.color; }, function (props) { return props.background; });
var LoadingContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject([""], [""])));
var InvalidIcon = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: inline-flex;\n  height: 24px;\n  width: 24px;\n  justify-content: center;\n  align-items: center;\n  border-radius: 24px;\n  background: ", ";\n  color: ", ";\n  font-size: 18px;\n  margin-right: 8px;\n"], ["\n  display: inline-flex;\n  height: 24px;\n  width: 24px;\n  justify-content: center;\n  align-items: center;\n  border-radius: 24px;\n  background: ", ";\n  color: ", ";\n  font-size: 18px;\n  margin-right: 8px;\n"])), function (props) { return props.theme.colors.coral.coral100; }, function (props) { return props.theme.colors.coral.coral500; });
var InvalidPhraseContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  background: ", ";\n  margin: 16px 16px -16px;\n  padding: 20px 48px;\n"], ["\n  display: flex;\n  background: ", ";\n  margin: 16px 16px -16px;\n  padding: 20px 48px;\n"])), function (props) { return props.theme.colors.coral.coral500; });
var SendingContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 36px;\n  background-color: ", ";\n\n  border: 1px solid ", ";\n  @media screen and (max-width: ", "px) {\n    border: none;\n    background: transparent;\n    padding: 0;\n    & > span {\n      text-align: center;\n    }\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 36px;\n  background-color: ", ";\n\n  border: 1px solid ", ";\n  @media screen and (max-width: ", "px) {\n    border: none;\n    background: transparent;\n    padding: 0;\n    & > span {\n      text-align: center;\n    }\n  }\n"])), function (props) { return props.theme.colors.bg.alt3; }, function (props) { return props.theme.colors.border.base; }, function (props) { return props.theme.breakpoints.md; });
var OuterContainer = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n"])));
var HeaderInfoContainer = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  display: flex;\n\n  padding: 20px;\n  background-color: ", ";\n  border: 1px solid ", ";\n\n  ", ";\n"], ["\n  display: flex;\n\n  padding: 20px;\n  background-color: ", ";\n  border: 1px solid ", ";\n\n  ", ";\n"])), function (props) { return props.theme.colors.bg.list; }, function (props) { return props.theme.colors.border.base; }, addMobileStyles(css(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    padding: 12px 20px;\n  "], ["\n    padding: 12px 20px;\n  "])))));
var PhoneNumberContainer = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  align-items: center;\n"], ["\n  display: flex;\n  flex: 1;\n  align-items: center;\n"])));
var TotalDiscountContainer = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  align-items: center;\n  justify-content: flex-end;\n  @media screen and (max-width: ", "px) {\n    flex-direction: column;\n    align-items: flex-end;\n  }\n"], ["\n  display: flex;\n  flex: 1;\n  align-items: center;\n  justify-content: flex-end;\n  @media screen and (max-width: ", "px) {\n    flex-direction: column;\n    align-items: flex-end;\n  }\n"])), function (props) { return props.theme.breakpoints.md; });
var SectionContainer = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  margin-top: 32px;\n"], ["\n  margin-top: 32px;\n"])));
var OptionsContainer = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  margin: 24px 0 0;\n  gap: 24px;\n  @media screen and (max-width: ", "px) {\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n    grid-row-gap: 12px;\n    grid-column-gap: 12px;\n    margin: 16px 0 0;\n  }\n"], ["\n  display: flex;\n  flex: 1;\n  margin: 24px 0 0;\n  gap: 24px;\n  @media screen and (max-width: ", "px) {\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n    grid-row-gap: 12px;\n    grid-column-gap: 12px;\n    margin: 16px 0 0;\n  }\n"])), function (props) { return props.theme.breakpoints.md; });
var OptionItem = styled.button(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  all: unset;\n  text-transform: capitalize;\n  cursor: pointer;\n  display: flex;\n  flex: 1;\n  margin: 0;\n\n  justify-content: center;\n  align-items: center;\n"], ["\n  all: unset;\n  text-transform: capitalize;\n  cursor: pointer;\n  display: flex;\n  flex: 1;\n  margin: 0;\n\n  justify-content: center;\n  align-items: center;\n"])));
var Container = styled.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  flex: 1;\n"], ["\n  flex: 1;\n"])));
var ColorOptionItem = styled(OptionItem)(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  background-color: ", ";\n  padding: 20px 0;\n  position: relative;\n"], ["\n  ", ";\n  ", ";\n  background-color: ", ";\n  padding: 20px 0;\n  position: relative;\n"])), function (props) { return props.active && 'border: 2px solid white'; }, function (props) {
    return props.active && "outline: 2px solid ".concat(props.theme.colors.border.error2);
}, function (props) { return props.color; });
var SymbolOptionItem = styled(OptionItem)(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  border: 2px solid ", ";\n  padding: 28px 0 12px 0;\n  flex-direction: column;\n  background-color: ", ";\n"], ["\n  border: 2px solid ", ";\n  padding: 28px 0 12px 0;\n  flex-direction: column;\n  background-color: ", ";\n"])), function (props) { return props.theme.colors.border.base; }, function (props) { return (props.active ? props.color : 'white'); });
var SelectedPhraseContainer = styled.div(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 20px;\n  background-color: ", ";\n  border: 1px solid ", ";\n\n  margin-top: 48px;\n  @media screen and (max-width: ", "px) {\n    background: transparent;\n    border: none;\n    margin: 16px 0 0;\n    padding: 0 20px;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 20px;\n  background-color: ", ";\n  border: 1px solid ", ";\n\n  margin-top: 48px;\n  @media screen and (max-width: ", "px) {\n    background: transparent;\n    border: none;\n    margin: 16px 0 0;\n    padding: 0 20px;\n  }\n"])), function (props) { return props.theme.colors.bg.alt3; }, function (props) { return props.theme.colors.border.base; }, function (props) { return props.theme.breakpoints.md; });
var FooterContainer = styled.div(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  & > span#resend-phrase,\n  & > span#text-to-voice {\n    margin-left: 12px;\n    :not(:last-child) {\n      margin-right: 8px;\n    }\n  }\n  @media screen and (max-width: ", "px) {\n    display: flex;\n    gap: 8px;\n    flex-direction: column;\n    margin-bottom: 12px;\n    & > span#resend-phrase,\n    & > span#text-to-voice {\n      margin-left: 0;\n      :not(:last-child) {\n        margin-right: 0;\n      }\n    }\n  }\n"], ["\n  & > span#resend-phrase,\n  & > span#text-to-voice {\n    margin-left: 12px;\n    :not(:last-child) {\n      margin-right: 8px;\n    }\n  }\n  @media screen and (max-width: ", "px) {\n    display: flex;\n    gap: 8px;\n    flex-direction: column;\n    margin-bottom: 12px;\n    & > span#resend-phrase,\n    & > span#text-to-voice {\n      margin-left: 0;\n      :not(:last-child) {\n        margin-right: 0;\n      }\n    }\n  }\n"])), function (props) { return props.theme.breakpoints.md; });
var Link = styled.span(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n  all: unset;\n  cursor: pointer;\n  color: ", ";\n  ", "\n  @media screen and (max-width: ", "px) {\n    margin-left: 0;\n    ", "\n  }\n  &:hover {\n    text-decoration: underline;\n  }\n"], ["\n  all: unset;\n  cursor: pointer;\n  color: ", ";\n  ", "\n  @media screen and (max-width: ", "px) {\n    margin-left: 0;\n    ", "\n  }\n  &:hover {\n    text-decoration: underline;\n  }\n"])), function (props) { return props.theme.colors.text.link; }, getVariant('bodySmall'), function (props) { return props.theme.breakpoints.md; }, getVariant('footnote'));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { Button, Typography } from '../../../designSystem';
import { Modal } from '../Modal';
import styled from 'styled-components';
import InsufficientVialsGraphic from 'assets/insufficient-vial-count.svg';
import { useModal } from 'context/ModalContext';
import { useScreenSizing } from '../../../hooks';
export var OptInInsufficientVials = function (_a) {
    var vialsNeeded = _a.vialsNeeded, onSubmit = _a.onSubmit;
    var closeModal = useModal().closeModal;
    var isMobile = useScreenSizing().isMobile;
    return (React.createElement(Modal, { size: "large" },
        React.createElement(ContentContainer, null,
            React.createElement(LeftContainer, null,
                React.createElement(Typography, { color: isMobile ? 'link' : 'base', variant: isMobile ? 'display' : 'titleLarge', mb: isMobile ? 32 : 16, display: "block" }, "Uh oh!"),
                React.createElement(Typography, { display: "block", mb: isMobile ? 16 : 8 },
                    "You need to purchase ",
                    vialsNeeded),
                React.createElement(Typography, { display: "block", color: "alt5", variant: "bodySmall" },
                    React.createElement("sup", null, "*"),
                    "Participation requires the purchase of at least 10 vials in the current quarter."),
                React.createElement(ActionsContainer, null,
                    React.createElement(Button, { size: "xlarge", onClick: function () { return onSubmit(); } },
                        "Buy ",
                        vialsNeeded,
                        " Vials Now"),
                    React.createElement(Button, { size: "xlarge", variant: isMobile ? 'tertiary' : 'outlined', onClick: function () { return closeModal(); } }, "Cancel"))),
            React.createElement(Image, { src: InsufficientVialsGraphic, alt: "insufficient-vials-purchased-graphic" }))));
};
var ContentContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  width: 100%;\n  justify-content: space-around;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column-reverse;\n    justify-content: flex-end;\n    align-items: center;\n  }\n"], ["\n  display: flex;\n  flex: 1;\n  width: 100%;\n  justify-content: space-around;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column-reverse;\n    justify-content: flex-end;\n    align-items: center;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var ActionsContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: auto;\n  width: 100%;\n  display: flex;\n  flex-direction: row-reverse;\n  align-items: center;\n  justify-content: flex-end;\n  gap: 12px;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n    margin-top: auto;\n    & > button {\n      width: 100%;\n    }\n  }\n"], ["\n  margin-top: auto;\n  width: 100%;\n  display: flex;\n  flex-direction: row-reverse;\n  align-items: center;\n  justify-content: flex-end;\n  gap: 12px;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n    margin-top: auto;\n    & > button {\n      width: 100%;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var LeftContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  @media only screen and (max-width: ", "px) {\n    flex: 1;\n    & > span {\n      text-align: center;\n    }\n    & > span:first-of-type {\n      margin-top: 32px;\n    }\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  @media only screen and (max-width: ", "px) {\n    flex: 1;\n    & > span {\n      text-align: center;\n    }\n    & > span:first-of-type {\n      margin-top: 32px;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var Image = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  max-width: 266px;\n"], ["\n  width: 100%;\n  max-width: 266px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { Modal } from '../Modal';
import styled from 'styled-components';
import { Button, Typography } from 'designSystem';
import { useModal } from 'context';
import CheckingPhoneGraphic from 'assets/checking-phone.svg';
export var EvolusSMSOptedOut = function () {
    var closeModal = useModal().closeModal;
    return (React.createElement(Modal, null,
        React.createElement(Container, null,
            React.createElement("div", null,
                React.createElement(Typography, { variant: "titleLarge", display: "block", mb: 16 }, "Uh oh!"),
                React.createElement(Typography, { display: "block", color: "alt", mb: 12 },
                    "This patient is not opted in to receive Evolus Rewards communications.",
                    ' '),
                React.createElement(Typography, { color: "alt", display: "block", mb: 46 },
                    React.createElement("strong", null, "Have them text START to EVOLUS (386-587)"),
                    " before continuing their check-in so they can receive the magic phrase."),
                React.createElement(Button, { variant: "primary", size: "xlarge", onClick: closeModal }, "Patient has texted START")),
            React.createElement("img", { src: CheckingPhoneGraphic, alt: "checking-phone" }))));
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 12px;\n  & > img {\n    width: 100%;\n    max-width: 280px;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 12px;\n  & > img {\n    width: 100%;\n    max-width: 280px;\n  }\n"])));
var templateObject_1;

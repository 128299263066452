var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import { useNavigate } from 'react-router';
import { PieChart } from 'react-minimal-pie-chart';
import { Button, Typography, LoadingBubbles } from 'designSystem';
import { pluralize } from '../../utils/pluralize';
import { addMobileStyles } from '../../utils/addMobileStyles';
import { stackedCardStyles } from 'scenes/Rewards/Tabs/RewardsDashboard/components/StackedMobileCard';
import Tag from '../../designSystem/components/Tag/Tag';
import SearchingGraphic from 'assets/searching.svg';
var getRewardAmountText = function (rewardAmount) {
    if (rewardAmount === 0) {
        return React.createElement(React.Fragment, null, "20 units free");
    }
    return React.createElement(React.Fragment, null,
        "$",
        rewardAmount,
        "\u00A0off");
};
var PieChartLegendRow = function (_a) {
    var rewardAmount = _a.rewardAmount, color = _a.color, percentage = _a.percentage;
    return (React.createElement(PieChartLegendRowContainer, null,
        React.createElement(PieChartLegendBox, null,
            React.createElement(PieChartLegendSquare, { color: color }),
            React.createElement("div", null,
                React.createElement(Typography, { color: "alt", variant: "bodySmall" },
                    "rewarded with ",
                    React.createElement("strong", null, getRewardAmountText(rewardAmount))))),
        React.createElement(PieChartLegendBox, null,
            React.createElement(Tag, { text: "".concat(percentage, "%"), size: "medium", variant: "primary" }))));
};
var RewardsCounterView = function (_a) {
    var fullWidth = _a.fullWidth, errors = _a.errors, isLoading = _a.isLoading, data = _a.data;
    var navigate = useNavigate();
    var theme = useTheme();
    if (!data) {
        return null;
    }
    var handleRewardDetailsClick = function () {
        navigate('/rewards/history?view=rewardsCounterDetails');
    };
    var pieChartColors = {
        0: theme.colors.coral.coral50,
        20: theme.colors.coral.coral200,
        40: theme.colors.coral.coral300,
        60: theme.colors.coral.coral400,
        80: theme.colors.coral.coral500,
        100: theme.colors.coral.coral600,
        120: theme.colors.coral.coral700,
    };
    var getPieChartLegendColor = function (rewardAmount) {
        var _a;
        return (_a = pieChartColors[rewardAmount]) !== null && _a !== void 0 ? _a : theme.colors.coral.coral400;
    };
    var pieChartData = data.data.map(function (entry) {
        return {
            title: "rewarded with $".concat(entry.rewardAmount, " off"),
            value: entry.count,
            color: getPieChartLegendColor(entry.rewardAmount),
        };
    });
    var HasRewardsBody = function () {
        return (React.createElement(ProgressBody, null,
            React.createElement(ProgressLeftContainer, null,
                React.createElement(PieChartContainer, null,
                    React.createElement(PieChartInner, null,
                        React.createElement(PieChartInnerText, null,
                            React.createElement(Typography, { variant: "titleLarge", align: "center" }, data.totalConsumersRewarded),
                            React.createElement(Typography, { variant: "headline", align: "center", color: "alt" },
                                "Patient",
                                pluralize(data.totalConsumersRewarded),
                                " ",
                                React.createElement("br", null),
                                ' ',
                                "Rewarded"))),
                    React.createElement(PieChart, { animate: true, totalValue: data.totalConsumersRewarded, data: pieChartData, startAngle: 45, lineWidth: 30 }))),
            React.createElement(ProgressRightContainer, null,
                React.createElement(Legend, null, data.data.map(function (entry) { return (React.createElement(PieChartLegendRow, { key: entry.rewardAmount, color: getPieChartLegendColor(entry.rewardAmount), rewardAmount: entry.rewardAmount, percentage: Math.round((entry.count / data.totalConsumersRewarded) * 100) })); })))));
    };
    var HasNoRewardsBody = function () {
        return (React.createElement(ProgressBody, null,
            React.createElement(ProgressLeftContainer, { style: { justifyContent: 'center' } },
                React.createElement(Typography, { variant: "headline", align: "center" }, "Looks like you haven't rewarded any patients yet!")),
            React.createElement(ProgressRightContainer, null,
                React.createElement(SearchingImage, { src: SearchingGraphic }))));
    };
    var renderBody = function () {
        if (data.totalConsumersRewarded) {
            return React.createElement(HasRewardsBody, null);
        }
        return React.createElement(HasNoRewardsBody, null);
    };
    if (errors) {
        return (React.createElement(ProgressContainer, { isLoading: isLoading },
            React.createElement(Typography, null, "Sorry, something went wrong!")));
    }
    return (React.createElement(ProgressContainer, { fullWidth: fullWidth, isLoading: isLoading },
        isLoading && (React.createElement(LoadingContainer, null,
            React.createElement(LoadingBubbles, null))),
        React.createElement(ProgressHead, null,
            React.createElement(Typography, { variant: "sectionHeader", color: "alt5" }, "Rewards Counter"),
            React.createElement(Button, { color: "primary", variant: "tertiary", onClick: handleRewardDetailsClick }, "Reward Details")),
        renderBody()));
};
export default RewardsCounterView;
var ProgressContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  padding: 32px;\n  flex-direction: column;\n  ", "\n  ", "\n  ", "\n"], ["\n  ", ";\n  padding: 32px;\n  flex-direction: column;\n  ", "\n  ", "\n  ", "\n"])), function (props) { return props.theme.card.default; }, addMobileStyles(css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    ", ";\n    z-index: 4;\n    margin: -16px 0 0;\n    flex-grow: 0;\n  "], ["\n    ", ";\n    z-index: 4;\n    margin: -16px 0 0;\n    flex-grow: 0;\n  "])), stackedCardStyles)), function (props) { return props.isLoading && props.theme.blur.loading; }, function (props) {
    return props.fullWidth && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      & > div:last-of-type > div {\n        flex: 1;\n      }\n    "], ["\n      & > div:last-of-type > div {\n        flex: 1;\n      }\n    "])));
});
var LoadingContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: absolute;\n  top: 0;\n  left: 0;\n"], ["\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: absolute;\n  top: 0;\n  left: 0;\n"])));
var ProgressLeftContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n\n  ", "\n"], ["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n\n  ", "\n"])), addMobileStyles(css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    align-items: center;\n  "], ["\n    align-items: center;\n  "])))));
var ProgressRightContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex: 1;\n\n  ", "\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex: 1;\n\n  ", "\n"])), addMobileStyles(css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    width: auto;\n    margin-right: 0px;\n    margin-left: 0;\n    margin-top: 24px;\n\n    flex-direction: column;\n    & > div:first-of-type {\n      margin-bottom: 24px;\n      width: 100%;\n    }\n    & > span:first-of-type {\n      margin-bottom: 8px;\n    }\n  "], ["\n    width: auto;\n    margin-right: 0px;\n    margin-left: 0;\n    margin-top: 24px;\n\n    flex-direction: column;\n    & > div:first-of-type {\n      margin-bottom: 24px;\n      width: 100%;\n    }\n    & > span:first-of-type {\n      margin-bottom: 8px;\n    }\n  "])))));
var ProgressHead = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  margin-bottom: 16px;\n  align-items: center;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  margin-bottom: 16px;\n  align-items: center;\n  justify-content: space-between;\n"])));
var ProgressBody = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  display: flex;\n  height: 100%;\n\n  ", "\n"], ["\n  display: flex;\n  height: 100%;\n\n  ", "\n"])), addMobileStyles(css(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    flex-direction: column;\n  "], ["\n    flex-direction: column;\n  "])))));
var PieChartLegendSquare = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  width: 16px;\n  height: 16px;\n  min-width: 16px;\n  min-height: 16px;\n  background-color: ", ";\n  margin-right: 8px;\n"], ["\n  width: 16px;\n  height: 16px;\n  min-width: 16px;\n  min-height: 16px;\n  background-color: ", ";\n  margin-right: 8px;\n"])), function (props) { return props.color; });
var PieChartLegendRowContainer = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 16px;\n  gap: 9px;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 16px;\n  gap: 9px;\n"])));
var PieChartLegendBox = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
var Legend = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  margin-top: 48px;\n\n  ", "\n"], ["\n  margin-top: 48px;\n\n  ", "\n"])), addMobileStyles(css(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n    margin-top: 32px;\n  "], ["\n    margin-top: 32px;\n  "])))));
var SearchingImage = styled.img(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  width: 100%;\n  max-width: 244px;\n  margin-left: 16px;\n"], ["\n  width: 100%;\n  max-width: 244px;\n  margin-left: 16px;\n"])));
var PieChartContainer = styled.div(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  width: 200px;\n  position: relative;\n  margin-right: 16px;\n\n  ", "\n"], ["\n  width: 200px;\n  position: relative;\n  margin-right: 16px;\n\n  ", "\n"])), addMobileStyles(css(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n    margin-top: 24px;\n  "], ["\n    margin-top: 24px;\n  "])))));
var PieChartInner = styled.div(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: absolute;\n  border-radius: 100%;\n  width: 70%;\n  height: 70%;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: absolute;\n  border-radius: 100%;\n  width: 70%;\n  height: 70%;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n"])));
var PieChartInnerText = styled.div(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21;

import React from 'react';
import { ListItemCard } from '../components/ListItemCard';
import { Typography } from '../../../../designSystem';
import { pluralize } from 'utils/pluralize';
export var RewardVialExpirationDetails = function (_a) {
    var _b;
    var details = _a.details;
    return (React.createElement(React.Fragment, null,
        React.createElement(ListItemCard, null,
            React.createElement(Typography, { variant: "headlineSmall", display: "inline" },
                details.facilityName,
                " Credits Expired"),
            React.createElement(Typography, { variant: "headlineSmall", display: "inline", color: "link" },
                "-",
                details.redeemedForPoints,
                " Credit",
                pluralize((_b = details.redeemedForPoints) !== null && _b !== void 0 ? _b : 0)))));
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { Button, Typography } from '../../../../../designSystem';
import styled from 'styled-components';
export var SortButton = function (_a) {
    var onClick = _a.onClick, label = _a.label, sortOrder = _a.sortOrder, active = _a.active;
    return (React.createElement("div", null,
        React.createElement(StyledSortButton, { variant: "quaternary", onClick: onClick },
            React.createElement(Typography, { variant: "headlineSmall", color: "alt" },
                label,
                ' ',
                React.createElement("div", null,
                    React.createElement(AscSortIcon, { active: active && sortOrder === 'asc' }),
                    React.createElement(DescSortIcon, { active: active && sortOrder === 'desc' }))))));
};
var StyledSortButton = styled(Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  & > span > span {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    & > div {\n      display: flex;\n      flex-direction: column;\n      gap: 4px;\n    }\n  }\n"], ["\n  & > span > span {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    & > div {\n      display: flex;\n      flex-direction: column;\n      gap: 4px;\n    }\n  }\n"])));
var SortIcon = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: inline-block;\n  width: 0;\n  height: 0;\n  margin-left: 5px;\n  vertical-align: middle;\n  border-top: 4px solid;\n  border-right: 4px solid transparent;\n  border-left: 4px solid transparent;\n  content: '';\n"], ["\n  display: inline-block;\n  width: 0;\n  height: 0;\n  margin-left: 5px;\n  vertical-align: middle;\n  border-top: 4px solid;\n  border-right: 4px solid transparent;\n  border-left: 4px solid transparent;\n  content: '';\n"])));
var AscSortIcon = styled(SortIcon)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border-top: none;\n  border-bottom: 4px solid;\n  color: ", ";\n"], ["\n  border-top: none;\n  border-bottom: 4px solid;\n  color: ", ";\n"])), function (props) {
    return props.active ? props.theme.colors.text.base : props.theme.colors.text.alt;
});
var DescSortIcon = styled(SortIcon)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border-top: 4px solid;\n  border-bottom: none;\n  color: ", ";\n"], ["\n  border-top: 4px solid;\n  border-bottom: none;\n  color: ", ";\n"])), function (props) {
    return props.active ? props.theme.colors.text.base : props.theme.colors.text.alt;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;

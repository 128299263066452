import React from 'react';
export var EvoluxLVBX = function (_a) {
    var color = _a.color;
    return (React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M13.125 0.625H6.875C5.49429 0.625 4.375 1.74429 4.375 3.125V16.875C4.375 18.2557 5.49429 19.375 6.875 19.375H13.125C14.5057 19.375 15.625 18.2557 15.625 16.875V3.125C15.625 1.74429 14.5057 0.625 13.125 0.625Z", stroke: color, strokeWidth: "1.25", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M4.375 15.625H15.625", stroke: color, strokeWidth: "1.25", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M18.125 8.75H19.375", stroke: color, strokeWidth: "1.25", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M18.125 6.25L19.375 5.625", stroke: color, strokeWidth: "1.25", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M18.125 11.25L19.375 11.875", stroke: color, strokeWidth: "1.25", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M1.875 8.75H0.625", stroke: color, strokeWidth: "1.25", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M1.875 6.25L0.625 5.625", stroke: color, strokeWidth: "1.25", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M1.875 11.25L0.625 11.875", stroke: color, strokeWidth: "1.25", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M7.5 6.66699H13.125", stroke: color, strokeWidth: "1.25", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M6.875 9.58301H12.5", stroke: color, strokeWidth: "1.25", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M9.37516 5.20801L7.7085 11.0413", stroke: color, strokeWidth: "1.25", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M12.2917 5.20801L10.625 11.0413", stroke: color, strokeWidth: "1.25", strokeLinecap: "round", strokeLinejoin: "round" })));
};

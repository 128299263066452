var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Desktop, Typography } from 'designSystem';
import RadioButton from 'designSystem/components/RadioButton/RadioButton';
import React from 'react';
import styled, { css } from 'styled-components';
import facilityIcon from 'assets/facility-icon.png';
import { useScreenSizing } from 'hooks';
export var AddressItem = function (_a) {
    var _b = _a.facility, facility = _b === void 0 ? {
        shippingAddress: {},
    } : _b, selectable = _a.selectable, onSelect = _a.onSelect, selectedFacility = _a.selectedFacility, _c = _a.showFacilityIcon, showFacilityIcon = _c === void 0 ? false : _c, _d = _a.hasRadioButton, hasRadioButton = _d === void 0 ? true : _d;
    var shippingAddress = facility.shippingAddress;
    var renderRadioButton = selectable && hasRadioButton;
    var isMobile = useScreenSizing().isMobile;
    return (React.createElement(AddressItemContainer, { onClick: function () {
            if (selectable) {
                onSelect(facility);
            }
        }, selected: (selectedFacility === null || selectedFacility === void 0 ? void 0 : selectedFacility.id) === facility.id, "data-automation-id": "address-item", "data-facility-id": facility.id },
        renderRadioButton && (React.createElement(RadioButton, { checked: (selectedFacility === null || selectedFacility === void 0 ? void 0 : selectedFacility.id) === facility.id, onChange: function () { }, "data-automation-id": "address-item-radio" })),
        React.createElement(Desktop, null, showFacilityIcon && React.createElement(FacilityIcon, { src: facilityIcon })),
        React.createElement(Typography, { variant: "headline" }, facility.name),
        React.createElement(FacilityAddressContainer, null,
            (shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.careOf) && isMobile ? (React.createElement(Typography, { variant: "bodySmall", color: "alt" }, shippingAddress.careOf)) : (React.createElement(Typography, { variant: "headlineSmall" }, shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.careOf)),
            React.createElement(Typography, { variant: "bodySmall", color: "alt" }, "".concat(shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.addressLine1.toLowerCase()).concat((shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.addressLine2) &&
                ', ' + (shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.addressLine2.toLowerCase()))),
            React.createElement(Typography, { variant: "bodySmall", color: "alt" }, "".concat(shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.city.toLowerCase(), ", ").concat(shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.state, " ").concat(shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.zip)))));
};
var AddressItemContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex: 0 1;\n  padding: 8px 24px;\n  margin: 10px 0;\n  border: 1px solid ", ";\n  font-weight: 500;\n  cursor: ", ";\n  align-items: center;\n\n  @media only screen and (max-width: ", "px) {\n    padding: 20px;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: flex-start;\n    background: #fcfdfd;\n    border: ", ";\n    box-sizing: border-box;\n  }\n"], ["\n  display: flex;\n  flex: 0 1;\n  padding: 8px 24px;\n  margin: 10px 0;\n  border: 1px solid ", ";\n  font-weight: 500;\n  cursor: ", ";\n  align-items: center;\n\n  @media only screen and (max-width: ", "px) {\n    padding: 20px;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: flex-start;\n    background: #fcfdfd;\n    border: ", ";\n    box-sizing: border-box;\n  }\n"])), function (props) { return props.theme.colors.border.base; }, function (props) { return (props.selectable ? 'pointer' : 'inherit'); }, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
}, function (props) {
    return props.selected && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["2px solid ", ""], ["2px solid ", ""])), props.theme.colors.border.error2);
});
var FacilityAddressContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  align-items: flex-end;\n  justify-content: center;\n\n  @media only screen and (max-width: ", "px) {\n    ", "\n\n    align-items: flex-start;\n    justify-content: flex-start;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  align-items: flex-end;\n  justify-content: center;\n\n  @media only screen and (max-width: ", "px) {\n    ", "\n\n    align-items: flex-start;\n    justify-content: flex-start;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
}, function (props) {
    return props.selected && css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n        border-color: ", ";\n      "], ["\n        border-color: ", ";\n      "])), props.theme.colors.border.error2);
});
var FacilityIcon = styled.img(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 16px;\n  height: 16px;\n  margin-right: 16px;\n"], ["\n  width: 16px;\n  height: 16px;\n  margin-right: 16px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;

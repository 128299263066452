var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled, { useTheme } from 'styled-components';
import LeavingCheckInGraphic from 'assets/enroll-patient-graphic-3.svg';
import { Modal } from '../Modal';
import { Typography } from 'designSystem';
export var DownForMaintenance = function (_a) {
    var message = _a.message;
    var theme = useTheme();
    return (React.createElement(Modal, { size: "medium", backdropColor: theme.colors.bg.accent, showClose: false },
        React.createElement(ContentContainer, null,
            React.createElement(ImageContainer, null,
                React.createElement(Image, { src: LeavingCheckInGraphic })),
            React.createElement(TextContainer, null,
                React.createElement(Typography, { align: "center", variant: "display" }, "Sorry, our system is currently down for maintenance."),
                message && (React.createElement(Typography, { mt: 32, align: "center", variant: "subtitle", color: "alt" }, message)),
                React.createElement(Typography, { mt: 30, align: "center", variant: "subtitle", color: "alt" }, "Please try again shortly.")))));
};
var ContentContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  align-items: center;\n  z-index: 10000;\n  position: relative;\n  @media only screen and (max-width: ", "px) {\n    width: 100%;\n  }\n"], ["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  align-items: center;\n  z-index: 10000;\n  position: relative;\n  @media only screen and (max-width: ", "px) {\n    width: 100%;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var ImageContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-bottom: 28px;\n  height: 60%;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-bottom: 28px;\n  height: 60%;\n"])));
var Image = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
var TextContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin: 0 64px;\n  display: flex;\n  flex-direction: column;\n"], ["\n  margin: 0 64px;\n  display: flex;\n  flex-direction: column;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;

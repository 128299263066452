import React from 'react';
export var Rewards = function (_a) {
    var color = _a.color;
    return (React.createElement("svg", { width: "17", height: "17", viewBox: "0 0 17 17", fill: "none", xmlns: "http://www.w3.org/2000/svg", color: color },
        React.createElement("g", { clipPath: "url(#clip0_895_8975)" },
            React.createElement("path", { d: "M15 8.08594H2V15.0859C2 15.3512 2.10536 15.6055 2.29289 15.793C2.48043 15.9806 2.73478 16.0859 3 16.0859H14C14.2652 16.0859 14.5196 15.9806 14.7071 15.793C14.8946 15.6055 15 15.3512 15 15.0859V8.08594Z", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { d: "M15 5.08594H2C1.73478 5.08594 1.48043 5.19129 1.29289 5.37883C1.10536 5.56637 1 5.82072 1 6.08594V7.58594C1 7.71855 1.05268 7.84572 1.14645 7.93949C1.24021 8.03326 1.36739 8.08594 1.5 8.08594H15.5C15.6326 8.08594 15.7598 8.03326 15.8536 7.93949C15.9473 7.84572 16 7.71855 16 7.58594V6.08594C16 5.82072 15.8946 5.56637 15.7071 5.37883C15.5196 5.19129 15.2652 5.08594 15 5.08594Z", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { d: "M8 5.08594C5.79067 5.08594 3.5 3.29527 3.5 1.08594", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { d: "M3.5 1.08594C5.70933 1.08594 8 2.8766 8 5.08594", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { d: "M9 5.08594C11.2093 5.08594 13.5 3.29527 13.5 1.08594", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { d: "M13.5 1.08594C11.2907 1.08594 9 2.8766 9 5.08594", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { d: "M7 5.08594H10V16.0859H7V5.08594Z", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_895_8975" },
                React.createElement("rect", { width: "16", height: "16", fill: "white", transform: "translate(0.5 0.585938)" })))));
};

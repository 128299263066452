import React from 'react';
export var Watermelon = function (_a) {
    var color = _a.color;
    return (React.createElement("svg", { width: "48", height: "48", viewBox: "0 0 48 48", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0_339_86568)" },
            React.createElement("path", { d: "M2.4002 35.89C2.0914 36.1987 1.85133 36.5693 1.69571 36.9773C1.54009 37.3853 1.47243 37.8216 1.49716 38.2576C1.5219 38.6936 1.63847 39.1195 1.83924 39.5073C2.04002 39.8951 2.32046 40.2361 2.6622 40.508C7.85323 44.6783 14.3918 46.8031 21.0428 46.4811C27.6937 46.159 33.9964 43.4123 38.7602 38.76C43.4104 33.9966 46.1557 27.6955 46.4778 21.0464C46.7998 14.3973 44.6763 7.86042 40.5082 2.66995C40.2369 2.32767 39.8964 2.04658 39.5089 1.84509C39.1214 1.6436 38.6957 1.52626 38.2597 1.50077C37.8237 1.47528 37.3872 1.5422 36.9788 1.69716C36.5705 1.85212 36.1995 2.09162 35.8902 2.39995L2.4002 35.89Z", stroke: color, strokeWidth: "3", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { d: "M4.2002 34.0979C8.13372 38.1421 13.5123 40.4587 19.1534 40.5382C24.7945 40.6177 30.2363 38.4537 34.2822 34.5219C38.2137 30.4757 40.3773 25.0338 40.2974 19.3927C40.2176 13.7517 37.9007 8.3732 33.8562 4.43994", stroke: color, strokeWidth: "3", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { d: "M30.0039 19.5H32.9999", stroke: color, strokeWidth: "3", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { d: "M25.5 25.5L28.5 28.5", stroke: color, strokeWidth: "3", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { d: "M19.5 30V32.996", stroke: color, strokeWidth: "3", strokeLinecap: "round", strokeLinejoin: "round" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_339_86568" },
                React.createElement("rect", { width: "48", height: "48", fill: "white" })))));
};
export default Watermelon;

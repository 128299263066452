var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useMemo } from 'react';
import { useTable, useFlexLayout } from 'react-table';
import { Table, TableBody, TableHead, TableRow, TableColumn, TableHeadColumn, Icon, Typography, } from 'designSystem';
import { useNavigate } from 'react-router';
import styled, { css, useTheme } from 'styled-components';
export var UsersTable = function (_a) {
    var isPendingUsers = _a.isPendingUsers, users = _a.users, mainContactId = _a.mainContactId;
    var theme = useTheme();
    var navigate = useNavigate();
    var sortedUsers = useMemo(function () {
        return users.reduce(function (acc, cv) {
            if (cv.userId === mainContactId) {
                return __spreadArray([cv], __read(acc), false);
            }
            return __spreadArray(__spreadArray([], __read(acc), false), [cv], false);
        }, []);
    }, [users, mainContactId]);
    var defaultColumn = React.useMemo(function () { return ({
        minWidth: 1,
        width: 1,
        maxWidth: 3,
    }); }, []);
    var columns = useMemo(function () { return [
        {
            id: 'name',
            Header: isPendingUsers ? 'Invited Users' : 'User',
            accessor: function (row) { return row; },
            Cell: function (_a) {
                var value = _a.value;
                var userId = value.userId, user = value.user;
                var isMain = userId === mainContactId;
                return (React.createElement(NameContainer, null,
                    React.createElement(UserIcon, { name: "user", isMain: isMain }),
                    React.createElement(Typography, { variant: "headline" }, !user.firstName
                        ? user.email
                        : "".concat(user.firstName, " ").concat(user.lastName))));
            },
            width: 2,
        },
        {
            id: 'role',
            Header: 'Permission',
            accessor: 'role',
            Cell: function (_a) {
                var value = _a.value;
                return (React.createElement(Typography, null, value === 'ACCOUNT_ADMIN' ? 'Admin Access' : 'Limited Access'));
            },
        },
        {
            id: 'action',
            accessor: 'userId',
            Cell: function (_a) {
                var value = _a.value;
                return (React.createElement(ActionContainer, null,
                    value === mainContactId && (React.createElement(Typography, { color: "link" }, "Primary Contact")),
                    React.createElement(Icon, { color: theme.colors.text.link, name: "chevron-right" })));
            },
        },
    ]; }, [isPendingUsers, theme.colors.text.link, mainContactId]);
    var _b = useTable({
        columns: columns,
        data: sortedUsers,
        defaultColumn: defaultColumn,
    }, useFlexLayout), getTableProps = _b.getTableProps, getTableBodyProps = _b.getTableBodyProps, headerGroups = _b.headerGroups, prepareRow = _b.prepareRow, rows = _b.rows;
    return (React.createElement("div", null,
        React.createElement(Table, __assign({ fullWidth: true }, getTableProps()),
            React.createElement(TableHead, null, headerGroups.map(function (headerGroup) { return (React.createElement(TableRow, __assign({}, headerGroup.getHeaderGroupProps()), headerGroup.headers.map(function (column) {
                return (React.createElement(TableHeadColumn, __assign({}, column.getHeaderProps()), column.render('Header')));
            }))); })),
            React.createElement(TableBody, __assign({}, getTableBodyProps(), { stripped: true, selectable: true }), rows.map(function (row, i) {
                prepareRow(row);
                return (React.createElement(TableRow, __assign({}, row.getRowProps(), { onClick: function () {
                        return navigate("/settings/user-permissions/".concat(row.original.userId));
                    } }), row.cells.map(function (cell) { return (React.createElement(TableColumn, __assign({}, cell.getCellProps({
                    style: {
                        display: 'flex',
                        alignItems: 'center',
                    },
                })), cell.render('Cell'))); })));
            })))));
};
var ActionContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  align-items: center;\n  justify-content: flex-end;\n  gap: 12px;\n"], ["\n  display: flex;\n  flex: 1;\n  align-items: center;\n  justify-content: flex-end;\n  gap: 12px;\n"])));
var UserIcon = styled(Icon)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: ", ";\n  ", "\n  & > svg {\n    color: ", ";\n  }\n"], ["\n  background: ", ";\n  ", "\n  & > svg {\n    color: ", ";\n  }\n"])), function (props) {
    return !props.isMain ? 'none' : props.theme.colors.text.alt;
}, function (props) {
    return props.isMain
        ? css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n          padding: 4px;\n          display: flex;\n          align-items: center;\n          justify-content: center;\n        "], ["\n          padding: 4px;\n          display: flex;\n          align-items: center;\n          justify-content: center;\n        "]))) : '';
}, function (props) {
    return !props.isMain
        ? props.theme.colors.text.alt
        : props.theme.colors.basic.white;
});
var NameContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 16px;\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 16px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;

import React from 'react';
export var Logout = function (_a) {
    var color = _a.color;
    return (React.createElement("svg", { width: "32", height: "32", viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0_3810_47820)" },
            React.createElement("path", { d: "M0.998169 16.0054H22.0007", stroke: color, strokeWidth: "2.00024", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { d: "M17.0001 21.006L22.0007 16.0054L17.0001 11.0048", stroke: color, strokeWidth: "2.00024", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { d: "M4.40649 22.1342C5.78272 24.9614 8.07056 27.2431 10.9015 28.6118C13.7324 29.9804 16.9416 30.3563 20.0122 29.6789C23.0828 29.0016 25.836 27.3103 27.8283 24.8776C29.8207 22.445 30.9362 19.4125 30.9952 16.2686C31.0541 13.1247 30.0531 10.0525 28.1534 7.54685C26.2537 5.04116 23.5659 3.24785 20.5229 2.45578C17.4799 1.66371 14.2588 1.91899 11.3786 3.1805C8.49829 4.44201 6.12649 6.63631 4.64519 9.40995", stroke: color, strokeWidth: "2.00024", strokeLinecap: "round", strokeLinejoin: "round" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_3810_47820" },
                React.createElement("rect", { width: "32.0039", height: "32.0039", fill: "white", transform: "translate(-0.00195312 -0.00195312)" })))));
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import ReactPlayer from 'react-player/vimeo';
import styled, { useTheme } from 'styled-components';
import { Modal } from '../Modal';
import { Icon, IconButton } from 'designSystem';
import { useModal } from 'context';
export var BenefitVideoPlayer = function (props) {
    var closeModal = useModal().closeModal;
    var theme = useTheme();
    return (React.createElement(Modal, { isHigherModal: true, transparent: true, showClose: false },
        React.createElement(ContentContainer, null,
            React.createElement(CloseContainer, null,
                React.createElement(CloseButton, { onClick: closeModal, variant: "tertiary" },
                    React.createElement(Icon, { name: "close", color: theme.colors.basic.white }))),
            React.createElement(PlayerContainer, null,
                React.createElement(VimeoPlayer, { width: "100%", height: "100%", url: props.vimeoUrl, controls: true })))));
};
var VimeoPlayer = styled(ReactPlayer)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: 1;\n  max-height: 75vh;\n"], ["\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: 1;\n  max-height: 75vh;\n"])));
var ContentContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: 100%;\n  width: 100%;\n  max-height: 75vh;\n  @media only screen and (max-width: ", "px) {\n    max-height: unset;\n    height: unset;\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n  }\n"], ["\n  height: 100%;\n  width: 100%;\n  max-height: 75vh;\n  @media only screen and (max-width: ", "px) {\n    max-height: unset;\n    height: unset;\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var PlayerContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: relative;\n  // aspect-ratio: 16 / 9\n  padding-top: 56.25%;\n"], ["\n  position: relative;\n  // aspect-ratio: 16 / 9\n  padding-top: 56.25%;\n"])));
var CloseContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n"], ["\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n"])));
var CloseButton = styled(IconButton)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-bottom: 16px;\n  @media only screen and (max-width: ", "px) {\n    margin-bottom: 48px;\n  }\n"], ["\n  margin-bottom: 16px;\n  @media only screen and (max-width: ", "px) {\n    margin-bottom: 48px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;

import React from 'react';
export var Lollipop = function (_a) {
    var color = _a.color;
    return (React.createElement("svg", { viewBox: "0 0 48 48", width: "48", height: "48", fill: "none" },
        React.createElement("g", { stroke: color, strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 3, clipPath: "url(#lolli-svg-clip)" },
            React.createElement("path", { d: "M10.5 19.5a18 18 0 1 0 36 0 18 18 0 0 0-36 0Z" }),
            React.createElement("path", { d: "M31.4 18.566a5.115 5.115 0 1 0-6.228 8.06 8.308 8.308 0 0 0 11.436-1.07C45.2 16.47 30.162 2.082 19.342 12.89c-7.942 7.926-.932 21.976 7.09 24.486M1.5 46.5l14.204-14.204" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "lolli-svg-clip" },
                React.createElement("path", { fill: "#fff", d: "M0 0h48v48H0z" })))));
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useModal } from '../../context';
import { useIsMounted, useScreenSizing } from '../../hooks';
import { Button, Desktop, Icon, IconButton, Input, Mobile, PlaceholderLoading, Typography, } from '../../designSystem';
import ModalTypeEnum from '../../types/enums/ModalTypeEnum';
import { PaymentMethodItem } from '../PaymentMethodItem';
import styled from 'styled-components';
import { useLocation } from 'react-router';
import { SensitivePaymentType } from 'types/types';
import { PaymentMethodType, getSelectedPaymentMethod, } from 'utils/getSelectedPaymentMethod';
export var AccountPaymentMethodsView = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var paymentMethods = _a.paymentMethods, onSelect = _a.onSelect, selectedPaymentMethod = _a.selectedPaymentMethod, loading = _a.loading, preselectedPaymentId = _a.preselectedPaymentId, editable = _a.editable, estimatedOrderTotal = _a.estimatedOrderTotal, hideCredit = _a.hideCredit, hideDiscount = _a.hideDiscount, promoApplied = _a.promoApplied, storedPromoCode = _a.storedPromoCode, preapplyCode = _a.preapplyCode, message = _a.message, onRemovePromo = _a.onRemovePromo, validatePromo = _a.validatePromo, isValid = _a.isValid, calculatedPricing = _a.calculatedPricing, promoLoading = _a.promoLoading, setIsAmountEnteredValid = _a.setIsAmountEnteredValid, isAmountEnteredValid = _a.isAmountEnteredValid, fromSelectPayment = _a.fromSelectPayment, calculatedPricingLoading = _a.calculatedPricingLoading;
    var _h = useModal(), openModal = _h.openModal, closeModal = _h.closeModal;
    var isMobile = useScreenSizing().isMobile;
    var location = useLocation();
    var _j = __read(useState(storedPromoCode || preapplyCode || ''), 2), promoCode = _j[0], setPromoCode = _j[1];
    var isMounted = useIsMounted();
    var noPromoCodeApplied = !!promoCode && !promoApplied;
    var renderPromoButton = !preapplyCode && (!isMobile || noPromoCodeApplied);
    var _k = __read(useState(''), 2), amountValiation = _k[0], setAmountValidation = _k[1];
    useEffect(function () {
        if (!!onSelect && (paymentMethods === null || paymentMethods === void 0 ? void 0 : paymentMethods.length) && preselectedPaymentId) {
            var preselectedPayment = (paymentMethods === null || paymentMethods === void 0 ? void 0 : paymentMethods.find(function (payment) { return payment.paymentMethodId === preselectedPaymentId; })) || null;
            preselectedPayment && onSelect([preselectedPayment]);
        }
    }, [paymentMethods, preselectedPaymentId, onSelect]);
    useEffect(function () {
        var _a;
        if (calculatedPricing && selectedPaymentMethod && hasEvolusCredit) {
            var secondaryPaymentMethod = getSelectedPaymentMethod(selectedPaymentMethod, PaymentMethodType.secondary);
            var primaryBankAccount = getSelectedPaymentMethod(selectedPaymentMethod, PaymentMethodType.primary);
            var bankAccounts_1 = !!primaryBankAccount.sensitive.ach;
            if (!secondaryPaymentMethod &&
                bankAccounts_1 &&
                (primaryBankAccount === null || primaryBankAccount === void 0 ? void 0 : primaryBankAccount.amount)) {
                if (((_a = calculatedPricing === null || calculatedPricing === void 0 ? void 0 : calculatedPricing.inputDisplay) === null || _a === void 0 ? void 0 : _a.totalEstimated) <
                    (primaryBankAccount === null || primaryBankAccount === void 0 ? void 0 : primaryBankAccount.amount)) {
                    setIsAmountEnteredValid(false);
                    setAmountValidation('Amount inputted is more than order total.');
                }
            }
        }
    }, [calculatedPricing]);
    useEffect(function () {
        if (preapplyCode && !promoApplied && !promoLoading && validatePromo) {
            validatePromo(preapplyCode);
        }
    }, [preapplyCode]);
    var onPaymentMethodSelect = function (pm) {
        var _a, _b;
        var paymentmethod = [];
        var totalAmount = (_a = calculatedPricing === null || calculatedPricing === void 0 ? void 0 : calculatedPricing.inputDisplay) === null || _a === void 0 ? void 0 : _a.totalEstimated;
        setIsAmountEnteredValid(true);
        setAmountValidation('');
        if (!pm.amount) {
            setIsAmountEnteredValid(false);
            setAmountValidation('Enter valid amount.');
        }
        if (pm.sensitive.type === SensitivePaymentType.Loc || !hasEvolusCredit) {
            paymentmethod.push(__assign(__assign({}, pm), { amount: totalAmount }));
            setIsAmountEnteredValid(true);
        }
        else {
            paymentmethod.push(pm);
            if (pm.amount && pm.amount < totalAmount) {
                var evolusCredit_1 = paymentMethods === null || paymentMethods === void 0 ? void 0 : paymentMethods.find(function (pm) { return pm.sensitive.type === SensitivePaymentType.Loc; });
                var availableCredit = evolusCredit_1.sensitive.loc.limit -
                    evolusCredit_1.sensitive.loc.balance;
                var bankAccounts_2 = !!pm.sensitive.ach;
                var achDiscount = (_b = calculatedPricing.inputDisplay.discounts.find(function (d) { return d.type === 'PAYMENT_METHOD'; })) === null || _b === void 0 ? void 0 : _b.total;
                var totalAmountWithAch = bankAccounts_2 && achDiscount
                    ? totalAmount + Math.abs(achDiscount)
                    : totalAmount;
                var balAmount = Number((totalAmountWithAch - pm.amount).toFixed(2));
                if (balAmount <= availableCredit && evolusCredit_1) {
                    paymentmethod.push(__assign(__assign({}, evolusCredit_1), { amount: balAmount }));
                }
                else {
                    setIsAmountEnteredValid(false);
                    setAmountValidation('Remaining balance is larger than available Evolus Credit.');
                }
            }
            else if (pm.amount && pm.amount > totalAmount) {
                setIsAmountEnteredValid(false);
                setAmountValidation('Amount inputted is more than order total.');
            }
        }
        onSelect && onSelect(paymentmethod);
    };
    var _l = useMemo(function () {
        var methods = {
            bankAccounts: [],
            creditCards: [],
            evolusCredit: null,
        };
        paymentMethods === null || paymentMethods === void 0 ? void 0 : paymentMethods.forEach(function (method) {
            var _a;
            var type = (_a = method === null || method === void 0 ? void 0 : method.sensitive) === null || _a === void 0 ? void 0 : _a.type;
            if (type === SensitivePaymentType.Cc) {
                methods.creditCards.push(method);
            }
            else if (type === SensitivePaymentType.Ach) {
                methods.bankAccounts.push(method);
            }
            else if (type === SensitivePaymentType.Loc) {
                methods.evolusCredit = method;
            }
        });
        return methods;
    }, [paymentMethods]), bankAccounts = _l.bankAccounts, creditCards = _l.creditCards, evolusCredit = _l.evolusCredit;
    var renderPromoEndAdornment = function () {
        if (!isMobile) {
            return null;
        }
        if (promoApplied && promoLoading) {
            return React.createElement(AdornmentLoader, { size: 20, name: "loading" });
        }
        if (promoApplied && !preapplyCode) {
            return (React.createElement(ClearableCloseButton, { variant: "tertiary", onClick: handlePromoClick },
                React.createElement(Icon, { color: "#fff", size: 12, name: "close" })));
        }
    };
    var handlePromoClick = useCallback(function () {
        if (promoApplied && onRemovePromo) {
            openModal(ModalTypeEnum.REMOVE_PROMO_CODE, {
                onRemove: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4, onRemovePromo()];
                            case 1:
                                _a.sent();
                                if (isMounted) {
                                    setPromoCode('');
                                }
                                return [2];
                        }
                    });
                }); },
            });
            return;
        }
        if (validatePromo)
            validatePromo(promoCode);
    }, [
        validatePromo,
        openModal,
        onRemovePromo,
        promoApplied,
        promoCode,
        isMounted,
    ]);
    var handleEdit = useCallback(function (method) {
        openModal(ModalTypeEnum.EDIT_PAYMENT_METHOD, {
            paymentMethod: method,
            onEdit: function () {
                closeModal();
            },
        });
    }, [openModal, closeModal]);
    var handleRemove = useCallback(function (paymentMethodId) {
        openModal(ModalTypeEnum.REMOVE_PAYMENT_METHOD, {
            paymentMethodId: paymentMethodId,
            onRemove: function () {
                closeModal();
            },
        });
    }, [openModal, closeModal]);
    if (loading) {
        return (React.createElement(LoadingContainer, null,
            React.createElement(PlaceholderLoading, { size: "small" })));
    }
    var hasEvolusCredit = !!((_b = evolusCredit === null || evolusCredit === void 0 ? void 0 : evolusCredit.sensitive) === null || _b === void 0 ? void 0 : _b.loc);
    var canUseEvolusCredit = ((_c = evolusCredit === null || evolusCredit === void 0 ? void 0 : evolusCredit.sensitive) === null || _c === void 0 ? void 0 : _c.loc) &&
        estimatedOrderTotal &&
        estimatedOrderTotal <=
            ((_e = (_d = evolusCredit === null || evolusCredit === void 0 ? void 0 : evolusCredit.sensitive) === null || _d === void 0 ? void 0 : _d.loc) === null || _e === void 0 ? void 0 : _e.limit) -
                ((_g = (_f = evolusCredit === null || evolusCredit === void 0 ? void 0 : evolusCredit.sensitive) === null || _f === void 0 ? void 0 : _f.loc) === null || _g === void 0 ? void 0 : _g.balance);
    var isInAccountSettings = location.pathname === '/settings/payments';
    return (React.createElement("div", null,
        React.createElement(PaymentMethodSectionContainer, { "data-automation-id": "order-payment-method-credit-card-container" },
            !isInAccountSettings && !fromSelectPayment && (React.createElement(PromoContainer, null,
                React.createElement(Typography, { variant: "headline", color: "alt" }, "Promo Code (optional)"),
                React.createElement(PromoCodeInputContainer, null,
                    React.createElement(Input, { disabled: promoApplied || loading, value: promoCode, onChange: function (e) {
                            setPromoCode(e.target.value);
                        }, variant: "outline", placeholder: "e.g. PROMO22", endAdornment: renderPromoEndAdornment() }),
                    renderPromoButton && (React.createElement(Button, { variant: isMobile ? 'outlined' : 'primary', fullWidth: isMobile, size: "large", disabled: loading, onClick: handlePromoClick }, promoApplied ? 'REMOVE' : 'APPLY'))),
                message && (React.createElement(MessageContainer, { isValid: !!isValid },
                    isValid ? React.createElement(Icon, { name: "round-check" }) : React.createElement(Icon, { name: "alert" }),
                    React.createElement(Typography, { color: isValid ? 'success' : 'link' }, message))))),
            React.createElement(SectionHeaderContainer, null,
                React.createElement(Typography, { variant: "headline", color: "alt" }, "Credit Cards"),
                React.createElement(AddPaymentMethodButton, { onClick: function () {
                        return openModal(ModalTypeEnum.ADD_CREDIT_CARD, {});
                    } },
                    React.createElement(Desktop, null, "+ Add New Credit or Debit Card"),
                    React.createElement(Mobile, null, "+ New Card"))),
            (creditCards === null || creditCards === void 0 ? void 0 : creditCards.length) ? (creditCards === null || creditCards === void 0 ? void 0 : creditCards.map(function (card) {
                var _a;
                return (React.createElement(PaymentMethodItem, { key: card.paymentMethodId, selectable: !!onSelect && !isMobile, paymentMethod: card, selectedPaymentMethod: selectedPaymentMethod, onSelect: function (pm) {
                        return fromSelectPayment && onSelect
                            ? onSelect([pm])
                            : onPaymentMethodSelect(pm);
                    }, editable: editable, onEdit: handleEdit, onRemove: handleRemove, isValidAmount: isAmountEnteredValid, hasEvolusCredit: hasEvolusCredit, amountValiation: amountValiation, fromSelectPayment: fromSelectPayment, totalAmount: (_a = calculatedPricing === null || calculatedPricing === void 0 ? void 0 : calculatedPricing.inputDisplay) === null || _a === void 0 ? void 0 : _a.totalEstimated, calculatedPricingLoading: calculatedPricingLoading, isPromoValid: isValid }));
            })) : (React.createElement(NoPaymentMethodContainer, null,
                React.createElement(Typography, null, "No Credit Cards Added")))),
        React.createElement(PaymentMethodSectionContainer, { "data-automation-id": "order-payment-method-bank-account-container" },
            React.createElement(SectionHeaderContainer, null,
                React.createElement(BankAccountHeaderTextContainer, null,
                    React.createElement(Typography, { variant: "headline", color: "alt" }, "Bank Accounts"),
                    !hideDiscount && (React.createElement(Desktop, null,
                        React.createElement(Typography, { variant: "footnote", color: "alt" }, "A 2% discount will be applied when paying the entire order with a bank account.")))),
                React.createElement(AddPaymentMethodButton, { onClick: function () {
                        return openModal(ModalTypeEnum.ADD_BANK_ACCOUNT, {});
                    } },
                    React.createElement(Desktop, null, "+ Add a Bank Account"),
                    React.createElement(Mobile, null, "+ New Account"))),
            (bankAccounts === null || bankAccounts === void 0 ? void 0 : bankAccounts.length) ? (bankAccounts === null || bankAccounts === void 0 ? void 0 : bankAccounts.map(function (bank) {
                var _a;
                return (React.createElement(PaymentMethodItem, { selectable: !!onSelect && !isMobile, paymentMethod: bank, selectedPaymentMethod: selectedPaymentMethod, onSelect: function (pm) {
                        return fromSelectPayment && onSelect
                            ? onSelect([pm])
                            : onPaymentMethodSelect(pm);
                    }, editable: editable, onEdit: handleEdit, onRemove: handleRemove, isValidAmount: isAmountEnteredValid, hasEvolusCredit: hasEvolusCredit, amountValiation: amountValiation, fromSelectPayment: fromSelectPayment, totalAmount: (_a = calculatedPricing === null || calculatedPricing === void 0 ? void 0 : calculatedPricing.inputDisplay) === null || _a === void 0 ? void 0 : _a.totalEstimated, calculatedPricingLoading: calculatedPricingLoading, isPromoValid: isValid }));
            })) : (React.createElement(NoPaymentMethodContainer, null,
                React.createElement(Typography, null, "No Bank Accounts Added"))),
            !hideDiscount && (React.createElement(Mobile, null,
                React.createElement(DiscountContainer, null,
                    React.createElement(Typography, { variant: "footnote", color: "alt" }, "A 2% discount will be applied when paying the entire order with a bank account."))))),
        !hideCredit && (React.createElement(PaymentMethodSectionContainer, { "data-automation-id": "order-payment-method-evolus-credit-container" },
            React.createElement(SectionHeaderContainer, null,
                React.createElement(Typography, { variant: "headline", color: "alt" }, "Evolus Credit"),
                React.createElement(AddPaymentMethodButton, { onClick: function () { } },
                    React.createElement(Desktop, null,
                        "+ Request ",
                        evolusCredit ? 'More' : 'Evolus',
                        " Credit"),
                    React.createElement(Mobile, null,
                        "+ Request ",
                        evolusCredit ? 'More' : 'Evolus',
                        " Credit"))),
            hasEvolusCredit ? (React.createElement(React.Fragment, null,
                !canUseEvolusCredit && !isInAccountSettings && (React.createElement(Typography, { color: "link", mt: 12, mb: 12 }, "Your estimated order total exceeds available credit.")),
                React.createElement(PaymentMethodItem, { selectable: !!onSelect && !isMobile && !!canUseEvolusCredit, paymentMethod: evolusCredit, selectedPaymentMethod: selectedPaymentMethod, onSelect: canUseEvolusCredit ? onPaymentMethodSelect : function () { }, fromSelectPayment: fromSelectPayment }))) : (React.createElement(NoPaymentMethodContainer, null,
                React.createElement(Typography, null, "You have not enrolled for Evolus Credit")))))));
};
var NoPaymentMethodContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 16px 24px;\n  border: 1px solid ", ";\n  display: flex;\n  margin-top: 8px;\n  justify-content: center;\n  align-items: center;\n"], ["\n  padding: 16px 24px;\n  border: 1px solid ", ";\n  display: flex;\n  margin-top: 8px;\n  justify-content: center;\n  align-items: center;\n"])), function (props) { return props.theme.colors.border.base; });
var SectionHeaderContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  color: ", ";\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  color: ", ";\n"])), function (props) { return props.theme.colors.text.alt; });
var BankAccountHeaderTextContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
var PaymentMethodSectionContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-bottom: 48px;\n\n  @media only screen and (max-width: ", "px) {\n    &:last-child {\n      margin-bottom: 32px;\n    }\n  }\n"], ["\n  margin-bottom: 48px;\n\n  @media only screen and (max-width: ", "px) {\n    &:last-child {\n      margin-bottom: 32px;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var AddPaymentMethodButton = styled.button(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  all: unset;\n  cursor: pointer;\n  background-color: white;\n  font-size: 0.725rem;\n  color: ", ";\n  font-weight: 500;\n  border: 1px solid ", ";\n  padding: 8px 12px;\n"], ["\n  all: unset;\n  cursor: pointer;\n  background-color: white;\n  font-size: 0.725rem;\n  color: ", ";\n  font-weight: 500;\n  border: 1px solid ", ";\n  padding: 8px 12px;\n"])), function (props) { return props.theme.colors.text.link; }, function (props) { return props.theme.colors.border.alert; });
var LoadingContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex: 1;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex: 1;\n"])));
var DiscountContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin-top: 8px;\n"], ["\n  margin-top: 8px;\n"])));
var PromoContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  margin: 48px 0 32px;\n"], ["\n  margin: 48px 0 32px;\n"])));
var PromoCodeInputContainer = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  flex: 1;\n  gap: 24px;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n    margin-bottom: 16px;\n  }\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  flex: 1;\n  gap: 24px;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n    margin-bottom: 16px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var AdornmentLoader = styled(Icon)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  position: absolute;\n  top: 50%;\n  right: 12px;\n  transform: translateY(-50%);\n"], ["\n  position: absolute;\n  top: 50%;\n  right: 12px;\n  transform: translateY(-50%);\n"])));
var ClearableCloseButton = styled(IconButton)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  position: absolute;\n  top: 50%;\n  right: 12px;\n  transform: translateY(-50%);\n  background: #333333;\n  color: ", ";\n  padding: 4px;\n  & > span {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n"], ["\n  position: absolute;\n  top: 50%;\n  right: 12px;\n  transform: translateY(-50%);\n  background: #333333;\n  color: ", ";\n  padding: 4px;\n  & > span {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n"])), function (props) { return props.theme.colors.basic.white; });
var MessageContainer = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  background: ", ";\n\n  padding: 12px 24px;\n  gap: 8px;\n  & > span:first-of-type {\n    display: inline-flex;\n    align-items: center;\n    justify-content: center;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  background: ", ";\n\n  padding: 12px 24px;\n  gap: 8px;\n  & > span:first-of-type {\n    display: inline-flex;\n    align-items: center;\n    justify-content: center;\n  }\n"])), function (props) {
    return props.isValid
        ? props.theme.colors.bg.success2
        : props.theme.colors.bg.error;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { format } from 'date-fns';
import { parseISO } from 'date-fns/esm';
import { Button, Typography } from 'designSystem';
import React from 'react';
import { useNavigate } from 'react-router';
import OrderPricingDetails from 'scenes/Orders/Tabs/OrderSummary/components/OrderPricingDetails';
import styled from 'styled-components';
import { convertMilitaryToStandardTime } from 'utils/convertMilitaryToStandardTime';
import { currencyFormatter } from 'utils/currencyFormatter';
function getPaymentMethodDescription(paymentMethod) {
    if (!paymentMethod)
        return null;
    if (paymentMethod.sensitive.ach) {
        return paymentMethod.sensitive.ach.accountNumber.replace(/.(?=.{4,}$)/g, '*');
    }
    if (paymentMethod.sensitive.cc) {
        return paymentMethod.sensitive.cc.number.replace(/.(?=.{4,}$)/g, '*');
    }
    if (paymentMethod.sensitive.loc) {
        return 'Evolus Credit';
    }
    return null;
}
export var OrderConfirmation = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0;
    var order = _a.order, orderDisplay = _a.orderDisplay;
    var navigate = useNavigate();
    if (order && !(order === null || order === void 0 ? void 0 : order.id)) {
        navigate('/orders/buy?step=quantitySelection');
        return null;
    }
    var renderPrimaryCardAmount = function () {
        var _a, _b, _c;
        return ((_a = order === null || order === void 0 ? void 0 : order.billing) === null || _a === void 0 ? void 0 : _a.secondaryCardAmount)
            ? currencyFormatter((_b = order === null || order === void 0 ? void 0 : order.billing) === null || _b === void 0 ? void 0 : _b.secondaryCardAmount, false)
            : currencyFormatter((_c = order === null || order === void 0 ? void 0 : order.billing) === null || _c === void 0 ? void 0 : _c.primaryCardAmount, false);
    };
    var renderSecondaryCardAmount = function () {
        var _a, _b;
        return ((_a = order === null || order === void 0 ? void 0 : order.billing) === null || _a === void 0 ? void 0 : _a.secondaryCardAmount)
            ? currencyFormatter((_b = order === null || order === void 0 ? void 0 : order.billing) === null || _b === void 0 ? void 0 : _b.primaryCardAmount, false)
            : '$0.00';
    };
    return (React.createElement("div", { style: {
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '24px',
            flexDirection: 'column',
        } },
        React.createElement(OuterContainer, null,
            React.createElement(OrderContent, null,
                React.createElement(OrderSection, null,
                    React.createElement(Typography, { style: { marginBottom: '32px' }, variant: "sectionHeader" }, "ORDER SUMMARY"),
                    React.createElement(OrderLargeLine, null,
                        React.createElement(OrderLargeLineLeft, null,
                            React.createElement(Typography, { variant: "headline", color: "alt" }, "Order Number")),
                        React.createElement(OrderLargeLineRight, null,
                            React.createElement(Typography, { variant: "headline" }, order === null || order === void 0 ? void 0 : order.id))),
                    React.createElement(OrderLargeLine, null,
                        React.createElement(OrderLargeLineLeft, null,
                            React.createElement(Typography, { variant: "headline", color: "alt" }, "Shipping Address")),
                        React.createElement(OrderLargeLineRight, null,
                            React.createElement(Typography, { variant: "headline" }, (_b = order === null || order === void 0 ? void 0 : order.facility) === null || _b === void 0 ? void 0 : _b.name),
                            ((_d = (_c = order === null || order === void 0 ? void 0 : order.facility) === null || _c === void 0 ? void 0 : _c.shippingAddress) === null || _d === void 0 ? void 0 : _d.careOf) && (React.createElement(Typography, null, (_f = (_e = order === null || order === void 0 ? void 0 : order.facility) === null || _e === void 0 ? void 0 : _e.shippingAddress) === null || _f === void 0 ? void 0 : _f.careOf)),
                            React.createElement(Typography, null, (_h = (_g = order === null || order === void 0 ? void 0 : order.shipping) === null || _g === void 0 ? void 0 : _g.address) === null || _h === void 0 ? void 0 : _h.addressLine1),
                            React.createElement(Typography, null, (_k = (_j = order === null || order === void 0 ? void 0 : order.shipping) === null || _j === void 0 ? void 0 : _j.address) === null || _k === void 0 ? void 0 :
                                _k.city,
                                ",",
                                ' ', (_m = (_l = order === null || order === void 0 ? void 0 : order.shipping) === null || _l === void 0 ? void 0 : _l.address) === null || _m === void 0 ? void 0 :
                                _m.state,
                                ' ', (_p = (_o = order === null || order === void 0 ? void 0 : order.shipping) === null || _o === void 0 ? void 0 : _o.address) === null || _p === void 0 ? void 0 :
                                _p.zip))),
                    React.createElement(OrderLargeLine, null,
                        React.createElement(OrderLargeLineLeft, null,
                            React.createElement(Typography, { variant: "headline", color: "alt" }, "Delivery")),
                        React.createElement(OrderLargeLineRight, null,
                            React.createElement(Typography, { variant: "headline" },
                                format(parseISO((_r = (_q = order === null || order === void 0 ? void 0 : order.shipping) === null || _q === void 0 ? void 0 : _q.method) === null || _r === void 0 ? void 0 : _r.requestedDeliveryDate), 'EEEE, LLLL d'),
                                ' ',
                                React.createElement(Typography, { variant: "headline" },
                                    'by',
                                    ' ',
                                    convertMilitaryToStandardTime(((_t = (_s = order === null || order === void 0 ? void 0 : order.shipping) === null || _s === void 0 ? void 0 : _s.method) === null || _t === void 0 ? void 0 : _t.requestedDeliveryTime) || ''))),
                            React.createElement(Typography, null, (_v = (_u = order === null || order === void 0 ? void 0 : order.shipping) === null || _u === void 0 ? void 0 : _u.method) === null || _v === void 0 ? void 0 : _v.serviceName))),
                    React.createElement(OrderLargeLine, null,
                        React.createElement(OrderLargeLineLeft, null,
                            React.createElement(Typography, { variant: "headline", color: "alt" }, "Payment Method")),
                        React.createElement(OrderLargeLine, null,
                            React.createElement(OrderLargeLineLeft, null,
                                React.createElement(Typography, { variant: "headline" }, (_x = (_w = order === null || order === void 0 ? void 0 : order.billing) === null || _w === void 0 ? void 0 : _w.paymentMethod) === null || _x === void 0 ? void 0 : _x.name),
                                React.createElement(Typography, null, getPaymentMethodDescription((_y = order === null || order === void 0 ? void 0 : order.billing) === null || _y === void 0 ? void 0 : _y.paymentMethod))),
                            ((_z = order === null || order === void 0 ? void 0 : order.billing) === null || _z === void 0 ? void 0 : _z.secondaryPaymentMethodId) && (React.createElement(OrderLargeLineRight, null,
                                React.createElement(Typography, { variant: "headline" }, renderPrimaryCardAmount()))))),
                    ((_0 = order === null || order === void 0 ? void 0 : order.billing) === null || _0 === void 0 ? void 0 : _0.secondaryPaymentMethodId) && (React.createElement(OrderLargeLine, null,
                        React.createElement(OrderLargeLineLeft, null,
                            React.createElement(Typography, { variant: "headline", color: "alt" }, "Payment Method")),
                        React.createElement(OrderLargeLine, null,
                            React.createElement(OrderLargeLineLeft, null,
                                React.createElement(Typography, { variant: "headline" }, "Evolus Credit")),
                            React.createElement(OrderLargeLineRight, null,
                                React.createElement(Typography, { variant: "headline" }, renderSecondaryCardAmount()))))),
                    (order === null || order === void 0 ? void 0 : order.referenceNumber) && (React.createElement(OrderLargeLine, null,
                        React.createElement(OrderLargeLineLeft, null,
                            React.createElement(Typography, { variant: "headline", color: "alt" }, "Reference Number")),
                        React.createElement(OrderLargeLineRight, null,
                            React.createElement(Typography, null, order === null || order === void 0 ? void 0 : order.referenceNumber)))),
                    React.createElement(OrderDivider, { style: { marginBottom: '48px', width: '100%' } }),
                    React.createElement("div", { style: {
                            flex: 1,
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                        } },
                        React.createElement(OrderPricingDetails, { loading: false, order: order, orderDisplay: orderDisplay }))))),
        React.createElement(BackButtonContainer, null,
            React.createElement(Button, { onClick: function () { return navigate('/orders'); }, variant: "outlined", "data-automation-id": "order-confirm-back-to-dashboard" }, "Back to Order Dashboard"))));
};
var OuterContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n\n  position: relative;\n  max-width: 916px;\n  width: 100%;\n  margin-bottom: 32px;\n\n  @media only screen and (max-width: ", "px) {\n    width: auto;\n    margin-left: -32px;\n    margin-right: -32px;\n    background-color: ", ";\n  }\n"], ["\n  background-color: ", ";\n\n  position: relative;\n  max-width: 916px;\n  width: 100%;\n  margin-bottom: 32px;\n\n  @media only screen and (max-width: ", "px) {\n    width: auto;\n    margin-left: -32px;\n    margin-right: -32px;\n    background-color: ", ";\n  }\n"])), function (props) { return props.theme.colors.bg.success2; }, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
}, function (props) { return props.theme.colors.basic.white; });
var OrderContent = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 64px 128px;\n  max-width: 916px;\n\n  @media only screen and (max-width: ", "px) {\n    padding: 32px;\n  }\n"], ["\n  padding: 64px 128px;\n  max-width: 916px;\n\n  @media only screen and (max-width: ", "px) {\n    padding: 32px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var OrderSection = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  align-items: flex-start;\n  justify-content: center;\n  width: 100%;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex: 1;\n  align-items: flex-start;\n  justify-content: center;\n  width: 100%;\n  flex-direction: column;\n"])));
var OrderLargeLine = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: flex-start;\n  justify-content: flex-start;\n  flex: 3;\n  width: 100%;\n\n  &:not(:last-child) {\n    margin-bottom: 48px;\n  }\n"], ["\n  display: flex;\n  align-items: flex-start;\n  justify-content: flex-start;\n  flex: 3;\n  width: 100%;\n\n  &:not(:last-child) {\n    margin-bottom: 48px;\n  }\n"])));
var OrderLargeLineLeft = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  flex: 2;\n\n  @media only screen and (max-width: ", "px) {\n    width: 120px;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  flex: 2;\n\n  @media only screen and (max-width: ", "px) {\n    width: 120px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var OrderLargeLineRight = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  flex: 3;\n"], ["\n  display: flex;\n  flex-direction: column;\n  flex: 3;\n"])));
var OrderDivider = styled.hr(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  border: 1px solid ", ";\n"], ["\n  border: 1px solid ", ";\n"])), function (props) { var _a; return (_a = props.theme.colors.border) === null || _a === void 0 ? void 0 : _a.success2; });
var BackButtonContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  align-self: flex-end;\n  margin-right: 96px;\n\n  @media only screen and (max-width: ", "px) {\n    margin: auto;\n    align-self: center;\n  }\n"], ["\n  display: flex;\n  align-self: flex-end;\n  margin-right: 96px;\n\n  @media only screen and (max-width: ", "px) {\n    margin: auto;\n    align-self: center;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;

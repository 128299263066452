var LevelBenefitEnums;
(function (LevelBenefitEnums) {
    LevelBenefitEnums["DIAMOND_MARKETING"] = "DIAMOND_MARKETING";
    LevelBenefitEnums["ELITE_MARKETING"] = "ELITE_MARKETING";
    LevelBenefitEnums["ELITE_PLUS_MARKETING"] = "ELITE_PLUS_MARKETING";
    LevelBenefitEnums["EVOLUS_PROFILE"] = "EVOLUS_PROFILE";
    LevelBenefitEnums["EVOLUS_REWARDS_NINE"] = "EVOLUS_REWARDS_NINE";
    LevelBenefitEnums["EVOLUS_REWARDS_TEN"] = "EVOLUS_REWARDS_TEN";
    LevelBenefitEnums["EVOLUS_REWARDS_TWELVE"] = "EVOLUS_REWARDS_TWELVE";
    LevelBenefitEnums["EVOLUS_REWARDS_EIGHT"] = "EVOLUS_REWARDS_EIGHT";
    LevelBenefitEnums["OFFICE_MARKETING"] = "OFFICE_MARKETING";
    LevelBenefitEnums["PLATINUM_MARKETING"] = "PLATINUM_MARKETING";
    LevelBenefitEnums["EXFLUENTIAL"] = "EXFLUENTIAL";
    LevelBenefitEnums["PLACEHOLDER"] = "PLACEHOLDER";
    LevelBenefitEnums["SWAG"] = "SWAG";
    LevelBenefitEnums["MARKETING_INSIGHTS"] = "MARKETING_INSIGHTS";
    LevelBenefitEnums["DIGITAL_ACCELERATOR"] = "DIGITAL_ACCELERATOR";
})(LevelBenefitEnums || (LevelBenefitEnums = {}));
export default LevelBenefitEnums;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { AccountPaymentMethods } from 'components/AccountPaymentMethods';
import { Typography } from 'designSystem';
export var PaymentMethodsSettings = function () {
    return (React.createElement(OuterContainer, null,
        React.createElement(InnerContainer, null,
            React.createElement(Typography, { mb: 16, display: "block", variant: "titleLarge" }, "Payment Methods"),
            React.createElement(Typography, { mb: 32, display: "block", color: "alt" }, "Payment methods for this account."),
            React.createElement(AccountPaymentMethods, { editable: true }))));
};
var OuterContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  box-sizing: border-box;\n  padding: 48px 48px 60px;\n  margin-top: 20px;\n  flex: 1;\n  width: 100%;\n  @media only screen and (max-width: ", "px) {\n    padding: 32px;\n    margin: 16px 0 0 0;\n  }\n"], ["\n  ", ";\n  box-sizing: border-box;\n  padding: 48px 48px 60px;\n  margin-top: 20px;\n  flex: 1;\n  width: 100%;\n  @media only screen and (max-width: ", "px) {\n    padding: 32px;\n    margin: 16px 0 0 0;\n  }\n"])), function (props) { return props.theme.card.default; }, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var InnerContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 48px;\n  ", ";\n\n  width: 100%;\n  @media only screen and (max-width: ", "px) {\n    border: none;\n    padding: 0;\n  }\n"], ["\n  padding: 48px;\n  ", ";\n\n  width: 100%;\n  @media only screen and (max-width: ", "px) {\n    border: none;\n    padding: 0;\n  }\n"])), function (props) { return props.theme.borders.base; }, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var templateObject_1, templateObject_2;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { PhoneInput } from './components/PhoneInput';
import { Button, Desktop, Icon, Mobile, Typography } from 'designSystem';
import CheckInGraphic from 'assets/check-in-graphic-1.svg';
import CheckInProgress from '../../CheckInProgress';
import { StepContainer } from '../../StepContainer';
import { customBreakpointStyles } from 'utils/customBreakpointStyles';
var oneToNine = new Array(9).fill(function (_v, i) {
    return i + 1;
});
export var PhoneNumberInputView = function (_a) {
    var onUpdatePhone = _a.onUpdatePhone, phoneNumber = _a.phoneNumber, loading = _a.loading, error = _a.error, onContinue = _a.onContinue;
    var _b = __read(useState({
        0: null,
        1: null,
        2: null,
        3: null,
        4: null,
        5: null,
        6: null,
        7: null,
        8: null,
        9: null,
    }), 2), numberObj = _b[0], setNumberObj = _b[1];
    var _c = __read(useMemo(function () {
        var objKeys = Object.keys(numberObj);
        var emptyInputIndex = objKeys.findIndex(function (item) { return !numberObj[item]; });
        var previousInputIndex = objKeys.length - 1;
        if (emptyInputIndex === -1) {
            return [previousInputIndex, previousInputIndex];
        }
        else {
            var backspaceIndex = emptyInputIndex === 0 ? 0 : emptyInputIndex - 1;
            return [emptyInputIndex, backspaceIndex];
        }
    }, [numberObj]), 2), keyToOverride = _c[0], backspaceOverrideKey = _c[1];
    return (React.createElement(React.Fragment, null,
        React.createElement(StepContainer, { hideRestart: true },
            React.createElement(InnerContainer, null,
                React.createElement(LeftContainer, null,
                    React.createElement(Typography, { mb: 16, variant: "titleLarge" }, "Enter Mobile Number"),
                    React.createElement(Typography, { mb: 32, color: "alt" }, "Enter the mobile phone number of the patient whose reward status you want to check."),
                    React.createElement(PhoneInput, { value: numberObj, onNumberInput: setNumberObj, onChange: onUpdatePhone, error: error }),
                    React.createElement(Mobile, null,
                        React.createElement(NumberPadContainer, null,
                            oneToNine.map(function (_n, i) { return (React.createElement(Button, { key: i, onClick: function () {
                                    var _a;
                                    return setNumberObj(__assign(__assign({}, numberObj), (_a = {}, _a[keyToOverride] = "".concat(i + 1), _a)));
                                }, variant: "secondary", "data-automation-id": "rewards-phone-btn-".concat(i + 1) },
                                React.createElement(Typography, { variant: "titleLarge" }, i + 1))); }),
                            React.createElement(Button, { onClick: function () { }, variant: "secondary", disabled: true }),
                            React.createElement(Button, { onClick: function () {
                                    var _a;
                                    return setNumberObj(__assign(__assign({}, numberObj), (_a = {}, _a[keyToOverride] = '0', _a)));
                                }, variant: "secondary", "data-automation-id": "rewards-phone-btn-0" },
                                React.createElement(Typography, { variant: "titleLarge" }, "0")),
                            React.createElement(DeleteButton, { onClick: function () {
                                    var _a;
                                    return setNumberObj(__assign(__assign({}, numberObj), (_a = {}, _a[backspaceOverrideKey] = '', _a)));
                                }, variant: "secondary", "data-automation-id": "rewards-phone-btn-clear" },
                                React.createElement(Icon, { size: 32, name: "delete" }))))),
                React.createElement(Desktop, null,
                    React.createElement(RightContainer, null,
                        React.createElement(CheckInImage, { src: CheckInGraphic }))))),
        React.createElement(CheckInProgress, { primaryButtonText: "Check Status", onContinue: onContinue, disabled: (phoneNumber === null || phoneNumber === void 0 ? void 0 : phoneNumber.length) !== 10, isLoading: loading })));
};
var InnerContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n\n  border: 1px solid ", ";\n  padding: 32px;\n  max-height: 40vh;\n  align-items: center;\n  @media screen and (max-width: ", "px) {\n    border: none;\n    padding: 0;\n    max-height: unset;\n    flex: 1;\n    align-items: unset;\n    margin-bottom: 32px;\n  }\n"], ["\n  display: flex;\n\n  border: 1px solid ", ";\n  padding: 32px;\n  max-height: 40vh;\n  align-items: center;\n  @media screen and (max-width: ", "px) {\n    border: none;\n    padding: 0;\n    max-height: unset;\n    flex: 1;\n    align-items: unset;\n    margin-bottom: 32px;\n  }\n"])), function (props) { return props.theme.colors.border.base; }, function (props) { return props.theme.breakpoints.md; });
var NumberPadContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: auto;\n  display: grid;\n  grid-template-columns: repeat(3, 1fr);\n  gap: 8px;\n  flex: 1;\n"], ["\n  margin-top: auto;\n  display: grid;\n  grid-template-columns: repeat(3, 1fr);\n  gap: 8px;\n  flex: 1;\n"])));
var LeftContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex: 2;\n  justify-content: center;\n  flex-direction: column;\n  margin-right: 32px;\n  @media screen and (max-width: ", "px) {\n    margin-right: 0;\n    flex: 1;\n  }\n  ", "\n"], ["\n  display: flex;\n  flex: 2;\n  justify-content: center;\n  flex-direction: column;\n  margin-right: 32px;\n  @media screen and (max-width: ", "px) {\n    margin-right: 0;\n    flex: 1;\n  }\n  ", "\n"])), function (props) { return props.theme.breakpoints.md; }, customBreakpointStyles(1150, css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      margin-right: 0;\n    "], ["\n      margin-right: 0;\n    "])))));
var DeleteButton = styled(Button)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  & > span {\n    height: 32px;\n    display: flex;\n    align-items: center;\n  }\n"], ["\n  & > span {\n    height: 32px;\n    display: flex;\n    align-items: center;\n  }\n"])));
var RightContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  align-items: center;\n  justify-content: center;\n  ", "\n"], ["\n  display: flex;\n  flex: 1;\n  align-items: center;\n  justify-content: center;\n  ", "\n"])), customBreakpointStyles(1150, css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n      display: none;\n    "], ["\n      display: none;\n    "])))));
var CheckInImage = styled.img(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  max-width: 100%;\n  max-height: 100%;\n"], ["\n  max-width: 100%;\n  max-height: 100%;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;

import React from 'react';
export var Switch = function (_a) {
    var color = _a.color;
    return (React.createElement("svg", { width: "32", height: "32", viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M7 18.9973V24.9973H1", stroke: color, strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M25 12.9973V6.99731H31", stroke: color, strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M25.376 6.99341C27.4449 9.14884 28.7084 11.951 28.954 14.9286C29.1996 17.9062 28.4123 20.8775 26.7246 23.3428C25.0368 25.8081 22.5515 27.617 19.6868 28.4652C16.822 29.3134 13.7525 29.1491 10.9946 28.0001", stroke: color, strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M6.62261 25.0013C4.55819 22.8448 3.29863 20.0436 3.05544 17.0682C2.81225 14.0927 3.60025 11.1242 5.2871 8.6611C6.97396 6.19798 9.45695 4.39027 12.3191 3.54151C15.1813 2.69275 18.2484 2.85465 21.0053 4", stroke: color, strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" })));
};
export default Switch;

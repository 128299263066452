import { SensitivePaymentType } from 'types/codegen/hooks';
var sortByAmount = function (arr, sortOrder) {
    if (sortOrder === void 0) { sortOrder = 'asc'; }
    arr.sort(function (a, b) {
        var comparison = a.amount - b.amount;
        if (sortOrder === 'desc') {
            return comparison * -1;
        }
        return comparison;
    });
    return arr;
};
var sortByStatus = function (arr, sortOrder) {
    if (sortOrder === void 0) { sortOrder = 'asc'; }
    arr.sort(function (a, b) {
        var statusA = a.status.toLowerCase();
        var statusB = b.status.toLowerCase();
        if (statusA < statusB) {
            return sortOrder === 'asc' ? -1 : 1;
        }
        if (statusA > statusB) {
            return sortOrder === 'asc' ? 1 : -1;
        }
        return 0;
    });
    return arr;
};
var sortByDate = function (arr, sortOrder) {
    if (sortOrder === void 0) { sortOrder = 'asc'; }
    arr.sort(function (a, b) {
        var dateA = new Date(a.date);
        var dateB = new Date(b.date);
        var comparison = dateA.getTime() - dateB.getTime();
        if (sortOrder === 'desc') {
            return comparison * -1;
        }
        return comparison;
    });
    return arr;
};
var getPaymentName = function (ip) {
    if ('displayName' in ip.paymentDetail) {
        return ip.paymentDetail.displayName;
    }
    var detail = ip.paymentDetail;
    if (detail.sensitive.type === SensitivePaymentType.Ach) {
        return detail.sensitive.ach.accountName;
    }
    return detail.sensitive.cc.network;
};
var getPaymentType = function (ip) {
    if ('displayName' in ip.paymentDetail) {
        return 'External';
    }
    var detail = ip.paymentDetail;
    return detail.sensitive.type;
};
var sortByPayment = function (arr, sortOrder) {
    if (sortOrder === void 0) { sortOrder = 'asc'; }
    arr.sort(function (a, b) {
        var typeA = getPaymentType(a);
        var typeB = getPaymentType(b);
        var sortOrdMultiplier = sortOrder === 'asc' ? 1 : -1;
        if (typeA < typeB) {
            return -1 * sortOrdMultiplier;
        }
        if (typeA > typeB) {
            return 1 * sortOrdMultiplier;
        }
        var nameA = getPaymentName(a);
        var nameB = getPaymentName(b);
        if (nameA < nameB) {
            return -1 * sortOrdMultiplier;
        }
        if (nameA > nameB) {
            return 1 * sortOrdMultiplier;
        }
        return 0;
    });
    return arr;
};
export var chunkArray = function (arr, chunkSize) {
    if (chunkSize === void 0) { chunkSize = 8; }
    var chunks = [];
    for (var i = 0; i < arr.length; i += chunkSize) {
        chunks.push(arr.slice(i, i + chunkSize));
    }
    return chunks;
};
export var handleSort = function (arr, sortBy, sortOrder) {
    if (sortOrder === void 0) { sortOrder = 'asc'; }
    switch (sortBy) {
        case 'amount':
            return sortByAmount(arr, sortOrder);
        case 'status':
            return sortByStatus(arr, sortOrder);
        case 'date':
            return sortByDate(arr, sortOrder);
        case 'payment':
            return sortByPayment(arr, sortOrder);
        default:
            return arr;
    }
};

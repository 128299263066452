var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { PaymentProgress } from '../PaymentProgress';
import { StepContainer } from '../StepContainer';
import { SubHeaderByOption } from '../../enums/SubHeaderByOption';
import styled, { css } from 'styled-components';
import { Typography } from 'designSystem';
import { SensitivePaymentType } from 'types/types';
import { currencyFormatter } from 'utils/currencyFormatter';
import PayInvoiceGraphic from 'assets/bills-payment.svg';
import { useScreenSizing } from '../../../../../../hooks';
import { MobileContinueButton } from '../MobileContinueButton';
import { addMobileStyles } from '../../../../../../utils/addMobileStyles';
import { PaymentMethodType, getSelectedPaymentMethod, } from '../../../../../../utils/getSelectedPaymentMethod';
var getPaymentName = function (pm) {
    var _a = pm.sensitive, type = _a.type, cc = _a.cc, ach = _a.ach;
    if (type === SensitivePaymentType.Cc) {
        return (React.createElement(React.Fragment, null,
            React.createElement(Typography, { variant: "headline", display: "block" }, pm.name),
            React.createElement(Typography, null, "".concat(cc.network, " ending in ").concat(cc.number.substring(cc.number.length - 4)))));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Typography, { display: "block", variant: "headline" }, ach === null || ach === void 0 ? void 0 : ach.accountName),
        React.createElement(Typography, null, ach === null || ach === void 0 ? void 0 : ach.accountNumber)));
};
export var ReviewPaymentView = function (_a) {
    var paymentOption = _a.paymentOption, paymentMethod = _a.paymentMethod, paymentAmount = _a.paymentAmount, handlePayment = _a.handlePayment, loading = _a.loading;
    var isMobile = useScreenSizing().isMobile;
    var formattedAmount = currencyFormatter(paymentAmount, 2);
    var buttonLabel = "Submit Payment ".concat(formattedAmount);
    var handleContinue = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4, handlePayment()];
                case 1:
                    _a.sent();
                    return [2];
            }
        });
    }); };
    return (React.createElement(React.Fragment, null,
        React.createElement(StepContainer, { subHeader: SubHeaderByOption[paymentOption], header: "Review Payment", tertiaryHeader: "Review your payment", imageOverlay: isMobile ? null : PayInvoiceGraphic },
            React.createElement(Container, null,
                React.createElement(Typography, { variant: "sectionHeader" }, "PAYMENT SUMMARY"),
                React.createElement("div", null,
                    React.createElement(Typography, { variant: "headline", color: "alt" }, "Payment Amount"),
                    React.createElement(Typography, { variant: isMobile ? 'title' : 'titleLarge' }, formattedAmount)),
                React.createElement("div", null,
                    React.createElement(Typography, { variant: "headline", color: "alt" }, "Payment Method"),
                    React.createElement("div", null, getPaymentName(getSelectedPaymentMethod(paymentMethod, PaymentMethodType.primary))))),
            React.createElement(Typography, { variant: "bodySmall", color: "alt", mt: 32 }, "*Once a payment is submitted, it will be processed and may take up to 15 minutes to reflect on your Evolus Credit Balance."),
            isMobile && (React.createElement(MobileContinueButton, { disabled: loading, loading: loading, label: buttonLabel, onContinue: handleContinue }))),
        !isMobile && (React.createElement(PaymentProgress, { loading: loading, primaryButtonDisabled: loading, paymentOption: paymentOption, primaryButtonLabel: buttonLabel, primaryAction: handleContinue }))));
};
var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  background: ", ";\n  padding: 32px;\n  z-index: 1;\n  margin-top: 32px;\n  display: flex;\n  flex-direction: column;\n  gap: 36px;\n  & > div {\n    display: flex;\n    align-items: center;\n    gap: 24px;\n    & > span:first-of-type {\n      width: 155px;\n    }\n  }\n  ", "\n"], ["\n  ", ";\n  background: ", ";\n  padding: 32px;\n  z-index: 1;\n  margin-top: 32px;\n  display: flex;\n  flex-direction: column;\n  gap: 36px;\n  & > div {\n    display: flex;\n    align-items: center;\n    gap: 24px;\n    & > span:first-of-type {\n      width: 155px;\n    }\n  }\n  ", "\n"])), function (props) { return props.theme.borders.base; }, function (props) { return props.theme.colors.bg.body; }, addMobileStyles(css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    border: none;\n    padding: 0;\n    gap: 24px;\n  "], ["\n    border: none;\n    padding: 0;\n    gap: 24px;\n  "])))));
var templateObject_1, templateObject_2;

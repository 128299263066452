var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled, { css } from 'styled-components';
import evoluxLogoWhite from 'assets/evolux-logo-white.png';
import evoluxLogoBlack from 'assets/evolux-logo-black.png';
import club_1k_badge from 'assets/banner_badge_1k.png';
import { useModal } from 'context';
import ModalTypeEnum from 'types/enums/ModalTypeEnum';
import { levelName } from 'utils/levelName';
import { Icon, Typography, Button } from 'designSystem';
import { useScreenSizing } from 'hooks';
import { levelDesignConfig } from 'utils/levelDesignConfig';
import { addMobileStyles } from '../../utils/addMobileStyles';
var CurrentEvoluxLevelWidget = function (_a) {
    var _b, _c;
    var _d = _a.accountName, accountName = _d === void 0 ? 'Account Name' : _d, accountId = _a.accountId, annualVialsPurchased = _a.annualVialsPurchased, _e = _a.currentLevel, currentLevel = _e === void 0 ? 'LEVEL_0' : _e, _f = _a.isUpgrade, isUpgrade = _f === void 0 ? false : _f, _g = _a.context, context = _g === void 0 ? 'DEFAULT' : _g, currentLevelOverride = _a.currentLevelOverride, pricingModel = _a.pricingModel, isLoading = _a.isLoading, locked = _a.locked, level = _a.level, levels = _a.levels, club_1k_feature_flag = _a.club_1k_feature_flag;
    var isMobile = useScreenSizing().isMobile;
    var openModal = useModal().openModal;
    var activeLevel = currentLevelOverride !== null && currentLevelOverride !== void 0 ? currentLevelOverride : currentLevel;
    var isCustomAccount = pricingModel === null || pricingModel === void 0 ? void 0 : pricingModel.includes('CUSTOM_');
    var achievedClub1k = club_1k_feature_flag &&
        !isCustomAccount &&
        (((_b = annualVialsPurchased === null || annualVialsPurchased === void 0 ? void 0 : annualVialsPurchased.presentYearVialsPurchased) !== null && _b !== void 0 ? _b : 0) >= 1000 ||
            ((_c = annualVialsPurchased === null || annualVialsPurchased === void 0 ? void 0 : annualVialsPurchased.previousYearVialsPurchased) !== null && _c !== void 0 ? _c : 0) >= 1000);
    var renderContext = function () {
        var _a, _b, _c, _d;
        if (context === 'EVOLUX_PROGRESS') {
            return (React.createElement(React.Fragment, null,
                React.createElement(EvoluxLogo, { src: ((_a = levelDesignConfig[activeLevel]) === null || _a === void 0 ? void 0 : _a.textColor) === 'white'
                        ? evoluxLogoWhite
                        : evoluxLogoBlack, size: "regular" }),
                React.createElement(ViewAllLevelsButton, { currentLevel: activeLevel }, "View all Evolux Levels")));
        }
        else if (context === 'OFFERS') {
            var discount = Math.round(level.percentOff * 610);
            return (React.createElement(OffersContextContainer, null,
                React.createElement(Typography, { align: isMobile ? 'center' : 'right', variant: "titleLarge", color: achievedClub1k ? 'base' : 'inherit' },
                    "$",
                    discount,
                    " Savings"),
                React.createElement(Typography, { align: isMobile ? 'center' : 'right', variant: isMobile ? 'headlineSmall' : 'body', color: achievedClub1k ? 'alt' : 'inherit', mb: 8, mt: 8 }, (_b = level === null || level === void 0 ? void 0 : level.vialThreshold) !== null && _b !== void 0 ? _b : '0',
                    " vials per quarter, $",
                    610 - discount,
                    "/vial"),
                achievedClub1k && (React.createElement(Button, { variant: "tertiary", onClick: function () {
                        return openModal(ModalTypeEnum.EVOLUX_LEVELS_LIST, {
                            currentLevel: activeLevel,
                            levels: levels,
                        });
                    }, style: { textAlign: 'right', padding: '0' } }, "View All Levels"))));
        }
        else if (context === 'ORDER_SUMMARY') {
            return (React.createElement(OrderSummaryContext, null,
                React.createElement("span", null)));
        }
        else if (context === 'HOME_SCREEN') {
            return (React.createElement(HomeScreenContext, { currentLevel: isCustomAccount ? 'LEVEL_0' : activeLevel, inClub1k: achievedClub1k },
                React.createElement(NestedRightContainer, null,
                    React.createElement(Typography, { variant: "title", color: "inherit" }, accountName),
                    React.createElement(Typography, { variant: "bodySmall", color: "inherit" }, accountId))));
        }
        else if (context === 'LEVELS_LIST') {
            var per = isMobile ? '/' : 'per';
            return (React.createElement(LevelsListContext, null,
                !isMobile && level.percentOff ? (React.createElement(Typography, { align: "right", variant: "titleLarge", color: "inherit" },
                    "$",
                    Math.round(level.percentOff * 610),
                    " Savings")) : null,
                React.createElement(Typography, { align: "right", variant: isMobile ? 'headlineSmall' : 'title', color: "inherit" }, (_c = level === null || level === void 0 ? void 0 : level.vialThreshold) !== null && _c !== void 0 ? _c : '0',
                    " vials ",
                    per,
                    ' ',
                    isMobile ? 'qtr' : 'quarter'),
                React.createElement(Typography, { align: "right", variant: isMobile ? 'footnote' : 'title', color: "inherit" },
                    "$",
                    610 - Math.round(level.percentOff * 610),
                    " ",
                    per,
                    " vial")));
        }
        else {
            return (React.createElement(EvoluxLogo, { src: ((_d = levelDesignConfig[activeLevel]) === null || _d === void 0 ? void 0 : _d.textColor) === 'white'
                    ? evoluxLogoWhite
                    : evoluxLogoBlack, size: "large" }));
        }
    };
    var renderLevelName = function () {
        if (isCustomAccount) {
            return 'Custom';
        }
        else {
            return levelName(activeLevel);
        }
    };
    var isLevelsList = context === 'LEVELS_LIST';
    var showSavingsSubText = Boolean(isMobile && isLevelsList && level.percentOff);
    return (React.createElement(OuterContainer, { isLevelsList: isLevelsList, context: context, currentLevel: isCustomAccount ? 'CUSTOM' : activeLevel, isLoading: !!(currentLevelOverride ? false : isLoading), locked: locked, inClub1k: achievedClub1k },
        locked && (React.createElement(LevelLockedContainer, null,
            React.createElement(LockImageContainer, null,
                React.createElement(Icon, { name: "lock", size: isMobile ? 20 : 32, color: "#6E9191" })))),
        React.createElement(ContentContainer, { isLevelsList: isLevelsList },
            React.createElement(LeftContainer, { inClub1k: achievedClub1k, context: context },
                achievedClub1k &&
                    (context === 'HOME_SCREEN' || context === 'OFFERS') && (React.createElement(AnnualLoyaltyBadge, { src: club_1k_badge, alt: "Club 1K Logo", context: context })),
                React.createElement(LevelName, { isLevelsList: isLevelsList, locked: locked }, achievedClub1k && context === 'OFFERS' ? '' : renderLevelName()),
                showSavingsSubText && (React.createElement(Typography, { color: "inherit", variant: "footnote" },
                    "$",
                    Math.round(level.percentOff * 610),
                    " Savings"))),
            React.createElement(RightContainer, { locked: locked }, renderContext()))));
};
export default CurrentEvoluxLevelWidget;
var ContentContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: ", ";\n    justify-content: ", ";\n    align-items: center;\n  }\n"], ["\n  display: flex;\n  flex: 1;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: ", ";\n    justify-content: ", ";\n    align-items: center;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
}, function (props) { return (props.isLevelsList ? 'row' : 'column'); }, function (props) {
    return props.isLevelsList ? 'space-between' : 'center';
});
var AnnualLoyaltyBadge = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  max-width: ", ";\n  background-color: #fff;\n  padding: 4px 10px;\n"], ["\n  max-width: ", ";\n  background-color: #fff;\n  padding: 4px 10px;\n"])), function (props) {
    return props.context === 'HOME_SCREEN' ? '150px' : '220px';
});
var OuterContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  margin: 16px;\n  background-color: ", ";\n  position: relative;\n  min-height: 160px;\n  padding: ", ";\n  color: ", ";\n  @media only screen and (max-width: ", "px) {\n    margin: ", ";\n    padding: ", ";\n    min-height: unset;\n    border: ", ";\n  }\n  ", "\n"], ["\n  display: flex;\n  flex: 1;\n  margin: 16px;\n  background-color: ", ";\n  position: relative;\n  min-height: 160px;\n  padding: ", ";\n  color: ", ";\n  @media only screen and (max-width: ", "px) {\n    margin: ", ";\n    padding: ", ";\n    min-height: unset;\n    border: ", ";\n  }\n  ", "\n"])), function (props) {
    var _a, _b, _c;
    if (props.context === 'HOME_SCREEN') {
        if (levelName(props === null || props === void 0 ? void 0 : props.currentLevel) === 'Elite+') {
            return (_a = levelDesignConfig[props === null || props === void 0 ? void 0 : props.currentLevel]) === null || _a === void 0 ? void 0 : _a.baseColor;
        }
        else {
            return (_b = levelDesignConfig[props.currentLevel]) === null || _b === void 0 ? void 0 : _b.baseColor;
        }
    }
    else if (props.context === 'OFFERS' && props.inClub1k) {
        return '#fff';
    }
    else {
        return (props === null || props === void 0 ? void 0 : props.locked)
            ? props.theme.colors.bg.base
            : (_c = levelDesignConfig[props === null || props === void 0 ? void 0 : props.currentLevel]) === null || _c === void 0 ? void 0 : _c.baseColor;
    }
}, function (props) { return (props.inClub1k ? '30px 60px' : '50px 60px'); }, function (props) {
    var _a;
    return props.locked
        ? props.theme.colors.text.alt5
        : (_a = levelDesignConfig[props === null || props === void 0 ? void 0 : props.currentLevel]) === null || _a === void 0 ? void 0 : _a.textColor;
}, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
}, function (props) { return (props.isLevelsList ? "12px 0" : "0 32px 64px 32px"); }, function (props) { return (props.isLevelsList ? "12px 24px" : "20px"); }, function (props) {
    return props.isLevelsList ? props.theme.borders.base : 'none';
}, function (props) { return props.isLoading && props.theme.blur.loading; });
var LeftContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  align-items: flex-start;\n  justify-content: ", ";\n  @media only screen and (max-width: ", "px) {\n    align-items: flex-start;\n    justify-content: center;\n  }\n"], ["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  align-items: flex-start;\n  justify-content: ", ";\n  @media only screen and (max-width: ", "px) {\n    align-items: flex-start;\n    justify-content: center;\n  }\n"])), function (props) {
    return props.inClub1k &&
        (props.context === 'HOME_SCREEN' || props.context === 'OFFERS')
        ? 'flex-end'
        : 'center';
}, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var RightContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  justify-content: center;\n  align-items: flex-end;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex: 1;\n  justify-content: center;\n  align-items: flex-end;\n  flex-direction: column;\n"])));
var NestedRightContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n"])));
var LevelName = styled.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-family: 'DIN';\n  font-weight: 700;\n  margin-bottom: -10px;\n  font-size: 4.5rem;\n  @media only screen and (max-width: ", "px) {\n    font-size: ", "px;\n    line-height: ", "px;\n    margin-bottom: ", ";\n  }\n"], ["\n  font-family: 'DIN';\n  font-weight: 700;\n  margin-bottom: -10px;\n  font-size: 4.5rem;\n  @media only screen and (max-width: ", "px) {\n    font-size: ", "px;\n    line-height: ", "px;\n    margin-bottom: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
}, function (props) { return (props.isLevelsList ? 22 : 64); }, function (props) { return (props.isLevelsList ? 24 : 77); }, function (props) { return (props.isLevelsList ? '0' : '14px'); });
var EvoluxLogo = styled.img(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  width: ", ";\n  height: ", ";\n"], ["\n  width: ", ";\n  height: ", ";\n"])), function (props) { return (props.size === 'regular' ? '87px' : '140px'); }, function (props) { return (props.size === 'regular' ? '20px' : '32px'); });
var ViewAllLevelsButton = styled.button(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  background: none;\n  border: none;\n  padding: 0;\n  font: inherit;\n  cursor: pointer;\n  outline: inherit;\n  text-decoration: underline;\n  margin-top: 5px;\n  color: ", ";\n"], ["\n  background: none;\n  border: none;\n  padding: 0;\n  font: inherit;\n  cursor: pointer;\n  outline: inherit;\n  text-decoration: underline;\n  margin-top: 5px;\n  color: ", ";\n"])), function (props) { var _a; return (_a = levelDesignConfig[props === null || props === void 0 ? void 0 : props.currentLevel]) === null || _a === void 0 ? void 0 : _a.textColor; });
var OrderSummaryContext = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: inline-block;\n  text-align: right;\n  flex-direction: column;\n"], ["\n  display: inline-block;\n  text-align: right;\n  flex-direction: column;\n"])));
var HomeScreenContext = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  text-align: right;\n  color: ", ";\n  justify-content: center;\n  align-items: flex-end;\n  @media only screen and (max-width: ", "px) {\n    align-items: center;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  text-align: right;\n  color: ", ";\n  justify-content: center;\n  align-items: flex-end;\n  @media only screen and (max-width: ", "px) {\n    align-items: center;\n  }\n"])), function (props) { var _a; return (_a = levelDesignConfig[props.currentLevel]) === null || _a === void 0 ? void 0 : _a.textColor; }, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var LevelsListContext = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  text-align: right;\n"], ["\n  display: flex;\n  flex-direction: column;\n  text-align: right;\n"])));
var OffersContextContainer = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n  ", ";\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n  ", ";\n"])), addMobileStyles(css(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n    align-items: center;\n  "], ["\n    align-items: center;\n  "])))));
var LevelLockedContainer = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(255, 255, 255, 0.2);\n  justify-content: center;\n  align-items: center;\n  display: flex;\n  top: 0;\n  left: 0;\n"], ["\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(255, 255, 255, 0.2);\n  justify-content: center;\n  align-items: center;\n  display: flex;\n  top: 0;\n  left: 0;\n"])));
var LockImageContainer = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: ", ";\n  width: 72px;\n  height: 72px;\n  border-radius: 100%;\n  @media only screen and (max-width: ", "px) {\n    align-items: center;\n    width: 36px;\n    height: 36px;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: ", ";\n  width: 72px;\n  height: 72px;\n  border-radius: 100%;\n  @media only screen and (max-width: ", "px) {\n    align-items: center;\n    width: 36px;\n    height: 36px;\n  }\n"])), function (props) { return props.theme.colors.bg.alt; }, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16;

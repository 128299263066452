var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { Modal } from '../Modal';
import styled from 'styled-components';
import { Button, Typography } from 'designSystem';
import { useModal } from 'context';
import OptInGraphic from 'assets/rewards-not-opted-in.svg';
import OptOutGraphic from 'assets/rewards-opted-out.svg';
import OptBakInGraphic from 'assets/rewards-opt-back-in.svg';
import { useScreenSizing } from '../../../hooks';
var ContentByContext = {
    OPT_IN: {
        title: 'Rewards Program',
        subtitle: 'Modern Patient Loyalty for your Practice awaits!',
        action: 'Opt In to Evolus Rewards',
        imgSrc: OptInGraphic,
        showTerms: true,
    },
    OPT_BACK_IN: {
        title: 'Hello, is it me you’re looking for?',
        subtitle: 'Got FOMO? Want to jump back in and be part of Evolus Rewards again, your patients will love you for it.',
        action: 'Confirm Opt-In',
        imgSrc: OptBakInGraphic,
        showTerms: true,
    },
    OPT_OUT: {
        title: 'We’ll be sad to see you go...',
        subtitle: 'Don’t worry you can still redeem reward vials you’ve earned. You’re able to opt-back into the program at anytime (via Account Settings) and any progress towards a reward vial will be retained.',
        action: 'Confirm Opt-Out',
        imgSrc: OptOutGraphic,
        showTerms: false,
    },
};
export var OptInOutEvolusRewards = function (_a) {
    var context = _a.context, onSubmit = _a.onSubmit;
    var closeModal = useModal().closeModal;
    var isMobile = useScreenSizing().isMobile;
    var _b = ContentByContext[context], title = _b.title, subtitle = _b.subtitle, action = _b.action, imgSrc = _b.imgSrc, showTerms = _b.showTerms;
    return (React.createElement(Modal, { size: "large" },
        React.createElement(ContentContainer, null,
            React.createElement(LeftContainer, null,
                React.createElement(Typography, { color: isMobile ? 'link' : 'base', variant: isMobile ? 'display' : 'titleLarge', mb: isMobile ? 32 : 16, display: "block" }, title),
                React.createElement(Typography, { variant: isMobile ? 'subtitle' : 'body', color: "alt", display: "block" }, subtitle),
                showTerms && (React.createElement(Typography, { variant: 'footnote', color: "alt5", display: "block", mt: 12, mb: 12 },
                    "*By clicking \"",
                    action,
                    "\" you agree to the",
                    ' ',
                    React.createElement(TermsLink, { href: "#", target: "blank", rel: "noopener noreferrer" }, "Terms and Conditions"),
                    ' ',
                    "of this program.")),
                React.createElement(ActionsContainer, null,
                    React.createElement(Button, { size: "xlarge", onClick: function () { return onSubmit(); } }, action),
                    React.createElement(Button, { size: "xlarge", variant: isMobile ? 'tertiary' : 'outlined', onClick: function () { return closeModal(); } }, "Cancel"))),
            React.createElement(Image, { src: imgSrc, alt: "".concat(context, "-graphic") }))));
};
var ContentContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  width: 100%;\n  justify-content: space-around;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column-reverse;\n    justify-content: flex-end;\n    align-items: center;\n  }\n"], ["\n  display: flex;\n  flex: 1;\n  width: 100%;\n  justify-content: space-around;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column-reverse;\n    justify-content: flex-end;\n    align-items: center;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var ActionsContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: auto;\n  width: 100%;\n  display: flex;\n  flex-direction: row-reverse;\n  align-items: center;\n  justify-content: flex-end;\n  gap: 12px;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n    margin-top: auto;\n    & > button {\n      width: 100%;\n    }\n  }\n"], ["\n  margin-top: auto;\n  width: 100%;\n  display: flex;\n  flex-direction: row-reverse;\n  align-items: center;\n  justify-content: flex-end;\n  gap: 12px;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n    margin-top: auto;\n    & > button {\n      width: 100%;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var LeftContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  @media only screen and (max-width: ", "px) {\n    flex: 1;\n    & > span {\n      text-align: center;\n    }\n    & > span:first-of-type {\n      margin-top: 32px;\n    }\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  @media only screen and (max-width: ", "px) {\n    flex: 1;\n    & > span {\n      text-align: center;\n    }\n    & > span:first-of-type {\n      margin-top: 32px;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var Image = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  max-width: 266px;\n"], ["\n  width: 100%;\n  max-width: 266px;\n"])));
var TermsLink = styled.a(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: ", ";\n  text-decoration: underline;\n"], ["\n  color: ", ";\n  text-decoration: underline;\n"])), function (props) { return props.theme.colors.text.alt5; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;

import React from 'react';
export var ClubSavings = function (_a) {
    var color = _a.color;
    return (React.createElement("svg", { width: "36", height: "36", viewBox: "0 0 36 36", fill: "none", xmlns: "http://www.w3.org/2000/svg", color: color },
        React.createElement("rect", { x: "0.5", y: "0.5", width: "35", height: "35", rx: "17.5", fill: "white" }),
        React.createElement("rect", { x: "0.5", y: "0.5", width: "35", height: "35", rx: "17.5", stroke: "#E5554F" }),
        React.createElement("path", { d: "M19.8002 21.333C19.6602 21.328 19.5238 21.3785 19.4208 21.4736C19.3178 21.5686 19.2565 21.7005 19.2502 21.8405V25.833C19.2563 25.9709 19.3166 26.1009 19.4181 26.1945C19.5196 26.2882 19.6539 26.338 19.7919 26.333H26.8252C26.9653 26.338 27.1017 26.2874 27.2047 26.1924C27.3077 26.0974 27.369 25.9655 27.3752 25.8255V21.958C27.3679 21.7854 27.2925 21.6228 27.1655 21.5056C27.0385 21.3885 26.8704 21.3264 26.6977 21.333H19.8002Z", stroke: "#E5554F", strokeWidth: "1.25", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M23.312 23.5259C23.3726 23.5269 23.4316 23.5457 23.4815 23.5801C23.5314 23.6145 23.57 23.6629 23.5925 23.7191C23.615 23.7754 23.6204 23.8371 23.608 23.8964C23.5956 23.9557 23.5659 24.01 23.5227 24.0525C23.4795 24.095 23.4247 24.1238 23.3652 24.1353C23.3057 24.1468 23.2441 24.1404 23.1882 24.117C23.1323 24.0936 23.0846 24.0541 23.051 24.0037C23.0174 23.9532 22.9995 23.894 22.9995 23.8334C22.9998 23.7927 23.0082 23.7524 23.0241 23.7149C23.0399 23.6775 23.0631 23.6435 23.0921 23.6149C23.1211 23.5864 23.1555 23.5638 23.1932 23.5485C23.2309 23.5333 23.2713 23.5256 23.312 23.5259Z", stroke: "#E5554F", strokeWidth: "1.25", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M19.2493 23.711C18.6904 24.5202 17.9434 25.1817 17.0725 25.6385C16.2016 26.0953 15.2327 26.3338 14.2493 26.3335C11.4251 26.3335 8.62427 24.2352 8.62427 21.646C8.62427 19.0893 10.8401 16.2643 12.5451 15.0835C12.0534 14.0002 11.6576 13.146 11.3493 12.4877C11.2603 12.2972 11.2207 12.0873 11.2344 11.8775C11.248 11.6676 11.3143 11.4647 11.4272 11.2873C11.5401 11.1099 11.696 10.9639 11.8803 10.8627C12.0646 10.7615 12.2715 10.7085 12.4818 10.7085H16.7493", stroke: "#E5554F", strokeWidth: "1.25", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M17.833 16.8716C18.9377 18.1244 19.6446 19.6775 19.8638 21.3332", stroke: "#E5554F", strokeWidth: "1.25", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M12.8568 22.8699C13.0372 23.107 13.2722 23.297 13.5417 23.4239C13.8113 23.5509 14.1074 23.611 14.4051 23.5991C15.3543 23.5991 16.1243 23.0216 16.1243 22.3099C16.1243 21.5983 15.3543 21.0216 14.4051 21.0216C13.4559 21.0216 12.6868 20.4441 12.6868 19.7316C12.6868 19.0191 13.4568 18.4424 14.4051 18.4424C14.7028 18.4304 14.999 18.4904 15.2686 18.6173C15.5382 18.7443 15.7731 18.9344 15.9534 19.1716", stroke: "#E5554F", strokeWidth: "1.25", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M14.4055 23.5991V24.4583", stroke: "#E5554F", strokeWidth: "1.25", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M14.4055 17.583V18.4422", stroke: "#E5554F", strokeWidth: "1.25", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M27.3746 12.4047V9.45801H24.428", stroke: "#E5554F", strokeWidth: "1.25", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M27.3748 9.46094L24.6664 12.1668C24.5127 12.3208 24.3201 12.4304 24.1091 12.4836C23.8981 12.5369 23.6765 12.5319 23.4681 12.4693L20.4089 11.6518C20.2042 11.5968 19.9885 11.5968 19.7837 11.6517C19.5789 11.7065 19.3922 11.8143 19.2423 11.9643L16.1206 15.0893", stroke: "#E5554F", strokeWidth: "1.25", strokeLinecap: "round", strokeLinejoin: "round" })));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useMemo, useState, useEffect, useContext, createContext, useCallback, } from 'react';
import { useNavigate } from 'react-router';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { accountList as accountIdList, userData, accounts as accountsArray, facilityList as FacilityIdList, staffIdList as StafffIdList, } from 'context/mockData/data';
var UserContext = createContext({
    user: null,
    account: null,
    loaded: false,
    isAdmin: false,
    error: null,
    signIn: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2];
    }); }); },
    signOut: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2];
    }); }); },
    setLoaded: function () { },
    refreshUser: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2];
    }); }); },
    switchAccount: function () { },
    switchFacility: function () { },
    facility: null,
});
var SELECTED_ACCOUNT_KEY = 'Evolus.Portal.AccountId';
var SELECTED_FACILITY_KEY = 'Evolus.Portal.FacilityId';
var UserConsumer = UserContext.Consumer;
var UserProvider = function (props) {
    var _a = __read(useState(null), 2), user = _a[0], setUser = _a[1];
    var _b = __read(useState(null), 2), account = _b[0], setAccount = _b[1];
    var _c = __read(useState(null), 2), facility = _c[0], setFacility = _c[1];
    var _d = __read(useState(false), 2), loaded = _d[0], setLoaded = _d[1];
    var _e = __read(useState(false), 2), isAdmin = _e[0], setIsAdmin = _e[1];
    var _f = __read(useState(null), 2), error = _f[0], setError = _f[1];
    var navigate = useNavigate();
    var _g = __read(useLocalStorage('accountIds', accountIdList), 1), accountIds = _g[0];
    var _h = __read(useLocalStorage('currentUser', userData), 1), currentUser = _h[0];
    useLocalStorage('facilityIds', FacilityIdList);
    useLocalStorage('staffIds', StafffIdList);
    useEffect(function () {
        function init() {
            return __awaiter(this, void 0, void 0, function () {
                var accounts, selectedFacility, selectedAccount, account_1, facility_1;
                return __generator(this, function (_a) {
                    accountIds.forEach(function (id) {
                        var acct = accountsArray.find(function (account) { return account.account.id === id; });
                        if (!localStorage.getItem(id)) {
                            localStorage.setItem(id, JSON.stringify(acct === null || acct === void 0 ? void 0 : acct.account));
                        }
                    });
                    accounts = accountIds.map(function (acctId) {
                        var acct = localStorage.getItem(acctId);
                        return acct ? { account: JSON.parse(acct) } : null;
                    });
                    selectedFacility = localStorage.getItem(SELECTED_FACILITY_KEY);
                    selectedAccount = localStorage.getItem(SELECTED_ACCOUNT_KEY);
                    if (currentUser && selectedAccount) {
                        account_1 = JSON.parse(localStorage.getItem(selectedAccount));
                        if (account_1) {
                            setIsAdmin(true);
                            setAccount(account_1);
                            if (currentUser && selectedFacility) {
                                facility_1 = account_1.facilities.find(function (facility) { return facility.id === selectedFacility; });
                                if (facility_1)
                                    setFacility(facility_1);
                            }
                        }
                    }
                    setUser(__assign(__assign({}, currentUser), { accounts: accounts }));
                    setLoaded(true);
                    return [2];
                });
            });
        }
        (function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
            return [2, init()];
        }); }); })();
    }, [setLoaded, accountIds, currentUser]);
    var signIn = function (callback) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setUser(currentUser);
            setLoaded(true);
            if (callback) {
                callback(currentUser);
            }
            return [2];
        });
    }); };
    var signOut = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            try {
                navigate('/auth/sign-in');
                setUser(null);
                setAccount(null);
                setFacility(null);
                setLoaded(false);
                localStorage.removeItem(SELECTED_ACCOUNT_KEY);
            }
            catch (error) {
                setError(error);
                console.log('error signing out: ', error);
            }
            return [2];
        });
    }); }, [setUser, navigate]);
    var refreshUser = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setUser(currentUser);
            return [2];
        });
    }); }, [setUser, currentUser]);
    var switchAccount = useCallback(function (account) {
        setAccount(account);
        localStorage.setItem(SELECTED_ACCOUNT_KEY, account === null || account === void 0 ? void 0 : account.id);
        setIsAdmin(true);
    }, [setAccount]);
    var switchFacility = useCallback(function (facility) {
        setFacility(facility);
        localStorage.setItem(SELECTED_FACILITY_KEY, facility === null || facility === void 0 ? void 0 : facility.id);
    }, [setFacility]);
    var values = useMemo(function () { return ({
        user: user,
        account: account,
        loaded: loaded,
        isAdmin: isAdmin,
        error: error,
        signIn: signIn,
        signOut: signOut,
        refreshUser: refreshUser,
        setLoaded: setLoaded,
        switchAccount: switchAccount,
        switchFacility: switchFacility,
        facility: facility,
    }); }, [
        isAdmin,
        user,
        account,
        loaded,
        error,
        signIn,
        signOut,
        refreshUser,
        setLoaded,
        switchAccount,
        switchFacility,
        facility,
    ]);
    return (React.createElement(UserContext.Provider, { value: values }, props.children));
};
var useUser = function () {
    var context = useContext(UserContext);
    if (context === undefined) {
        throw new Error('useUser must be used within an UserProvider');
    }
    return context;
};
export { UserProvider, UserConsumer, useUser };

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled, { css } from 'styled-components';
import { Typography } from 'designSystem';
import { currencyFormatter } from 'utils/currencyFormatter';
import { cardStyles } from '../constants/CardStyles';
import { addMobileStyles } from 'utils/addMobileStyles';
import { useScreenSizing } from 'hooks';
export var InvoiceStats = function (_a) {
    var currentBalance = _a.currentBalance, creditLimit = _a.creditLimit, availableCredit = _a.availableCredit;
    var isMobile = useScreenSizing().isMobile;
    return (React.createElement(Container, null,
        React.createElement(StatCard, null,
            React.createElement(Typography, { variant: "headline", color: "alt" }, "Current Balance"),
            React.createElement(Typography, { variant: isMobile ? 'subtitleLarge' : 'titleLarge', color: "link" }, currencyFormatter(currentBalance, 2))),
        isMobile && React.createElement(Divider, null),
        React.createElement(StatCard, null,
            React.createElement(Typography, { variant: "headline", color: "alt" }, "Available Credit"),
            React.createElement(Typography, { variant: isMobile ? 'headline' : 'titleLarge', color: "success" }, currencyFormatter(availableCredit, 2))),
        React.createElement(StatCard, null,
            React.createElement(Typography, { variant: "headline", color: "alt" }, "Credit Limit"),
            React.createElement(Typography, { variant: isMobile ? 'headline' : 'titleLarge' }, currencyFormatter(creditLimit, 2)))));
};
var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 32px;\n  margin: 32px 0;\n  flex: 1;\n  ", "\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 32px;\n  margin: 32px 0;\n  flex: 1;\n  ", "\n"])), addMobileStyles(css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    flex-direction: column;\n    gap: 0;\n    & > div:first-of-type {\n      display: flex;\n      flex-direction: column-reverse;\n      & > span:last-of-type {\n        font-weight: 500;\n      }\n    }\n    & > div:last-of-type {\n      margin-top: 12px;\n    }\n  "], ["\n    flex-direction: column;\n    gap: 0;\n    & > div:first-of-type {\n      display: flex;\n      flex-direction: column-reverse;\n      & > span:last-of-type {\n        font-weight: 500;\n      }\n    }\n    & > div:last-of-type {\n      margin-top: 12px;\n    }\n  "])))));
var StatCard = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 16px;\n  padding: 41px 0;\n  ", "\n"], ["\n  ", ";\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 16px;\n  padding: 41px 0;\n  ", "\n"])), cardStyles, addMobileStyles(css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    box-shadow: none;\n    border: none;\n    background: none;\n    flex-direction: row;\n    justify-content: space-between;\n    padding: 0;\n    width: 100%;\n    gap: 4px;\n  "], ["\n    box-shadow: none;\n    border: none;\n    background: none;\n    flex-direction: row;\n    justify-content: space-between;\n    padding: 0;\n    width: 100%;\n    gap: 4px;\n  "])))));
var Divider = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n  ", ";\n  margin: 24px 0;\n"], ["\n  width: 100%;\n  ", ";\n  margin: 24px 0;\n"])), function (props) { return props.theme.borders.base; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;

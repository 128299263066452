var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { useModal } from 'context';
import { Button, Typography } from 'designSystem';
import React from 'react';
import styled from 'styled-components';
import LevelBenefitEnums from 'types/enums/LevelBenefitsEnum';
import ModalTypeEnum from 'types/enums/ModalTypeEnum';
import { levelName } from 'utils/levelName';
import { levelBenefits, levelBenefitsById, } from './levelBenefits';
import { BenefitItem, EvoluxLevel } from './components/BenefitItem';
import { useScreenSizing } from '../../../../hooks';
import { levelDesignConfig } from 'utils/levelDesignConfig';
import { stackedCardStyles } from 'components/StackedMobileCard';
export var EvoluxBenefitsWidget = function (_a) {
    var _b, _c, _d;
    var currentLevelName = _a.currentLevelName, isLoading = _a.isLoading, levels = _a.levels, showButton = _a.showButton, pricingModel = _a.pricingModel, msrp = _a.msrp, defaultRate = _a.defaultRate, annualCampaigns = _a.annualCampaigns;
    var openModal = useModal().openModal;
    var isMobile = useScreenSizing().isMobile;
    var isCustomAccount = pricingModel === null || pricingModel === void 0 ? void 0 : pricingModel.includes('CUSTOM_');
    var isNonMember = currentLevelName === EvoluxLevel.LEVEL_0;
    var currentLevelText = isNonMember
        ? 'Unlock These Benefits'
        : "My ".concat(isCustomAccount ? 'Custom' : levelName(currentLevelName), " Benefits");
    var currentLevel = levels === null || levels === void 0 ? void 0 : levels.find(function (level) { return level.name === currentLevelName; });
    var nextLevel = levels === null || levels === void 0 ? void 0 : levels.find(function (level) {
        return currentLevel && level.vialThreshold > (currentLevel === null || currentLevel === void 0 ? void 0 : currentLevel.vialThreshold);
    });
    var nextLevelName = (nextLevel === null || nextLevel === void 0 ? void 0 : nextLevel.name) || '';
    var levelSavings = function (level) {
        return "$".concat(Math.round(((level === null || level === void 0 ? void 0 : level.percentOff) || 0) * 610), " savings");
    };
    var levelVialPrice = function (level) {
        return "$".concat(Math.round(610 - ((level === null || level === void 0 ? void 0 : level.percentOff) || 0) * 610), " per vial");
    };
    var levelVialThreshold = function (level) {
        return "".concat((level === null || level === void 0 ? void 0 : level.vialThreshold) || 0, " vials per quarter");
    };
    var currentLevelBenefits = (_b = levelBenefits[currentLevelName]) === null || _b === void 0 ? void 0 : _b.benefits;
    var nextLevelBenefits = (_c = levelBenefits[currentLevelName]) === null || _c === void 0 ? void 0 : _c.nextLevelBenefits;
    var isElitePlus = currentLevelName === 'LEVEL_8';
    var achievedCampaigns = annualCampaigns === null || annualCampaigns === void 0 ? void 0 : annualCampaigns.filter(function (campaign) { var _a; return !((_a = campaign === null || campaign === void 0 ? void 0 : campaign.meta) === null || _a === void 0 ? void 0 : _a.benefitsHeader); });
    var campaignsInProgress = annualCampaigns === null || annualCampaigns === void 0 ? void 0 : annualCampaigns.filter(function (campaign) { var _a; return (_a = campaign === null || campaign === void 0 ? void 0 : campaign.meta) === null || _a === void 0 ? void 0 : _a.benefitsHeader; });
    var hasBenefits = ((currentLevelBenefits === null || currentLevelBenefits === void 0 ? void 0 : currentLevelBenefits.length) || 0) > 0;
    var renderCurrentBenefits = function () {
        var _a, _b;
        if (isCustomAccount) {
            return (React.createElement(BenefitsContainer, null,
                React.createElement(BenefitRow, null,
                    React.createElement(BenefitItem, { currentLevel: 'LEVEL_0', header: "$".concat((msrp - defaultRate).toString(), " savings"), content: "$".concat(defaultRate.toString(), " per vial"), icon: 'savings' }), (_b = (_a = levelBenefits['CUSTOM']) === null || _a === void 0 ? void 0 : _a.benefits) === null || _b === void 0 ? void 0 :
                    _b.map(function (benefit, i) {
                        var _a, _b, _c, _d;
                        return (React.createElement(BenefitItem, { key: i, currentLevel: 'LEVEL_0', header: (_a = levelBenefitsById[benefit]) === null || _a === void 0 ? void 0 : _a.header, content: (_b = levelBenefitsById[benefit]) === null || _b === void 0 ? void 0 : _b.content, icon: (_c = levelBenefitsById[benefit]) === null || _c === void 0 ? void 0 : _c.icon, url: (_d = levelBenefitsById[benefit]) === null || _d === void 0 ? void 0 : _d.url }));
                    }))));
        }
        if (hasBenefits) {
            return (React.createElement(BenefitsContainer, null,
                React.createElement(BenefitRow, null,
                    achievedCampaigns.map(function (campaign) {
                        var _a;
                        return (_a = campaign === null || campaign === void 0 ? void 0 : campaign.meta) === null || _a === void 0 ? void 0 : _a.benefitsTiles.map(function (tile) {
                            return (React.createElement(BenefitItem, { key: tile === null || tile === void 0 ? void 0 : tile.header, currentLevel: currentLevelName, header: tile === null || tile === void 0 ? void 0 : tile.header, content: tile === null || tile === void 0 ? void 0 : tile.body, icon: 'club-1k-benefits', filled: true }));
                        });
                    }),
                    (currentLevel === null || currentLevel === void 0 ? void 0 : currentLevel.name) !== 'LEVEL_0' && (React.createElement(BenefitItem, { currentLevel: currentLevelName, header: levelSavings(currentLevel), content: "".concat(levelVialThreshold(currentLevel), " / ").concat(levelVialPrice(currentLevel)), icon: "savings" })),
                    currentLevelBenefits.map(function (benefit, i) {
                        var _a, _b, _c, _d;
                        return (React.createElement(BenefitItem, { key: i, currentLevel: currentLevelName, header: (_a = levelBenefitsById[benefit]) === null || _a === void 0 ? void 0 : _a.header, content: (_b = levelBenefitsById[benefit]) === null || _b === void 0 ? void 0 : _b.content, icon: (_c = levelBenefitsById[benefit]) === null || _c === void 0 ? void 0 : _c.icon, url: (_d = levelBenefitsById[benefit]) === null || _d === void 0 ? void 0 : _d.url }));
                    }))));
        }
    };
    return (React.createElement(OuterContainer, { isLoading: isLoading },
        React.createElement(HeaderContainer, null,
            React.createElement(Typography, { variant: isMobile ? 'headline' : 'titleLarge' }, currentLevelText),
            showButton && !isCustomAccount && (React.createElement(Button, { variant: "tertiary", onClick: function () {
                    return openModal(ModalTypeEnum.EVOLUX_LEVELS_LIST, {
                        currentLevel: currentLevelName,
                        levels: levels,
                    });
                } }, "See All Levels"))),
        isLoading && React.createElement(Placeholder, { currentLevel: currentLevelName }),
        renderCurrentBenefits(),
        !isCustomAccount && !isElitePlus && (React.createElement(Typography, { mt: 32, variant: "title", color: "alt" },
            "Level up to ", (_d = levelDesignConfig[nextLevelName]) === null || _d === void 0 ? void 0 :
            _d.name)),
        isLoading && React.createElement(Placeholder, { currentLevel: currentLevelName }),
        !isCustomAccount && (React.createElement(React.Fragment, null,
            React.createElement(BenefitsContainer, null,
                !isElitePlus && (React.createElement(BenefitRow, null,
                    React.createElement(BenefitItem, { currentLevel: nextLevelName, header: levelSavings(nextLevel), content: "".concat((currentLevel === null || currentLevel === void 0 ? void 0 : currentLevel.name) === 'LEVEL_0'
                            ? ">=10 vials per quarter"
                            : levelVialThreshold(nextLevel), " / ").concat(levelVialPrice(nextLevel)), icon: 'lock', isLocked: true }), nextLevelBenefits === null || nextLevelBenefits === void 0 ? void 0 :
                    nextLevelBenefits.map(function (benefit, i) {
                        var _a, _b, _c;
                        return (React.createElement(BenefitItem, { key: i, currentLevel: currentLevelName, isLocked: true, header: (_a = levelBenefitsById[benefit]) === null || _a === void 0 ? void 0 : _a.header, content: (_b = levelBenefitsById[benefit]) === null || _b === void 0 ? void 0 : _b.content, icon: 'lock', url: (_c = levelBenefitsById[benefit]) === null || _c === void 0 ? void 0 : _c.url }));
                    }))), campaignsInProgress === null || campaignsInProgress === void 0 ? void 0 :
                campaignsInProgress.map(function (_a) {
                    var _b;
                    var meta = _a.meta;
                    return (React.createElement(React.Fragment, null,
                        React.createElement(Typography, { mt: 32, variant: "title", color: "alt" }, meta === null || meta === void 0 ? void 0 : meta.benefitsHeader),
                        React.createElement(BenefitRow, null, (_b = meta === null || meta === void 0 ? void 0 : meta.benefitsTiles) === null || _b === void 0 ? void 0 : _b.map(function (tile) {
                            return (React.createElement(BenefitItem, { key: tile === null || tile === void 0 ? void 0 : tile.header, currentLevel: currentLevelName, header: tile === null || tile === void 0 ? void 0 : tile.header, content: tile === null || tile === void 0 ? void 0 : tile.body, icon: 'lock', isLocked: true }));
                        }))));
                })))),
        !isCustomAccount && (React.createElement(React.Fragment, null,
            currentLevelName > 'LEVEL_0' && (React.createElement(Typography, { mt: 16, display: "block", variant: "footnote", color: "alt5" }, "* Benefit rewarded upon first purchase of 12 or more vials.")),
            currentLevelName > 'LEVEL_4' && (React.createElement(Typography, { mb: 16, display: "block", variant: "footnote", color: "alt5" }, "** Commercials originally filmed in the last 18 months are eligible to be re-aired rather than re-filmed."))))));
};
export default EvoluxBenefitsWidget;
var oneThruFour = [1, 2, 3, 4];
var Placeholder = function (props) {
    return (React.createElement(BenefitsContainer, null,
        React.createElement(BenefitRow, null, oneThruFour.map(function (i) {
            var _a, _b, _c, _d;
            return (React.createElement(BenefitItem, { key: i, currentLevel: props.currentLevel, header: (_a = levelBenefitsById[LevelBenefitEnums.PLACEHOLDER]) === null || _a === void 0 ? void 0 : _a.header, content: (_b = levelBenefitsById[LevelBenefitEnums.PLACEHOLDER]) === null || _b === void 0 ? void 0 : _b.content, icon: (_c = levelBenefitsById[LevelBenefitEnums.PLACEHOLDER]) === null || _c === void 0 ? void 0 : _c.icon, url: (_d = levelBenefitsById[LevelBenefitEnums.PLACEHOLDER]) === null || _d === void 0 ? void 0 : _d.url }));
        }))));
};
var OuterContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  ", ";\n  @media screen and (max-width: ", "px) {\n    ", "\n    z-index: 5;\n    margin: 0 -30px;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  ", ";\n  @media screen and (max-width: ", "px) {\n    ", "\n    z-index: 5;\n    margin: 0 -30px;\n  }\n"])), function (props) { return props.isLoading && props.theme.blur.loading; }, function (props) { return props.theme.breakpoints.md; }, stackedCardStyles);
var HeaderContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 16px;\n"], ["\n  display: flex;\n  flex: 1;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 16px;\n"])));
var BenefitsContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
var BenefitRow = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  grid-auto-rows: auto;\n  margin-top: 16px;\n  > :nth-child(2n + 1) {\n    margin: 0 16px 16px 0;\n  }\n  @media screen and (max-width: ", "px) {\n    grid-template-columns: repeat(1, 1fr);\n    > :nth-child(2n + 1) {\n      margin: 0;\n    }\n  }\n"], ["\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  grid-auto-rows: auto;\n  margin-top: 16px;\n  > :nth-child(2n + 1) {\n    margin: 0 16px 16px 0;\n  }\n  @media screen and (max-width: ", "px) {\n    grid-template-columns: repeat(1, 1fr);\n    > :nth-child(2n + 1) {\n      margin: 0;\n    }\n  }\n"])), function (props) { return props.theme.breakpoints.md; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;

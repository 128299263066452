var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { AddressItem } from 'components/AddressItem';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Typography } from 'designSystem';
import { useScreenSizing } from 'hooks';
var AddressSelectionContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  flex: 3;\n"], ["\n  display: flex;\n  flex-direction: column;\n  flex: 3;\n"])));
export var AddressSelection = function (_a) {
    var facilities = _a.facilities, orderData = _a.orderData, setOrderData = _a.setOrderData;
    var _b = __read(useState((orderData === null || orderData === void 0 ? void 0 : orderData.facility) || null), 2), selectedFacility = _b[0], setSelectedFacility = _b[1];
    var isMobile = useScreenSizing().isMobile;
    useEffect(function () {
        setOrderData(__assign(__assign({}, orderData), { facility: selectedFacility }));
    }, [selectedFacility]);
    return (React.createElement(AddressSelectionContainer, { "data-automation-id": "order-address-container" },
        React.createElement(Typography, { variant: "headline", color: "alt" }, "Active Facilities"),
        facilities.map(function (facility) { return (React.createElement(AddressItem, { facility: facility, selectable: true, hasRadioButton: !isMobile, selectedFacility: selectedFacility || undefined, onSelect: setSelectedFacility })); })));
};
var templateObject_1;

import React from 'react';
import View from './View';
import { getConsumerRewardOptInStatusRewards, getIndividualAccountLoyalty } from 'context/mockData/data';
import { useUser } from "../../context";
var RewardsProgressContainer = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j;
    var fullWidth = _a.fullWidth;
    var account = useUser().account;
    return (React.createElement(View, { fullWidth: fullWidth, isLoading: false, rewardVialProgress: {
            "rewardsRequiredForNextFreeVial": 9,
            "rewardsRedeemedTowardsNextFreeVial": ((_b = account === null || account === void 0 ? void 0 : account.rewardPointsEarned) !== null && _b !== void 0 ? _b : 0) % 9,
        }, canOptIn: (_c = getConsumerRewardOptInStatusRewards === null || getConsumerRewardOptInStatusRewards === void 0 ? void 0 : getConsumerRewardOptInStatusRewards.canOptIn) !== null && _c !== void 0 ? _c : false, isInactive: (_d = getConsumerRewardOptInStatusRewards === null || getConsumerRewardOptInStatusRewards === void 0 ? void 0 : getConsumerRewardOptInStatusRewards.isInactive) !== null && _d !== void 0 ? _d : false, status: (_e = getConsumerRewardOptInStatusRewards === null || getConsumerRewardOptInStatusRewards === void 0 ? void 0 : getConsumerRewardOptInStatusRewards.status) !== null && _e !== void 0 ? _e : null, isLocked: (_g = (_f = getConsumerRewardOptInStatusRewards === null || getConsumerRewardOptInStatusRewards === void 0 ? void 0 : getConsumerRewardOptInStatusRewards.lockStatus) === null || _f === void 0 ? void 0 : _f.isLocked) !== null && _g !== void 0 ? _g : false, vialsPurchased: (_j = (_h = getIndividualAccountLoyalty === null || getIndividualAccountLoyalty === void 0 ? void 0 : getIndividualAccountLoyalty.accountLoyalty) === null || _h === void 0 ? void 0 : _h.vialsPurchased) !== null && _j !== void 0 ? _j : 0 }));
};
export default RewardsProgressContainer;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { EvolusRewardsView } from './View';
import { evolusRewardsMockResponse } from 'context/mockData/data';
export var EvolusRewardsContainer = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
    var onUpdateStatus = function (newStatus) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            console.log('@TODO IPG opt out', newStatus);
            return [2];
        });
    }); };
    return (React.createElement(EvolusRewardsView, { lockStatus: (_b = (_a = evolusRewardsMockResponse === null || evolusRewardsMockResponse === void 0 ? void 0 : evolusRewardsMockResponse.data) === null || _a === void 0 ? void 0 : _a.getConsumerRewardOptInStatus) === null || _b === void 0 ? void 0 : _b.lockStatus, canOptIn: (_e = (_d = (_c = evolusRewardsMockResponse === null || evolusRewardsMockResponse === void 0 ? void 0 : evolusRewardsMockResponse.data) === null || _c === void 0 ? void 0 : _c.getConsumerRewardOptInStatus) === null || _d === void 0 ? void 0 : _d.canOptIn) !== null && _e !== void 0 ? _e : false, isInactive: (_h = (_g = (_f = evolusRewardsMockResponse === null || evolusRewardsMockResponse === void 0 ? void 0 : evolusRewardsMockResponse.data) === null || _f === void 0 ? void 0 : _f.getConsumerRewardOptInStatus) === null || _g === void 0 ? void 0 : _g.isInactive) !== null && _h !== void 0 ? _h : false, freeVialsAvailable: (_l = (_k = (_j = evolusRewardsMockResponse === null || evolusRewardsMockResponse === void 0 ? void 0 : evolusRewardsMockResponse.data) === null || _j === void 0 ? void 0 : _j.getFreeVialsAvailable) === null || _k === void 0 ? void 0 : _k.freeVialsAvailable) !== null && _l !== void 0 ? _l : 0, optInStatus: (_o = (_m = evolusRewardsMockResponse === null || evolusRewardsMockResponse === void 0 ? void 0 : evolusRewardsMockResponse.data) === null || _m === void 0 ? void 0 : _m.getConsumerRewardOptInStatus) === null || _o === void 0 ? void 0 : _o.status, loading: false, onUpdateStatus: onUpdateStatus, vialsPurchased: (_r = (_q = (_p = evolusRewardsMockResponse === null || evolusRewardsMockResponse === void 0 ? void 0 : evolusRewardsMockResponse.data) === null || _p === void 0 ? void 0 : _p.getIndividualAccountLoyalty) === null || _q === void 0 ? void 0 : _q.accountLoyalty) === null || _r === void 0 ? void 0 : _r.vialsPurchased, updateLoading: false }));
};

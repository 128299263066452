var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var _a;
import { Typography } from 'designSystem';
import React from 'react';
import styled, { css } from 'styled-components';
import EmailSentGraphic from 'assets/message-sent.svg';
import { StepContainer } from '../../StepContainer';
import CheckInProgress from '../../CheckInProgress';
import { useNavigate } from 'react-router';
import { ConsumerRewardEligibilityStatus, EnrollmentMethod, } from 'types/codegen/hooks';
import { TextMessageBlock } from '../../TextMessageBlock';
import { EmailMessageBlock } from '../../EmailMessageBlock';
import { useScreenSizing } from 'hooks';
import { addMobileStyles } from 'utils/addMobileStyles';
import { customBreakpointStyles } from 'utils/customBreakpointStyles';
import { PhoneNumber } from '../../PhoneNumber';
var configByStatus = (_a = {},
    _a[ConsumerRewardEligibilityStatus.Expired] = {
        messageText: function () {
            return "Timing is everything. Unfortunately, your previous reward expired \uD83D\uDE29 We'll send you a new Evolus Rewards coupon on...";
        },
    },
    _a[ConsumerRewardEligibilityStatus.NotYetActive] = {
        messageText: function () {
            return "We get it. You want what you want. But you're not eligible for your next Evolus Rewards treatment yet \uD83E\uDD8B...";
        },
    },
    _a[ConsumerRewardEligibilityStatus.Eligible] = {
        messageText: function (_a) {
            var rewardAmount = _a.rewardAmount;
            return "Psst... You still have $".concat(rewardAmount, " off your next treatment with Evolus Rewards! \uD83E\uDD8B...");
        },
    },
    _a);
var subHeaderGenerator = function (_a) {
    var phoneNumber = _a.phoneNumber, email = _a.email, enrollmentMethod = _a.enrollmentMethod, status = _a.status;
    if (status === ConsumerRewardEligibilityStatus.Expired) {
        return (React.createElement(React.Fragment, null,
            "New reward successfully sent to",
            ' ',
            React.createElement("b", null, enrollmentMethod === EnrollmentMethod.Email ? (email) : (React.createElement(PhoneNumber, { phoneNumber: phoneNumber })))));
    }
    if (enrollmentMethod === EnrollmentMethod.Email) {
        return (React.createElement(React.Fragment, null,
            "Email reminder successfully sent to ",
            React.createElement("b", null, email)));
    }
    return (React.createElement(React.Fragment, null,
        "Text reminder successfully sent to",
        ' ',
        React.createElement("b", null,
            React.createElement(PhoneNumber, { phoneNumber: phoneNumber }))));
};
export var RewardReminderSent = function (_a) {
    var _b;
    var status = _a.status, noPromptRestart = _a.noPromptRestart, phoneNumber = _a.phoneNumber, email = _a.email, enrollmentMethod = _a.enrollmentMethod, rewardAmount = _a.rewardAmount;
    var navigate = useNavigate();
    var isMobile = useScreenSizing().isMobile;
    var messageText = ((_b = configByStatus[status]) !== null && _b !== void 0 ? _b : {}).messageText;
    var isEmailEnrollment = enrollmentMethod === EnrollmentMethod.Email;
    var message = messageText({
        rewardAmount: rewardAmount,
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(StepContainer, { hideRestart: true, label: isMobile ? null : 'Reward Status' },
            React.createElement(OuterContainer, null,
                React.createElement(LeftContainer, null,
                    React.createElement(Typography, { display: "block", mb: 20 }, subHeaderGenerator({
                        phoneNumber: phoneNumber,
                        email: email,
                        enrollmentMethod: enrollmentMethod,
                        status: status,
                    })),
                    !isEmailEnrollment ? (React.createElement(TextMessageBlock, { message: message })) : (React.createElement(EmailMessageBlock, { message: message }))),
                React.createElement(RightContainer, null,
                    React.createElement(Image, { invert: true, src: EmailSentGraphic })))),
        React.createElement(CheckInProgress, { primaryButtonText: "Check Another Status", onContinue: noPromptRestart, secondaryButtonText: "Return to Dashboard", onSecondaryContinue: function () { return navigate('/rewards/dashboard'); } })));
};
var customBreakpoint = 1180;
var OuterContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  border: 1px solid ", ";\n\n  padding: 32px;\n  min-height: 25vh;\n\n  ", ";\n  @media screen and (max-width: ", "px) {\n    border: none;\n    flex-direction: column-reverse;\n    align-items: center;\n    justify-content: center;\n    padding: 0;\n  }\n"], ["\n  display: flex;\n  border: 1px solid ", ";\n\n  padding: 32px;\n  min-height: 25vh;\n\n  ", ";\n  @media screen and (max-width: ", "px) {\n    border: none;\n    flex-direction: column-reverse;\n    align-items: center;\n    justify-content: center;\n    padding: 0;\n  }\n"])), function (props) { return props.theme.colors.border.base; }, customBreakpointStyles(customBreakpoint, css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      flex-direction: column;\n      justify-content: unset;\n    "], ["\n      flex-direction: column;\n      justify-content: unset;\n    "])))), function (props) { return props.theme.breakpoints.md; });
var LeftContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  justify-content: center;\n  margin-right: 40px;\n  ", ";\n  @media screen and (max-width: ", "px) {\n    margin-right: 0;\n    justify-content: flex-start;\n  }\n"], ["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  justify-content: center;\n  margin-right: 40px;\n  ", ";\n  @media screen and (max-width: ", "px) {\n    margin-right: 0;\n    justify-content: flex-start;\n  }\n"])), customBreakpointStyles(customBreakpoint, css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      margin-right: 0;\n    "], ["\n      margin-right: 0;\n    "])))), function (props) { return props.theme.breakpoints.md; });
var RightContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  justify-content: center;\n  align-items: center;\n"], ["\n  display: flex;\n  flex: 1;\n  justify-content: center;\n  align-items: center;\n"])));
var Image = styled.img(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  max-width: 100%;\n  max-height: 100%;\n  resize-mode: contain;\n  margin: 20px 0;\n  ", "\n  ", ";\n"], ["\n  max-width: 100%;\n  max-height: 100%;\n  resize-mode: contain;\n  margin: 20px 0;\n  ", "\n  ", ";\n"])), addMobileStyles(css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    margin: 0 0 20px;\n  "], ["\n    margin: 0 0 20px;\n  "])))), function (props) {
    return props.invert
        ? css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n          transform: scaleX(-1);\n        "], ["\n          transform: scaleX(-1);\n        "]))) : '';
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;

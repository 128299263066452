var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { StepContainer } from '../../StepContainer';
import CheckInProgress from '../../CheckInProgress';
import { PlaceholderLoading, Toast, Typography } from 'designSystem';
import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router';
import { StepEnum } from '../../../Enums/StepEnum';
import { ConsumerRewardEligibilityStatus, EnrollmentMethod, } from 'types/codegen/hooks';
import { format } from 'date-fns';
import { add, parseISO, sub } from 'date-fns/esm';
import { RewardAvailable } from './components/RewardAvailable';
import { RewardNotYetValid } from './components/RewardNotYetValid';
import { RewardExpired } from './components/RewardExpired';
import { StatusHeader } from './components/StatusHeader';
import { addMobileStyles } from 'utils/addMobileStyles';
import { useScreenSizing } from 'hooks';
import { TextMessageBlock } from '../../TextMessageBlock';
import { EmailMessageBlock } from '../../EmailMessageBlock';
import OnwardsImage from 'assets/check-in-graphic-onwards.svg';
import { customBreakpointStyles } from 'utils/customBreakpointStyles';
import { PhoneNumber } from '../../PhoneNumber';
import { TwentyUnitsFreeBanner } from './components/TwentyUnitsBanner';
var formatDateAdd = function (date) {
    return format(add(parseISO(date), {
        minutes: new Date(date).getTimezoneOffset(),
    }), 'MM/dd/yyyy');
};
var formatDate = function (date) { return format(parseISO(date), 'MM/dd/yyyy'); };
var formatDateSub = function (date) {
    return format(sub(parseISO(date), {
        minutes: new Date(date).getTimezoneOffset(),
    }), 'MM/dd/yyyy');
};
export var RewardStatusView = function (_a) {
    var _b, _c, _d;
    var consumerProfile = _a.consumerProfile, stepLoading = _a.stepLoading, sendLoading = _a.sendLoading, phoneNumber = _a.phoneNumber, enrolledThisSession = _a.enrolledThisSession, error = _a.error, facilityName = _a.facilityName, rewardReminderSent = _a.rewardReminderSent, resentEnrollmentByEmail = _a.resentEnrollmentByEmail, handleCreateReward = _a.handleCreateReward, handleResendEnrollmentEmail = _a.handleResendEnrollmentEmail, handleSendStatusReminder = _a.handleSendStatusReminder, handleSendRewardReminder = _a.handleSendRewardReminder, noPromptRestart = _a.noPromptRestart, onRemind = _a.onRemind, onRewardAction = _a.onRewardAction;
    var navigate = useNavigate();
    var isMobile = useScreenSizing().isMobile;
    var _e = (_b = consumerProfile === null || consumerProfile === void 0 ? void 0 : consumerProfile.reward) !== null && _b !== void 0 ? _b : {}, eligibleAt = _e.eligibleAt, expiration = _e.expiration, earnedAt = _e.earnedAt, consumerRewardAmount = _e.rewardAmount, rewardStatus = _e.eligibilityStatus, rewardSource = _e.rewardSource, rewardAmountUpdateReason = _e.rewardAmountUpdateReason;
    var _f = (_c = consumerProfile === null || consumerProfile === void 0 ? void 0 : consumerProfile.promotion) !== null && _c !== void 0 ? _c : {}, promoRewardAmount = _f.amount, campaignId = _f.campaignId;
    var _g = (_d = consumerProfile === null || consumerProfile === void 0 ? void 0 : consumerProfile.profile) !== null && _d !== void 0 ? _d : {}, enrollmentMethod = _g.enrollmentMethod, email = _g.email;
    var isEmailEnrollment = enrollmentMethod === EnrollmentMethod.Email;
    var rewardAmount = promoRewardAmount !== null && promoRewardAmount !== void 0 ? promoRewardAmount : consumerRewardAmount;
    var isNewProfile = rewardSource === 'NEW_PROFILE' ||
        rewardSource === 'NEW_PROFILE_FROM_REFERRAL';
    var isBusCampaign = campaignId === null || campaignId === void 0 ? void 0 : campaignId.includes('BUS_20U');
    var isEligible = rewardStatus === ConsumerRewardEligibilityStatus.Eligible;
    var formattedEligibleAt = eligibleAt && formatDateAdd(eligibleAt);
    var formattedEarnedAt = earnedAt && formatDateAdd(earnedAt);
    var formattedExpiration = expiration && formatDate(expiration);
    var handleReminderSent = function (_a) {
        var startDate = _a.startDate, endDate = _a.endDate, rewardAmount = _a.rewardAmount;
        var email = (consumerProfile === null || consumerProfile === void 0 ? void 0 : consumerProfile.profile).email;
        onRemind({
            rewardStatus: rewardStatus,
            enrollmentMethod: enrollmentMethod,
            email: email,
            startDate: startDate,
            endDate: endDate,
            rewardAmount: rewardAmount,
        });
        navigate(StepEnum.RewardReminderSent);
    };
    var getRewardStatusButtonLabel = function () {
        if (rewardStatus === ConsumerRewardEligibilityStatus.Eligible) {
            return 'Continue with Check In';
        }
        if (rewardStatus === ConsumerRewardEligibilityStatus.ExpiredInGracePeriod) {
            return 'Check In Patient';
        }
        if (rewardStatus === ConsumerRewardEligibilityStatus.Expired) {
            return 'Send Patient New Reward';
        }
        return 'Send Reminder to Patient';
    };
    var renderStatusCard = function () {
        if (rewardStatus === ConsumerRewardEligibilityStatus.Eligible ||
            rewardStatus === ConsumerRewardEligibilityStatus.ExpiredInGracePeriod) {
            return (React.createElement(RewardAvailable, { isBusCampaign: isBusCampaign, newProfileFromReferral: rewardSource === 'NEW_PROFILE_FROM_REFERRAL', rewardAmountUpdateReason: rewardAmountUpdateReason, rewardAmount: rewardAmount, expiredInGrace: rewardStatus ===
                    ConsumerRewardEligibilityStatus.ExpiredInGracePeriod }));
        }
        if (rewardStatus === ConsumerRewardEligibilityStatus.NotYetActive) {
            return React.createElement(RewardNotYetValid, { validDate: formattedEligibleAt });
        }
        if (rewardStatus === ConsumerRewardEligibilityStatus.Expired) {
            return React.createElement(RewardExpired, { rewardAmount: rewardAmount });
        }
        return null;
    };
    var renderRewardMessage = function () {
        var amount = isBusCampaign ? 40 : rewardAmount;
        var emailMessage = "Your $".concat(amount, " off reward is now available to use on your next Jeuveau\u00AE (prabotulinumtoxinA-xvfs) treatment at ").concat(facilityName, "!");
        if (resentEnrollmentByEmail || isEmailEnrollment) {
            return React.createElement(EmailMessageBlock, { message: emailMessage });
        }
        var textMessage = "Here\u2019s $".concat(rewardAmount, " off your next Jeuveau\u00AE (prabotulinumtoxinA-xvfs) treatment at ").concat(facilityName, "!...");
        return React.createElement(TextMessageBlock, { message: textMessage });
    };
    var renderContent = function () {
        if (stepLoading || error) {
            return React.createElement(PlaceholderLoading, { size: "extra-small" });
        }
        var showTextMessage = !resentEnrollmentByEmail && !isEmailEnrollment;
        return (React.createElement(React.Fragment, null,
            isNewProfile && enrolledThisSession && (React.createElement(Toast, { message: "Patient enrolled! Check in ".concat(isMobile ? 'below' : 'on the right', " if the patient has been treated today."), type: "success" })),
            !enrolledThisSession && (React.createElement(StatusHeader, { rewardStatus: rewardStatus, earnedAt: formattedEarnedAt, eligibleAt: formattedEligibleAt, expiration: formattedExpiration, isNewProfile: isNewProfile })),
            isBusCampaign && React.createElement(TwentyUnitsFreeBanner, null),
            enrolledThisSession && isMobile && (React.createElement(MobileImage, { src: OnwardsImage, alt: "onwards" })),
            !enrolledThisSession && renderStatusCard(),
            rewardReminderSent && (React.createElement(RewardMessageContainer, null,
                React.createElement("div", null,
                    showTextMessage ? (React.createElement(Typography, { variant: "body", mt: isMobile ? 16 : 8, mb: 16 },
                        "Text successfully sent to",
                        ' ',
                        React.createElement("strong", null,
                            React.createElement(PhoneNumber, { phoneNumber: phoneNumber })),
                        ".")) : (React.createElement(Typography, { variant: "body", color: "alt", mt: isMobile ? 16 : 8, mb: 16 },
                        "Email reminder successfully sent to",
                        ' ',
                        React.createElement(Typography, { variant: "headline", bold: true }, email))),
                    renderRewardMessage(),
                    !isEmailEnrollment && !resentEnrollmentByEmail && (React.createElement(ResendByMailContainer, null,
                        React.createElement(Typography, { color: "alt", variant: "bodySmall" },
                            "Having trouble sending reward?",
                            ' '),
                        React.createElement(Link, { onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4, handleResendEnrollmentEmail()];
                                        case 1:
                                            _a.sent();
                                            return [2];
                                    }
                                });
                            }); } }, "Send via email")))),
                !isMobile && React.createElement("img", { src: OnwardsImage, alt: "onwards" })))));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(StepContainer, { hideRestart: true, label: isMobile ? null : 'Patient Reward Status' }, renderContent()),
        React.createElement(CheckInProgress, { isLoading: stepLoading || sendLoading, primaryButtonText: getRewardStatusButtonLabel(), secondaryButtonText: isEligible && !enrolledThisSession ? 'Send Reminder to Patient' : null, onSecondaryContinue: function () { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4, handleSendRewardReminder()];
                        case 1:
                            _a.sent();
                            handleReminderSent({
                                startDate: formattedEligibleAt,
                                endDate: formattedExpiration,
                                rewardAmount: rewardAmount,
                            });
                            return [2];
                    }
                });
            }); }, tertiaryAction: noPromptRestart, tertiaryButtonText: "Check Another Status", onContinue: function () { return __awaiter(void 0, void 0, void 0, function () {
                var res, createdAmount, createdEligible, createdExpiry;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (isEligible ||
                                rewardStatus ===
                                    ConsumerRewardEligibilityStatus.ExpiredInGracePeriod) {
                                navigate(StepEnum.SpecialistSelect);
                                return [2];
                            }
                            if (!(rewardStatus === ConsumerRewardEligibilityStatus.Expired)) return [3, 2];
                            onRewardAction('primary');
                            return [4, handleCreateReward()];
                        case 1:
                            res = _a.sent();
                            if (!res) {
                                return [2];
                            }
                            createdAmount = res.rewardAmount, createdEligible = res.eligibleAt, createdExpiry = res.expiration;
                            handleReminderSent({
                                rewardAmount: createdAmount,
                                startDate: formatDateAdd(createdEligible),
                                endDate: formatDateSub(createdExpiry),
                            });
                            return [3, 4];
                        case 2: return [4, handleSendStatusReminder()];
                        case 3:
                            _a.sent();
                            onRewardAction('primary');
                            handleReminderSent({
                                startDate: formattedEligibleAt,
                                endDate: formattedExpiration,
                                rewardAmount: rewardAmount,
                            });
                            _a.label = 4;
                        case 4: return [2];
                    }
                });
            }); } })));
};
var ResendByMailContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 15px;\n"], ["\n  margin-top: 15px;\n"])));
var Link = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  all: unset;\n  cursor: pointer;\n  color: ", ";\n  @media screen and (max-width: ", "px) {\n    margin-left: 0;\n  }\n  &:hover {\n    text-decoration: underline;\n  }\n"], ["\n  all: unset;\n  cursor: pointer;\n  color: ", ";\n  @media screen and (max-width: ", "px) {\n    margin-left: 0;\n  }\n  &:hover {\n    text-decoration: underline;\n  }\n"])), function (props) { return props.theme.colors.text.link; }, function (props) { return props.theme.breakpoints.md; });
var RewardMessageContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", ";\n  margin-top: 32px;\n  padding: 32px;\n  display: flex;\n  align-items: center;\n  gap: 32px;\n  & > div {\n    display: flex;\n    flex-direction: column;\n    flex: 1;\n  }\n  & > img {\n    width: 100%;\n    max-width: 260px;\n  }\n  ", "\n  ", "\n"], ["\n  ", ";\n  margin-top: 32px;\n  padding: 32px;\n  display: flex;\n  align-items: center;\n  gap: 32px;\n  & > div {\n    display: flex;\n    flex-direction: column;\n    flex: 1;\n  }\n  & > img {\n    width: 100%;\n    max-width: 260px;\n  }\n  ", "\n  ", "\n"])), function (props) { return props.theme.borders.base; }, customBreakpointStyles(1180, css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      flex-direction: column;\n    "], ["\n      flex-direction: column;\n    "])))), addMobileStyles(css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    border: none;\n    padding: 0;\n    margin: 24px 0 0;\n    flex-direction: column;\n  "], ["\n    border: none;\n    padding: 0;\n    margin: 24px 0 0;\n    flex-direction: column;\n  "])))));
var MobileImage = styled.img(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  align-self: center;\n  max-width: 140px;\n  margin-top: 24px;\n"], ["\n  align-self: center;\n  max-width: 140px;\n  margin-top: 24px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;

import React from 'react';
import { KickerCardInProgress } from './KickerCardInProgress';
import { KickerCardComplete } from './KickerCardComplete';
export var KickerCard = function (_a) {
    var meta = _a.meta, index = _a.index;
    var qualifyingVialsNeeded = meta.qualifyingVialsNeeded, qualifyingVialsPurchased = meta.qualifyingVialsPurchased;
    if (qualifyingVialsPurchased >= qualifyingVialsNeeded) {
        return React.createElement(KickerCardComplete, { meta: meta, index: index });
    }
    return React.createElement(KickerCardInProgress, { meta: meta, index: index });
};

import React from 'react';
export var Pineapple = function (_a) {
    var color = _a.color;
    return (React.createElement("svg", { width: "48", height: "48", viewBox: "0 0 48 48", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M19.5439 12.958L23.9999 1.5L28.1999 12.302", stroke: color, strokeWidth: "3", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M20.482 16.982C20.482 16.982 20 6.5 10 6.5C12.4949 11.0092 13.8025 16.0786 13.8 21.232", stroke: color, strokeWidth: "3", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M26.7998 16.8C26.7998 16.8 27.9998 6.5 37.9998 6.5C35.8088 11.1247 34.5191 16.1245 34.1998 21.232", stroke: color, strokeWidth: "3", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M24 46.5C31.732 46.5 38 39.7843 38 31.5C38 23.2157 31.732 16.5 24 16.5C16.268 16.5 10 23.2157 10 31.5C10 39.7843 16.268 46.5 24 46.5Z", stroke: color, strokeWidth: "3", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M11.4209 38.08L31.0009 18.5", stroke: color, strokeWidth: "3", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M17.0068 44.494L36.5808 24.92", stroke: color, strokeWidth: "3", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M36.5818 38.0819L17.0078 18.5079", stroke: color, strokeWidth: "3", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M31.0009 44.5L11.4209 24.92", stroke: color, strokeWidth: "3", strokeLinecap: "round", strokeLinejoin: "round" })));
};

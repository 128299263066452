var _a, _b;
function getEnv(name) {
    var env = process.env;
    var ENV = env.REACT_APP_ENV;
    var envKey = "".concat(name, "_").concat(ENV);
    return envKey in env ? env[envKey] : env[name];
}
var environment = {
    gtmContainerId: getEnv('REACT_APP_GTM_CONTAINER_ID'),
    graphqlUrl: getEnv('REACT_APP_GRAPHQL_URL'),
    cognitoAppClientId: getEnv('REACT_APP_COGNITO_APP_CLIENT_ID'),
    cognitoIdentityPoolId: getEnv('REACT_APP_COGNITO_IDENTITY_POOL_ID'),
    cognitoUserPoolId: getEnv('REACT_APP_COGNITO_USER_POOL_ID'),
    commitHash: getEnv('REACT_APP_COMMIT_HASH'),
    awsRegion: getEnv('REACT_APP_AWS_REGION'),
    maxAttachmentSize: getEnv('REACT_APP_MAX_ATTACHMENT_SIZE'),
    version: (_a = getEnv('REACT_APP_VERSION')) !== null && _a !== void 0 ? _a : 'unknown',
    intercomApp: getEnv('REACT_APP_INTERCOM_APP_ID'),
    rollbarApiKey: getEnv('REACT_APP_ROLLBAR_API_KEY'),
    rollbarCodeVersion: (_b = getEnv('REACT_APP_VERSION')) !== null && _b !== void 0 ? _b : 'unknown',
    rollbarEnvironment: getEnv('REACT_APP_ROLLBAR_ENVIRONMENT'),
    segmentWriteKey: getEnv('REACT_APP_SEGMENT_WRITE_KEY'),
    mapboxAccessToken: getEnv('REACT_APP_MAPBOX_ACCESS_TOKEN'),
    updateInterval: isNaN(Number(getEnv('REACT_APP_UPDATE_INTERVAL')))
        ? 60000
        : Number(getEnv('REACT_APP_UPDATE_INTERVAL')),
    maintenanceInterval: isNaN(Number(getEnv('REACT_APP_MAINTENANCE_INTERVAL')))
        ? 60000
        : Number(getEnv('REACT_APP_MAINTENANCE_INTERVAL')),
    isStaticApp: getEnv('REACT_APP_STATIC_WEB') === 'true',
    domain: getEnv('REACT_APP_DOMAIN'),
    env: process.env.REACT_APP_ENV,
};
export default environment;

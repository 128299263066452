var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from 'react';
import { StepContainer } from '../StepContainer';
import { PaymentProgress } from '../PaymentProgress';
import { SingleInvoiceItem } from '../../../../components/SingleInvoiceItem';
import { getInvoiceStatus } from '../../../../constants';
import styled, { css } from 'styled-components';
import { PaymentOption } from '../../enums/PaymentOptions';
import { useScreenSizing } from 'hooks';
import { MobileContinueButton } from '../MobileContinueButton';
import { Typography } from 'designSystem';
import { currencyFormatter } from 'utils/currencyFormatter';
import { addMobileStyles } from '../../../../../../utils/addMobileStyles';
export var SelectInvoices = function (_a) {
    var _b;
    var invoices = _a.invoices, onContinue = _a.onContinue;
    var isMobile = useScreenSizing().isMobile;
    var _c = __read(useState((_b = invoices === null || invoices === void 0 ? void 0 : invoices.map(function (i) { return i.invoiceId; })) !== null && _b !== void 0 ? _b : []), 2), selectedInvoices = _c[0], setSelectedInvoices = _c[1];
    var handleContinue = function () {
        onContinue(invoices.filter(function (i) { return selectedInvoices.includes(i.invoiceId); }));
    };
    var continueDisabled = selectedInvoices.length < 1;
    var total = invoices === null || invoices === void 0 ? void 0 : invoices.filter(function (i) { return selectedInvoices.includes(i.invoiceId); }).reduce(function (acc, cv) { var _a; return acc + ((_a = cv.amountDue) !== null && _a !== void 0 ? _a : 0); }, 0);
    return (React.createElement(React.Fragment, null,
        React.createElement(StepContainer, { subHeader: "Make A Payment", header: "Select Invoices To Pay", tertiaryHeader: "Select the invoices you want to pay" },
            React.createElement(Container, null,
                React.createElement("div", null,
                    React.createElement(Typography, { variant: isMobile ? 'headline' : 'title', color: "alt" }, "Amount Paying Today"),
                    React.createElement("div", null,
                        React.createElement(Typography, { variant: isMobile ? 'title' : 'titleLarge' }, currencyFormatter(total, 2)),
                        React.createElement(Typography, { variant: isMobile ? 'caption' : 'headlineSmall', color: "alt5" }, selectedInvoices === null || selectedInvoices === void 0 ? void 0 :
                            selectedInvoices.length,
                            " Invoice(s)"))), invoices === null || invoices === void 0 ? void 0 :
                invoices.map(function (i) {
                    var _a, _b, _c;
                    var invoiceStatus = getInvoiceStatus(i);
                    var selected = selectedInvoices.includes(i.invoiceId);
                    return (React.createElement(SingleInvoiceItem, { selectable: true, onSelect: function () {
                            if (selected) {
                                setSelectedInvoices(selectedInvoices.filter(function (id) { return id !== i.invoiceId; }));
                                return;
                            }
                            setSelectedInvoices(__spreadArray(__spreadArray([], __read(selectedInvoices), false), [i.invoiceId], false));
                        }, selected: selected, status: invoiceStatus, invoiceId: i.invoiceId, amount: (_a = i.amountDue) !== null && _a !== void 0 ? _a : 0, date: i.date, dueDate: i.dueDate, facilityName: (_b = i.facility) === null || _b === void 0 ? void 0 : _b.name, quantity: (_c = i.jeuveauQuantity) !== null && _c !== void 0 ? _c : 0 }));
                })),
            isMobile && (React.createElement(MobileContinueButton, { label: "Select Payment Method", onContinue: handleContinue, disabled: continueDisabled }))),
        !isMobile && (React.createElement(PaymentProgress, { paymentOption: PaymentOption.SELECT_INVOICES, primaryButtonDisabled: continueDisabled, primaryButtonLabel: "Select Payment Method", primaryAction: handleContinue }))));
};
var Container = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  gap: 16px;\n  margin-top: 32px;\n  & > div:first-of-type {\n    display: flex;\n    align-items: flex-end;\n    justify-content: space-between;\n    margin-bottom: 16px;\n    ", "\n    & > div {\n      display: flex;\n      align-items: flex-end;\n      gap: 12px;\n      ", "\n    }\n  }\n"], ["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  gap: 16px;\n  margin-top: 32px;\n  & > div:first-of-type {\n    display: flex;\n    align-items: flex-end;\n    justify-content: space-between;\n    margin-bottom: 16px;\n    ", "\n    & > div {\n      display: flex;\n      align-items: flex-end;\n      gap: 12px;\n      ", "\n    }\n  }\n"])), addMobileStyles(css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      align-items: flex-start;\n      margin-bottom: 0;\n    "], ["\n      align-items: flex-start;\n      margin-bottom: 0;\n    "])))), addMobileStyles(css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        flex-direction: column;\n        gap: 4px;\n        justify-content: flex-end;\n      "], ["\n        flex-direction: column;\n        gap: 4px;\n        justify-content: flex-end;\n      "])))));
var templateObject_1, templateObject_2, templateObject_3;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { Button } from '../../../../../designSystem';
export var PaginationBar = function (_a) {
    var currentPage = _a.currentPage, numberOfPages = _a.numberOfPages, onSelectPage = _a.onSelectPage;
    if (numberOfPages === 1) {
        return null;
    }
    return (React.createElement(PageSelectContainer, null,
        React.createElement(Button, { onClick: function () {
                onSelectPage(currentPage - 1);
            }, variant: "quaternary", disabled: currentPage === 0 }, '<'),
        Array.from({ length: numberOfPages }, function (_, i) { return (React.createElement(PageButton, { active: currentPage === i, variant: "quaternary", onClick: function () {
                if (currentPage === i) {
                    return;
                }
                onSelectPage(i);
            } }, i + 1)); }),
        React.createElement(Button, { onClick: function () {
                onSelectPage(currentPage + 1);
            }, variant: "quaternary", disabled: currentPage === numberOfPages - 1 }, '>')));
};
var PageButton = styled(Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-radius: 2px;\n  padding: 0 8px;\n  background: ", ";\n  color: ", ";\n"], ["\n  border-radius: 2px;\n  padding: 0 8px;\n  background: ", ";\n  color: ", ";\n"])), function (props) {
    return props.active ? props.theme.colors.text.alt : 'transparent';
}, function (props) {
    return props.active ? props.theme.colors.text.alt3 : props.theme.colors.text.alt;
});
var PageSelectContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  justify-content: flex-end;\n  margin-top: 16px;\n"], ["\n  display: flex;\n  flex: 1;\n  justify-content: flex-end;\n  margin-top: 16px;\n"])));
var templateObject_1, templateObject_2;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var _a;
import { Typography } from 'designSystem';
import React from 'react';
import styled from 'styled-components';
import OptedOutGraphic from 'assets/rewards-check-in-suspended.svg';
import LockedGraphic from 'assets/locked-out.svg';
import { useScreenSizing } from '../../../../hooks';
var Context;
(function (Context) {
    Context["LOCKED"] = "LOCKED";
    Context["INACTIVE"] = "INACTIVE";
    Context["OPTED_OUT"] = "OPTED_OUT";
})(Context || (Context = {}));
var ContentByContext = (_a = {},
    _a[Context.OPTED_OUT] = {
        title: 'This Account needs to be opted in to Evolus Rewards.',
        image: OptedOutGraphic,
        altTag: 'account-opted-out',
    },
    _a[Context.LOCKED] = {
        title: 'This Account is Disabled.',
        image: LockedGraphic,
        altTag: 'account-inactive',
    },
    _a[Context.INACTIVE] = {
        title: 'This Account is Inactive.',
        image: OptedOutGraphic,
        altTag: 'account-disabled',
    },
    _a);
export var LimitedOptedOut = function (_a) {
    var isInactive = _a.isInactive, isLocked = _a.isLocked;
    var isMobile = useScreenSizing().isMobile;
    var context = Context.OPTED_OUT;
    if (isInactive) {
        context = Context.INACTIVE;
    }
    if (isLocked) {
        context = Context.LOCKED;
    }
    var _b = ContentByContext[context], title = _b.title, image = _b.image, altTag = _b.altTag;
    return (React.createElement(Container, null,
        React.createElement(Image, { src: image, alt: altTag }),
        React.createElement("div", null,
            React.createElement(Typography, { display: "block", mb: isMobile ? 16 : 12, variant: "titleLarge" }, title),
            React.createElement(Typography, { color: "alt" }, "Please contact your Practice Owner / Evolus Rewards Admin."))));
};
var Image = styled.img(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  max-width: 300px;\n"], ["\n  width: 100%;\n  max-width: 300px;\n"])));
var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  justify-content: center;\n  align-items: center;\n  background-color: white;\n  margin: 43px 16px 0 16px;\n\n  padding: 48px;\n  gap: 40px;\n  @media screen and (max-width: ", "px) {\n    margin: 16px 0 0;\n    padding: 32px;\n    box-shadow: 0px -2px 4px rgba(207, 208, 208, 0.248416),\n      0px -20px 40px rgba(144, 155, 165, 0.11);\n    flex: 1;\n    min-height: unset;\n    flex-direction: column;\n    justify-content: flex-start;\n    gap: 48px;\n  }\n"], ["\n  display: flex;\n  flex: 1;\n  justify-content: center;\n  align-items: center;\n  background-color: white;\n  margin: 43px 16px 0 16px;\n\n  padding: 48px;\n  gap: 40px;\n  @media screen and (max-width: ", "px) {\n    margin: 16px 0 0;\n    padding: 32px;\n    box-shadow: 0px -2px 4px rgba(207, 208, 208, 0.248416),\n      0px -20px 40px rgba(144, 155, 165, 0.11);\n    flex: 1;\n    min-height: unset;\n    flex-direction: column;\n    justify-content: flex-start;\n    gap: 48px;\n  }\n"])), function (props) { return props.theme.breakpoints.md; });
var templateObject_1, templateObject_2;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useRef, useState } from 'react';
import { IconButton } from 'designSystem';
import styled, { css } from 'styled-components';
import { useOutsideClickHandler } from 'hooks/useOutsideClickHandler';
export var MenuButton = function (_a) {
    var children = _a.children, menuItems = _a.menuItems;
    var _b = __read(useState(false), 2), open = _b[0], setOpen = _b[1];
    var rootRef = useRef(null);
    useOutsideClickHandler({
        element: rootRef.current,
        handler: function () {
            setOpen(false);
        },
    });
    return (React.createElement(Root, { ref: rootRef, variant: "quaternary", onClick: function () {
            setOpen(!open);
        } },
        children,
        React.createElement(FloatingMenuContainer, { open: open },
            React.createElement(NotchWindow, null,
                React.createElement(MenuNotch, null)),
            menuItems)));
};
var Root = styled(IconButton)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n"], ["\n  position: relative;\n"])));
var NotchWindow = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  height: 34px;\n  width: 8px;\n  top: 50%;\n  transform: translateY(-50%);\n  right: -8px;\n  overflow: hidden;\n"], ["\n  position: absolute;\n  height: 34px;\n  width: 8px;\n  top: 50%;\n  transform: translateY(-50%);\n  right: -8px;\n  overflow: hidden;\n"])));
var MenuNotch = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: white;\n  position: absolute;\n  height: 34px;\n  width: 34px;\n  top: 50%;\n  right: 7px;\n  transform: translateY(-50%) rotate(45deg);\n  ", ";\n"], ["\n  background: white;\n  position: absolute;\n  height: 34px;\n  width: 34px;\n  top: 50%;\n  right: 7px;\n  transform: translateY(-50%) rotate(45deg);\n  ", ";\n"])), function (props) { return props.theme.elevation.base.elevation1; });
var FloatingMenuContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", ";\n  min-width: 240px;\n  position: absolute;\n  top: 50%;\n  transform: translateY(-50%);\n  right: 36px;\n  background: white;\n\n  ", ";\n"], ["\n  ", ";\n  min-width: 240px;\n  position: absolute;\n  top: 50%;\n  transform: translateY(-50%);\n  right: 36px;\n  background: white;\n\n  ", ";\n"])), function (props) {
    if (props.open) {
        return css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n        visibility: visible;\n        opacity: 1;\n        transition: opacity ease-in-out 250ms;\n      "], ["\n        visibility: visible;\n        opacity: 1;\n        transition: opacity ease-in-out 250ms;\n      "])));
    }
    return css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      visibility: hidden;\n      opacity: 0;\n      transition: visibility 0s 250ms, opacity 250ms ease-in-out;\n    "], ["\n      visibility: hidden;\n      opacity: 0;\n      transition: visibility 0s 250ms, opacity 250ms ease-in-out;\n    "])));
}, function (props) { return props.theme.elevation.base.elevation1; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;

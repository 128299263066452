var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Typography } from '../../../../../../../designSystem';
import React from 'react';
import styled from 'styled-components';
export var UserInfoField = function (_a) {
    var label = _a.label, value = _a.value;
    return (React.createElement(UserFieldContainer, null,
        React.createElement(Typography, { color: "alt", display: "block" }, label),
        React.createElement(Typography, { display: "block" }, value)));
};
var UserFieldContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-start;\n  width: 100%;\n  flex: 1;\n  gap: 24px;\n  & > span:first-of-type {\n    max-width: 220px;\n    width: 100%;\n  }\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n    gap: 8px;\n    & > span:last-of-type {\n      padding: 12px 16px;\n      background: ", ";\n\n      color: ", ";\n    }\n  }\n"], ["\n  display: flex;\n  justify-content: flex-start;\n  width: 100%;\n  flex: 1;\n  gap: 24px;\n  & > span:first-of-type {\n    max-width: 220px;\n    width: 100%;\n  }\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n    gap: 8px;\n    & > span:last-of-type {\n      padding: 12px 16px;\n      background: ", ";\n\n      color: ", ";\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
}, function (props) { return props.theme.colors.bg.base; }, function (props) { return props.theme.colors.text.alt5; });
var templateObject_1;

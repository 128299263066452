var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { Modal } from '../Modal';
import styled from 'styled-components';
import { Button, Typography } from 'designSystem';
import SYTGraphic from 'assets/almost-double-love.svg';
import { useScreenSizing } from '../../../hooks';
export var SYT2UpsellModal = function (_a) {
    var onDecline = _a.onDecline, onAccept = _a.onAccept, vialsNeededForUpgrade = _a.vialsNeededForUpgrade, totalConsumersEligible = _a.totalConsumersEligible, rewardAmount = _a.rewardAmount;
    var isMobile = useScreenSizing().isMobile;
    return (React.createElement(Modal, null,
        React.createElement(MainContainer, null,
            React.createElement(ContentContainer, null,
                React.createElement(Typography, { variant: "titleLarge" },
                    "Unlock \"$",
                    rewardAmount * 2,
                    " Off\""),
                React.createElement(Typography, { display: "block", mt: 16, mb: 16, color: "alt" },
                    "Add ",
                    vialsNeededForUpgrade,
                    " more vials to qualify for",
                    ' ',
                    React.createElement(Bold, null,
                        "\"$",
                        rewardAmount * 2,
                        " Off\""),
                    " for ",
                    totalConsumersEligible,
                    ' ',
                    "new Jeuveau\u00AE patients and earn even more with Evolus Rewards."),
                React.createElement(Anchor, { href: "#", target: "_blank" },
                    React.createElement(Typography, { color: "link", variant: "caption" }, "Click Here to Learn More")),
                React.createElement(ButtonContainer, null,
                    React.createElement(Button, { size: "xlarge", variant: isMobile ? 'tertiary' : 'outlined', onClick: onDecline }, "No thanks"),
                    React.createElement(Button, { size: "xlarge", variant: "primary", onClick: onAccept },
                        "Add ",
                        vialsNeededForUpgrade,
                        " More Vials"))),
            React.createElement(ImageContainer, null,
                React.createElement(PromoImage, null)))));
};
var MainContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  align-items: center;\n  gap: 48px;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column-reverse;\n    flex: 1;\n  }\n"], ["\n  display: flex;\n  flex: 1;\n  align-items: center;\n  gap: 48px;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column-reverse;\n    flex: 1;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var ContentContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n"], ["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n"])));
var ImageContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: 100%;\n"], ["\n  height: 100%;\n"])));
var ButtonContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-top: 38px;\n  display: flex;\n  gap: 16px;\n  & > button:last-of-type {\n    flex: 1;\n    max-width: 270px;\n  }\n\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column-reverse;\n    flex: 1;\n    margin-top: 32px;\n    & > button {\n      width: 100%;\n      max-width: unset;\n      &:last-of-type {\n        flex: none;\n        max-width: none;\n      }\n    }\n  }\n"], ["\n  margin-top: 38px;\n  display: flex;\n  gap: 16px;\n  & > button:last-of-type {\n    flex: 1;\n    max-width: 270px;\n  }\n\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column-reverse;\n    flex: 1;\n    margin-top: 32px;\n    & > button {\n      width: 100%;\n      max-width: unset;\n      &:last-of-type {\n        flex: none;\n        max-width: none;\n      }\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var Bold = styled.span(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-weight: 600;\n"], ["\n  font-weight: 600;\n"])));
var PromoImage = styled.img.attrs({
    src: SYTGraphic,
    alt: 'promotion-upsell',
})(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  max-width: 280px;\n"], ["\n  max-width: 280px;\n"])));
var Anchor = styled.a(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  text-decoration: none;\n"], ["\n  text-decoration: none;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { Icon, Typography } from 'designSystem';
import { currencyFormatter } from 'utils/currencyFormatter';
import { pluralize } from 'utils/pluralize';
import { format } from 'date-fns';
import styled, { css, useTheme } from 'styled-components';
import { SensitivePaymentType, } from 'types/codegen/hooks';
import { useNavigate } from 'react-router';
import { StatusChip } from './StatusChip';
import { addMobileStyles } from 'utils/addMobileStyles';
import { useScreenSizing } from 'hooks';
var renderPaymentName = function (pd) {
    if (!pd) {
        return '';
    }
    if (pd.__typename === 'ExternalPayment') {
        return pd.displayName;
    }
    if (pd.__typename === 'PaymentMethod') {
        var _a = pd.sensitive, type = _a.type, cc = _a.cc, ach = _a.ach;
        if (type === SensitivePaymentType.Cc) {
            return "".concat(cc === null || cc === void 0 ? void 0 : cc.network, " ending in ").concat(cc === null || cc === void 0 ? void 0 : cc.number.substring((cc === null || cc === void 0 ? void 0 : cc.number.length) - 4));
        }
        return "".concat(ach === null || ach === void 0 ? void 0 : ach.accountName, " ").concat(ach === null || ach === void 0 ? void 0 : ach.accountNumber);
    }
};
export var PaymentHistoryItem = function (_a) {
    var invoicePayment = _a.invoicePayment;
    var theme = useTheme();
    var navigate = useNavigate();
    var isMobile = useScreenSizing().isMobile;
    if (!invoicePayment) {
        return null;
    }
    var amount = invoicePayment.amount, invoices = invoicePayment.invoices, date = invoicePayment.date, paymentDetail = invoicePayment.paymentDetail, status = invoicePayment.status, paymentId = invoicePayment.paymentId;
    return (React.createElement(PaymentContainer, { onClick: function () { return navigate("/invoices/payment/".concat(paymentId)); } },
        React.createElement(PaymentDataWrapper, null,
            React.createElement("div", null,
                React.createElement(Typography, { display: "block", variant: "headline" }, currencyFormatter(amount, 2)),
                React.createElement(Typography, { display: "block", variant: isMobile ? 'footnote' : 'headlineSmall', color: "alt" },
                    !isMobile && '(',
                    invoices.length,
                    " Invoice",
                    pluralize(invoices.length),
                    !isMobile && ')')),
            React.createElement("div", null,
                React.createElement(Typography, { variant: isMobile ? 'footnote' : 'body', color: "alt" }, format(new Date(date), 'MMM dd, yyyy'))),
            React.createElement("div", null,
                React.createElement(Typography, { variant: isMobile ? 'footnote' : 'body', color: "alt" }, renderPaymentName(paymentDetail)))),
        React.createElement("div", null,
            React.createElement(StatusChip, { status: status })),
        React.createElement(Icon, { color: theme.colors.text.link, name: "chevron-right" })));
};
var PaymentDataWrapper = function (_a) {
    var children = _a.children;
    var isMobile = useScreenSizing().isMobile;
    if (isMobile) {
        return React.createElement("div", null, children);
    }
    return React.createElement(React.Fragment, null, children);
};
var PaymentContainer = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  flex: 1;\n  margin: 7.5px 24px;\n  gap: 20px;\n  background: unset;\n  border: unset;\n  :hover {\n    cursor: pointer;\n  }\n  & > div {\n    display: flex;\n    flex: 1;\n    justify-content: flex-start;\n  }\n  & > div:first-of-type {\n    flex-direction: column;\n    align-items: flex-start;\n    gap: 4px;\n  }\n  & > div:last-of-type {\n    justify-content: flex-end;\n  }\n\n  ", ";\n"], ["\n  display: flex;\n  align-items: center;\n  flex: 1;\n  margin: 7.5px 24px;\n  gap: 20px;\n  background: unset;\n  border: unset;\n  :hover {\n    cursor: pointer;\n  }\n  & > div {\n    display: flex;\n    flex: 1;\n    justify-content: flex-start;\n  }\n  & > div:first-of-type {\n    flex-direction: column;\n    align-items: flex-start;\n    gap: 4px;\n  }\n  & > div:last-of-type {\n    justify-content: flex-end;\n  }\n\n  ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return addMobileStyles(css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      ", ";\n      width: 100%;\n      margin: 0;\n      padding: 12px 20px;\n      & > div:first-of-type {\n        gap: 0;\n        & > div:last-of-type {\n          text-align: left;\n        }\n      }\n    "], ["\n      ", ";\n      width: 100%;\n      margin: 0;\n      padding: 12px 20px;\n      & > div:first-of-type {\n        gap: 0;\n        & > div:last-of-type {\n          text-align: left;\n        }\n      }\n    "])), theme.borders.base));
});
var templateObject_1, templateObject_2;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Button, Desktop, Icon, Mobile, Typography, } from 'designSystem';
import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import UpcomingRewardsGraphic from 'assets/upcoming-rewards-graphic.svg';
import { stackedCardStyles } from 'components/StackedMobileCard';
import { addMobileStyles } from '../../../../../../utils/addMobileStyles';
export var UpcomingRewardsDownloader = function () {
    return (React.createElement(UpcomingRewardsContainer, null,
        React.createElement(UpcomingRewardsLeftContainer, null,
            React.createElement(Typography, { mb: 16, variant: "titleLarge" }, "Upcoming Rewards"),
            React.createElement(Typography, { mb: 16, variant: "body", color: "alt" }, "Download a list of patient rewards that are available soon or about to expire."),
            React.createElement(Desktop, null,
                React.createElement(UpcomingRewardsButtonContainer, null,
                    React.createElement(DownloadButton, { downloadType: "XLSX" }),
                    React.createElement(DownloadButton, { downloadType: "PDF" })))),
        React.createElement(UpcomingRewardsRightContainer, null,
            React.createElement(UpcomingRewardsImage, { src: UpcomingRewardsGraphic })),
        React.createElement(Mobile, null,
            React.createElement(UpcomingRewardsButtonContainer, null,
                React.createElement(DownloadButton, { downloadType: "XLSX" }),
                React.createElement(DownloadButton, { downloadType: "PDF" })))));
};
function DownloadButton(props) {
    var downloadType = props.downloadType;
    var theme = useTheme();
    return (React.createElement(Button, { variant: "outlined", size: "xlarge", disabled: false, startIcon: React.createElement(Icon, { name: "download", size: 16, color: theme.colors.text.link }), onClick: function () { } }, "Download ".concat(downloadType)));
}
var UpcomingRewardsContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  border: 1px solid ", ";\n  background-color: white;\n\n  padding: 32px;\n  margin: 16px;\n  width: 100%;\n  flex: 1;\n  @media screen and (max-width: ", "px) {\n    ", "\n    margin: -16px 0 0;\n    z-index: 5;\n    border: none;\n  }\n"], ["\n  display: flex;\n  border: 1px solid ", ";\n  background-color: white;\n\n  padding: 32px;\n  margin: 16px;\n  width: 100%;\n  flex: 1;\n  @media screen and (max-width: ", "px) {\n    ", "\n    margin: -16px 0 0;\n    z-index: 5;\n    border: none;\n  }\n"])), function (props) { return props.theme.colors.border.base; }, function (props) { return props.theme.breakpoints.md; }, stackedCardStyles);
var UpcomingRewardsLeftContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  justify-content: center;\n"], ["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  justify-content: center;\n"])));
var UpcomingRewardsRightContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  justify-content: center;\n  align-items: center;\n"], ["\n  display: flex;\n  flex: 1;\n  justify-content: center;\n  align-items: center;\n"])));
var UpcomingRewardsButtonContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-top: 32px;\n  display: flex;\n  align-items: center;\n  gap: 16px;\n  & > button {\n    padding: 16px;\n  }\n  ", "\n"], ["\n  margin-top: 32px;\n  display: flex;\n  align-items: center;\n  gap: 16px;\n  & > button {\n    padding: 16px;\n  }\n  ", "\n"])), addMobileStyles(css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    gap: 16px;\n    flex-direction: column;\n    & > button {\n      width: 100%;\n      margin: 0;\n      padding: 16px 0;\n    }\n  "], ["\n    gap: 16px;\n    flex-direction: column;\n    & > button {\n      width: 100%;\n      margin: 0;\n      padding: 16px 0;\n    }\n  "])))));
var UpcomingRewardsImage = styled.img(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 100%;\n  max-width: 244px;\n  min-width: 101px;\n"], ["\n  width: 100%;\n  max-width: 244px;\n  min-width: 101px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;

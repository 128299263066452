var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { AccountInfoHeader } from './components/AccountInfoHeader';
import EvoluxProgressWidget from 'components/EvoluxProgressWidget';
import OrderHistoryWidget from './components/OrderHistory';
import { EvolusCredit } from 'components/EvolusCredit';
import { useUser } from 'context';
export var OrderDashboardView = function () {
    var account = useUser().account;
    return (React.createElement(React.Fragment, null,
        React.createElement(AccountInfoHeader, { accountName: (account === null || account === void 0 ? void 0 : account.name) || '', accountId: (account === null || account === void 0 ? void 0 : account.id) || '' }),
        React.createElement(EvoluxRow, null,
            React.createElement(EvoluxProgressWidget, null),
            React.createElement(EvolusCredit, null)),
        React.createElement(OrderHistoryWidget, null)));
};
var EvoluxRow = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n    z-index: 1;\n  }\n"], ["\n  display: flex;\n\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n    z-index: 1;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var templateObject_1;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { ActivityType, RewardType, } from 'types/codegen/hooks';
import { Typography, Dropdown, CheckboxDropdown, Button, Icon, LoadingBubbles, } from 'designSystem';
import RewardHistoryTable from '../RewardHistoryTable';
import { DateRangePicker } from 'designSystem/components/DateRangePicker';
var rewardTypeOptions = [
    {
        value: RewardType.NewPatientReward,
        label: 'New Patients',
    },
    {
        value: RewardType.ExistingPatientReward,
        label: 'Existing Patients',
    },
];
var TransactionDetails = function (_a) {
    var injectors = _a.injectors, selectedFacility = _a.selectedFacility, onSelectItem = _a.onSelectItem, csvOnDownload = _a.csvOnDownload, csvIsLoading = _a.csvIsLoading, selectedActivityTypes = _a.selectedActivityTypes, showRewardsCounterDetails = _a.showRewardsCounterDetails, selectedRewardTypes = _a.selectedRewardTypes, setSelectedRewardTypes = _a.setSelectedRewardTypes;
    var _b = __read(useState(null), 2), dateRange = _b[0], setDateRange = _b[1];
    var _c = __read(useState(null), 2), selectedInjectors = _c[0], setSelectedInjectors = _c[1];
    var transactionTypeOptions = useMemo(function () {
        var types = [];
        var label = '';
        Object.values(ActivityType).forEach(function (value) {
            var split = value.split('_');
            label = "".concat(split[0].toLowerCase(), " ").concat(split[1].toLowerCase());
            types.push({
                value: value,
                label: label,
            });
        });
        return types;
    }, []);
    var _d = __read(useState(selectedActivityTypes
        ? transactionTypeOptions.filter(function (o) { return selectedActivityTypes.indexOf(o.value) !== -1; })
        : []), 2), selectedTransactionType = _d[0], setSelectedTransactionType = _d[1];
    var _e = __read(useState(selectedRewardTypes
        ? rewardTypeOptions.filter(function (o) { return selectedRewardTypes.indexOf(o.value) !== -1; })
        : []), 2), checkedRewardTypes = _e[0], setCheckedRewardTypes = _e[1];
    var injectorOptions = useMemo(function () {
        var types = injectors === null || injectors === void 0 ? void 0 : injectors.map(function (injector) {
            return {
                value: (injector === null || injector === void 0 ? void 0 : injector.uniqueId) || '',
                label: (injector === null || injector === void 0 ? void 0 : injector.name) || '',
            };
        });
        return types;
    }, [injectors]);
    var handleDownloadCSV = function () {
        var request = {
            accountId: '',
            startDate: '',
            activityFilter: [],
        };
        if (selectedFacility) {
            request.facilityId = [selectedFacility];
        }
        if (dateRange) {
            request.startDate = dateRange[0].toString();
            request.endDate = dateRange[1].toString();
        }
        if (selectedTransactionType.length) {
            request.activityFilter = selectedTransactionType.map(function (tt) {
                var parts = tt.value.split('_');
                for (var i = 0; i < parts.length; i++) {
                    parts[i] = parts[i].charAt(0) + parts[i].toLowerCase().slice(1);
                }
                var key = parts.join('');
                return ActivityType[key];
            });
        }
        if (selectedInjectors) {
            request.injectorId = [selectedInjectors.value];
        }
        request.rewardTypeFilter = selectedRewardTypes;
        csvOnDownload(request);
    };
    return (React.createElement(OuterContainer, null,
        React.createElement(HeaderContainer, null,
            React.createElement(HeaderColumn, null,
                React.createElement(Typography, { variant: "titleLarge", mb: 2 }, "Transaction Details"),
                React.createElement(Subtext, null, "View recent transactional reports.")),
            React.createElement(HeaderColumn, null,
                React.createElement(Button, { variant: "outlinedSecondary", color: "primary", onClick: handleDownloadCSV, disabled: csvIsLoading, startIcon: React.createElement(Icon, { name: "download", color: "secondary" }) }, csvIsLoading ? React.createElement(LoadingBubbles, null) : React.createElement(React.Fragment, null, "Download CSV")))),
        React.createElement(FilterSectionContainer, null,
            React.createElement(Typography, { variant: "sectionHeader", color: "alt", style: { marginRight: 12 } }, "Filters"),
            !showRewardsCounterDetails && (React.createElement(FilterDropdownContainer, null,
                React.createElement(CheckboxDropdown, { onChange: function (option) { return setSelectedTransactionType(option); }, selected: selectedTransactionType, options: transactionTypeOptions, placeholder: "Transaction Type" }))),
            showRewardsCounterDetails && (React.createElement(FilterDropdownContainer, null,
                React.createElement(CheckboxDropdown, { onChange: function (options) {
                        setCheckedRewardTypes(options);
                        setSelectedRewardTypes(options.map(function (option) { return option.value; }));
                    }, selected: checkedRewardTypes, options: rewardTypeOptions, placeholder: "Reward Type" }))),
            React.createElement(FilterDropdownContainer, null,
                React.createElement(Dropdown, { onSelect: function (option) { return setSelectedInjectors(option); }, selected: selectedInjectors, options: injectorOptions, placeholder: "Select Specialist", clearable: true })),
            React.createElement(FilterDropdownContainer, null,
                React.createElement(DateRangePicker, { onChange: function (value) { return setDateRange(value); }, value: dateRange }))),
        React.createElement(RewardHistoryTable, { selectedTransactionType: selectedTransactionType, setSelectedTransactionType: setSelectedTransactionType, dateRange: dateRange, selectedInjectors: selectedInjectors, selectedFacility: selectedFacility, selectedRewardTypes: selectedRewardTypes, onSelectItem: onSelectItem })));
};
export default TransactionDetails;
var OuterContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  justify-content: flex-start;\n  background-color: white;\n  margin: 16px;\n\n  padding: 48px;\n  position: relative;\n  min-height: 65vh;\n"], ["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  justify-content: flex-start;\n  background-color: white;\n  margin: 16px;\n\n  padding: 48px;\n  position: relative;\n  min-height: 65vh;\n"])));
var FilterSectionContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  margin-bottom: 48px;\n"], ["\n  display: flex;\n  align-items: center;\n  margin-bottom: 48px;\n"])));
var FilterDropdownContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  margin: 0 12px;\n"], ["\n  display: flex;\n  flex: 1;\n  margin: 0 12px;\n"])));
var Subtext = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 1rem;\n  color: ", ";\n  margin-top: 16px;\n  margin-bottom: 32px;\n"], ["\n  font-size: 1rem;\n  color: ", ";\n  margin-top: 16px;\n  margin-bottom: 32px;\n"])), function (props) { return props.theme.colors.text.alt; });
var HeaderContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n"])));
var HeaderColumn = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import RewardsProgress from 'components/RewardsProgress';
import styled from 'styled-components';
import CurrentEvoluxLevel from '../../components/CurrentEvoluxLevel';
import { stackedCardStyles } from 'components/StackedMobileCard';
import EvoluxBenefitsWidget from './components/EvoluxBenefitsWidget';
import EvoluxProgressWidget from 'components/EvoluxProgressWidget';
import { useUser } from 'context';
import { Typography } from 'designSystem';
import { useScreenSizing } from 'hooks';
export var HomeScreen = function (_a) {
    var isLoading = _a.isLoading;
    var isMobile = useScreenSizing().isMobile;
    var user = useUser().user;
    return (React.createElement(OuterContainer, null,
        isMobile && (user === null || user === void 0 ? void 0 : user.firstName) && (React.createElement(UserNameContainer, null,
            React.createElement(Typography, { variant: "title" },
                "Hi ", user === null || user === void 0 ? void 0 :
                user.firstName,
                ", "),
            React.createElement(Typography, { variant: "title", color: "alt" }, "Welcome!"))),
        React.createElement(RowContainer, null,
            React.createElement(ItemContainer, { flex: 2 },
                React.createElement(CurrentEvoluxLevel, { context: "HOME_SCREEN" }))),
        React.createElement(RowContainer, null,
            React.createElement(EvoluxProgressWidget, null),
            React.createElement(RewardsProgress, null)),
        React.createElement(RowContainer, null,
            React.createElement(ItemContainer, { flex: 1 },
                React.createElement(CardContainer, null,
                    React.createElement(EvoluxBenefitsWidget, { showButton: true })))),
        React.createElement(RowContainer, null)));
};
var OuterContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 26px;\n"], ["\n  margin-top: 26px;\n"])));
var RowContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n  }\n"], ["\n  display: flex;\n  flex: 1;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var ItemContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex: ", ";\n"], ["\n  display: flex;\n  flex: ", ";\n"])), function (props) { return props.flex; });
var CardContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  flex-direction: column;\n  @media screen and (max-width: ", "px) {\n    ", ";\n    margin: -16px 0 0;\n    padding: 0 32px;\n  }\n"], ["\n  ", ";\n  flex-direction: column;\n  @media screen and (max-width: ", "px) {\n    ", ";\n    margin: -16px 0 0;\n    padding: 0 32px;\n  }\n"])), function (props) { return props.theme.card.default; }, function (props) { return props.theme.breakpoints.md; }, stackedCardStyles);
var UserNameContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin: 0 32px 32px 32px;\n"], ["\n  margin: 0 32px 32px 32px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;

export var getLoyaltyLevel = function (vialsPurchased, levels) {
    var sortedLevels = levels.sort(function (a, b) {
        if (a.vialThreshold === b.vialThreshold) {
            return 0;
        }
        return a.vialThreshold < b.vialThreshold ? 1 : -1;
    });
    for (var i = 0; i < sortedLevels.length; i++) {
        if (vialsPurchased >= sortedLevels[i].vialThreshold) {
            return sortedLevels[i];
        }
    }
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useCallback, useState } from 'react';
import { Button, Checkbox, CheckboxDropdown, DateRangePicker, Typography, } from 'designSystem';
import styled from 'styled-components';
import { ActivityType } from 'types/codegen/hooks';
import { Modal } from '../Modal';
import { useModal } from '../../../context';
export function AccountStatementsFilters(props) {
    var _a;
    var _b, _c;
    var onApply = props.onApply, facilitiesOptions = props.facilitiesOptions, specialistsOptions = props.specialistsOptions, onReset = props.onReset, activityTypes = props.activityTypes, currentTypes = props.currentTypes, currentFacilities = props.currentFacilities, currentSpecialists = props.currentSpecialists, currentRange = props.currentRange;
    var closeModal = useModal().closeModal;
    var _d = __read(useState((_a = {},
        _a[ActivityType.VialRedeemed] = !!currentTypes.find(function (t) { return t.value === ActivityType.VialRedeemed; }),
        _a[ActivityType.CreditReceived] = !!currentTypes.find(function (t) { return t.value === ActivityType.CreditReceived; }),
        _a)), 2), checkedTypes = _d[0], setCheckedTypes = _d[1];
    var _e = __read(useState(currentFacilities), 2), facilities = _e[0], setFacilities = _e[1];
    var _f = __read(useState(currentSpecialists), 2), specialists = _f[0], setSpecialists = _f[1];
    var _g = __read(useState(currentRange), 2), dateRange = _g[0], setDateRange = _g[1];
    var handleChange = useCallback(function (type) { return function () {
        var _a, _b;
        if (checkedTypes[type]) {
            setCheckedTypes(__assign(__assign({}, checkedTypes), (_a = {}, _a[type] = false, _a)));
        }
        else {
            setCheckedTypes(__assign(__assign({}, checkedTypes), (_b = {}, _b[type] = true, _b)));
        }
    }; }, [checkedTypes]);
    return (React.createElement(Modal, null,
        React.createElement(InnerContainer, null,
            React.createElement(Typography, { variant: "sectionHeader", align: "left" }, "ACCOUNT STATEMENTS"),
            React.createElement(Typography, { variant: "titleLarge", align: "left" }, "Filters for CSV Downloads Only"),
            React.createElement(CheckContainer, null,
                React.createElement(Typography, null, "Transaction Type"),
                React.createElement("div", { onClick: handleChange(ActivityType.VialRedeemed) },
                    React.createElement(Checkbox, { checked: checkedTypes[ActivityType.VialRedeemed], title: "Vial Redemption", onChange: function () { return null; } })),
                React.createElement("div", { onClick: handleChange(ActivityType.CreditReceived) },
                    React.createElement(Checkbox, { checked: checkedTypes[ActivityType.CreditReceived], title: "Credit Received", onChange: function () { return null; } }))),
            React.createElement(DropdownsContainer, null,
                React.createElement(FullWidthDropdown, null,
                    React.createElement(Typography, { mb: 8, variant: "headline", color: "alt" }, "Facilities"),
                    React.createElement(CheckboxDropdown, { placeholder: "Select Facilities", options: facilitiesOptions, selected: facilities, onChange: function (updated) { return setFacilities(updated); }, closeOnSelect: true })),
                React.createElement(FullWidthDropdown, null,
                    React.createElement(Typography, { mb: 8, variant: "headline", color: "alt" }, "Specialists"),
                    React.createElement(CheckboxDropdown, { placeholder: (_c = (_b = specialists === null || specialists === void 0 ? void 0 : specialists[0]) === null || _b === void 0 ? void 0 : _b.label) !== null && _c !== void 0 ? _c : 'All Specialists', options: specialistsOptions, selected: specialists, onChange: function (updated) { return setSpecialists(updated); }, closeOnSelect: true })),
                React.createElement(DateRangePicker, { label: "Dates", onChange: function (range) { return setDateRange(range); }, value: dateRange })),
            React.createElement(ApplyButton, { size: "xlarge", fullWidth: true, onClick: function () {
                    onApply({
                        dateRange: dateRange,
                        newTypes: activityTypes.filter(function (t) { return checkedTypes[t.value]; }),
                        newFacilities: facilities,
                        newSpecialists: specialists,
                    });
                    closeModal();
                } }, "Apply Filters"),
            React.createElement(ResetButton, { fullWidth: true, onClick: function () {
                    onReset();
                    closeModal();
                }, variant: "tertiary" }, "Reset"))));
}
var InnerContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: flex-start;\n  & > span:nth-child(1) {\n    margin-bottom: 16px;\n  }\n  & > span:nth-child(2) {\n    margin-bottom: 32px;\n  }\n"], ["\n  width: 100%;\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: flex-start;\n  & > span:nth-child(1) {\n    margin-bottom: 16px;\n  }\n  & > span:nth-child(2) {\n    margin-bottom: 32px;\n  }\n"])));
var FullWidthDropdown = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  & > span {\n    display: block;\n  }\n  & > div > button {\n    width: 100%;\n    text-align: left;\n  }\n"], ["\n  width: 100%;\n  & > span {\n    display: block;\n  }\n  & > div > button {\n    width: 100%;\n    text-align: left;\n  }\n"])));
var CheckContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  & > span {\n    display: block;\n    margin-bottom: 16px;\n  }\n  & > div {\n    margin-bottom: 24px;\n  }\n"], ["\n  & > span {\n    display: block;\n    margin-bottom: 16px;\n  }\n  & > div {\n    margin-bottom: 24px;\n  }\n"])));
var ApplyButton = styled(Button)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-top: auto;\n"], ["\n  margin-top: auto;\n"])));
var ResetButton = styled(Button)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin: 20px 0 40px;\n"], ["\n  margin: 20px 0 40px;\n"])));
var DropdownsContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  flex-direction: column;\n  gap: 16px;\n"], ["\n  display: flex;\n  width: 100%;\n  flex-direction: column;\n  gap: 16px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;

import React from 'react';
import View from './View';
import { useUser } from 'context';
import { getLoyaltyLevel } from "../../utils/getLoyaltyLevel";
var CurrentEvoluxLevelContainer = function (_a) {
    var _b, _c;
    var context = _a.context, isUpgrade = _a.isUpgrade, currentLevelOverride = _a.currentLevelOverride, locked = _a.locked, level = _a.level;
    var account = useUser().account;
    var currentLevel = getLoyaltyLevel(account === null || account === void 0 ? void 0 : account.currentQuarterLoyalty.vialsPurchased, account === null || account === void 0 ? void 0 : account.currentQuarterLoyalty.levels);
    return (React.createElement(View, { accountId: account === null || account === void 0 ? void 0 : account.id, accountName: account === null || account === void 0 ? void 0 : account.name, annualVialsPurchased: account === null || account === void 0 ? void 0 : account.annualVialsPurchased, pricingModel: account === null || account === void 0 ? void 0 : account.pricingModel, context: context, currentLevel: (_b = currentLevel === null || currentLevel === void 0 ? void 0 : currentLevel.name) !== null && _b !== void 0 ? _b : "LEVEL_0", currentLevelOverride: currentLevelOverride, error: undefined, isLoading: false, isUpgrade: isUpgrade, locked: locked, level: currentLevel, levels: (_c = account === null || account === void 0 ? void 0 : account.currentQuarterLoyalty) === null || _c === void 0 ? void 0 : _c.levels, club_1k_feature_flag: false }));
};
export default CurrentEvoluxLevelContainer;

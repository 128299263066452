var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import portalGif from 'assets/my-evolus-logo-graphic.svg';
import { LoadingBubbles } from '../LoadingBubbles';
var LoadingContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  justify-content: center;\n  align-items: center;\n"], ["\n  display: flex;\n  flex: 1;\n  justify-content: center;\n  align-items: center;\n"])));
var LoadingGif = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: ", ";\n  margin-bottom: 20px;\n"], ["\n  width: ", ";\n  margin-bottom: 20px;\n"])), function (props) { return (props.size ? sizer[props.size] : '10%'); });
var LogoContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  flex: 1;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  flex: 1;\n"])));
var sizer = {
    'extra-small': '5%',
    small: '10%',
    medium: '15%',
    large: '20%',
};
export var PlaceholderLoading = function (_a) {
    var size = _a.size;
    return (React.createElement(LoadingContainer, null,
        React.createElement(LogoContainer, null,
            React.createElement(LoadingGif, { size: size, src: portalGif }),
            React.createElement(LoadingBubbles, null))));
};
var templateObject_1, templateObject_2, templateObject_3;

var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import DefaultLayout from 'components/Layouts/DefaultLayout';
import { useLocalStorage } from "../../../../hooks";
var noRedirectRoutes = ['selectAccount', 'home', 'auth'];
export var RequireAuth = function () {
    var _a = __read(useLocalStorage('loggedIn', false), 1), loggedIn = _a[0];
    var location = useLocation();
    var navTo = '/auth/sign-in';
    if (location.pathname !== '/' &&
        !noRedirectRoutes.some(function (route) { return location.pathname.includes(route); })) {
        var redirectTo = "?redirect=".concat(location.pathname).concat(location.search);
        navTo = navTo + redirectTo;
    }
    if (!loggedIn) {
        return React.createElement(Navigate, { to: navTo, state: { from: location } });
    }
    return (React.createElement(DefaultLayout, null,
        React.createElement(Outlet, null)));
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Icon, Typography } from 'designSystem';
import useAsyncEffect from 'hooks/useAsyncEffect';
import React from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { StepContainer } from '../../StepContainer';
import CheckInProgress from '../../CheckInProgress';
import { StepEnum } from '../../../Enums/StepEnum';
import { useScreenSizing } from 'hooks';
import { PhoneNumber } from '../../PhoneNumber';
var SendMagicPhrase = function (_a) {
    var phoneNumber = _a.phoneNumber, handleRestart = _a.handleRestart, injector = _a.injector, onForward = _a.onForward;
    var navigate = useNavigate();
    var isMobile = useScreenSizing().isMobile;
    useAsyncEffect(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            try {
                onForward('testtest');
                navigate(StepEnum.ValidateMagicPhrase);
            }
            catch (e) {
                console.error(e);
            }
            return [2];
        });
    }); }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(StepContainer, { handleRestart: handleRestart, label: "Sending Magic Phrase" },
            React.createElement(OuterContainer, null,
                React.createElement(Icon, { name: "loading", size: 32 }),
                React.createElement(Typography, { mt: 30, variant: "subtitle" },
                    "Sending magic phrase to ",
                    React.createElement(PhoneNumber, { phoneNumber: phoneNumber })))),
        React.createElement(CheckInProgress, { hidePrimaryButton: true, onContinue: function () { }, secondaryButtonText: isMobile && 'Restart Check In', onSecondaryContinue: handleRestart })));
};
export default SendMagicPhrase;
var OuterContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 36px;\n  background-color: ", ";\n\n  border: 1px solid ", ";\n  @media screen and (max-width: ", "px) {\n    border: none;\n    background: transparent;\n    padding: 0;\n    & > span {\n      text-align: center;\n    }\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 36px;\n  background-color: ", ";\n\n  border: 1px solid ", ";\n  @media screen and (max-width: ", "px) {\n    border: none;\n    background: transparent;\n    padding: 0;\n    & > span {\n      text-align: center;\n    }\n  }\n"])), function (props) { return props.theme.colors.bg.alt3; }, function (props) { return props.theme.colors.border.base; }, function (props) { return props.theme.breakpoints.md; });
var templateObject_1;

import React from 'react';
import { EnrollmentProgressView } from './View';
import { useUser } from 'context';
import { useQuery } from '@apollo/client';
import query from './index.graphql';
export var EnrollmentProgressContainer = function (_a) {
    var _b;
    var phoneNumber = _a.phoneNumber, retryLink = _a.retryLink, onEnroll = _a.onEnroll, enrollmentMethod = _a.enrollmentMethod, email = _a.email, noPromptRestart = _a.noPromptRestart, onResend = _a.onResend, isInitialEmail = _a.isInitialEmail;
    var account = useUser().account;
    var _c = useQuery(query, {
        variables: {
            input: {
                accountId: account === null || account === void 0 ? void 0 : account.id,
                phoneNumber: phoneNumber,
            },
        },
        pollInterval: 5000,
    }), data = _c.data, loading = _c.loading, error = _c.error, stopPolling = _c.stopPolling;
    var _d = (_b = data === null || data === void 0 ? void 0 : data.lookupConsumerProfile) !== null && _b !== void 0 ? _b : {}, profile = _d.profile, reward = _d.reward, promotion = _d.promotion;
    return (React.createElement(EnrollmentProgressView, { loading: loading, error: error, isInitialEmail: isInitialEmail, stopPolling: stopPolling, profile: profile, reward: reward, promotion: promotion, enrollmentMethod: enrollmentMethod, phoneNumber: phoneNumber, retryLink: retryLink, onEnroll: onEnroll, email: email, noPromptRestart: noPromptRestart, onResend: onResend }));
};

import React from 'react';
import { PaymentHistoryView } from './View';
import query from './index.graphql';
import { useQuery } from '@apollo/client';
import { useUser } from 'context';
export var PaymentHistoryContainer = function () {
    var _a;
    var account = useUser().account;
    var _b = useQuery(query, {
        variables: {
            accountId: account === null || account === void 0 ? void 0 : account.id,
        },
    }), data = _b.data, loading = _b.loading, called = _b.called;
    return (React.createElement(PaymentHistoryView, { loading: loading || !called, invoicePayments: (_a = data === null || data === void 0 ? void 0 : data.getInvoicePaymentsByAccount) === null || _a === void 0 ? void 0 : _a.payments }));
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Button } from 'designSystem/components/Button';
import { useModal } from 'context/ModalContext';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import styled, { withTheme } from 'styled-components';
import AuthError from 'assets/auth-error-graphic-1.svg';
import CreditOnHold from 'assets/auth-error-graphic-2.svg';
import ExpiredCard from 'assets/auth-error-graphic-3.svg';
import InsufficientFunds from 'assets/auth-error-graphic-4.svg';
import ProcessingError from 'assets/auth-error-graphic-5.svg';
import AbandonCartGraphic from 'assets/auth-error-graphic-6.svg';
import { Modal } from '../Modal';
import { Desktop, Mobile } from 'designSystem';
var paymentErrorImages = {
    ABANDON_CART: AbandonCartGraphic,
    ACCOUNT_ON_HOLD: CreditOnHold,
    EXPIRED_CARD: ExpiredCard,
    GENERIC_PAYMENT_PROCESSOR_ERROR: ProcessingError,
    INSUFFICIENT_EVOLUS_CREDIT: InsufficientFunds,
    INSUFFICIENT_FUNDS: InsufficientFunds,
    INVALID: ProcessingError,
    NETWORK_ISSUE: AuthError,
    REWARD_VIAL_BALANCE_ERROR: InsufficientFunds,
    DEFAULT: ProcessingError,
};
var PaymentAuthError = function (_a) {
    var _b = _a.error, error = _b === void 0 ? { errorCode: '' } : _b, theme = _a.theme;
    var navigate = useNavigate();
    var closeModal = useModal().closeModal;
    var sorryErrorCodes = ['ACCOUNT_ON_HOLD', 'NETWORK_ISSUE'];
    var _c = __read(useState(false), 2), showAbandonCart = _c[0], setShowAbandonCart = _c[1];
    var renderCTA = function () {
        if ((error === null || error === void 0 ? void 0 : error.errorCode) !== 'NETWORK_ISSUE') {
            if ((error === null || error === void 0 ? void 0 : error.errorCode) === 'REWARD_VIAL_BALANCE_ERROR') {
                return (React.createElement(Button, { size: "large", variant: "primary", onClick: function () {
                        closeModal();
                        navigate('?step=quantitySelection');
                    } }, "Restart Order"));
            }
            return (React.createElement(Button, { size: "large", variant: "primary", onClick: function () {
                    closeModal();
                    navigate('?step=paymentMethodSelection');
                } }, "Change Payment Method"));
        }
        return null;
    };
    var renderButtonRow = function () {
        if (showAbandonCart) {
            return (React.createElement(ButtonContainer, null,
                React.createElement(Button, { size: "large", variant: "outlined", onClick: function () { return closeModal(); } }, "No, Return to Cart"),
                React.createElement(Desktop, null,
                    React.createElement(VerticalSpacer, null)),
                React.createElement(Mobile, null,
                    React.createElement(Spacer, null)),
                React.createElement(Button, { size: "large", variant: "primary", onClick: function () {
                        closeModal();
                        navigate('/orders/dashboard');
                    } }, "Yes, Abandon Cart")));
        }
        return (React.createElement(ButtonContainer, null,
            React.createElement(Button, { size: "large", variant: "outlined", onClick: function () { } }, "Contact Customer Support"),
            React.createElement(Desktop, null,
                React.createElement(VerticalSpacer, null)),
            React.createElement(Mobile, null,
                React.createElement(Spacer, null)),
            renderCTA()));
    };
    return (React.createElement(Modal, { backdropColor: theme.colors.bg.accent },
        React.createElement(ContentContainer, null,
            !showAbandonCart && (React.createElement(CloseButton, { onClick: function () { return setShowAbandonCart(true); } })),
            !showAbandonCart && React.createElement(HeaderText, null, "Payment Error"),
            React.createElement(ImageContainer, null,
                React.createElement(Image, { src: paymentErrorImages[showAbandonCart ? 'ABANDON_CART' : (error === null || error === void 0 ? void 0 : error.errorCode) || 'DEFAULT'] || ProcessingError })),
            showAbandonCart ? (React.createElement(Subtext, null, "Are you sure you want to abandon cart?")) : (React.createElement(Subtext, null, sorryErrorCodes.includes(error === null || error === void 0 ? void 0 : error.errorCode)
                ? "We're Sorry!"
                : 'Uh Oh!')),
            !showAbandonCart && (React.createElement(FooterText, null, (error === null || error === void 0 ? void 0 : error.displayMessage) || "Something's gone wrong.")),
            React.createElement(ButtonSpacer, null),
            renderButtonRow())));
};
var ContentContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  align-items: center;\n  z-index: 10000;\n  position: relative;\n"], ["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  align-items: center;\n  z-index: 10000;\n  position: relative;\n"])));
var CloseButton = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  right: -40px;\n  top: -30px;\n  width: 40px;\n  height: 40px;\n  cursor: pointer;\n"], ["\n  position: absolute;\n  right: -40px;\n  top: -30px;\n  width: 40px;\n  height: 40px;\n  cursor: pointer;\n"])));
var HeaderText = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 1.125rem;\n  font-weight: 500;\n"], ["\n  color: ", ";\n  font-size: 1.125rem;\n  font-weight: 500;\n"])), function (props) { return props.theme.colors.text.link; });
var ImageContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"])));
var Image = styled.img(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 50%;\n"], ["\n  width: 50%;\n"])));
var Subtext = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: 2.25rem;\n  margin-bottom: 32px;\n"], ["\n  font-size: 2.25rem;\n  margin-bottom: 32px;\n"])));
var FooterText = styled.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-size: 1.125rem;\n  color: ", ";\n"], ["\n  font-size: 1.125rem;\n  color: ", ";\n"])), function (props) { return props.theme.colors.text.alt; });
var ButtonSpacer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  margin-top: 72px;\n"], ["\n  margin-top: 72px;\n"])));
var ButtonContainer = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n  font-size: 1rem;\n  width: 100%;\n  margin-top: 27px;\n\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n  }\n"], ["\n  display: flex;\n  justify-content: flex-end;\n  font-size: 1rem;\n  width: 100%;\n  margin-top: 27px;\n\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var VerticalSpacer = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  margin: 0 12px;\n"], ["\n  margin: 0 12px;\n"])));
var Spacer = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  margin: 12px 0;\n"], ["\n  margin: 12px 0;\n"])));
export default withTheme(PaymentAuthError);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;

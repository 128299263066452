var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { StepContainer } from '../StepContainer';
import { useLocation } from 'react-router';
import { PaymentSteps } from '../../enums/Steps';
import styled, { css } from 'styled-components';
import { Typography } from 'designSystem';
import { PaymentProgress } from '../PaymentProgress';
import MakePaymentGraphic from 'assets/bills-payment.svg';
import { currencyFormatter } from 'utils/currencyFormatter';
import { pluralize } from 'utils/pluralize';
import { InvoicesSettledTable } from '../../../../components/InvoicesSettledTable';
import { useScreenSizing } from '../../../../../../hooks';
import { addMobileStyles } from '../../../../../../utils/addMobileStyles';
import { MobileContinueButton } from '../MobileContinueButton';
export var PayPreSelectedInvoices = function (_a) {
    var invoices = _a.invoices, paymentOption = _a.paymentOption, amountDue = _a.amountDue, onContinue = _a.onContinue;
    var isMobile = useScreenSizing().isMobile;
    var location = useLocation();
    var header = location.search === PaymentSteps.PayEntireBalance
        ? 'Pay Entire Balance'
        : 'Pay Balance Due This Week';
    return (React.createElement(React.Fragment, null,
        React.createElement(StepContainer, { subHeader: "Make A Payment", header: header },
            React.createElement(UpperCard, null,
                React.createElement("div", null,
                    React.createElement(Typography, { display: "block", mb: 32, variant: "title" }, "Amount Paying Today"),
                    React.createElement("div", null,
                        React.createElement(Typography, { display: "block", mb: 16, variant: "titleLarge" }, currencyFormatter(amountDue, 2)),
                        React.createElement(Typography, { display: "block", variant: "headline", color: "alt5" },
                            "Payment for ", invoices === null || invoices === void 0 ? void 0 :
                            invoices.length,
                            " Invoice",
                            pluralize(invoices === null || invoices === void 0 ? void 0 : invoices.length)))),
                !isMobile && React.createElement("img", { src: MakePaymentGraphic, alt: "make-a-payment" })),
            invoices && (React.createElement(InvoicesSettledTable, { invoices: invoices, invoicesApplied: [] })),
            isMobile && (React.createElement(MobileContinueButton, { label: 'Select Payment Method', onContinue: onContinue }))),
        !isMobile && (React.createElement(PaymentProgress, { paymentOption: paymentOption, primaryButtonLabel: "Select Payment Method", primaryAction: onContinue }))));
};
var UpperCard = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  padding: 32px 48px;\n  & > img {\n    width: 100%;\n    max-width: 192px;\n  }\n  display: flex;\n  align-items: center;\n  flex: 1;\n  gap: 36px;\n  margin: 32px 0;\n  & > div {\n    flex: 1;\n    & > div {\n      display: flex;\n      flex-direction: column;\n      align-items: center;\n      justify-content: center;\n      padding: 32px 0;\n      ", ";\n      ", ";\n    }\n  }\n  ", "\n"], ["\n  ", ";\n  padding: 32px 48px;\n  & > img {\n    width: 100%;\n    max-width: 192px;\n  }\n  display: flex;\n  align-items: center;\n  flex: 1;\n  gap: 36px;\n  margin: 32px 0;\n  & > div {\n    flex: 1;\n    & > div {\n      display: flex;\n      flex-direction: column;\n      align-items: center;\n      justify-content: center;\n      padding: 32px 0;\n      ", ";\n      ", ";\n    }\n  }\n  ", "\n"])), function (props) { return props.theme.borders.base; }, function (props) { return props.theme.elevation.base.elevation1; }, function (props) { return props.theme.borders.base; }, addMobileStyles(css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    padding: 0;\n    border: none;\n    flex: none;\n  "], ["\n    padding: 0;\n    border: none;\n    flex: none;\n  "])))));
var templateObject_1, templateObject_2;

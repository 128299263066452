var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { AddressItem } from 'components/AddressItem';
import { PaymentMethodItem } from 'components/PaymentMethodItem';
import { Input } from 'designSystem/components/Input/Input';
import { Typography } from 'designSystem';
import { useScreenSizing } from 'hooks';
import CurrentEvoluxLevelWidget from 'components/CurrentEvoluxLevel';
import { parseISO } from 'date-fns/esm';
import { convertMilitaryToStandardTime } from 'utils/convertMilitaryToStandardTime';
import { currencyFormatter } from 'utils/currencyFormatter';
export var ReviewOrderView = function (_a) {
    var _b, _c, _d, _e;
    var orderData = _a.orderData, setOrderData = _a.setOrderData;
    var _f = __read(useState(''), 2), referenceNumber = _f[0], setReferenceNumber = _f[1];
    var isMobile = useScreenSizing().isMobile;
    useEffect(function () {
        setOrderData(__assign(__assign({}, orderData), { referenceNumber: referenceNumber }));
    }, [referenceNumber]);
    if (!((_b = Object.keys(orderData)) === null || _b === void 0 ? void 0 : _b.length)) {
        return null;
    }
    return (React.createElement(OuterContainer, null,
        (orderData === null || orderData === void 0 ? void 0 : orderData.isLevelUpgradeOrRetain) &&
            orderData.currentLevel <= (orderData === null || orderData === void 0 ? void 0 : orderData.liveUpgradeRate) && (React.createElement(CurrentEvoluxLevelWidget, { isUpgrade: (orderData === null || orderData === void 0 ? void 0 : orderData.currentLevel) < (orderData === null || orderData === void 0 ? void 0 : orderData.liveUpgradeRate), context: "ORDER_SUMMARY", currentLevelOverride: orderData === null || orderData === void 0 ? void 0 : orderData.liveUpgradeRate })),
        React.createElement(OrderSummaryItemContainer, null,
            React.createElement(Typography, { variant: "headline", color: "alt" }, "Shipping Address"),
            React.createElement(AddressItem, { showFacilityIcon: !isMobile, facility: orderData === null || orderData === void 0 ? void 0 : orderData.facility })),
        React.createElement(OrderSummaryItemContainer, null,
            React.createElement(Typography, { variant: "headline", color: "alt" }, "Delivery"),
            React.createElement(DeliveryItemContainer, null,
                React.createElement(Typography, { variant: "headline", style: { marginBottom: 8 } },
                    format(parseISO((_c = orderData === null || orderData === void 0 ? void 0 : orderData.shippingMethod) === null || _c === void 0 ? void 0 : _c.requestedDeliveryDate), 'eeee, MMMM d'),
                    ' ',
                    "by ",
                    convertMilitaryToStandardTime(orderData === null || orderData === void 0 ? void 0 : orderData.deliveryByTime)),
                React.createElement(Typography, { variant: "bodySmall", color: "alt" }, (_d = orderData === null || orderData === void 0 ? void 0 : orderData.shippingMethod) === null || _d === void 0 ? void 0 : _d.serviceName))),
        React.createElement(OrderSummaryItemContainer, null,
            React.createElement(Typography, { variant: "headline", color: "alt" }, "Payment Method"), (_e = orderData === null || orderData === void 0 ? void 0 : orderData.paymentMethod) === null || _e === void 0 ? void 0 :
            _e.map(function (pm, i) {
                var _a;
                return (React.createElement(PaymentMethodItemContainer, { key: i },
                    React.createElement(PaymentMethodItem, { selectable: false, paymentMethod: pm }),
                    ((_a = orderData === null || orderData === void 0 ? void 0 : orderData.paymentMethod) === null || _a === void 0 ? void 0 : _a.length) > 1 && (React.createElement(PaymentMethodItemAmountContainer, null,
                        React.createElement(PaymentAmount, null,
                            React.createElement(Typography, { variant: "body", color: "alt" }, "Amount")),
                        React.createElement(PaymentAmount, null,
                            React.createElement(Typography, { variant: "body", color: "alt" }, currencyFormatter(pm === null || pm === void 0 ? void 0 : pm.amount, 2)))))));
            })),
        React.createElement(OrderSummaryItemContainer, null,
            React.createElement(Typography, { variant: "headline", color: "alt" }, "Reference Number"),
            React.createElement(Input, { footerLabel: '', label: '', variant: "outline", placeholder: "Reference Number", onChange: function (e) { return setReferenceNumber(e.target.value); }, value: referenceNumber }))));
};
var OuterContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n"], ["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n"])));
var DeliveryItemContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n\n  border: 1px solid ", ";\n  padding: 20px;\n  justify-content: space-between;\n  margin-top: 8px;\n\n  @media only screen and (max-width: ", "px) {\n    &:last-child {\n      flex-direction: column;\n    }\n  }\n"], ["\n  display: flex;\n  flex: 1;\n\n  border: 1px solid ", ";\n  padding: 20px;\n  justify-content: space-between;\n  margin-top: 8px;\n\n  @media only screen and (max-width: ", "px) {\n    &:last-child {\n      flex-direction: column;\n    }\n  }\n"])), function (props) { return props.theme.colors.border.base; }, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var OrderSummaryItemContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-top: 48px;\n\n  @media only screen and (max-width: ", "px) {\n    &:last-child {\n      margin-top: 24px;\n    }\n  }\n"], ["\n  margin-top: 48px;\n\n  @media only screen and (max-width: ", "px) {\n    &:last-child {\n      margin-top: 24px;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var PaymentMethodItemAmountContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 25%;\n  cursor: pointer;\n  padding: 5px 24px 5px 15px;\n  border: 1px solid ", ";\n  margin-top: 8px;\n"], ["\n  width: 25%;\n  cursor: pointer;\n  padding: 5px 24px 5px 15px;\n  border: 1px solid ", ";\n  margin-top: 8px;\n"])), function (props) { return props.theme.colors.border.base; });
var PaymentMethodItemContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  gap: 8px;\n"], ["\n  display: flex;\n  gap: 8px;\n"])));
var PaymentAmount = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject([""], [""])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;

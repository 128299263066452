var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled, { css } from 'styled-components';
import { Typography } from '../../../../../designSystem';
import SMSBubble from 'assets/sms-bubble.svg';
export var TextMessageBlock = function (_a) {
    var message = _a.message;
    return (React.createElement(Container, null,
        React.createElement(Header, null,
            React.createElement("div", null,
                React.createElement(SMSBubbleIcon, { src: SMSBubble }),
                React.createElement(Typography, null, "Text Message")),
            React.createElement(Typography, null, "now")),
        React.createElement(Body, null,
            React.createElement("span", { dangerouslySetInnerHTML: { __html: message } }))));
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: #e8ebed;\n  backdrop-filter: blur(21.7463px);\n  border-radius: 13px;\n  max-width: 450px;\n"], ["\n  background: #e8ebed;\n  backdrop-filter: blur(21.7463px);\n  border-radius: 13px;\n  max-width: 450px;\n"])));
var headerTextCss = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-style: normal;\n  font-weight: 300;\n  font-size: 13px;\n  line-height: 16px;\n  letter-spacing: -0.078px;\n"], ["\n  font-style: normal;\n  font-weight: 300;\n  font-size: 13px;\n  line-height: 16px;\n  letter-spacing: -0.078px;\n"])));
var Header = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 8px;\n  background: rgba(255, 255, 255, 0.4);\n  border-radius: 13px 13px 0 0;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  & > span {\n    ", ";\n  }\n  & > div {\n    display: flex;\n    align-items: center;\n    gap: 8px;\n    & > span {\n      ", ";\n    }\n  }\n"], ["\n  padding: 8px;\n  background: rgba(255, 255, 255, 0.4);\n  border-radius: 13px 13px 0 0;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  & > span {\n    ", ";\n  }\n  & > div {\n    display: flex;\n    align-items: center;\n    gap: 8px;\n    & > span {\n      ", ";\n    }\n  }\n"])), headerTextCss, headerTextCss);
var Body = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding: 16px;\n  & > span {\n    font-style: normal;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 24px;\n  }\n"], ["\n  padding: 16px;\n  & > span {\n    font-style: normal;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 24px;\n  }\n"])));
var SMSBubbleIcon = styled.img(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  height: 18px;\n  width: 18px;\n"], ["\n  height: 18px;\n  width: 18px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useCallback, useEffect, useRef, useState } from 'react';
import View from './View';
import { useLocation, useNavigate } from 'react-router';
import { useUser } from 'context';
import { useLocalStorage, useSessionStorage } from 'hooks';
import { PricingModel, ShippingType } from '../../../../types/codegen/hooks';
var OrderJeuveauContainer = function () {
    var _a, _b;
    var location = useLocation();
    var navigate = useNavigate();
    var account = useUser().account;
    var _c = __read(useSessionStorage('@new_order', {}), 2), orderValue = _c[0], setOrderValue = _c[1];
    var _d = __read(useState(orderValue), 2), orderData = _d[0], setOrderData = _d[1];
    var _e = __read(useState(null), 2), storedPricing = _e[0], setStoredPricing = _e[1];
    var _f = __read(useState(null), 2), selectedPaymentMethod = _f[0], setSelectedPaymentMethod = _f[1];
    var _g = __read(useLocalStorage('vialsPurchasedThisQuarter', 0), 1), vialsPurhcasedThisQuarter = _g[0];
    var _h = __read(useLocalStorage('vialsPurchasedLastQuarter', 0), 1), vialsPurhcasedLastQuarter = _h[0];
    var updatePaymentMethod = useCallback(function (pm) {
        setSelectedPaymentMethod(pm);
    }, []);
    var isFreshStart = !((_a = Object.keys(orderData)) === null || _a === void 0 ? void 0 : _a.length);
    var _j = __read(useState(isFreshStart), 2), restarting = _j[0], setRestarting = _j[1];
    useEffect(function () {
        setOrderValue(__assign(__assign({}, orderData), { currentStep: location.search }));
    }, [orderData]);
    useEffect(function () {
        if ((orderValue === null || orderValue === void 0 ? void 0 : orderValue.currentStep) &&
            (orderValue === null || orderValue === void 0 ? void 0 : orderValue.currentStep) !== '?step=orderConfirmation')
            navigate(orderValue === null || orderValue === void 0 ? void 0 : orderValue.currentStep);
        else
            navigate('?step=quantitySelection', { replace: true });
    }, []);
    var restartOrder = function () {
        setOrderValue({});
        setStoredPricing(null);
        window.sessionStorage.removeItem('@new_order');
        setOrderData({});
        navigate('?step=quantitySelection');
        setSelectedPaymentMethod(null);
    };
    var onDismount = useRef();
    useEffect(function () {
        onDismount.current = function () {
            if (window.location.pathname !== '/orders/buy') {
                setOrderValue({});
            }
        };
    }, [location, setOrderValue]);
    useEffect(function () {
        return onDismount.current;
    }, [onDismount]);
    var userOutOfBounds = orderData &&
        !((_b = Object.keys(orderData)) === null || _b === void 0 ? void 0 : _b.length) &&
        location.search !== '?step=quantitySelection' &&
        location.search !== '?step=orderConfirmation';
    if (userOutOfBounds) {
        navigate('?step=quantitySelection', { replace: true });
        return null;
    }
    return (React.createElement(View, { availableFreeVials: account.availableFreeVials, quote: {
            achDiscountPercentage: 0.02,
            availableDeliveryDates: [
                {
                    available: true,
                    comment: null,
                    date: '2024-06-30',
                    servicesAvailable: [
                        {
                            serviceId: '1',
                            serviceName: 'FedEx Priority Overnight',
                            shipperName: 'FedEx',
                            rates: [
                                {
                                    quantity: 1,
                                    rate: 20,
                                },
                            ],
                            type: ShippingType.PriorityOvernight,
                            deliveryByTime: '10:30',
                        },
                        {
                            serviceId: '2',
                            serviceName: 'FedEx Standard Overnight',
                            shipperName: 'FedEx',
                            rates: [
                                {
                                    quantity: 1,
                                    rate: 20,
                                },
                            ],
                            type: ShippingType.StandardOvernight,
                            deliveryByTime: '16:30',
                        },
                    ],
                    __typename: 'QuoteShippingDate',
                },
            ],
            credit: {
                available: 0,
                limit: 20000,
            },
            defaultShipping: {
                deliveryByTime: '10:30',
                rates: [
                    {
                        quantity: 1,
                        rate: 20,
                    },
                ],
                serviceId: '1',
                serviceName: 'Priority Overnight',
                shipperName: 'FedEx',
                type: ShippingType.PriorityOvernight,
            },
            jet: {
                available: 0,
            },
            pricing: {
                bulkRates: [
                    {
                        rate: 375,
                        quantity: 6,
                        discountPercentage: 6.1,
                    },
                    {
                        rate: 363,
                        quantity: 12,
                        discountPercentage: 9.15,
                    },
                    {
                        rate: 351,
                        quantity: 24,
                        discountPercentage: 12.2,
                    },
                    {
                        rate: 339,
                        quantity: 48,
                        discountPercentage: 15.25,
                    },
                    {
                        rate: 326,
                        quantity: 96,
                        discountPercentage: 18.3,
                    },
                ],
                defaultRate: 400,
                hasBulkRates: true,
                msrp: 610,
                pricingModel: PricingModel.AccountLoyalty,
                quantityAvailable: 200000,
                rates: [{ quantity: 0, rate: 400 }],
            },
        }, calculatePricing: function () { }, calculatedPricing: storedPricing, calculatedPricingLoading: false, calculatedPricingError: undefined, currentQuarterLoyalty: account === null || account === void 0 ? void 0 : account.currentQuarterLoyalty, boostEnabled: false, accountById: account, isLoading: false, error: undefined, orderData: orderData, setOrderData: setOrderData, pricingModel: account === null || account === void 0 ? void 0 : account.pricingModel, vialsPurchasedThisQuarter: vialsPurhcasedThisQuarter, vialsPurchasedLastQuarter: vialsPurhcasedLastQuarter, restarting: restarting, setRestarting: setRestarting, setStoredPricing: setStoredPricing, onRestart: restartOrder, selectedPaymentMethod: selectedPaymentMethod, setSelectedPaymentMethod: updatePaymentMethod }));
};
export default OrderJeuveauContainer;

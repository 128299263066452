var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
export var TabNav = function (_a) {
    var tabs = _a.tabs, disabled = _a.disabled;
    return (React.createElement(PageTabsContainer, null, tabs.map(function (tab) { return (React.createElement(TabLink, { to: tab.path, key: tab.path }, function (_a) {
        var isActive = _a.isActive;
        return (React.createElement(Tab, { disabled: disabled, active: !disabled && isActive }, tab.label));
    })); })));
};
var TabLink = styled(NavLink)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  text-decoration: none;\n"], ["\n  text-decoration: none;\n"])));
var PageTabsContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  margin: 0 16px;\n  gap: 28px;\n  @media screen and (max-width: ", "px) {\n    margin: 0 32px;\n    padding: 0 8px;\n    overflow-x: scroll;\n    &::-webkit-scrollbar {\n      display: none;\n    }\n    -ms-overflow-style: none;\n  }\n"], ["\n  display: flex;\n  margin: 0 16px;\n  gap: 28px;\n  @media screen and (max-width: ", "px) {\n    margin: 0 32px;\n    padding: 0 8px;\n    overflow-x: scroll;\n    &::-webkit-scrollbar {\n      display: none;\n    }\n    -ms-overflow-style: none;\n  }\n"])), function (props) { return props.theme.breakpoints.md; });
var Tab = styled.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  all: unset;\n  cursor: ", ";\n  color: ", ";\n  padding-bottom: 5px;\n  border-bottom: ", ";\n  white-space: nowrap;\n"], ["\n  all: unset;\n  cursor: ", ";\n  color: ", ";\n  padding-bottom: 5px;\n  border-bottom: ", ";\n  white-space: nowrap;\n"])), function (props) { return (props.disabled ? 'default' : 'pointer'); }, function (props) {
    return (props === null || props === void 0 ? void 0 : props.active)
        ? props === null || props === void 0 ? void 0 : props.theme.colors.text.link
        : props === null || props === void 0 ? void 0 : props.theme.colors.text.alt;
}, function (props) { return ((props === null || props === void 0 ? void 0 : props.active) ? '2.1px solid red' : 'none'); });
var templateObject_1, templateObject_2, templateObject_3;

import React from 'react';
import { Outlet } from 'react-router-dom';
import { TabNav } from '../../components/TabNav';
import { useAllowAdminOnly } from 'hooks/useAllowAdminOnly';
var tabs = [
    {
        label: 'Dashboard',
        path: '/invoices/dashboard',
    },
    {
        label: 'Make a Payment',
        path: '/invoices/make-payment',
    },
    {
        label: 'Payment History',
        path: '/invoices/history',
    },
];
export var Invoices = function () {
    useAllowAdminOnly();
    return (React.createElement(React.Fragment, null,
        React.createElement(TabNav, { tabs: tabs }),
        React.createElement(Outlet, null)));
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { Modal } from '../Modal';
import styled, { css } from 'styled-components';
import { Button, Icon, PlaceholderLoading } from 'designSystem';
import { useQuery } from '@apollo/client';
import { useUser } from 'context';
import query from './index.graphql';
import { downloadFromLink } from 'utils/downloadFromLink';
import { addMobileStyles } from '../../../utils/addMobileStyles';
import { useScreenSizing } from '../../../hooks';
var base64ToUint8Array = function (base64) {
    var raw = atob(base64);
    var uint8Array = new Uint8Array(raw.length);
    for (var i = 0; i < raw.length; i++) {
        uint8Array[i] = raw.charCodeAt(i);
    }
    return uint8Array;
};
export var InvoicePdfViewer = function (_a) {
    var _b, _c;
    var invoiceId = _a.invoiceId;
    var account = useUser().account;
    var isMobile = useScreenSizing().isMobile;
    var _d = useQuery(query, {
        variables: {
            input: {
                invoiceId: invoiceId,
                accountId: account.id,
            },
        },
    }), data = _d.data, loading = _d.loading;
    var invoiceData = (_c = (_b = data === null || data === void 0 ? void 0 : data.getInvoicePdf) === null || _b === void 0 ? void 0 : _b.data) !== null && _c !== void 0 ? _c : null;
    var downloadDocument = function () {
        downloadFromLink({
            url: "data:application/pdf;base64,".concat(invoiceData),
            downloadName: "".concat(invoiceId, ".pdf"),
        });
    };
    return (React.createElement(Modal, { transparent: true, backdropColor: isMobile ? '#fff' : undefined },
        loading ? (React.createElement(LoadingContainer, null,
            React.createElement(PlaceholderLoading, { size: "large" }))) : (React.createElement(DocumentWrapper, null,
            React.createElement(Document, { file: { data: base64ToUint8Array(invoiceData) } },
                React.createElement(Page, { pageNumber: 1, renderAnnotationLayer: false })))),
        React.createElement(ActionContainer, null,
            React.createElement(Button, { variant: isMobile ? 'tertiary' : 'outlinedSecondary', startIcon: React.createElement(Icon, { name: "download", color: "inherit" }), disabled: loading || !invoiceData, onClick: downloadDocument },
                isMobile && 'Download ',
                "PDF"))));
};
var ActionContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  justify-content: flex-end;\n  margin-top: 16px;\n  & > button {\n    ", ";\n  }\n  ", "\n"], ["\n  display: flex;\n  width: 100%;\n  justify-content: flex-end;\n  margin-top: 16px;\n  & > button {\n    ", ";\n  }\n  ", "\n"])), function (props) { return props.theme.borders.alt2; }, addMobileStyles(css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    justify-content: center;\n    margin-top: auto;\n    & > button {\n      border: none;\n    }\n  "], ["\n    justify-content: center;\n    margin-top: auto;\n    & > button {\n      border: none;\n    }\n  "])))));
var LoadingContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  min-height: 60vh;\n  width: 100%;\n"], ["\n  display: flex;\n  min-height: 60vh;\n  width: 100%;\n"])));
var DocumentWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), function (props) {
    return addMobileStyles(css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      ", ";\n      margin-top: 16px;\n      canvas {\n        width: 100% !important;\n        height: auto !important;\n      }\n    "], ["\n      ", ";\n      margin-top: 16px;\n      canvas {\n        width: 100% !important;\n        height: auto !important;\n      }\n    "])), props.theme.borders.base));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled, { css } from 'styled-components';
export function StackedMobileCard(props) {
    var children = props.children;
    return React.createElement(StackedCard, null, children);
}
export var stackedCardStyles = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  padding: 44px 32px 60px;\n  background: #ffffff;\n  margin-top: -16px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  padding: 44px 32px 60px;\n  background: #ffffff;\n  margin-top: -16px;\n"])));
var StackedCard = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "\n  ", "\n"], ["\n  ", "\n  ", "\n"])), stackedCardStyles, function (_a) {
    var theme = _a.theme;
    return theme.elevation.base.elevation2;
});
var templateObject_1, templateObject_2;

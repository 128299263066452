import React from 'react';
import { UserPermissionsContainer } from './Container';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
export var RoutedUserPermissions = function () {
    var selectedUserId = useParams().userId;
    var pathname = useLocation().pathname;
    var isUserInvite = pathname.includes('new');
    return (React.createElement(UserPermissionsContainer, { isUserInvite: isUserInvite, selectedUserId: selectedUserId }));
};

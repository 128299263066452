var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled, { css } from 'styled-components';
import EnrollPatientGraphic from 'assets/enroll-patient-graphic-1.svg';
import { Typography } from 'designSystem';
import EnrollmentStatusItem from '../components/EnrollmentStatusItem';
import CheckInProgress from '../../CheckInProgress';
import { useNavigate } from 'react-router';
import { StepEnum } from '../../../Enums/StepEnum';
import { StepContainer } from '../../StepContainer';
import { useScreenSizing } from 'hooks';
import { customBreakpointStyles } from 'utils/customBreakpointStyles';
import { addMobileStyles } from 'utils/addMobileStyles';
var customBreakPoint = 1134;
export var EnrollPatient = function (_a) {
    var phoneNumber = _a.phoneNumber, handleRestart = _a.handleRestart;
    var navigate = useNavigate();
    var _b = useScreenSizing(customBreakPoint), isMobile = _b.isMobile, isCustom = _b.isCustom;
    return (React.createElement(React.Fragment, null,
        React.createElement(StepContainer, { handleRestart: handleRestart },
            React.createElement(OuterContainer, null,
                React.createElement("div", null,
                    React.createElement(LeftContainer, null,
                        React.createElement(Image, { src: EnrollPatientGraphic })),
                    React.createElement(RightContainer, null,
                        React.createElement(Typography, { variant: "titleLarge" }, "Enroll Patient"),
                        React.createElement(EnrollText, { variant: "body", color: "alt" }, "Enroll patient into the Evolus Rewards program."),
                        isMobile && (React.createElement(EnrollText, { mt: 24, variant: "body", color: "alt" }, "Click below to send invite.")),
                        !isCustom && React.createElement(EnrollmentStatusItem, { phoneNumber: phoneNumber }))),
                isCustom && (React.createElement("div", null,
                    React.createElement(EnrollmentStatusItem, { phoneNumber: phoneNumber }))))),
        React.createElement(CheckInProgress, { primaryButtonText: "Start Enrollment", onContinue: function () { return navigate(StepEnum.PatientConsent); }, tertiaryAction: handleRestart, tertiaryButtonText: isMobile && 'Restart Enrollment' })));
};
var OuterContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: block;\n  width: 100%;\n  border: 1px solid ", ";\n\n  & > div:first-of-type {\n    display: flex;\n  }\n\n  padding: 32px;\n  @media screen and (max-width: ", "px) {\n    border: none;\n    padding: 0;\n    & > div:first-of-type {\n      flex-direction: column;\n    }\n  }\n"], ["\n  display: block;\n  width: 100%;\n  border: 1px solid ", ";\n\n  & > div:first-of-type {\n    display: flex;\n  }\n\n  padding: 32px;\n  @media screen and (max-width: ", "px) {\n    border: none;\n    padding: 0;\n    & > div:first-of-type {\n      flex-direction: column;\n    }\n  }\n"])), function (props) { return props.theme.colors.border.base; }, function (props) { return props.theme.breakpoints.md; });
var LeftContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  justify-content: center;\n  align-items: center;\n  margin-right: 32px;\n  @media screen and (max-width: ", "px) {\n    margin-right: 0;\n    margin-bottom: 30px;\n  }\n  ", "\n"], ["\n  display: flex;\n  flex: 1;\n  justify-content: center;\n  align-items: center;\n  margin-right: 32px;\n  @media screen and (max-width: ", "px) {\n    margin-right: 0;\n    margin-bottom: 30px;\n  }\n  ", "\n"])), function (props) { return props.theme.breakpoints.md; }, customBreakpointStyles(customBreakPoint, css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      margin-bottom: 32px;\n    "], ["\n      margin-bottom: 32px;\n    "])))));
var Image = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  max-width: 100%;\n  max-height: 100%;\n"], ["\n  max-width: 100%;\n  max-height: 100%;\n"])));
var RightContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex: 2;\n  flex-direction: column;\n  justify-content: center;\n"], ["\n  display: flex;\n  flex: 2;\n  flex-direction: column;\n  justify-content: center;\n"])));
var EnrollText = styled(Typography)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin: 16px 0 32px;\n  ", "\n"], ["\n  margin: 16px 0 32px;\n  ", "\n"])), addMobileStyles(css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    margin: 16px 0 24px;\n  "], ["\n    margin: 16px 0 24px;\n  "])))));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;

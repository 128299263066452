import React from 'react';
export var Candy = function (_a) {
    var color = _a.color;
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 48 48", width: "48", height: "48", fill: "none" },
        React.createElement("g", { stroke: color, strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 3, clipPath: "url(#candy-svg-clip)" },
            React.createElement("path", { d: "M11.84 24a12.16 12.16 0 1 0 24.32 0 12.16 12.16 0 0 0-24.32 0Z" }),
            React.createElement("path", { d: "M36 21.66a14.318 14.318 0 0 1-10.86 4.78 14 14 0 0 1-12.44-6.96M34.94 2.74a1.52 1.52 0 0 0-2.36.28c-2.38 4-2.7 9.66 0 12.38 2.7 2.72 8.42 2.4 12.38 0a1.521 1.521 0 0 0 .28-2.36l-10.3-10.3ZM2.74 34.94a1.52 1.52 0 0 1 .28-2.36c4-2.38 9.66-2.7 12.38 0 2.72 2.7 2.4 8.42 0 12.38a1.521 1.521 0 0 1-2.36.28l-10.3-10.3Z" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "candy-svg-clip" },
                React.createElement("path", { fill: "#fff", d: "M0 0h48v48H0z" })))));
};

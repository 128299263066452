import { useEffect } from 'react';
export var useOutsideClickHandler = function (_a) {
    var element = _a.element, handler = _a.handler;
    useEffect(function () {
        var handleClick = function (event) {
            if (element === null || element === void 0 ? void 0 : element.contains(event.target)) {
                return;
            }
            handler();
        };
        document.addEventListener('mousedown', handleClick);
        return function () { return document.removeEventListener('mousedown', handleClick); };
    }, [element, handler]);
};

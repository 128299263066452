var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useMemo } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { Button } from '../Button';
import { Icon } from '../Icon';
var getIconSize = function (size) {
    if (size === void 0) { size = 'medium'; }
    if (size === 'small')
        return 12;
    if (size === 'medium')
        return 16;
    return 20;
};
export var Pagination = function (_a) {
    var page = _a.page, total = _a.total, limit = _a.limit, onPageChange = _a.onPageChange, _b = _a.size, size = _b === void 0 ? 'medium' : _b, _c = _a.maxPages, maxPages = _c === void 0 ? 7 : _c, _d = _a.buttonNavigation, buttonNavigation = _d === void 0 ? false : _d, _e = _a.showLimits, showLimits = _e === void 0 ? true : _e, _f = _a.justifyContent, justifyContent = _f === void 0 ? 'flex-start' : _f;
    var theme = useTheme();
    var pages = useMemo(function () { return __spreadArray([], __read(Array(Math.ceil(total / limit)).keys()), false).map(function (p) { return Number(p); }); }, [total, limit]);
    return (React.createElement(Root, { showLimits: showLimits, justifyContent: justifyContent },
        React.createElement(Paging, null,
            React.createElement(Button, { startIcon: buttonNavigation ? undefined : 'chevron-left', variant: "quaternary", disabled: page === 0, size: size, icon: buttonNavigation, onClick: function () { return onPageChange(page - 1); } }, buttonNavigation ? (React.createElement(Icon, { name: "chevron-left", size: getIconSize(size), color: page === 0 ? theme.colors.text.alt5 : theme.colors.text.base })) : ('Back')),
            pages
                .filter(function (p, _, arr) {
                if (page < maxPages - 3 && p <= maxPages - 1)
                    return true;
                if (page > arr.length - 4 && p > arr.length - 1 - maxPages)
                    return true;
                return p >= page - (maxPages - 3) && p <= page + 2;
            })
                .map(function (p) { return (React.createElement(Page, { key: "page-".concat(p), selected: page === p, onClick: function () { return onPageChange(p); }, size: size }, p + 1)); }),
            React.createElement(Button, { endIcon: buttonNavigation ? undefined : 'chevron-right', variant: "quaternary", disabled: page * limit + limit >= total, size: size, icon: buttonNavigation, onClick: function () { return onPageChange(page + 1); } }, buttonNavigation ? (React.createElement(Icon, { name: "chevron-right", size: getIconSize(size), color: page * limit + limit >= total
                    ? theme.colors.text.alt5
                    : theme.colors.text.base })) : ('Next')))));
};
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  width: 100%;\n  ", "\n\n  @media (max-width: 1080px) {\n    flex-wrap: wrap;\n  }\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  width: 100%;\n  ", "\n\n  @media (max-width: 1080px) {\n    flex-wrap: wrap;\n  }\n"])), function (props) {
    return props.justifyContent && "justify-content: ".concat(props.justifyContent, ";");
});
var Paging = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  order: 1;\n\n  align-items: center;\n}\n\n  @media (max-width: 1080px) {\n    flex-basis: 100%;\n    order: 0;\n    justify-content: center;\n    margin-bottom: 12px;\n  }\n"], ["\n  display: flex;\n  order: 1;\n\n  align-items: center;\n}\n\n  @media (max-width: 1080px) {\n    flex-basis: 100%;\n    order: 0;\n    justify-content: center;\n    margin-bottom: 12px;\n  }\n"])));
var getPageSize = function (size) {
    if (size === void 0) { size = 'medium'; }
    if (size === 'large') {
        return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      width: 40px;\n      height: 40px;\n      padding: 0 16px;\n      margin: 0 20px;\n      font-size: 18px;\n      line-height: 40px;\n    "], ["\n      width: 40px;\n      height: 40px;\n      padding: 0 16px;\n      margin: 0 20px;\n      font-size: 18px;\n      line-height: 40px;\n    "])));
    }
    if (size === 'medium') {
        return css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      width: 32px;\n      height: 32px;\n      padding: 0 12px;\n      margin: 0 16px;\n      font-size: 16px;\n      line-height: 32px;\n    "], ["\n      width: 32px;\n      height: 32px;\n      padding: 0 12px;\n      margin: 0 16px;\n      font-size: 16px;\n      line-height: 32px;\n    "])));
    }
    return css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    width: 24px;\n    height: 24px;\n    padding: 0 8px;\n    margin: 0 12px;\n    font-size: 14px;\n    line-height: 24px;\n  "], ["\n    width: 24px;\n    height: 24px;\n    padding: 0 8px;\n    margin: 0 12px;\n    font-size: 14px;\n    line-height: 24px;\n  "])));
};
var Page = styled.div.attrs({
    role: 'button',
})(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n\n  background: ", ";\n  color: ", ";\n  ", "\n  &:hover {\n    background: ", ";\n    color: ", ";\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n\n  background: ", ";\n  color: ", ";\n  ", "\n  &:hover {\n    background: ", ";\n    color: ", ";\n  }\n"])), function (props) {
    return props.selected
        ? props.theme.colors.text.alt
        : props.theme.colors.white.white100;
}, function (props) {
    return props.selected
        ? props.theme.colors.text.alt3
        : props.theme.colors.white.white900;
}, function (props) { return getPageSize(props.size); }, function (props) {
    return props.selected
        ? props.theme.colors.text.alt
        : props.theme.colors.text.alt2;
}, function (props) {
    return props.selected
        ? props.theme.colors.text.alt3
        : props.theme.colors.white.white900;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components';
import toast from 'react-hot-toast';
import { Typography } from '../Typography/Typography';
import { Icon } from '../Icon';
function getIcon(type) {
    var types = {
        default: null,
        error: React.createElement("span", null, "!"),
        pending: React.createElement(Icon, { name: "toast-pending", size: 24 }),
        success: React.createElement(Icon, { name: "toast-success", size: 24 }),
    };
    return types[type] || types['default'];
}
function getTypeStyle(type) {
    var types = {
        default: "color: #155050; background: #EAF9FA; border: 2px solid #B1EBF2;",
        pending: "color: #D88218; background: #FFF4E5; border: 2px solid #FFE0B7",
        success: "color: #3B6955; background: #ECF8F6; border: 2px solid #D1EFE9",
        error: "color: #E5554F; background: #FCF2F2; border: 2px solid #F7C9C7",
    };
    return types[type] || types['default'];
}
var ToastContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n"], ["\n  display: flex;\n  width: 100%;\n"])));
var ToastContent = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 12px 24px;\n  width: 100%;\n  display: inline-flex;\n\n  justify-content: space-between;\n  ", "\n"], ["\n  padding: 12px 24px;\n  width: 100%;\n  display: inline-flex;\n\n  justify-content: space-between;\n  ", "\n"])), function (props) { return getTypeStyle(props.type); });
var ToastLeftContent = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: inline-flex;\n  align-items: center;\n"], ["\n  display: inline-flex;\n  align-items: center;\n"])));
var ToastIcon = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-right: 24px;\n"], ["\n  margin-right: 24px;\n"])));
var ToastMessage = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject([""], [""])));
var ToastItem = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject([""], [""])));
export var Toast = function (_a) {
    var message = _a.message, type = _a.type, rightItem = _a.rightItem, props = __rest(_a, ["message", "type", "rightItem"]);
    return (React.createElement(ToastContainer, null,
        React.createElement(ToastContent, { type: type },
            React.createElement(ToastLeftContent, null,
                type !== 'default' && React.createElement(ToastIcon, null,
                    " ",
                    getIcon(type),
                    " "),
                React.createElement(ToastMessage, null,
                    React.createElement(Typography, { variant: "body", color: type === 'success' ? 'teal900' : 'coral900' }, message))),
            React.createElement(ToastItem, null, rightItem))));
};
export var showToast = function (_a) {
    var duration = _a.duration, args = __rest(_a, ["duration"]);
    toast.custom(React.createElement(Toast, __assign({}, args)), __assign({}, (duration && { duration: duration })));
};
export default Toast;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;

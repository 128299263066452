import React from 'react';
export var Savings = function (_a) {
    var color = _a.color;
    return (React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M11.8 13.333C11.6599 13.328 11.5236 13.3785 11.4206 13.4736C11.3176 13.5686 11.2563 13.7005 11.25 13.8405V17.833C11.2561 17.9709 11.3164 18.1009 11.4179 18.1945C11.5193 18.2882 11.6537 18.338 11.7917 18.333H18.825C18.9651 18.338 19.1014 18.2874 19.2044 18.1924C19.3074 18.0974 19.3687 17.9655 19.375 17.8255V13.958C19.3676 13.7854 19.2922 13.6228 19.1653 13.5056C19.0383 13.3885 18.8701 13.3264 18.6975 13.333H11.8Z", stroke: color, strokeWidth: "1.25", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M15.3125 15.5259C15.3731 15.5269 15.432 15.5457 15.482 15.5801C15.5319 15.6145 15.5705 15.6629 15.593 15.7191C15.6155 15.7754 15.6209 15.8371 15.6085 15.8964C15.596 15.9557 15.5664 16.01 15.5232 16.0525C15.48 16.095 15.4252 16.1238 15.3657 16.1353C15.3062 16.1468 15.2446 16.1404 15.1887 16.117C15.1328 16.0936 15.0851 16.0541 15.0515 16.0037C15.0179 15.9532 15 15.894 15 15.8334C15.0003 15.7927 15.0087 15.7524 15.0246 15.7149C15.0404 15.6775 15.0635 15.6435 15.0926 15.6149C15.1216 15.5864 15.1559 15.5638 15.1937 15.5485C15.2314 15.5333 15.2718 15.5256 15.3125 15.5259Z", stroke: color, strokeWidth: "1.25", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M11.249 15.711C10.6902 16.5202 9.94318 17.1817 9.07224 17.6385C8.20131 18.0953 7.23248 18.3338 6.24902 18.3335C3.42486 18.3335 0.624023 16.2352 0.624023 13.646C0.624023 11.0893 2.83986 8.26433 4.54486 7.0835C4.05319 6.00016 3.65736 5.146 3.34902 4.48766C3.26001 4.29717 3.22049 4.0873 3.23412 3.87747C3.24775 3.66765 3.31409 3.46465 3.42699 3.28727C3.5399 3.10989 3.69573 2.96386 3.88006 2.86269C4.06439 2.76153 4.27126 2.70849 4.48152 2.7085H8.74902", stroke: color, strokeWidth: "1.25", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M9.83301 8.87158C10.9377 10.1244 11.6446 11.6775 11.8638 13.3332", stroke: color, strokeWidth: "1.25", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M4.85652 14.8699C5.037 15.107 5.27195 15.297 5.54148 15.4239C5.81101 15.5509 6.10717 15.611 6.40486 15.5991C7.35402 15.5991 8.12402 15.0216 8.12402 14.3099C8.12402 13.5983 7.35402 13.0216 6.40486 13.0216C5.45569 13.0216 4.68652 12.4441 4.68652 11.7316C4.68652 11.0191 5.45652 10.4424 6.40486 10.4424C6.70257 10.4304 6.9988 10.4904 7.26837 10.6173C7.53793 10.7443 7.77286 10.9344 7.95319 11.1716", stroke: color, strokeWidth: "1.25", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M6.40527 15.5991V16.4583", stroke: color, strokeWidth: "1.25", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M6.40527 9.58301V10.4422", stroke: color, strokeWidth: "1.25", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M19.3744 4.40467V1.45801H16.4277", stroke: color, strokeWidth: "1.25", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M19.3743 1.46094L16.666 4.16677C16.5122 4.32085 16.3196 4.43035 16.1086 4.48362C15.8976 4.53689 15.676 4.53193 15.4676 4.46927L12.4085 3.65177C12.2037 3.59685 11.988 3.59682 11.7832 3.65167C11.5784 3.70653 11.3917 3.81434 11.2418 3.96427L8.12012 7.08927", stroke: color, strokeWidth: "1.25", strokeLinecap: "round", strokeLinejoin: "round" })));
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled, { withTheme } from 'styled-components';
var CircularProgress = function (_a) {
    var radius = _a.radius, children = _a.children, theme = _a.theme, vialsRequired = _a.vialsRequired, vialsRedeemed = _a.vialsRedeemed, isLoading = _a.isLoading, _b = _a.width, width = _b === void 0 ? 240 : _b, colorOverride = _a.colorOverride, backgroundColorOverride = _a.backgroundColorOverride;
    var progress = (vialsRedeemed / vialsRequired) * 100;
    var circumference = 2 * Math.PI * radius;
    var strokeLength = (circumference / 360) * 360;
    var dashOffset = (strokeLength / 100) * (100 - (progress || 1));
    return (React.createElement(Container, { isLoading: isLoading },
        React.createElement("svg", { width: width, height: width, viewBox: "0 0 ".concat(width, " ").concat(width), style: { transform: "rotate(".concat(-90, "deg)") } },
            React.createElement("circle", { cx: width / 2, cy: width / 2, r: radius, fill: "none", stroke: backgroundColorOverride !== null && backgroundColorOverride !== void 0 ? backgroundColorOverride : theme.colors.border.base, strokeWidth: 8, strokeLinecap: 'round' }),
            React.createElement("circle", { cx: width / 2, cy: width / 2, r: radius, fill: 'transparent', stroke: colorOverride !== null && colorOverride !== void 0 ? colorOverride : theme.colors.text.link, strokeWidth: 12, strokeDasharray: "".concat(strokeLength, ", ").concat(circumference), strokeDashoffset: dashOffset, strokeLinecap: 'round' })),
        children || null));
};
CircularProgress.defaultProps = {
    radius: 100,
    progress: 0,
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  ", "\n"], ["\n  position: relative;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  ", "\n"])), function (props) {
    return props.isLoading &&
        "z-index: 1;\n    ".concat(props.theme.blur.loading);
});
export default withTheme(CircularProgress);
var templateObject_1;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { Button, Input, LoadingBubbles, Typography } from 'designSystem';
import SendCodeGraphic from 'assets/forgot-password-graphic.svg';
import { useScreenSizing } from '../../../../../hooks';
export var SendCode = function (_a) {
    var emailAddress = _a.emailAddress, isLoading = _a.isLoading, setEmailAddress = _a.setEmailAddress, renderedError = _a.renderedError, getVerificationCode = _a.getVerificationCode;
    var isMobile = useScreenSizing().isMobile;
    return (React.createElement(OuterContainer, null,
        React.createElement(LeftContainer, null,
            React.createElement(Typography, { align: isMobile ? 'center' : 'left', variant: isMobile ? 'display' : 'titleLarge', color: isMobile ? 'link' : 'alt', mb: 16 }, "Forgot your password?"),
            React.createElement(Typography, { align: isMobile ? 'center' : 'left', variant: isMobile ? 'subtitle' : 'body', color: "alt", mb: 16 }, "Don't worry, resetting your password is easy. Just enter the email address you registered with Evolus."),
            React.createElement(ButtonSpacer, null),
            React.createElement(Input, { onChange: function (e) { return setEmailAddress(e.target.value); }, label: "Email", placeholder: "Email Address", value: emailAddress, name: "email", type: "text" }),
            renderedError,
            React.createElement(ButtonSpacer, null),
            React.createElement(Button, { size: "xlarge", variant: "primary", onClick: function () { return getVerificationCode(); }, disabled: !emailAddress || isLoading }, isLoading ? React.createElement(LoadingBubbles, null) : 'Send Me My Reset Code')),
        React.createElement(ImageContainer, null,
            React.createElement(Image, { src: SendCodeGraphic }))));
};
var OuterContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  flex-direction: row;\n  align-items: center;\n  z-index: 10000;\n  position: relative;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column-reverse;\n  }\n"], ["\n  display: flex;\n  flex: 1;\n  flex-direction: row;\n  align-items: center;\n  z-index: 10000;\n  position: relative;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column-reverse;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var LeftContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  & > button {\n    margin-top: auto;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  & > button {\n    margin-top: auto;\n  }\n"])));
var ImageContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex: 1;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex: 1;\n"])));
var Image = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 80%;\n"], ["\n  width: 80%;\n"])));
var ButtonSpacer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-top: 48px;\n  @media only screen and (max-width: ", "px) {\n    margin-top: 0;\n  }\n"], ["\n  margin-top: 48px;\n  @media only screen and (max-width: ", "px) {\n    margin-top: 0;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;

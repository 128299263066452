var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useEffect } from 'react';
import { StepContainer } from '../../StepContainer';
import CheckInProgress from '../../CheckInProgress';
import { Typography, Toast } from 'designSystem';
import WaitingImage from 'assets/waiting.svg';
import MessageSentMobile from 'assets/message-sent-m.svg';
import MessageSent from 'assets/message-sent.svg';
import styled, { css } from 'styled-components';
import { ConsumerProfileStatus, EnrollmentMethod } from 'types/codegen/hooks';
import { useNavigate } from 'react-router';
import { StepEnum } from '../../../Enums/StepEnum';
import { TextMessageBlock } from '../../TextMessageBlock';
import { addMobileStyles } from 'utils/addMobileStyles';
import phoneNumberFormatter from 'utils/phoneNumberFormatter';
import { EmailMessageBlock } from '../../EmailMessageBlock';
import { useScreenSizing } from 'hooks';
import { InvertableImage } from 'components/InvertableImage';
var getMessageSentGraphic = function (isMobile) {
    if (isMobile) {
        return MessageSentMobile;
    }
    return MessageSent;
};
export var EnrollmentProgressView = function (_a) {
    var loading = _a.loading, phoneNumber = _a.phoneNumber, profile = _a.profile, reward = _a.reward, email = _a.email, onEnroll = _a.onEnroll, enrollmentMethod = _a.enrollmentMethod, noPromptRestart = _a.noPromptRestart, onResend = _a.onResend, retryLink = _a.retryLink, isInitialEmail = _a.isInitialEmail;
    var navigate = useNavigate();
    var isMobile = useScreenSizing().isMobile;
    var isEmailEnrollment = enrollmentMethod === EnrollmentMethod.Email;
    var formattedPhone = phoneNumberFormatter(phoneNumber);
    var isRetry = retryLink && !isInitialEmail;
    var message = "Welcome to Evolus Rewards\u2122! \uD83E\uDD8B You are officially on your way to earning rewards every 90 days!";
    var getSecondaryButtonText = function () {
        if (isRetry) {
            return null;
        }
        if (isEmailEnrollment) {
            return 'Resend Enrollment Email';
        }
        return 'Resend Enrollment SMS';
    };
    useEffect(function () {
        if ((profile === null || profile === void 0 ? void 0 : profile.status) === ConsumerProfileStatus.Complete && !!reward) {
            onEnroll();
            navigate(StepEnum.RewardStatus);
        }
    }, [profile, reward, navigate, onEnroll]);
    return (React.createElement(React.Fragment, null,
        React.createElement(StepContainer, { label: isMobile ? null : 'Reward Status', hideRestart: true },
            React.createElement(Toast, { message: "Enrollment Pending", type: "pending" }),
            React.createElement(PendingContainer, null,
                React.createElement("div", null,
                    React.createElement(Typography, { variant: "body", mb: 16 },
                        "Enrollment ",
                        isEmailEnrollment ? 'Email' : 'Text',
                        " successfully",
                        ' ',
                        isRetry ? 'resent' : 'sent',
                        " to",
                        ' ',
                        React.createElement("strong", null, isEmailEnrollment ? email : formattedPhone)),
                    isEmailEnrollment ? (React.createElement(EmailMessageBlock, { message: message })) : (React.createElement(TextMessageBlock, { message: message })),
                    React.createElement(Typography, { variant: "bodySmall", mt: 16 },
                        "Remind the patient to enroll via",
                        ' ',
                        isEmailEnrollment ? 'Email' : 'SMS',
                        "."),
                    !isEmailEnrollment && (React.createElement(Typography, { variant: "bodySmall", color: "alt", mt: 16 },
                        "Having trouble enrolling patient?",
                        ' ',
                        React.createElement(Link, { onClick: function () {
                                navigate(StepEnum.EmailInput);
                            } }, "Send via email")))),
                React.createElement(InvertableImage, { invert: isRetry && !isMobile, src: isRetry ? getMessageSentGraphic(isMobile) : WaitingImage, alt: "waiting" }))),
        React.createElement(CheckInProgress, { onContinue: function () {
                onResend();
                navigate(StepEnum.EnrollPatientForm);
            }, primaryButtonText: "Enroll Patient Myself", isLoading: loading, secondaryButtonText: getSecondaryButtonText(), onSecondaryContinue: function () {
                onResend();
                navigate(StepEnum.SendingMessage);
            }, tertiaryButtonText: "Check Another Status", tertiaryAction: noPromptRestart })));
};
var PendingContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-top: 32px;\n  padding: 32px;\n  ", ";\n  & > * {\n    flex: 1;\n  }\n  & > img {\n    max-width: 229px;\n    width: 100%;\n  }\n  & > div:first-of-type {\n    display: flex;\n    flex-direction: column;\n  }\n  ", "\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-top: 32px;\n  padding: 32px;\n  ", ";\n  & > * {\n    flex: 1;\n  }\n  & > img {\n    max-width: 229px;\n    width: 100%;\n  }\n  & > div:first-of-type {\n    display: flex;\n    flex-direction: column;\n  }\n  ", "\n"])), function (props) { return props.theme.borders.base; }, addMobileStyles(css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    border: none;\n    padding: 0;\n    flex-direction: column-reverse;\n    margin-top: 0;\n    & > img {\n      max-width: 140px;\n      margin: 24px 0;\n    }\n  "], ["\n    border: none;\n    padding: 0;\n    flex-direction: column-reverse;\n    margin-top: 0;\n    & > img {\n      max-width: 140px;\n      margin: 24px 0;\n    }\n  "])))));
var Link = styled.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  all: unset;\n  cursor: pointer;\n  color: ", ";\n  margin-left: 12px;\n  &:hover {\n    text-decoration: underline;\n  }\n"], ["\n  all: unset;\n  cursor: pointer;\n  color: ", ";\n  margin-left: 12px;\n  &:hover {\n    text-decoration: underline;\n  }\n"])), function (props) { return props.theme.colors.text.link; });
var templateObject_1, templateObject_2, templateObject_3;

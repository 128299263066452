var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { InputStepper } from 'designSystem/components/InputStepper/InputStepper';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import jeaveauVial from 'assets/jeuveau-shadow.png';
import { useScreenSizing } from 'hooks/useScreenSizing';
var RewardVialSelectionContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  display: flex;\n  position: relative;\n  padding: 64px;\n\n  @media only screen and (max-width: ", "px) {\n    padding: 0;\n    background: transparent;\n    flex: 1;\n  }\n"], ["\n  background-color: ", ";\n  display: flex;\n  position: relative;\n  padding: 64px;\n\n  @media only screen and (max-width: ", "px) {\n    padding: 0;\n    background: transparent;\n    flex: 1;\n  }\n"])), function (props) { return props.theme.colors.bg.alt3; }, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var JeaveauVialImageContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: white;\n  margin-right: 68px;\n\n  @media only screen and (min-width: ", "px) {\n    padding: 28px 98px;\n    ", ";\n  }\n\n  @media only screen and (max-width: ", "px) {\n    margin-right: 44px;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: white;\n  margin-right: 68px;\n\n  @media only screen and (min-width: ", "px) {\n    padding: 28px 98px;\n    ", ";\n  }\n\n  @media only screen and (max-width: ", "px) {\n    margin-right: 44px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
}, function (props) { return props.theme.elevation.base.elevation1; }, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var JeaveauVialImage = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 84px;\n\n  @media only screen and (max-width: ", "px) {\n    margin: 0;\n    width: 76px;\n  }\n"], ["\n  width: 84px;\n\n  @media only screen and (max-width: ", "px) {\n    margin: 0;\n    width: 76px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var VialSelectorContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: flex-start;\n  @media only screen and (max-width: ", "px) {\n    justify-content: flex-start;\n    gap: 64px;\n  }\n"], ["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: flex-start;\n  @media only screen and (max-width: ", "px) {\n    justify-content: flex-start;\n    gap: 64px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var VialSelectorHeaderText = styled.span(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-weight: 500;\n  color: ", ";\n  font-size: 1.125rem;\n"], ["\n  font-weight: 500;\n  color: ", ";\n  font-size: 1.125rem;\n"])), function (props) { return props.theme.colors.text.alt; });
var VialSelector = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  @media only screen and (max-width: ", "px) {\n    justify-content: center;\n    width: 100%;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  @media only screen and (max-width: ", "px) {\n    justify-content: center;\n    width: 100%;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var VialSelectorLabel = styled.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-weight: 500;\n  color: ", ";\n  font-size: 1.125rem;\n"], ["\n  font-weight: 500;\n  color: ", ";\n  font-size: 1.125rem;\n"])), function (props) { return props.theme.colors.text.alt; });
var Coral = styled.span(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  color: ", ";\n"], ["\n  color: ", ";\n"])), function (props) { return props.theme.colors.text.link; });
export var RewardVialSelection = function (_a) {
    var orderData = _a.orderData, setOrderData = _a.setOrderData;
    var isMobile = useScreenSizing().isMobile;
    var _b = __read(useState((orderData === null || orderData === void 0 ? void 0 : orderData.applyFreeVials) || (orderData === null || orderData === void 0 ? void 0 : orderData.availableFreeVials)), 2), selectedVials = _b[0], setSelectedVials = _b[1];
    var _c = __read(useState((orderData === null || orderData === void 0 ? void 0 : orderData.quantity) || 0), 1), startQuantity = _c[0];
    useEffect(function () {
        setOrderData(__assign(__assign({}, orderData), { applyFreeVials: selectedVials, quantity: startQuantity }));
    }, [selectedVials]);
    return (React.createElement(RewardVialSelectionContainer, null,
        !isMobile && (React.createElement(JeaveauVialImageContainer, null,
            React.createElement(JeaveauVialImage, { src: jeaveauVial }))),
        React.createElement(VialSelectorContainer, null,
            React.createElement(VialSelectorHeaderText, null,
                orderData.accountName,
                " Spa has",
                ' ',
                React.createElement(Coral, null, orderData.availableFreeVials),
                " reward vials available for redemption."),
            React.createElement(VialSelector, null,
                React.createElement(InputStepper, { onChange: function (value) {
                        return value >= 0 &&
                            value <= orderData.availableFreeVials &&
                            setSelectedVials(value);
                    }, value: selectedVials }),
                React.createElement(VialSelectorLabel, null, "Reward Vials")))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Button, Icon, Typography } from 'designSystem';
import { ProgressBar } from 'components/ProgressBar';
import KickerIcon from 'assets/kicker-icon.svg';
import { addMobileStyles } from 'utils/addMobileStyles';
import { useNavigate } from 'react-router';
import { stackedCardStyles } from 'components/StackedMobileCard';
import { useScreenSizing } from '../../../../../../hooks';
export var KickerCardInProgress = function (_a) {
    var meta = _a.meta, index = _a.index;
    var theme = useTheme();
    var navigate = useNavigate();
    var isMobile = useScreenSizing().isMobile;
    var qualifyingVialsPurchased = meta.qualifyingVialsPurchased, qualifyingVialsNeeded = meta.qualifyingVialsNeeded, subTitle = meta.subTitle, terms = meta.terms, title = meta.title, rebateAmount = meta.rebateAmount, learnMoreUrl = meta.learnMoreUrl, endText = meta.endText;
    var handleLearnMore = function () {
        window.open(learnMoreUrl, '_blank');
    };
    var handleOrder = function () {
        navigate('/orders/buy?step=quantitySelect');
    };
    var vialsLeft = qualifyingVialsNeeded - qualifyingVialsPurchased;
    var progressPercentage = Math.max((qualifyingVialsPurchased / qualifyingVialsNeeded) * 100, 1);
    var renderContent = function () {
        if (isMobile) {
            return (React.createElement(React.Fragment, null,
                React.createElement("div", null,
                    React.createElement(Typography, { variant: "title" }, title),
                    React.createElement("img", { alt: "kicker-icon", src: KickerIcon })),
                React.createElement("div", null,
                    React.createElement(Typography, { mb: 4, display: "block", variant: "headlineSmall", color: "alt" },
                        React.createElement(Typography, { variant: "titleLarge", color: "base" }, vialsLeft),
                        ' ',
                        "vials to unlock rebate"),
                    React.createElement(ProgressBar, { color: theme.colors.basic.black, background: theme.colors.bg.alt, percentage: progressPercentage }),
                    React.createElement(Typography, { variant: "caption", mt: 4, display: "block", color: "alt" },
                        React.createElement(Typography, { variant: "title", color: "base" },
                            qualifyingVialsPurchased,
                            "/",
                            qualifyingVialsNeeded),
                        ' ',
                        "Qualifying Vials")),
                React.createElement("div", null,
                    React.createElement(Typography, { color: "alt", mb: 24, display: "block" }, terms),
                    React.createElement(Typography, { display: "block", mb: 16, variant: "caption" }, subTitle),
                    React.createElement(Typography, { display: "block", variant: "footnote", color: "alt5" }, endText))));
        }
        return (React.createElement(React.Fragment, null,
            React.createElement("div", null,
                React.createElement("img", { src: KickerIcon, alt: "kicker-promo-logo" }),
                React.createElement("div", null,
                    React.createElement(Typography, { mb: 4, display: "block", variant: "bodySmall", color: "alt" },
                        React.createElement(Typography, { variant: "titleLarge", color: "base" }, vialsLeft),
                        ' ',
                        "vials to unlock $",
                        rebateAmount,
                        " rebate for every vial purchased"),
                    React.createElement(ProgressBar, { color: theme.colors.basic.black, background: theme.colors.bg.alt, percentage: progressPercentage }),
                    React.createElement(Typography, { mt: 4, display: "block", align: "center" },
                        qualifyingVialsPurchased,
                        "/",
                        qualifyingVialsNeeded,
                        " Qualifying Vials"))),
            React.createElement("div", null,
                React.createElement(Typography, { display: "block", variant: "headline" }, title),
                React.createElement(Typography, { display: "block", variant: "bodySmall", color: "alt", mt: 8, mb: 8 }, terms),
                React.createElement(Typography, { display: "block", variant: "caption", mb: 6 }, subTitle),
                React.createElement(Typography, { display: "block", variant: "footnote", color: "alt5" }, endText))));
    };
    return (React.createElement(KickerContainer, { zIndex: index + 2 },
        renderContent(),
        React.createElement("div", null,
            React.createElement(Button, { variant: "outlined", onClick: handleOrder, startIcon: React.createElement(Icon, { name: "orders", color: "inherit" }) }, "Order Now"),
            React.createElement(Button, { variant: "secondary", onClick: handleLearnMore }, "Learn More"))));
};
var KickerContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  flex-direction: column;\n  align-items: flex-start;\n  & > div:first-of-type {\n    display: flex;\n    margin-bottom: 8px;\n    gap: 32px;\n    & > img {\n      width: 100%;\n      max-width: 72px;\n    }\n    & > div {\n      flex: 1;\n      & > * {\n        width: 100%;\n      }\n    }\n  }\n  & > div:last-of-type {\n    display: flex;\n    gap: 14px;\n    flex: 1;\n    width: 100%;\n    align-items: center;\n    margin-top: 12px;\n    & > button {\n      flex: 1;\n      padding: 16px 0;\n      margin: 0;\n    }\n  }\n  ", ";\n"], ["\n  ", ";\n  flex-direction: column;\n  align-items: flex-start;\n  & > div:first-of-type {\n    display: flex;\n    margin-bottom: 8px;\n    gap: 32px;\n    & > img {\n      width: 100%;\n      max-width: 72px;\n    }\n    & > div {\n      flex: 1;\n      & > * {\n        width: 100%;\n      }\n    }\n  }\n  & > div:last-of-type {\n    display: flex;\n    gap: 14px;\n    flex: 1;\n    width: 100%;\n    align-items: center;\n    margin-top: 12px;\n    & > button {\n      flex: 1;\n      padding: 16px 0;\n      margin: 0;\n    }\n  }\n  ", ";\n"])), function (props) { return props.theme.card.default; }, addMobileStyles("\n    ".concat(stackedCardStyles, ";\n    z-index: ").concat(function (props) { return props.zIndex; }, ";\n    margin: unset;\n    & > div {\n      width: 100%;\n      align-items: center;\n      flex: 1;\n      & > span {\n        flex: 1;\n      }\n    }\n    & > div:nth-child(2) {\n      margin-bottom: 24px;\n    }\n    & > div:last-of-type {\n      display: flex;\n      flex-direction: column;\n      margin-top: 32px;\n      & > button {\n        width: 100%;\n      }  \n    }\n  ")));
var templateObject_1;

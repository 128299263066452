var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { Typography } from 'designSystem';
export var AnnualLoyaltyProgressBar = function (_a) {
    var vialsThreshold = _a.vialsThreshold, vialsPurchased = _a.vialsPurchased, counterText = _a.counterText, counterSubText = _a.counterSubText;
    return (React.createElement(SingleColumnContainer, null,
        React.createElement(Typography, { variant: "caption", color: "alt", style: { width: '100%', fontSize: '14px' } },
            React.createElement(Typography, { variant: "headline", style: { fontSize: '24px' } }, Math.max(0, vialsThreshold - vialsPurchased)),
            ' ',
            counterText),
        React.createElement(ProgressBarContainer, null,
            React.createElement(CurrentProgressBar, { percentage: Math.min((vialsPurchased / vialsThreshold) * 100, 100) })),
        React.createElement(Typography, { variant: "caption", color: "alt", style: { width: '100%', fontSize: '12px' } },
            React.createElement(Typography, { variant: "headline", style: { fontSize: '18px' } },
                Math.min(vialsPurchased, vialsThreshold),
                " / ",
                vialsThreshold),
            ' ',
            counterSubText)));
};
var SingleColumnContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  align-items: center;\n"], ["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  align-items: center;\n"])));
var ProgressBarContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  background-color: #ebf0f0;\n  height: 6px;\n  margin: 6px 0;\n  align-items: center;\n  width: 100%;\n"], ["\n  display: flex;\n  background-color: #ebf0f0;\n  height: 6px;\n  margin: 6px 0;\n  align-items: center;\n  width: 100%;\n"])));
var CurrentProgressBar = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: ", "%;\n  background-color: #e5554f;\n  height: 10px;\n"], ["\n  width: ", "%;\n  background-color: #e5554f;\n  height: 10px;\n"])), function (props) { return props === null || props === void 0 ? void 0 : props.percentage; });
var templateObject_1, templateObject_2, templateObject_3;

import React from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import RoutedApplication from 'components/Root';
import config from 'config';
import { ThemeProvider } from 'styled-components';
import theme from './designSystem/theme/theme';
import { ModalProvider, UserProvider } from 'context';
import ErrorBoundary from 'components/ErrorBoundary';
import { GlobalStyle } from './designSystem/theme/global';
import { Footer } from './components/Layouts/Footer';
import { BrowserRouter, HashRouter } from 'react-router-dom';
var Router = config.isStaticApp ? HashRouter : BrowserRouter;
if (!Intl.Locale) {
    require('@formatjs/intl-getcanonicallocales/polyfill');
    require('@formatjs/intl-locale/polyfill');
}
if (!Intl.PluralRules) {
    require('@formatjs/intl-pluralrules/polyfill');
    require('@formatjs/intl-pluralrules/locale-data/en');
}
if (!Intl.RelativeTimeFormat) {
    require('@formatjs/intl-relativetimeformat/polyfill');
    require('@formatjs/intl-relativetimeformat/locale-data/en');
}
if (!Intl.NumberFormat) {
    require('@formatjs/intl-numberformat/polyfill');
    require('@formatjs/intl-numberformat/locale-data/en');
}
var App = function () {
    return (React.createElement(Router, { basename: "/portal" },
        React.createElement(UserProvider, null,
            React.createElement(ThemeProvider, { theme: theme },
                React.createElement(GlobalStyle, null),
                React.createElement(ModalProvider, null,
                    React.createElement(ErrorBoundary, null,
                        React.createElement(React.Fragment, null,
                            React.createElement(RoutedApplication, null),
                            React.createElement(Footer, null))))))));
};
export default App;

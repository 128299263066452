import React from 'react';
import { Outlet } from 'react-router-dom';
import { TabNav } from '../../components/TabNav';
var tabs = [
    {
        path: '/settings/payments',
        label: 'Payment Methods',
    },
    {
        path: '/settings/user-permissions',
        label: 'User Permissions',
    },
    {
        path: '/settings/rewards',
        label: 'Evolus Rewards',
    },
];
export var Settings = function () {
    return (React.createElement(React.Fragment, null,
        React.createElement(TabNav, { tabs: tabs }),
        React.createElement(Outlet, null)));
};

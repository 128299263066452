import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useUser } from 'context/UserContext';
export var AccountAdminRoute = function () {
    var isAdmin = useUser().isAdmin;
    if (!isAdmin) {
        return React.createElement(Navigate, { to: "/rewards/check-in" });
    }
    return React.createElement(Outlet, null);
};

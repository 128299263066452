var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { Icon, Typography } from 'designSystem';
import { ListItemCard } from '../../../components/ListItemCard';
import { ActivityType } from 'types/codegen/hooks';
import { pluralize } from 'utils/pluralize';
import { format } from 'date-fns';
var renderStatement = function (activity) {
    if ((activity === null || activity === void 0 ? void 0 : activity.activityType) === ActivityType.VialRedeemed ||
        (activity === null || activity === void 0 ? void 0 : activity.activityType) === ActivityType.EvolusAdjustment ||
        (activity === null || activity === void 0 ? void 0 : activity.activityType) === ActivityType.RewardVialExpiration) {
        return (React.createElement(Typography, { color: "link" },
            "-", activity === null || activity === void 0 ? void 0 :
            activity.redeemedForPoints,
            " Credit",
            pluralize(activity === null || activity === void 0 ? void 0 : activity.redeemedForPoints)));
    }
    if ((activity === null || activity === void 0 ? void 0 : activity.activityType) === ActivityType.CreditReceived ||
        (activity === null || activity === void 0 ? void 0 : activity.activityType) === ActivityType.ManualCredit) {
        return (React.createElement(Typography, { color: "success" },
            "+", activity === null || activity === void 0 ? void 0 :
            activity.creditsEarned,
            " Credit",
            pluralize(activity === null || activity === void 0 ? void 0 : activity.creditsEarned)));
    }
    return activity === null || activity === void 0 ? void 0 : activity.rewardsGiven;
};
var renderTransactionDetail = function (_a) {
    var activityType = _a.activityType, redeemedForQuantity = _a.redeemedForQuantity, consumerFirstName = _a.consumerFirstName, consumerLastName = _a.consumerLastName, occurredOn = _a.occurredOn;
    if (activityType === ActivityType.VialRedeemed) {
        return "".concat(redeemedForQuantity, " vial").concat(pluralize(redeemedForQuantity), " redeemed");
    }
    if (activityType === ActivityType.EvolusAdjustment) {
        return format(new Date(occurredOn), 'MMM d');
    }
    if (activityType === ActivityType.RewardVialExpiration) {
        return format(new Date(occurredOn), 'MMM d');
    }
    return "".concat(consumerFirstName, " ").concat(consumerLastName !== null && consumerLastName !== void 0 ? consumerLastName : '');
};
var renderTableIcon = function (activity) {
    if ((activity === null || activity === void 0 ? void 0 : activity.activityType) === ActivityType.CreditReceived ||
        (activity === null || activity === void 0 ? void 0 : activity.activityType) === ActivityType.ManualCredit) {
        return 'user';
    }
    return 'evolus-rewards';
};
var renderTransactionDate = function (_a) {
    var occurredOn = _a.occurredOn;
    if (occurredOn) {
        return format(new Date(occurredOn), 'MMM d');
    }
};
export function TransactionDetailItem(props) {
    var details = props.details, onSelect = props.onSelect, fullHistoryOpen = props.fullHistoryOpen;
    return (React.createElement(ListItemCard, { onClick: onSelect },
        React.createElement(LeftContainer, null,
            React.createElement(Icon, { name: renderTableIcon(details.activityType) }),
            React.createElement("div", null,
                React.createElement(CardText, { variant: "headlineSmall" }, details.facilityName),
                React.createElement(CardText, { variant: "footnote", color: "alt" }, renderTransactionDate(details)))),
        React.createElement(RightContainer, null,
            React.createElement(RightDiv, null,
                renderStatement(details),
                fullHistoryOpen && (React.createElement(CardText, { variant: "footnote", color: "alt", align: "right" }, renderTransactionDetail(details)))))));
}
var CardText = styled(Typography)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: block;\n"], ["\n  display: block;\n"])));
var LeftContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 12px;\n"], ["\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 12px;\n"])));
var RightContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  gap: 12px;\n"], ["\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  gap: 12px;\n"])));
var RightDiv = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  text-align: right;\n"], ["\n  text-align: right;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;

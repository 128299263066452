var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Button, Icon, Typography } from 'designSystem';
import React from 'react';
import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router';
import { levelDesignConfig } from '../../../../../utils/levelDesignConfig';
import { useScreenSizing } from '../../../../../hooks';
import { addMobileStyles } from '../../../../../utils/addMobileStyles';
import { stackedCardStyles } from '../../../../../components/StackedMobileCard';
export var OfferCard = function (_a) {
    var meta = _a.meta, standardDiscount = _a.standardDiscount, vialsFromThreshold = _a.vialsFromThreshold, index = _a.index;
    var upgradeToLevel = meta.upgradeToLevel, badge = meta.badge, promoCode = meta.promoCode, terms = meta.terms, cta = meta.cta, learnMoreUrl = meta.learnMoreUrl, body = meta.body, subTitle = meta.subTitle, title = meta.title;
    var navigate = useNavigate();
    var isMobile = useScreenSizing().isMobile;
    var onApply = function () {
        navigate('/orders/buy?step=quantitySelection', {
            state: {
                autoContinue: true,
                preFilledQuantity: vialsFromThreshold
                    ? vialsFromThreshold
                    : standardDiscount.maxDiscountedQuantity,
                preapplyCode: promoCode,
            },
        });
    };
    var onOrderNow = function () {
        navigate('/orders/buy?step=quantitySelection', {
            state: {
                preapplyCode: promoCode,
            },
        });
    };
    var handleLearnMore = function () {
        window.open(learnMoreUrl, '_blank');
    };
    return (React.createElement(Container, { zIndex: index + 2 },
        React.createElement("div", null,
            React.createElement("div", null,
                React.createElement(Typography, { display: "block", color: "love", variant: isMobile ? 'sectionHeader' : 'caption' }, subTitle),
                React.createElement(Typography, { mt: 2, variant: isMobile ? 'headline' : 'titleLarge', display: "block" }, title)),
            React.createElement("div", null,
                React.createElement(LevelChipContainer, { level: upgradeToLevel },
                    React.createElement("div", null,
                        React.createElement(Typography, { variant: isMobile ? 'display' : 'displayLarge' }, isMobile ? badge.title : badge.shortTitle)),
                    React.createElement("div", null,
                        React.createElement(Typography, { variant: isMobile ? 'sectionHeader' : 'body', display: "block" }, badge.subTitle),
                        React.createElement(Typography, { variant: isMobile ? 'caption' : 'footnote' }, badge.description))))),
        React.createElement("div", null,
            React.createElement(BodyText, { dangerouslySetInnerHTML: { __html: body }, variant: "bodySmall", color: "alt", display: "block" }),
            React.createElement(Typography, { variant: "footnote", mt: isMobile ? 16 : 8, color: "alt5", display: "block" }, terms)),
        React.createElement("div", null,
            cta === 'Order Now' ? (React.createElement(Button, { size: "xlarge", variant: "outlined", startIcon: React.createElement(Icon, { name: "orders", color: "inherit" }), onClick: onOrderNow }, cta)) : (React.createElement(Button, { size: "xlarge", variant: "primary", onClick: onApply }, cta)),
            !!learnMoreUrl && (React.createElement(Button, { size: "xlarge", variant: "secondary", onClick: handleLearnMore }, "Learn More")))));
};
var Container = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  flex-direction: column;\n  & > div:first-of-type {\n    display: flex;\n    gap: 8px;\n    align-items: center;\n    & > * {\n      flex: 1;\n    }\n    margin-bottom: 24px;\n    ", ";\n  }\n  & > div:last-of-type {\n    align-items: center;\n    display: flex;\n    margin-top: 24px;\n    width: 100%;\n    & > a {\n      margin-left: 20px;\n    }\n    & > button {\n      flex: 0 0 50%;\n      max-width: 244px;\n      width: 50%;\n\n      + button {\n        margin-left: 14px;\n      }\n    }\n    ", ";\n  }\n  ", ";\n"], ["\n  ", ";\n  flex-direction: column;\n  & > div:first-of-type {\n    display: flex;\n    gap: 8px;\n    align-items: center;\n    & > * {\n      flex: 1;\n    }\n    margin-bottom: 24px;\n    ", ";\n  }\n  & > div:last-of-type {\n    align-items: center;\n    display: flex;\n    margin-top: 24px;\n    width: 100%;\n    & > a {\n      margin-left: 20px;\n    }\n    & > button {\n      flex: 0 0 50%;\n      max-width: 244px;\n      width: 50%;\n\n      + button {\n        margin-left: 14px;\n      }\n    }\n    ", ";\n  }\n  ", ";\n"])), function (props) { return props.theme.card.default; }, addMobileStyles(css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      flex-direction: column-reverse;\n      margin-bottom: 8px;\n      align-items: unset;\n    "], ["\n      flex-direction: column-reverse;\n      margin-bottom: 8px;\n      align-items: unset;\n    "])))), addMobileStyles(css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      & > button {\n        flex: 0 0 100%;\n        width: 100%;\n      }\n      margin-top: 32px;\n    "], ["\n      & > button {\n        flex: 0 0 100%;\n        width: 100%;\n      }\n      margin-top: 32px;\n    "])))), addMobileStyles("\n    margin: unset;\n    z-index: ".concat(function (props) { return props.zIndex; }, ";\n    ").concat(stackedCardStyles, "\n  ")));
var LevelChipContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  background: ", ";\n  height: 100%;\n  box-shadow: 0px -1.5616px 3.12321px rgba(207, 208, 208, 0.248416),\n    0px -15.616px 31.2321px rgba(144, 155, 165, 0.11);\n\n  padding: 15px;\n  gap: 12px;\n  & > div > * {\n    color: ", ";\n  }\n  & > div:first-of-type > * {\n    font-family: 'DIN';\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  background: ", ";\n  height: 100%;\n  box-shadow: 0px -1.5616px 3.12321px rgba(207, 208, 208, 0.248416),\n    0px -15.616px 31.2321px rgba(144, 155, 165, 0.11);\n\n  padding: 15px;\n  gap: 12px;\n  & > div > * {\n    color: ", ";\n  }\n  & > div:first-of-type > * {\n    font-family: 'DIN';\n  }\n"])), function (props) { return levelDesignConfig[props.level].baseColor; }, function (props) { return levelDesignConfig[props.level].textColor; });
var BodyText = styled(Typography)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  & > strong {\n    color: ", ";\n  }\n"], ["\n  & > strong {\n    color: ", ";\n  }\n"])), function (props) { return props.theme.colors.text.base; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;

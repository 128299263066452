var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import useDebounce from 'hooks/useDebounce';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useScreenSizing } from 'hooks';
export var SearchView = function (_a) {
    var onSearch = _a.onSearch, value = _a.value, setSearchText = _a.setSearchText;
    var _b = __read(useState(''), 2), searchInput = _b[0], setSearchInput = _b[1];
    var debouncedSearchText = useDebounce(searchInput, 1000);
    var isMobile = useScreenSizing().isMobile;
    useEffect(function () {
        if (debouncedSearchText || debouncedSearchText === '') {
            setSearchText(debouncedSearchText);
        }
    }, [debouncedSearchText]);
    return (React.createElement(SearchBarContainer, null,
        React.createElement(SearchBar, { placeholder: isMobile ? 'Order ID' : 'Search by Order ID', value: value, onChange: function (ev) {
                return setSearchInput(ev.currentTarget.value);
            } }),
        React.createElement(SearchBarIcon, { src: require('assets/search.png'), onClick: function () { return onSearch(); } })));
};
var SearchBarContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 366px;\n  height: 48px;\n  position: relative;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  @media only screen and (max-width: ", "px) {\n    width: 100%;\n  }\n"], ["\n  width: 366px;\n  height: 48px;\n  position: relative;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  @media only screen and (max-width: ", "px) {\n    width: 100%;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var SearchBar = styled.input(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  padding-left: 16px;\n  font-size: 1rem;\n  color: ", ";\n  border: 1px solid ", ";\n\n  &:focus {\n    border: 1px solid ", ";\n  }\n\n  &::placeholder {\n    color: ", ";\n  }\n"], ["\n  width: 100%;\n  height: 100%;\n  padding-left: 16px;\n  font-size: 1rem;\n  color: ", ";\n  border: 1px solid ", ";\n\n  &:focus {\n    border: 1px solid ", ";\n  }\n\n  &::placeholder {\n    color: ", ";\n  }\n"])), function (props) { return props.theme.colors.text.base; }, function (props) { return props.theme.colors.border.alt2; }, function (props) { return props.theme.colors.border.alt; }, function (props) { return props.theme.colors.text.alt5; });
var SearchBarIcon = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 24px;\n  height: 24px;\n  position: absolute;\n  right: 12px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n"], ["\n  width: 24px;\n  height: 24px;\n  position: absolute;\n  right: 12px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n"])));
var templateObject_1, templateObject_2, templateObject_3;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from 'react';
import { Modal } from '../Modal';
import { Button, Typography } from 'designSystem';
import { SingleInvoiceItem } from '../../../scenes/Invoices/components/SingleInvoiceItem';
import styled from 'styled-components';
export var SpecifyInvoices = function (_a) {
    var invoices = _a.invoices, onSelect = _a.onSelect, preselected = _a.preselected;
    var _b = __read(useState(preselected), 2), selected = _b[0], setSelected = _b[1];
    return (React.createElement(Modal, null,
        React.createElement(Typography, { mb: 12, variant: "display" }, "Select Invoices"),
        React.createElement(Typography, { variant: "subtitle", color: "alt" }, "Select the invoices you want to apply to today\u2019s payment if applicable."),
        React.createElement(InvoicesContainer, null, invoices.map(function (i) {
            var _a, _b, _c;
            var isSelected = selected.includes(i.invoiceId);
            return (React.createElement(SingleInvoiceItem, { selectable: true, selected: isSelected, onSelect: function () {
                    if (isSelected) {
                        setSelected(selected.filter(function (id) { return id !== i.invoiceId; }));
                        return;
                    }
                    setSelected(__spreadArray(__spreadArray([], __read(selected), false), [i.invoiceId], false));
                }, invoiceId: i.invoiceId, status: i.status, dueDate: i.dueDate, date: i.date, amount: (_a = i.amountDue) !== null && _a !== void 0 ? _a : 0, quantity: (_b = i.jeuveauQuantity) !== null && _b !== void 0 ? _b : 0, facilityName: (_c = i.facility) === null || _c === void 0 ? void 0 : _c.name }));
        })),
        React.createElement(ActionContainer, null,
            React.createElement(Button, { size: "xlarge", variant: "primary", onClick: function () {
                    return onSelect(invoices.filter(function (i) { return selected.includes(i.invoiceId); }));
                }, disabled: selected.length < 1 }, "Save Selection"))));
};
var InvoicesContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n  margin-top: 48px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n  margin-top: 48px;\n"])));
var ActionContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  justify-content: flex-end;\n  width: 100%;\n  margin-top: 64px;\n"], ["\n  display: flex;\n  flex: 1;\n  justify-content: flex-end;\n  width: 100%;\n  margin-top: 64px;\n"])));
var templateObject_1, templateObject_2;

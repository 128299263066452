var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { Button, Typography } from 'designSystem';
import KickerComplete from 'assets/kicker-complete.svg';
import KickerIcon from 'assets/kicker-icon.svg';
import { useScreenSizing } from 'hooks';
import { addMobileStyles } from 'utils/addMobileStyles';
import { stackedCardStyles } from 'components/StackedMobileCard';
export var KickerCardComplete = function (_a) {
    var meta = _a.meta, index = _a.index;
    var isMobile = useScreenSizing().isMobile;
    var _b = meta.completionCard, body = _b.body, ctaText = _b.ctaText, subTitle = _b.subTitle, learnMoreUrl = _b.learnMoreUrl, terms = _b.terms, title = _b.title;
    var handleCta = function () {
        window.open(learnMoreUrl, '_blank');
    };
    var renderContent = function () {
        if (isMobile) {
            return (React.createElement(React.Fragment, null,
                React.createElement("div", null,
                    React.createElement("div", null,
                        React.createElement(Typography, { mb: 2, display: "block", variant: "overline", color: "love" }, title),
                        React.createElement(Typography, { variant: "headline", display: "block" }, subTitle)),
                    React.createElement("img", { src: KickerIcon, alt: "kicker-icon" })),
                React.createElement("div", null,
                    React.createElement(Typography, { mb: 16, display: "block", color: "alt" }, body),
                    React.createElement(Typography, { mb: 32, display: "block", variant: "footnote", color: "alt5" }, terms),
                    React.createElement(Button, { fullWidth: true, variant: "secondary", size: "xlarge", onClick: handleCta }, ctaText))));
        }
        return (React.createElement(React.Fragment, null,
            React.createElement("div", null,
                React.createElement(Typography, { mb: 2, display: "block", variant: "caption", color: "love" }, title),
                React.createElement(Typography, { mb: 8, display: "block", variant: "titleLarge" }, subTitle),
                React.createElement(Typography, { color: "alt", display: "block", variant: "bodySmall" }, body),
                React.createElement(Typography, { mb: 26, display: "block", variant: "footnote", color: "alt5" }, terms),
                React.createElement(Button, { variant: "secondary", onClick: handleCta }, ctaText)),
            React.createElement("div", null,
                React.createElement("img", { src: KickerComplete, alt: "kicker-complete" }))));
    };
    return React.createElement(Container, { zIndex: index + 2 }, renderContent());
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  align-items: center;\n  gap: 32px;\n  & > div:last-of-type {\n    flex: 1;\n    & > img {\n      max-width: 244px;\n      width: 100%;\n    }\n  }\n  & > div:first-of-type {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    & > button {\n      width: 100%;\n      padding: 16px 0;\n    }\n  }\n  ", "\n"], ["\n  ", ";\n  align-items: center;\n  gap: 32px;\n  & > div:last-of-type {\n    flex: 1;\n    & > img {\n      max-width: 244px;\n      width: 100%;\n    }\n  }\n  & > div:first-of-type {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    & > button {\n      width: 100%;\n      padding: 16px 0;\n    }\n  }\n  ", "\n"])), function (props) { return props.theme.card.default; }, addMobileStyles("\n    ".concat(stackedCardStyles, ";\n    z-index: ").concat(function (props) { return props.zIndex; }, ";\n    margin: unset;\n    flex-direction: column;\n    gap: 12px;\n    & > div:first-of-type {\n      display: flex;\n      align-items: center;\n      flex-direction: row;\n      width: 100%;\n      & > div {\n        flex: 1;\n      }\n      & > img {\n        width: 100%;\n        max-width: 72px;\n      }\n    }\n  ")));
var templateObject_1;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import FailedToSendGraphic from 'assets/enroll-patient-graphic-2.svg';
import { Typography } from 'designSystem';
var CompletionLinkSendFailure = function () {
    return (React.createElement(OuterContainer, null,
        React.createElement(LeftContainer, null,
            React.createElement(CheckInImage, { src: FailedToSendGraphic })),
        React.createElement(RightContainer, null,
            React.createElement(Typography, { variant: "titleLarge", color: "link" }, "Try That Again?"),
            React.createElement(RetryText, { variant: "body", color: "alt" }, "It seems something went wrong trying to send your message. Want to try again?"))));
};
export default CompletionLinkSendFailure;
var OuterContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  border: 1px solid ", ";\n\n  padding: 32px;\n  @media screen and (max-width: ", "px) {\n    border: none;\n    padding: 0;\n    margin-bottom: auto;\n    flex-direction: column;\n  }\n"], ["\n  display: flex;\n  border: 1px solid ", ";\n\n  padding: 32px;\n  @media screen and (max-width: ", "px) {\n    border: none;\n    padding: 0;\n    margin-bottom: auto;\n    flex-direction: column;\n  }\n"])), function (props) { return props.theme.colors.border.base; }, function (props) { return props.theme.breakpoints.md; });
var LeftContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  align-items: center;\n  justify-content: center;\n"], ["\n  display: flex;\n  flex: 1;\n  align-items: center;\n  justify-content: center;\n"])));
var RightContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex: 2;\n  justify-content: center;\n  flex-direction: column;\n  margin-left: 32px;\n  @media screen and (max-width: ", "px) {\n    margin-left: 0;\n  }\n"], ["\n  display: flex;\n  flex: 2;\n  justify-content: center;\n  flex-direction: column;\n  margin-left: 32px;\n  @media screen and (max-width: ", "px) {\n    margin-left: 0;\n  }\n"])), function (props) { return props.theme.breakpoints.md; });
var CheckInImage = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  max-width: 100%;\n  max-height: 100%;\n  transform: scaleX(-1);\n"], ["\n  max-width: 100%;\n  max-height: 100%;\n  transform: scaleX(-1);\n"])));
var RetryText = styled(Typography)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-top: 16px;\n"], ["\n  margin-top: 16px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;

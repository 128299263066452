var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useMemo } from 'react';
import { CardBody } from 'designSystem/components/Card/CardBody';
import { Card, CheckboxDropdown, Column, Pagination, Row, Table, TableBody, TableColumn, TableFooter, TableHead, TableHeadColumn, TableRow, Typography, DateRangePicker, PlaceholderLoading, } from 'designSystem';
import styled from 'styled-components';
import { useTable, usePagination } from 'react-table';
import { PDFDownloadButton } from '../PDFDownloadButton';
import { CSVDownloadButton } from '../CSVDownloadButton';
import { TableFilters } from '../TableFilters';
export function DesktopTable(_a) {
    var _b, _c, _d, _e, _f, _g;
    var activityTypes = _a.activityTypes, data = _a.data, dateRange = _a.dateRange, startDate = _a.startDate, endDate = _a.endDate, facilities = _a.facilities, facilitiesOptions = _a.facilitiesOptions, injectorOptions = _a.injectorOptions, injectors = _a.injectors, isFiltered = _a.isFiltered, isLoading = _a.isLoading, setDateRange = _a.setDateRange, setFacilities = _a.setFacilities, setInjectors = _a.setInjectors, setTypes = _a.setTypes, types = _a.types;
    var columns = useMemo(function () { return [
        {
            id: 'name',
            Header: 'Statements',
            accessor: 'name',
            defaultCanSort: false,
            Cell: function (_a) {
                var value = _a.value;
                return (React.createElement(Typography, { variant: "headline" }, value));
            },
        },
        {
            id: 'date',
            Header: 'Date',
            accessor: 'date',
            Cell: function (_a) {
                var value = _a.value;
                if (value instanceof Date) {
                    return new Intl.DateTimeFormat('en-US').format(value);
                }
                return null;
            },
            defaultCanSort: true,
        },
        {
            id: 'download',
            Header: 'Download',
            Cell: function (value) {
                var _a, _b, _c;
                return (React.createElement(Buttons, null,
                    React.createElement(CSVDownloadButton, { date: value.row.original.date, facilityIds: (_a = facilities === null || facilities === void 0 ? void 0 : facilities.map(function (_a) {
                            var value = _a.value;
                            return value;
                        })) !== null && _a !== void 0 ? _a : null, injectorIds: (_b = injectors === null || injectors === void 0 ? void 0 : injectors.map(function (_a) {
                            var value = _a.value;
                            return value;
                        })) !== null && _b !== void 0 ? _b : null, startDate: startDate, endDate: endDate, activityFilters: (_c = types === null || types === void 0 ? void 0 : types.map(function (_a) {
                            var value = _a.value;
                            return value;
                        })) !== null && _c !== void 0 ? _c : null, isFiltered: isFiltered }),
                    !isFiltered && (React.createElement(PDFDownloadButton, { date: new Date(value.row.original.date) }))));
            },
            width: 200,
        },
    ]; }, [startDate, endDate, isFiltered, facilities, injectors, types]);
    var PAGE_SIZE = 8;
    var _h = useTable({
        columns: columns,
        data: data,
        initialState: {
            pageIndex: 0,
            pageSize: PAGE_SIZE,
        },
        autoResetSortBy: false,
        autoResetExpanded: false,
        autoResetPage: true,
    }, usePagination), getTableProps = _h.getTableProps, getTableBodyProps = _h.getTableBodyProps, headerGroups = _h.headerGroups, prepareRow = _h.prepareRow, gotoPage = _h.gotoPage, page = _h.page, _j = _h.state, pageSize = _j.pageSize, pageIndex = _j.pageIndex;
    return (React.createElement(React.Fragment, null,
        React.createElement(Card, { direction: "horizontal", fullWidth: true },
            React.createElement(CardBody, null,
                React.createElement(Typography, { variant: "titleLarge", style: { marginBottom: 16 } }, "Account Statements"),
                React.createElement(Typography, { variant: "body", color: "alt", style: { marginBottom: 24 } }, "Download all account statements."),
                React.createElement(Row, { alignItems: "center" },
                    React.createElement(Column, { sm: 2, md: 1, style: { flexDirection: 'column' } },
                        React.createElement(Typography, { variant: "sectionHeader" }, "Filters"),
                        React.createElement(Typography, { variant: "sectionHeader" }, "(CSV ONLY)")),
                    React.createElement(Column, { sm: 10, md: 11, justifyContent: "flex-end" },
                        React.createElement(Row, { alignItems: "center" },
                            React.createElement(Column, { md: 3 },
                                React.createElement(DropdownWrapper, null,
                                    React.createElement(CheckboxDropdown, { placeholder: (_c = (_b = facilities === null || facilities === void 0 ? void 0 : facilities[0]) === null || _b === void 0 ? void 0 : _b.label) !== null && _c !== void 0 ? _c : 'All Facilities', options: facilitiesOptions, selected: facilities, onChange: function (updated) { return setFacilities(updated); }, closeOnSelect: true }))),
                            React.createElement(Column, { md: 3 },
                                React.createElement(DropdownWrapper, null,
                                    React.createElement(CheckboxDropdown, { placeholder: (_e = (_d = types === null || types === void 0 ? void 0 : types[0]) === null || _d === void 0 ? void 0 : _d.label) !== null && _e !== void 0 ? _e : 'All Transactions', options: activityTypes, selected: types, onChange: function (updated) { return setTypes(updated); }, closeOnSelect: true }))),
                            React.createElement(Column, { md: 3 },
                                React.createElement(DropdownWrapper, null,
                                    React.createElement(CheckboxDropdown, { placeholder: (_g = (_f = injectors === null || injectors === void 0 ? void 0 : injectors[0]) === null || _f === void 0 ? void 0 : _f.label) !== null && _g !== void 0 ? _g : 'All Specialists', options: injectorOptions, selected: injectors, onChange: function (updated) { return setInjectors(updated); }, closeOnSelect: true }))),
                            React.createElement(Column, { md: 3 },
                                React.createElement(DropdownWrapper, null,
                                    React.createElement(DateRangePicker, { onChange: function (value) { return setDateRange(value); }, value: dateRange })))))),
                React.createElement(Row, null,
                    React.createElement(Column, { style: { marginTop: 48 } },
                        React.createElement(TableFilters, { facilities: facilities, injectors: injectors, types: types, setFacilities: setFacilities, setInjectors: setInjectors, setTypes: setTypes }))),
                isLoading ? (React.createElement(LoadingContainer, null,
                    React.createElement(PlaceholderLoading, { size: "extra-small" }))) : (React.createElement(Table, __assign({ fullWidth: true, style: { marginTop: 48 } }, getTableProps()),
                    React.createElement(TableHead, null, headerGroups.map(function (headerGroup) { return (React.createElement(TableRow, __assign({}, headerGroup.getHeaderGroupProps()), headerGroup.headers.map(function (column) { return (React.createElement(TableHeadColumn, __assign({}, column.getHeaderProps(), { width: column.width !== 150 ? column.width : 'auto' }), column.render('Header'))); }))); })),
                    React.createElement(TableBody, __assign({}, getTableBodyProps(), { stripped: true, selectable: true }), page.map(function (row) {
                        prepareRow(row);
                        return (React.createElement(TableRow, __assign({}, row.getRowProps()), row.cells.map(function (cell) { return (React.createElement(TableColumn, __assign({}, cell.getCellProps()), cell.render('Cell'))); })));
                    })),
                    React.createElement(TableFooter, null,
                        React.createElement(TableRow, null,
                            React.createElement(TableColumn, { colSpan: 6 },
                                React.createElement(Pagination, { page: pageIndex, limit: pageSize, total: data.length, onPageChange: function (page) { return gotoPage(page); }, buttonNavigation: true, size: "small", justifyContent: "end" }))))))))));
}
var DropdownWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  &:first-child {\n    width: calc(100% - 24px);\n  }\n"], ["\n  &:first-child {\n    width: calc(100% - 24px);\n  }\n"])));
var Buttons = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n"], ["\n  display: flex;\n  justify-content: flex-end;\n"])));
var LoadingContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n"], ["\n  display: flex;\n  flex: 1;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n"])));
var templateObject_1, templateObject_2, templateObject_3;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useRef, useState } from 'react';
import { useSessionStorage } from 'hooks';
import { useLocation, useNavigate } from 'react-router';
import { CheckInView } from './View';
import ModalTypeEnum from 'types/enums/ModalTypeEnum';
import { useModal } from 'context';
import { StepEnum } from './Enums/StepEnum';
export var CheckInContainer = function () {
    var _a;
    var navigate = useNavigate();
    var location = useLocation();
    var openModal = useModal().openModal;
    var _b = __read(useSessionStorage('@check_in', {}), 2), storedCheckInData = _b[0], setStoredCheckInData = _b[1];
    var _c = __read(useState(__assign({}, storedCheckInData)), 2), checkInData = _c[0], setCheckInData = _c[1];
    var onDismount = useRef();
    var userOutOfBounds = !((_a = Object.keys(storedCheckInData)) === null || _a === void 0 ? void 0 : _a.length) &&
        location.search !== 'step?=mobileNumberInput';
    useEffect(function () {
        if (userOutOfBounds) {
            return navigate('?step=mobileNumberInput', { replace: true });
        }
    }, [userOutOfBounds, navigate]);
    useEffect(function () {
        if (storedCheckInData === null || storedCheckInData === void 0 ? void 0 : storedCheckInData.currentPath) {
            navigate(storedCheckInData === null || storedCheckInData === void 0 ? void 0 : storedCheckInData.currentPath);
        }
    }, [storedCheckInData, navigate]);
    useEffect(function () {
        if (location.search !== checkInData.currentStep) {
            setCheckInData(__assign(__assign({}, checkInData), { currentStep: location.search }));
        }
    }, [location, checkInData]);
    useEffect(function () {
        onDismount.current = function () {
            var data = JSON.parse(window.sessionStorage.getItem('@check_in') || '{}');
            if (window.location.pathname === '/rewards/check-in') {
                return null;
            }
            if ((data === null || data === void 0 ? void 0 : data.checkInComplete) || !(data === null || data === void 0 ? void 0 : data.phoneNumber)) {
                setStoredCheckInData({});
            }
            else {
                openModal(ModalTypeEnum.LEAVING_CHECK_IN, {
                    path: data === null || data === void 0 ? void 0 : data.currentStep,
                    setStoredCheckInData: setStoredCheckInData,
                    trackCancelled: function () {
                        if (!checkInData.rewardStatus) {
                            return;
                        }
                    },
                });
            }
        };
    }, [location, openModal, setStoredCheckInData, checkInData]);
    useEffect(function () {
        setStoredCheckInData(checkInData);
    }, [checkInData, setStoredCheckInData]);
    useEffect(function () {
        return onDismount.current;
    }, [onDismount]);
    var noPromptRestart = function () {
        setCheckInData({});
        setStoredCheckInData({});
        navigate(StepEnum.PhoneInput);
    };
    var handleRestart = function () {
        var fromEnrollment = false;
        switch (location.search) {
            case StepEnum.EnrollmentProgress:
            case StepEnum.EnrollPatientForm:
            case StepEnum.EnrollPatient:
            case StepEnum.PatientConsent:
            case StepEnum.SendingMessage:
            case StepEnum.EmailInput:
                fromEnrollment = true;
        }
        openModal(ModalTypeEnum.RESTART_CHECK_IN, {
            onRestart: noPromptRestart,
            fromEnrollment: fromEnrollment,
        });
    };
    return (React.createElement(CheckInView, { handleRestart: handleRestart, checkInData: checkInData, setCheckInData: setCheckInData, noPromptRestart: noPromptRestart }));
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { Button, Icon, PlaceholderLoading, Typography } from 'designSystem';
import { ConsumerRewardOptInStatus } from 'types/codegen/hooks';
import { format } from 'date-fns';
import { useModal } from 'context';
import ModalTypeEnum from 'types/enums/ModalTypeEnum';
import { ActiveChip, DeactivatedChip, InactiveChip, NotActivatedChip, } from './components/StatusChips';
import { useNavigate } from 'react-router';
import ErrorCircle from 'assets/error-circle.png';
import { useScreenSizing } from '../../../../hooks';
var RewardsContext;
(function (RewardsContext) {
    RewardsContext["LOCKED"] = "LOCKED";
    RewardsContext["INACTIVE"] = "INACTIVE";
    RewardsContext["OPT_BACK_IN"] = "OPT_BACK_IN";
    RewardsContext["OPT_IN"] = "OPT_IN";
    RewardsContext["OPT_OUT"] = "OPT_OUT";
})(RewardsContext || (RewardsContext = {}));
var ContentByContext = {
    LOCKED: {
        statusChip: (React.createElement(DeactivatedChip, null,
            React.createElement(Typography, { variant: "caption", color: "inherit" }, "Deactivated"))),
        dateText: 'Deactivated On:',
    },
    INACTIVE: {
        statusChip: (React.createElement(InactiveChip, null,
            React.createElement(Typography, { variant: "caption", color: "inherit" }, "Inactive"))),
        dateText: 'Opted Out On:',
        getActionText: function (vialsNeeded) { return "Buy ".concat(vialsNeeded, " Vials"); },
    },
    OPT_BACK_IN: {
        statusChip: (React.createElement(InactiveChip, null,
            React.createElement(Typography, { variant: "caption", color: "inherit" }, "Inactive"))),
        dateText: 'Opted Out On:',
        getActionText: function () { return 'Opt In'; },
    },
    OPT_IN: {
        statusChip: (React.createElement(NotActivatedChip, null,
            React.createElement(Typography, { variant: "caption", color: "inherit" }, "Not Activated"))),
        getActionText: function () { return 'Opt In'; },
    },
    OPT_OUT: {
        statusChip: (React.createElement(ActiveChip, null,
            React.createElement(Typography, { variant: "caption", color: "inherit" }, "Active"))),
        dateText: 'Opted In On:',
        getActionText: function () { return 'Opt Out'; },
    },
};
export var EvolusRewardsView = function (_a) {
    var lockStatus = _a.lockStatus, canOptIn = _a.canOptIn, isInactive = _a.isInactive, freeVialsAvailable = _a.freeVialsAvailable, optInStatus = _a.optInStatus, loading = _a.loading, onUpdateStatus = _a.onUpdateStatus, updateLoading = _a.updateLoading, vialsPurchased = _a.vialsPurchased;
    var _b = useModal(), openModal = _b.openModal, closeModal = _b.closeModal;
    var navigate = useNavigate();
    var isMobile = useScreenSizing().isMobile;
    var vialsNeeded = 10 - vialsPurchased;
    var context = useMemo(function () {
        if (lockStatus === null || lockStatus === void 0 ? void 0 : lockStatus.isLocked) {
            return RewardsContext.LOCKED;
        }
        else if (isInactive) {
            return RewardsContext.INACTIVE;
        }
        else if (!optInStatus) {
            return RewardsContext.OPT_IN;
        }
        else if ((optInStatus === null || optInStatus === void 0 ? void 0 : optInStatus.status) === ConsumerRewardOptInStatus.OptedOut) {
            return RewardsContext.OPT_BACK_IN;
        }
        return RewardsContext.OPT_OUT;
    }, [lockStatus, optInStatus, isInactive]);
    var getFormattedStatusDate = useCallback(function (context) {
        var date;
        if (context === RewardsContext.LOCKED) {
            date = lockStatus === null || lockStatus === void 0 ? void 0 : lockStatus.updatedAt;
        }
        else if (context !== RewardsContext.OPT_IN) {
            date = optInStatus === null || optInStatus === void 0 ? void 0 : optInStatus.updatedAt;
        }
        return date ? format(new Date(date), 'MM/dd/Y') : null;
    }, [lockStatus, optInStatus]);
    var handleCTA = function () {
        if (context === RewardsContext.INACTIVE) {
            navigate('/orders/buy?step=quantitySelection', {
                state: {
                    autoContinue: true,
                    preFilledQuantity: vialsNeeded,
                },
            });
            return;
        }
        if (context === RewardsContext.OPT_IN && !canOptIn) {
            openModal(ModalTypeEnum.OPT_IN_INSUFFICIENT_VIALS, {
                vialsNeeded: vialsNeeded,
                onSubmit: function () {
                    navigate('/orders/buy?step=quantitySelection', {
                        state: {
                            autoContinue: true,
                            preFilledQuantity: vialsNeeded,
                        },
                    });
                    closeModal();
                },
            });
            return;
        }
        openModal(ModalTypeEnum.OPT_IN_OUT_EVOLUS_REWARDS, {
            context: context,
            onSubmit: function () { return __awaiter(void 0, void 0, void 0, function () {
                var newStatus;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            closeModal();
                            newStatus = context === RewardsContext.OPT_OUT
                                ? ConsumerRewardOptInStatus.OptedOut
                                : ConsumerRewardOptInStatus.OptedIn;
                            return [4, onUpdateStatus(newStatus)];
                        case 1:
                            _a.sent();
                            return [2];
                    }
                });
            }); },
        });
    };
    var renderContent = function () {
        if (loading) {
            return (React.createElement(LoadingContainer, null,
                React.createElement(PlaceholderLoading, { size: "small" })));
        }
        var _a = ContentByContext[context], statusChip = _a.statusChip, dateText = _a.dateText, getActionText = _a.getActionText;
        var renderStatusChangeDate = !!optInStatus || (lockStatus === null || lockStatus === void 0 ? void 0 : lockStatus.isLocked) || isInactive;
        return (React.createElement(InnerContainer, null,
            React.createElement(SplitContainer, null,
                React.createElement(LeftContainer, null,
                    React.createElement("div", null,
                        React.createElement(Typography, { mb: 16, display: "block", variant: "title" }, "Status"),
                        statusChip,
                        context === RewardsContext.LOCKED && (React.createElement(WarningChip, null,
                            React.createElement("img", { src: ErrorCircle, alt: "deactivated-warning-icon" }),
                            "Note: Due to suspicious activity, Evolus Rewards has been locked.")),
                        context === RewardsContext.INACTIVE && (React.createElement(React.Fragment, null,
                            React.createElement(Typography, { variant: "bodySmall", display: "block", mt: 16, mb: 8, color: "alert" },
                                "You need to purchase ",
                                vialsNeeded,
                                " more vials to continue participating in Evolus Rewards."),
                            React.createElement(Typography, { color: "alt", variant: "footnote", display: "block" },
                                React.createElement("sup", null, "*"),
                                "Account inactive given no purchases within the last 12 months."))),
                        renderStatusChangeDate && (React.createElement(SplitTypography, null,
                            React.createElement(Typography, { color: "alt" }, dateText),
                            React.createElement(Typography, null, getFormattedStatusDate(context))))),
                    React.createElement("div", null,
                        React.createElement(Typography, { mb: 28, display: "block", variant: "title" }, "Earned Reward Vials"),
                        React.createElement(SplitTypography, null,
                            React.createElement(Typography, { color: "alt" }, "Reward Vials Available:"),
                            React.createElement(Typography, null, freeVialsAvailable))),
                    React.createElement("div", null,
                        React.createElement(Typography, { mb: 24, display: "block", variant: "title" }, "Info"),
                        React.createElement(ExternalLink, { href: '#', target: "_blank" },
                            React.createElement(Typography, { mb: 24, color: "link", display: "block" }, "Frequently Asked Questions")),
                        React.createElement(ExternalLink, { href: '#', target: "_blank" },
                            React.createElement(Typography, { color: "link", display: "block" }, "Program Terms")))),
                React.createElement(RightContainer, null, !lockStatus.isLocked && (React.createElement(Button, { size: "xlarge", disabled: updateLoading, endIcon: updateLoading && React.createElement(Icon, { color: "inherit", name: "loading" }), variant: "outlined", onClick: handleCTA }, getActionText(vialsNeeded)))))));
    };
    return (React.createElement(OuterContainer, null,
        React.createElement(InnerContainer, null,
            React.createElement(Typography, { mb: 16, variant: "titleLarge", display: "block" }, "Evolus Rewards"),
            React.createElement(Typography, { mb: isMobile ? 24 : 32, display: "block" }, "Manage your participation in Evolus Rewards."),
            renderContent())));
};
var OuterContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  box-sizing: border-box;\n  padding: 48px 48px 60px;\n  margin-top: 20px;\n  flex: 1;\n  width: 100%;\n  @media only screen and (max-width: ", "px) {\n    padding: 32px;\n    margin: 16px 0 0 0;\n  }\n"], ["\n  ", ";\n  box-sizing: border-box;\n  padding: 48px 48px 60px;\n  margin-top: 20px;\n  flex: 1;\n  width: 100%;\n  @media only screen and (max-width: ", "px) {\n    padding: 32px;\n    margin: 16px 0 0 0;\n  }\n"])), function (props) { return props.theme.card.default; }, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var InnerContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 48px;\n  ", ";\n\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  width: 100%;\n  @media only screen and (max-width: ", "px) {\n    border: none;\n    padding: 0;\n  }\n"], ["\n  padding: 48px;\n  ", ";\n\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  width: 100%;\n  @media only screen and (max-width: ", "px) {\n    border: none;\n    padding: 0;\n  }\n"])), function (props) { return props.theme.borders.base; }, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var SplitContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  justify-content: space-between;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column-reverse;\n  }\n"], ["\n  display: flex;\n  flex: 1;\n  justify-content: space-between;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column-reverse;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var LeftContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 32px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 32px;\n"])));
var RightContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-start;\n  flex-direction: column;\n  align-items: flex-end;\n  @media only screen and (max-width: ", "px) {\n    margin-bottom: 24px;\n    & > button {\n      width: 100%;\n    }\n  }\n"], ["\n  display: flex;\n  justify-content: flex-start;\n  flex-direction: column;\n  align-items: flex-end;\n  @media only screen and (max-width: ", "px) {\n    margin-bottom: 24px;\n    & > button {\n      width: 100%;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var ExternalLink = styled.a(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  text-decoration: none;\n"], ["\n  text-decoration: none;\n"])));
var SplitTypography = styled(Typography)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  margin-top: 28px;\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  margin-top: 28px;\n"])));
var LoadingContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex: 1;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex: 1;\n"])));
var WarningChip = styled(NotActivatedChip)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 8px;\n\n  margin-top: 16px;\n  padding: 12px;\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 8px;\n\n  margin-top: 16px;\n  padding: 12px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;

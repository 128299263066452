import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { TabNav } from 'components/TabNav';
import { useAllowAdminOnly } from '../../hooks';
export var OrdersView = function () {
    useAllowAdminOnly();
    var tabs = useMemo(function () { return [
        {
            label: 'Order Dashboard',
            path: '/orders/dashboard',
        },
        {
            label: 'Buy Now',
            path: '/orders/buy?step=quantitySelection',
        },
        {
            label: 'Order History',
            path: '/orders/history',
        },
    ]; }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(TabNav, { tabs: tabs }),
        React.createElement(Outlet, null)));
};
export default OrdersView;

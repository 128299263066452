var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useMutation } from '@apollo/client';
import { useModal } from 'context';
import { Button, Icon, Typography } from 'designSystem';
import React, { useState } from 'react';
import styled from 'styled-components';
import ModalTypeEnum from 'types/enums/ModalTypeEnum';
import mutation from './mutation.graphql';
import Success from 'assets/success-circle.png';
import { useScreenSizing } from 'hooks/useScreenSizing';
export var AssignFacilities = function (_a) {
    var selectedFacilities = _a.selectedFacilities, setSelectedFacilities = _a.setSelectedFacilities, facilities = _a.facilities, IsSingleUserEdit = _a.IsSingleUserEdit, userId = _a.userId, accountId = _a.accountId, onUpdate = _a.onUpdate;
    var _b = useModal(), openModal = _b.openModal, closeModal = _b.closeModal;
    var _c = __read(useMutation(mutation), 2), updateFacilties = _c[0], loading = _c[1].loading;
    var _d = __read(useState(false), 2), editSuccess = _d[0], setEditSuccess = _d[1];
    var isMobile = useScreenSizing().isMobile;
    var openFacilitySelectionModal = function () {
        openModal(ModalTypeEnum.FACILITY_SELECTION, {
            onConfirm: function (facilities) { return __awaiter(void 0, void 0, void 0, function () {
                var data;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!IsSingleUserEdit) return [3, 2];
                            return [4, updateFacilties({
                                    variables: {
                                        input: {
                                            userId: userId,
                                            userAssignment: {
                                                accountId: accountId,
                                                facilities: facilities,
                                            },
                                        },
                                    },
                                })];
                        case 1:
                            data = _a.sent();
                            setEditSuccess(!!data);
                            onUpdate && onUpdate();
                            return [3, 3];
                        case 2:
                            setSelectedFacilities === null || setSelectedFacilities === void 0 ? void 0 : setSelectedFacilities(facilities);
                            _a.label = 3;
                        case 3:
                            closeModal();
                            return [2];
                    }
                });
            }); },
            facilities: facilities,
            facilitiesSelected: selectedFacilities,
        });
    };
    return (React.createElement(InnerContainer, null,
        React.createElement(FacilitiesHeaderContainer, null,
            React.createElement(StyledTitle, { mb: 16, display: "block", variant: "titleLarge" }, "Assigned Facilities (optional)"),
            React.createElement(Button, { variant: "outlined", onClick: openFacilitySelectionModal, size: isMobile ? 'small' : 'medium', endIcon: loading && React.createElement(Icon, { color: "inherit", name: "loading" }) }, (selectedFacilities === null || selectedFacilities === void 0 ? void 0 : selectedFacilities.length) > 0
                ? 'Edit Selection'
                : 'Assign Facility')),
        React.createElement(Typography, { display: "block", mb: 32, color: "alt" }, "Optionally assign the user to facilities. Unassigned user will have access to all facilities"),
        editSuccess && (React.createElement(Banner, null,
            React.createElement(TickMark, null,
                React.createElement(Image, { src: Success })),
            React.createElement(Text, null, "Facilities have been assigned successfully."))),
        (selectedFacilities === null || selectedFacilities === void 0 ? void 0 : selectedFacilities.length) > 0 && (React.createElement(React.Fragment, null,
            React.createElement(Typography, { mb: 16, display: "block", variant: "headline" }, "Assigned Facilities"),
            React.createElement(ChipContainer, null, selectedFacilities === null || selectedFacilities === void 0 ? void 0 : selectedFacilities.map(function (f) {
                var _a;
                return (React.createElement(Chip, { key: f },
                    React.createElement(Typography, { align: "center", variant: "headlineSmall", color: "base" }, (_a = facilities.find(function (facility) { return facility.id === f; })) === null || _a === void 0 ? void 0 : _a.name)));
            }))))));
};
var InnerContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 48px;\n  ", ";\n  margin-bottom: 48px;\n  width: 100%;\n  @media only screen and (max-width: ", "px) {\n    border: none;\n    padding: 0;\n  }\n"], ["\n  padding: 48px;\n  ", ";\n  margin-bottom: 48px;\n  width: 100%;\n  @media only screen and (max-width: ", "px) {\n    border: none;\n    padding: 0;\n  }\n"])), function (props) { return props.theme.borders.base; }, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var ChipContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  gap: 16px;\n  flex-wrap: wrap;\n"], ["\n  display: flex;\n  gap: 16px;\n  flex-wrap: wrap;\n"])));
var FacilitiesHeaderContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin: 0px 0 0px;\n  @media only screen and (max-width: ", "px) {\n    margin: 24px 0;\n    align-items: flex-start;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin: 0px 0 0px;\n  @media only screen and (max-width: ", "px) {\n    margin: 24px 0;\n    align-items: flex-start;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var Chip = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border: none;\n  height: 48px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 16px;\n  background: ", ";\n  & > span {\n    line-height: 16px;\n  }\n  font-weight: 900;\n"], ["\n  border: none;\n  height: 48px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 16px;\n  background: ", ";\n  & > span {\n    line-height: 16px;\n  }\n  font-weight: 900;\n"])), function (props) { return props.theme.colors.bg.base; });
var Banner = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background-color: #ecf8f6;\n  height: 64px;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  padding: 0 20px;\n  box-sizing: border-box;\n  margin-bottom: 32px;\n"], ["\n  background-color: #ecf8f6;\n  height: 64px;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  padding: 0 20px;\n  box-sizing: border-box;\n  margin-bottom: 32px;\n"])));
var TickMark = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: 24px;\n  margin-right: 15px;\n"], ["\n  font-size: 24px;\n  margin-right: 15px;\n"])));
var Text = styled.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-size: 16px;\n  font-weight: 400;\n  color: #3b6955;\n"], ["\n  font-size: 16px;\n  font-weight: 400;\n  color: #3b6955;\n"])));
var Image = styled.img(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  height: 24px;\n  max-width: 24px;\n  width: 100%;\n"], ["\n  height: 24px;\n  max-width: 24px;\n  width: 100%;\n"])));
var StyledTitle = styled(Typography)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  font-size: 18px;\n  line-height: 24px;\n  @media only screen and (max-width: ", "px) {\n    width: 170px;\n  }\n"], ["\n  font-size: 18px;\n  line-height: 24px;\n  @media only screen and (max-width: ", "px) {\n    width: 170px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;

var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { addMethod, boolean, object, string, } from 'yup';
addMethod(string, 'overEighteen', function (errorMessage) {
    return this.test("test-age", errorMessage, function (value) {
        var _a = this, path = _a.path, createError = _a.createError;
        if (!value) {
            return true;
        }
        var _b = __read(value.split('/'), 3), monthString = _b[0], dayString = _b[1], yearString = _b[2];
        var dayInput = parseInt(dayString);
        var monthInput = parseInt(monthString);
        var yearInput = parseInt(yearString);
        var birthdayDate = new Date();
        birthdayDate.setDate(dayInput);
        birthdayDate.setMonth(monthInput - 1);
        birthdayDate.setFullYear(yearInput);
        var invalidMonth = monthInput < 1 || monthInput > 12;
        var invalidYear = yearInput < 1900;
        if (invalidMonth || invalidYear) {
            return createError({
                path: path,
                message: "Oops! We couldn\u2019t recognize the format of the date you entered. Please use the format mm/dd/yyyy.",
            });
        }
        var maxDayInMonth = new Date(yearInput, monthInput, 0).getDate();
        if (dayInput < 1 || dayInput > maxDayInMonth) {
            return createError({
                path: path,
                message: "Oops! We couldn\u2019t recognize the format of the date you entered. Please use the format mm/dd/yyyy.",
            });
        }
        var minAgeDate = new Date();
        minAgeDate.setFullYear(new Date().getFullYear() - 18);
        if (birthdayDate.valueOf() >= minAgeDate.valueOf()) {
            return createError({
                path: path,
                message: 'Patient must be 18 years or older to enroll in Evolus Rewards.',
            });
        }
        return true;
    });
});
export var validationSchema = object({
    firstName: string().required('First Name is required.'),
    lastName: string().required('Last Name is required.'),
    zipCode: string()
        .required('Zip code is required.')
        .matches(/^[0-9]{5}?$/, 'Invalid zip code.'),
    email: string().email('Invalid email.').required('Email is required.'),
    birthday: string()
        .matches(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/, "Oops! We couldn\u2019t recognize the format of the date you entered. Please use the format mm/dd/yyyy.")
        .overEighteen('error')
        .required(),
    gender: string().required(),
    hasReceivedToxinBefore: boolean().required(),
    toxinUsed: string()
        .nullable()
        .when('hasReceivedToxinBefore', {
        is: true,
        then: string().required('Toxin used is required'),
    }),
});

export var levelDesignConfig = {
    LEVEL_0: {
        baseColor: '#E5554F',
        textColor: 'white',
        name: 'Non-Member',
    },
    LEVEL_1: {
        baseColor: '#C0805E',
        textColor: 'white',
        name: 'Member',
    },
    LEVEL_2: {
        baseColor: '#AFAFAC',
        textColor: 'white',
        name: 'Silver',
    },
    LEVEL_3: {
        baseColor: '#AE986E',
        textColor: 'white',
        name: 'Gold',
    },
    LEVEL_4: {
        baseColor: '#747571',
        textColor: 'white',
        name: 'Titanium',
    },
    LEVEL_5: {
        baseColor: '#F2EAE2',
        textColor: 'black',
        name: 'Platinum',
    },
    LEVEL_6: {
        baseColor: '#C0DBE3',
        textColor: 'black',
        name: 'Diamond',
    },
    LEVEL_7: {
        baseColor: '#131312',
        textColor: 'white',
        name: 'Elite',
    },
    LEVEL_8: {
        baseColor: '#CABBA7',
        textColor: 'black',
        name: 'Elite+',
    },
    CUSTOM: {
        baseColor: '#E5554F',
        textColor: 'white',
        name: 'Custom',
    },
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import { Modal } from '../Modal';
import { Button, LoadingBubbles, Typography } from 'designSystem';
import { passwordVerification, } from 'utils/passwordVerification';
import { PasswordRequirementsValidation } from '../../PasswordRequirementsValidation';
import { NewPasswordInputs } from '../../NewPasswordInputs';
import MyEvoLogo from 'assets/evolus-logo-coral.svg';
import { useScreenSizing } from '../../../hooks';
import styled, { useTheme } from 'styled-components';
export var RequireNewPasswordView = function (_a) {
    var isLoading = _a.isLoading, onUpdatePassword = _a.onUpdatePassword, error = _a.error;
    var theme = useTheme();
    var _b = __read(useState(''), 2), password = _b[0], setPassword = _b[1];
    var isMobile = useScreenSizing().isMobile;
    var _c = __read(useState(''), 2), passwordConfirmation = _c[0], setPasswordConfirmation = _c[1];
    var _d = __read(useState({
        minimum: false,
        uppercase: false,
        lowercase: false,
        number: false,
        allTestsPassing: false,
    }), 2), passwordRequirements = _d[0], setPasswordRequirements = _d[1];
    useEffect(function () {
        var verification = passwordVerification(password);
        if (password !== null) {
            setPasswordRequirements(__assign(__assign({}, verification.passwordRequirements), { allTestsPassing: verification.allTestsPassing }));
        }
    }, [password]);
    var handleSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!passwordRequirements.allTestsPassing) {
                        return [2];
                    }
                    return [4, onUpdatePassword(password)];
                case 1:
                    _a.sent();
                    return [2];
            }
        });
    }); };
    return (React.createElement(Modal, { backdropColor: theme.colors.bg.accent },
        !isMobile && React.createElement("img", { src: MyEvoLogo, alt: "my-evolus-logo" }),
        React.createElement(Typography, { mt: isMobile ? 0 : 56, variant: "display" }, "Set your new password"),
        React.createElement(PasswordRequirementsValidation, __assign({}, passwordRequirements)),
        React.createElement(NewPasswordInputs, { error: error, password: password, passwordConfirmation: passwordConfirmation, onPasswordChange: setPassword, onConfirmationChange: setPasswordConfirmation }),
        React.createElement(ActionButton, { variant: "primary", onClick: handleSubmit, disabled: !passwordRequirements.allTestsPassing || isLoading, size: "xlarge" }, isLoading ? React.createElement(LoadingBubbles, null) : 'Set New Password')));
};
var ActionButton = styled(Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 56px;\n  @media only screen and (max-width: ", "px) {\n    margin-top: auto;\n    width: 100%;\n  }\n"], ["\n  margin-top: 56px;\n  @media only screen and (max-width: ", "px) {\n    margin-top: auto;\n    width: 100%;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var templateObject_1;

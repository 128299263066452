var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { Modal } from '../Modal';
import { Button, Typography } from 'designSystem';
import { useModal } from 'context';
import ConfirmAdminGraphic from 'assets/confirm-new-admin.svg';
import { useScreenSizing } from '../../../hooks';
export var AdminAccessConfirmation = function (_a) {
    var onConfirm = _a.onConfirm;
    var closeModal = useModal().closeModal;
    var isMobile = useScreenSizing().isMobile;
    return (React.createElement(Modal, { size: "large" },
        React.createElement(ContentContainer, null,
            React.createElement("div", null,
                React.createElement(Typography, { display: "block", variant: isMobile ? 'display' : 'titleLarge', color: isMobile ? 'link' : 'base' }, "Are you sure you want to provide Admin access to this user?"),
                React.createElement(Typography, { display: "block", color: "alt", variant: "body" }, "This person will have admin level access with the same right as you"),
                React.createElement(ActionsContainer, null,
                    React.createElement(Button, { variant: "primary", size: "xlarge", onClick: function () { return onConfirm(); } }, "Yes, Send Invite"),
                    React.createElement(Button, { variant: isMobile ? 'tertiary' : 'outlined', size: "xlarge", onClick: function () { return closeModal(); } }, "Cancel"))),
            React.createElement(Image, { src: ConfirmAdminGraphic, alt: "confirm-admin-graphic" }))));
};
var ContentContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  align-items: center;\n  & > div:first-of-type {\n    display: flex;\n    flex-direction: column;\n    flex: 1;\n    gap: 16px;\n    & > div:last-of-type {\n      margin-top: 66px;\n    }\n  }\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column-reverse;\n    align-items: stretch;\n    & > div:first-of-type {\n      justify-content: flex-end;\n      & > span {\n        text-align: center;\n      }\n    }\n  }\n"], ["\n  display: flex;\n  flex: 1;\n  align-items: center;\n  & > div:first-of-type {\n    display: flex;\n    flex-direction: column;\n    flex: 1;\n    gap: 16px;\n    & > div:last-of-type {\n      margin-top: 66px;\n    }\n  }\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column-reverse;\n    align-items: stretch;\n    & > div:first-of-type {\n      justify-content: flex-end;\n      & > span {\n        text-align: center;\n      }\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var ActionsContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row-reverse;\n  justify-content: flex-end;\n  align-items: center;\n  gap: 12px;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n    & > button {\n      width: 100%;\n    }\n  }\n"], ["\n  display: flex;\n  flex-direction: row-reverse;\n  justify-content: flex-end;\n  align-items: center;\n  gap: 12px;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n    & > button {\n      width: 100%;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var Image = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  max-width: 300px;\n"], ["\n  width: 100%;\n  max-width: 300px;\n"])));
var templateObject_1, templateObject_2, templateObject_3;

export var typography = {
    type: {
        primary: '"Euclid", "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif',
        code: '"SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace',
    },
    weight: {
        light: 300,
        regular: 400,
        medium: 500,
        semibold: 600,
        bold: 700,
    },
    size: {
        s1: 10,
        s2: 12,
        s3: 14,
        m1: 16,
        m2: 18,
        m3: 24,
        l1: 36,
        l2: 42,
        l3: 64,
        code: 90,
    },
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { ConsumerRewardEligibilityStatus } from 'types/codegen/hooks';
import { Icon, Typography } from 'designSystem';
import SuccessIcon from 'assets/success-circle.png';
import ErrorIcon from 'assets/error-circle.png';
import styled, { css, useTheme } from 'styled-components';
import { customBreakpointStyles } from 'utils/customBreakpointStyles';
var customBreakPoint = 1180;
export var StatusHeader = function (_a) {
    var rewardStatus = _a.rewardStatus, earnedAt = _a.earnedAt, eligibleAt = _a.eligibleAt, expiration = _a.expiration, isNewProfile = _a.isNewProfile;
    var theme = useTheme();
    var isEligible = rewardStatus === ConsumerRewardEligibilityStatus.Eligible;
    var highlightEarned = rewardStatus === ConsumerRewardEligibilityStatus.NotYetActive;
    var highlightEligible = isEligible;
    var highlightExpiration = rewardStatus === ConsumerRewardEligibilityStatus.Expired ||
        rewardStatus === ConsumerRewardEligibilityStatus.ExpiredInGracePeriod;
    return (React.createElement(Container, null,
        React.createElement("div", null,
            React.createElement(Icon, { style: {
                    filter: !highlightEarned ? 'opacity(50%)' : 'unset',
                }, name: "present", color: theme.colors.text.link }),
            React.createElement("div", null,
                React.createElement(Typography, { display: "block", color: highlightEarned ? 'base' : 'alt4' }, earnedAt),
                React.createElement(Typography, { display: "block", color: highlightEarned ? 'alt' : 'alt4' }, "Reward Earned"))),
        eligibleAt && (React.createElement("div", null,
            React.createElement("img", { style: {
                    filter: !highlightEligible ? 'opacity(50%)' : 'unset',
                }, src: SuccessIcon, alt: "valid-icon" }),
            React.createElement("div", null,
                React.createElement(Typography, { display: "block", color: highlightEligible ? 'base' : 'alt4' }, eligibleAt),
                React.createElement(Typography, { display: "block", color: highlightEligible ? 'alt' : 'alt4' }, "Reward Valid")))),
        expiration && (React.createElement("div", null,
            React.createElement("img", { style: {
                    filter: !highlightExpiration ? 'opacity(50%)' : 'unset',
                }, src: ErrorIcon, alt: "expired-icon" }),
            React.createElement("div", null,
                React.createElement(Typography, { display: "block", color: highlightExpiration ? 'base' : 'alt4' }, expiration),
                React.createElement(Typography, { display: "block", color: highlightExpiration ? 'alt' : 'alt4' }, "Reward Expires"))))));
};
var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background: ", ";\n  ", ";\n  padding: 12px;\n  display: flex;\n  justify-content: space-evenly;\n  align-items: center;\n  & > div {\n    display: flex;\n    align-items: center;\n    flex-direction: row;\n    gap: 16px;\n  }\n\n  ", ";\n  @media screen and (max-width: ", "px) {\n    background: transparent;\n    border: none;\n    & > div {\n      flex-direction: column;\n      justify-content: space-evenly;\n      & > div > span {\n        text-align: center;\n      }\n    }\n  }\n"], ["\n  background: ", ";\n  ", ";\n  padding: 12px;\n  display: flex;\n  justify-content: space-evenly;\n  align-items: center;\n  & > div {\n    display: flex;\n    align-items: center;\n    flex-direction: row;\n    gap: 16px;\n  }\n\n  ", ";\n  @media screen and (max-width: ", "px) {\n    background: transparent;\n    border: none;\n    & > div {\n      flex-direction: column;\n      justify-content: space-evenly;\n      & > div > span {\n        text-align: center;\n      }\n    }\n  }\n"])), function (props) { return props.theme.colors.bg.base; }, function (props) { return props.theme.borders.base; }, customBreakpointStyles(customBreakPoint, css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      & > div {\n        flex-direction: column;\n        gap: 12px;\n        & > div > span {\n          text-align: center;\n        }\n      }\n    "], ["\n      & > div {\n        flex-direction: column;\n        gap: 12px;\n        & > div > span {\n          text-align: center;\n        }\n      }\n    "])))), function (props) { return props.theme.breakpoints.md; });
var templateObject_1, templateObject_2;

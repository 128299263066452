var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled, { css } from 'styled-components';
export function StackedMobileCard(props) {
    var children = props.children;
    return React.createElement(StackedCard, null, children);
}
export var stackedCardStyles = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  padding: 44px 32px 60px;\n  background: #ffffff;\n  box-shadow: 0px -2px 4px rgba(207, 208, 208, 0.248416),\n    0px -20px 40px rgba(144, 155, 165, 0.11);\n  margin-top: -16px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  padding: 44px 32px 60px;\n  background: #ffffff;\n  box-shadow: 0px -2px 4px rgba(207, 208, 208, 0.248416),\n    0px -20px 40px rgba(144, 155, 165, 0.11);\n  margin-top: -16px;\n"])));
var StackedCard = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), stackedCardStyles);
var templateObject_1, templateObject_2;

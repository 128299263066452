import React from 'react';
export var ConsumerRewards = function (_a) {
    var color = _a.color;
    return (React.createElement("svg", { width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M14.5 7.5H1.5V14.5C1.5 14.7652 1.60536 15.0196 1.79289 15.2071C1.98043 15.3946 2.23478 15.5 2.5 15.5H13.5C13.7652 15.5 14.0196 15.3946 14.2071 15.2071C14.3946 15.0196 14.5 14.7652 14.5 14.5V7.5Z", stroke: color, strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M14.5 4.5H1.5C1.23478 4.5 0.98043 4.60536 0.792893 4.79289C0.605357 4.98043 0.5 5.23478 0.5 5.5V7C0.5 7.13261 0.552678 7.25979 0.646447 7.35355C0.740215 7.44732 0.867392 7.5 1 7.5H15C15.1326 7.5 15.2598 7.44732 15.3536 7.35355C15.4473 7.25979 15.5 7.13261 15.5 7V5.5C15.5 5.23478 15.3946 4.98043 15.2071 4.79289C15.0196 4.60536 14.7652 4.5 14.5 4.5Z", stroke: color, strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M7.5 4.5C5.29067 4.5 3 2.70933 3 0.5", stroke: color, strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M3 0.5C5.20933 0.5 7.5 2.29067 7.5 4.5", stroke: color, strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M8.5 4.5C10.7093 4.5 13 2.70933 13 0.5", stroke: color, strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M13 0.5C10.7907 0.5 8.5 2.29067 8.5 4.5", stroke: color, strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M6.5 4.5H9.5V15.5H6.5V4.5Z", stroke: color, strokeLinecap: "round", strokeLinejoin: "round" })));
};

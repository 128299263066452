import React from 'react';
import View from './View';
import { useUser } from "../../context";
var EvolusProgressWidgetContainer = function () {
    var _a;
    var account = useUser().account;
    var getJeuveauQuote = {
        "pricing": {
            "defaultRate": 610,
            "__typename": "JeuveauPricing"
        },
        "__typename": "JeuveauQuote"
    };
    return (React.createElement(View, { currentQuarterLoyalty: account === null || account === void 0 ? void 0 : account.currentQuarterLoyalty, pricingModel: account === null || account === void 0 ? void 0 : account.pricingModel, defaultRate: (_a = getJeuveauQuote === null || getJeuveauQuote === void 0 ? void 0 : getJeuveauQuote.pricing) === null || _a === void 0 ? void 0 : _a.defaultRate, isLoading: false }));
};
export default EvolusProgressWidgetContainer;

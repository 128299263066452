var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Button, Input, LoadingBubbles, Typography } from 'designSystem';
import { useUser } from 'context';
import { Avatar } from 'designSystem/components/Avatar/Avatar';
import phoneNumberFormatter from '../../../../utils/phoneNumberFormatter';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useScreenSizing } from 'hooks';
export var Profile = function () {
    var user = useUser().user;
    var _a = __read(useState(user === null || user === void 0 ? void 0 : user.firstName), 2), firstName = _a[0], setFirstName = _a[1];
    var _b = __read(useState(user === null || user === void 0 ? void 0 : user.lastName), 2), lastName = _b[0], setLastName = _b[1];
    var _c = __read(useState(user === null || user === void 0 ? void 0 : user.phoneNumber), 2), phoneNumber = _c[0], setPhoneNumber = _c[1];
    var _d = __read(useState(false), 1), isLoading = _d[0];
    var _e = __read(useState(null), 1), error = _e[0];
    var isMobile = useScreenSizing().isMobile;
    var UpdateUserProfile = function () {
        localStorage.setItem('currentUser', JSON.stringify({
            "id": "U-2605414-DEV",
            "createdAt": "2024-01-09T00:28:58.581+00:00",
            "email": user === null || user === void 0 ? void 0 : user.email,
            "firstName": firstName,
            "lastName": lastName,
            "phoneNumber": phoneNumber,
        }));
    };
    return (React.createElement(OuterContainer, null,
        React.createElement(InnerContainer, null,
            React.createElement(HeaderContainer, null,
                React.createElement(Typography, { variant: "titleLarge", mb: 16 }, "Edit Profile"),
                React.createElement(Typography, { mb: 32, variant: "body", color: "alt" }, "Edit your user profile.")),
            React.createElement(ContentContainer, null,
                React.createElement(LeftContainer, null,
                    React.createElement(Input, { onChange: function (e) { return setFirstName(e.target.value); }, label: "First Name", value: firstName }),
                    React.createElement(Spacer, null),
                    React.createElement(Input, { onChange: function (e) { return setLastName(e.target.value); }, label: "Last Name", value: lastName }),
                    React.createElement(Spacer, null),
                    React.createElement(Input, { onChange: function (e) { return setPhoneNumber(e.target.value); }, label: "Phone Number", value: phoneNumberFormatter(phoneNumber || '') }),
                    React.createElement(Spacer, null),
                    React.createElement(Input, { onChange: function () { }, label: "Email", value: user === null || user === void 0 ? void 0 : user.email, disabled: true }),
                    React.createElement(ButtonContainer, null,
                        React.createElement(Button, { disabled: isLoading, fullWidth: true, size: "xlarge", onClick: function () { UpdateUserProfile(); } }, isLoading ? React.createElement(LoadingBubbles, null) : 'Update')),
                    error && (React.createElement(Typography, { mt: 24, color: "link" }, "Sorry, something went wrong!"))),
                React.createElement(RightContainer, null,
                    React.createElement(Avatar, { size: isMobile ? 'large' : 'xlarge' }))))));
};
var OuterContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  margin-top: 16px;\n  padding: 32px;\n"], ["\n  ", ";\n  margin-top: 16px;\n  padding: 32px;\n"])), function (props) { return props.theme.card.alt; });
var InnerContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  padding: 48px;\n  border: 1px solid ", ";\n\n  @media screen and (max-width: ", "px) {\n    & > div:first-of-type {\n      order: 1;\n    }\n    display: flex;\n    flex-direction: column-reverse;\n    border: none;\n    padding: 0;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  padding: 48px;\n  border: 1px solid ", ";\n\n  @media screen and (max-width: ", "px) {\n    & > div:first-of-type {\n      order: 1;\n    }\n    display: flex;\n    flex-direction: column-reverse;\n    border: none;\n    padding: 0;\n  }\n"])), function (props) { return props.theme.colors.border.base; }, function (props) { return props.theme.breakpoints.md; });
var Spacer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-top: 24px;\n"], ["\n  margin-top: 24px;\n"])));
var LeftContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  margin-right: 48px;\n  @media screen and (max-width: ", "px) {\n    margin-top: 24px;\n  }\n"], ["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  margin-right: 48px;\n  @media screen and (max-width: ", "px) {\n    margin-top: 24px;\n  }\n"])), function (props) { return props.theme.breakpoints.md; });
var RightContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  flex: 1;\n  @media screen and (max-width: ", "px) {\n    flex: 0;\n  }\n  flex-direction: column;\n"], ["\n  display: flex;\n  align-items: center;\n  flex: 1;\n  @media screen and (max-width: ", "px) {\n    flex: 0;\n  }\n  flex-direction: column;\n"])), function (props) { return props.theme.breakpoints.md; });
var ButtonContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin-top: 48px;\n  width: 33%;\n  @media screen and (max-width: ", "px) {\n    width: 100%;\n  }\n"], ["\n  margin-top: 48px;\n  width: 33%;\n  @media screen and (max-width: ", "px) {\n    width: 100%;\n  }\n"])), function (props) { return props.theme.breakpoints.md; });
var HeaderContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
var ContentContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  flex: 1;\n\n  @media screen and (max-width: ", "px) {\n    display: flex;\n    flex-direction: column-reverse;\n    border: none;\n    padding: 0;\n  }\n"], ["\n  display: flex;\n  flex-direction: row;\n  flex: 1;\n\n  @media screen and (max-width: ", "px) {\n    display: flex;\n    flex-direction: column-reverse;\n    border: none;\n    padding: 0;\n  }\n"])), function (props) { return props.theme.breakpoints.md; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;

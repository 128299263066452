var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import AlertIcon from 'assets/alert-circle.png';
export var OrderBlockBannerComponent = function () {
    return (React.createElement(OrderBlockBanner, null,
        React.createElement(OrderBannerContent, null,
            React.createElement(OrderBannerTextContainer, null,
                React.createElement(AlertIconImage, { src: AlertIcon, alt: "past-due-warning" }),
                React.createElement("b", null, "Your order will be under review and will take a few days to clear. Please contact the AR support team if you have any questions.")))));
};
var OrderBlockBanner = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  flex-direction: column;\n  align-items: center;\n  padding: 16px 48px;\n  gap: 16px;\n  flex-shrink: 0;\n  background-color: ", ";\n  @media only screen and (max-width: ", "px) {\n    padding: 16px 32px;\n  }\n"], ["\n  display: flex;\n  width: 100%;\n  flex-direction: column;\n  align-items: center;\n  padding: 16px 48px;\n  gap: 16px;\n  flex-shrink: 0;\n  background-color: ", ";\n  @media only screen and (max-width: ", "px) {\n    padding: 16px 32px;\n  }\n"])), function (props) { return props.theme.colors.bg.alert; }, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var OrderBannerContent = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 16px;\n  flex: 1 0 0;\n  justify-content: space-between;\n  width: 100%;\n  color: ", ";\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 16px;\n  flex: 1 0 0;\n  justify-content: space-between;\n  width: 100%;\n  color: ", ";\n"])), function (props) { return props.theme.colors.text.alert; });
var OrderBannerTextContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  @media only screen and (max-width: ", "px) {\n    font-size: 12px;\n  }\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  @media only screen and (max-width: ", "px) {\n    font-size: 12px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var AlertIconImage = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-right: 16px;\n"], ["\n  margin-right: 16px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { useModal } from 'context';
import { useUser } from 'context/UserContext';
import { Button, Dropdown, Typography } from 'designSystem';
import RadioButton from 'designSystem/components/RadioButton/RadioButton';
import { useNavigate } from 'react-router';
import { useScreenSizing } from 'hooks';
import styled from 'styled-components';
import { Modal } from '../Modal';
import { useQueryParams } from '../../../hooks/useQueryParams';
var AccountSelection = function () {
    var _a, _b;
    var _c = useUser(), user = _c.user, account = _c.account, switchAccount = _c.switchAccount, switchFacility = _c.switchFacility;
    var params = useQueryParams().params;
    var _d = useModal(), closeModal = _d.closeModal, currentModals = _d.currentModals;
    var navigate = useNavigate();
    var _e = __read(useState(account), 2), selectedAccount = _e[0], setSelectedAccount = _e[1];
    var _f = __read(useState(true), 1), showSwitchUI = _f[0];
    var isMobile = useScreenSizing().isMobile;
    var accounts = function () {
        var _a, _b;
        if (!((_a = user === null || user === void 0 ? void 0 : user.accounts) === null || _a === void 0 ? void 0 : _a.length)) {
            return [];
        }
        return (_b = user === null || user === void 0 ? void 0 : user.accounts) === null || _b === void 0 ? void 0 : _b.map(function (_a) {
            var account = _a.account;
            return {
                value: account === null || account === void 0 ? void 0 : account.id,
                label: account === null || account === void 0 ? void 0 : account.name,
            };
        });
    };
    var renderAccountItem = function (item) {
        var _a = item === null || item === void 0 ? void 0 : item.account, id = _a.id, name = _a.name;
        return (React.createElement(AccountItemTextContainer, { key: id },
            React.createElement(Typography, { variant: "headlineSmall" }, "".concat(name)),
            React.createElement(Typography, { variant: "footnote", color: "alt" }, id)));
    };
    var dropdownOverrideLabel = function (id) {
        return renderAccountItem(user === null || user === void 0 ? void 0 : user.accounts[id]);
    };
    var canClose = showSwitchUI && currentModals.length >= 1;
    return (React.createElement(Modal, { size: "small", showClose: canClose },
        React.createElement(OuterContainer, null,
            React.createElement(HeaderContainer, null,
                React.createElement(Typography, { variant: "displayLarge" },
                    showSwitchUI ? 'Switch' : 'Select',
                    " Account"),
                React.createElement(Typography, { mt: 12, variant: "subtitle", color: "alt" }, "Select another account.")),
            !isMobile ? (React.createElement(AccountsContainer, { "data-automation-id": "account-selection-container" }, (_b = (_a = user === null || user === void 0 ? void 0 : user.accounts) === null || _a === void 0 ? void 0 : _a.sort(function (a, b) { var _a, _b; return (_a = a === null || a === void 0 ? void 0 : a.account) === null || _a === void 0 ? void 0 : _a.name.localeCompare((_b = b === null || b === void 0 ? void 0 : b.account) === null || _b === void 0 ? void 0 : _b.name); })) === null || _b === void 0 ? void 0 : _b.map(function (account) {
                var _a, _b, _c;
                return (React.createElement(RadioItemContainer, { onClick: function () {
                        setSelectedAccount(JSON.parse(localStorage.getItem(account.account.id)));
                    }, "data-automation-id": "account-".concat((_a = account === null || account === void 0 ? void 0 : account.account) === null || _a === void 0 ? void 0 : _a.id) },
                    React.createElement(RadioButton, { checked: (selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.id) === ((_b = account === null || account === void 0 ? void 0 : account.account) === null || _b === void 0 ? void 0 : _b.id), onChange: function () {
                            setSelectedAccount(JSON.parse(localStorage.getItem(account.account.id)));
                        }, "data-automation-id": "account-".concat((_c = account === null || account === void 0 ? void 0 : account.account) === null || _c === void 0 ? void 0 : _c.id, "-radio") }),
                    renderAccountItem(account)));
            }))) : (React.createElement(DropdownContainer, null,
                React.createElement(Dropdown, { overrideLabel: dropdownOverrideLabel, placeholder: "Choose one", options: accounts(), onSelect: function (item) {
                        var _a, _b;
                        var matchingAccount = (_b = (_a = user === null || user === void 0 ? void 0 : user.accounts) === null || _a === void 0 ? void 0 : _a.find(function (a) { var _a; return ((_a = a === null || a === void 0 ? void 0 : a.account) === null || _a === void 0 ? void 0 : _a.id) === item.value; })) === null || _b === void 0 ? void 0 : _b.account;
                        setSelectedAccount(matchingAccount);
                    }, selected: accounts().find(function (a) { return a.value === (selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.id); }) })))),
        React.createElement(ButtonContainer, null,
            canClose && (React.createElement(CancelButton, { variant: "outlined", size: "xlarge", onClick: function () { return closeModal(); } }, "Cancel")),
            React.createElement(Button, { "data-automation-id": "select-account-button", size: "xlarge", onClick: function () {
                    var _a;
                    switchAccount(selectedAccount);
                    window.sessionStorage.removeItem('@new_order');
                    window.sessionStorage.removeItem('@check_in');
                    switchFacility(null);
                    navigate((_a = params.redirect) !== null && _a !== void 0 ? _a : '/home');
                    closeModal();
                } },
                showSwitchUI ? 'Switch' : 'Select',
                " Account"))));
};
var AccountItemTextContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
var AccountsContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  overflow: auto;\n  max-height: 30vh;\n  width: 100%;\n  @media only screen and (max-width: ", "px) {\n    flex: 1;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  overflow: auto;\n  max-height: 30vh;\n  width: 100%;\n  @media only screen and (max-width: ", "px) {\n    flex: 1;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var HeaderContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  margin-bottom: 24px;\n  flex-direction: column;\n  margin-top: 50px;\n"], ["\n  display: flex;\n  justify-content: center;\n  margin-bottom: 24px;\n  flex-direction: column;\n  margin-top: 50px;\n"])));
var OuterContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  justify-content: flex-start;\n  align-items: flex-start;\n  width: 100%;\n  max-height: 50vh;\n  @media only screen and (max-width: ", "px) {\n    max-height: none;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  justify-content: flex-start;\n  align-items: flex-start;\n  width: 100%;\n  max-height: 50vh;\n  @media only screen and (max-width: ", "px) {\n    max-height: none;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var RadioItemContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  cursor: pointer;\n  margin-bottom: 15px;\n  display: flex;\n  align-items: center;\n"], ["\n  cursor: pointer;\n  margin-bottom: 15px;\n  display: flex;\n  align-items: center;\n"])));
var ButtonContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  margin-top: 48px;\n  @media only screen and (max-width: ", "px) {\n    justify-content: flex-start;\n    flex-direction: column-reverse;\n  }\n"], ["\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  margin-top: 48px;\n  @media only screen and (max-width: ", "px) {\n    justify-content: flex-start;\n    flex-direction: column-reverse;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var CancelButton = styled(Button)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin-right: 8px;\n  @media only screen and (max-width: ", "px) {\n    margin-top: 12px;\n    border: none;\n  }\n"], ["\n  margin-right: 8px;\n  @media only screen and (max-width: ", "px) {\n    margin-top: 12px;\n    border: none;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var DropdownContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  width: 100%;\n  overflow: auto;\n"], ["\n  display: flex;\n  flex: 1;\n  width: 100%;\n  overflow: auto;\n"])));
export default AccountSelection;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;

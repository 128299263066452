var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { Button, Desktop, Icon, Typography } from 'designSystem';
import { StepEnum } from '../Enums/StepEnum';
import { useLocation } from 'react-router';
import { useScreenSizing } from 'hooks';
var checkInOnlySteps = new Set([
    StepEnum.SpecialistSelect,
    StepEnum.CheckInAgreement,
    StepEnum.SendMagicPhrase,
    StepEnum.ValidateMagicPhrase,
    StepEnum.CheckInComplete,
]);
var enrollmentSteps = new Set([
    StepEnum.EnrollPatient,
    StepEnum.EnrollPatientForm,
    StepEnum.EmailInput,
    StepEnum.PatientConsent,
    StepEnum.ConsentNotGiven,
    StepEnum.SendingMessage,
]);
export var StepContainer = function (_a) {
    var children = _a.children, handleRestart = _a.handleRestart, label = _a.label, headerText = _a.headerText, subHeader = _a.subHeader, hideRestart = _a.hideRestart;
    var isCustom = useScreenSizing(1028).isCustom;
    var location = useLocation();
    var renderHeaderPreText = !checkInOnlySteps.has(location.search);
    var isEnrollmentStep = enrollmentSteps.has(location.search);
    var hasHeaders = !!headerText || !!subHeader;
    var addHeaderLineBreak = renderHeaderPreText && isCustom && !hideRestart;
    return (React.createElement(Container, null,
        React.createElement(StepHeaderContainer, null,
            React.createElement(Desktop, null,
                React.createElement(TopBarContainer, null,
                    React.createElement(Typography, { variant: "sectionHeader", color: "alt5" },
                        renderHeaderPreText && 'Patient Reward Status / ',
                        addHeaderLineBreak && React.createElement("br", null),
                        "Check In"),
                    !hideRestart && (React.createElement(Button, { size: "small", variant: "secondary", startIcon: React.createElement(Icon, { name: "switch" }), onClick: handleRestart },
                        React.createElement("b", null,
                            "Restart ",
                            isEnrollmentStep ? 'Enrollment' : 'Check-In'))))),
            !!label && (React.createElement(Typography, { mb: !hasHeaders ? 32 : 0, variant: "titleLarge" }, label)),
            (!!headerText || !!subHeader) && (React.createElement(SubheaderContainer, null,
                !!headerText && React.createElement(Typography, { color: "alt" }, headerText),
                headerText && subHeader && (React.createElement(React.Fragment, null,
                    React.createElement("br", null),
                    React.createElement("br", null))),
                !!subHeader && React.createElement(Typography, { color: "alt" }, subHeader)))),
        children));
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex: 3;\n  flex-direction: column;\n  justify-content: flex-start;\n  background-color: white;\n  margin: 16px;\n  ", ";\n\n  padding: 48px;\n  position: relative;\n  min-height: 80vh;\n  @media screen and (max-width: ", "px) {\n    margin: 16px 0 0;\n    padding: 32px;\n    box-shadow: 0px -2px 4px rgba(207, 208, 208, 0.248416),\n      0px -20px 40px rgba(144, 155, 165, 0.11);\n    flex: 1;\n    min-height: unset;\n  }\n"], ["\n  display: flex;\n  flex: 3;\n  flex-direction: column;\n  justify-content: flex-start;\n  background-color: white;\n  margin: 16px;\n  ", ";\n\n  padding: 48px;\n  position: relative;\n  min-height: 80vh;\n  @media screen and (max-width: ", "px) {\n    margin: 16px 0 0;\n    padding: 32px;\n    box-shadow: 0px -2px 4px rgba(207, 208, 208, 0.248416),\n      0px -20px 40px rgba(144, 155, 165, 0.11);\n    flex: 1;\n    min-height: unset;\n  }\n"])), function (props) { return props.theme.elevation.base.elevation1; }, function (props) { return props.theme.breakpoints.md; });
var StepHeaderContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
var SubheaderContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-top: 16px;\n  margin-bottom: 32px;\n"], ["\n  margin-top: 16px;\n  margin-bottom: 32px;\n"])));
var TopBarContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 12px;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 12px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;

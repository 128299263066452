var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { PaymentOptions } from './components/PaymentOptions';
import { useLocation, useNavigate } from 'react-router';
import { PaymentSteps } from './enums/Steps';
import { PayPreSelectedInvoices } from './components/PayPreSelectedInvoices';
import { PaymentOption } from './enums/PaymentOptions';
import { useSessionStorage } from 'hooks';
import { CustomPayment } from './components/CustomPayment';
import { SelectInvoices } from './components/SelectInvoices';
import { SelectPayment } from './components/SelectPayment';
import { ReviewPayment } from './components/ReviewPayment';
import { PaymentOverview } from './components/PaymentOverview';
import { useMountCycle } from '../../../../hooks/useMountCycle';
import { addMobileStyles } from '../../../../utils/addMobileStyles';
var isValidStep = function (_a) {
    var currentData = _a.currentData;
    var _b = currentData !== null && currentData !== void 0 ? currentData : {}, amountDue = _b.amountDue, paymentOption = _b.paymentOption, selectedInvoices = _b.selectedInvoices, allInvoices = _b.allInvoices, customAmount = _b.customAmount, paymentMethod = _b.paymentMethod, completedId = _b.completedId, currentStep = _b.currentStep;
    var paymentAmount = customAmount !== null && customAmount !== void 0 ? customAmount : amountDue;
    switch (currentStep) {
        case PaymentSteps.PaymentOptions:
            return true;
        case PaymentSteps.PayEntireBalance:
        case PaymentSteps.PayDueThisWeek:
            return amountDue && paymentOption && selectedInvoices;
        case PaymentSteps.CustomPayment:
        case PaymentSteps.SelectInvoices:
            return allInvoices && paymentOption;
        case PaymentSteps.SelectPayment:
            return paymentAmount && paymentOption && selectedInvoices;
        case PaymentSteps.ReviewPayment:
            return (selectedInvoices && paymentOption && paymentMethod && paymentAmount);
        case PaymentSteps.PaymentOverview:
            return (selectedInvoices &&
                paymentOption &&
                paymentMethod &&
                paymentAmount &&
                completedId);
    }
};
export var MakeAPayment = function () {
    var _a, _b, _c, _d, _e, _f, _g;
    var location = useLocation();
    var navigate = useNavigate();
    var _h = __read(useSessionStorage('@make-payment', {}), 2), paymentData = _h[0], setPaymentData = _h[1];
    var _j = __read(useState((_a = paymentData.paymentOption) !== null && _a !== void 0 ? _a : null), 2), paymentOption = _j[0], setPaymentOption = _j[1];
    var _k = __read(useState((_b = paymentData.selectedInvoices) !== null && _b !== void 0 ? _b : null), 2), selectedInvoices = _k[0], setSelectedInvoices = _k[1];
    var _l = __read(useState((_c = paymentData === null || paymentData === void 0 ? void 0 : paymentData.allInvoices) !== null && _c !== void 0 ? _c : null), 2), allInvoices = _l[0], setAllInvoices = _l[1];
    var _m = __read(useState((_d = paymentData === null || paymentData === void 0 ? void 0 : paymentData.customAmount) !== null && _d !== void 0 ? _d : null), 2), customAmount = _m[0], setCustomAmount = _m[1];
    var _o = __read(useState((_e = paymentData === null || paymentData === void 0 ? void 0 : paymentData.amountDue) !== null && _e !== void 0 ? _e : null), 2), amountDue = _o[0], setAmountDue = _o[1];
    var _p = __read(useState((_f = paymentData === null || paymentData === void 0 ? void 0 : paymentData.paymentMethod) !== null && _f !== void 0 ? _f : null), 2), paymentMethod = _p[0], setPaymentMethod = _p[1];
    var _q = __read(useState((_g = paymentData === null || paymentData === void 0 ? void 0 : paymentData.paymentMethod) !== null && _g !== void 0 ? _g : null), 2), completedId = _q[0], setCompletedId = _q[1];
    useMountCycle(function () {
        if (paymentData.currentStep && isValidStep({ currentData: paymentData })) {
            navigate(paymentData.currentStep, { replace: true });
        }
        else {
            navigate(PaymentSteps.PaymentOptions, { replace: true });
        }
    });
    useEffect(function () {
        if (!location.search) {
            navigate(PaymentSteps.PaymentOptions, { replace: true });
            return;
        }
        if (location.search === PaymentSteps.PaymentOptions &&
            Object.values(paymentData).length) {
            setPaymentData({});
            setPaymentOption(null);
            setSelectedInvoices(null);
            setAllInvoices(null);
            setCustomAmount(null);
            setAmountDue(null);
            setPaymentMethod(null);
        }
    }, [
        location,
        navigate,
        selectedInvoices,
        paymentOption,
        paymentData,
        customAmount,
        setPaymentData,
        allInvoices,
        amountDue,
        paymentMethod,
        completedId,
    ]);
    useEffect(function () {
        setPaymentData({
            paymentOption: paymentOption,
            selectedInvoices: selectedInvoices,
            currentStep: location.search,
            customAmount: customAmount,
            allInvoices: allInvoices,
            amountDue: amountDue,
            paymentMethod: paymentMethod,
        });
    }, [
        setPaymentData,
        paymentOption,
        selectedInvoices,
        customAmount,
        allInvoices,
        amountDue,
        paymentMethod,
        location,
    ]);
    var onDismount = useRef();
    useEffect(function () {
        onDismount.current = function () {
            setPaymentData({});
        };
    }, [setPaymentData]);
    useEffect(function () { return onDismount.current; }, []);
    var renderStep = function () {
        switch (location.search) {
            case PaymentSteps.PaymentOptions:
                return (React.createElement(PaymentOptions, { onContinue: function (_a) {
                        var option = _a.paymentOption, due = _a.amountDue, invoices = _a.invoices;
                        var next = PaymentSteps.PayEntireBalance;
                        switch (option) {
                            case PaymentOption.DUE_THIS_WEEK:
                                next = PaymentSteps.PayDueThisWeek;
                                break;
                            case PaymentOption.SELECT_INVOICES:
                                next = PaymentSteps.SelectInvoices;
                                break;
                            case PaymentOption.CUSTOM:
                                next = PaymentSteps.CustomPayment;
                                break;
                        }
                        setAllInvoices(invoices);
                        setPaymentOption(option);
                        setAmountDue(due);
                        navigate(next);
                    }, onPreselect: function (invoices) { return setSelectedInvoices(invoices); } }));
            case PaymentSteps.PayEntireBalance:
            case PaymentSteps.PayDueThisWeek:
                return (React.createElement(PayPreSelectedInvoices, { onContinue: function () { return navigate(PaymentSteps.SelectPayment); }, amountDue: amountDue, paymentOption: paymentOption, invoices: selectedInvoices }));
            case PaymentSteps.CustomPayment:
                return (React.createElement(CustomPayment, { allInvoices: allInvoices, onContinue: function (_a) {
                        var ca = _a.customAmount, invoices = _a.invoices;
                        setCustomAmount(ca);
                        setSelectedInvoices(invoices);
                        navigate(PaymentSteps.SelectPayment);
                    } }));
            case PaymentSteps.SelectInvoices:
                return (React.createElement(SelectInvoices, { invoices: allInvoices, onContinue: function (selected) {
                        setSelectedInvoices(selected);
                        setAmountDue(selected.reduce(function (acc, cv) { var _a; return acc + ((_a = cv.amountDue) !== null && _a !== void 0 ? _a : 0); }, 0));
                        navigate(PaymentSteps.SelectPayment);
                    } }));
            case PaymentSteps.SelectPayment:
                return (React.createElement(SelectPayment, { onContinue: function (pm) {
                        setPaymentMethod(pm);
                        navigate(PaymentSteps.ReviewPayment);
                    }, paymentOption: paymentOption }));
            case PaymentSteps.ReviewPayment:
                return (React.createElement(ReviewPayment, { onContinue: function (id) {
                        setCompletedId(id);
                        navigate(PaymentSteps.PaymentOverview);
                    }, invoices: selectedInvoices, paymentOption: paymentOption, paymentMethod: paymentMethod, paymentAmount: customAmount !== null && customAmount !== void 0 ? customAmount : amountDue }));
            case PaymentSteps.PaymentOverview:
                return (React.createElement(PaymentOverview, { paymentAmount: customAmount !== null && customAmount !== void 0 ? customAmount : amountDue, paymentId: completedId, paymentMethod: paymentMethod, invoices: selectedInvoices, paymentOption: paymentOption }));
        }
    };
    return React.createElement(Container, null, renderStep());
};
var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: 16px;\n  display: flex;\n  gap: 32px;\n  ", "\n"], ["\n  margin: 16px;\n  display: flex;\n  gap: 32px;\n  ", "\n"])), addMobileStyles(css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    margin: 16px 0 0;\n    flex-direction: column;\n    flex: 1;\n  "], ["\n    margin: 16px 0 0;\n    flex-direction: column;\n    flex: 1;\n  "])))));
var templateObject_1, templateObject_2;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { StepContainer } from '../StepContainer';
import { MakePaymentOption } from './components/MakePaymentOption';
import styled from 'styled-components';
import { PaymentProgress } from '../PaymentProgress';
import { PlaceholderLoading } from 'designSystem';
import { currencyFormatter } from 'utils/currencyFormatter';
import { format, nextSaturday, previousSunday } from 'date-fns';
import { PaymentOption } from '../../enums/PaymentOptions';
import { useScreenSizing } from '../../../../../../hooks';
import { pluralize } from '../../../../../../utils/pluralize';
import { MobileContinueButton } from '../MobileContinueButton';
export var PaymentOptionsView = function (_a) {
    var loading = _a.loading, totalAmountDue = _a.totalAmountDue, totalAmountDueThisWeek = _a.totalAmountDueThisWeek, invoices = _a.invoices, onPreselect = _a.onPreselect, onContinue = _a.onContinue;
    var isMobile = useScreenSizing().isMobile;
    var _b = __read(useState(null), 2), paymentOption = _b[0], setPaymentOption = _b[1];
    var dueThisWeek = invoices === null || invoices === void 0 ? void 0 : invoices.filter(function (invoice) { return invoice.isDueThisWeek; });
    var pastDue = invoices === null || invoices === void 0 ? void 0 : invoices.filter(function (invoice) { return invoice.isPastDue; });
    var buttonDisabled = !paymentOption || !(invoices === null || invoices === void 0 ? void 0 : invoices.length);
    var handleContinue = function () {
        if (paymentOption === PaymentOption.ALL) {
            onPreselect(invoices);
        }
        if (paymentOption === PaymentOption.DUE_THIS_WEEK) {
            onPreselect(dueThisWeek);
        }
        onContinue({
            paymentOption: paymentOption,
            amountDue: paymentOption === PaymentOption.ALL
                ? totalAmountDue
                : totalAmountDueThisWeek,
            invoices: invoices,
        });
    };
    var renderContent = function () {
        if (loading) {
            return React.createElement(PlaceholderLoading, null);
        }
        var now = new Date();
        var lastSunday = format(previousSunday(now), 'MM/dd');
        var followingSaturday = format(nextSaturday(now), 'MM/dd');
        var formattedTotal = currencyFormatter(totalAmountDue, 2);
        var formattedWeekTotal = currencyFormatter(totalAmountDueThisWeek, 2);
        var selectInvoicesLabel = (pastDue === null || pastDue === void 0 ? void 0 : pastDue.length) > 0
            ? "".concat(pastDue === null || pastDue === void 0 ? void 0 : pastDue.length, " Past Due Invoice").concat(pluralize(pastDue === null || pastDue === void 0 ? void 0 : pastDue.length))
            : "".concat(invoices === null || invoices === void 0 ? void 0 : invoices.length, " Invoices");
        return (React.createElement(React.Fragment, null,
            React.createElement(MakePaymentOption, { onSelect: function () {
                    setPaymentOption(PaymentOption.ALL);
                }, title: "Pay Entire Balance", endLabel: formattedTotal, selected: paymentOption === PaymentOption.ALL }),
            React.createElement(MakePaymentOption, { onSelect: function () {
                    setPaymentOption(PaymentOption.DUE_THIS_WEEK);
                }, title: React.createElement(React.Fragment, null,
                    "Pay Balance Due This Week",
                    isMobile ? React.createElement("br", null) : ' ',
                    React.createElement("strong", null,
                        lastSunday,
                        "-",
                        followingSaturday)), disabled: totalAmountDueThisWeek <= 0, endLabel: formattedWeekTotal, selected: paymentOption === PaymentOption.DUE_THIS_WEEK }),
            React.createElement(MakePaymentOption, { onSelect: function () {
                    setPaymentOption(PaymentOption.SELECT_INVOICES);
                }, title: "Select Which Invoice to Pay", endLabel: selectInvoicesLabel, selected: paymentOption === PaymentOption.SELECT_INVOICES }),
            React.createElement(MakePaymentOption, { onSelect: function () {
                    setPaymentOption(PaymentOption.CUSTOM);
                }, title: "Pay Custom Amount", selected: paymentOption === PaymentOption.CUSTOM })));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(StepContainer, { header: "Make a Payment", subHeader: "Payment Options", tertiaryHeader: "Choose from the following payment options." },
            React.createElement(OptionsContainer, null, renderContent()),
            isMobile && (React.createElement(MobileContinueButton, { label: "Confirm Payment Option", onContinue: handleContinue, disabled: buttonDisabled }))),
        !isMobile && (React.createElement(PaymentProgress, { primaryButtonLabel: "Confirm Payment Option", primaryAction: handleContinue, primaryButtonDisabled: buttonDisabled }))));
};
var OptionsContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  gap: 12px;\n  margin-top: 32px;\n"], ["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  gap: 12px;\n  margin-top: 32px;\n"])));
var templateObject_1;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Typography } from 'designSystem';
import React from 'react';
import styled from 'styled-components';
import { CSVDownloadButton } from '../CSVDownloadButton';
import { PDFDownloadButton } from '../PDFDownloadButton';
export function StatementListCardView(props) {
    var startDate = props.startDate, endDate = props.endDate, date = props.date, name = props.name, facilityIds = props.facilityIds, injectorIds = props.injectorIds, activityFilters = props.activityFilters, isFiltered = props.isFiltered, _a = props.showPDFButton, showPDFButton = _a === void 0 ? true : _a;
    var formattedDate = date instanceof Date ? new Intl.DateTimeFormat('en-US').format(date) : null;
    return (React.createElement(Container, null,
        React.createElement(DateContainer, null,
            React.createElement(Typography, { variant: "headlineSmall" }, name),
            date && React.createElement(Typography, { variant: "footnote" }, formattedDate)),
        React.createElement(ButtonContainer, null,
            React.createElement(CSVDownloadButton, { date: date, facilityIds: facilityIds, injectorIds: injectorIds, startDate: startDate, endDate: endDate, activityFilters: activityFilters, isFiltered: isFiltered }),
            showPDFButton && React.createElement(PDFDownloadButton, { date: date }))));
}
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: ", ";\n  border: 1px solid ", ";\n  box-sizing: border-box;\n\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 12px 20px;\n"], ["\n  background: ", ";\n  border: 1px solid ", ";\n  box-sizing: border-box;\n\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 12px 20px;\n"])), function (props) { return props.theme.colors.white.white50; }, function (props) { return props.theme.colors.border.base; });
var DateContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  & > span {\n    display: block;\n  }\n"], ["\n  & > span {\n    display: block;\n  }\n"])));
var ButtonContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  gap: 8px;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  gap: 8px;\n"])));
var templateObject_1, templateObject_2, templateObject_3;

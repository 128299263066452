var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var _a;
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Button, LoadingBubbles, Typography, VerticalStepper, } from 'designSystem';
import { cardStyles } from '../../Dashboard/constants/CardStyles';
import { PaymentSteps } from '../enums/Steps';
import { useLocation } from 'react-router';
import { PaymentOption } from '../enums/PaymentOptions';
var pathToStepIndex = (_a = {},
    _a[PaymentSteps.PaymentOptions] = 0,
    _a[PaymentSteps.PayDueThisWeek] = 1,
    _a[PaymentSteps.PayEntireBalance] = 1,
    _a[PaymentSteps.SelectInvoices] = 1,
    _a[PaymentSteps.CustomPayment] = 1,
    _a[PaymentSteps.SelectPayment] = 2,
    _a[PaymentSteps.ReviewPayment] = 3,
    _a);
export var PaymentProgress = function (_a) {
    var primaryAction = _a.primaryAction, primaryButtonLabel = _a.primaryButtonLabel, primaryButtonDisabled = _a.primaryButtonDisabled, paymentOption = _a.paymentOption, loading = _a.loading;
    var location = useLocation();
    var steps = useMemo(function () {
        var stepTwoTitle;
        switch (paymentOption) {
            case PaymentOption.ALL:
                stepTwoTitle = 'Pay Entire Balance - Overview';
                break;
            case PaymentOption.DUE_THIS_WEEK:
                stepTwoTitle = 'Pay Balance Due This Week - Overview';
                break;
            case PaymentOption.SELECT_INVOICES:
                stepTwoTitle = 'Select Invoices To Pay';
                break;
            case PaymentOption.CUSTOM:
                stepTwoTitle = 'Pay Custom Amount';
        }
        return [
            {
                id: 'step-0',
                name: 'Select Payment Option',
            },
            {
                id: 'step-1',
                name: stepTwoTitle,
            },
            {
                id: 'step-2',
                name: 'Select Payment Method',
            },
            {
                id: 'step-3',
                name: 'Review Payment',
            },
            {
                id: 'step-3',
                name: 'Placeholder',
            },
        ];
    }, [paymentOption]);
    return (React.createElement(Container, null,
        React.createElement("div", null,
            React.createElement(Typography, { display: "block", mb: 36, variant: "subtitle", color: "alt" }, "Your Progress"),
            React.createElement(VerticalStepper, { onlyShowCurrent: true, steps: steps, currentStep: pathToStepIndex[location.search] })),
        React.createElement(Button, { size: "xlarge", disabled: !!primaryButtonDisabled, onClick: primaryAction, variant: "primary" }, loading ? React.createElement(LoadingBubbles, null) : primaryButtonLabel)));
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  display: flex;\n  flex-direction: column;\n  padding: 48px 32px;\n  & > button {\n    margin-top: 48px;\n  }\n"], ["\n  ", ";\n  display: flex;\n  flex-direction: column;\n  padding: 48px 32px;\n  & > button {\n    margin-top: 48px;\n  }\n"])), cardStyles);
var templateObject_1;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { Typography } from 'designSystem';
import { StatusContainer } from './StatusContainer';
import styled, { css } from 'styled-components';
import { addMobileStyles } from 'utils/addMobileStyles';
import { AvailableRewardCard } from '../../AvailableRewardCard';
import { customBreakpointStyles } from 'utils/customBreakpointStyles';
import { useScreenSizing } from 'hooks';
import { TwentyUnitsCard } from './TwentyUnitsCard';
var getSubtext = function (isBusCampaign) {
    if (isBusCampaign) {
        return (React.createElement(React.Fragment, null,
            React.createElement("sup", null, "*"),
            "Patient eligible to redeem reward today only"));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("sup", null, "*"),
        "Patient eligible to redeem reward once every 90 days. Rewards expire 547 days after date of issuance."));
};
export var RewardAvailable = function (_a) {
    var rewardAmount = _a.rewardAmount, expiredInGrace = _a.expiredInGrace, isBusCampaign = _a.isBusCampaign, newProfileFromReferral = _a.newProfileFromReferral, rewardAmountUpdateReason = _a.rewardAmountUpdateReason;
    var isMobile = useScreenSizing().isMobile;
    return (React.createElement(RewardAvailableContainer, null,
        React.createElement("div", null,
            React.createElement(Typography, { align: isMobile ? 'center' : 'left', variant: "titleLarge", mt: 8, mb: 8 }, "Reward Available"),
            expiredInGrace && (React.createElement(Typography, { align: isMobile ? 'center' : 'left', variant: "headlineSmall", color: "link" }, "Reward has been extended for an additional 365 days")),
            React.createElement(Typography, { variant: "footnote", color: "alt" }, getSubtext(!!isBusCampaign))),
        React.createElement(RewardCardContainer, null, isBusCampaign ? (React.createElement(TwentyUnitsCard, null)) : (React.createElement(AvailableRewardCard, { rewardAmount: rewardAmount, newProfileFromReferral: newProfileFromReferral, rewardAmountUpdateReason: rewardAmountUpdateReason })))));
};
var RewardAvailableContainer = styled(StatusContainer)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", "\n  ", "\n"], ["\n  ", "\n  ", "\n"])), customBreakpointStyles(1180, css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      flex-direction: column;\n    "], ["\n      flex-direction: column;\n    "])))), addMobileStyles(css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    padding: 0;\n    margin: 0;\n    flex-direction: column-reverse;\n  "], ["\n    padding: 0;\n    margin: 0;\n    flex-direction: column-reverse;\n  "])))));
var RewardCardContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), addMobileStyles(css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    margin-top: 20px;\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  "], ["\n    margin-top: 20px;\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  "])))));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;

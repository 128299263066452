var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { InlineEnrollmentView } from './View';
import { useLazyQuery, useMutation } from '@apollo/client';
import mutation from './index.graphql';
import query from '../../CheckIn/RewardStatus/index.graphql';
import { useUser } from 'context';
import { useNavigate } from 'react-router';
import { StepEnum } from '../../../Enums/StepEnum';
import { waitForIt } from '@kingsmendv/util';
export var InlineEnrollmentContainer = function (_a) {
    var phoneNumber = _a.phoneNumber, onForward = _a.onForward, prefillEmail = _a.prefillEmail, handleRestart = _a.handleRestart;
    var navigate = useNavigate();
    var _b = useUser(), account = _b.account, facility = _b.facility;
    var _c = __read(useState(false), 2), loading = _c[0], setLoading = _c[1];
    var _d = __read(useState(null), 2), existingEmail = _d[0], setExistingEmail = _d[1];
    var _e = __read(useLazyQuery(query), 1), statusQuery = _e[0];
    var _f = __read(useMutation(mutation, {
        onCompleted: function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, waitForIt({
                            timeoutInMs: 15000,
                            backOffInMs: 1000,
                            initialDelayInMs: 1000,
                            generator: function () {
                                return statusQuery({
                                    variables: {
                                        input: {
                                            phoneNumber: phoneNumber,
                                            accountId: account === null || account === void 0 ? void 0 : account.id,
                                        },
                                    },
                                });
                            },
                            validator: function (_a) {
                                var result = _a.result;
                                return !!result.data.lookupConsumerProfile.profile;
                            },
                        })];
                    case 1:
                        _a.sent();
                        setLoading(false);
                        navigate(StepEnum.RewardStatus);
                        return [2];
                }
            });
        }); },
        onError: function (error, clientOptions) {
            var _a, _b;
            setLoading(false);
            if (error.message.includes('Profile already exists for email:') ||
                error.message ===
                    'cannot patch email of profile that does not belong to phone number') {
                setExistingEmail((_b = (_a = clientOptions === null || clientOptions === void 0 ? void 0 : clientOptions.variables) === null || _a === void 0 ? void 0 : _a.input) === null || _b === void 0 ? void 0 : _b.email);
            }
        },
    }), 2), enrollPatient = _f[0], mutationLoading = _f[1].loading;
    var handleEnroll = function (_a) {
        var firstName = _a.firstName, lastName = _a.lastName, email = _a.email, toxinUsed = _a.toxinUsed, zipCode = _a.zipCode, hasReceivedToxinBefore = _a.hasReceivedToxinBefore, gender = _a.gender, birthday = _a.birthday;
        return __awaiter(void 0, void 0, void 0, function () {
            var _b, month, day, year;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        setLoading(true);
                        _b = __read(birthday.split('/'), 3), month = _b[0], day = _b[1], year = _b[2];
                        return [4, enrollPatient({
                                variables: {
                                    input: {
                                        accountId: account.id,
                                        birthday: {
                                            month: month,
                                            day: day,
                                            year: year,
                                        },
                                        isOptedIn: true,
                                        email: email,
                                        facilityId: facility.id,
                                        firstName: firstName,
                                        gender: gender,
                                        lastName: lastName,
                                        phoneNumber: phoneNumber,
                                        survey: {
                                            toxinUsed: toxinUsed,
                                            hasReceivedToxinBefore: hasReceivedToxinBefore,
                                            hasSpecialist: true,
                                        },
                                        zipCode: zipCode,
                                    },
                                },
                            })];
                    case 1:
                        _c.sent();
                        onForward();
                        return [2];
                }
            });
        });
    };
    return (React.createElement(InlineEnrollmentView, { handleRestart: handleRestart, prefillEmail: prefillEmail, phoneNumber: phoneNumber, loading: loading || mutationLoading, onForward: onForward, onEnroll: handleEnroll, existingEmail: existingEmail }));
};

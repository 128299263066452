var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { Card, CardHeader, CardBody, CardFooter, } from 'designSystem/components/Card';
import { Typography } from 'designSystem/components/Typography';
import { Input } from 'designSystem/components/Input';
import { Row, Column } from 'designSystem/components/Row';
import { Button } from 'designSystem/components/Button';
import { useModal } from 'context';
import ModalTypeEnum from 'types/enums/ModalTypeEnum';
import { useLocalStorage, useScreenSizing } from 'hooks';
import LogomarkImg from 'assets/logomark.svg';
import LogoImage from 'assets/my-evolus-logo-3.svg';
import WhiteLogo from 'assets/evolus-logo-white.svg';
import { IntercomButton } from 'components/IntercomButton';
import { Icon } from 'designSystem';
import { useNavigate } from "react-router";
export var SignInView = function () {
    var _a = __read(useLocalStorage('loggedIn', false), 2), setUserSignedIn = _a[1];
    var openModal = useModal().openModal;
    var isMobile = useScreenSizing().isMobile;
    var theme = useTheme();
    var _b = __read(useState(false), 2), isLoading = _b[0], setIsLoading = _b[1];
    var _c = __read(useState(''), 2), error = _c[0], setError = _c[1];
    var _d = __read(useState(''), 2), emailAddress = _d[0], setEmailAddress = _d[1];
    var _e = __read(useState(''), 2), password = _e[0], setPassword = _e[1];
    var _f = __read(useState(false), 2), showPassword = _f[0], setShowPassword = _f[1];
    var _g = __read(useState(false), 2), showPasswordReset = _g[0], setShowPasswordReset = _g[1];
    var navigate = useNavigate();
    var onSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, type, message, e;
        return __generator(this, function (_b) {
            setShowPasswordReset(false);
            if (!password) {
                setError('Enter Password');
                return [2, null];
            }
            setIsLoading(true);
            setError('');
            try {
                setUserSignedIn(true);
                navigate('/home');
            }
            catch (err) {
                _a = __read(err.toString().split(': '), 2), type = _a[0], message = _a[1];
                e = message;
                if (type === 'UserNotFoundException' ||
                    (type === 'NotAuthorizedException' &&
                        message !== 'Password attempts exceeded')) {
                    e = 'Incorrect Email/Password.';
                }
                if (type === 'NotAuthorizedException') {
                    setShowPasswordReset(true);
                }
                setError(e);
            }
            finally {
                setIsLoading(false);
            }
            return [2];
        });
    }); };
    var handleForgotPassword = function () {
        openModal(ModalTypeEnum.FORGOT_PASSWORD);
    };
    var handleEnter = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (e.code !== 'Enter') {
                        return [2];
                    }
                    return [4, onSubmit()];
                case 1:
                    _a.sent();
                    return [2];
            }
        });
    }); };
    var renderRightIcon = function () {
        if (!!error) {
            return null;
        }
        if (!showPassword) {
            return 'show-password';
        }
        return 'hide-password';
    };
    var renderLoginError = function () {
        if (!error) {
            return '';
        }
        return (React.createElement(React.Fragment, null,
            error,
            ' ',
            showPasswordReset && (React.createElement(ForgotPasswordButton, { onClick: function () { return handleForgotPassword(); } },
                React.createElement(ForgotPasswordErrorText, { color: "link" }, "Click here to reset your password")))));
    };
    return (React.createElement(Container, null,
        !isMobile && (React.createElement(Background, null,
            React.createElement(BackgroundTop, null,
                React.createElement(Logomark, null)),
            React.createElement(BackgroundBottom, null))),
        React.createElement(IntercomButton, null),
        React.createElement(TopBar, null, !isMobile && React.createElement(LogoWhite, null)),
        React.createElement(Wrapper, null,
            React.createElement(Card, { direction: "horizontal" },
                React.createElement(StyledCardHeader, null,
                    React.createElement(Heading, null,
                        React.createElement(Typography, { variant: "titleLarge" }, "Welcome to"),
                        React.createElement(Logo, null)),
                    React.createElement(SubheaderText, { variant: isMobile ? 'bodySmall' : 'headlineSmall', color: "alt" }, "Your all-in-one destination to order Jeuveau, reward patients, manage staff & more")),
                React.createElement(CardBody, { alignItems: "center" },
                    React.createElement(LoginInstructions, null,
                        React.createElement(Icon, { name: "info", color: theme.colors.text.link }),
                        React.createElement(Typography, { variant: "bodySmall", color: "link" }, "Log in using your Evolus Practice or Rewards App credentials")),
                    React.createElement(Input, { label: "Email", fullWidth: true, value: emailAddress, onChange: function (e) { return setEmailAddress(e.target.value); }, onKeyPress: handleEnter, name: "email", type: "text", disabled: isLoading, "data-automation-id": "sign-in-email" }),
                    React.createElement(Input, { label: "Password", fullWidth: true, value: password, onChange: function (e) { return setPassword(e.target.value); }, onKeyPress: handleEnter, name: "password", type: showPassword ? 'text' : 'password', hasError: !!error, disabled: isLoading, footerLabel: renderLoginError(), "data-automation-id": "sign-in-password", errorAutomationId: "sign-in-error", rightIcon: renderRightIcon(), onRightIconClicked: function () { return setShowPassword(!showPassword); } }),
                    React.createElement(Row, null,
                        React.createElement(Column, { xs: 6, sm: 6, md: 6, lg: 6 }),
                        React.createElement(Column, { xs: 6, sm: 6, md: 6, lg: 6, justifyContent: "flex-end" },
                            React.createElement(ForgotPasswordButton, { onClick: function () { return handleForgotPassword(); }, "data-automation-id": "sign-in-forgot-password" },
                                React.createElement(Typography, { variant: "headlineSmall", color: "link" }, "Forgot Password?")))),
                    React.createElement(StyledButton, { size: "large", fullWidth: true, onClick: onSubmit, disabled: isLoading, "data-automation-id": "sign-in-submit" }, "Login"),
                    React.createElement(CardFooter, null,
                        React.createElement(Row, { flexDirection: "column", alignItems: "center" },
                            React.createElement(FooterText, { variant: "headlineSmall", color: "alt5" }, "New to Evolus?"),
                            React.createElement(CreateLink, { href: "#", "data-automation-id": "sign-in-create-new-account" }, "Create an Account"))))))));
};
export default SignInView;
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  min-height: 100%;\n  display: flex;\n  flex-direction: column;\n  background: ", ";\n"], ["\n  min-height: 100%;\n  display: flex;\n  flex-direction: column;\n  background: ", ";\n"])), function (props) { return props.theme.colors.bg.base; });
var Background = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100vw;\n  height: 100vh;\n  position: absolute;\n  top: 0;\n  left: 0;\n"], ["\n  width: 100vw;\n  height: 100vh;\n  position: absolute;\n  top: 0;\n  left: 0;\n"])));
var BackgroundBottom = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: ", ";\n  height: 50vh;\n"], ["\n  background: ", ";\n  height: 50vh;\n"])), function (props) { return props.theme.colors.white.white500; });
var BackgroundTop = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background: ", ";\n  height: 50vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  overflow: hidden;\n"], ["\n  background: ", ";\n  height: 50vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  overflow: hidden;\n"])), function (props) { return props.theme.colors.coral.coral500; });
var Logomark = styled.img.attrs({
    src: LogomarkImg,
    alt: 'logomark',
})(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  max-width: 100%;\n  height: 100%;\n"], ["\n  max-width: 100%;\n  height: 100%;\n"])));
var LogoWhite = styled.img.attrs({
    src: WhiteLogo,
    alt: 'white-logomark',
})(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 100%;\n  max-width: 114px;\n"], ["\n  width: 100%;\n  max-width: 114px;\n"])));
var Logo = styled.img.attrs({
    src: LogoImage,
    alt: 'logo',
})(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  height: 26px;\n  margin-left: 8px;\n  opacity: 1;\n  margin-top: 8px;\n"], ["\n  height: 26px;\n  margin-left: 8px;\n  opacity: 1;\n  margin-top: 8px;\n"])));
var LoginInstructions = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  width: 100%;\n  gap: 8px;\n  margin-bottom: 5px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  width: 100%;\n  gap: 8px;\n  margin-bottom: 5px;\n"])));
var TopBar = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  padding: 48px 64px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  z-index: 1;\n  @media only screen and (max-width: ", "px) {\n    background: no-repeat center/80% url(", "),\n      ", ";\n  }\n"], ["\n  padding: 48px 64px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  z-index: 1;\n  @media only screen and (max-width: ", "px) {\n    background: no-repeat center/80% url(", "),\n      ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
}, LogomarkImg, function (props) { return props.theme.colors.coral.coral500; });
var Wrapper = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  @media only screen and (max-width: ", "px) {\n    background: ", ";\n    flex: 1;\n    flex-direction: column;\n    justify-content: flex-start;\n    & > div {\n      flex: 1;\n      flex-direction: column;\n      & > div:last-of-type {\n        flex: 1;\n      }\n    }\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  @media only screen and (max-width: ", "px) {\n    background: ", ";\n    flex: 1;\n    flex-direction: column;\n    justify-content: flex-start;\n    & > div {\n      flex: 1;\n      flex-direction: column;\n      & > div:last-of-type {\n        flex: 1;\n      }\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
}, function (props) { return props.theme.colors.coral.coral500; });
var Heading = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  margin-bottom: 8px;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  margin-bottom: 8px;\n"])));
var StyledCardHeader = styled(CardHeader)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  padding: 40px 64px 28px 64px;\n  @media only screen and (max-width: ", "px) {\n    padding: 32px 32px 23px 32px;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  padding: 40px 64px 28px 64px;\n  @media only screen and (max-width: ", "px) {\n    padding: 32px 32px 23px 32px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var FooterText = styled(Typography)(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  margin-bottom: ", "px;\n"], ["\n  margin-bottom: ", "px;\n"])), function (props) { return props.theme.space[2]; });
var ForgotPasswordButton = styled.button(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  all: unset;\n  cursor: pointer;\n"], ["\n  all: unset;\n  cursor: pointer;\n"])));
var StyledButton = styled(Button)(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  margin: 32px 0;\n  @media only screen and (max-width: ", "px) {\n    margin-top: auto;\n  }\n"], ["\n  margin: 32px 0;\n  @media only screen and (max-width: ", "px) {\n    margin-top: auto;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var CreateLink = styled.a(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  text-decoration: none;\n  color: red;\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 24px;\n  &:hover {\n    text-decoration: underline;\n    color: ", ";\n  }\n  &:focus {\n    border: 1px solid ", ";\n    box-shadow: 0 0 0 3px ", ";\n  }\n  &:visited {\n    text-decoration: none;\n    color: ", ";\n  }\n"], ["\n  text-decoration: none;\n  color: red;\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 24px;\n  &:hover {\n    text-decoration: underline;\n    color: ", ";\n  }\n  &:focus {\n    border: 1px solid ", ";\n    box-shadow: 0 0 0 3px ", ";\n  }\n  &:visited {\n    text-decoration: none;\n    color: ", ";\n  }\n"])), function (props) { return props.theme.colors.coral.coral500; }, function (props) { return props.theme.colors.aqua.aqua300; }, function (props) { return props.theme.colors.white.white500; }, function (props) { return props.theme.colors.coral.coral500; });
var SubheaderText = styled(Typography)(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  margin: 0 100px;\n  text-align: center;\n  @media only screen and (max-width: ", "px) {\n    margin: 0 32px;\n  }\n"], ["\n  margin: 0 100px;\n  text-align: center;\n  @media only screen and (max-width: ", "px) {\n    margin: 0 32px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var ForgotPasswordErrorText = styled(Typography)(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  font-size: inherit;\n  color: inherit;\n  line-height: inherit;\n  text-decoration: underline;\n"], ["\n  font-size: inherit;\n  color: inherit;\n  line-height: inherit;\n  text-decoration: underline;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18;

import React from 'react';
import View from './View';
import { useUser } from "../../../../../context";
var TransactionInformationContainer = function (_a) {
    var _b, _c;
    var singleLine = _a.singleLine;
    var account = useUser().account;
    var transactionHistorySummary = {
        totalFreeVialsAvailable: account === null || account === void 0 ? void 0 : account.availableFreeVials,
        totalRewardPointsEarned: (_b = account === null || account === void 0 ? void 0 : account.rewardPointsEarned) !== null && _b !== void 0 ? _b : 0,
        totalRewardsGiven: 12,
        totalVialsEarned: (account === null || account === void 0 ? void 0 : account.availableFreeVials) + 23,
        totalVialsRedeemed: (_c = account === null || account === void 0 ? void 0 : account.rewardVialsRedeemed) !== null && _c !== void 0 ? _c : 0,
    };
    return (React.createElement(View, { isLoading: false, transactionSummary: transactionHistorySummary, singleLine: singleLine }));
};
export default TransactionInformationContainer;

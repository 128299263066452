var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled, { css } from 'styled-components';
import { Typography } from 'designSystem';
import { addMobileStyles } from 'utils/addMobileStyles';
export var TwentyUnitsFreeBanner = function () {
    return (React.createElement(Container, null,
        React.createElement("div", null,
            React.createElement(Typography, { display: "block", color: "link", align: "center" }, "20 UNITS FREE")),
        React.createElement("div", null,
            React.createElement(Typography, null, "SPECIAL OFFER"),
            React.createElement(Typography, { variant: "headline" }, "This new patient qualifies for up to 20 units free today!"))));
};
var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: 32px;\n  border: 2px solid ", ";\n  background-color: #fdf2f2;\n  display: flex;\n  justify-content: space-between;\n  padding: 32px;\n  gap: 32px;\n  & > div:first-of-type > span {\n    font-size: 63px;\n    line-height: 53px;\n    letter-spacing: -2.4382100105285645px;\n    font-weight: 700;\n  }\n\n  & > div:last-of-type {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    gap: 16px;\n    & > span:first-of-type {\n      font-size: 18px;\n      font-weight: 700;\n      line-height: 24px;\n    }\n  }\n  ", "\n"], ["\n  margin-top: 32px;\n  border: 2px solid ", ";\n  background-color: #fdf2f2;\n  display: flex;\n  justify-content: space-between;\n  padding: 32px;\n  gap: 32px;\n  & > div:first-of-type > span {\n    font-size: 63px;\n    line-height: 53px;\n    letter-spacing: -2.4382100105285645px;\n    font-weight: 700;\n  }\n\n  & > div:last-of-type {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    gap: 16px;\n    & > span:first-of-type {\n      font-size: 18px;\n      font-weight: 700;\n      line-height: 24px;\n    }\n  }\n  ", "\n"])), function (props) { return props.theme.colors.coral.coral50; }, addMobileStyles(css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    flex-direction: column;\n    & > div:first-of-type > span {\n      font-size: 58px;\n      line-height: 49px;\n      letter-spacing: -2.25065541267395px;\n    }\n    gap: 24px;\n  "], ["\n    flex-direction: column;\n    & > div:first-of-type > span {\n      font-size: 58px;\n      line-height: 49px;\n      letter-spacing: -2.25065541267395px;\n    }\n    gap: 24px;\n  "])))));
var templateObject_1, templateObject_2;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { useModal } from 'context/ModalContext';
import { Icon, Button } from 'designSystem';
import React from 'react';
import styled, { createGlobalStyle, useTheme } from 'styled-components';
var modalSizeDictionary = {
    small: '40%',
    medium: '50%',
    large: '75%',
};
export var Modal = function (_a) {
    var children = _a.children, _b = _a.size, size = _b === void 0 ? 'medium' : _b, _c = _a.backdropColor, backdropColor = _c === void 0 ? 'rgba(18, 44, 41, 0.8)' : _c, onCloseOverride = _a.onCloseOverride, transparent = _a.transparent, _d = _a.showClose, showClose = _d === void 0 ? true : _d, isHigherModal = _a.isHigherModal, _e = _a.scrollable, scrollable = _e === void 0 ? false : _e;
    var closeModal = useModal().closeModal;
    var theme = useTheme();
    return (React.createElement(React.Fragment, null,
        React.createElement(GlobalStyle, null),
        React.createElement(ModalWrapper, { backdropColor: isHigherModal ? backdropColor : 'unset', size: size, scrollable: scrollable },
            React.createElement(ModalDialog, { size: size },
                React.createElement(ModalContent, { transparent: transparent },
                    showClose && (React.createElement(ModalHeader, null,
                        React.createElement(Button, { variant: "tertiary", onClick: function () {
                                return onCloseOverride ? onCloseOverride() : closeModal();
                            } },
                            React.createElement(Icon, { name: "close", color: theme.colors.text.alt4, size: 24 })))),
                    children))),
        React.createElement(Backdrop, { backdropColor: backdropColor })));
};
var GlobalStyle = createGlobalStyle(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  body {\n    overflow: hidden !important;\n  }\n"], ["\n  body {\n    overflow: hidden !important;\n  }\n"])));
var Backdrop = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  position: fixed;\n  justify-content: center;\n  align-items: center;\n  width: 100vw;\n  min-height: 100vh;\n  background-color: ", ";\n  top: 0;\n  left: 0;\n  z-index: 9;\n"], ["\n  display: flex;\n  position: fixed;\n  justify-content: center;\n  align-items: center;\n  width: 100vw;\n  min-height: 100vh;\n  background-color: ", ";\n  top: 0;\n  left: 0;\n  z-index: 9;\n"])), function (props) { return props.backdropColor; });
var ModalWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  z-index: 1050;\n  outline: 0;\n  width: 100%;\n  ", "\n  background-color: ", ";\n  @media only screen and (max-width: ", "px) {\n    overflow: auto;\n  }\n  overflow: auto;\n"], ["\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  z-index: 1050;\n  outline: 0;\n  width: 100%;\n  ", "\n  background-color: ", ";\n  @media only screen and (max-width: ", "px) {\n    overflow: auto;\n  }\n  overflow: auto;\n"])), function (props) { return props.scrollable && 'overflow: auto;'; }, function (props) { return props.backdropColor; }, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var ModalDialog = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: relative;\n  width: ", ";\n  transform: translate(0, 0);\n  margin: 32px auto;\n  @media only screen and (max-width: ", "px) {\n    max-width: 100vh;\n    width: 100vw;\n    height: 100%;\n    margin: 0;\n  }\n"], ["\n  position: relative;\n  width: ", ";\n  transform: translate(0, 0);\n  margin: 32px auto;\n  @media only screen and (max-width: ", "px) {\n    max-width: 100vh;\n    width: 100vw;\n    height: 100%;\n    margin: 0;\n  }\n"])), function (props) { return modalSizeDictionary[(props === null || props === void 0 ? void 0 : props.size) || 'medium']; }, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var ModalHeader = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: absolute;\n  right: 10px;\n  top: 10px;\n"], ["\n  position: absolute;\n  right: 10px;\n  top: 10px;\n"])));
var ModalContent = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  background-color: ", ";\n  padding: 48px;\n\n  width: 100%;\n  justify-content: center;\n  align-items: center;\n  position: relative;\n  flex-direction: column;\n  z-index: 99;\n\n  @media only screen and (max-width: ", "px) {\n    width: 100vw;\n    min-height: 100%;\n    padding: 32px;\n    justify-content: flex-start;\n    align-items: flex-start;\n  }\n"], ["\n  display: flex;\n  background-color: ", ";\n  padding: 48px;\n\n  width: 100%;\n  justify-content: center;\n  align-items: center;\n  position: relative;\n  flex-direction: column;\n  z-index: 99;\n\n  @media only screen and (max-width: ", "px) {\n    width: 100vw;\n    min-height: 100%;\n    padding: 32px;\n    justify-content: flex-start;\n    align-items: flex-start;\n  }\n"])), function (props) { return (props.transparent ? 'transparent' : 'white'); }, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;

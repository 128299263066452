var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled, { css } from 'styled-components';
import { Typography } from '../Typography/Typography';
function getSize(size, type) {
    if (size === void 0) { size = 'medium'; }
    if (type === void 0) { type = 'primary'; }
    var border = type === 'secondary' || type === 'outlined';
    if (size === 'large')
        return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      padding: ", ";\n      font-size: 12px;\n      line-height: 16px;\n      font-weight: ", ";\n    "], ["\n      padding: ", ";\n      font-size: 12px;\n      line-height: 16px;\n      font-weight: ", ";\n    "])), border ? '7px 11px' : '8px 12px', function (props) { return props.theme.typography.weight.regular; });
    if (size === 'medium')
        return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      padding: ", ";\n      font-size: 10px;\n      line-height: 12px;\n      font-weight: ", ";\n    "], ["\n      padding: ", ";\n      font-size: 10px;\n      line-height: 12px;\n      font-weight: ", ";\n    "])), border ? '5px 7px' : '6px 8px', function (props) { return props.theme.typography.weight.regular; });
    return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    padding: ", ";\n    font-size: 10px;\n    line-height: 12px;\n    font-weight: ", ";\n  "], ["\n    padding: ", ";\n    font-size: 10px;\n    line-height: 12px;\n    font-weight: ", ";\n  "])), border ? '3px 7px' : '4px 8px', function (props) { return props.theme.typography.weight.regular; });
}
function getType(type) {
    if (type === void 0) { type = 'primary'; }
    if (type === 'primary') {
        return css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      border: none !important;\n    "], ["\n      border: none !important;\n    "])));
    }
    if (type === 'secondary') {
        return css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      border-style: solid !important;\n      border-width: 1px !important;\n    "], ["\n      border-style: solid !important;\n      border-width: 1px !important;\n    "])));
    }
    if (type === 'outlined') {
        return css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n      border-style: solid !important;\n      border-width: 1px !important;\n      background-color: transparent !important;\n    "], ["\n      border-style: solid !important;\n      border-width: 1px !important;\n      background-color: transparent !important;\n    "])));
    }
    return css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    border: none !important;\n    background-color: transparent !important;\n  "], ["\n    border: none !important;\n    background-color: transparent !important;\n  "])));
}
function getVariant(variant) {
    if (variant === void 0) { variant = 'base'; }
    if (variant === 'danger') {
        return css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n      background-color: ", ";\n      color: ", ";\n      border-color: ", ";\n    "], ["\n      background-color: ", ";\n      color: ", ";\n      border-color: ", ";\n    "])), function (props) { return props.theme.colors.bg.error; }, function (props) { return props.theme.colors.coral.coral500; }, function (props) { return props.theme.colors.coral.coral100; });
    }
    if (variant === 'success') {
        return css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n      background-color: ", ";\n      color: ", ";\n      border-color: ", ";\n    "], ["\n      background-color: ", ";\n      color: ", ";\n      border-color: ", ";\n    "])), function (props) { return props.theme.colors.bg.success2; }, function (props) { return props.theme.colors.aqua.aqua900; }, function (props) { return props.theme.colors.aqua.aqua100; });
    }
    if (variant === 'alert') {
        return css(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n      background-color: ", ";\n      color: ", ";\n      border-color: ", ";\n    "], ["\n      background-color: ", ";\n      color: ", ";\n      border-color: ", ";\n    "])), function (props) { return props.theme.colors.bg.alert; }, function (props) { return props.theme.colors.orange.orange600; }, function (props) { return props.theme.colors.orange.orange100; });
    }
    if (variant === 'alt') {
        return css(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n      background-color: ", ";\n      color: ", ";\n      border-color: ", ";\n    "], ["\n      background-color: ", ";\n      color: ", ";\n      border-color: ", ";\n    "])), function (props) { return props.theme.colors.bg.alt3; }, function (props) { return props.theme.colors.text.alt; }, function (props) { return props.theme.colors.border.base; });
    }
    if (variant === 'alt3') {
        return css(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n      background-color: #dde8ee;\n      color: ", ";\n      border-color: ", ";\n    "], ["\n      background-color: #dde8ee;\n      color: ", ";\n      border-color: ", ";\n    "])), function (props) { return props.theme.colors.text.base; }, function (props) { return props.theme.colors.white.white600; });
    }
    return css(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n    background-color: ", ";\n    color: ", ";\n    border-color: ", ";\n  "], ["\n    background-color: ", ";\n    color: ", ";\n    border-color: ", ";\n  "])), function (props) { return props.theme.colors.bg.base; }, function (props) { return props.theme.colors.white.white900; }, function (props) { return props.theme.colors.white.white600; });
}
var TagContainer = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  display: flex;\n"], ["\n  display: flex;\n"])));
var TagContent = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  display: flex;\n  align-self: center;\n  justify-content: center;\n  align-items: center;\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"], ["\n  display: flex;\n  align-self: center;\n  justify-content: center;\n  align-items: center;\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"])), function (props) { return getSize(props.size, props.type); }, function (props) { return getVariant(props.variant); }, function (props) { return getType(props.type); }, function (props) { return props.width && "width: ".concat(props.width, "px;"); }, function (props) {
    return (props === null || props === void 0 ? void 0 : props.badgeColor) && "background-color: ".concat(props.badgeColor, "; !important");
});
var TagTextContainer = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
export var Tag = function (_a) {
    var startIcon = _a.startIcon, endIcon = _a.endIcon, text = _a.text, size = _a.size, variant = _a.variant, subtext = _a.subtext, type = _a.type, width = _a.width, textColor = _a.textColor, badgeColor = _a.badgeColor, rest = __rest(_a, ["startIcon", "endIcon", "text", "size", "variant", "subtext", "type", "width", "textColor", "badgeColor"]);
    return (React.createElement(TagContainer, __assign({}, rest),
        React.createElement(TagContent, { size: size, variant: variant, type: type, width: width, badgeColor: badgeColor },
            startIcon,
            React.createElement(TagTextContainer, null,
                React.createElement(Typography, { variant: size === 'small' ? 'small' : 'caption', color: "inherit", colorOverride: textColor }, text),
                subtext && (React.createElement(Typography, { variant: "inherit", color: "inherit" }, subtext))),
            endIcon)));
};
export default Tag;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16;

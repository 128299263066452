var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Icon, Typography } from 'designSystem';
import { useNavigate, useOutletContext } from 'react-router';
import { UserInfoField } from './components/UserInfoField';
import { useModal, useUser } from 'context';
import ModalTypeEnum from 'types/enums/ModalTypeEnum';
import { useIsMounted } from 'hooks/useIsMounted';
import LaunchGraphic from 'assets/launch-graphic.svg';
import { PrivilegesWidget } from '../PrivilegesWidget';
import { useScreenSizing } from '../../../../../../hooks';
import { MenuButton } from 'components/MenuButton';
import { AssignFacilities } from '../AssignFacilities/View';
export var SingleUser = function () {
    var context = useOutletContext();
    var navigate = useNavigate();
    var isMobile = useScreenSizing().isMobile;
    var account = useUser().account;
    var _a = useModal(), openModal = _a.openModal, closeModal = _a.closeModal;
    var isMounted = useIsMounted();
    var _b = __read(useState(false), 2), userRemoved = _b[0], setUserRemoved = _b[1];
    var backToUsers = function () { return navigate('/settings/user-permissions'); };
    var handleRemove = function () {
        openModal(ModalTypeEnum.REMOVE_USER, {
            userId: userId,
            accountId: account.id,
            onRemove: function () {
                closeModal();
                if (isMounted) {
                    setUserRemoved(true);
                }
            },
        });
    };
    if (!context.selectedUser) {
        return null;
    }
    var _c = context.selectedUser, user = _c.user, userId = _c.userId, role = _c.role, primaryJob = _c.primaryJob;
    var renderContent = function () {
        if (userRemoved) {
            return (React.createElement(InnerContainer, null,
                React.createElement(RemovedContainer, null,
                    React.createElement("div", null,
                        React.createElement(Typography, { mb: 16, variant: "titleLarge", display: "block" }, "Notification Sent"),
                        React.createElement(Typography, { mb: 32, display: "block", color: "alt" }, "Notification of access change sent to:"),
                        React.createElement(Typography, { variant: "title", display: "block" }, user.email)),
                    React.createElement(UserRemovedImage, { src: LaunchGraphic })),
                React.createElement(Button, { onClick: function () {
                        backToUsers();
                        context.onUpdate();
                    }, size: "xlarge", variant: "outlined" }, "Back to Users")));
        }
        var isMainContact = (account === null || account === void 0 ? void 0 : account.mainContactUserId) === user.userId;
        return (React.createElement(React.Fragment, null,
            React.createElement(InnerContainer, null,
                React.createElement(TopContainer, null,
                    React.createElement(Button, { variant: isMobile ? 'tertiary' : 'outlined', noPadding: isMobile, startIcon: React.createElement(Icon, { color: "inherit", name: "chevron-left" }), onClick: backToUsers }, "Back to User Permissions"),
                    isMobile && (React.createElement(MenuButton, { menuItems: [
                            React.createElement(MenuItemButton, { fullWidth: true, disabled: isMainContact, variant: "quaternary", onClick: handleRemove }, "Remove"),
                        ] },
                        React.createElement(Icon, { name: "kebab", size: 24 })))),
                React.createElement(HeaderContainer, null,
                    React.createElement(Typography, { variant: "titleLarge" }, !user.firstName
                        ? user.email
                        : "".concat(user.firstName, " ").concat(user.lastName)),
                    !isMobile && (React.createElement(Button, { disabled: isMainContact, onClick: handleRemove, variant: "outlined" }, "Remove User"))),
                React.createElement(UserInfoContainer, null,
                    React.createElement(UserInfoField, { label: "First Name", value: user.firstName }),
                    React.createElement(UserInfoField, { label: "Last Name", value: user.lastName }),
                    React.createElement(UserInfoField, { label: "Email Address", value: user.email }),
                    React.createElement(UserInfoField, { label: "Phone Number", value: user.phoneNumber }),
                    React.createElement(UserInfoField, { label: "Primary Job", value: primaryJob }))),
            React.createElement(PrivilegesWidget, { role: role }),
            role === 'CUSTOM' && (React.createElement(FaciltyContainer, null,
                React.createElement(AssignFacilities, { facilities: context.facilities, selectedFacilities: context.selectedUser.facilities, IsSingleUserEdit: true, userId: user.userId, accountId: account.id, onUpdate: context.onUpdate })))));
    };
    return React.createElement(OuterContainer, null, renderContent());
};
var OuterContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  display: block;\n  box-sizing: border-box;\n  padding: 48px 48px 60px;\n  margin-top: 20px;\n  flex: 1;\n  @media only screen and (max-width: ", "px) {\n    padding: 32px;\n    & > div:first-of-type {\n      margin-bottom: 32px;\n    }\n  }\n\n  & > div:first-of-type {\n    margin-bottom: 48px;\n  }\n"], ["\n  ", ";\n  display: block;\n  box-sizing: border-box;\n  padding: 48px 48px 60px;\n  margin-top: 20px;\n  flex: 1;\n  @media only screen and (max-width: ", "px) {\n    padding: 32px;\n    & > div:first-of-type {\n      margin-bottom: 32px;\n    }\n  }\n\n  & > div:first-of-type {\n    margin-bottom: 48px;\n  }\n"])), function (props) { return props.theme.card.default; }, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var InnerContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 48px;\n  ", ";\n  @media only screen and (max-width: ", "px) {\n    border: none;\n    padding: 0;\n  }\n"], ["\n  padding: 48px;\n  ", ";\n  @media only screen and (max-width: ", "px) {\n    border: none;\n    padding: 0;\n  }\n"])), function (props) { return props.theme.borders.base; }, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var HeaderContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin: 32px 0 44px;\n  @media only screen and (max-width: ", "px) {\n    margin: 24px 0;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin: 32px 0 44px;\n  @media only screen and (max-width: ", "px) {\n    margin: 24px 0;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var UserInfoContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 48px;\n  width: 100%;\n  @media only screen and (max-width: ", "px) {\n    gap: 8px;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 48px;\n  width: 100%;\n  @media only screen and (max-width: ", "px) {\n    gap: 8px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var UserRemovedImage = styled.img(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n  max-width: 266px;\n"], ["\n  width: 100%;\n  max-width: 266px;\n"])));
var RemovedContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 18px;\n  & > div:first-of-type {\n    flex: 1;\n    flex-direction: column;\n    justify-content: flex-start;\n  }\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column-reverse;\n    margin-bottom: 40px;\n  }\n"], ["\n  display: flex;\n  flex: 1;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 18px;\n  & > div:first-of-type {\n    flex: 1;\n    flex-direction: column;\n    justify-content: flex-start;\n  }\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column-reverse;\n    margin-bottom: 40px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var MenuItemButton = styled(Button)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  padding: 16px;\n  & > span {\n    width: 100%;\n    text-align: left;\n    font-weight: 400;\n  }\n"], ["\n  padding: 16px;\n  & > span {\n    width: 100%;\n    text-align: left;\n    font-weight: 400;\n  }\n"])));
var TopContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  @media only screen and (max-width: ", "px) {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n  }\n"], ["\n  @media only screen and (max-width: ", "px) {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var FaciltyContainer = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  margin-top: 48px;\n"], ["\n  margin-top: 48px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;

import React from 'react';
export var Cookie = function (_a) {
    var color = _a.color;
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 48 48", width: "48", height: "48", fill: "none" },
        React.createElement("g", { stroke: color, strokeWidth: 3, clipPath: "url(#cookie-svg-clip)" },
            React.createElement("path", { strokeLinecap: "round", strokeLinejoin: "round", d: "M41.98 17.99a7.493 7.493 0 0 1-7.498-7.54 7.432 7.432 0 0 1-8.06.644 7.494 7.494 0 0 1-3.334-9.56 22.476 22.476 0 1 0 22.316 15.6 7.408 7.408 0 0 1-3.424.856Z" }),
            React.createElement("path", { strokeLinecap: "round", strokeLinejoin: "round", d: "M14.946 25.478a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM28.446 37.478a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" }),
            React.createElement("path", { d: "M14.2 34.478a.75.75 0 1 1 0-1.5M14.2 34.478a.75.75 0 1 0 0-1.5M26.196 20.978a.75.75 0 1 1 0-1.5M26.196 20.978a.75.75 0 1 0 0-1.5M38.196 26.98a.75.75 0 1 1 0-1.5M38.196 26.98a.75.75 0 1 0 0-1.5" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "cookie-svg-clip" },
                React.createElement("path", { fill: "#fff", d: "M0 0h48v48H0z" })))));
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Button, Desktop, Icon, LoadingBubbles, Mobile, Typography, } from 'designSystem';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { ConsumerRewardOptInStatus, } from 'types/codegen/hooks';
import { useModal } from 'context';
import { format, add, sub, startOfYear, endOfYear, startOfMonth, endOfMonth, isSameDay, isBefore, differenceInMonths, } from 'date-fns';
import ModalTypeEnum from 'types/enums/ModalTypeEnum';
import { stackedCardStyles } from '../../../../components/StackedMobileCard';
import { DesktopTable } from './components/DesktopTable';
import { StatementListCard } from './components/StatementListCard';
import { TableFilters } from './components/TableFilters';
var getMonthsByMaxDate = function (date, onlyYear, year) {
    if (onlyYear === void 0) { onlyYear = true; }
    var months = [];
    var maxDate = new Date(date);
    var dateStart = onlyYear && year
        ? maxDate.getFullYear() === year
            ? add(maxDate, { years: 1 })
            : startOfYear(new Date(year, 0))
        : add(date, { months: 1 });
    var now = new Date();
    var dateEnd = onlyYear && year
        ? now.getFullYear() === year
            ? sub(now, { months: 1 })
            : endOfYear(new Date(year, 1))
        : sub(now, { months: 1 });
    var dateStartMonth = startOfMonth(dateStart);
    while (differenceInMonths(endOfMonth(dateEnd), dateStartMonth) >= 0) {
        months.push(endOfMonth(dateEnd));
        dateEnd = sub(dateEnd, { months: 1 });
    }
    return months;
};
var getYearsByMaxDate = function (date) {
    var now = new Date().getFullYear();
    var yearDiff = now - new Date(date).getFullYear();
    return Array(now - (now - yearDiff - 1))
        .fill('')
        .map(function (_, idx) { return now - idx; });
};
var RewardsStatementsView = function (_a) {
    var _b, _c, _e, _f, _g;
    var optInHistory = _a.optInHistory, facilitiesOptions = _a.facilities, injectorOptions = _a.injectors, activityTypes = _a.types, onGetCSVStatement = _a.onGetCSVStatement, csvEntry = _a.csvEntry, isLoading = _a.isLoading, gettingCSV = _a.gettingCSV;
    var openModal = useModal().openModal;
    var _h = __read(useState(false), 2), isFiltered = _h[0], setIsFiltered = _h[1];
    var _j = __read(useState([]), 2), facilities = _j[0], setFacilities = _j[1];
    var _k = __read(useState([]), 2), types = _k[0], setTypes = _k[1];
    var _l = __read(useState([]), 2), injectors = _l[0], setInjectors = _l[1];
    var _m = __read(useState(null), 2), dateRange = _m[0], setDateRange = _m[1];
    var _o = __read(useState([]), 1), years = _o[0];
    var _p = __read(useState([]), 2), setYearsList = _p[1];
    var _q = __read(useState([]), 2), monthsList = _q[0], setMonthsList = _q[1];
    var _r = __read(useState(0), 2), page = _r[0], setPage = _r[1];
    var todaysDate = useMemo(function () {
        if (!dateRange)
            return true;
        return (isSameDay(dateRange[0], new Date()) && isSameDay(dateRange[1], new Date()));
    }, [dateRange]);
    var optInDate = useMemo(function () {
        var _a, _b;
        var optInHistoryRecord = (_b = (_a = optInHistory
            .filter(function (e) { return e.status === ConsumerRewardOptInStatus.OptedIn; })
            .sort(function (a, b) {
            return new Date(a.updatedAt).valueOf() - new Date(b.updatedAt).valueOf();
        })[0]) === null || _a === void 0 ? void 0 : _a.updatedAt) !== null && _b !== void 0 ? _b : null;
        if (optInHistoryRecord) {
            return new Date(optInHistoryRecord);
        }
        else {
            return new Date();
        }
    }, [optInHistory]);
    var hasCSVEntries = useMemo(function () {
        var _a;
        var splittedString = (_a = csvEntry === null || csvEntry === void 0 ? void 0 : csvEntry.split('\n')) !== null && _a !== void 0 ? _a : [];
        return (splittedString.length > 2 ||
            (splittedString.length === 2 && splittedString[1] !== ''));
    }, [csvEntry]);
    var data = useMemo(function () {
        var _a, _b, _c;
        var startDate = (dateRange === null || dateRange === void 0 ? void 0 : dateRange[0])
            ? new Date(dateRange[0])
            : startOfMonth(new Date());
        var endDate = (dateRange === null || dateRange === void 0 ? void 0 : dateRange[1])
            ? new Date(dateRange[1])
            : endOfMonth(new Date());
        if (isFiltered && hasCSVEntries) {
            return [
                {
                    date: "".concat(format(todaysDate ? optInDate : startDate, 'MMM dd, yyyy'), " - ").concat(format(todaysDate ? new Date() : endDate, 'MMM dd, yyyy')),
                    name: 'Statement',
                    injectorIds: (_a = injectors === null || injectors === void 0 ? void 0 : injectors.map(function (_a) {
                        var value = _a.value;
                        return value;
                    })) !== null && _a !== void 0 ? _a : null,
                    activityFilters: (_b = types === null || types === void 0 ? void 0 : types.map(function (_a) {
                        var value = _a.value;
                        return value;
                    })) !== null && _b !== void 0 ? _b : null,
                    facilityIds: (_c = facilities === null || facilities === void 0 ? void 0 : facilities.map(function (_a) {
                        var value = _a.value;
                        return value;
                    })) !== null && _c !== void 0 ? _c : null,
                    startDate: todaysDate ? optInDate : startDate,
                    endDate: todaysDate ? new Date() : endDate,
                },
            ];
        }
        if (isFiltered && !hasCSVEntries) {
            return [];
        }
        return monthsList.map(function (m) {
            var _a, _b, _c;
            return ({
                date: m,
                name: format(new Date(m), "MMMM 'Statement'"),
                injectorIds: (_a = injectors === null || injectors === void 0 ? void 0 : injectors.map(function (_a) {
                    var value = _a.value;
                    return value;
                })) !== null && _a !== void 0 ? _a : null,
                activityFilters: (_b = types === null || types === void 0 ? void 0 : types.map(function (_a) {
                    var value = _a.value;
                    return value;
                })) !== null && _b !== void 0 ? _b : null,
                facilityIds: (_c = facilities === null || facilities === void 0 ? void 0 : facilities.map(function (_a) {
                    var value = _a.value;
                    return value;
                })) !== null && _c !== void 0 ? _c : null,
                startDate: todaysDate ? optInDate : startDate,
                endDate: todaysDate ? new Date() : endDate,
            });
        });
    }, [monthsList, isFiltered, hasCSVEntries]);
    useEffect(function () {
        setMonthsList(getMonthsByMaxDate(optInDate, false));
        setYearsList(getYearsByMaxDate(optInDate));
    }, [optInDate]);
    var onSearch = function () {
        var _a, _b, _c, _e, _f;
        var date = new Date();
        if (!(facilities === null || facilities === void 0 ? void 0 : facilities.length)) {
            if (!(years === null || years === void 0 ? void 0 : years.length)) {
                setMonthsList(getMonthsByMaxDate(optInDate, false));
            }
            else {
                setMonthsList(getMonthsByMaxDate(optInDate, true, years[0].value));
            }
        }
        else {
            date = new Date(facilities[0].createdAt);
            if (isBefore(date, optInDate)) {
                date = optInDate;
            }
            if (!(years === null || years === void 0 ? void 0 : years.length)) {
                setMonthsList(getMonthsByMaxDate(date, false));
            }
            else {
                setMonthsList(getMonthsByMaxDate(date, true, years[0].value));
            }
        }
        var startDate = (_a = dateRange === null || dateRange === void 0 ? void 0 : dateRange[0]) !== null && _a !== void 0 ? _a : startOfMonth(new Date());
        var endDate = (_b = dateRange === null || dateRange === void 0 ? void 0 : dateRange[1]) !== null && _b !== void 0 ? _b : endOfMonth(new Date());
        onGetCSVStatement({
            date: date,
            startDate: todaysDate ? optInDate : startDate,
            endDate: todaysDate ? new Date() : endDate,
            injectorIds: (_c = injectors === null || injectors === void 0 ? void 0 : injectors.map(function (_a) {
                var value = _a.value;
                return value;
            })) !== null && _c !== void 0 ? _c : null,
            activityFilters: (_e = types === null || types === void 0 ? void 0 : types.map(function (_a) {
                var value = _a.value;
                return value;
            })) !== null && _e !== void 0 ? _e : null,
            facilityIds: (_f = facilities === null || facilities === void 0 ? void 0 : facilities.map(function (_a) {
                var value = _a.value;
                return value;
            })) !== null && _f !== void 0 ? _f : null,
        });
    };
    useEffect(function () {
        var isFiltered = facilities.length > 0 ||
            types.length > 0 ||
            injectors.length > 0 ||
            !!dateRange;
        if (isFiltered) {
            setPage(0);
            onSearch();
        }
        setIsFiltered(isFiltered);
    }, [facilities, types, injectors, years, dateRange, setIsFiltered]);
    var paginatedData = useMemo(function () {
        var pag = [];
        if (data.length <= 0) {
            return pag;
        }
        for (var i = 0; i < data.length; i += 8) {
            pag.push(data.slice(i, i + 8));
        }
        return pag;
    }, [data]);
    var startDate = todaysDate
        ? optInDate
        : (_b = dateRange === null || dateRange === void 0 ? void 0 : dateRange[0]) !== null && _b !== void 0 ? _b : startOfMonth(new Date());
    var endDate = todaysDate
        ? new Date()
        : (_c = dateRange === null || dateRange === void 0 ? void 0 : dateRange[1]) !== null && _c !== void 0 ? _c : endOfMonth(new Date());
    var renderFilterChips = facilities.length > 0 || injectors.length > 0 || types.length > 0;
    var loading = isLoading || gettingCSV;
    var renderEmptyResults = !(((_f = (_e = paginatedData[page]) === null || _e === void 0 ? void 0 : _e.length) !== null && _f !== void 0 ? _f : 0) > 0) || loading;
    return (React.createElement(Container, null,
        React.createElement(Desktop, null,
            React.createElement(DesktopTable, { startDate: startDate, endDate: endDate, data: data, facilitiesOptions: facilitiesOptions, injectorOptions: injectorOptions, activityTypes: activityTypes, setFacilities: setFacilities, setTypes: setTypes, setInjectors: setInjectors, setDateRange: setDateRange, dateRange: dateRange, types: types, injectors: injectors, hasCSVEntries: hasCSVEntries, facilities: facilities, isFiltered: isFiltered, isLoading: loading })),
        React.createElement(Mobile, null,
            React.createElement(HeaderContainer, null,
                React.createElement(Typography, { variant: "titleLarge" }, "Account Statements"),
                React.createElement(Button, { variant: "secondary", onClick: function () {
                        openModal(ModalTypeEnum.ACCOUNT_STATEMENTS_FILTERS, {
                            facilitiesOptions: facilitiesOptions,
                            specialistsOptions: injectorOptions,
                            activityTypes: activityTypes,
                            currentTypes: types,
                            currentFacilities: facilities,
                            currentSpecialists: injectors,
                            currentRange: dateRange,
                            onReset: function () {
                                setTypes([]);
                                setDateRange(null);
                                setInjectors([]);
                                setFacilities([]);
                            },
                            onApply: function (_a) {
                                var newFacilities = _a.newFacilities, newSpecialists = _a.newSpecialists, newTypes = _a.newTypes, dateRange = _a.dateRange;
                                setTypes(newTypes);
                                setDateRange(dateRange);
                                setInjectors(newSpecialists);
                                setFacilities(newFacilities);
                            },
                        });
                    } },
                    React.createElement(Icon, { name: "filter" }))),
            React.createElement(Typography, { variant: "body", color: "alt" }, "Download all account statements."),
            renderFilterChips && (React.createElement(TableFilters, { facilities: facilities, injectors: injectors, types: types, setFacilities: setFacilities, setInjectors: setInjectors, setTypes: setTypes })),
            React.createElement(ResultsContainer, null,
                renderEmptyResults && (React.createElement(EmptyResultsContainer, null, loading ? (React.createElement(LoadingBubbles, null)) : (React.createElement(Typography, null, "No Results...")))), (_g = paginatedData[page]) === null || _g === void 0 ? void 0 :
                _g.map(function (d, i) {
                    var _a, _b, _c;
                    return (React.createElement(StatementListCard, { key: i, isFiltered: isFiltered, date: d.date, name: d.name, startDate: startDate, endDate: endDate, activityFilters: (_a = types === null || types === void 0 ? void 0 : types.map(function (_a) {
                            var value = _a.value;
                            return value;
                        })) !== null && _a !== void 0 ? _a : null, facilityIds: (_b = facilities === null || facilities === void 0 ? void 0 : facilities.map(function (_a) {
                            var value = _a.value;
                            return value;
                        })) !== null && _b !== void 0 ? _b : null, injectorIds: (_c = injectors === null || injectors === void 0 ? void 0 : injectors.map(function (_a) {
                            var value = _a.value;
                            return value;
                        })) !== null && _c !== void 0 ? _c : null, showPDFButton: !(isFiltered && hasCSVEntries) }));
                })),
            paginatedData.length > 1 && (React.createElement(PaginationContainer, null,
                React.createElement(Button, { disabled: page <= 0, variant: "tertiary", onClick: function () {
                        if (page > 0) {
                            setPage(page - 1);
                        }
                    } },
                    React.createElement(Icon, { name: "chevron-left" })),
                React.createElement("div", null, paginatedData.map(function (_d, i) {
                    return (React.createElement(PageButton, { key: i, currentPage: i === page, onClick: function () { return setPage(i); } }, i + 1));
                })),
                React.createElement(Button, { disabled: page > paginatedData.length - 1, variant: "tertiary", onClick: function () {
                        if (page < paginatedData.length - 1) {
                            setPage(page + 1);
                        }
                    } },
                    React.createElement(Icon, { name: "chevron-right" })))))));
};
export default RewardsStatementsView;
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 32px 16px 16px;\n  display: flex;\n  @media screen and (max-width: ", "px) {\n    ", ";\n    margin: 0;\n    flex: 1;\n    padding: 32px;\n  }\n"], ["\n  margin: 32px 16px 16px;\n  display: flex;\n  @media screen and (max-width: ", "px) {\n    ", ";\n    margin: 0;\n    flex: 1;\n    padding: 32px;\n  }\n"])), function (props) { return props.theme.breakpoints.md; }, stackedCardStyles);
var HeaderContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-top: 16px;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-top: 16px;\n"])));
var PaginationContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  align-items: center;\n  justify-content: center;\n  margin-bottom: 32px;\n"], ["\n  display: flex;\n  width: 100%;\n  align-items: center;\n  justify-content: center;\n  margin-bottom: 32px;\n"])));
var PageButton = styled(Button)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background: ", ";\n  color: ", ";\n  filter: none;\n  &:active {\n    background: ", ";\n    color: ", ";\n  }\n  &:hover {\n    background: ", ";\n    color: ", ";\n  }\n"], ["\n  background: ", ";\n  color: ", ";\n  filter: none;\n  &:active {\n    background: ", ";\n    color: ", ";\n  }\n  &:hover {\n    background: ", ";\n    color: ", ";\n  }\n"])), function (props) {
    return props.currentPage ? props.theme.colors.text.alt : 'transparent';
}, function (props) {
    return props.currentPage
        ? props.theme.colors.text.alt3
        : props.theme.colors.text.alt;
}, function (props) { return props.theme.colors.text.alt; }, function (props) { return props.theme.colors.text.alt3; }, function (props) { return props.theme.colors.text.alt; }, function (props) { return props.theme.colors.text.alt3; });
var ResultsContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  margin-top: 32px;\n"], ["\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  margin-top: 32px;\n"])));
var EmptyResultsContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n"], ["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;

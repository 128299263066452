var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { Typography } from 'designSystem';
import RewardAmountBg from 'assets/tilted-logomark.svg';
import { RewardAmountUpdateReason } from 'types/codegen/hooks';
export var AvailableRewardCard = function (_a) {
    var rewardAmount = _a.rewardAmount, isComplete = _a.isComplete, rewardAmountUpdateReason = _a.rewardAmountUpdateReason, newProfileFromReferral = _a.newProfileFromReferral;
    var renderRefererHeader = function () {
        var message = null;
        if (newProfileFromReferral) {
            message = 'NEW REFERRED PATIENT';
        }
        if (rewardAmountUpdateReason ===
            RewardAmountUpdateReason.RefereeRewardRedemption) {
            message = 'REFERRED A FRIEND';
        }
        if (!message) {
            return null;
        }
        return (React.createElement(ReferHeader, null,
            React.createElement(Typography, { mb: 7, variant: "subtitle", bold: true }, message)));
    };
    return (React.createElement(Container, { reverse: !!isComplete },
        renderRefererHeader(),
        React.createElement(Typography, { align: "center", variant: "displayLarge", bold: true, color: "link" },
            "$",
            rewardAmount ? rewardAmount : 40,
            " OFF"),
        React.createElement(Typography, { align: "center", color: "link" }, isComplete ? (React.createElement(React.Fragment, null, "Reward Patient with")) : (React.createElement(React.Fragment, null,
            "next visit",
            React.createElement("sup", null, "*"))))));
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  height: 171px;\n  width: 255px;\n  display: flex;\n  flex-direction: ", ";\n  align-items: center;\n  justify-content: center;\n  gap: ", "px;\n  background: no-repeat center url(", ");\n  background-size: cover;\n  ", ";\n  padding: 48px 32px;\n  border-radius: 12px;\n"], ["\n  position: relative;\n  height: 171px;\n  width: 255px;\n  display: flex;\n  flex-direction: ", ";\n  align-items: center;\n  justify-content: center;\n  gap: ", "px;\n  background: no-repeat center url(", ");\n  background-size: cover;\n  ", ";\n  padding: 48px 32px;\n  border-radius: 12px;\n"])), function (props) { return (props.reverse ? 'column-reverse' : 'column'); }, function (props) { return (props.reverse ? 2 : 6); }, RewardAmountBg, function (props) { return props.theme.elevation.base.elevation4; });
var ReferHeader = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  top: 18px;\n  position: absolute;\n"], ["\n  top: 18px;\n  position: absolute;\n"])));
var templateObject_1, templateObject_2;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { ReviewOrderView } from './View';
export var ReviewOrderContainer = function (_a) {
    var paidVials = _a.paidVials, calculatedPricingLoading = _a.calculatedPricingLoading, orderData = _a.orderData, viewProps = __rest(_a, ["paidVials", "calculatedPricingLoading", "orderData"]);
    return (React.createElement(ReviewOrderView, __assign({}, viewProps, { orderData: orderData, loading: calculatedPricingLoading })));
};

import React from 'react';
export var HidePassword = function (_a) {
    var color = _a.color;
    return (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M4.31836 19L19.9434 4", stroke: color, strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M9.46875 17.3752C10.2878 17.6296 11.1412 17.7558 11.9988 17.7494C15.4154 17.8069 18.8804 15.4052 21.0188 13.0519C21.248 12.7974 21.3749 12.467 21.3749 12.1244C21.3749 11.7819 21.248 11.4514 21.0188 11.1969C20.2441 10.3471 19.3859 9.57738 18.4571 8.89941", stroke: color, strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M14.0095 6.735C13.3519 6.57137 12.6762 6.4924 11.9986 6.5C8.63947 6.44417 5.1653 8.79333 2.98114 11.1958C2.75187 11.4504 2.625 11.7808 2.625 12.1233C2.625 12.4659 2.75187 12.7963 2.98114 13.0508C3.64524 13.7778 4.37008 14.4469 5.1478 15.0508", stroke: color, strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M8.87305 12.125C8.87294 11.7146 8.95369 11.3082 9.1107 10.929C9.26771 10.5498 9.49789 10.2053 9.78809 9.91505C10.0783 9.62484 10.4228 9.39466 10.802 9.23765C11.1812 9.08065 11.5876 8.99989 11.998 9", stroke: color, strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M15.123 12.124C15.1232 12.5345 15.0424 12.9409 14.8854 13.3202C14.7284 13.6994 14.4982 14.044 14.2081 14.3343C13.9179 14.6245 13.5733 14.8548 13.1941 15.0119C12.8149 15.169 12.4085 15.2499 11.998 15.2499", stroke: color, strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" })));
};

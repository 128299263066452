var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Icon, Typography } from 'designSystem';
import { ListItemCard } from '../components/ListItemCard';
import phoneNumberFormatter from 'utils/phoneNumberFormatter';
import styled from 'styled-components';
export var RewardReceivedDetails = function (_a) {
    var _b, _c, _d, _e;
    var details = _a.details, injectors = _a.injectors, loading = _a.loading, processing = _a.processing;
    var hasInjector = !!((_b = details.injector) === null || _b === void 0 ? void 0 : _b.name);
    var isBusCampaign = !!((_e = (_d = (_c = details.rewardPromotion) === null || _c === void 0 ? void 0 : _c.campaignId) === null || _d === void 0 ? void 0 : _d.includes) === null || _e === void 0 ? void 0 : _e.call(_d, 'BUS_20U'));
    var _f = __read(useState([]), 2), options = _f[0], setOptions = _f[1];
    var _g = __read(useState(false), 2), editing = _g[0], setEditing = _g[1];
    var _h = __read(useState(hasInjector
        ? { value: details.injector.uniqueId, label: details.injector.name }
        : null), 2), selectedInjector = _h[0], setSelectedInjector = _h[1];
    useEffect(function () {
        var injectorOptions = injectors.map(function (i) { return ({
            label: i.name,
            value: i.uniqueId,
        }); });
        setOptions(injectorOptions);
    }, [injectors]);
    var handleCreate = function (name) {
        var newOption = {
            value: undefined,
            label: name,
        };
        var newOptions = __spreadArray(__spreadArray([], __read(options.filter(function (i) { return !!i.value; })), false), [newOption], false);
        setOptions(newOptions);
        setSelectedInjector(newOption);
    };
    var renderSpecialist = function () {
        var specialistInfo = selectedInjector
            ? "Treated By ".concat(selectedInjector.label)
            : 'Add specialist information';
        if (!editing) {
            return (React.createElement(React.Fragment, null,
                React.createElement("div", null,
                    React.createElement(Icon, { name: loading ? 'loading' : 'injector' }),
                    React.createElement(Typography, { variant: "headlineSmall" }, loading ? 'Loading...' : specialistInfo)),
                !loading && (React.createElement(SpecialistEditButton, { disabled: loading || processing, variant: "quaternary", onClick: function () { return setEditing(true); } }, "Edit"))));
        }
        return (React.createElement(React.Fragment, null,
            React.createElement("div", null,
                React.createElement(Icon, { name: loading || processing ? 'loading' : 'injector' }),
                React.createElement(Dropdown, { searchable: true, selected: selectedInjector, formatAddLabel: "Add new Specialist", placeholder: "Specialist Name", options: options, onAddOption: handleCreate, onSelect: setSelectedInjector }))));
    };
    var renderRewardAmount = function () {
        if (isBusCampaign) {
            return React.createElement(React.Fragment, null, "20 Units Free");
        }
        return React.createElement(React.Fragment, null,
            "$",
            details.rewardAmount,
            ".00");
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ListItemCard, null,
            React.createElement("div", null,
                React.createElement(Icon, { name: "user" }),
                React.createElement(Typography, { variant: "headlineSmall", display: "inline" },
                    details.consumerFirstName,
                    " ",
                    details.consumerLastName)),
            React.createElement(Typography, { variant: "headlineSmall", display: "inline", color: "success" },
                "+",
                details.creditsEarned,
                " Credit")),
        React.createElement(ListItemCard, null,
            React.createElement("div", null,
                React.createElement(Icon, { name: "phone" }),
                React.createElement(Typography, { variant: "headlineSmall", display: "inline" },
                    "+",
                    phoneNumberFormatter(details.phoneNumber)))),
        React.createElement(ListItemCard, null,
            React.createElement("div", null,
                React.createElement(Icon, { name: "present" }),
                React.createElement(Typography, { variant: "headlineSmall", display: "inline" }, "Reward Amount")),
            React.createElement(Typography, { variant: "headlineSmall", display: "inline", color: "success" }, renderRewardAmount())),
        React.createElement(ListItemCard, null,
            React.createElement(Typography, { variant: "headlineSmall", display: "inline" },
                "Given by ",
                details.staffFirstName,
                " ",
                details.staffLastName)),
        React.createElement(ListItemCard, null, renderSpecialist())));
};
var SpecialistEditButton = styled(Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  padding: 0 12px;\n"], ["\n  color: ", ";\n  padding: 0 12px;\n"])), function (props) { return props.theme.colors.text.alt; });
var templateObject_1;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components';
import { LocalCurrency } from 'components/LocalCurrency';
import { stackedCardStyles } from 'scenes/Rewards/Tabs/RewardsDashboard/components/StackedMobileCard';
var EvolusCreditContainer = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j;
    var paymentMethods = _a.paymentMethods, totalAmountDue = _a.totalAmountDue, isLoading = _a.isLoading, props = __rest(_a, ["paymentMethods", "totalAmountDue", "isLoading"]);
    var amountTotal = totalAmountDue;
    var availableEvolusCredit = null;
    var evolusCredit = paymentMethods === null || paymentMethods === void 0 ? void 0 : paymentMethods.find(function (paymentMethod) { return (paymentMethod === null || paymentMethod === void 0 ? void 0 : paymentMethod.type) === 'CREDIT'; });
    if (evolusCredit && (evolusCredit === null || evolusCredit === void 0 ? void 0 : evolusCredit.sensitive)) {
        availableEvolusCredit =
            ((_c = (_b = evolusCredit === null || evolusCredit === void 0 ? void 0 : evolusCredit.sensitive) === null || _b === void 0 ? void 0 : _b.loc) === null || _c === void 0 ? void 0 : _c.limit) -
                ((_e = (_d = evolusCredit === null || evolusCredit === void 0 ? void 0 : evolusCredit.sensitive) === null || _d === void 0 ? void 0 : _d.loc) === null || _e === void 0 ? void 0 : _e.balance);
    }
    else {
        availableEvolusCredit = null;
    }
    var creditBalance = Number((_g = (_f = evolusCredit === null || evolusCredit === void 0 ? void 0 : evolusCredit.sensitive) === null || _f === void 0 ? void 0 : _f.loc) === null || _g === void 0 ? void 0 : _g.limit) -
        (Number(availableEvolusCredit) + Number(amountTotal));
    creditBalance = creditBalance < 0 ? creditBalance : 0;
    var CurrentBalanceWrapper = function (_a) {
        var money = _a.money;
        return money > 0 ? (React.createElement(CurrentBalance, null,
            React.createElement(LocalCurrency, { amount: money }))) : (React.createElement(ContentValue, null,
            React.createElement(LocalCurrency, { amount: money })));
    };
    return (React.createElement(OuterContainer, { isLoading: isLoading },
        React.createElement(CardTitle, null, "EVOLUS CREDIT"),
        React.createElement(ContentContainer, null,
            React.createElement(ContentRowFirst, null,
                React.createElement(ContentRow, null,
                    React.createElement(ContentKeyFirst, null, "Available Credit"),
                    React.createElement(ContentValueFirst, null,
                        React.createElement(LocalCurrency, { amount: availableEvolusCredit })))),
            React.createElement(ContentRowSecond, null,
                React.createElement(ContentRow, null,
                    React.createElement(ContentKey, null, "Credit Balance"),
                    React.createElement(ContentValue, null,
                        React.createElement(LocalCurrency, { amount: isNaN(creditBalance) ? 0 : Math.abs(creditBalance) })))),
            React.createElement(ContentRow, null,
                React.createElement(ContentKey, null, "Current Balance"),
                React.createElement(CurrentBalanceWrapper, { money: totalAmountDue })),
            React.createElement(ContentRow, null,
                React.createElement(ContentKey, null, "Credit Limit"),
                React.createElement(ContentValue, null,
                    React.createElement(LocalCurrency, { amount: (_j = (_h = evolusCredit === null || evolusCredit === void 0 ? void 0 : evolusCredit.sensitive) === null || _h === void 0 ? void 0 : _h.loc) === null || _j === void 0 ? void 0 : _j.limit }))))));
};
export default EvolusCreditContainer;
var OuterContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  background-color: white;\n  position: relative;\n  margin: 16px;\n  ", ";\n  padding: 32px;\n  & > button {\n    margin-top: auto;\n  }\n  @media screen and (max-width: ", "px) {\n    ", ";\n    margin: -16px 0 0;\n    z-index: 3;\n  }\n  ", "\n"], ["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  background-color: white;\n  position: relative;\n  margin: 16px;\n  ", ";\n  padding: 32px;\n  & > button {\n    margin-top: auto;\n  }\n  @media screen and (max-width: ", "px) {\n    ", ";\n    margin: -16px 0 0;\n    z-index: 3;\n  }\n  ", "\n"])), function (props) { return props.theme.elevation.base.elevation1; }, function (props) { return props.theme.breakpoints.md; }, stackedCardStyles, function (props) { return props.isLoading && props.theme.blur.loading; });
var CardTitle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 24px;\n  text-transform: uppercase;\n  color: ", ";\n  margin-bottom: 56px;\n"], ["\n  display: flex;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 24px;\n  text-transform: uppercase;\n  color: ", ";\n  margin-bottom: 56px;\n"])), function (props) { return props.theme.colors.text.alt5; });
var ContentContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
var ContentRow = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 8px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 8px;\n"])));
var ContentRowFirst = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  padding-bottom: 26px;\n  border-bottom: 2px solid ", ";\n"], ["\n  padding-bottom: 26px;\n  border-bottom: 2px solid ", ";\n"])), function (props) { return props.theme.colors.white.white500; });
var ContentRowSecond = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  padding-top: 26px;\n"], ["\n  padding-top: 26px;\n"])));
var ContentKey = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 24px;\n  color: ", ";\n"], ["\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 24px;\n  color: ", ";\n"])), function (props) { return props.theme.colors.text.alt; });
var ContentValue = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 24px;\n"], ["\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 24px;\n"])));
var CurrentBalance = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 24px;\n  color: ", ";\n"], ["\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 24px;\n  color: ", ";\n"])), function (props) { return props.theme.colors.text.link; });
var ContentKeyFirst = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 24px;\n  color: ", ";\n"], ["\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 24px;\n  color: ", ";\n"])), function (props) { return props.theme.colors.text.alt; });
var ContentValueFirst = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  font-size: 24px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 32px;\n  color: ", ";\n"], ["\n  font-size: 24px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 32px;\n  color: ", ";\n"])), function (props) { return props.theme.colors.text.success; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;

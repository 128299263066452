import React from 'react';
import { Typography } from 'designSystem';
import RewardExpiredImage from 'assets/check-in-graphic-4.svg';
import { StatusContainer } from './StatusContainer';
import { useScreenSizing } from '../../../../../../../../hooks';
export var RewardExpired = function (props) {
    var rewardAmount = props.rewardAmount;
    var isMobile = useScreenSizing().isMobile;
    return (React.createElement(StatusContainer, null,
        React.createElement("div", null,
            React.createElement(Typography, { align: isMobile ? 'center' : 'left', variant: "titleLarge", mt: 8, mb: 8, color: "link" }, "Reward Expired"),
            React.createElement(Typography, { align: isMobile ? 'center' : 'left', mb: 16, color: "link" },
                "No discount available today, earn $",
                rewardAmount,
                " for next visit."),
            React.createElement(Typography, { variant: "bodySmall" }, "*Patient eligible to redeem reward once every 90 days. Rewards expire 547 days after date of issuance.")),
        React.createElement("img", { src: RewardExpiredImage, alt: "reward-expired" })));
};

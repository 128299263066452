var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Button } from 'designSystem/components/Button';
import { useModal } from 'context/ModalContext';
import React from 'react';
import { useNavigate } from 'react-router';
import styled, { css } from 'styled-components';
import LeavingCheckInGraphic from 'assets/leaving-check-in-graphic.svg';
import { Modal } from '../Modal';
import { Typography } from 'designSystem';
import { addMobileStyles } from 'utils/addMobileStyles';
import { useScreenSizing } from 'hooks';
export var LeavingCheckIn = function (_a) {
    var path = _a.path, setStoredCheckInData = _a.setStoredCheckInData, trackCancelled = _a.trackCancelled;
    var navigate = useNavigate();
    var isMobile = useScreenSizing().isMobile;
    var closeModal = useModal().closeModal;
    return (React.createElement(Modal, { onCloseOverride: function () {
            navigate('/rewards/check-in' + (path || ''));
            closeModal();
        }, size: "medium" },
        React.createElement(ContentContainer, null,
            React.createElement("div", null,
                React.createElement(TextContainer, null,
                    React.createElement(Typography, { align: isMobile ? 'center' : 'left', variant: isMobile ? 'display' : 'titleLarge', color: isMobile ? 'link' : 'base', mb: isMobile ? 32 : 16 }, "Leaving the Check In process?"),
                    React.createElement(Typography, { align: isMobile ? 'center' : 'left', color: "alt", mb: 24 }, "By heading to another part of the Portal, you will lose your progress of checking in a patient for Evolus Rewards."),
                    React.createElement(Typography, { align: isMobile ? 'center' : 'left', color: "alt" }, "Please complete the process or start over at a later time.")),
                React.createElement(ButtonContainer, null,
                    React.createElement(Button, { size: "xlarge", variant: isMobile ? 'tertiary' : 'outlined', onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4, trackCancelled()];
                                    case 1:
                                        _a.sent();
                                        closeModal();
                                        return [2];
                                }
                            });
                        }); } }, "Yes, Leave"),
                    React.createElement(Button, { size: "xlarge", variant: "primary", onClick: function () {
                            var goTo = '/rewards/check-in' + (path || '');
                            navigate(goTo);
                            closeModal();
                        } }, "No, Keep Checking in Patient"))),
            React.createElement(Image, { src: LeavingCheckInGraphic }))));
};
var ContentContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  flex-direction: row;\n  align-items: center;\n  z-index: 10000;\n  gap: 44px;\n  ", "\n"], ["\n  display: flex;\n  flex: 1;\n  flex-direction: row;\n  align-items: center;\n  z-index: 10000;\n  gap: 44px;\n  ", "\n"])), addMobileStyles(css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 100%;\n    flex-direction: column-reverse;\n    justify-content: flex-end;\n    & > div:first-of-type {\n      flex: 1;\n      display: flex;\n      flex-direction: column;\n    }\n  "], ["\n    width: 100%;\n    flex-direction: column-reverse;\n    justify-content: flex-end;\n    & > div:first-of-type {\n      flex: 1;\n      display: flex;\n      flex-direction: column;\n    }\n  "])))));
var Image = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  max-width: 280px;\n  ", ";\n"], ["\n  width: 100%;\n  max-width: 280px;\n  ", ";\n"])), addMobileStyles(css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    margin-bottom: 32px;\n    max-width: 215px;\n  "], ["\n    margin-bottom: 32px;\n    max-width: 215px;\n  "])))));
var TextContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
var ButtonContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-start;\n  font-size: 1rem;\n  width: 100%;\n  margin-top: 48px;\n  gap: 12px;\n  @media only screen and (max-width: ", "px) {\n    width: 100%;\n    flex-direction: column-reverse;\n    margin-top: auto;\n  }\n"], ["\n  display: flex;\n  justify-content: flex-start;\n  font-size: 1rem;\n  width: 100%;\n  margin-top: 48px;\n  gap: 12px;\n  @media only screen and (max-width: ", "px) {\n    width: 100%;\n    flex-direction: column-reverse;\n    margin-top: auto;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { useModal } from 'context';
import { Button, Typography } from 'designSystem';
import PasswordResetGraphic from 'assets/reset-password-graphic.svg';
import { useScreenSizing } from 'hooks';
export var PasswordResetSuccessful = function () {
    var closeModal = useModal().closeModal;
    var isMobile = useScreenSizing().isMobile;
    return (React.createElement(OuterContainer, null,
        React.createElement(LeftContainer, null,
            React.createElement(TextContainer, null,
                React.createElement(Typography, { align: isMobile ? 'center' : 'left', color: isMobile ? 'link' : 'base', variant: isMobile ? 'display' : 'titleLarge', mb: 16 }, "Password reset successful!"),
                React.createElement(Typography, { align: isMobile ? 'center' : 'left', variant: isMobile ? 'headlineSmall' : 'body', color: "alt", mb: isMobile ? 32 : 16 }, "You may now log in with your new password.")),
            React.createElement(ButtonContainer, null,
                React.createElement(Button, { size: "xlarge", variant: "primary", onClick: function () { return closeModal(); } }, "Back to Log in"))),
        React.createElement(ImageContainer, null,
            React.createElement(Image, { src: PasswordResetGraphic }))));
};
var OuterContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  flex-direction: row;\n  z-index: 10000;\n  position: relative;\n  justify-content: space-between;\n  gap: 48px;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column-reverse;\n    justify-content: unset;\n    gap: 32px;\n  }\n"], ["\n  display: flex;\n  flex: 1;\n  flex-direction: row;\n  z-index: 10000;\n  position: relative;\n  justify-content: space-between;\n  gap: 48px;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column-reverse;\n    justify-content: unset;\n    gap: 32px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var LeftContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n"], ["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n"])));
var ImageContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex: 1;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex: 1;\n"])));
var Image = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  max-width: 280px;\n"], ["\n  width: 100%;\n  max-width: 280px;\n"])));
var ButtonContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-start;\n  align-items: flex-end;\n  @media only screen and (max-width: ", "px) {\n    margin-top: auto;\n    & > button {\n      width: 100%;\n    }\n  }\n"], ["\n  display: flex;\n  justify-content: flex-start;\n  align-items: flex-end;\n  @media only screen and (max-width: ", "px) {\n    margin-top: auto;\n    & > button {\n      width: 100%;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var TextContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n"], ["\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;

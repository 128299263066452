var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useCallback, useEffect, useState } from 'react';
import { AccountPaymentMethods } from '../../../../../../components/AccountPaymentMethods';
import { useUser } from 'context';
import { PaymentMethodType, getSelectedPaymentMethod, } from 'utils/getSelectedPaymentMethod';
export var PaymentMethodSelection = function (_a) {
    var _b, _c, _d;
    var orderData = _a.orderData, setOrderData = _a.setOrderData, onApplyPromo = _a.onApplyPromo, paidVials = _a.paidVials, onRemovePromo = _a.onRemovePromo, preapplyCode = _a.preapplyCode, selectedPaymentMethod = _a.selectedPaymentMethod, setSelectedPaymentMethod = _a.setSelectedPaymentMethod, calculatedPricing = _a.calculatedPricing, setIsAmountEnteredValid = _a.setIsAmountEnteredValid, isAmountEnteredValid = _a.isAmountEnteredValid, calculatedPricingLoading = _a.calculatedPricingLoading;
    var account = useUser().account;
    var facilityId = (_b = orderData === null || orderData === void 0 ? void 0 : orderData.facility) === null || _b === void 0 ? void 0 : _b.id;
    var storedPromoCode = (_c = orderData === null || orderData === void 0 ? void 0 : orderData.promotions) === null || _c === void 0 ? void 0 : _c.find(function (p) { return p.type === 'PROMO_CODE'; });
    var _e = __read(useState((_d = orderData === null || orderData === void 0 ? void 0 : orderData.promoAppliedMessage) !== null && _d !== void 0 ? _d : null), 2), message = _e[0], setMessage = _e[1];
    useEffect(function () {
        setSelectedPaymentMethod(null);
        setIsAmountEnteredValid(false);
        setMessage(null);
    }, []);
    var _f = __read(useState(!!storedPromoCode || null), 2), isValid = _f[0], setIsValid = _f[1];
    var _g = __read(useState(!!storedPromoCode), 2), promoApplied = _g[0], setPromoApplied = _g[1];
    var onValidate = useCallback(function (promoCode) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setPromoApplied(true);
            setIsValid(true);
            setMessage('Super awesome promo');
            return [2];
        });
    }); }, [
        onApplyPromo,
        account,
        paidVials,
        facilityId,
        setSelectedPaymentMethod,
        setIsAmountEnteredValid,
    ]);
    var handleRemovePromo = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4, onRemovePromo()];
                case 1:
                    _a.sent();
                    setMessage(null);
                    setIsValid(null);
                    setPromoApplied(false);
                    return [2];
            }
        });
    }); }, [onRemovePromo]);
    useEffect(function () {
        var _a;
        if (selectedPaymentMethod) {
            var primaryPaymentMethod = getSelectedPaymentMethod(selectedPaymentMethod, PaymentMethodType.primary);
            setOrderData(__assign(__assign({}, orderData), { paymentMethod: selectedPaymentMethod, achDiscountPercentage: ((_a = primaryPaymentMethod === null || primaryPaymentMethod === void 0 ? void 0 : primaryPaymentMethod.sensitive) === null || _a === void 0 ? void 0 : _a.ach) ? 2 : 0 }));
        }
    }, [selectedPaymentMethod]);
    return (React.createElement(AccountPaymentMethods, { preselectedMethodId: (orderData === null || orderData === void 0 ? void 0 : orderData.paymentMethod) && (orderData === null || orderData === void 0 ? void 0 : orderData.paymentMethod[0].paymentMethodId), calculatedPricing: calculatedPricing, selectedPaymentMethod: selectedPaymentMethod, onSelect: setSelectedPaymentMethod, estimatedOrderTotal: orderData.pricePerUnit *
            ((orderData === null || orderData === void 0 ? void 0 : orderData.quantity) - (orderData.applyFreeVials || 0)), promoApplied: promoApplied, message: message, isValid: isValid, validatePromo: onValidate, onRemovePromo: handleRemovePromo, storedPromoCode: storedPromoCode === null || storedPromoCode === void 0 ? void 0 : storedPromoCode.promoCode, orderData: orderData, setOrderData: setOrderData, called: true, promoLoading: false, preapplyCode: preapplyCode, setIsAmountEnteredValid: setIsAmountEnteredValid, isAmountEnteredValid: isAmountEnteredValid, calculatedPricingLoading: calculatedPricingLoading }));
};

import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { RequireAuth } from '../RequireAuth';
import { Authenticated } from '../Authenticated';
import { SelectAccount } from '../SelectAccount';
import { AccountAdminRoute } from '../AccountAdminRoute';
import { SignIn } from 'scenes/Auth/SignIn';
import { OrdersView } from 'scenes/Orders';
import OrderJeuveauView from 'scenes/Orders/Tabs/OrderJeuveau';
import { OrderHistory } from 'scenes/Orders/Tabs/OrderHistory';
import { OrderSummary } from 'scenes/Orders/Tabs/OrderSummary';
import AccountSelection from 'components/Modals/AccountSelection';
import { Rewards } from 'scenes/Rewards';
import RewardsStatements from 'scenes/Rewards/Tabs/Statements';
import { OrderDashboard } from 'scenes/Orders/Tabs/OrderDashboard';
import UpcomingRewards from 'scenes/Rewards/Tabs/UpcomingRewards';
import { RewardsDashboard } from 'scenes/Rewards/Tabs/RewardsDashboard';
import RewardsHistory from 'scenes/Rewards/Tabs/RewardsHistory';
import { HomeScreen } from 'scenes/HomeScreen';
import { ProfileSettings } from 'scenes/ProfileSettings';
import Evolux from 'scenes/Evolux';
import { Profile } from 'scenes/ProfileSettings/Tabs/Profile';
import { ChangePassword } from 'scenes/ProfileSettings/Tabs/ChangePassword';
import { Settings } from 'scenes/Settings';
import { PaymentMethodsSettings } from 'scenes/Settings/Tabs/PaymentMethods';
import { UserPermissions } from 'scenes/Settings/Tabs/UserPermissions';
import { SingleUser } from 'scenes/Settings/Tabs/UserPermissions/components/SingleUser';
import { InviteUser } from 'scenes/Settings/Tabs/UserPermissions/components/InviteUser';
import { EvolusRewards } from 'scenes/Settings/Tabs/EvolusRewards';
import { Offers } from 'scenes/Offers';
import { OffersDashboard } from 'scenes/Offers/Tabs/Dashboard';
import { CheckIn } from 'scenes/Rewards/Tabs/CheckIn';
import { Invoices } from 'scenes/Invoices';
import { InvoicesDashboard } from '../../../../scenes/Invoices/tabs/Dashboard';
import { PaymentHistory } from '../../../../scenes/Invoices/tabs/PaymentHistory';
import { SinglePayment } from '../../../../scenes/Invoices/tabs/SinglePayment';
import { MakeAPayment } from '../../../../scenes/Invoices/tabs/MakeAPayment';
var AppRoutes = function () {
    return (React.createElement(Routes, null,
        React.createElement(Route, { element: React.createElement(Authenticated, null) },
            React.createElement(Route, { path: "/auth/sign-in", element: React.createElement(SignIn, null) })),
        React.createElement(Route, { element: React.createElement(RequireAuth, null) },
            React.createElement(Route, { path: "/selectAccount/:accountId", element: React.createElement(AccountSelection, null) }),
            React.createElement(Route, { path: "/selectAccount", element: React.createElement(AccountSelection, null) }),
            React.createElement(Route, { element: React.createElement(SelectAccount, null) },
                React.createElement(Route, { element: React.createElement(AccountAdminRoute, null) },
                    React.createElement(Route, { path: "orders/*", element: React.createElement(OrdersView, null) },
                        React.createElement(Route, { path: "dashboard", element: React.createElement(OrderDashboard, null) }),
                        React.createElement(Route, { path: "buy", element: React.createElement(OrderJeuveauView, null) }),
                        React.createElement(Route, { path: "history", element: React.createElement(OrderHistory, null) }),
                        React.createElement(Route, { path: ":orderId", element: React.createElement(OrderSummary, null) })),
                    React.createElement(Route, { path: "/orders", element: React.createElement(Navigate, { replace: true, to: "/orders/dashboard" }) }),
                    React.createElement(Route, { path: "/home", element: React.createElement(HomeScreen, null) }),
                    React.createElement(Route, { path: "settings/*", element: React.createElement(Settings, null) },
                        React.createElement(Route, { path: "payments", element: React.createElement(PaymentMethodsSettings, null) }),
                        React.createElement(Route, { path: "rewards", element: React.createElement(EvolusRewards, null) }),
                        React.createElement(Route, { path: "user-permissions/*", element: React.createElement(UserPermissions, null) },
                            React.createElement(Route, { path: ":userId", element: React.createElement(SingleUser, null) }),
                            React.createElement(Route, { path: "new", element: React.createElement(InviteUser, null) }))),
                    React.createElement(Route, { path: "/settings", element: React.createElement(Navigate, { replace: true, to: '/settings/payments' }) }),
                    React.createElement(Route, { path: "/evolux", element: React.createElement(Evolux, null) })),
                React.createElement(Route, { path: "/profile-settings", element: React.createElement(Navigate, { replace: true, to: "/profile-settings/profile" }) }),
                React.createElement(Route, { path: "profile-settings/*", element: React.createElement(ProfileSettings, null) },
                    React.createElement(Route, { path: "profile", element: React.createElement(Profile, null) }),
                    React.createElement(Route, { path: "change-password", element: React.createElement(ChangePassword, null) })),
                React.createElement(Route, { path: "/rewards", element: React.createElement(Navigate, { replace: true, to: "/rewards/dashboard" }) }),
                React.createElement(Route, { path: "rewards/*", element: React.createElement(Rewards, null) },
                    React.createElement(Route, { path: "dashboard", element: React.createElement(RewardsDashboard, null) }),
                    React.createElement(Route, { path: "check-in", element: React.createElement(CheckIn, null) }),
                    React.createElement(Route, { path: "statements", element: React.createElement(RewardsStatements, null) }),
                    React.createElement(Route, { path: "upcoming-rewards", element: React.createElement(UpcomingRewards, null) }),
                    React.createElement(Route, { path: "history", element: React.createElement(RewardsHistory, null) })),
                React.createElement(Route, { path: "offers/*", element: React.createElement(Offers, null) },
                    React.createElement(Route, { path: "dashboard", element: React.createElement(OffersDashboard, null) })),
                React.createElement(Route, { path: "/offers", element: React.createElement(Navigate, { replace: true, to: "/offers/dashboard" }) }),
                React.createElement(Route, { path: "invoices/*", element: React.createElement(Invoices, null) },
                    React.createElement(Route, { path: "dashboard", element: React.createElement(InvoicesDashboard, null) }),
                    React.createElement(Route, { path: "history", element: React.createElement(PaymentHistory, null) }),
                    React.createElement(Route, { path: "payment/:paymentId", element: React.createElement(SinglePayment, null) }),
                    React.createElement(Route, { path: "make-payment", element: React.createElement(MakeAPayment, null) })),
                React.createElement(Route, { path: "/invoices", element: React.createElement(Navigate, { replace: true, to: "/invoices/dashboard" }) }),
                React.createElement(Route, { path: "*", element: React.createElement(Navigate, { replace: true, to: '/home' }) })))));
};
export default AppRoutes;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Button } from 'designSystem/components/Button';
import { useModal } from 'context/ModalContext';
import React from 'react';
import styled from 'styled-components';
import { levelName } from 'utils/levelName';
import { pluralize } from 'utils/pluralize';
import { Modal } from '../Modal';
import CurrentEvoluxLevel from 'components/CurrentEvoluxLevel';
import { Typography } from 'designSystem';
import { useScreenSizing } from 'hooks';
export var AutomaticLevelUpgrade = function (_a) {
    var loyaltyLevel = _a.loyaltyLevel, vialsNeededForUpgrade = _a.vialsNeededForUpgrade, onAgree = _a.onAgree, onRefuse = _a.onRefuse, getEvoluxSummary = _a.getEvoluxSummary, pricingModel = _a.pricingModel;
    var closeModal = useModal().closeModal;
    var isRetained = getEvoluxSummary(loyaltyLevel, vialsNeededForUpgrade).isRetained;
    var isMobile = useScreenSizing().isMobile;
    var isCustomAccount = pricingModel === null || pricingModel === void 0 ? void 0 : pricingModel.includes('CUSTOM_');
    var getTitle = function () {
        var level = levelName(loyaltyLevel);
        return "".concat(isRetained ? 'Retain ' : 'Upgrade to ', " ").concat(level, " for more Savings!");
    };
    var getSubtitle = function () {
        var level = levelName(loyaltyLevel);
        return "\n        Buy ".concat(vialsNeededForUpgrade, " More Qualifying Vial").concat(pluralize(vialsNeededForUpgrade), " to ").concat(isRetained ? 'Keep' : 'Upgrade to', " ").concat(level, " status now.\n      ");
    };
    return (React.createElement(Modal, { size: "small" },
        React.createElement(ContentContainer, null,
            React.createElement(Typography, { variant: "display", align: "center", mb: 32 }, getTitle()),
            React.createElement(Typography, { variant: "subtitle", align: "center", mb: 64, color: "alt" }, getSubtitle()),
            React.createElement(EvoluxLevelContainer, null,
                React.createElement(CurrentEvoluxLevel, { context: "DEFAULT", currentLevelOverride: loyaltyLevel })),
            React.createElement(Typography, { variant: "subtitle", align: "center", mb: 64, mt: !isCustomAccount && loyaltyLevel === 'LEVEL_3' ? 0 : 64, color: "alt" }, "Currently all vials of your order count towards your Evolux status upgrade."),
            React.createElement(ButtonContainer, null,
                React.createElement(Button, { size: "large", variant: isMobile ? 'tertiary' : 'outlined', onClick: function () {
                        closeModal();
                        onRefuse();
                    } }, "No thanks"),
                !isMobile && React.createElement(VerticalSpacer, null),
                React.createElement(Button, { size: "large", variant: "primary", onClick: function () {
                        closeModal();
                        onAgree();
                    } },
                    "Add ",
                    vialsNeededForUpgrade,
                    " More Vials")))));
};
var ContentContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  align-items: center;\n  z-index: 10000;\n"], ["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  align-items: center;\n  z-index: 10000;\n"])));
var EvoluxLevelContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
var VerticalSpacer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: 0 12px;\n"], ["\n  margin: 0 12px;\n"])));
var ButtonContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  justify-content: flex-end;\n  font-size: 1rem;\n\n  @media only screen and (max-width: ", "px) {\n    font-size: inherit;\n    flex-direction: column-reverse;\n    justify-content: center;\n\n    & > button {\n      padding: 16px 32px;\n\n      &:last-child {\n        margin-bottom: 16px;\n      }\n    }\n  }\n"], ["\n  display: flex;\n  width: 100%;\n  justify-content: flex-end;\n  font-size: 1rem;\n\n  @media only screen and (max-width: ", "px) {\n    font-size: inherit;\n    flex-direction: column-reverse;\n    justify-content: center;\n\n    & > button {\n      padding: 16px 32px;\n\n      &:last-child {\n        margin-bottom: 16px;\n      }\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;

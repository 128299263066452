var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Button, Icon, Typography } from 'designSystem';
import React from 'react';
import styled from 'styled-components';
import { Modal } from '../Modal';
import { useScreenSizing } from 'hooks';
import { useModal } from 'context';
import RemoveGraphic from 'assets/remove-item.svg';
export var RemovePaymentMethodModalView = function (_a) {
    var onRemove = _a.onRemove, loading = _a.loading;
    var isMobile = useScreenSizing().isMobile;
    var closeModal = useModal().closeModal;
    return (React.createElement(Modal, null,
        React.createElement(ContentContainer, null,
            React.createElement(ImageContainer, null,
                React.createElement(Image, { src: RemoveGraphic })),
            React.createElement(InnerContainer, null,
                React.createElement(Typography, { display: "block", mb: 16, variant: isMobile ? 'display' : 'titleLarge', align: isMobile ? 'center' : 'left', color: isMobile ? 'link' : 'base' }, "Are you sure you want to remove this payment method?"),
                React.createElement(Typography, { variant: "body", align: isMobile ? 'center' : 'left', color: "alt" }, "You will not be able to pay with this method of payment once its is removed. You can add it again in Account Settings."),
                React.createElement(ActionsContainer, null,
                    React.createElement(Button, { size: "xlarge", endIcon: loading && React.createElement(Icon, { color: "inherit", name: "loading" }), disabled: loading, variant: "primary", onClick: function () { return onRemove(); } }, "Yes, Remove"),
                    React.createElement(Button, { size: "xlarge", disabled: loading, variant: isMobile ? 'tertiary' : 'outlined', onClick: closeModal }, "Cancel"))))));
};
var ContentContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  flex-direction: row-reverse;\n  justify-content: flex-start;\n  align-items: initial;\n  flex: 1;\n  gap: 64px;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n    gap: 36px;\n    align-items: center;\n  }\n"], ["\n  width: 100%;\n  display: flex;\n  flex-direction: row-reverse;\n  justify-content: flex-start;\n  align-items: initial;\n  flex: 1;\n  gap: 64px;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n    gap: 36px;\n    align-items: center;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var InnerContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex: 2;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  @media only screen and (max-width: ", "px) {\n    flex: 1;\n  }\n"], ["\n  flex: 2;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  @media only screen and (max-width: ", "px) {\n    flex: 1;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var ImageContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex: 1;\n  @media only screen and (max-width: ", "px) {\n    flex: unset;\n  }\n"], ["\n  flex: 1;\n  @media only screen and (max-width: ", "px) {\n    flex: unset;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var Image = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  max-width: 192px;\n"], ["\n  width: 100%;\n  max-width: 192px;\n"])));
var ActionsContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-top: auto;\n  width: 100%;\n  display: flex;\n  flex-direction: row-reverse;\n  align-items: center;\n  justify-content: flex-end;\n  gap: 12px;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n    flex: 1;\n    margin-top: 32px;\n    & > button {\n      width: 100%;\n    }\n  }\n"], ["\n  margin-top: auto;\n  width: 100%;\n  display: flex;\n  flex-direction: row-reverse;\n  align-items: center;\n  justify-content: flex-end;\n  gap: 12px;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n    flex: 1;\n    margin-top: 32px;\n    & > button {\n      width: 100%;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useUser } from 'context';
import { EditPaymentMethodModalView } from './View';
export var EditPaymentMethodModalContainer = function (_a) {
    var paymentMethod = _a.paymentMethod, onEdit = _a.onEdit;
    var _b = useUser(), account = _b.account, switchAccount = _b.switchAccount;
    var updatePaymentMethod = function (_a) {
        var id = _a.id, name = _a.name;
        var existingPaymentMethod = account.paymentMethods.find(function (p) { return p.paymentMethodId === id; });
        var updated = __assign(__assign({}, existingPaymentMethod), { name: name });
        var updatedAccount = __assign(__assign({}, account), { paymentMethods: account.paymentMethods.map(function (p) {
                return p.paymentMethodId === id ? updated : p;
            }) });
        localStorage.setItem(account.id, JSON.stringify(updatedAccount));
        switchAccount(updatedAccount);
        onEdit();
    };
    return (React.createElement(EditPaymentMethodModalView, { loading: false, paymentMethod: paymentMethod, onSubmit: updatePaymentMethod }));
};

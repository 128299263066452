var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { Typography } from '../Typography/Typography';
export var InputStepper = function (_a) {
    var value = _a.value, onChange = _a.onChange, _b = _a.step, step = _b === void 0 ? 1 : _b, _c = _a.label, label = _c === void 0 ? null : _c;
    return (React.createElement(Root, null,
        React.createElement(Decrement, { onClick: function () { return onChange(value - step); } }, "-"),
        React.createElement(TextWrapper, null,
            React.createElement(Typography, { variant: "displayExtraLarge" }, value),
            label && React.createElement(Typography, { variant: "title" }, label)),
        React.createElement(Increment, { onClick: function () { return onChange(value + step); } }, "+")));
};
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
var Button = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 19px 9px;\n  background: ", ";\n  color: ", ";\n  border: 1px solid ", ";\n  cursor: pointer;\n  width: 36px;\n  font-size: 18px;\n"], ["\n  padding: 19px 9px;\n  background: ", ";\n  color: ", ";\n  border: 1px solid ", ";\n  cursor: pointer;\n  width: 36px;\n  font-size: 18px;\n"])), function (props) { return props.theme.colors.basic.white; }, function (props) { return props.theme.colors.coral[5]; }, function (props) { return props.theme.colors.border.base; });
var Increment = styled(Button)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-left: 64px;\n"], ["\n  margin-left: 64px;\n"])));
var Decrement = styled(Button)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-right: 64px;\n"], ["\n  margin-right: 64px;\n"])));
var TextWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n"])));
InputStepper.displayName = 'InputStepper';
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;

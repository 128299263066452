var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import CloseIcon from '../../assets/close.svg';
export var Close = styled.img.attrs({
    src: CloseIcon,
})(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  font-size: 20px;\n  border: none;\n  padding: 8px 20px;\n  cursor: pointer;\n"], ["\n  position: relative;\n  font-size: 20px;\n  border: none;\n  padding: 8px 20px;\n  cursor: pointer;\n"])));
export default Close;
var templateObject_1;

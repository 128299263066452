var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';
import styled from 'styled-components';
import priorityIcon from 'assets/priority-shipping-graphic.svg';
import standardIcon from 'assets/standard-shipping-graphic.svg';
import { Desktop, Mobile, Typography } from 'designSystem';
export var DeliveryDateSelection = function (_a) {
    var _b, _c, _d, _e, _f;
    var orderData = _a.orderData, setOrderData = _a.setOrderData;
    var _g = __read(useState(((_b = orderData === null || orderData === void 0 ? void 0 : orderData.shippingDates) === null || _b === void 0 ? void 0 : _b.find(function (date) { var _a; return date.date === ((_a = orderData === null || orderData === void 0 ? void 0 : orderData.shippingMethod) === null || _a === void 0 ? void 0 : _a.requestedDeliveryDate); })) ||
        ((_c = orderData === null || orderData === void 0 ? void 0 : orderData.shippingDates) === null || _c === void 0 ? void 0 : _c[0]) ||
        {}), 2), selectedDate = _g[0], setSelectedDate = _g[1];
    console.log(selectedDate, orderData);
    var _h = __read(useState((selectedDate === null || selectedDate === void 0 ? void 0 : selectedDate.servicesAvailable.find(function (service) { var _a; return (service === null || service === void 0 ? void 0 : service.serviceName) === ((_a = orderData === null || orderData === void 0 ? void 0 : orderData.shippingMethod) === null || _a === void 0 ? void 0 : _a.serviceName); })) ||
        ((_d = selectedDate === null || selectedDate === void 0 ? void 0 : selectedDate.servicesAvailable) === null || _d === void 0 ? void 0 : _d[1]) ||
        {}), 2), selectedRate = _h[0], setSelectedRate = _h[1];
    useEffect(function () {
        setOrderData(__assign(__assign({}, orderData), { shippingMethod: __assign(__assign({}, orderData.shippingMethod), { serviceId: selectedRate === null || selectedRate === void 0 ? void 0 : selectedRate.serviceId, serviceName: selectedRate === null || selectedRate === void 0 ? void 0 : selectedRate.serviceName, shipperName: selectedRate === null || selectedRate === void 0 ? void 0 : selectedRate.shipperName, requestedDeliveryDate: selectedDate === null || selectedDate === void 0 ? void 0 : selectedDate.date, requestedDeliveryTime: selectedRate === null || selectedRate === void 0 ? void 0 : selectedRate.deliveryByTime }), deliveryByTime: selectedRate === null || selectedRate === void 0 ? void 0 : selectedRate.deliveryByTime }));
    }, [selectedDate, selectedRate]);
    var DatePicker = function () {
        var _a;
        return (React.createElement(DeliveryDatesContainer, { "data-automation-id": "order-delivery-dates-container" }, (_a = orderData === null || orderData === void 0 ? void 0 : orderData.shippingDates) === null || _a === void 0 ? void 0 : _a.map(function (date, idx) {
            var _a;
            return (React.createElement(DateButtonContainer, { active: date.date === (selectedDate === null || selectedDate === void 0 ? void 0 : selectedDate.date), onClick: function () { return setSelectedDate(date); }, "data-automation-id": "order-delivery-date-container-".concat(idx), "data-delivery-date": date.date, "data-selected": date.date === (selectedDate === null || selectedDate === void 0 ? void 0 : selectedDate.date) },
                React.createElement(Typography, { color: date.date === (selectedDate === null || selectedDate === void 0 ? void 0 : selectedDate.date) ? 'alt4' : 'alt', variant: "headlineSmall" }, format(parseISO(date.date), 'MMMM d')),
                React.createElement(Typography, { color: date.date === (selectedDate === null || selectedDate === void 0 ? void 0 : selectedDate.date) ? 'alt3' : 'base', variant: "title" }, new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(parseISO(date === null || date === void 0 ? void 0 : date.date))),
                React.createElement(Desktop, null,
                    React.createElement(Typography, { color: date.date === (selectedDate === null || selectedDate === void 0 ? void 0 : selectedDate.date) ? 'alt4' : 'alt', variant: "headlineSmall" },
                        "by",
                        ' ',
                        ((_a = selectedRate === null || selectedRate === void 0 ? void 0 : selectedRate.serviceName) === null || _a === void 0 ? void 0 : _a.includes('Priority'))
                            ? '10:30 AM'
                            : '4:30 PM'))));
        })));
    };
    return (React.createElement(DeliveryDateSelectionContainer, null,
        React.createElement(Typography, { variant: "headline" }, "Expected Delivery"),
        React.createElement(Desktop, null,
            React.createElement(DatePicker, null)),
        React.createElement(Mobile, null,
            React.createElement(SliderWrapper, null,
                React.createElement(DatePicker, null))),
        React.createElement(DeliverServiceContainer, null,
            React.createElement(Typography, { variant: "headline" }, "Delivery Service"),
            React.createElement(DeliveryDatesContainer, { "data-automation-id": "order-delivery-service-container" }, (_f = (_e = selectedDate === null || selectedDate === void 0 ? void 0 : selectedDate.servicesAvailable) === null || _e === void 0 ? void 0 : _e.sort(function (a, b) { return b.serviceId - a.serviceId; })) === null || _f === void 0 ? void 0 : _f.map(function (rate, idx) {
                var _a, _b;
                var sameService = (rate === null || rate === void 0 ? void 0 : rate.serviceName) === (selectedRate === null || selectedRate === void 0 ? void 0 : selectedRate.serviceName);
                return (React.createElement(DeliveryServiceButtonContainer, { active: rate.serviceId === (selectedRate === null || selectedRate === void 0 ? void 0 : selectedRate.serviceId), onClick: function () { return setSelectedRate(rate); }, "data-automation-id": "order-delivery-service-".concat(idx), "data-delivery-service": rate.serviceId, "data-selected": rate.serviceId === (selectedRate === null || selectedRate === void 0 ? void 0 : selectedRate.serviceId) },
                    React.createElement(DeliveryServiceImage, { src: ((_a = rate === null || rate === void 0 ? void 0 : rate.serviceName) === null || _a === void 0 ? void 0 : _a.includes('Priority'))
                            ? priorityIcon
                            : standardIcon }),
                    React.createElement(DeliveryServiceRightContainer, null,
                        React.createElement(Typography, { variant: "caption", align: "center", color: sameService ? 'white' : 'alt' }, "Delivery by"),
                        React.createElement(Typography, { variant: "headline", align: "center", color: sameService ? 'white' : 'base' }, ((_b = rate === null || rate === void 0 ? void 0 : rate.serviceName) === null || _b === void 0 ? void 0 : _b.includes('Priority'))
                            ? '10:30 AM'
                            : '4:30 PM'),
                        React.createElement(Typography, { variant: "caption", color: sameService ? 'white' : 'alt', align: "center" }, rate === null || rate === void 0 ? void 0 : rate.serviceName))));
            })))));
};
var SliderWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  overflow-y: hidden;\n  margin: 0px -32px;\n  padding: 0px 32px;\n\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n\n  &::-webkit-scrollbar {\n    display: none;\n  }\n"], ["\n  overflow-y: hidden;\n  margin: 0px -32px;\n  padding: 0px 32px;\n\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n\n  &::-webkit-scrollbar {\n    display: none;\n  }\n"])));
var DeliveryDateSelectionContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
var DeliveryDatesContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  margin-bottom: 48px;\n\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n\n  &::-webkit-scrollbar {\n    display: none;\n  }\n"], ["\n  display: flex;\n  margin-bottom: 48px;\n\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n\n  &::-webkit-scrollbar {\n    display: none;\n  }\n"])));
var DateButtonContainer = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  all: unset;\n  cursor: pointer;\n  display: flex;\n  flex: 1;\n  margin: 0;\n  margin-top: 12px;\n  background-color: ", ";\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 16px 0;\n\n  &:not(:last-child) {\n    margin-right: 16px;\n  }\n\n  @media only screen and (max-width: ", "px) {\n    padding: 12px 26px;\n    min-width: 120px;\n\n    &:not(:last-child) {\n      margin-right: 12px;\n    }\n\n    &:last-child {\n      margin-right: 32px;\n    }\n  }\n"], ["\n  all: unset;\n  cursor: pointer;\n  display: flex;\n  flex: 1;\n  margin: 0;\n  margin-top: 12px;\n  background-color: ", ";\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 16px 0;\n\n  &:not(:last-child) {\n    margin-right: 16px;\n  }\n\n  @media only screen and (max-width: ", "px) {\n    padding: 12px 26px;\n    min-width: 120px;\n\n    &:not(:last-child) {\n      margin-right: 12px;\n    }\n\n    &:last-child {\n      margin-right: 32px;\n    }\n  }\n"])), function (props) {
    return props.active ? props.theme.colors.text.base : props.theme.colors.bg.base;
}, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var DeliverServiceContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject([""], [""])));
var DeliveryServiceImage = styled.img(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 20%;\n  margin-right: 36px;\n\n  @media only screen and (max-width: ", "px) {\n    width: 48px;\n    margin-right: 0;\n    margin-bottom: 12px;\n  }\n"], ["\n  width: 20%;\n  margin-right: 36px;\n\n  @media only screen and (max-width: ", "px) {\n    width: 48px;\n    margin-right: 0;\n    margin-bottom: 12px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var DeliveryServiceButtonContainer = styled.button(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  all: unset;\n  cursor: pointer;\n  display: flex;\n  flex: 1;\n  margin: 0;\n  margin-top: 12px;\n  background-color: ", ";\n  justify-content: center;\n  align-items: center;\n  padding: 24px 36px;\n\n  &:not(:last-child) {\n    margin-right: 16px;\n  }\n\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n    padding: 20px 12px;\n    align-items: center;\n  }\n"], ["\n  all: unset;\n  cursor: pointer;\n  display: flex;\n  flex: 1;\n  margin: 0;\n  margin-top: 12px;\n  background-color: ", ";\n  justify-content: center;\n  align-items: center;\n  padding: 24px 36px;\n\n  &:not(:last-child) {\n    margin-right: 16px;\n  }\n\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n    padding: 20px 12px;\n    align-items: center;\n  }\n"])), function (props) {
    return props.active ? props.theme.colors.text.base : props.theme.colors.bg.base;
}, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var DeliveryServiceRightContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n\n  @media only screen and (max-width: ", "px) {\n    align-items: center;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n\n  @media only screen and (max-width: ", "px) {\n    align-items: center;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;

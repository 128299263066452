var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { FacilitySelectionView as FacilitySelection } from './Tabs/FacilitySelection';
import FacilityInfoHeader from './Tabs/RewardsDashboard/components/FacilityInfoHeader';
import { useUser } from 'context';
import { Mobile } from 'designSystem';
import { TabNav } from 'components/TabNav';
import { useNavigate } from 'react-router';
import { ConsumerRewardOptInStatus } from 'types/codegen/hooks';
import { LimitedOptedOut } from './Tabs/LimitedOptedOut';
export var RewardsView = function (_a) {
    var isLoading = _a.isLoading, isLocked = _a.isLocked, canOptIn = _a.canOptIn, isInactive = _a.isInactive, status = _a.status, facilities = _a.facilities;
    var _b = useUser(), facility = _b.facility, isAdmin = _b.isAdmin, switchFacility = _b.switchFacility;
    var location = useLocation();
    var navigate = useNavigate();
    var _c = __read(useState(false), 2), tabsDisabled = _c[0], setTabsDisabled = _c[1];
    var _d = __read(useState(false), 2), showFacilitySelect = _d[0], setShowFacilitySelect = _d[1];
    var isOptedOut = isLocked || isInactive || status !== ConsumerRewardOptInStatus.OptedIn;
    useEffect(function () {
        var limitedUserOutOfBounds = !isAdmin && location.pathname !== '/rewards/check-in';
        var noCheckInStep = location.pathname === '/rewards/check-in' && !location.search;
        var navigateToCheckInStart = (limitedUserOutOfBounds && !isOptedOut) || noCheckInStep;
        if (navigateToCheckInStart) {
            navigate('/rewards/check-in?step=mobileNumberInput', {
                replace: true,
            });
        }
    }, [isAdmin, location, navigate, isOptedOut]);
    useEffect(function () {
        if ((facilities === null || facilities === void 0 ? void 0 : facilities.length) === 1) {
            switchFacility(facilities[0]);
            setShowFacilitySelect(false);
        }
    }, [facilities, switchFacility, setShowFacilitySelect]);
    var tabs = useMemo(function () {
        if (!isAdmin && !isOptedOut) {
            return [
                {
                    label: 'Reward Status/Check In',
                    path: '/rewards/check-in?step=mobileNumberInput',
                },
            ];
        }
        if (isOptedOut) {
            return [
                {
                    label: 'Reward Dashboard',
                    path: '/rewards/dashboard',
                },
                {
                    label: 'Reward History',
                    path: '/rewards/history',
                },
                {
                    label: 'Statements',
                    path: '/rewards/statements',
                },
                {
                    label: 'Upcoming Rewards',
                    path: '/rewards/upcoming-rewards',
                },
            ];
        }
        return [
            {
                label: 'Reward Dashboard',
                path: '/rewards/dashboard',
            },
            {
                label: 'Reward Status/Check In',
                path: '/rewards/check-in?step=mobileNumberInput',
            },
            {
                label: 'Reward History',
                path: '/rewards/history',
            },
            {
                label: 'Statements',
                path: '/rewards/statements',
            },
            {
                label: 'Upcoming Rewards',
                path: '/rewards/upcoming-rewards',
            },
        ];
    }, [isAdmin, isOptedOut]);
    if (!isAdmin && isOptedOut) {
        return React.createElement(LimitedOptedOut, { isInactive: isInactive, isLocked: isLocked });
    }
    if (isOptedOut && location.pathname === '/rewards/check-in') {
        navigate('/rewards/dashboard');
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(TabsOuterContainer, null,
            React.createElement(TabNav, { tabs: tabs, disabled: tabsDisabled }),
            React.createElement(Mobile, null,
                React.createElement(BlurBox, null))),
        !showFacilitySelect && (React.createElement(FacilityInfoHeader, { canOptIn: canOptIn, status: status, isLocked: isLocked, isInactive: isInactive, facilityName: facility === null || facility === void 0 ? void 0 : facility.name, setShowFacilitySelect: setShowFacilitySelect, hideFacilitySwitch: (facilities === null || facilities === void 0 ? void 0 : facilities.length) === 1, showCTA: location.pathname !== '/rewards/check-in' })),
        React.createElement(FacilitySelection, { showFacilitySelect: showFacilitySelect, setShowFacilitySelect: setShowFacilitySelect, setTabsDisabled: setTabsDisabled, facilities: facilities, isLoading: isLoading },
            React.createElement(Outlet, null))));
};
var TabsOuterContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n"], ["\n  position: relative;\n"])));
var BlurBox = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  right: 0;\n  top: 0;\n  height: 22px;\n  width: 78px;\n  background: linear-gradient(\n    270deg,\n    #f3f6f6 32.69%,\n    rgba(243, 246, 246, 0) 113.46%\n  );\n"], ["\n  position: absolute;\n  right: 0;\n  top: 0;\n  height: 22px;\n  width: 78px;\n  background: linear-gradient(\n    270deg,\n    #f3f6f6 32.69%,\n    rgba(243, 246, 246, 0) 113.46%\n  );\n"])));
var templateObject_1, templateObject_2;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { DashboardHeaderCard } from './components/DashboardHeaderCard';
import styled, { css } from 'styled-components';
import { InvoiceStats } from './components/InvoiceStats';
import { InvoiceHistoryCard } from './components/InvoiceHistoryCard';
import { Button, PlaceholderLoading } from 'designSystem';
import { PaymentMethodType } from 'types/codegen/hooks';
import { addMobileStyles } from 'utils/addMobileStyles';
import { stackedCardStyles } from '../../../../components/StackedMobileCard';
import { useScreenSizing } from 'hooks';
import { useNavigate } from 'react-router';
import { PastDueCard } from './components/PastDueCard';
export var DashboardView = function (_a) {
    var _b, _c, _d, _e;
    var loading = _a.loading, invoicePayments = _a.invoicePayments, paymentMethods = _a.paymentMethods, invoices = _a.invoices, currentBalance = _a.currentBalance;
    var isMobile = useScreenSizing().isMobile;
    var navigate = useNavigate();
    var hasPastDue = invoices === null || invoices === void 0 ? void 0 : invoices.some(function (i) { return i.isPastDue; });
    var loc = (_c = (_b = paymentMethods === null || paymentMethods === void 0 ? void 0 : paymentMethods.find(function (p) { return p.type === PaymentMethodType.Credit; })) === null || _b === void 0 ? void 0 : _b.sensitive) === null || _c === void 0 ? void 0 : _c.loc;
    var hasBalance = currentBalance > 0;
    var creditLimit = (_d = loc === null || loc === void 0 ? void 0 : loc.limit) !== null && _d !== void 0 ? _d : 0;
    var availableCredit = creditLimit - ((_e = loc === null || loc === void 0 ? void 0 : loc.balance) !== null && _e !== void 0 ? _e : 0);
    var renderContent = function () {
        if (loading) {
            return (React.createElement(LoadingContainer, null,
                React.createElement(PlaceholderLoading, { size: "large" })));
        }
        return (React.createElement(React.Fragment, null,
            isMobile && hasPastDue && React.createElement(PastDueCard, null),
            React.createElement(UpperContainer, null,
                React.createElement(DashboardHeaderCard, { hasBalance: hasBalance, hasPastDue: hasPastDue }),
                React.createElement(InvoiceStats, { currentBalance: currentBalance, availableCredit: availableCredit, creditLimit: creditLimit }),
                isMobile && hasBalance && (React.createElement(Button, { mb: 38, fullWidth: true, size: "xlarge", onClick: function () { return navigate('/invoices/make-payment'); } }, "Make a Payment"))),
            React.createElement(InvoiceHistoryCard, { invoicePayments: invoicePayments === null || invoicePayments === void 0 ? void 0 : invoicePayments.slice(0, 3) })));
    };
    return React.createElement(Container, null, renderContent());
};
var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: 16px;\n  flex: 1;\n  height: 100%;\n  ", "\n"], ["\n  margin: 16px;\n  flex: 1;\n  height: 100%;\n  ", "\n"])), addMobileStyles(css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    margin: 42px 0 0;\n  "], ["\n    margin: 42px 0 0;\n  "])))));
var LoadingContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  height: 100%;\n  width: 100%;\n  justify-content: center;\n  align-items: center;\n  & > div {\n    width: 100%;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  height: 100%;\n  width: 100%;\n  justify-content: center;\n  align-items: center;\n  & > div {\n    width: 100%;\n  }\n"])));
var UpperContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), addMobileStyles(css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    ", ";\n  "], ["\n    display: flex;\n    flex-direction: column;\n    ", ";\n  "])), stackedCardStyles)));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;

import environment from './environment';
var config = {
    aws: {
        api: {
            graphqlUrl: environment.graphqlUrl,
        },
        cognito: {
            appClientId: environment.cognitoAppClientId,
            identityPoolId: environment.cognitoIdentityPoolId,
            userPoolId: environment.cognitoUserPoolId,
            domain: environment.domain,
        },
        region: environment.awsRegion,
    },
    gtmContainerId: environment.gtmContainerId,
    commitHash: environment.commitHash,
    maxAttachmentSize: environment.maxAttachmentSize,
    version: environment.version,
    intercomApp: environment.intercomApp,
    isStaticApp: environment.isStaticApp,
    platform: 'WEB',
    rollbarApiKey: environment.rollbarApiKey,
    rollbarCodeVersion: environment.rollbarCodeVersion,
    rollbarEnvironment: environment.rollbarEnvironment,
    segmentWriteKey: environment.segmentWriteKey,
    updateInterval: environment.updateInterval,
    maintenanceInterval: environment.maintenanceInterval,
    mapboxAccessToken: environment.mapboxAccessToken,
};
export default config;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
export var ProgressBar = function (_a) {
    var percentage = _a.percentage, background = _a.background, color = _a.color;
    return (React.createElement(ProgressBarContainer, { background: background },
        React.createElement(ProgressBarInner, { percentage: percentage, color: color })));
};
var ProgressBarInner = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: ", "%;\n  background-color: ", ";\n  height: 10px;\n"], ["\n  width: ", "%;\n  background-color: ", ";\n  height: 10px;\n"])), function (props) { return props === null || props === void 0 ? void 0 : props.percentage; }, function (props) { return props.color; });
var ProgressBarContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  background-color: ", ";\n  height: 6px;\n  flex: 1;\n\n  margin-top: 10px;\n  align-items: center;\n"], ["\n  display: flex;\n  background-color: ", ";\n  height: 6px;\n  flex: 1;\n\n  margin-top: 10px;\n  align-items: center;\n"])), function (props) { return props.background; });
var templateObject_1, templateObject_2;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { AlACarteSelector } from '../../components/AlACarteSelector/AlACarteSelector';
import { BundleSelector } from '../../components/BundleSelector/BundleSelector';
import { OrderQuantityType } from 'types/enums/OrderQuantityTypeEnum';
export var QuantitySelection = function (_a) {
    var _b, _c;
    var autoContinue = _a.autoContinue, prefillQuantity = _a.prefillQuantity, boostEnabled = _a.boostEnabled, availableFreeVials = _a.availableFreeVials, currentOrderType = _a.currentOrderType, currentQuarterLoyalty = _a.currentQuarterLoyalty, getEvoluxSummary = _a.getEvoluxSummary, orderData = _a.orderData, quoteObj = _a.quoteObj, setCurrentOrderType = _a.setCurrentOrderType, setOrderData = _a.setOrderData, pricingModel = _a.pricingModel, vialsPurchasedLastQuarter = _a.vialsPurchasedLastQuarter;
    var _d = __read(useState((orderData === null || orderData === void 0 ? void 0 : orderData.selectedBulkRate) || {}), 2), selectedBulkRate = _d[0], setSelectedBulkRate = _d[1];
    var _e = __read(useState(prefillQuantity || (orderData === null || orderData === void 0 ? void 0 : orderData.orderType) === OrderQuantityType.A_LA_CARTE
        ? (_b = orderData === null || orderData === void 0 ? void 0 : orderData.quantity) === null || _b === void 0 ? void 0 : _b.toString()
        : ''), 2), selectedQuantity = _e[0], setSelectedQuantity = _e[1];
    useEffect(function () {
        if (autoContinue && prefillQuantity) {
            setCurrentOrderType(OrderQuantityType.A_LA_CARTE);
            setSelectedQuantity(prefillQuantity.toString());
            onQuantityChanged();
        }
    }, [autoContinue, prefillQuantity]);
    var activeRate = function () {
        if (currentOrderType === OrderQuantityType.BULK) {
            return selectedBulkRate;
        }
        else {
            return __assign(__assign({}, calculateTier()), { quantity: selectedQuantity });
        }
    };
    var isLevelUpgradeOrRetain = function () {
        var _a;
        var rate = activeRate();
        var matchingLevel = ((_a = currentQuarterLoyalty === null || currentQuarterLoyalty === void 0 ? void 0 : currentQuarterLoyalty.levels) === null || _a === void 0 ? void 0 : _a.find(function (r) { return r.name === rate.loyaltyLevel; })) || { vialThreshold: 1000 };
        return ((currentQuarterLoyalty === null || currentQuarterLoyalty === void 0 ? void 0 : currentQuarterLoyalty.vialsPurchased) + activeRate().quantity >=
            (matchingLevel === null || matchingLevel === void 0 ? void 0 : matchingLevel.vialThreshold));
    };
    useEffect(function () {
        onQuantityChanged();
    }, [currentOrderType, selectedQuantity, selectedBulkRate, prefillQuantity]);
    var onQuantityChanged = function () {
        var _a, _b, _c;
        setOrderData(__assign(__assign({}, orderData), { selectedBulkRate: selectedBulkRate, orderType: currentOrderType, quantity: prefillQuantity || ((_a = activeRate()) === null || _a === void 0 ? void 0 : _a.quantity), pricePerUnit: (_b = activeRate()) === null || _b === void 0 ? void 0 : _b.rate, currentLevel: ((_c = currentQuarterLoyalty === null || currentQuarterLoyalty === void 0 ? void 0 : currentQuarterLoyalty.pricedWith) === null || _c === void 0 ? void 0 : _c.level) || '', isLevelUpgradeOrRetain: isLevelUpgradeOrRetain(), promotions: currentOrderType === OrderQuantityType.BULK
                ? [
                    {
                        type: currentOrderType,
                    },
                ]
                : [], liveUpgradeRate: !(pricingModel === null || pricingModel === void 0 ? void 0 : pricingModel.includes('CUSTOM_')) && activeRate().loyaltyLevel, shippingMethod: {} }));
    };
    var currentQuantity = function () {
        var _a;
        if (currentOrderType === OrderQuantityType.BULK) {
            return ((_a = activeRate()) === null || _a === void 0 ? void 0 : _a.quantity) || 0;
        }
        else {
            return selectedQuantity;
        }
    };
    var sortPrices = function () {
        if (!(quoteObj === null || quoteObj === void 0 ? void 0 : quoteObj.pricing)) {
            return [];
        }
        var _a = quoteObj.pricing, defaultRate = _a.defaultRate, rates = _a.rates;
        var defaultPricing = { quantity: -1, rate: defaultRate };
        var sorted = rates.sort(function (r1, r2) { return r1.quantity - r2.quantity; });
        return __spreadArray([defaultPricing], __read(sorted), false);
    };
    var sortedPricesMemo = useMemo(function () { return sortPrices(); }, []);
    var calculateTier = function () {
        if (!sortedPricesMemo.length) {
            return 0;
        }
        var higherIndex = sortedPricesMemo.findIndex(function (p) { return p.quantity > currentQuantity(); });
        var index = higherIndex === -1 ? sortedPricesMemo.length - 1 : higherIndex - 1;
        return sortedPricesMemo[index];
    };
    if (autoContinue) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(SwitchContainer, { availableFreeVials: false, "data-automation-id": "order-quantity-type", "data-order-type": currentOrderType },
            React.createElement(SwitchButtonLeft, { onClick: function () { return setCurrentOrderType(OrderQuantityType.A_LA_CARTE); }, active: currentOrderType === OrderQuantityType.A_LA_CARTE, "data-automation-id": "order-quantity-type-a-la-carte" }, "A La Carte"),
            React.createElement(SwitchButtonRight, { onClick: function () { return setCurrentOrderType(OrderQuantityType.BULK); }, active: currentOrderType === OrderQuantityType.BULK, "data-automation-id": "order-quantity-type-bundle" }, "Bundle")),
        React.createElement(ProductSelectionContainer, null, currentOrderType === OrderQuantityType.BULK ? (React.createElement(BundleSelector, { setSelectedBulkRate: setSelectedBulkRate, orderData: orderData, currentLevel: ((_c = currentQuarterLoyalty === null || currentQuarterLoyalty === void 0 ? void 0 : currentQuarterLoyalty.pricedWith) === null || _c === void 0 ? void 0 : _c.level) || '', boostEligible: boostEnabled && vialsPurchasedLastQuarter >= 30, getEvoluxSummary: getEvoluxSummary, msrp: quoteObj.pricing.msrp, defaultRate: quoteObj.pricing.defaultRate, bulkRates: quoteObj.pricing.bulkRates, pricingModel: pricingModel, availableFreeVials: availableFreeVials })) : (React.createElement(AlACarteSelector, { msrp: quoteObj.pricing.msrp, defaultRate: quoteObj.pricing.defaultRate, selectedQuantity: selectedQuantity, setSelectedQuantity: setSelectedQuantity, availableFreeVials: availableFreeVials })))));
};
var ProductSelectionContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  position: relative;\n"], ["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  position: relative;\n"])));
var SwitchButton = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  all: unset;\n  cursor: pointer;\n  display: flex;\n  flex: 1;\n  justify-content: center;\n  align-items: center;\n  width: 88px;\n  height: 32px;\n  font-size: 0.75rem;\n  font-weight: 600;\n  box-sizing: border-box;\n  -moz-box-sizing: border-box;\n  -webkit-box-sizing: border-box;\n  border: ", ";\n  ", ";\n"], ["\n  all: unset;\n  cursor: pointer;\n  display: flex;\n  flex: 1;\n  justify-content: center;\n  align-items: center;\n  width: 88px;\n  height: 32px;\n  font-size: 0.75rem;\n  font-weight: 600;\n  box-sizing: border-box;\n  -moz-box-sizing: border-box;\n  -webkit-box-sizing: border-box;\n  border: ", ";\n  ", ";\n"])), function (props) {
    return props.active ? 'none' : "1px solid ".concat(props.theme.colors.border.error);
}, function (props) { return props.active && props.theme.elevation.button.elevation1; });
var SwitchContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  position: absolute;\n  top: 82px;\n  right: 48px;\n  z-index: 99;\n\n  @media only screen and (max-width: ", "px) {\n    position: relative;\n    top: ", "px;\n    right: 0;\n    margin-bottom: 32px;\n    align-self: center;\n  }\n"], ["\n  display: flex;\n  position: absolute;\n  top: 82px;\n  right: 48px;\n  z-index: 99;\n\n  @media only screen and (max-width: ", "px) {\n    position: relative;\n    top: ", "px;\n    right: 0;\n    margin-bottom: 32px;\n    align-self: center;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
}, function (props) { return (props.availableFreeVials ? 72 : 0); });
var SwitchButtonLeft = styled(SwitchButton)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background-color: ", ";\n  color: ", ";\n"], ["\n  background-color: ", ";\n  color: ", ";\n"])), function (props) {
    return (props === null || props === void 0 ? void 0 : props.active)
        ? props.theme.colors.bg.accent
        : props.theme.colors.basic.white;
}, function (props) { return ((props === null || props === void 0 ? void 0 : props.active) ? 'white' : props.theme.colors.text.link); });
var SwitchButtonRight = styled(SwitchButton)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background-color: ", ";\n  color: ", ";\n"], ["\n  background-color: ", ";\n  color: ", ";\n"])), function (props) {
    return (props === null || props === void 0 ? void 0 : props.active)
        ? props.theme.colors.bg.accent
        : props.theme.colors.basic.white;
}, function (props) { return ((props === null || props === void 0 ? void 0 : props.active) ? 'white' : props.theme.colors.text.link); });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;

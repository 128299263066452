var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useCallback } from 'react';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';
import styled from 'styled-components';
var PageTabsContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  margin-left: 16px;\n\n  @media only screen and (max-width: ", "px) {\n    margin-left: 32px;\n  }\n"], ["\n  display: flex;\n  margin-left: 16px;\n\n  @media only screen and (max-width: ", "px) {\n    margin-left: 32px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var tabs = [
    {
        label: 'Profile',
        path: '/profile-settings/profile',
    },
    {
        label: 'Change Password',
        path: '/profile-settings/change-password',
    },
];
var Tab = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  all: unset;\n  cursor: pointer;\n  margin-right: 25px;\n  color: ", ";\n  padding-bottom: 5px;\n  border-bottom: ", ";\n"], ["\n  all: unset;\n  cursor: pointer;\n  margin-right: 25px;\n  color: ", ";\n  padding-bottom: 5px;\n  border-bottom: ", ";\n"])), function (props) {
    return (props === null || props === void 0 ? void 0 : props.active)
        ? props === null || props === void 0 ? void 0 : props.theme.colors.text.link
        : props === null || props === void 0 ? void 0 : props.theme.colors.text.alt;
}, function (props) { return ((props === null || props === void 0 ? void 0 : props.active) ? '2.1px solid red' : 'none'); });
export var ProfileSettings = function () {
    var navigate = useNavigate();
    var location = useLocation();
    var getActivePath = useCallback(function (tab) {
        return location.pathname.includes(tab.path);
    }, [location]);
    return (React.createElement(React.Fragment, null,
        React.createElement(PageTabsContainer, null, tabs.map(function (tab) { return (React.createElement(Tab, { key: tab.path, active: getActivePath(tab), onClick: function () { return navigate(tab.path); } }, tab.label)); })),
        React.createElement(Outlet, null)));
};
var templateObject_1, templateObject_2;

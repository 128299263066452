import React from 'react';
import { Typography } from 'designSystem';
import NotYetValidImage from 'assets/waiting.svg';
import { StatusContainer } from './StatusContainer';
import { useScreenSizing } from 'hooks';
export var RewardNotYetValid = function (_a) {
    var validDate = _a.validDate;
    var isMobile = useScreenSizing().isMobile;
    return (React.createElement(StatusContainer, null,
        React.createElement("div", null,
            React.createElement(Typography, { variant: "titleLarge", mt: 8, mb: 8, color: "link" }, "Reward Not Yet Valid"),
            React.createElement(Typography, { align: isMobile ? 'center' : 'left', variant: "body", mb: 16 },
                "No discount available today, existing reward valid for next visit* valid ",
                validDate,
                "."),
            React.createElement(Typography, { variant: "footnote", color: "alt" }, "*Patient eligible to redeem reward once every 90 days. Rewards expire 547 days after date of issuance.")),
        React.createElement("img", { src: NotYetValidImage, alt: "reward-not-yet-valid" })));
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { useUser } from 'context';
import styled, { css } from 'styled-components';
import { Button, Typography } from 'designSystem';
import { cardStyles } from '../constants/CardStyles';
import { addMobileStyles } from 'utils/addMobileStyles';
import { useScreenSizing } from 'hooks';
import { PastDueCard } from './PastDueCard';
import { useNavigate } from 'react-router';
export var DashboardHeaderCard = function (_a) {
    var hasBalance = _a.hasBalance, hasPastDue = _a.hasPastDue;
    var account = useUser().account;
    var isMobile = useScreenSizing().isMobile;
    var navigate = useNavigate();
    return (React.createElement(Container, null,
        React.createElement("div", null,
            React.createElement(Typography, { align: isMobile ? 'center' : 'left', display: "block", mb: 4, variant: isMobile ? 'bodySmall' : 'subtitle', color: "alt" },
                !isMobile && 'Account ID: ', account === null || account === void 0 ? void 0 :
                account.id),
            React.createElement(Typography, { align: isMobile ? 'center' : 'left', display: "block", variant: isMobile ? 'title' : 'titleLarge' }, account === null || account === void 0 ? void 0 : account.name)),
        !isMobile && (React.createElement("div", null,
            hasPastDue && React.createElement(PastDueCard, null),
            hasBalance && (React.createElement(Button, { variant: "primary", size: "xlarge", onClick: function () { return navigate('/invoices/make-payment'); } }, "Make a Payment"))))));
};
var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  padding: 42px 48px;\n  & > div:last-of-type {\n    display: flex;\n    gap: 16px;\n    align-items: center;\n  }\n  ", "\n"], ["\n  ", ";\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  padding: 42px 48px;\n  & > div:last-of-type {\n    display: flex;\n    gap: 16px;\n    align-items: center;\n  }\n  ", "\n"])), cardStyles, addMobileStyles(css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    box-shadow: none;\n    border: none;\n    background: none;\n    padding: 0;\n    flex-direction: column;\n    & > div:first-of-type {\n      display: flex;\n      flex-direction: column-reverse;\n      align-items: unset;\n      gap: unset;\n    }\n  "], ["\n    box-shadow: none;\n    border: none;\n    background: none;\n    padding: 0;\n    flex-direction: column;\n    & > div:first-of-type {\n      display: flex;\n      flex-direction: column-reverse;\n      align-items: unset;\n      gap: unset;\n    }\n  "])))));
var templateObject_1, templateObject_2;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { PaymentStatus } from 'types/codegen/hooks';
import { useUser } from 'context';
import { StepContainer } from '../StepContainer';
import { SubHeaderByOption } from '../../enums/SubHeaderByOption';
import styled, { css } from 'styled-components';
import PaymentSuccessGraphic from 'assets/order-success-graphic.svg';
import { Button, Typography } from 'designSystem';
import { currencyFormatter } from 'utils/currencyFormatter';
import { format } from 'date-fns';
import { StatusChip } from '../../../../components/StatusChip';
import { useNavigate } from 'react-router';
import { InvoicesSettledTable } from '../../../../components/InvoicesSettledTable';
import { addMobileStyles } from 'utils/addMobileStyles';
import { useScreenSizing } from 'hooks';
import { PaymentMethodType, getSelectedPaymentMethod, } from '../../../../../../utils/getSelectedPaymentMethod';
import { SensitivePaymentType } from 'types/types';
var getPaymentName = function (pm) {
    var _a = pm.sensitive, type = _a.type, cc = _a.cc, ach = _a.ach;
    if (type === SensitivePaymentType.Cc) {
        return (React.createElement("div", null,
            React.createElement(Typography, { variant: "headline", display: "block" }, pm.name),
            React.createElement(Typography, { variant: "headline" }, "".concat(cc.network, " ending in ").concat(cc.number.substring(cc.number.length - 4)))));
    }
    return (React.createElement("div", null,
        React.createElement(Typography, { display: "block", variant: "headline" }, ach === null || ach === void 0 ? void 0 : ach.accountName),
        React.createElement(Typography, { variant: "headline" }, ach === null || ach === void 0 ? void 0 : ach.accountNumber)));
};
export var PaymentOverview = function (_a) {
    var paymentOption = _a.paymentOption, paymentAmount = _a.paymentAmount, paymentId = _a.paymentId, paymentMethod = _a.paymentMethod, invoices = _a.invoices;
    var user = useUser().user;
    var isMobile = useScreenSizing().isMobile;
    var navigate = useNavigate();
    return (React.createElement(OuterContainer, null,
        React.createElement(HeaderBanner, null,
            React.createElement(Typography, { color: "alt3", variant: "headline" }, "Thanks for your payment.")),
        React.createElement(StepContainer, { subHeader: SubHeaderByOption[paymentOption], header: "Payment Overview", tertiaryHeader: "Your payment has been submitted and will be charged soon.", imageOverlay: isMobile ? null : PaymentSuccessGraphic },
            isMobile && (React.createElement(Image, { src: PaymentSuccessGraphic, alt: "payment-success" })),
            React.createElement(InnerContainer, null,
                React.createElement(Typography, { display: "block", mb: isMobile ? 24 : 36, variant: "sectionHeader" }, "Payment Summary"),
                React.createElement("div", null,
                    React.createElement(Typography, { variant: "headline", color: "alt" }, "Amount Paid"),
                    React.createElement(Typography, { variant: "titleLarge" }, currencyFormatter(paymentAmount, 2))),
                React.createElement("div", null,
                    React.createElement(Typography, { variant: "headline", color: "alt" }, "Payment ID"),
                    React.createElement(Typography, { variant: "headline", color: "alt5" }, paymentId)),
                React.createElement("div", null,
                    React.createElement(Typography, { variant: "headline", color: "alt" }, "Payment Info"),
                    React.createElement("div", null,
                        React.createElement(Typography, { variant: "headline" },
                            "Payment made on ",
                            format(new Date(), 'MM/dd/yy')),
                        React.createElement(Typography, { variant: "headline" },
                            "by ",
                            user.firstName,
                            " ",
                            user.lastName))),
                React.createElement("div", null,
                    React.createElement(Typography, { variant: "headline", color: "alt" }, "Payment Method"),
                    getPaymentName(getSelectedPaymentMethod(paymentMethod, PaymentMethodType.primary))),
                React.createElement("div", null,
                    React.createElement(Typography, { variant: "headline", color: "alt" },
                        "Payment",
                        isMobile ? React.createElement("br", null) : ' ',
                        "Status"),
                    React.createElement(StatusChip, { status: PaymentStatus.Pending })),
                React.createElement(Divider, null),
                React.createElement(InvoicesSettledTable, { status: PaymentStatus.Pending, invoices: invoices, invoicesApplied: [] })),
            React.createElement(ActionContainer, null,
                React.createElement(Button, { fullWidth: isMobile, variant: "outlined", size: "xlarge", onClick: function () {
                        navigate('/invoices/dashboard');
                    } }, "Back to Dashboard")))));
};
var OuterContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n"], ["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n"])));
var HeaderBanner = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 20px 48px;\n  background: ", ";\n"], ["\n  padding: 20px 48px;\n  background: ", ";\n"])), function (props) { return props.theme.colors.basic.black; });
var InnerContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  background: ", ";\n  z-index: 1;\n  margin: 56px 72px 32px;\n  padding: 64px 96px;\n  & > div:not(:last-of-type) {\n    display: flex;\n    align-items: center;\n    gap: 89px;\n    margin-bottom: 48px;\n    & > div {\n      display: flex;\n      flex-direction: column;\n    }\n    & > span:first-of-type {\n      width: 155px;\n    }\n  }\n  ", "\n"], ["\n  ", ";\n  background: ", ";\n  z-index: 1;\n  margin: 56px 72px 32px;\n  padding: 64px 96px;\n  & > div:not(:last-of-type) {\n    display: flex;\n    align-items: center;\n    gap: 89px;\n    margin-bottom: 48px;\n    & > div {\n      display: flex;\n      flex-direction: column;\n    }\n    & > span:first-of-type {\n      width: 155px;\n    }\n  }\n  ", "\n"])), function (props) { return props.theme.borders.base; }, function (props) { return props.theme.colors.bg.body; }, addMobileStyles(css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    margin: 24px 0 0;\n    padding: 0;\n    border: none;\n    & > div:not(:last-of-type) {\n      gap: 8px;\n      margin-bottom: 24px;\n      & > span:first-of-type {\n        width: 120px;\n      }\n    }\n  "], ["\n    margin: 24px 0 0;\n    padding: 0;\n    border: none;\n    & > div:not(:last-of-type) {\n      gap: 8px;\n      margin-bottom: 24px;\n      & > span:first-of-type {\n        width: 120px;\n      }\n    }\n  "])))));
var Divider = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin: 48px 36px;\n  ", ";\n  ", "\n"], ["\n  margin: 48px 36px;\n  ", ";\n  ", "\n"])), function (props) { return props.theme.borders.base; }, addMobileStyles(css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    margin: 24px 0;\n  "], ["\n    margin: 24px 0;\n  "])))));
var ActionContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  justify-content: flex-end;\n  ", "\n"], ["\n  display: flex;\n  flex: 1;\n  justify-content: flex-end;\n  ", "\n"])), addMobileStyles(css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    margin-top: 48px;\n  "], ["\n    margin-top: 48px;\n  "])))));
var Image = styled.img(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  width: 100%;\n  max-width: 200px;\n  align-self: center;\n  justify-self: center;\n  margin-top: 24px;\n"], ["\n  width: 100%;\n  max-width: 200px;\n  align-self: center;\n  justify-self: center;\n  margin-top: 24px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;

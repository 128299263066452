var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled, { css } from 'styled-components';
import { Typography } from '../Typography';
function getSize(size, noPadding, isOutline) {
    if (noPadding) {
        return 'padding: 0';
    }
    var mappedSize = "".concat(isOutline ? 'outline-' : '').concat(size);
    var sizes = {
        xlarge: 'padding: 16px 24px 16px 24px;',
        large: 'padding: 10px 24px 10px 24px;',
        medium: 'padding: 8px 12px 8px 12px;',
        small: 'padding: 6px 8px 6px 8px;',
        'outline-xlarge': 'padding: 14px 22px 14px 22px;',
        'outline-large': 'padding: 8px 22px 8px 22px;',
        'outline-medium': 'padding: 6px 10px 6px 10px;',
        'outline-small': 'padding: 4px 6px 4px 6px;',
    };
    return sizes[mappedSize];
}
function getFontSize(size) {
    var sizes = {
        xlarge: 'action',
        large: 'action',
        medium: 'action',
        small: 'headlineSmall',
    };
    return sizes[size];
}
function getVariant(variant) {
    var variants = {
        primary: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      background-color: ", ";\n      color: ", ";\n      border: 0;\n      ", "\n    "], ["\n      background-color: ", ";\n      color: ", ";\n      border: 0;\n      ", "\n    "])), function (props) { return props.theme.colors.coral.coral500; }, function (props) { return props.theme.colors.text.alt3; }, function (_a) {
            var theme = _a.theme;
            return theme.elevation.button.elevation1;
        }),
        secondary: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      background-color: ", ";\n      color: ", ";\n      border: 0;\n    "], ["\n      background-color: ", ";\n      color: ", ";\n      border: 0;\n    "])), function (props) { return props.theme.colors.white.white500; }, function (props) { return props.theme.colors.text.base; }),
        tertiary: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      background-color: transparent;\n      color: ", ";\n      border: 0;\n    "], ["\n      background-color: transparent;\n      color: ", ";\n      border: 0;\n    "])), function (props) { return props.theme.colors.coral.coral500; }),
        quaternary: css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      background-color: transparent;\n      color: ", ";\n      border: 0;\n    "], ["\n      background-color: transparent;\n      color: ", ";\n      border: 0;\n    "])), function (props) { return props.theme.colors.text.base; }),
        outlined: css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      background-color: transparent;\n      color: ", ";\n      border: 2px solid ", ";\n    "], ["\n      background-color: transparent;\n      color: ", ";\n      border: 2px solid ", ";\n    "])), function (props) { return props.theme.colors.coral.coral500; }, function (props) { return props.theme.colors.coral.coral500; }),
        outlinedSecondary: css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n      background-color: transparent;\n      color: ", ";\n      border: 2px solid ", ";\n    "], ["\n      background-color: transparent;\n      color: ", ";\n      border: 2px solid ", ";\n    "])), function (props) { return props.theme.colors.text.alt; }, function (props) { return props.theme.colors.white.white600; }),
    };
    return variants[variant];
}
function getHoverVariant(variant) {
    var variants = {
        primary: css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n      background-color: #df312a;\n    "], ["\n      background-color: #df312a;\n    "]))),
        secondary: css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n      background-color: ", ";\n    "], ["\n      background-color: ", ";\n    "])), function (props) { return props.theme.colors.bg.alt; }),
        tertiary: css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n      background-color: ", ";\n      color: ", ";\n    "], ["\n      background-color: ", ";\n      color: ", ";\n    "])), function (props) { return props.theme.colors.bg.error; }, function (props) { return props.theme.colors.coral.coral500; }),
        quaternary: css(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n      background-color: ", ";\n      color: ", ";\n    "], ["\n      background-color: ", ";\n      color: ", ";\n    "])), function (props) { return props.theme.colors.white.white300; }, function (props) { return props.theme.colors.text.base; }),
        outlined: css(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n      background-color: ", ";\n    "], ["\n      background-color: ", ";\n    "])), function (props) { return props.theme.colors.bg.error; }),
        outlinedSecondary: css(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n      background-color: ", ";\n      color: ", ";\n      border: 2px solid ", ";\n    "], ["\n      background-color: ", ";\n      color: ", ";\n      border: 2px solid ", ";\n    "])), function (props) { return props.theme.colors.white.white500; }, function (props) { return props.theme.colors.text.alt; }, function (props) { return props.theme.colors.white.white600; }),
    };
    return variants[variant];
}
function getPressedVariant(variant) {
    var variants = {
        primary: css(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n      background-color: #d52720;\n    "], ["\n      background-color: #d52720;\n    "]))),
        secondary: css(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n      background-color: #d52720;\n      color: ", ";\n    "], ["\n      background-color: #d52720;\n      color: ", ";\n    "])), function (props) { return props.theme.colors.text.base; }),
        tertiary: css(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n      background-color: ", ";\n    "], ["\n      background-color: ", ";\n    "])), function (props) { return props.theme.colors.coral.coral50; }),
        quaternary: css(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n      background-color: ", ";\n    "], ["\n      background-color: ", ";\n    "])), function (props) { return props.theme.colors.white.white500; }),
        outlined: css(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n      background-color: ", ";\n    "], ["\n      background-color: ", ";\n    "])), function (props) { return props.theme.colors.coral.coral50; }),
        outlinedSecondary: css(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n      background-color: ", ";\n    "], ["\n      background-color: ", ";\n    "])), function (props) { return props.theme.colors.bg.alt; }),
    };
    return variants[variant];
}
function getDisabledVariant(variant) {
    var variants = {
        primary: css(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n      background-color: ", ";\n      color: ", ";\n    "], ["\n      background-color: ", ";\n      color: ", ";\n    "])), function (props) { return props.theme.colors.white.white500; }, function (props) { return props.theme.colors.text.alt; }),
        secondary: css(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n      background-color: ", ";\n      color: ", ";\n    "], ["\n      background-color: ", ";\n      color: ", ";\n    "])), function (props) { return props.theme.colors.white.white500; }, function (props) { return props.theme.colors.text.alt; }),
        tertiary: css(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n      background-color: transparent;\n      color: ", ";\n    "], ["\n      background-color: transparent;\n      color: ", ";\n    "])), function (props) { return props.theme.colors.text.alt; }),
        quaternary: css(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n      background-color: transparent;\n      color: ", ";\n    "], ["\n      background-color: transparent;\n      color: ", ";\n    "])), function (props) { return props.theme.colors.text.alt; }),
        outlined: css(templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n      background-color: transparent;\n      color: ", ";\n      border: 2px solid ", ";\n    "], ["\n      background-color: transparent;\n      color: ", ";\n      border: 2px solid ", ";\n    "])), function (props) { return props.theme.colors.text.alt5; }, function (props) { return props.theme.colors.white.white600; }),
        outlinedSecondary: css(templateObject_24 || (templateObject_24 = __makeTemplateObject(["\n      background-color: transparent;\n      color: ", ";\n      border: 2px solid ", ";\n    "], ["\n      background-color: transparent;\n      color: ", ";\n      border: 2px solid ", ";\n    "])), function (props) { return props.theme.colors.text.alt5; }, function (props) { return props.theme.colors.white.white600; }),
    };
    return variants[variant];
}
var Root = styled.button(templateObject_25 || (templateObject_25 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n  text-decoration: none;\n  cursor: pointer;\n  ", ";\n  ", ";\n  ", "\n  margin-top: ", "px;\n  margin-bottom: ", "px;\n\n  &:hover {\n    ", ";\n  }\n\n  &:active {\n    ", ";\n  }\n\n  &:disabled,\n  &[disabled] {\n    ", ";\n    cursor: not-allowed;\n    box-shadow: none;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n  text-decoration: none;\n  cursor: pointer;\n  ", ";\n  ", ";\n  ", "\n  margin-top: ", "px;\n  margin-bottom: ", "px;\n\n  &:hover {\n    ", ";\n  }\n\n  &:active {\n    ", ";\n  }\n\n  &:disabled,\n  &[disabled] {\n    ", ";\n    cursor: not-allowed;\n    box-shadow: none;\n  }\n"])), function (props) {
    var _a, _b;
    return getSize((_a = props.size) !== null && _a !== void 0 ? _a : 'medium', props.noPadding, (_b = props.variant) === null || _b === void 0 ? void 0 : _b.includes('outlined'));
}, function (props) { var _a; return getVariant((_a = props.variant) !== null && _a !== void 0 ? _a : 'primary'); }, function (props) { return props.fullWidth && 'width: 100%;'; }, function (props) { var _a; return (_a = props.mt) !== null && _a !== void 0 ? _a : 0; }, function (props) { var _a; return (_a = props.mb) !== null && _a !== void 0 ? _a : 0; }, function (props) { var _a; return getHoverVariant((_a = props.variant) !== null && _a !== void 0 ? _a : 'primary'); }, function (props) { var _a; return getPressedVariant((_a = props.variant) !== null && _a !== void 0 ? _a : 'primary'); }, function (props) { var _a; return getDisabledVariant((_a = props.variant) !== null && _a !== void 0 ? _a : 'primary'); });
var StartIconContainer = styled.div(templateObject_26 || (templateObject_26 = __makeTemplateObject(["\n  margin-right: 9px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"], ["\n  margin-right: 9px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"])));
var EndIconContainer = styled.div(templateObject_27 || (templateObject_27 = __makeTemplateObject(["\n  margin-left: 9px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"], ["\n  margin-left: 9px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"])));
export var Button = function (_a) {
    var startIcon = _a.startIcon, endIcon = _a.endIcon, text = _a.text, _b = _a.size, size = _b === void 0 ? 'medium' : _b, variant = _a.variant, children = _a.children, props = __rest(_a, ["startIcon", "endIcon", "text", "size", "variant", "children"]);
    return (React.createElement(Root, __assign({ size: size, variant: variant }, props),
        startIcon && React.createElement(StartIconContainer, null, startIcon),
        React.createElement(Typography, { variant: getFontSize(size), color: "inherit" }, children),
        endIcon && React.createElement(EndIconContainer, null, endIcon)));
};
export var IconButton = React.forwardRef(function (_a, ref) {
    var startIcon = _a.startIcon, endIcon = _a.endIcon, text = _a.text, _b = _a.size, size = _b === void 0 ? 'small' : _b, variant = _a.variant, children = _a.children, props = __rest(_a, ["startIcon", "endIcon", "text", "size", "variant", "children"]);
    return (React.createElement(Root, __assign({ size: size, variant: variant }, props, { ref: ref }), children));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23, templateObject_24, templateObject_25, templateObject_26, templateObject_27;

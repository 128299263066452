var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { IconButton } from 'designSystem';
import { SpeechBubble } from 'designSystem/components/Icon/icons';
import styled from 'styled-components';
export var IntercomButtonView = function () {
    return (React.createElement(Root, { onClick: function () { } },
        React.createElement(SpeechBubble, { height: "0.92em", color: "inherit" })));
};
var Root = styled(IconButton)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-radius: 100px;\n  ", "\n  padding: 18px 28px;\n  width: fit-content;\n  z-index: 2;\n  position: fixed;\n  bottom: 16px;\n  right: 64px;\n  transform: translate(50%, -50%);\n  & > svg {\n    font-size: 24px;\n  }\n"], ["\n  border-radius: 100px;\n  ", "\n  padding: 18px 28px;\n  width: fit-content;\n  z-index: 2;\n  position: fixed;\n  bottom: 16px;\n  right: 64px;\n  transform: translate(50%, -50%);\n  & > svg {\n    font-size: 24px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.elevation.button.elevation1;
});
var templateObject_1;

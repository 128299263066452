var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { AccountPaymentMethodsView } from './View';
import { useUser } from 'context';
export var AccountPaymentMethodsContainer = function (props) {
    var account = useUser().account;
    return (React.createElement(AccountPaymentMethodsView, __assign({}, props, { paymentMethods: account === null || account === void 0 ? void 0 : account.paymentMethods, loading: false })));
};

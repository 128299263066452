import React from 'react';
export var ToastPending = function () { return (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { clipPath: "url(#clip0_6342_225227)" },
        React.createElement("path", { d: "M11.9922 0.979492V4.65296", stroke: "#D88218", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M11.9922 23.0211V19.3477", stroke: "#D88218", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M4.20312 4.20703L6.801 6.80491", stroke: "#D88218", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M19.7932 19.7922L17.1953 17.1953", stroke: "#D88218", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M0.976562 12H4.65003", stroke: "#D88218", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M23.0172 12H19.3438", stroke: "#D88218", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M4.20312 19.7922L6.801 17.1953", stroke: "#D88218", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M19.7932 4.20703L17.1953 6.80491", stroke: "#D88218", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" })),
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "clip0_6342_225227" },
            React.createElement("rect", { width: "23.9959", height: "24", fill: "white" }))))); };

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { Modal } from '../Modal';
import styled from 'styled-components';
import { Button, Typography } from 'designSystem';
import { addMobileStyles } from 'utils/addMobileStyles';
export var ApplicationUpdate = function (_a) {
    var refresh = _a.refresh;
    return (React.createElement(Modal, { showClose: false, size: "small" },
        React.createElement(ContentContainer, null,
            React.createElement("div", null,
                React.createElement(Typography, { variant: "titleLarge", color: "link", align: "center" }, "Hey there! We've just updated our system."),
                React.createElement(Typography, { variant: "title", align: "center" }, "Refresh the page for the most updated version."),
                React.createElement(ActionsContainer, null,
                    React.createElement(Button, { variant: "primary", size: "xlarge", onClick: function () { return refresh(); } }, "Refresh"))))));
};
var ContentContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  align-items: center;\n  & > div:first-of-type {\n    display: flex;\n    flex-direction: column;\n    flex: 1;\n    gap: 16px;\n    & > div:last-of-type {\n      margin-top: 66px;\n    }\n  }\n  ", "\n"], ["\n  display: flex;\n  flex: 1;\n  align-items: center;\n  & > div:first-of-type {\n    display: flex;\n    flex-direction: column;\n    flex: 1;\n    gap: 16px;\n    & > div:last-of-type {\n      margin-top: 66px;\n    }\n  }\n  ", "\n"])), addMobileStyles("\n    width: 100%;\n    flex-direction: column-reverse;\n    align-items: stretch;\n    & > div:first-of-type {\n      justify-content: center;\n      align-items: center;\n      & > span {\n        text-align: center;\n      }\n    }\n  "));
var ActionsContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  justify-content: flex-end;\n  align-items: flex-end;\n  gap: 12px;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n    & > button {\n      width: 100%;\n    }\n  }\n"], ["\n  display: flex;\n  width: 100%;\n  justify-content: flex-end;\n  align-items: flex-end;\n  gap: 12px;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n    & > button {\n      width: 100%;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var templateObject_1, templateObject_2;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Button, Typography } from 'designSystem/components';
import { useModal } from 'context/ModalContext';
import React from 'react';
import { useNavigate } from 'react-router';
import styled, { withTheme } from 'styled-components';
import RewardsCheckInSuspended from 'assets/rewards-check-in-suspended.svg';
import { Modal } from '../Modal';
var CheckInSuspended = function (_a) {
    var theme = _a.theme;
    var navigate = useNavigate();
    var closeModal = useModal().closeModal;
    var onClose = function () {
        closeModal();
        navigate('/rewards/dashboard');
    };
    return (React.createElement(Modal, { backdropColor: theme.colors.bg.accent },
        React.createElement(ContentContainer, null,
            React.createElement(CloseButton, { onClick: function () { return onClose(); } }),
            React.createElement(ImageContainer, null,
                React.createElement(Image, { src: RewardsCheckInSuspended })),
            React.createElement(Typography, { mt: 32, mb: 32, align: "center", variant: "display" }, "Patient check-in has been disabled"),
            React.createElement(Typography, { mb: 32, align: "center", variant: "subtitle", color: "alt" }, "Looks like your account is inactive on Evolus Rewards."),
            React.createElement(Typography, { mb: 32, align: "center", variant: "subtitle", color: "alt" }, "We\u2019ve recently aligned Evolux status and Evolus Rewards and require a purchase of at least 10 vials in the current quarter to continue participating in the Rewards program."),
            React.createElement(Typography, { mb: 48, align: "center", variant: "subtitle", color: "alt" }, "Please head to the Practice App to make a purchase or contact your rep today!"),
            React.createElement(ButtonContainer, null,
                React.createElement(Button, { size: "large", variant: "primary", onClick: function () {
                        onClose();
                    } }, "Okay")))));
};
var ContentContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  align-items: center;\n  z-index: 10000;\n  position: relative;\n"], ["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  align-items: center;\n  z-index: 10000;\n  position: relative;\n"])));
var CloseButton = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  right: -40px;\n  top: -30px;\n  width: 40px;\n  height: 40px;\n  cursor: pointer;\n"], ["\n  position: absolute;\n  right: -40px;\n  top: -30px;\n  width: 40px;\n  height: 40px;\n  cursor: pointer;\n"])));
var ImageContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"])));
var Image = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  max-width: 285px;\n  width: 100%;\n"], ["\n  max-width: 285px;\n  width: 100%;\n"])));
var ButtonContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n  font-size: 1rem;\n  width: 100%;\n  margin-top: 27px;\n\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n  }\n"], ["\n  display: flex;\n  justify-content: flex-end;\n  font-size: 1rem;\n  width: 100%;\n  margin-top: 27px;\n\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
export default withTheme(CheckInSuspended);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { TransactionInformation } from '../components/TransactionInformation';
import RewardsProgress from '../../../../components/RewardsProgress';
import { UpcomingRewardsDownloader } from './components/UpcomingRewardsDownloader';
import { Desktop, Mobile } from 'designSystem/components/Responsive';
import { PlaceholderLoading } from 'designSystem';
import { CheckStatusCard } from './components/CheckStatusCard';
import { RewardsCounter } from '../../../../components/RewardsCounter';
import { ConsumerRewardOptInStatus } from 'types/codegen/hooks';
export var RewardsDashboardView = function (_a) {
    var loading = _a.loading, consumerRewardOptInStatus = _a.consumerRewardOptInStatus;
    if (loading) {
        return (React.createElement(LoadingContainer, null,
            React.createElement(PlaceholderLoading, { size: "small" })));
    }
    return (React.createElement(OuterContainer, null,
        React.createElement(Mobile, null,
            React.createElement(TransactionInformation, null),
            React.createElement(RewardsProgress, null),
            React.createElement(RewardsCounter, null),
            React.createElement(CheckStatusCard, null),
            React.createElement(UpcomingRewardsDownloader, null)),
        React.createElement(Desktop, null,
            React.createElement(RowContainer, null,
                React.createElement(FlexContainer, null,
                    React.createElement(TransactionInformation, null)),
                React.createElement(FlexContainer, null,
                    React.createElement(RewardsProgress, null))),
            React.createElement(RowContainer, null,
                React.createElement(FlexContainer, null,
                    React.createElement(UpcomingRewardsDownloader, null)),
                React.createElement(FlexContainer, null,
                    React.createElement(RewardsCounter, null))),
            consumerRewardOptInStatus === ConsumerRewardOptInStatus.OptedIn && (React.createElement(RowContainer, null,
                React.createElement(FlexContainer, null,
                    React.createElement(CheckStatusCard, null)),
                React.createElement(FlexContainer, null))))));
};
var OuterContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n"])));
var RowContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n"], ["\n  display: flex;\n  flex: 1;\n"])));
var LoadingContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex: 1;\n  margin-top: 32px;\n  ", ";\n  padding: 200px 0;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex: 1;\n  margin-top: 32px;\n  ", ";\n  padding: 200px 0;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.card.default;
});
var FlexContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  flex: 1;\n  display: flex;\n"], ["\n  flex: 1;\n  display: flex;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useCallback, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import OutsideClickHandler from 'react-outside-click-handler';
import { Card, Menu, MenuItem, MenuItemText, Typography, Icon, Checkbox, } from '..';
export var CheckboxDropdown = function (_a) {
    var _b = _a.selected, selected = _b === void 0 ? [] : _b, closeOnSelect = _a.closeOnSelect, onChange = _a.onChange, options = _a.options, _c = _a.placeholder, placeholder = _c === void 0 ? 'Placeholder' : _c, _d = _a.clear, clear = _d === void 0 ? true : _d, _e = _a.clearText, clearText = _e === void 0 ? 'Clear all' : _e;
    var _f = __read(useState(false), 2), showMenu = _f[0], setShowMenu = _f[1];
    var theme = useTheme();
    var handleChange = function (option) {
        var optionExists = !!selected.find(function (s) { return s.value === option.value; });
        var newArray = !optionExists
            ? __spreadArray(__spreadArray([], __read(selected), false), [option], false) : __spreadArray([], __read(selected.filter(function (o) { return o.value !== option.value; })), false);
        if (closeOnSelect) {
            setShowMenu(false);
        }
        return onChange(newArray);
    };
    var onClear = useCallback(function () {
        onChange([]);
        if (closeOnSelect) {
            setShowMenu(false);
        }
    }, []);
    return (React.createElement(OuterContainer, null,
        React.createElement(OutsideClickHandler, { onOutsideClick: function () { return setShowMenu(false); } },
            React.createElement(DropdownWrapper, null,
                React.createElement(Dropdown, { onClick: function () { return setShowMenu(function (show) { return !show; }); } },
                    React.createElement(Typography, null, placeholder),
                    React.createElement(Icon, { name: showMenu ? 'chevron-up' : 'chevron-down', size: 16 })),
                showMenu && (React.createElement(Root, null,
                    React.createElement(Card, { direction: "horizontal", fullWidth: true },
                        React.createElement(Menu, null,
                            options.map(function (option) { return (React.createElement(MenuItem, { key: option.value, onClick: function () { return handleChange(option); } },
                                React.createElement(Checkbox, { checked: !!selected.find(function (s) { return s.value === option.value; }), onChange: function () { return null; } }),
                                React.createElement(MenuItemText, { style: {
                                        marginLeft: 8,
                                        color: theme.colors.text.base,
                                        textTransform: 'capitalize',
                                    } }, option.label))); }),
                            clear && (React.createElement(MenuItem, { onClick: function () { return onClear(); } },
                                React.createElement(MenuItemText, { style: { color: theme.colors.coral.coral500 } }, clearText)))))))))));
};
export default CheckboxDropdown;
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  top: 60px;\n  right: 0;\n  width: 100%;\n  z-index: 1;\n"], ["\n  position: absolute;\n  top: 60px;\n  right: 0;\n  width: 100%;\n  z-index: 1;\n"])));
var Dropdown = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 12px 16px;\n\n  border: 1px solid ", ";\n  cursor: pointer;\n  flex: 1;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 12px 16px;\n\n  border: 1px solid ", ";\n  cursor: pointer;\n  flex: 1;\n"])), function (props) { return props.theme.colors.white.white600; });
var DropdownWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: relative;\n  width: 100%;\n  display: flex;\n  flex: 1;\n"], ["\n  position: relative;\n  width: 100%;\n  display: flex;\n  flex: 1;\n"])));
var OuterContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var _a;
import React from 'react';
import { Icon, Typography } from 'designSystem';
import { currencyFormatter } from 'utils/currencyFormatter';
import { InvoiceStatus } from 'types/codegen/hooks';
import { format } from 'date-fns';
import styled, { css, useTheme } from 'styled-components';
import { Tick } from 'designSystem/components/Icon/icons';
import { useModal } from '../../../context';
import ModalTypeEnum from '../../../types/enums/ModalTypeEnum';
import { useScreenSizing } from '../../../hooks';
import { addMobileStyles } from '../../../utils/addMobileStyles';
var InvoiceStatusConfig = (_a = {},
    _a[InvoiceStatus.Open] = {
        label: 'Open',
        typoColor: 'alt',
        bgColor: 'base',
        borderColor: 'alt2',
    },
    _a[InvoiceStatus.Closed] = {
        label: 'Paid',
        typoColor: 'success',
        bgColor: 'success2',
        borderColor: 'success2',
    },
    _a[InvoiceStatus.Pending] = {
        label: 'Open',
        typoColor: 'alt',
        bgColor: 'base',
        borderColor: 'alt2',
    },
    _a[InvoiceStatus.Unknown] = {
        label: 'Invoice Details Unavailable',
        typoColor: 'success',
        bgColor: 'success2',
        borderColor: 'success2',
    },
    _a.PAST_DUE = {
        label: 'Past Due',
        typoColor: 'link',
        bgColor: 'error',
        borderColor: 'error2',
    },
    _a);
var formatDate = function (d) { return format(new Date(d), 'MM/dd/yyyy'); };
export var SingleInvoiceItem = function (_a) {
    var dueDate = _a.dueDate, date = _a.date, amount = _a.amount, status = _a.status, quantity = _a.quantity, facilityName = _a.facilityName, selectable = _a.selectable, selected = _a.selected, onSelect = _a.onSelect, invoiceId = _a.invoiceId;
    var openModal = useModal().openModal;
    var theme = useTheme();
    var isMobile = useScreenSizing().isMobile;
    var _b = InvoiceStatusConfig[status], label = _b.label, bgColor = _b.bgColor, borderColor = _b.borderColor, typoColor = _b.typoColor;
    var viewPdf = function () {
        openModal(ModalTypeEnum.INVOICE_PDF_VIEWER, { invoiceId: invoiceId });
    };
    return (React.createElement(ItemContainer, { selectable: selectable, onClick: selectable ? onSelect : viewPdf, selected: selected },
        isMobile && selectable && (React.createElement(CheckContainer, { selected: !!selected }, React.createElement(Tick, { color: theme.colors.coral.coral500 }))),
        React.createElement(DetailsWrapper, null,
            React.createElement("div", null,
                !isMobile && selectable && (React.createElement(CheckContainer, { selected: !!selected }, React.createElement(Tick, { color: theme.colors.coral.coral500 }))),
                React.createElement(Typography, { variant: "headlineSmall" }, facilityName !== null && facilityName !== void 0 ? facilityName : 'No Associated Facility')),
            React.createElement(Typography, { variant: isMobile ? 'footnote' : 'headlineSmall', color: "alt" },
                "Due ",
                formatDate(dueDate)),
            React.createElement(Typography, { variant: isMobile ? 'footnote' : 'headlineSmall', color: "alt" },
                "Ordered ",
                formatDate(date)),
            React.createElement(Typography, { variant: isMobile ? 'footnote' : 'headlineSmall', color: "alt" },
                currencyFormatter(amount, 2),
                " (",
                quantity,
                " Vials)")),
        React.createElement(InvoiceStatusChip, { bgColor: bgColor, borderColor: borderColor },
            React.createElement(Typography, { variant: "caption", color: typoColor }, label)),
        React.createElement(Icon, { name: "chevron-right", color: selectable ? theme.colors.text.base : theme.colors.text.link })));
};
var DetailsWrapper = function (_a) {
    var children = _a.children;
    var isMobile = useScreenSizing().isMobile;
    if (isMobile) {
        return React.createElement("div", null, children);
    }
    return React.createElement(React.Fragment, null, children);
};
var ItemContainer = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: repeat(4, 1fr) 84px 16px;\n  align-items: center;\n  column-gap: 20px;\n  padding: 20px 24px;\n  background: none;\n  border: none;\n  & > div:first-of-type {\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n    gap: 16px;\n  }\n  ", "\n  :hover {\n    cursor: pointer;\n  }\n  ", "\n"], ["\n  display: grid;\n  grid-template-columns: repeat(4, 1fr) 84px 16px;\n  align-items: center;\n  column-gap: 20px;\n  padding: 20px 24px;\n  background: none;\n  border: none;\n  & > div:first-of-type {\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n    gap: 16px;\n  }\n  ", "\n  :hover {\n    cursor: pointer;\n  }\n  ", "\n"])), function (props) {
    return props.selectable && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      ", "\n    "], ["\n      ", "\n    "])), !!props.selected
        ? props.theme.borders.selected
        : props.theme.borders.base);
}, function (props) {
    return addMobileStyles(css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      ", ";\n      background: ", ";\n      display: flex;\n      flex-direction: row;\n      align-items: center;\n      padding: 12px 20px;\n      &:not(:last-of-type) {\n        margin-bottom: 8px;\n      }\n      ", "\n      & > div:nth-child(", ") {\n        align-items: flex-start;\n        display: flex;\n        flex-direction: column;\n        gap: 0;\n      }\n    "], ["\n      ", ";\n      background: ", ";\n      display: flex;\n      flex-direction: row;\n      align-items: center;\n      padding: 12px 20px;\n      &:not(:last-of-type) {\n        margin-bottom: 8px;\n      }\n      ", "\n      & > div:nth-child(", ") {\n        align-items: flex-start;\n        display: flex;\n        flex-direction: column;\n        gap: 0;\n      }\n    "])), props.selectable && !!props.selected
        ? props.theme.borders.selected
        : props.theme.borders.base, props.theme.colors.bg.list, props.selectable && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        & > div:first-of-type {\n          align-items: center;\n          justify-content: center;\n        }\n      "], ["\n        & > div:first-of-type {\n          align-items: center;\n          justify-content: center;\n        }\n      "]))), props.selectable ? 2 : 1));
});
var CheckContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  height: 16px;\n  width: 16px;\n  border-radius: 2px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  ", ";\n  & > svg {\n    width: ", "px;\n    transition: width 175ms ease-in-out;\n  }\n"], ["\n  height: 16px;\n  width: 16px;\n  border-radius: 2px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  ", ";\n  & > svg {\n    width: ", "px;\n    transition: width 175ms ease-in-out;\n  }\n"])), function (props) { return props.theme.borders.alt2; }, function (props) { return (props.selected ? 11 : 0); });
var InvoiceStatusChip = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border: 1px solid ", ";\n  background: ", ";\n  padding: 8px 12px;\n  ", "\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border: 1px solid ", ";\n  background: ", ";\n  padding: 8px 12px;\n  ", "\n"])), function (props) { return props.theme.colors.border[props.borderColor]; }, function (props) { return props.theme.colors.bg[props.bgColor]; }, addMobileStyles(css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    padding: 4px 8px;\n    margin-left: auto;\n  "], ["\n    padding: 4px 8px;\n    margin-left: auto;\n  "])))));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;

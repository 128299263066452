var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { createRef, useEffect, useState } from 'react';
import styled from 'styled-components';
var defaultValue = {
    0: null,
    1: null,
    2: null,
    3: null,
    4: null,
    5: null,
    6: null,
    7: null,
    8: null,
    9: null,
};
var codeInputRefs = Object.keys(defaultValue).map(function (_, i) { return createRef(); });
export var PhoneInput = function (_a) {
    var error = _a.error, onChange = _a.onChange, value = _a.value, onNumberInput = _a.onNumberInput;
    var _b = __read(useState(defaultValue), 2), internalVal = _b[0], setInternalVal = _b[1];
    var numberObject = value !== null && value !== void 0 ? value : internalVal;
    var setNumberObject = onNumberInput !== null && onNumberInput !== void 0 ? onNumberInput : setInternalVal;
    var _c = __read(useState(!!error || false), 2), showError = _c[0], setShowError = _c[1];
    useEffect(function () {
        setShowError(!!error);
    }, [error]);
    useEffect(function () {
        onChange(Object.values(numberObject).join().replace(/,/g, ''));
    }, [numberObject]);
    var onPaste = function (event) {
        var _a, _b, _c, _d;
        event.preventDefault();
        var paste = (event.clipboardData || window.clipboardData)
            .getData('text')
            .replace(/\D/g, '')
            .slice(0, 10);
        var copyObj = __assign({}, defaultValue);
        paste.split('').forEach(function (num, ind) {
            copyObj[ind] = num;
        });
        setNumberObject(copyObj);
        var objKeys = Object.keys(copyObj);
        var emptyInputIndex = objKeys.findIndex(function (item) { return !copyObj[item]; });
        var previousInputIndex = objKeys.length - 1;
        if (emptyInputIndex === -1) {
            (_b = (_a = codeInputRefs[previousInputIndex]) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.focus();
        }
        else {
            (_d = (_c = codeInputRefs[emptyInputIndex]) === null || _c === void 0 ? void 0 : _c.current) === null || _d === void 0 ? void 0 : _d.focus();
        }
    };
    useEffect(function () {
        window.addEventListener('paste', onPaste);
        return function () { return window.removeEventListener('paste', onPaste); };
    });
    var clearInput = function () {
        var newObj = __assign({}, numberObject);
        for (var number in numberObject) {
            newObj[number] = null;
        }
        setShowError(false);
        codeInputRefs.forEach(function (ref) { var _a; return ((_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.value = null); });
        setNumberObject(newObj);
        codeInputRefs[0].current.focus();
    };
    var setInputFocus = function () {
        var _a, _b, _c, _d;
        var objKeys = Object.keys(numberObject);
        var emptyInputIndex = objKeys.findIndex(function (item) { return !numberObject[item]; });
        var previousInputIndex = objKeys.length - 1;
        if (showError) {
            return clearInput();
        }
        if (emptyInputIndex === -1) {
            (_b = (_a = codeInputRefs[previousInputIndex]) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.focus();
        }
        else {
            (_d = (_c = codeInputRefs[emptyInputIndex]) === null || _c === void 0 ? void 0 : _c.current) === null || _d === void 0 ? void 0 : _d.focus();
        }
    };
    var handleCodeInput = function (input, id) {
        var _a, _b, _c, _d;
        var _e, _f, _g, _h;
        var currentIndex = parseInt(id, 0);
        var strippedInput = input.replace(/\D/g, '');
        if (strippedInput.length === 0) {
            setNumberObject(__assign(__assign({}, numberObject), (_a = {}, _a[id] = null, _a)));
            codeInputRefs[currentIndex].current.value = '';
            return;
        }
        if (currentIndex === Object.keys(numberObject).length - 1 && input) {
            setNumberObject(__assign(__assign({}, numberObject), (_b = {}, _b[id] = input, _b)));
            return (_f = (_e = codeInputRefs[currentIndex]) === null || _e === void 0 ? void 0 : _e.current) === null || _f === void 0 ? void 0 : _f.blur();
        }
        if (!input) {
            return setNumberObject(__assign(__assign({}, numberObject), (_c = {}, _c[id] = null, _c)));
        }
        setNumberObject(__assign(__assign({}, numberObject), (_d = {}, _d[id] = input, _d)));
        return (_h = (_g = codeInputRefs[currentIndex + 1]) === null || _g === void 0 ? void 0 : _g.current) === null || _h === void 0 ? void 0 : _h.focus();
    };
    var handleInputBackspace = function (nativeEvent, id) {
        var _a, _b, _c;
        var currentIndex = parseInt(id, 0);
        var previousIndex = currentIndex - 1;
        var clearPreviousInput = function () {
            var _a, _b;
            if (codeInputRefs[previousIndex]) {
                (_b = (_a = codeInputRefs[previousIndex]) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.focus();
            }
        };
        if (nativeEvent.key === 'Backspace') {
            var nextIndex = currentIndex + 1;
            if (nextIndex !== Object.keys(numberObject).length && nextIndex !== 1) {
                setNumberObject(__assign(__assign({}, numberObject), (_a = {}, _a[previousIndex] = null, _a)));
                clearPreviousInput();
                return;
            }
            if (numberObject[id] !== null) {
                setNumberObject(__assign(__assign({}, numberObject), (_b = {}, _b[id] = null, _b)));
                return;
            }
            setNumberObject(__assign(__assign({}, numberObject), (_c = {}, _c[id] = null, _c)));
            clearPreviousInput();
        }
    };
    return (React.createElement(OuterContainer, null,
        React.createElement(InputContainer, { onClick: function () { return setInputFocus(); }, error: !!showError },
            "(",
            [0, 1, 2].map(function (item) {
                return (React.createElement(SingleInputContainer, { key: item },
                    React.createElement(SingleCharacterInput, { error: !!showError, onFocus: function () { return null; }, onChange: function (e) { return handleCodeInput(e.target.value, item); }, placeholder: "8", maxLength: 1, id: "charinput-".concat(item), onKeyDown: function (e) { return handleInputBackspace(e, item); }, value: numberObject[item], ref: codeInputRefs[item], "data-automation-id": "rewards-phone-input-".concat(item) }),
                    React.createElement(Underscore, null)));
            }),
            ")",
            [3, 4, 5].map(function (item) {
                return (React.createElement(SingleInputContainer, { key: item },
                    React.createElement(SingleCharacterInput, { error: !!showError, onFocus: function () { return null; }, onChange: function (e) { return handleCodeInput(e.target.value, item); }, placeholder: "8", maxLength: 1, id: "charinput-".concat(item), onKeyDown: function (e) { return handleInputBackspace(e, item); }, value: numberObject[item], ref: codeInputRefs[item], "data-automation-id": "rewards-phone-input-".concat(item) }),
                    React.createElement(Underscore, null)));
            }),
            "-",
            [6, 7, 8, 9].map(function (item) {
                return (React.createElement(SingleInputContainer, { key: item },
                    React.createElement(SingleCharacterInput, { error: !!showError, onFocus: function () { return null; }, onChange: function (e) { return handleCodeInput(e.target.value, item); }, placeholder: "8", maxLength: 1, id: "charinput-".concat(item), onKeyDown: function (e) { return handleInputBackspace(e, item); }, value: numberObject[item], ref: codeInputRefs[item], "data-automation-id": "rewards-phone-input-".concat(item) }),
                    React.createElement(Underscore, null)));
            })),
        React.createElement(ErrorContainer, null, showError && (React.createElement(ErrorText, { "data-automation-id": "rewards-phone-input-error" }, "This number is not valid, enter another one.")))));
};
var OuterContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n"])));
var InputContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  font-size: 2.25rem;\n  color: ", ";\n  width: 80%;\n"], ["\n  display: flex;\n  font-size: 2.25rem;\n  color: ", ";\n  width: 80%;\n"])), function (props) {
    return props.error ? props.theme.colors.text.link : props.theme.colors.text.alt4;
});
var SingleInputContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  margin: 0 5px;\n"], ["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  margin: 0 5px;\n"])));
var SingleCharacterInput = styled.input(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  all: unset;\n  max-width: 20px;\n  text-align: center;\n  color: ", ";\n  &&::placeholder {\n    color: ", ";\n  }\n"], ["\n  all: unset;\n  max-width: 20px;\n  text-align: center;\n  color: ", ";\n  &&::placeholder {\n    color: ", ";\n  }\n"])), function (props) {
    return props.error ? props.theme.colors.text.link : props.theme.colors.text.base;
}, function (props) { return props.theme.colors.text.alt4; });
var Underscore = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  border-bottom: 2px solid ", ";\n  width: 100%;\n"], ["\n  border-bottom: 2px solid ", ";\n  width: 100%;\n"])), function (props) { return props.theme.colors.border.alt2; });
var ErrorContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  height: 20px;\n"], ["\n  height: 20px;\n"])));
var ErrorText = styled.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 0.75rem;\n"], ["\n  color: ", ";\n  font-size: 0.75rem;\n"])), function (props) { return props.theme.colors.text.link; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { Desktop, Button, Typography } from 'designSystem';
import orderWhite from './order-white.png';
export var AccountInfoHeader = function (_a) {
    var accountName = _a.accountName, accountId = _a.accountId;
    var navigate = useNavigate();
    return (React.createElement(AccountHeader, null,
        React.createElement(AccountInfo, null,
            React.createElement(Typography, { variant: "subtitle" },
                React.createElement(Desktop, null, "Account Number: "),
                accountId),
            React.createElement(Typography, { variant: "titleLarge" }, accountName)),
        React.createElement(OrderButtonContainer, null,
            React.createElement(OrderButton, { onClick: function () {
                    navigate('/orders/buy');
                }, fullWidth: true, size: "large", "data-automation-id": "orders-header-order-btn" },
                React.createElement(OrderButtonIcon, { src: orderWhite }),
                React.createElement(OrderButtonText, null, "Order Jeuveau")))));
};
var AccountHeader = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 48px;\n  background-color: white;\n\n  display: flex;\n  justify-content: space-between;\n  margin: 16px;\n\n  @media only screen and (min-width: ", "px) {\n    ", ";\n  }\n\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n    align-items: center;\n    padding: 32px;\n    margin: 32px;\n  }\n"], ["\n  padding: 48px;\n  background-color: white;\n\n  display: flex;\n  justify-content: space-between;\n  margin: 16px;\n\n  @media only screen and (min-width: ", "px) {\n    ", ";\n  }\n\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n    align-items: center;\n    padding: 32px;\n    margin: 32px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
}, function (props) { return props.theme.elevation.base.elevation1; }, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var AccountInfo = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n\n  @media only screen and (max-width: ", "px) {\n    align-items: center;\n    margin-bottom: 32px;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n\n  @media only screen and (max-width: ", "px) {\n    align-items: center;\n    margin-bottom: 32px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var OrderButtonContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n\n  @media only screen and (max-width: ", "px) {\n    width: 100%;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n\n  @media only screen and (max-width: ", "px) {\n    width: 100%;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var OrderButton = styled(Button)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  height: 56px;\n\n  box-shadow: 0 6px 14px rgba(242, 76, 70, 0.5);\n"], ["\n  height: 56px;\n\n  box-shadow: 0 6px 14px rgba(242, 76, 70, 0.5);\n"])));
var OrderButtonIcon = styled.img(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 14px;\n  height: 14px;\n  margin-right: 10px;\n"], ["\n  width: 14px;\n  height: 14px;\n  margin-right: 10px;\n"])));
var OrderButtonText = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: white;\n  font-size: 1rem;\n  font-weight: 600;\n"], ["\n  color: white;\n  font-size: 1rem;\n  font-weight: 600;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;

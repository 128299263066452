import React from 'react';
export var Help = function (_a) {
    var color = _a.color;
    return (React.createElement("svg", { color: color, width: 16, height: 17, fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#a)" },
            React.createElement("path", { d: "M8 15.002a7 7 0 1 0 0-14 7 7 0 0 0 0 14Z", stroke: "currentColor", strokeWidth: 1.313, strokeMiterlimit: 10, strokeLinecap: "square" }),
            React.createElement("path", { d: "M8 12.457a.636.636 0 1 0 0-1.273.636.636 0 0 0 0 1.273Z", fill: "currentColor" }),
            React.createElement("path", { d: "M6.634 4.54c1.26-.557 2.89-.49 3.447.41.557.902.172 1.95-.782 2.759-.955.809-1.3 1.248-1.3 1.884", stroke: "currentColor", strokeWidth: 1.313, strokeMiterlimit: 10, strokeLinecap: "square" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "a" },
                React.createElement("path", { fill: "#fff", transform: "translate(0 .002)", d: "M0 0h16v16H0z" })))));
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
export var ListItemCard = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  flex: 1;\n  padding: 20px;\n\n  background: ", ";\n  ", ";\n  ", "\n  & > div {\n    display: flex;\n    flex: 1;\n    align-items: center;\n    justify-content: flex-start;\n    gap: 12px;\n  }\n  & > span {\n    display: flex;\n    height: 100%;\n    justify-content: center;\n    align-items: center;\n    & > svg {\n      width: 10.67px;\n      height: 6.68px;\n      color: ", ";\n    }\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  flex: 1;\n  padding: 20px;\n\n  background: ", ";\n  ", ";\n  ", "\n  & > div {\n    display: flex;\n    flex: 1;\n    align-items: center;\n    justify-content: flex-start;\n    gap: 12px;\n  }\n  & > span {\n    display: flex;\n    height: 100%;\n    justify-content: center;\n    align-items: center;\n    & > svg {\n      width: 10.67px;\n      height: 6.68px;\n      color: ", ";\n    }\n  }\n"])), function (props) { return props.theme.colors.bg.list; }, function (props) { return props.theme.borders.base; }, function (props) {
    return props.onClick
        ? css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n          :hover {\n            cursor: pointer;\n          }\n        "], ["\n          :hover {\n            cursor: pointer;\n          }\n        "]))) : '';
}, function (props) { return props.theme.colors.text.link; });
var templateObject_1, templateObject_2;

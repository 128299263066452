import React from 'react';
import { PaymentOptionsView } from './View';
import { useQuery } from '@apollo/client';
import query from './index.graphql';
import { useUser } from 'context';
export var PaymentOptionsContainer = function (_a) {
    var _b, _c, _d, _e;
    var onPreselect = _a.onPreselect, onContinue = _a.onContinue;
    var account = useUser().account;
    var _f = useQuery(query, {
        variables: {
            input: {
                accountId: account === null || account === void 0 ? void 0 : account.id,
            },
        },
    }), data = _f.data, loading = _f.loading, called = _f.called;
    return (React.createElement(PaymentOptionsView, { loading: loading || !called, hasPastDue: (_b = data === null || data === void 0 ? void 0 : data.getOpenInvoicesByAccount) === null || _b === void 0 ? void 0 : _b.hasPastDue, totalAmountDue: (_c = data === null || data === void 0 ? void 0 : data.getOpenInvoicesByAccount) === null || _c === void 0 ? void 0 : _c.totalAmountDue, totalAmountDueThisWeek: (_d = data === null || data === void 0 ? void 0 : data.getOpenInvoicesByAccount) === null || _d === void 0 ? void 0 : _d.totalAmountDueThisWeek, invoices: (_e = data === null || data === void 0 ? void 0 : data.getOpenInvoicesByAccount) === null || _e === void 0 ? void 0 : _e.invoices, onPreselect: onPreselect, onContinue: onContinue }));
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { Modal } from '../Modal';
import styled from 'styled-components';
import { Button, Icon, Typography } from 'designSystem';
import { useModal } from 'context';
import RemoveUserGraphic from 'assets/remove-item.svg';
export var RemoveUserModalView = function (_a) {
    var onRemove = _a.onRemove, loading = _a.loading;
    var closeModal = useModal().closeModal;
    return (React.createElement(Modal, null,
        React.createElement(Container, null,
            React.createElement(Image, { src: RemoveUserGraphic }),
            React.createElement(TextContainer, null,
                React.createElement(Typography, { mb: 32, display: "block", variant: "titleLarge" }, "Are you sure you want to remove this user?"),
                React.createElement(Typography, { mb: 32, display: "block", color: "alt", variant: "body" }, "This action will remove this user from the account, and the user will no longer have access to log into this account. If you need to give this user access in the future you will need to add them as a new staff user again."),
                React.createElement(Typography, { display: "block", color: "alt", variant: "body" }, "If you want to remove this user from this account, click remove.")),
            React.createElement(ActionsContainer, null,
                React.createElement(Button, { disabled: loading, endIcon: loading && React.createElement(Icon, { name: "loading", color: "inherit" }), size: "xlarge", variant: "primary", onClick: function () { return onRemove(); } }, "Yes, Remove"),
                React.createElement(Button, { size: "xlarge", variant: "outlined", onClick: closeModal }, "Cancel")))));
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  align-items: center;\n"], ["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  align-items: center;\n"])));
var TextContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  @media only screen and (max-width: ", "px) {\n    & > * {\n      text-align: center;\n    }\n  }\n"], ["\n  @media only screen and (max-width: ", "px) {\n    & > * {\n      text-align: center;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var ActionsContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row-reverse;\n  gap: 12px;\n  align-items: center;\n  width: 100%;\n  margin-top: 48px;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n    flex: 1;\n    margin-top: 32px;\n    & > button {\n      width: 100%;\n    }\n  }\n"], ["\n  display: flex;\n  flex-direction: row-reverse;\n  gap: 12px;\n  align-items: center;\n  width: 100%;\n  margin-top: 48px;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n    flex: 1;\n    margin-top: 32px;\n    & > button {\n      width: 100%;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var Image = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  max-width: 300px;\n  width: 100%;\n  margin-bottom: 28px;\n"], ["\n  max-width: 300px;\n  width: 100%;\n  margin-bottom: 28px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;

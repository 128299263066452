var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { ListItemCard } from '../../../components/ListItemCard';
import { Icon, Typography } from '../../../../../../designSystem';
import styled, { useTheme } from 'styled-components';
export function FacilityCard(props) {
    var facility = props.facility, onSelect = props.onSelect;
    var colors = useTheme().colors;
    return (React.createElement(ListItemCard, { onClick: function () { return onSelect(facility.id); } },
        React.createElement("div", null,
            React.createElement(FacilityName, { variant: "headlineSmall" }, facility.name),
            React.createElement(Typography, { variant: "footnote" }, "".concat(facility.shippingAddress.city, ", ").concat(facility.shippingAddress.state))),
        React.createElement(Icon, { color: colors.text.link, name: "chevron-right" })));
}
var FacilityName = styled(Typography)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: block;\n"], ["\n  display: block;\n"])));
var templateObject_1;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled, { css } from 'styled-components';
import { Button, Typography } from 'designSystem';
import { useNavigate } from 'react-router';
import CheckStatusGraphic from 'assets/check-status.svg';
import StarsGraphic from 'assets/evolux.svg';
import { addMobileStyles } from '../../../../../../utils/addMobileStyles';
import { stackedCardStyles } from '../StackedMobileCard';
import { useScreenSizing } from '../../../../../../hooks';
export var CheckStatusCard = function () {
    var navigate = useNavigate();
    var isMobile = useScreenSizing().isMobile;
    var CTA = (React.createElement(Button, { fullWidth: true, size: "xlarge", variant: "outlined", onClick: function () { return navigate('/rewards/check-in'); } }, "Check Status"));
    return (React.createElement(Container, null,
        React.createElement("div", null,
            React.createElement(ChipContainer, null,
                React.createElement(Typography, { variant: "overline", color: "linkAlt" }, "New Feature"),
                React.createElement("img", { src: StarsGraphic, alt: "stars" })),
            React.createElement(Typography, { display: "block", variant: "headline" }, "Patient Reward Status"),
            React.createElement(Typography, { display: "block", mt: 12, mb: 40, variant: "bodySmall", color: "alt" }, "Easily check your patients current rewards status, send sms reminders, and enroll new patients."),
            !isMobile && CTA),
        React.createElement("img", { src: CheckStatusGraphic, alt: "check-status" }),
        isMobile && CTA));
};
var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  display: flex;\n  align-items: center;\n  gap: 32px;\n  justify-content: space-between;\n  & > img {\n    width: 100%;\n    max-width: 244px;\n  }\n  ", ";\n"], ["\n  ", ";\n  display: flex;\n  align-items: center;\n  gap: 32px;\n  justify-content: space-between;\n  & > img {\n    width: 100%;\n    max-width: 244px;\n  }\n  ", ";\n"])), function (props) { return props.theme.card.default; }, addMobileStyles(css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    ", ";\n    z-index: 4;\n    margin: -16px 0 0;\n    gap: 0;\n    & > img {\n      max-width: 195px;\n    }\n  "], ["\n    ", ";\n    z-index: 4;\n    margin: -16px 0 0;\n    gap: 0;\n    & > img {\n      max-width: 195px;\n    }\n  "])), stackedCardStyles)));
var ChipContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: ", ";\n  border: 1px solid ", ";\n  padding: 4px 8px;\n  margin-bottom: 16px;\n  display: flex;\n  width: fit-content;\n  gap: 4px;\n  align-items: center;\n  & > img {\n    max-width: 10px;\n  }\n"], ["\n  background: ", ";\n  border: 1px solid ", ";\n  padding: 4px 8px;\n  margin-bottom: 16px;\n  display: flex;\n  width: fit-content;\n  gap: 4px;\n  align-items: center;\n  & > img {\n    max-width: 10px;\n  }\n"])), function (props) { return props.theme.colors.teal.teal25; }, function (props) { return props.theme.colors.teal.teal50; });
var templateObject_1, templateObject_2, templateObject_3;

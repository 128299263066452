var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import RewardAmountBg from 'assets/tilted-light-logomark.svg';
import { Typography } from 'designSystem';
export var TwentyUnitsCard = function () {
    return (React.createElement(Container, null,
        React.createElement(Typography, { color: "alt3" }, "20 UNITS FREE"),
        React.createElement(Typography, { color: "alt3" }, "For Patient")));
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 171px;\n  width: 311px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  background: no-repeat center url(", ");\n  background-color: ", ";\n  padding: 48px 19px;\n  border-radius: 12px;\n  & > span:first-of-type {\n    font-size: 40px;\n    font-weight: 700;\n    line-height: 50px;\n    letter-spacing: -0.5px;\n  }\n"], ["\n  height: 171px;\n  width: 311px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  background: no-repeat center url(", ");\n  background-color: ", ";\n  padding: 48px 19px;\n  border-radius: 12px;\n  & > span:first-of-type {\n    font-size: 40px;\n    font-weight: 700;\n    line-height: 50px;\n    letter-spacing: -0.5px;\n  }\n"])), RewardAmountBg, function (props) { return props.theme.colors.coral.coral500; });
var templateObject_1;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { PlaceholderLoading } from '../PlaceholderLoading';
import { Button } from 'designSystem';
import { Typography } from '../Typography';
export var TableCard = function (_a) {
    var buttonLabel = _a.buttonLabel, subtext = _a.subtext, header = _a.header, onButtonPress = _a.onButtonPress, children = _a.children, isLoading = _a.isLoading, error = _a.error;
    return (React.createElement(OrderHistoryContainer, null,
        React.createElement(OrderHistoryHeaderContainer, null,
            React.createElement(Typography, { variant: "titleLarge" }, header),
            buttonLabel && onButtonPress && (React.createElement(Button, { onClick: function () { return onButtonPress(); }, variant: "outlined" }, buttonLabel))),
        subtext && React.createElement(OrderHistorySubtext, null, subtext),
        isLoading || error ? (React.createElement(LoadingOuterContainer, null,
            React.createElement(LoadingInnerContainer, null,
                React.createElement(PlaceholderLoading, { size: "extra-small" })))) : (React.createElement(React.Fragment, null, children))));
};
var OrderHistoryContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  background-color: white;\n  padding: 48px;\n  flex-direction: column;\n\n  margin: 16px;\n\n  @media only screen and (min-width: ", "px) {\n    ", ";\n  }\n\n  @media only screen and (max-width: ", "px) {\n    margin: 0;\n    margin-top: -20px;\n    padding: 32px;\n    ", ";\n  }\n"], ["\n  display: flex;\n  flex: 1;\n  background-color: white;\n  padding: 48px;\n  flex-direction: column;\n\n  margin: 16px;\n\n  @media only screen and (min-width: ", "px) {\n    ", ";\n  }\n\n  @media only screen and (max-width: ", "px) {\n    margin: 0;\n    margin-top: -20px;\n    padding: 32px;\n    ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
}, function (props) { return props.theme.elevation.base.elevation1; }, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
}, function (props) { return props.theme.elevation.base.elevation2; });
var OrderHistoryHeaderContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  flex-direction: row;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  flex: 1;\n  flex-direction: row;\n  justify-content: space-between;\n"])));
var OrderHistorySubtext = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 1rem;\n  padding-top: 16px;\n  color: ", ";\n"], ["\n  font-size: 1rem;\n  padding-top: 16px;\n  color: ", ";\n"])), function (props) { return props.theme.colors.text.alt; });
var LoadingOuterContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  height: 300px;\n"], ["\n  display: flex;\n  width: 100%;\n  height: 300px;\n"])));
var LoadingInnerContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 300px;\n"], ["\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 300px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;

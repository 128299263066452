var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
import CardImage from './CardImage';
import CardClose from './CardClose';
import CardTitle from './CardTitle';
import CardDescription from './CardDescription';
import CardAction from './CardAction';
function getSize(size, fullWidth) {
    if (size === void 0) { size = 'md'; }
    if (fullWidth === void 0) { fullWidth = false; }
    if (fullWidth) {
        return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      max-width: 100%;\n    "], ["\n      max-width: 100%;\n    "])));
    }
    switch (size) {
        case 'md':
            return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        max-width: 640px;\n      "], ["\n        max-width: 640px;\n      "])));
        default:
            return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n        max-width: 1140px;\n      "], ["\n        max-width: 1140px;\n      "])));
    }
}
export var Card = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: ", ";\n  flex-wrap: wrap;\n  width: 100%;\n  border: 1px solid ", ";\n  ", "\n\n  background: ", ";\n  ", ";\n  z-index: 1;\n"], ["\n  display: flex;\n  flex-direction: ", ";\n  flex-wrap: wrap;\n  width: 100%;\n  border: 1px solid ", ";\n  ", "\n\n  background: ", ";\n  ", ";\n  z-index: 1;\n"])), function (_a) {
    var direction = _a.direction;
    return direction === 'horizontal' ? 'row' : 'column';
}, function (props) { return props.theme.colors.text.alt2; }, function (props) { return props.theme.elevation.base.elevation1; }, function (props) { return props.theme.colors.basic.white; }, function (props) { return getSize(props.size, props.fullWidth); });
Card.displayName = 'Card';
export default Object.assign(Card, {
    Img: CardImage,
    Close: CardClose,
    Title: CardTitle,
    Description: CardDescription,
    Action: CardAction,
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled, { useTheme } from 'styled-components';
import evoluxSuccessImage from 'assets/elite-plus-success-graphic.svg';
import { Button, Icon, LoadingBubbles, Typography } from 'designSystem';
import { useNavigate } from 'react-router';
import { stackedCardStyles } from 'scenes/Rewards/Tabs/RewardsDashboard/components/StackedMobileCard';
import { useScreenSizing } from '../../hooks';
import { levelDesignConfig } from 'utils/levelDesignConfig';
import { getLoyaltyLevel } from '../../utils/getLoyaltyLevel';
var BoldText = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-weight: 700;\n"], ["\n  font-weight: 700;\n"])));
var benefitsByLevel = {
    LEVEL_1: [
        React.createElement(React.Fragment, null,
            React.createElement(BoldText, null, "Save $10"),
            " per vial"),
        React.createElement(React.Fragment, null),
    ],
    LEVEL_2: [
        React.createElement(React.Fragment, null,
            React.createElement(BoldText, null, "Save $80"),
            " more per vial"),
        React.createElement(React.Fragment, null,
            React.createElement(BoldText, null, "Access exclusive marketing platforms"),
            " with content to help you stay connected to patients"),
    ],
    LEVEL_3: [
        React.createElement(React.Fragment, null,
            React.createElement(BoldText, null, "Save $60"),
            " more per vial"),
    ],
    LEVEL_4: [
        React.createElement(React.Fragment, null,
            React.createElement(BoldText, null, "Save $20"),
            " more per vial"),
    ],
    LEVEL_5: [
        React.createElement(React.Fragment, null,
            React.createElement(BoldText, null, "Save $25"),
            " more per vial"),
    ],
    LEVEL_6: [
        React.createElement(React.Fragment, null,
            React.createElement(BoldText, null, "Save $10"),
            " more per vial"),
        React.createElement(React.Fragment, null,
            React.createElement(BoldText, null, "Increase your Marketing Package"),
            " to include paid search and paid social."),
    ],
    LEVEL_7: [
        React.createElement(React.Fragment, null,
            React.createElement(BoldText, null, "Save $15"),
            " more per vial"),
        React.createElement(React.Fragment, null,
            React.createElement(BoldText, null, "Increase your Marketing Package"),
            " to include streaming package or digital package."),
    ],
    LEVEL_8: [
        React.createElement(React.Fragment, null,
            React.createElement(BoldText, null, "Save $20"),
            " more per vial"),
        React.createElement(React.Fragment, null,
            React.createElement(BoldText, null, "Increase your Marketing Package"),
            " to include either paid search and paid social, streaming commercial, premium billboard, or an in-office experience."),
    ],
};
var EvoluxProgressWidget = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h;
    var currentQuarterLoyalty = _a.currentQuarterLoyalty, isLoading = _a.isLoading, pricingModel = _a.pricingModel, defaultRate = _a.defaultRate;
    var navigate = useNavigate();
    var isMobile = useScreenSizing().isMobile;
    var isCustomAccount = pricingModel === null || pricingModel === void 0 ? void 0 : pricingModel.includes('CUSTOM_');
    var theme = useTheme();
    var pricingLevel = getLoyaltyLevel(currentQuarterLoyalty === null || currentQuarterLoyalty === void 0 ? void 0 : currentQuarterLoyalty.vialsPurchased, currentQuarterLoyalty === null || currentQuarterLoyalty === void 0 ? void 0 : currentQuarterLoyalty.levels);
    var vialsNeeded = 10 - ((_b = currentQuarterLoyalty === null || currentQuarterLoyalty === void 0 ? void 0 : currentQuarterLoyalty.vialsPurchased) !== null && _b !== void 0 ? _b : 0);
    var nextLevel = currentQuarterLoyalty === null || currentQuarterLoyalty === void 0 ? void 0 : currentQuarterLoyalty.levels.find(function (l) { return l.rank === pricingLevel.rank + 1; });
    var onOrderJeuveau = function (vials) {
        if (vials === void 0) { vials = 0; }
        navigate('/orders/buy?step=quantitySelection', {
            state: {
                autoContinue: !!vials,
                preFilledQuantity: vials ? vials : undefined,
            },
        });
    };
    var isMaxLevel = !nextLevel;
    var hasMetOrExceededPriorQuarterProgress = pricingLevel.rank > 1;
    var goalLevel;
    if (!nextLevel) {
        goalLevel = pricingLevel;
    }
    else if (hasMetOrExceededPriorQuarterProgress) {
        goalLevel = nextLevel;
    }
    else {
        goalLevel = pricingLevel;
    }
    var goalVials = goalLevel === null || goalLevel === void 0 ? void 0 : goalLevel.vialThreshold;
    var startedAtMaxLevel = (currentQuarterLoyalty === null || currentQuarterLoyalty === void 0 ? void 0 : currentQuarterLoyalty.startOfQuarterPricedWith.level) === 'LEVEL_8';
    var earningStartingPoint = 0;
    if (isMaxLevel && !startedAtMaxLevel) {
        var lastLevel = currentQuarterLoyalty === null || currentQuarterLoyalty === void 0 ? void 0 : currentQuarterLoyalty.levels.find(function (l) { var _a; return l.rank === (((_a = currentQuarterLoyalty === null || currentQuarterLoyalty === void 0 ? void 0 : currentQuarterLoyalty.earned) === null || _a === void 0 ? void 0 : _a.rank) || 0) - 1; });
        earningStartingPoint = lastLevel === null || lastLevel === void 0 ? void 0 : lastLevel.vialThreshold;
    }
    else if (nextLevel && hasMetOrExceededPriorQuarterProgress) {
        earningStartingPoint = (_c = currentQuarterLoyalty === null || currentQuarterLoyalty === void 0 ? void 0 : currentQuarterLoyalty.earned) === null || _c === void 0 ? void 0 : _c.vialThreshold;
    }
    var numberToBuyToRetainOrAchieveNextLevel = goalVials - ((currentQuarterLoyalty === null || currentQuarterLoyalty === void 0 ? void 0 : currentQuarterLoyalty.vialsPurchased) || 0);
    var earnedTowardsGoal = (currentQuarterLoyalty === null || currentQuarterLoyalty === void 0 ? void 0 : currentQuarterLoyalty.vialsPurchased) - (earningStartingPoint || 0);
    var pricingLevelProgressBarPercentage = (earnedTowardsGoal / (goalVials - (earningStartingPoint || 0))) * 100;
    if (isCustomAccount) {
        return (React.createElement(ElitePlusContainer, { isLoading: isLoading },
            React.createElement(ElitePlusLeftContainer, null,
                React.createElement(CustomTextContainer, null,
                    React.createElement(Typography, { variant: isMobile ? 'title' : 'headline' }, "This account is custom."),
                    React.createElement(Typography, { mt: 10, variant: "headline", color: "alt" },
                        "You are receiving custom pricing and benefits, awesome!",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "You are receiving a custom price of $",
                        defaultRate,
                        " per vial.")),
                !isMobile && (React.createElement(CustomOrderButtonContainer, null,
                    React.createElement(Button, { mt: 28, fullWidth: true, variant: "outlined", size: "xlarge", startIcon: React.createElement(Icon, { name: "orders", color: theme.colors.text.link }), onClick: onOrderJeuveau }, "Order Jeuveau")))),
            React.createElement(ElitePlusRightContainer, null,
                React.createElement(ElitePlusSuccessImage, { src: evoluxSuccessImage }),
                isMobile && (React.createElement(CustomOrderButtonContainer, null,
                    React.createElement(Button, { mt: 28, fullWidth: true, variant: "outlined", size: "xlarge", startIcon: React.createElement(Icon, { name: "orders", color: theme.colors.text.link }), onClick: onOrderJeuveau }, "Order Jeuveau"))))));
    }
    if (((_d = currentQuarterLoyalty === null || currentQuarterLoyalty === void 0 ? void 0 : currentQuarterLoyalty.earned) === null || _d === void 0 ? void 0 : _d.name) === 'LEVEL_8') {
        return (React.createElement(OuterContainer, { isLoading: isLoading },
            React.createElement(ElitePlusInnerContainer, null,
                React.createElement(ElitePlusTextContainer, null,
                    React.createElement(Typography, { align: isMobile ? 'center' : 'left', variant: "titleLarge" }, "You made it to Elite+"),
                    React.createElement(BodyBenefitsContainer, null,
                        React.createElement(Typography, { mt: 8, display: "block", color: "alt" }, "Started from the bottom..."),
                        React.createElement(Typography, { mb: 32, display: "block", color: "alt" }, "now you're here")),
                    React.createElement(Typography, { variant: "titleLarge" }, "Cheers to you!")),
                React.createElement(ElitePlusRightContainer, null,
                    React.createElement(ElitePlusSuccessImage, { src: evoluxSuccessImage }))),
            React.createElement(CustomOrderButtonContainer, null,
                React.createElement(Button, { fullWidth: true, variant: "outlined", size: "xlarge", startIcon: React.createElement(Icon, { name: "orders", color: theme.colors.text.link }), onClick: onOrderJeuveau }, "Order Jeuveau"))));
    }
    if ((pricingLevel === null || pricingLevel === void 0 ? void 0 : pricingLevel.name) === 'LEVEL_0') {
        return (React.createElement(OuterContainer, { isLoading: isLoading },
            React.createElement(Typography, { variant: "titleLarge" }, "It looks like you're not part of our Evolux loyalty program"),
            React.createElement(BodyBenefitsContainer, null,
                React.createElement(Typography, { mt: 32, display: "block", color: "alt" },
                    "By purchasing at least ",
                    vialsNeeded,
                    " vials, you can:"),
                React.createElement(IncentivesList, null,
                    React.createElement(ListItem, null,
                        React.createElement(Typography, { variant: "bodySmall", color: "alt" },
                            React.createElement(BoldText, null, "Save $10"),
                            " per vial")),
                    React.createElement(ListItem, null,
                        React.createElement(Typography, { variant: "bodySmall", color: "alt" },
                            React.createElement(BoldText, null, "Showcase your practice"),
                            " on our website")))),
            React.createElement(Button, { mt: isMobile ? 32 : 52, variant: "outlined", size: "xlarge", onClick: function () { return onOrderJeuveau(vialsNeeded); }, "data-automation-id": "orders-progress-evolux-order-btn", startIcon: React.createElement(Icon, { name: "orders", color: theme.colors.text.link }) },
                "Buy ",
                vialsNeeded,
                " to join")));
    }
    var level = hasMetOrExceededPriorQuarterProgress
        ? nextLevel.name
        : (pricingLevel === null || pricingLevel === void 0 ? void 0 : pricingLevel.name) || 'LEVEL_1';
    var levelName = (_e = levelDesignConfig[level]) === null || _e === void 0 ? void 0 : _e.name;
    return (React.createElement(OuterContainer, { isLoading: isLoading },
        isLoading && (React.createElement(LoadingContainer, null,
            React.createElement(LoadingBubbles, null))),
        React.createElement(ProgressHeaderContainer, null,
            React.createElement(ProgressHeaderLeftContainer, null,
                React.createElement(RequiredVialsText, { variant: "titleLarge" }, numberToBuyToRetainOrAchieveNextLevel),
                React.createElement(RequiredVialsSubtext, { variant: "headlineSmall", color: "alt" },
                    "Vials Needed to",
                    ' ',
                    hasMetOrExceededPriorQuarterProgress ? 'Upgrade to' : 'Retain',
                    ' ',
                    levelName)),
            React.createElement(ProgressHeaderRightContainer, null,
                React.createElement(VialProgressText, { variant: "title" }, "".concat((_f = currentQuarterLoyalty === null || currentQuarterLoyalty === void 0 ? void 0 : currentQuarterLoyalty.vialsPurchased) !== null && _f !== void 0 ? _f : 0, "/").concat(goalVials)),
                React.createElement(VialProgressSubtext, { variant: "caption", color: "alt" }, "Qualifying Vials"))),
        React.createElement(ProgressBarContainer, null,
            React.createElement(CurrentProgressBar, { percentage: pricingLevelProgressBarPercentage })),
        React.createElement(BodyContainer, null,
            React.createElement(Typography, { variant: "headline" },
                "Purchase ",
                goalVials - ((_g = currentQuarterLoyalty === null || currentQuarterLoyalty === void 0 ? void 0 : currentQuarterLoyalty.vialsPurchased) !== null && _g !== void 0 ? _g : 0),
                ' ',
                "Vials to ",
                hasMetOrExceededPriorQuarterProgress ? 'Unlock' : 'Retain',
                ' ',
                levelName),
            React.createElement(BodyBenefitsContainer, null,
                React.createElement(Typography, { mt: 8, display: "block", variant: "body", color: "alt" },
                    "With ",
                    levelName,
                    ", you can:"),
                React.createElement(IncentivesList, null, (_h = benefitsByLevel[level]) === null || _h === void 0 ? void 0 : _h.map(function (b, i) { return (React.createElement(ListItem, { key: i },
                    React.createElement(Typography, { variant: "body", color: "alt" }, b))); })))),
        React.createElement(Button, { variant: "outlined", size: "xlarge", onClick: onOrderJeuveau, "data-automation-id": "orders-progress-order-btn", startIcon: React.createElement(Icon, { name: "orders", color: theme.colors.text.link }) }, "Order Jeuveau")));
};
export default EvoluxProgressWidget;
var OuterContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  background-color: white;\n  position: relative;\n  margin: 16px;\n  ", ";\n  padding: 32px;\n  & > button {\n    margin-top: auto;\n  }\n  @media screen and (max-width: ", "px) {\n    ", ";\n    margin: -16px 0 0;\n    z-index: 3;\n  }\n  ", "\n"], ["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  background-color: white;\n  position: relative;\n  margin: 16px;\n  ", ";\n  padding: 32px;\n  & > button {\n    margin-top: auto;\n  }\n  @media screen and (max-width: ", "px) {\n    ", ";\n    margin: -16px 0 0;\n    z-index: 3;\n  }\n  ", "\n"])), function (props) { return props.theme.elevation.base.elevation1; }, function (props) { return props.theme.breakpoints.md; }, stackedCardStyles, function (props) { return props.isLoading && props.theme.blur.loading; });
var ProgressHeaderContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n"], ["\n  display: flex;\n"])));
var ProgressHeaderLeftContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  flex: 1;\n  display: flex;\n  align-items: flex-end;\n"], ["\n  flex: 1;\n  display: flex;\n  align-items: flex-end;\n"])));
var ProgressHeaderRightContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  flex: 1;\n  display: flex;\n  justify-content: flex-end;\n  align-items: flex-end;\n"], ["\n  flex: 1;\n  display: flex;\n  justify-content: flex-end;\n  align-items: flex-end;\n"])));
var RequiredVialsText = styled(Typography)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  align-self: flex-end;\n"], ["\n  align-self: flex-end;\n"])));
var RequiredVialsSubtext = styled(Typography)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin-left: 5px;\n"], ["\n  margin-left: 5px;\n"])));
var VialProgressText = styled(Typography)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  align-self: flex-end;\n"], ["\n  align-self: flex-end;\n"])));
var VialProgressSubtext = styled(Typography)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  margin-left: 5px;\n"], ["\n  margin-left: 5px;\n"])));
var ProgressBarContainer = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  background-color: #ebf0f0;\n  height: 6px;\n  margin-top: 10px;\n  align-items: center;\n"], ["\n  display: flex;\n  background-color: #ebf0f0;\n  height: 6px;\n  margin-top: 10px;\n  align-items: center;\n"])));
var CurrentProgressBar = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  width: ", "%;\n  background-color: #e5554f;\n  height: 10px;\n"], ["\n  width: ", "%;\n  background-color: #e5554f;\n  height: 10px;\n"])), function (props) { return props === null || props === void 0 ? void 0 : props.percentage; });
var BodyContainer = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  margin: 20px 0;\n"], ["\n  margin: 20px 0;\n"])));
var BodyBenefitsContainer = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  font-size: 0.875rem;\n  color: #6e9191;\n  margin-top: 10px;\n  line-height: 1.5rem;\n"], ["\n  font-size: 0.875rem;\n  color: #6e9191;\n  margin-top: 10px;\n  line-height: 1.5rem;\n"])));
var IncentivesList = styled.ul(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  padding-inline-start: 0;\n  margin-left: 10px;\n"], ["\n  padding-inline-start: 0;\n  margin-left: 10px;\n"])));
var ListItem = styled.li(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  margin-left: 10px;\n"], ["\n  margin-left: 10px;\n"])));
var ElitePlusInnerContainer = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  flex: 1;\n  margin-bottom: 26px;\n  @media only screen and (max-width: ", "px) {\n    margin-bottom: 32px;\n    flex-direction: column;\n  }\n"], ["\n  display: flex;\n  flex-direction: row;\n  flex: 1;\n  margin-bottom: 26px;\n  @media only screen and (max-width: ", "px) {\n    margin-bottom: 32px;\n    flex-direction: column;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var ElitePlusContainer = styled(OuterContainer)(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  flex-direction: row;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n  }\n"], ["\n  display: flex;\n  flex: 1;\n  flex-direction: row;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var ElitePlusTextContainer = styled.div(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: flex-start;\n  flex-direction: column;\n  flex: 1;\n  @media only screen and (max-width: ", "px) {\n    align-items: center;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: flex-start;\n  flex-direction: column;\n  flex: 1;\n  @media only screen and (max-width: ", "px) {\n    align-items: center;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var ElitePlusLeftContainer = styled.div(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: flex-start;\n  @media only screen and (max-width: ", "px) {\n    margin-left: 0;\n  }\n"], ["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: flex-start;\n  @media only screen and (max-width: ", "px) {\n    margin-left: 0;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var ElitePlusRightContainer = styled.div(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  display: flex;\n  flex: 1.25;\n  justify-content: flex-end;\n  align-items: center;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n  }\n"], ["\n  display: flex;\n  flex: 1.25;\n  justify-content: flex-end;\n  align-items: center;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var ElitePlusSuccessImage = styled.img(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  width: 80%;\n  max-width: 270px;\n  @media only screen and (max-width: ", "px) {\n    margin-top: 24px;\n  }\n"], ["\n  width: 80%;\n  max-width: 270px;\n  @media only screen and (max-width: ", "px) {\n    margin-top: 24px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var LoadingContainer = styled.div(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: absolute;\n  top: 0;\n  left: 0;\n"], ["\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: absolute;\n  top: 0;\n  left: 0;\n"])));
var CustomOrderButtonContainer = styled.div(templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n  width: 100%;\n  margin-top: auto;\n"], ["\n  width: 100%;\n  margin-top: auto;\n"])));
var CustomTextContainer = styled.div(templateObject_24 || (templateObject_24 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: flex-start;\n  flex-direction: column;\n  flex: 1;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: flex-start;\n  flex-direction: column;\n  flex: 1;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23, templateObject_24;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { AccountStatementsFilters } from './AccountStatementsFilters';
import AddCreditCard from './CreatePaymentMethodModals/AddCreditCard';
import AddBankAccount from './CreatePaymentMethodModals/AddBankAccount';
import { AutomaticLevelUpgrade } from './AutomaticLevelUpgrade';
import { BenefitVideoPlayer } from './BenefitVideoPlayer';
import { DownForMaintenance } from './DownForMaintenance';
import { EditPaymentMethod } from './EditPaymentMethod';
import PaymentAuthError from './PaymentAuthError';
import { RemovePromoCode } from './RemovePromoCode';
import AccountSelection from './AccountSelection';
import { LeavingCheckIn } from './LeavingCheckIn';
import UnderConstruction from './UnderConstruction';
import CheckInSuspended from './CheckInSuspended';
import EvoluxLevelsList from './EvoluxLevelsList';
import { ForgotPassword } from './ForgotPassword';
import { TransactionHistoryFilters } from './TransactionHistoryFilters';
import AnnualCampaign from './AnnualCampaign';
import { useModal } from 'context/ModalContext';
import { RemovePaymentMethod } from './RemovePaymentMethod';
import { RemoveUser } from './RemoveUser';
import { AdminAccessConfirmation } from './AdminAccessConfirmation';
import { OptInOutEvolusRewards } from './OptInOutEvolusRewards';
import { OptInInsufficientVials } from './OptInInsufficientVials';
import { RequireNewPassword } from './RequireNewPassword';
import { CompleteProfile } from './CompleteProfile';
import { RestartCheckIn } from './RestartCheckIn';
import { RestartOrder } from './RestartOrder';
import { SYT2UpsellModal } from './SYT2Upsell';
import { TransactionDetailsModal } from './TransactionDetails';
import { UnsignedAgreementsModal } from './UnsignedAgreements';
import { ApplicationUpdate } from './ApplicationUpdate';
import { EvolusSMSOptedOut } from './EvolusSMSOptedOut';
import { SpecifyInvoices } from './SpecifyInvoices';
import { InvoicePdfViewer } from './InvoicePdfViewer';
import { PaymentExceedsBalance } from './PaymentExceedsBalance';
import { FacilitySelection } from './FacilitySelection';
var modalComponentLookupTable = {
    AccountSelection: AccountSelection,
    AccountStatementsFilters: AccountStatementsFilters,
    ApplicationUpdate: ApplicationUpdate,
    AddBankAccount: AddBankAccount,
    AddCreditCard: AddCreditCard,
    AdminAccessConfirmation: AdminAccessConfirmation,
    AnnualCampaign: AnnualCampaign,
    AutomaticLevelUpgrade: AutomaticLevelUpgrade,
    BenefitVideoPlayer: BenefitVideoPlayer,
    CheckInSuspended: CheckInSuspended,
    CompleteProfile: CompleteProfile,
    DownForMaintenance: DownForMaintenance,
    EditPaymentMethod: EditPaymentMethod,
    EvolusSMSOptedOut: EvolusSMSOptedOut,
    EvoluxLevelsList: EvoluxLevelsList,
    ForgotPassword: ForgotPassword,
    InvoicePdfViewer: InvoicePdfViewer,
    LeavingCheckIn: LeavingCheckIn,
    OptInInsufficientVials: OptInInsufficientVials,
    OptInOutEvolusRewards: OptInOutEvolusRewards,
    PaymentAuthError: PaymentAuthError,
    PaymentExceedsBalance: PaymentExceedsBalance,
    RemovePaymentMethod: RemovePaymentMethod,
    RemovePromoCode: RemovePromoCode,
    RemoveUser: RemoveUser,
    RequireNewPassword: RequireNewPassword,
    RestartCheckIn: RestartCheckIn,
    RestartOrder: RestartOrder,
    SpecifyInvoices: SpecifyInvoices,
    SYT2UpsellModal: SYT2UpsellModal,
    TransactionDetailsModal: TransactionDetailsModal,
    TransactionHistoryFilters: TransactionHistoryFilters,
    UnderConstruction: UnderConstruction,
    UnsignedAgreementsModal: UnsignedAgreementsModal,
    FacilitySelection: FacilitySelection,
};
var ModalManager = function () {
    var currentModals = useModal().currentModals;
    var renderModals = currentModals === null || currentModals === void 0 ? void 0 : currentModals.map(function (modalDescription, index) {
        var modalType = modalDescription.modalType, modalProps = modalDescription.modalProps;
        var ModalComponent = modalComponentLookupTable[modalType];
        return React.createElement(ModalComponent, __assign({}, modalProps, { key: modalType + index }));
    });
    return React.createElement("span", null, renderModals);
};
export default ModalManager;

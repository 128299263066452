var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
export var AccountLoyaltyLevelName;
(function (AccountLoyaltyLevelName) {
    AccountLoyaltyLevelName["Level_1"] = "LEVEL_1";
    AccountLoyaltyLevelName["Level_2"] = "LEVEL_2";
    AccountLoyaltyLevelName["Level_3"] = "LEVEL_3";
    AccountLoyaltyLevelName["Level_4"] = "LEVEL_4";
    AccountLoyaltyLevelName["Level_5"] = "LEVEL_5";
    AccountLoyaltyLevelName["Level_6"] = "LEVEL_6";
    AccountLoyaltyLevelName["Level_7"] = "LEVEL_7";
})(AccountLoyaltyLevelName || (AccountLoyaltyLevelName = {}));
export var AccountStatus;
(function (AccountStatus) {
    AccountStatus["Active"] = "ACTIVE";
    AccountStatus["Inactive"] = "INACTIVE";
})(AccountStatus || (AccountStatus = {}));
export var AccountType;
(function (AccountType) {
    AccountType["Checking"] = "CHECKING";
    AccountType["Savings"] = "SAVINGS";
})(AccountType || (AccountType = {}));
export var ActivityType;
(function (ActivityType) {
    ActivityType["CreditReceived"] = "CREDIT_RECEIVED";
    ActivityType["EvolusAdjustment"] = "EVOLUS_ADJUSTMENT";
    ActivityType["ManualCredit"] = "MANUAL_CREDIT";
    ActivityType["VialRedeemed"] = "VIAL_REDEEMED";
})(ActivityType || (ActivityType = {}));
export var AgreementStatus;
(function (AgreementStatus) {
    AgreementStatus["Unsent"] = "UNSENT";
    AgreementStatus["Emailed"] = "EMAILED";
    AgreementStatus["Signed"] = "SIGNED";
})(AgreementStatus || (AgreementStatus = {}));
export var AgreementType;
(function (AgreementType) {
    AgreementType["FinancialResponsibility"] = "FINANCIAL_RESPONSIBILITY";
    AgreementType["MedicalLiability"] = "MEDICAL_LIABILITY";
})(AgreementType || (AgreementType = {}));
export var Animal;
(function (Animal) {
    Animal["Bird"] = "BIRD";
    Animal["Cat"] = "CAT";
    Animal["Dog"] = "DOG";
    Animal["Fish"] = "FISH";
    Animal["Hippo"] = "HIPPO";
    Animal["Monkey"] = "MONKEY";
    Animal["Mouse"] = "MOUSE";
    Animal["Unicorn"] = "UNICORN";
    Animal["Hearts"] = "HEARTS";
    Animal["Roses"] = "ROSES";
    Animal["Arrow"] = "ARROW";
    Animal["Chocolate"] = "CHOCOLATE";
    Animal["Butterfly"] = "BUTTERFLY";
    Animal["Tulip"] = "TULIP";
    Animal["Bunny"] = "BUNNY";
    Animal["Bubbles"] = "BUBBLES";
    Animal["Ladybug"] = "LADYBUG";
    Animal["Kite"] = "KITE";
    Animal["Clover"] = "CLOVER";
})(Animal || (Animal = {}));
export var AssignablePermission;
(function (AssignablePermission) {
    AssignablePermission["ConsumerRewardsManage"] = "CONSUMER_REWARDS_MANAGE";
})(AssignablePermission || (AssignablePermission = {}));
export var CcNetwork;
(function (CcNetwork) {
    CcNetwork["Amex"] = "AMEX";
    CcNetwork["DinersClub"] = "DINERS_CLUB";
    CcNetwork["Discover"] = "DISCOVER";
    CcNetwork["Mastercard"] = "MASTERCARD";
    CcNetwork["Visa"] = "VISA";
})(CcNetwork || (CcNetwork = {}));
export var ChillFactor;
(function (ChillFactor) {
    ChillFactor["UwU"] = "UwU";
    ChillFactor["UAight"] = "U_AIGHT";
    ChillFactor["NahDawg"] = "NAH_DAWG";
})(ChillFactor || (ChillFactor = {}));
export var Color;
(function (Color) {
    Color["Red"] = "RED";
    Color["Green"] = "GREEN";
    Color["Yellow"] = "YELLOW";
    Color["Teal"] = "TEAL";
    Color["Purple"] = "PURPLE";
    Color["Pink"] = "PINK";
    Color["Coral"] = "CORAL";
    Color["Lavender"] = "LAVENDER";
    Color["Gold"] = "GOLD";
    Color["Violet"] = "VIOLET";
    Color["Lilac"] = "LILAC";
    Color["Babyblue"] = "BABYBLUE";
    Color["Sage"] = "SAGE";
    Color["Blush"] = "BLUSH";
    Color["Honey"] = "HONEY";
    Color["Tangerine"] = "TANGERINE";
})(Color || (Color = {}));
export var ConsumerProfileStatus;
(function (ConsumerProfileStatus) {
    ConsumerProfileStatus["Draft"] = "DRAFT";
    ConsumerProfileStatus["Complete"] = "COMPLETE";
})(ConsumerProfileStatus || (ConsumerProfileStatus = {}));
export var ConsumerRewardEligibilityStatus;
(function (ConsumerRewardEligibilityStatus) {
    ConsumerRewardEligibilityStatus["AlreadyRedeemed"] = "ALREADY_REDEEMED";
    ConsumerRewardEligibilityStatus["Eligible"] = "ELIGIBLE";
    ConsumerRewardEligibilityStatus["Expired"] = "EXPIRED";
    ConsumerRewardEligibilityStatus["ExpiredInGracePeriod"] = "EXPIRED_IN_GRACE_PERIOD";
    ConsumerRewardEligibilityStatus["NotYetActive"] = "NOT_YET_ACTIVE";
    ConsumerRewardEligibilityStatus["Voided"] = "VOIDED";
})(ConsumerRewardEligibilityStatus || (ConsumerRewardEligibilityStatus = {}));
export var ConsumerRewardOptInStatus;
(function (ConsumerRewardOptInStatus) {
    ConsumerRewardOptInStatus["OptedIn"] = "OPTED_IN";
    ConsumerRewardOptInStatus["OptedOut"] = "OPTED_OUT";
})(ConsumerRewardOptInStatus || (ConsumerRewardOptInStatus = {}));
export var ConsumerRewardsEnrollmentStatus;
(function (ConsumerRewardsEnrollmentStatus) {
    ConsumerRewardsEnrollmentStatus["OptedIn"] = "OPTED_IN";
    ConsumerRewardsEnrollmentStatus["OptedOut"] = "OPTED_OUT";
})(ConsumerRewardsEnrollmentStatus || (ConsumerRewardsEnrollmentStatus = {}));
export var DiscountPercentageBasis;
(function (DiscountPercentageBasis) {
    DiscountPercentageBasis["ListPrice"] = "LIST_PRICE";
    DiscountPercentageBasis["Subtotal"] = "SUBTOTAL";
})(DiscountPercentageBasis || (DiscountPercentageBasis = {}));
export var EnrollmentMethod;
(function (EnrollmentMethod) {
    EnrollmentMethod["Email"] = "EMAIL";
    EnrollmentMethod["Sms"] = "SMS";
})(EnrollmentMethod || (EnrollmentMethod = {}));
export var EntityType;
(function (EntityType) {
    EntityType["Business"] = "BUSINESS";
    EntityType["Personal"] = "PERSONAL";
})(EntityType || (EntityType = {}));
export var FacilityStatus;
(function (FacilityStatus) {
    FacilityStatus["Active"] = "ACTIVE";
    FacilityStatus["Inactive"] = "INACTIVE";
    FacilityStatus["ExpiredMedicalLicense"] = "EXPIRED_MEDICAL_LICENSE";
})(FacilityStatus || (FacilityStatus = {}));
export var FacilityType;
(function (FacilityType) {
    FacilityType["MedSpa"] = "MED_SPA";
    FacilityType["Dermatology"] = "DERMATOLOGY";
    FacilityType["PlasticSurgery"] = "PLASTIC_SURGERY";
    FacilityType["Dental"] = "DENTAL";
    FacilityType["Other"] = "OTHER";
})(FacilityType || (FacilityType = {}));
export var FeedbackEventType;
(function (FeedbackEventType) {
    FeedbackEventType["EvolusCreditPayment"] = "EVOLUS_CREDIT_PAYMENT";
    FeedbackEventType["OrderPlaced"] = "ORDER_PLACED";
})(FeedbackEventType || (FeedbackEventType = {}));
export var GiftCardStatus;
(function (GiftCardStatus) {
    GiftCardStatus["Cancelled"] = "CANCELLED";
    GiftCardStatus["Pending"] = "PENDING";
    GiftCardStatus["Fulfilled"] = "FULFILLED";
    GiftCardStatus["FulfillmentInProgress"] = "FULFILLMENT_IN_PROGRESS";
    GiftCardStatus["PdfReady"] = "PDF_READY";
})(GiftCardStatus || (GiftCardStatus = {}));
export var GroupStatus;
(function (GroupStatus) {
    GroupStatus["Active"] = "ACTIVE";
    GroupStatus["Inactive"] = "INACTIVE";
})(GroupStatus || (GroupStatus = {}));
export var InvoiceStatus;
(function (InvoiceStatus) {
    InvoiceStatus["Open"] = "OPEN";
    InvoiceStatus["Closed"] = "CLOSED";
    InvoiceStatus["Pending"] = "PENDING";
    InvoiceStatus["Unknown"] = "UNKNOWN";
})(InvoiceStatus || (InvoiceStatus = {}));
export var MedicalLicenseInvolvement;
(function (MedicalLicenseInvolvement) {
    MedicalLicenseInvolvement["WorksAtAccount"] = "WORKS_AT_ACCOUNT";
    MedicalLicenseInvolvement["SponsorsAccount"] = "SPONSORS_ACCOUNT";
})(MedicalLicenseInvolvement || (MedicalLicenseInvolvement = {}));
export var MedicalLicenseStatus;
(function (MedicalLicenseStatus) {
    MedicalLicenseStatus["Active"] = "ACTIVE";
    MedicalLicenseStatus["Expired"] = "EXPIRED";
    MedicalLicenseStatus["InGracePeriod"] = "IN_GRACE_PERIOD";
})(MedicalLicenseStatus || (MedicalLicenseStatus = {}));
export var OptInStatus;
(function (OptInStatus) {
    OptInStatus["OptedIn"] = "OPTED_IN";
    OptInStatus["OptedOut"] = "OPTED_OUT";
})(OptInStatus || (OptInStatus = {}));
export var OrderBillingStatus;
(function (OrderBillingStatus) {
    OrderBillingStatus["Declined"] = "DECLINED";
    OrderBillingStatus["Free"] = "FREE";
    OrderBillingStatus["Paid"] = "PAID";
    OrderBillingStatus["Unpaid"] = "UNPAID";
})(OrderBillingStatus || (OrderBillingStatus = {}));
export var OrderDiscountType;
(function (OrderDiscountType) {
    OrderDiscountType["BulkOrder"] = "BULK_ORDER";
    OrderDiscountType["CustomPricing"] = "CUSTOM_PRICING";
    OrderDiscountType["EvolusPointRedemption"] = "EVOLUS_POINT_REDEMPTION";
    OrderDiscountType["Generic"] = "GENERIC";
    OrderDiscountType["Jet"] = "JET";
    OrderDiscountType["Other"] = "OTHER";
    OrderDiscountType["PaymentMethod"] = "PAYMENT_METHOD";
    OrderDiscountType["Shipping"] = "SHIPPING";
    OrderDiscountType["PracticeApp"] = "PRACTICE_APP";
    OrderDiscountType["Custom"] = "CUSTOM";
})(OrderDiscountType || (OrderDiscountType = {}));
export var OrderPricingVersion;
(function (OrderPricingVersion) {
    OrderPricingVersion["V1"] = "V1";
    OrderPricingVersion["V2"] = "V2";
})(OrderPricingVersion || (OrderPricingVersion = {}));
export var OrderPromotionType;
(function (OrderPromotionType) {
    OrderPromotionType["BulkOrder"] = "BULK_ORDER";
    OrderPromotionType["Custom"] = "CUSTOM";
    OrderPromotionType["EvolusPointRedemption"] = "EVOLUS_POINT_REDEMPTION";
    OrderPromotionType["FreeGiftCards"] = "FREE_GIFT_CARDS";
    OrderPromotionType["Generic"] = "GENERIC";
})(OrderPromotionType || (OrderPromotionType = {}));
export var OrderShippingStatus;
(function (OrderShippingStatus) {
    OrderShippingStatus["Accepted"] = "ACCEPTED";
    OrderShippingStatus["Shipped"] = "SHIPPED";
    OrderShippingStatus["Unshipped"] = "UNSHIPPED";
})(OrderShippingStatus || (OrderShippingStatus = {}));
export var OrderStatus;
(function (OrderStatus) {
    OrderStatus["Accepted"] = "ACCEPTED";
    OrderStatus["Completed"] = "COMPLETED";
    OrderStatus["OnHold"] = "ON_HOLD";
    OrderStatus["PendingEvolusPointAuth"] = "PENDING_EVOLUS_POINT_AUTH";
    OrderStatus["Rejected"] = "REJECTED";
    OrderStatus["Shipped"] = "SHIPPED";
    OrderStatus["Submitted"] = "SUBMITTED";
})(OrderStatus || (OrderStatus = {}));
export var PaymentMethodAssignmentStatus;
(function (PaymentMethodAssignmentStatus) {
    PaymentMethodAssignmentStatus["Assigned"] = "ASSIGNED";
    PaymentMethodAssignmentStatus["Revoked"] = "REVOKED";
})(PaymentMethodAssignmentStatus || (PaymentMethodAssignmentStatus = {}));
export var PaymentMethodType;
(function (PaymentMethodType) {
    PaymentMethodType["Credit"] = "CREDIT";
    PaymentMethodType["Gateway"] = "GATEWAY";
})(PaymentMethodType || (PaymentMethodType = {}));
export var PaymentStatus;
(function (PaymentStatus) {
    PaymentStatus["Pending"] = "PENDING";
    PaymentStatus["Declined"] = "DECLINED";
    PaymentStatus["Accepted"] = "ACCEPTED";
})(PaymentStatus || (PaymentStatus = {}));
export var Permission;
(function (Permission) {
    Permission["AccountAdmin"] = "ACCOUNT_ADMIN";
    Permission["BaseAccess"] = "BASE_ACCESS";
    Permission["ConsumerRewardsManage"] = "CONSUMER_REWARDS_MANAGE";
})(Permission || (Permission = {}));
export var Platform;
(function (Platform) {
    Platform["Android"] = "ANDROID";
    Platform["Ios"] = "IOS";
    Platform["Web"] = "WEB";
})(Platform || (Platform = {}));
export var PracticeProfileUrlDeliveryMethod;
(function (PracticeProfileUrlDeliveryMethod) {
    PracticeProfileUrlDeliveryMethod["Link"] = "LINK";
    PracticeProfileUrlDeliveryMethod["Email"] = "EMAIL";
})(PracticeProfileUrlDeliveryMethod || (PracticeProfileUrlDeliveryMethod = {}));
export var PricingModel;
(function (PricingModel) {
    PricingModel["AccountLoyalty"] = "ACCOUNT_LOYALTY";
    PricingModel["CustomAccount"] = "CUSTOM_ACCOUNT";
    PricingModel["CustomGroup"] = "CUSTOM_GROUP";
    PricingModel["GroupLoyalty"] = "GROUP_LOYALTY";
    PricingModel["StimulusPrice"] = "STIMULUS_PRICE";
})(PricingModel || (PricingModel = {}));
export var QuarterSpecifier;
(function (QuarterSpecifier) {
    QuarterSpecifier["ThisQuarter"] = "THIS_QUARTER";
    QuarterSpecifier["LastQuarter"] = "LAST_QUARTER";
})(QuarterSpecifier || (QuarterSpecifier = {}));
export var RewardDeliveryMethod;
(function (RewardDeliveryMethod) {
    RewardDeliveryMethod["TextToVoice"] = "TEXT_TO_VOICE";
    RewardDeliveryMethod["Sms"] = "SMS";
    RewardDeliveryMethod["Email"] = "EMAIL";
})(RewardDeliveryMethod || (RewardDeliveryMethod = {}));
export var SearchableEntity;
(function (SearchableEntity) {
    SearchableEntity["Facility"] = "FACILITY";
    SearchableEntity["Order"] = "ORDER";
})(SearchableEntity || (SearchableEntity = {}));
export var SensitivePaymentType;
(function (SensitivePaymentType) {
    SensitivePaymentType["Ach"] = "ACH";
    SensitivePaymentType["Cc"] = "CC";
    SensitivePaymentType["Loc"] = "LOC";
})(SensitivePaymentType || (SensitivePaymentType = {}));
export var ShippingType;
(function (ShippingType) {
    ShippingType["PriorityOvernight"] = "PRIORITY_OVERNIGHT";
    ShippingType["StandardOvernight"] = "STANDARD_OVERNIGHT";
})(ShippingType || (ShippingType = {}));
export var SortDirection;
(function (SortDirection) {
    SortDirection["Asc"] = "ASC";
    SortDirection["Desc"] = "DESC";
})(SortDirection || (SortDirection = {}));
export var UserRole;
(function (UserRole) {
    UserRole["AccountAdmin"] = "ACCOUNT_ADMIN";
    UserRole["Custom"] = "CUSTOM";
})(UserRole || (UserRole = {}));
export var UserStatus;
(function (UserStatus) {
    UserStatus["Enabled"] = "ENABLED";
    UserStatus["Disabled"] = "DISABLED";
})(UserStatus || (UserStatus = {}));
export var GetConsumerRewardOptInStatusContentHeaderDocument = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query getConsumerRewardOptInStatusContentHeader(\n    $input: GetConsumerRewardOptInStatusInput!\n  ) {\n    getConsumerRewardOptInStatus(input: $input) {\n      lockStatus {\n        isLocked\n      }\n    }\n  }\n"], ["\n  query getConsumerRewardOptInStatusContentHeader(\n    $input: GetConsumerRewardOptInStatusInput!\n  ) {\n    getConsumerRewardOptInStatus(input: $input) {\n      lockStatus {\n        isLocked\n      }\n    }\n  }\n"])));
export var MainNavigationDocument = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  query MainNavigation($input: GetCommunicationOptInStatusInput!) {\n    currentUser {\n      id\n      firstName\n      lastName\n      accounts {\n        accountId\n      }\n    }\n    getCommunicationOptInStatus(input: $input) {\n      accountId\n      optInStatus\n    }\n  }\n"], ["\n  query MainNavigation($input: GetCommunicationOptInStatusInput!) {\n    currentUser {\n      id\n      firstName\n      lastName\n      accounts {\n        accountId\n      }\n    }\n    getCommunicationOptInStatus(input: $input) {\n      accountId\n      optInStatus\n    }\n  }\n"])));
export var ReachifyRedirectDocument = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  query ReachifyRedirect($input: GetCommunicationChatUrlInput!) {\n    getCommunicationChatUrl(input: $input) {\n      chatUrl\n    }\n  }\n"], ["\n  query ReachifyRedirect($input: GetCommunicationChatUrlInput!) {\n    getCommunicationChatUrl(input: $input) {\n      chatUrl\n    }\n  }\n"])));
export var CsvStatementDocument = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  query CSVStatement($input: GetTransactionHistoryCSVRequest!) {\n    getTransactionHistoryCSV(input: $input) {\n      csvResponse\n    }\n  }\n"], ["\n  query CSVStatement($input: GetTransactionHistoryCSVRequest!) {\n    getTransactionHistoryCSV(input: $input) {\n      csvResponse\n    }\n  }\n"])));
export var GetFacilitiessDocument = gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  query GetFacilitiess(\n    $id: String!\n    $input: GetConsumerRewardOptInStatusInput!\n    $injectorInput: GetInjectorsInput!\n  ) {\n    accountById(id: $id) {\n      facilities {\n        id\n        name\n        shippingAddress {\n          addressLine1\n          addressLine2\n          city\n          state\n          zip\n        }\n        createdAt\n      }\n    }\n    getConsumerRewardOptInHistory(input: $input) {\n      history {\n        status\n        updatedAt\n      }\n    }\n    getInjectors(input: $injectorInput) {\n      externalLookupSuccess\n      injectors {\n        name\n        uniqueId\n        externalId\n      }\n    }\n  }\n"], ["\n  query GetFacilitiess(\n    $id: String!\n    $input: GetConsumerRewardOptInStatusInput!\n    $injectorInput: GetInjectorsInput!\n  ) {\n    accountById(id: $id) {\n      facilities {\n        id\n        name\n        shippingAddress {\n          addressLine1\n          addressLine2\n          city\n          state\n          zip\n        }\n        createdAt\n      }\n    }\n    getConsumerRewardOptInHistory(input: $input) {\n      history {\n        status\n        updatedAt\n      }\n    }\n    getInjectors(input: $injectorInput) {\n      externalLookupSuccess\n      injectors {\n        name\n        uniqueId\n        externalId\n      }\n    }\n  }\n"])));
export var AutomagicStatementOptOutDocument = gql(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  mutation AutomagicStatementOptOut(\n    $input: SetTransactionHistoryStatementOptInRequest!\n  ) {\n    setTransactionHistoryStatementOptIn(input: $input) {\n      status\n    }\n  }\n"], ["\n  mutation AutomagicStatementOptOut(\n    $input: SetTransactionHistoryStatementOptInRequest!\n  ) {\n    setTransactionHistoryStatementOptIn(input: $input) {\n      status\n    }\n  }\n"])));
export var GetAutomagicStatementStatusDocument = gql(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  query GetAutomagicStatementStatus(\n    $input: GetTransactionHistoryStatementOptInRequest!\n  ) {\n    getTransactionHistoryStatementOptIn(input: $input) {\n      status\n    }\n  }\n"], ["\n  query GetAutomagicStatementStatus(\n    $input: GetTransactionHistoryStatementOptInRequest!\n  ) {\n    getTransactionHistoryStatementOptIn(input: $input) {\n      status\n    }\n  }\n"])));
export var GetConsumerProfileEnrollmentStatusDocument = gql(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  query GetConsumerProfileEnrollmentStatus(\n    $input: GetConsumerProfileEnrollmentStatusRequest!\n  ) {\n    getConsumerProfileEnrollmentStatus(input: $input) {\n      isAlreadyEnrolled\n    }\n  }\n"], ["\n  query GetConsumerProfileEnrollmentStatus(\n    $input: GetConsumerProfileEnrollmentStatusRequest!\n  ) {\n    getConsumerProfileEnrollmentStatus(input: $input) {\n      isAlreadyEnrolled\n    }\n  }\n"])));
export var ResendProfileCompletionLinkByEmailDocument = gql(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  mutation ResendProfileCompletionLinkByEmail(\n    $input: ResendEnrollmentLinkRequest!\n  ) {\n    resendProfileCompletionLink(input: $input) {\n      isAlreadyCompleted\n    }\n  }\n"], ["\n  mutation ResendProfileCompletionLinkByEmail(\n    $input: ResendEnrollmentLinkRequest!\n  ) {\n    resendProfileCompletionLink(input: $input) {\n      isAlreadyCompleted\n    }\n  }\n"])));
export var CheckInConsumerCheckInEnrolledDocument = gql(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  mutation CheckInConsumerCheckInEnrolled($input: CheckInConsumerRequest!) {\n    checkInConsumer(input: $input) {\n      checkIn {\n        id\n      }\n      profile {\n        status\n        enrolledAt\n        enrollmentMethod\n        email\n        sourceFacilityId\n        sourceAccountId\n      }\n      reward {\n        eligibilityStatus\n        eligibleAt\n        expiration\n        earnedAt\n        rewardSource\n        rewardAmount\n        isExtension\n      }\n      promotion {\n        adjustedAmount\n        endDate\n        startDate\n        name\n        metadata {\n          isQualified\n          isElevensDay\n          isEnabled\n        }\n      }\n    }\n  }\n"], ["\n  mutation CheckInConsumerCheckInEnrolled($input: CheckInConsumerRequest!) {\n    checkInConsumer(input: $input) {\n      checkIn {\n        id\n      }\n      profile {\n        status\n        enrolledAt\n        enrollmentMethod\n        email\n        sourceFacilityId\n        sourceAccountId\n      }\n      reward {\n        eligibilityStatus\n        eligibleAt\n        expiration\n        earnedAt\n        rewardSource\n        rewardAmount\n        isExtension\n      }\n      promotion {\n        adjustedAmount\n        endDate\n        startDate\n        name\n        metadata {\n          isQualified\n          isElevensDay\n          isEnabled\n        }\n      }\n    }\n  }\n"])));
export var CreateConsumerRewardDocument = gql(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  mutation CreateConsumerReward($input: CreateConsumerRewardRequest!) {\n    createConsumerReward(input: $input) {\n      reward {\n        eligibilityStatus\n      }\n    }\n  }\n"], ["\n  mutation CreateConsumerReward($input: CreateConsumerRewardRequest!) {\n    createConsumerReward(input: $input) {\n      reward {\n        eligibilityStatus\n      }\n    }\n  }\n"])));
export var SendConsumerRewardSecretCheckInEnrolledDocument = gql(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  mutation SendConsumerRewardSecretCheckInEnrolled(\n    $input: SendRewardSecretRequest!\n  ) {\n    sendConsumerRewardSecret(input: $input) {\n      attemptsRemaining\n    }\n  }\n"], ["\n  mutation SendConsumerRewardSecretCheckInEnrolled(\n    $input: SendRewardSecretRequest!\n  ) {\n    sendConsumerRewardSecret(input: $input) {\n      attemptsRemaining\n    }\n  }\n"])));
export var InitialLookupConsumerProfileSecondDocument = gql(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  query InitialLookupConsumerProfileSecond(\n    $input: LookupConsumerProfileInput!\n  ) {\n    lookupConsumerProfile(input: $input) {\n      profile {\n        status\n        enrolledAt\n        enrollmentMethod\n        email\n        sourceFacilityId\n        sourceAccountId\n      }\n      reward {\n        earnedAt\n        eligibleAt\n        expiration\n        eligibilityStatus\n        rewardSource\n      }\n      promotion {\n        adjustedAmount\n        startDate\n        endDate\n        name\n        metadata {\n          isQualified\n          isElevensDay\n          isEnabled\n        }\n      }\n    }\n  }\n"], ["\n  query InitialLookupConsumerProfileSecond(\n    $input: LookupConsumerProfileInput!\n  ) {\n    lookupConsumerProfile(input: $input) {\n      profile {\n        status\n        enrolledAt\n        enrollmentMethod\n        email\n        sourceFacilityId\n        sourceAccountId\n      }\n      reward {\n        earnedAt\n        eligibleAt\n        expiration\n        eligibilityStatus\n        rewardSource\n      }\n      promotion {\n        adjustedAmount\n        startDate\n        endDate\n        name\n        metadata {\n          isQualified\n          isElevensDay\n          isEnabled\n        }\n      }\n    }\n  }\n"])));
export var LookupPhoneNumberDocument = gql(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  query LookupPhoneNumber($input: LookupPhoneNumberRequest!) {\n    lookupPhoneNumber(input: $input) {\n      isBlackListed\n    }\n  }\n"], ["\n  query LookupPhoneNumber($input: LookupPhoneNumberRequest!) {\n    lookupPhoneNumber(input: $input) {\n      isBlackListed\n    }\n  }\n"])));
export var InitialLookupConsumerProfileFirstDocument = gql(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  query InitialLookupConsumerProfileFirst($input: LookupConsumerProfileInput!) {\n    lookupConsumerProfile(input: $input) {\n      profile {\n        status\n        enrolledAt\n        enrollmentMethod\n        email\n        sourceAccountId\n        sourceFacilityId\n        isOptedIn\n      }\n      reward {\n        earnedAt\n        eligibleAt\n        expiration\n        eligibilityStatus\n        rewardSource\n        rewardAmount\n        isExtension\n      }\n      promotion {\n        adjustedAmount\n        endDate\n        startDate\n        name\n        metadata {\n          isQualified\n          isElevensDay\n          isEnabled\n        }\n      }\n    }\n  }\n"], ["\n  query InitialLookupConsumerProfileFirst($input: LookupConsumerProfileInput!) {\n    lookupConsumerProfile(input: $input) {\n      profile {\n        status\n        enrolledAt\n        enrollmentMethod\n        email\n        sourceAccountId\n        sourceFacilityId\n        isOptedIn\n      }\n      reward {\n        earnedAt\n        eligibleAt\n        expiration\n        eligibilityStatus\n        rewardSource\n        rewardAmount\n        isExtension\n      }\n      promotion {\n        adjustedAmount\n        endDate\n        startDate\n        name\n        metadata {\n          isQualified\n          isElevensDay\n          isEnabled\n        }\n      }\n    }\n  }\n"])));
export var ResendProfileCompletionLinkDocument = gql(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  mutation ResendProfileCompletionLink($input: ResendEnrollmentLinkRequest!) {\n    resendProfileCompletionLink(input: $input) {\n      isAlreadyCompleted\n    }\n  }\n"], ["\n  mutation ResendProfileCompletionLink($input: ResendEnrollmentLinkRequest!) {\n    resendProfileCompletionLink(input: $input) {\n      isAlreadyCompleted\n    }\n  }\n"])));
export var CreateConsumerProfileDocument = gql(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  mutation CreateConsumerProfile($input: CreateConsumerProfileRequest!) {\n    createConsumerProfile(input: $input) {\n      profile {\n        status\n        enrollmentMethod\n      }\n    }\n  }\n"], ["\n  mutation CreateConsumerProfile($input: CreateConsumerProfileRequest!) {\n    createConsumerProfile(input: $input) {\n      profile {\n        status\n        enrollmentMethod\n      }\n    }\n  }\n"])));
export var OptConsumerInDocument = gql(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  mutation optConsumerIn($input: optConsumerInInput!) {\n    optConsumerIn(input: $input) {\n      profile {\n        status\n        enrollmentMethod\n      }\n    }\n  }\n"], ["\n  mutation optConsumerIn($input: optConsumerInInput!) {\n    optConsumerIn(input: $input) {\n      profile {\n        status\n        enrollmentMethod\n      }\n    }\n  }\n"])));
export var CheckInConsumerProvideCodeDocument = gql(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  mutation CheckInConsumerProvideCode($input: CheckInConsumerRequest!) {\n    checkInConsumer(input: $input) {\n      checkIn {\n        id\n      }\n      profile {\n        status\n        enrolledAt\n        enrollmentMethod\n        email\n        sourceFacilityId\n        sourceAccountId\n      }\n      reward {\n        eligibilityStatus\n        eligibleAt\n        expiration\n        earnedAt\n        rewardSource\n      }\n      promotion {\n        adjustedAmount\n        endDate\n        startDate\n        name\n        metadata {\n          isQualified\n          isElevensDay\n          isEnabled\n        }\n      }\n    }\n  }\n"], ["\n  mutation CheckInConsumerProvideCode($input: CheckInConsumerRequest!) {\n    checkInConsumer(input: $input) {\n      checkIn {\n        id\n      }\n      profile {\n        status\n        enrolledAt\n        enrollmentMethod\n        email\n        sourceFacilityId\n        sourceAccountId\n      }\n      reward {\n        eligibilityStatus\n        eligibleAt\n        expiration\n        earnedAt\n        rewardSource\n      }\n      promotion {\n        adjustedAmount\n        endDate\n        startDate\n        name\n        metadata {\n          isQualified\n          isElevensDay\n          isEnabled\n        }\n      }\n    }\n  }\n"])));
export var CreateConsumerRewardProvideDocument = gql(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  mutation CreateConsumerRewardProvide($input: CreateConsumerRewardRequest!) {\n    createConsumerReward(input: $input) {\n      reward {\n        eligibilityStatus\n      }\n    }\n  }\n"], ["\n  mutation CreateConsumerRewardProvide($input: CreateConsumerRewardRequest!) {\n    createConsumerReward(input: $input) {\n      reward {\n        eligibilityStatus\n      }\n    }\n  }\n"])));
export var SendConsumerRewardSecretCheckInProvideCodeDocument = gql(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  mutation SendConsumerRewardSecretCheckInProvideCode(\n    $input: SendRewardSecretRequest!\n  ) {\n    sendConsumerRewardSecret(input: $input) {\n      attemptsRemaining\n    }\n  }\n"], ["\n  mutation SendConsumerRewardSecretCheckInProvideCode(\n    $input: SendRewardSecretRequest!\n  ) {\n    sendConsumerRewardSecret(input: $input) {\n      attemptsRemaining\n    }\n  }\n"])));
export var RedeemConsumerRewardDocument = gql(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n  mutation RedeemConsumerReward($input: RedeemConsumerRewardRequest!) {\n    redeemConsumerReward(input: $input) {\n      attemptsRemaining\n      success\n    }\n  }\n"], ["\n  mutation RedeemConsumerReward($input: RedeemConsumerRewardRequest!) {\n    redeemConsumerReward(input: $input) {\n      attemptsRemaining\n      success\n    }\n  }\n"])));
export var SendConsumerRewardSecretValidatePhraseDocument = gql(templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n  mutation SendConsumerRewardSecretValidatePhrase(\n    $input: SendRewardSecretRequest!\n  ) {\n    sendConsumerRewardSecret(input: $input) {\n      attemptsRemaining\n    }\n  }\n"], ["\n  mutation SendConsumerRewardSecretValidatePhrase(\n    $input: SendRewardSecretRequest!\n  ) {\n    sendConsumerRewardSecret(input: $input) {\n      attemptsRemaining\n    }\n  }\n"])));
export var GetCheckInMilestoneDocument = gql(templateObject_24 || (templateObject_24 = __makeTemplateObject(["\n  query getCheckInMilestone($input: GetCheckInMilestoneRequest!) {\n    getCheckInMilestone(input: $input) {\n      currentMonthlyCheckIns\n      highScoreDifferential\n      isMileStone\n      isNewHighScore\n      seenByUser\n    }\n  }\n"], ["\n  query getCheckInMilestone($input: GetCheckInMilestoneRequest!) {\n    getCheckInMilestone(input: $input) {\n      currentMonthlyCheckIns\n      highScoreDifferential\n      isMileStone\n      isNewHighScore\n      seenByUser\n    }\n  }\n"])));
export var UpdateCheckInMilestoneDocument = gql(templateObject_25 || (templateObject_25 = __makeTemplateObject(["\n  mutation UpdateCheckInMilestone($input: UpdateCheckInMilestoneRequest!) {\n    updateCheckInMilestone(input: $input) {\n      success\n    }\n  }\n"], ["\n  mutation UpdateCheckInMilestone($input: UpdateCheckInMilestoneRequest!) {\n    updateCheckInMilestone(input: $input) {\n      success\n    }\n  }\n"])));
export var CheckInInitialQueryDocument = gql(templateObject_26 || (templateObject_26 = __makeTemplateObject(["\n  query CheckInInitialQuery($input: GetAvailableRewardPromotionRequest!) {\n    getAvailableRewardPromotion(input: $input) {\n      promotion {\n        name\n        endDate\n        startDate\n        adjustedAmount\n        metadata {\n          isQualified\n          isElevensDay\n          isEnabled\n        }\n      }\n    }\n  }\n"], ["\n  query CheckInInitialQuery($input: GetAvailableRewardPromotionRequest!) {\n    getAvailableRewardPromotion(input: $input) {\n      promotion {\n        name\n        endDate\n        startDate\n        adjustedAmount\n        metadata {\n          isQualified\n          isElevensDay\n          isEnabled\n        }\n      }\n    }\n  }\n"])));
export var UpdateRewardVialEncouragementDocument = gql(templateObject_27 || (templateObject_27 = __makeTemplateObject(["\n  mutation UpdateRewardVialEncouragement(\n    $input: UpdateRewardVialEncouragementRequest!\n  ) {\n    updateRewardVialEncouragement(input: $input) {\n      success\n    }\n  }\n"], ["\n  mutation UpdateRewardVialEncouragement(\n    $input: UpdateRewardVialEncouragementRequest!\n  ) {\n    updateRewardVialEncouragement(input: $input) {\n      success\n    }\n  }\n"])));
export var RewardReceivedMutationDocument = gql(templateObject_28 || (templateObject_28 = __makeTemplateObject(["\n  mutation RewardReceivedMutation($input: UpdateInjectorInput!) {\n    updateInjector(input: $input) {\n      injector {\n        name\n        uniqueId\n      }\n    }\n  }\n"], ["\n  mutation RewardReceivedMutation($input: UpdateInjectorInput!) {\n    updateInjector(input: $input) {\n      injector {\n        name\n        uniqueId\n      }\n    }\n  }\n"])));
export var TransactionFiltersQueryDocument = gql(templateObject_29 || (templateObject_29 = __makeTemplateObject(["\n  query TransactionFiltersQuery($input: GetInjectorsInput!) {\n    getInjectors(input: $input) {\n      externalLookupSuccess\n      injectors {\n        name\n        uniqueId\n      }\n    }\n  }\n"], ["\n  query TransactionFiltersQuery($input: GetInjectorsInput!) {\n    getInjectors(input: $input) {\n      externalLookupSuccess\n      injectors {\n        name\n        uniqueId\n      }\n    }\n  }\n"])));
export var SpecialistSelectQueryDocument = gql(templateObject_30 || (templateObject_30 = __makeTemplateObject(["\n  query SpecialistSelectQuery($input: GetInjectorsInput!) {\n    getInjectors(input: $input) {\n      externalLookupSuccess\n      injectors {\n        name\n        uniqueId\n        externalId\n      }\n    }\n  }\n"], ["\n  query SpecialistSelectQuery($input: GetInjectorsInput!) {\n    getInjectors(input: $input) {\n      externalLookupSuccess\n      injectors {\n        name\n        uniqueId\n        externalId\n      }\n    }\n  }\n"])));
export var StatementDocument = gql(templateObject_31 || (templateObject_31 = __makeTemplateObject(["\n  query Statement($input: GetTransactionHistoryStatementRequest!) {\n    getTransactionHistoryStatement(input: $input) {\n      imageURL\n      imageBuffer {\n        ContentLength\n        ContentEncoding\n        ContentType\n        Body {\n          type\n          data\n        }\n      }\n    }\n  }\n"], ["\n  query Statement($input: GetTransactionHistoryStatementRequest!) {\n    getTransactionHistoryStatement(input: $input) {\n      imageURL\n      imageBuffer {\n        ContentLength\n        ContentEncoding\n        ContentType\n        Body {\n          type\n          data\n        }\n      }\n    }\n  }\n"])));
export var ApplicationVersionQueryDocument = gql(templateObject_32 || (templateObject_32 = __makeTemplateObject(["\n  query ApplicationVersionQuery($input: GetApplicationVersionInput!) {\n    getAppVersion(input: $input) {\n      application\n      ver\n      forceUpdate\n    }\n  }\n"], ["\n  query ApplicationVersionQuery($input: GetApplicationVersionInput!) {\n    getAppVersion(input: $input) {\n      application\n      ver\n      forceUpdate\n    }\n  }\n"])));
export var MaintenanceQueryDocument = gql(templateObject_33 || (templateObject_33 = __makeTemplateObject(["\n  query MaintenanceQuery($input: GetApplicationMaintenanceInput!) {\n    getMaintenance(input: $input) {\n      message\n      enabled\n      startDate\n      endDate\n    }\n  }\n"], ["\n  query MaintenanceQuery($input: GetApplicationMaintenanceInput!) {\n    getMaintenance(input: $input) {\n      message\n      enabled\n      startDate\n      endDate\n    }\n  }\n"])));
export var CommonSegmentDataDocument = gql(templateObject_34 || (templateObject_34 = __makeTemplateObject(["\n  query CommonSegmentData($input: GetConsumerRewardOptInStatusInput!) {\n    currentUser {\n      id\n      accounts {\n        accountId\n        role\n        permissions\n        status\n        account {\n          id\n          createdAt\n          address {\n            zip\n            state\n          }\n          facilities {\n            id\n            status\n            shippingAddress {\n              state\n              zip\n            }\n            type\n          }\n        }\n      }\n    }\n    getConsumerRewardOptInStatus(input: $input) {\n      status {\n        status\n      }\n    }\n  }\n"], ["\n  query CommonSegmentData($input: GetConsumerRewardOptInStatusInput!) {\n    currentUser {\n      id\n      accounts {\n        accountId\n        role\n        permissions\n        status\n        account {\n          id\n          createdAt\n          address {\n            zip\n            state\n          }\n          facilities {\n            id\n            status\n            shippingAddress {\n              state\n              zip\n            }\n            type\n          }\n        }\n      }\n    }\n    getConsumerRewardOptInStatus(input: $input) {\n      status {\n        status\n      }\n    }\n  }\n"])));
export var GetConsumerRewardOptInStatusMiddlewareDocument = gql(templateObject_35 || (templateObject_35 = __makeTemplateObject(["\n  query getConsumerRewardOptInStatusMiddleware(\n    $input: GetConsumerRewardOptInStatusInput!\n  ) {\n    getConsumerRewardOptInStatus(input: $input) {\n      status {\n        status\n      }\n      lockStatus {\n        isLocked\n      }\n    }\n  }\n"], ["\n  query getConsumerRewardOptInStatusMiddleware(\n    $input: GetConsumerRewardOptInStatusInput!\n  ) {\n    getConsumerRewardOptInStatus(input: $input) {\n      status {\n        status\n      }\n      lockStatus {\n        isLocked\n      }\n    }\n  }\n"])));
export var SendUserTemporaryPasswordDocument = gql(templateObject_36 || (templateObject_36 = __makeTemplateObject(["\n  mutation SendUserTemporaryPassword($input: SendTemporaryPasswordInput!) {\n    sendUserTemporaryPasswordByEmail(input: $input) {\n      success\n    }\n  }\n"], ["\n  mutation SendUserTemporaryPassword($input: SendTemporaryPasswordInput!) {\n    sendUserTemporaryPasswordByEmail(input: $input) {\n      success\n    }\n  }\n"])));
export var UpdateUserAuthenticatorDocument = gql(templateObject_37 || (templateObject_37 = __makeTemplateObject(["\n  mutation UpdateUserAuthenticator($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      id\n    }\n  }\n"], ["\n  mutation UpdateUserAuthenticator($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      id\n    }\n  }\n"])));
export var GetConsumerRewardOptInStatusChooseAccountDocument = gql(templateObject_38 || (templateObject_38 = __makeTemplateObject(["\n  query getConsumerRewardOptInStatusChooseAccount(\n    $input: GetConsumerRewardOptInStatusInput!\n  ) {\n    getConsumerRewardOptInStatus(input: $input) {\n      status {\n        status\n      }\n    }\n  }\n"], ["\n  query getConsumerRewardOptInStatusChooseAccount(\n    $input: GetConsumerRewardOptInStatusInput!\n  ) {\n    getConsumerRewardOptInStatus(input: $input) {\n      status {\n        status\n      }\n    }\n  }\n"])));
export var GetFacilitiesDocument = gql(templateObject_39 || (templateObject_39 = __makeTemplateObject(["\n  query GetFacilities($id: String!) {\n    accountById(id: $id) {\n      facilities {\n        id\n        name\n        status\n        shippingAddress {\n          addressLine1\n          addressLine2\n          city\n          state\n          zip\n        }\n      }\n    }\n  }\n"], ["\n  query GetFacilities($id: String!) {\n    accountById(id: $id) {\n      facilities {\n        id\n        name\n        status\n        shippingAddress {\n          addressLine1\n          addressLine2\n          city\n          state\n          zip\n        }\n      }\n    }\n  }\n"])));
export var UpdateUserCompleteProfileDocument = gql(templateObject_40 || (templateObject_40 = __makeTemplateObject(["\n  mutation UpdateUserCompleteProfile($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      id\n      firstName\n      lastName\n      phoneNumber\n      email\n      accounts {\n        accountId\n        role\n      }\n      createdAt\n    }\n  }\n"], ["\n  mutation UpdateUserCompleteProfile($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      id\n      firstName\n      lastName\n      phoneNumber\n      email\n      accounts {\n        accountId\n        role\n      }\n      createdAt\n    }\n  }\n"])));
export var GetFreeVialProgressDocument = gql(templateObject_41 || (templateObject_41 = __makeTemplateObject(["\n  query GetFreeVialProgress(\n    $input: GetFreeVialProgressInput!\n    $inputOptInStatus: GetConsumerRewardOptInStatusInput!\n    $inputPromo: GetAvailableRewardPromotionRequest!\n  ) {\n    getFreeVialProgress(input: $input) {\n      rewardsRedeemedTowardsNextFreeVial\n      rewardsRequiredForNextFreeVial\n    }\n    getConsumerRewardOptInStatus(input: $inputOptInStatus) {\n      status {\n        status\n      }\n      lockStatus {\n        isLocked\n      }\n    }\n    getAvailableRewardPromotion(input: $inputPromo) {\n      promotion {\n        name\n        endDate\n        startDate\n        adjustedAmount\n        metadata {\n          isQualified\n          isElevensDay\n          isEnabled\n        }\n      }\n    }\n  }\n"], ["\n  query GetFreeVialProgress(\n    $input: GetFreeVialProgressInput!\n    $inputOptInStatus: GetConsumerRewardOptInStatusInput!\n    $inputPromo: GetAvailableRewardPromotionRequest!\n  ) {\n    getFreeVialProgress(input: $input) {\n      rewardsRedeemedTowardsNextFreeVial\n      rewardsRequiredForNextFreeVial\n    }\n    getConsumerRewardOptInStatus(input: $inputOptInStatus) {\n      status {\n        status\n      }\n      lockStatus {\n        isLocked\n      }\n    }\n    getAvailableRewardPromotion(input: $inputPromo) {\n      promotion {\n        name\n        endDate\n        startDate\n        adjustedAmount\n        metadata {\n          isQualified\n          isElevensDay\n          isEnabled\n        }\n      }\n    }\n  }\n"])));
export var EnhanceProfileDocument = gql(templateObject_42 || (templateObject_42 = __makeTemplateObject(["\n  mutation EnhanceProfile($input: CreatePracticeProfileUrlInput!) {\n    createEditPracticeProfileUrl(input: $input) {\n      editPracticeProfileUrl\n    }\n  }\n"], ["\n  mutation EnhanceProfile($input: CreatePracticeProfileUrlInput!) {\n    createEditPracticeProfileUrl(input: $input) {\n      editPracticeProfileUrl\n    }\n  }\n"])));
export var PracticeLocationDocument = gql(templateObject_43 || (templateObject_43 = __makeTemplateObject(["\n  query PracticeLocation($id: String!) {\n    facilityById(id: $id) {\n      id\n      name\n      shippingAddress {\n        addressLine1\n        addressLine2\n        city\n        state\n        zip\n      }\n    }\n  }\n"], ["\n  query PracticeLocation($id: String!) {\n    facilityById(id: $id) {\n      id\n      name\n      shippingAddress {\n        addressLine1\n        addressLine2\n        city\n        state\n        zip\n      }\n    }\n  }\n"])));
export var PatientRewardsStatementDocument = gql(templateObject_44 || (templateObject_44 = __makeTemplateObject(["\n  query PatientRewardsStatement($input: GetRewardInsightsReportRequest!) {\n    getRewardInsightsReport(input: $input) {\n      imageBuffer {\n        ContentLength\n        ContentEncoding\n        ContentType\n        Body {\n          type\n          data\n        }\n      }\n    }\n  }\n"], ["\n  query PatientRewardsStatement($input: GetRewardInsightsReportRequest!) {\n    getRewardInsightsReport(input: $input) {\n      imageBuffer {\n        ContentLength\n        ContentEncoding\n        ContentType\n        Body {\n          type\n          data\n        }\n      }\n    }\n  }\n"])));
export var WelcomeViewEncouragementDocument = gql(templateObject_45 || (templateObject_45 = __makeTemplateObject(["\n  query WelcomeViewEncouragement($input: GetRewardVialEncouragementRequest!) {\n    getRewardVialEncouragement(input: $input) {\n      freeShippingThreshold\n      freeVialsAvailable\n      hasEarnedNewVial\n      seenByUser\n      vialsNeededForFreeShipping\n    }\n  }\n"], ["\n  query WelcomeViewEncouragement($input: GetRewardVialEncouragementRequest!) {\n    getRewardVialEncouragement(input: $input) {\n      freeShippingThreshold\n      freeVialsAvailable\n      hasEarnedNewVial\n      seenByUser\n      vialsNeededForFreeShipping\n    }\n  }\n"])));
export var WelcomeViewDocument = gql(templateObject_46 || (templateObject_46 = __makeTemplateObject(["\n  query WelcomeView($input: GetConsumerRewardOptInStatusInput!) {\n    currentUser {\n      id\n      firstName\n      lastName\n      accounts {\n        accountId\n        role\n      }\n    }\n    getConsumerRewardOptInStatus(input: $input) {\n      status {\n        status\n      }\n    }\n  }\n"], ["\n  query WelcomeView($input: GetConsumerRewardOptInStatusInput!) {\n    currentUser {\n      id\n      firstName\n      lastName\n      accounts {\n        accountId\n        role\n      }\n    }\n    getConsumerRewardOptInStatus(input: $input) {\n      status {\n        status\n      }\n    }\n  }\n"])));
export var GetTransactionHistoryActivityRewardActivityDocument = gql(templateObject_47 || (templateObject_47 = __makeTemplateObject(["\n  query GetTransactionHistoryActivityRewardActivity(\n    $input: GetTransactionHistoryActivityRequest!\n  ) {\n    getTransactionHistoryActivity(input: $input) {\n      pageCount\n      reportId\n      activity {\n        consumerFirstName\n        consumerLastName\n        activityType\n        occurredOn\n        phoneNumber\n        creditsEarned\n        facilityName\n        rewardsGiven\n        redeemedForQuantity\n        redeemedForPoints\n        orderId\n        staffFirstName\n        staffLastName\n        email\n        checkInId\n        facilityId\n        accountId\n        injector {\n          name\n          uniqueId\n          externalId\n        }\n      }\n    }\n  }\n"], ["\n  query GetTransactionHistoryActivityRewardActivity(\n    $input: GetTransactionHistoryActivityRequest!\n  ) {\n    getTransactionHistoryActivity(input: $input) {\n      pageCount\n      reportId\n      activity {\n        consumerFirstName\n        consumerLastName\n        activityType\n        occurredOn\n        phoneNumber\n        creditsEarned\n        facilityName\n        rewardsGiven\n        redeemedForQuantity\n        redeemedForPoints\n        orderId\n        staffFirstName\n        staffLastName\n        email\n        checkInId\n        facilityId\n        accountId\n        injector {\n          name\n          uniqueId\n          externalId\n        }\n      }\n    }\n  }\n"])));
export var GetTransactionHistoryActivityRewardFacilityDocument = gql(templateObject_48 || (templateObject_48 = __makeTemplateObject(["\n  query GetTransactionHistoryActivityRewardFacility($id: String!) {\n    facilityById(id: $id) {\n      id\n      name\n    }\n  }\n"], ["\n  query GetTransactionHistoryActivityRewardFacility($id: String!) {\n    facilityById(id: $id) {\n      id\n      name\n    }\n  }\n"])));
export var GetTransactionHistorySummaryDocument = gql(templateObject_49 || (templateObject_49 = __makeTemplateObject(["\n  query GetTransactionHistorySummary(\n    $input: GetTransactionHistorySummaryRequest!\n  ) {\n    getTransactionHistorySummary(input: $input) {\n      totalFreeVialsAvailable\n      totalRewardPointsEarned\n      totalRewardsGiven\n      totalVialsEarned\n      totalVialsRedeemed\n    }\n  }\n"], ["\n  query GetTransactionHistorySummary(\n    $input: GetTransactionHistorySummaryRequest!\n  ) {\n    getTransactionHistorySummary(input: $input) {\n      totalFreeVialsAvailable\n      totalRewardPointsEarned\n      totalRewardsGiven\n      totalVialsEarned\n      totalVialsRedeemed\n    }\n  }\n"])));
export var GetMyFacilitiesDocument = gql(templateObject_50 || (templateObject_50 = __makeTemplateObject(["\n  query GetMyFacilities(\n    $id: String!\n    $input: GetTransactionHistoryActivityRequest!\n  ) {\n    accountById(id: $id) {\n      facilities {\n        id\n        name\n        status\n        shippingAddress {\n          city\n          state\n        }\n      }\n    }\n    getTransactionHistoryActivity(input: $input) {\n      activity {\n        facilityId\n        activityType\n        creditsEarned\n      }\n    }\n  }\n"], ["\n  query GetMyFacilities(\n    $id: String!\n    $input: GetTransactionHistoryActivityRequest!\n  ) {\n    accountById(id: $id) {\n      facilities {\n        id\n        name\n        status\n        shippingAddress {\n          city\n          state\n        }\n      }\n    }\n    getTransactionHistoryActivity(input: $input) {\n      activity {\n        facilityId\n        activityType\n        creditsEarned\n      }\n    }\n  }\n"])));
export var RecentStatementsDocument = gql(templateObject_51 || (templateObject_51 = __makeTemplateObject(["\n  query RecentStatements(\n    $id: String!\n    $input: GetConsumerRewardOptInStatusInput!\n    $featureInput: GetIsFeatureEnabledInput!\n  ) {\n    accountById(id: $id) {\n      id\n      name\n      address {\n        addressLine1\n        addressLine2\n        city\n        state\n        zip\n      }\n      createdAt\n    }\n    getConsumerRewardOptInHistory(input: $input) {\n      history {\n        status\n        updatedAt\n      }\n    }\n    isFeatureEnabled(input: $featureInput) {\n      enabled\n    }\n  }\n"], ["\n  query RecentStatements(\n    $id: String!\n    $input: GetConsumerRewardOptInStatusInput!\n    $featureInput: GetIsFeatureEnabledInput!\n  ) {\n    accountById(id: $id) {\n      id\n      name\n      address {\n        addressLine1\n        addressLine2\n        city\n        state\n        zip\n      }\n      createdAt\n    }\n    getConsumerRewardOptInHistory(input: $input) {\n      history {\n        status\n        updatedAt\n      }\n    }\n    isFeatureEnabled(input: $featureInput) {\n      enabled\n    }\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23, templateObject_24, templateObject_25, templateObject_26, templateObject_27, templateObject_28, templateObject_29, templateObject_30, templateObject_31, templateObject_32, templateObject_33, templateObject_34, templateObject_35, templateObject_36, templateObject_37, templateObject_38, templateObject_39, templateObject_40, templateObject_41, templateObject_42, templateObject_43, templateObject_44, templateObject_45, templateObject_46, templateObject_47, templateObject_48, templateObject_49, templateObject_50, templateObject_51;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { Typography } from 'designSystem';
import NoHistoryGraphic from 'assets/search-user.svg';
import styled, { css } from 'styled-components';
import { addMobileStyles } from '../../../utils/addMobileStyles';
export var EmptyHistory = function () {
    return (React.createElement(EmptyHistoryContainer, null,
        React.createElement(Typography, null, "Nothing here yet."),
        React.createElement("img", { alt: "no-payment-history", src: NoHistoryGraphic })));
};
var EmptyHistoryContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: 32px;\n  display: flex;\n  align-items: center;\n  gap: 32px;\n  flex: 1;\n  justify-content: center;\n  & > img {\n    width: 100%;\n    max-width: 228px;\n  }\n  ", "\n"], ["\n  margin: 32px;\n  display: flex;\n  align-items: center;\n  gap: 32px;\n  flex: 1;\n  justify-content: center;\n  & > img {\n    width: 100%;\n    max-width: 228px;\n  }\n  ", "\n"])), addMobileStyles(css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    margin: 0;\n    flex-direction: column-reverse;\n    gap: 0;\n    width: 100% !important;\n  "], ["\n    margin: 0;\n    flex-direction: column-reverse;\n    gap: 0;\n    width: 100% !important;\n  "])))));
var templateObject_1, templateObject_2;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import EvolusLogo from 'assets/evolus-logo-white.svg';
import { Typography } from 'designSystem';
import EvolusFooterLogo from 'assets/evolus-footer-butterfly.svg';
import React from 'react';
import styled from 'styled-components';
export var Footer = function () {
    return (React.createElement(Container, null,
        React.createElement(FooterContainer, null,
            React.createElement(EvolusLogoImg, { src: EvolusLogo }),
            React.createElement(FooterLinkContainer, null,
                React.createElement(FooterLink, { href: "#" },
                    React.createElement(Typography, { variant: "bodySmall", color: "alt3" }, "Terms & Conditions")),
                React.createElement(FooterLink, { href: "#" },
                    React.createElement(Typography, { variant: "bodySmall", color: "alt3" }, "Privacy Policy")),
                React.createElement(FooterLink, { href: "#" },
                    React.createElement(Typography, { variant: "bodySmall", color: "alt3" }, "Prescribing Information")),
                React.createElement(FooterLink, { href: "#" },
                    React.createElement(Typography, { variant: "bodySmall", color: "alt3" }, "Evolus Rewards Terms & Conditions")),
                React.createElement(FooterLink, { href: "#" },
                    React.createElement(Typography, { variant: "bodySmall", color: "alt3" }, "Evolux Terms & Conditions"))),
            React.createElement(FooterText, { display: "block", variant: "footnote", color: "alt5", mt: 16 }, "Exclusively licensed and manufactured for: Evolus Inc., 520 Newport Center Drive, Suite 1200, Newport Beach, CA 92660"),
            React.createElement(FooterText, { variant: "footnote", color: "alt5" }, "All rights reserved. All trademarks are the property of their respective owners."),
            React.createElement(EvoluxBackgroundLogo, { src: EvolusFooterLogo }))));
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: ", ";\n  padding-top: 48px;\n  @media screen and (max-width: ", "px) {\n    background: ", ";\n  }\n"], ["\n  background: ", ";\n  padding-top: 48px;\n  @media screen and (max-width: ", "px) {\n    background: ", ";\n  }\n"])), function (props) { return props.theme.colors.bg.base; }, function (props) { return props.theme.breakpoints.md; }, function (props) { return props.theme.colors.basic.white; });
var FooterContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  background-color: ", ";\n  padding: 64px 180px;\n  position: relative;\n  overflow: hidden;\n  @media screen and (max-width: ", "px) {\n    display: flex;\n    flex-direction: column;\n    padding: 32px;\n    margin-top: -16px;\n    height: auto;\n  }\n"], ["\n  width: 100%;\n  background-color: ", ";\n  padding: 64px 180px;\n  position: relative;\n  overflow: hidden;\n  @media screen and (max-width: ", "px) {\n    display: flex;\n    flex-direction: column;\n    padding: 32px;\n    margin-top: -16px;\n    height: auto;\n  }\n"])), function (props) { return props.theme.colors.basic.black; }, function (props) { return props.theme.breakpoints.md; });
var EvolusLogoImg = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 188px;\n  height: 43px;\n  z-index: 2;\n"], ["\n  width: 188px;\n  height: 43px;\n  z-index: 2;\n"])));
var FooterLinkContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  margin-top: 48px;\n  @media screen and (max-width: ", "px) {\n    flex-direction: column;\n    margin-top: 32px;\n  }\n"], ["\n  display: flex;\n  margin-top: 48px;\n  @media screen and (max-width: ", "px) {\n    flex-direction: column;\n    margin-top: 32px;\n  }\n"])), function (props) { return props.theme.breakpoints.md; });
var FooterLink = styled.a(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  all: unset;\n  cursor: pointer;\n  text-decoration: none;\n  z-index: 2;\n  &:hover {\n    border-bottom: 2px solid ", ";\n    margin-bottom: -2px;\n  }\n  &:focus {\n    border-bottom: 2px solid ", ";\n    margin-bottom: -2px;\n  }\n  margin-right: 40px;\n  @media screen and (max-width: ", "px) {\n    margin: 8px 0;\n    &:hover {\n      border-bottom: none;\n      margin-bottom: 8px;\n    }\n    &:focus {\n      border-bottom: none;\n      margin-bottom: 8px;\n    }\n  }\n"], ["\n  all: unset;\n  cursor: pointer;\n  text-decoration: none;\n  z-index: 2;\n  &:hover {\n    border-bottom: 2px solid ", ";\n    margin-bottom: -2px;\n  }\n  &:focus {\n    border-bottom: 2px solid ", ";\n    margin-bottom: -2px;\n  }\n  margin-right: 40px;\n  @media screen and (max-width: ", "px) {\n    margin: 8px 0;\n    &:hover {\n      border-bottom: none;\n      margin-bottom: 8px;\n    }\n    &:focus {\n      border-bottom: none;\n      margin-bottom: 8px;\n    }\n  }\n"])), function (props) { return props.theme.colors.text.alt3; }, function (props) { return props.theme.colors.text.alt3; }, function (props) { return props.theme.breakpoints.md; });
var EvoluxBackgroundLogo = styled.img(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: absolute;\n  right: -10px;\n  top: 40px;\n  width: 20rem;\n  z-index: 0;\n  @media screen and (max-width: ", "px) {\n    top: 150px;\n  }\n"], ["\n  position: absolute;\n  right: -10px;\n  top: 40px;\n  width: 20rem;\n  z-index: 0;\n  @media screen and (max-width: ", "px) {\n    top: 150px;\n  }\n"])), function (props) { return props.theme.breakpoints.md; });
var FooterText = styled(Typography)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  z-index: 2;\n"], ["\n  z-index: 2;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import LoadingBubblesGif from 'assets/loading-bubbles.gif';
export var LoadingBubbles = function () {
    return (React.createElement(ImageContainer, null,
        React.createElement(Image, { src: LoadingBubblesGif })));
};
var Image = styled.img(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 89px;\n  height: 24px;\n  object-fit: contain;\n"], ["\n  width: 89px;\n  height: 24px;\n  object-fit: contain;\n"])));
var ImageContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"], ["\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"])));
var templateObject_1, templateObject_2;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled, { css } from 'styled-components';
import { Typography } from 'designSystem';
import { cardStyles } from '../../Dashboard/constants/CardStyles';
import { useLocation } from 'react-router';
import { PaymentSteps } from '../enums/Steps';
import { addMobileStyles } from '../../../../../utils/addMobileStyles';
export var StepContainer = function (_a) {
    var header = _a.header, subHeader = _a.subHeader, tertiaryHeader = _a.tertiaryHeader, children = _a.children, imageOverlay = _a.imageOverlay;
    var location = useLocation();
    var isOverview = location.search === PaymentSteps.PaymentOverview;
    return (React.createElement(Container, null,
        !!imageOverlay && (React.createElement(Image, { src: imageOverlay, rightOffset: isOverview ? 120 : 53, topOffset: isOverview ? 0 : 32, alt: "pay-invoice" })),
        React.createElement(Typography, { display: "block", mb: 4, variant: "sectionHeader", color: "alt5" }, subHeader),
        React.createElement(Typography, { display: "block", variant: "titleLarge" }, header),
        tertiaryHeader && (React.createElement(Typography, { display: "block", mt: 16, color: "alt" }, tertiaryHeader)),
        children));
};
var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 48px;\n  ", ";\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  position: relative;\n  ", "\n"], ["\n  padding: 48px;\n  ", ";\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  position: relative;\n  ", "\n"])), cardStyles, addMobileStyles(css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    padding: 32px;\n  "], ["\n    padding: 32px;\n  "])))));
var Image = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  top: ", "px;\n  right: ", "px;\n  max-width: 256px;\n"], ["\n  position: absolute;\n  top: ", "px;\n  right: ", "px;\n  max-width: 256px;\n"])), function (props) { return props.topOffset; }, function (props) { return props.rightOffset; });
var templateObject_1, templateObject_2, templateObject_3;

import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';
export var Desktop = function (_a) {
    var children = _a.children;
    var theme = useTheme();
    var isDesktop = useMediaQuery({ minWidth: theme.breakpoints.md });
    return isDesktop ? children : null;
};
export var Mobile = function (_a) {
    var children = _a.children;
    var theme = useTheme();
    var isMobile = useMediaQuery({ maxWidth: theme.breakpoints.md });
    return isMobile ? children : null;
};
export var Default = function (_a) {
    var children = _a.children;
    var theme = useTheme();
    var isNotMobile = useMediaQuery({ minWidth: theme.breakpoints.md });
    return isNotMobile ? children : null;
};

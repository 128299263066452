import React from 'react';
export var Club1kBenefits = function (_a) {
    var color = _a.color;
    return (React.createElement("svg", { width: "36", height: "36", viewBox: "0 0 36 36", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("rect", { width: "36", height: "36", rx: "18", fill: "#F15E58" }),
        React.createElement("path", { d: "M11.2275 10.3635H13.4876V18.5931H15.7031V8.17773H12.8128L11.2275 10.3635Z", fill: "black" }),
        React.createElement("path", { d: "M16.9153 18.5931H19.2326V13.9287L23.4726 18.5931H26.4648L21.5203 13.1796L25.6287 8.17773H22.7681L19.2326 12.5344V8.17773H16.9153V18.5931Z", fill: "black" }),
        React.createElement("path", { d: "M10.5005 27.8227C11.831 27.8227 12.9643 27.2201 13.6518 26.2693V27.6997H18.3332V26.8826C18.8701 27.479 19.6638 27.8227 20.606 27.8227C22.3631 27.8227 23.6237 26.6237 23.6237 24.7945V20.3614H21.9811V24.8157C21.9811 25.6943 21.423 26.2311 20.606 26.2311C19.789 26.2311 19.2309 25.6943 19.2309 24.8157V20.3614H17.5884V24.7945C17.5884 25.2889 17.6817 25.7367 17.8515 26.1272H15.2943V20.3614H13.6518V21.7917C12.9643 20.8388 11.831 20.2383 10.5005 20.2383C8.40383 20.2383 6.70825 21.9423 6.70825 24.0305C6.70825 26.1187 8.40383 27.8227 10.5005 27.8227ZM10.5005 21.8299C11.4194 21.8299 12.0708 22.2946 12.4847 23.0289L13.6518 22.3944V25.6667L12.4847 25.0321C12.0708 25.7664 11.4194 26.2311 10.5005 26.2311C9.30148 26.2311 8.36138 25.2189 8.36138 24.0305C8.36138 22.8421 9.30148 21.8299 10.5005 21.8299Z", fill: "white" }),
        React.createElement("path", { d: "M26.5944 20.3638H23.8357V27.6999H27.0592C28.3197 27.6999 29.2917 26.7492 29.2917 25.5396C29.2917 24.7544 28.8672 24.1242 28.2476 23.8037C28.568 23.4939 28.7739 23.0589 28.7739 22.5432C28.7739 21.3336 27.7913 20.3638 26.5944 20.3638ZM25.4782 21.862H26.4077C26.8512 21.862 27.1717 22.2036 27.1717 22.6069C27.1717 23.0101 26.8512 23.3305 26.4077 23.3305H25.4782V21.8641V21.862ZM26.9255 26.1911H25.4782V24.7247H26.9149C27.3584 24.7247 27.6895 25.0558 27.6895 25.459C27.6895 25.8622 27.3584 26.1932 26.9255 26.1932V26.1911Z", fill: "white" })));
};

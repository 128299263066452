var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { Modal } from '../Modal';
import { Button, LoadingBubbles, Typography } from 'designSystem';
import { AgreementCard } from './components/AgreementCard';
import { useModal, useUser } from 'context';
import AgreementsGraphic from 'assets/agreements.svg';
import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router';
import { useScreenSizing } from '../../../hooks';
import { addMobileStyles } from '../../../utils/addMobileStyles';
export var UnsignedAgreementsView = function (_a) {
    var _b;
    var financialSigned = _a.financialSigned, medicalSigned = _a.medicalSigned, onRefresh = _a.onRefresh, loading = _a.loading;
    var user = useUser().user;
    var isMobile = useScreenSizing().isMobile;
    var navigate = useNavigate();
    var closeModal = useModal().closeModal;
    var renderContent = function () {
        if (loading) {
            return React.createElement(LoadingBubbles, null);
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(AgreementCard, { label: "Financial Liability Agreement", signed: financialSigned }),
            React.createElement(AgreementCard, { label: "Medical Liability Agreement", signed: medicalSigned })));
    };
    var renderAccountSelection = (_b = user === null || user === void 0 ? void 0 : user.accounts.length) !== null && _b !== void 0 ? _b : 0 > 1;
    return (React.createElement(Modal, { showClose: false, size: "large" },
        React.createElement(Image, { src: AgreementsGraphic }),
        React.createElement(Typography, { display: "block", mt: 28, mb: 32, align: "center", color: isMobile ? 'link' : 'base', variant: "display" }, "Signatures Required"),
        React.createElement(Typography, { display: "block", align: "center", variant: "subtitle", color: "alt", mb: isMobile ? 24 : 48 },
            "Before you can access your account, we require signed financial and medical liability agreements.",
            !isMobile && ' These were sent via email'),
        isMobile && (React.createElement(Typography, { display: "block", align: "center", variant: "subtitle", color: "alt", mb: 32 }, "These were sent via email.")),
        React.createElement(CardsContainer, null, renderContent()),
        React.createElement(RefreshContainer, null,
            React.createElement(Button, { variant: "tertiary", onClick: onRefresh }, "refresh")),
        React.createElement(ActionsContainer, null,
            renderAccountSelection && (React.createElement(Button, { size: "xlarge", variant: isMobile ? 'tertiary' : 'outlined', onClick: function () {
                    closeModal();
                    navigate('/selectAccount');
                } }, isMobile ? 'Back to Accounts' : 'Back to Account Selection')),
            React.createElement(Button, { size: "xlarge", variant: "primary", onClick: function () { } }, "Help Me Complete This"))));
};
var CardsContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  gap: 12px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  gap: 12px;\n"])));
var ActionsContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  gap: 12px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-end;\n  ", "\n"], ["\n  width: 100%;\n  gap: 12px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-end;\n  ", "\n"])), addMobileStyles(css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    flex-direction: column-reverse;\n    justify-content: flex-start;\n    & > button {\n      width: 100%;\n    }\n  "], ["\n    flex-direction: column-reverse;\n    justify-content: flex-start;\n    & > button {\n      width: 100%;\n    }\n  "])))));
var RefreshContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n  margin: 12px 0;\n  ", "\n"], ["\n  width: 100%;\n  margin: 12px 0;\n  ", "\n"])), addMobileStyles(css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    margin: 12px 0 32px 0;\n  "], ["\n    margin: 12px 0 32px 0;\n  "])))));
var Image = styled.img(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  max-width: 300px;\n  width: 100%;\n  align-self: center;\n  ", "\n"], ["\n  max-width: 300px;\n  width: 100%;\n  align-self: center;\n  ", "\n"])), addMobileStyles(css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    max-width: 215px;\n  "], ["\n    max-width: 215px;\n  "])))));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;

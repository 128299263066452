var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var _a, _b;
import { useLocation } from 'react-router';
import React, { useMemo } from 'react';
import { Button, Desktop, LoadingBubbles, VerticalStepper } from 'designSystem';
import styled from 'styled-components';
import { StepEnum } from '../Enums/StepEnum';
var steps = [
    {
        id: 'step-0',
        name: 'Enter Mobile Number',
    },
    {
        id: 'step-1',
        name: 'Patient Reward Status',
    },
    {
        id: 'step-2',
        name: 'Select Specialist',
    },
    {
        id: 'step-3',
        name: 'Check-In Agreement',
    },
    {
        id: 'step-4',
        name: 'Validate Magic Phrase',
    },
    {
        id: 'step-5',
        name: 'Complete Check-In',
    },
];
var stepsWhenNoReward = [
    {
        id: 'step-0',
        name: 'Enter Mobile Number',
    },
    {
        id: 'step-1',
        name: 'Patient Reward Status',
    },
];
var pathToStepWhenNotCheckingIn = (_a = {},
    _a[StepEnum.PhoneInput] = 0,
    _a[StepEnum.EnrollPatient] = 0,
    _a[StepEnum.EnrollPatientForm] = 0,
    _a[StepEnum.NumberNotSupported] = 0,
    _a[StepEnum.PatientConsent] = 0,
    _a[StepEnum.SendingMessage] = 0,
    _a[StepEnum.EnrollmentProgress] = 1,
    _a[StepEnum.EmailInput] = 0,
    _a[StepEnum.ConsentNotGiven] = 0,
    _a[StepEnum.RewardStatus] = 1,
    _a[StepEnum.RewardReminderSent] = 1,
    _a);
var pathToStepIndex = (_b = {},
    _b[StepEnum.PhoneInput] = 0,
    _b[StepEnum.EnrollPatient] = 0,
    _b[StepEnum.EnrollPatientForm] = 0,
    _b[StepEnum.NumberNotSupported] = 0,
    _b[StepEnum.PatientConsent] = 0,
    _b[StepEnum.SendingMessage] = 0,
    _b[StepEnum.EnrollmentProgress] = 0,
    _b[StepEnum.EmailInput] = 0,
    _b[StepEnum.ConsentNotGiven] = 0,
    _b[StepEnum.RewardStatus] = 1,
    _b[StepEnum.SpecialistSelect] = 2,
    _b[StepEnum.CheckInAgreement] = 3,
    _b[StepEnum.SendMagicPhrase] = 4,
    _b[StepEnum.ValidateMagicPhrase] = 4,
    _b[StepEnum.CheckInComplete] = 5,
    _b);
export var CheckInProgress = function (_a) {
    var disabled = _a.disabled, isLoading = _a.isLoading, onContinue = _a.onContinue, primaryButtonText = _a.primaryButtonText, hidePrimaryButton = _a.hidePrimaryButton, secondaryButtonText = _a.secondaryButtonText, _b = _a.onSecondaryContinue, onSecondaryContinue = _b === void 0 ? function () { } : _b, tertiaryButtonText = _a.tertiaryButtonText, tertiaryAction = _a.tertiaryAction;
    var location = useLocation();
    var checkingIn = useMemo(function () {
        var yee = false;
        switch (location.search) {
            case StepEnum.SpecialistSelect:
            case StepEnum.CheckInAgreement:
            case StepEnum.SendMagicPhrase:
            case StepEnum.ValidateMagicPhrase:
            case StepEnum.CheckInComplete:
                yee = true;
        }
        return yee;
    }, [location]);
    return (React.createElement(OuterContainer, null,
        React.createElement(Desktop, null,
            React.createElement(Header, null, "Your Progress"),
            React.createElement(StepperContainer, null,
                React.createElement(VerticalStepper, { steps: !checkingIn ? stepsWhenNoReward : steps, currentStep: !checkingIn
                        ? pathToStepWhenNotCheckingIn[location.search]
                        : pathToStepIndex[location.search] }))),
        !hidePrimaryButton && (React.createElement(Button, { disabled: disabled || isLoading, onClick: function () { return onContinue(); }, size: "xlarge", "data-automation-id": "rewards-check-in-progress-primary" }, isLoading ? React.createElement(LoadingBubbles, null) : primaryButtonText || 'Continue')),
        secondaryButtonText && (React.createElement(Button, { mt: 12, onClick: function () { return onSecondaryContinue(); }, size: "xlarge", variant: "outlined", "data-automation-id": "rewards-check-in-progress-secondary" }, isLoading ? React.createElement(LoadingBubbles, null) : secondaryButtonText || 'Continue')),
        tertiaryButtonText && (React.createElement(Button, { mt: 12, onClick: function () { return tertiaryAction(); }, size: "xlarge", variant: "tertiary", "data-automation-id": "rewards-check-in-progress-tertiary" }, isLoading ? React.createElement(LoadingBubbles, null) : tertiaryButtonText || 'Continue'))));
};
export default CheckInProgress;
var OuterContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  background-color: white;\n  ", ";\n\n  margin: 16px;\n  padding: 32px;\n  flex-direction: column;\n  @media screen and (max-width: ", "px) {\n    flex: unset;\n    box-shadow: unset;\n    padding: 0 32px 32px;\n    margin: 0;\n    & > button:nth-of-type(2) {\n      margin: 12px 0 -16px;\n    }\n  }\n"], ["\n  display: flex;\n  background-color: white;\n  ", ";\n\n  margin: 16px;\n  padding: 32px;\n  flex-direction: column;\n  @media screen and (max-width: ", "px) {\n    flex: unset;\n    box-shadow: unset;\n    padding: 0 32px 32px;\n    margin: 0;\n    & > button:nth-of-type(2) {\n      margin: 12px 0 -16px;\n    }\n  }\n"])), function (props) { return props.theme.elevation.base.elevation1; }, function (props) { return props.theme.breakpoints.md; });
var StepperContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: 42px;\n"], ["\n  margin-bottom: 42px;\n"])));
var Header = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 1.125rem;\n  color: ", ";\n  margin-bottom: 36px;\n"], ["\n  font-size: 1.125rem;\n  color: ", ";\n  margin-bottom: 36px;\n"])), function (props) { return props.theme.colors.text.alt; });
var templateObject_1, templateObject_2, templateObject_3;

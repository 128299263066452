var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components';
var RadioButtonElement = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: inline-block;\n  vertical-align: middle;\n  margin-top: auto;\n  margin-bottom: auto;\n"], ["\n  display: inline-block;\n  vertical-align: middle;\n  margin-top: auto;\n  margin-bottom: auto;\n"])));
var RadioButtonLabel = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
var StyledTitle = styled.p(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: 0px;\n"], ["\n  margin: 0px;\n"])));
var RadioButtonContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  cursor: pointer;\n  display: inline-flex;\n  line-height: 1;\n  vertical-align: middle;\n  margin-right: 16px;\n"], ["\n  cursor: pointer;\n  display: inline-flex;\n  line-height: 1;\n  vertical-align: middle;\n  margin-right: 16px;\n"])));
var Root = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: ", "px;\n  height: ", "px;\n  position: relative;\n\n  &::before {\n    content: '';\n    border-radius: 100%;\n    border: 1px solid\n      ", ";\n    width: 100%;\n    height: 100%;\n    position: absolute;\n    top: 0;\n    left: 0;\n    box-sizing: border-box;\n    pointer-events: none;\n    z-index: 0;\n  }\n"], ["\n  width: ", "px;\n  height: ", "px;\n  position: relative;\n\n  &::before {\n    content: '';\n    border-radius: 100%;\n    border: 1px solid\n      ", ";\n    width: 100%;\n    height: 100%;\n    position: absolute;\n    top: 0;\n    left: 0;\n    box-sizing: border-box;\n    pointer-events: none;\n    z-index: 0;\n  }\n"])), function (props) { return (props.size ? props.size : 20); }, function (props) { return (props.size ? props.size : 20); }, function (props) { return (props.borderColor ? props.borderColor : '#DDD'); });
var Fill = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  background: ", ";\n  width: 0;\n  height: 0;\n  border-radius: 100%;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  transition: width 0.2s ease-in, height 0.2s ease-in;\n  pointer-events: none;\n\n  &::before {\n    content: '';\n    opacity: 0;\n    width: calc(20px - 2px);\n    position: absolute;\n    height: calc(20px - 2px);\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    border-radius: 100%;\n  }\n"], ["\n  background: ", ";\n  width: 0;\n  height: 0;\n  border-radius: 100%;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  transition: width 0.2s ease-in, height 0.2s ease-in;\n  pointer-events: none;\n\n  &::before {\n    content: '';\n    opacity: 0;\n    width: calc(20px - 2px);\n    position: absolute;\n    height: calc(20px - 2px);\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    border-radius: 100%;\n  }\n"])), function (props) {
    return props.fillColor ? props.fillColor : props.theme.colors.coral.coral500;
});
var FillWhite = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  background: ", ";\n  width: 0;\n  height: 0;\n  border-radius: 100%;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  transition: width 0.2s ease-in, height 0.2s ease-in;\n  pointer-events: none;\n  z-index: 1;\n\n  &::before {\n    content: '';\n    opacity: 0;\n    width: calc(10px - 4px);\n    position: absolute;\n    height: calc(10px - 4px);\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n  }\n"], ["\n  background: ", ";\n  width: 0;\n  height: 0;\n  border-radius: 100%;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  transition: width 0.2s ease-in, height 0.2s ease-in;\n  pointer-events: none;\n  z-index: 1;\n\n  &::before {\n    content: '';\n    opacity: 0;\n    width: calc(10px - 4px);\n    position: absolute;\n    height: calc(10px - 4px);\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n  }\n"])), function (props) { return (props.fillColor ? props.fillColor : 'white'); });
var Input = styled.input(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  opacity: 0;\n  z-index: 2;\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  margin: 0;\n  cursor: pointer;\n\n  &:focus {\n    outline: none;\n  }\n\n  &:checked {\n    & ~ ", " {\n      width: calc(100% - 2px);\n      height: calc(100% - 2px);\n      transition: width 0.2s ease-out, height 0.2s ease-out;\n\n      &::before {\n        opacity: 1;\n        transition: opacity 1s ease;\n      }\n    }\n    & ~ ", " {\n      width: calc(100% - 12px);\n      height: calc(100% - 12px);\n      transition: width 0.2s ease-out, height 0.2s ease-out;\n\n      &::before {\n        opacity: 1;\n        transition: opacity 1s ease;\n      }\n    }\n  }\n"], ["\n  opacity: 0;\n  z-index: 2;\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  margin: 0;\n  cursor: pointer;\n\n  &:focus {\n    outline: none;\n  }\n\n  &:checked {\n    & ~ ", " {\n      width: calc(100% - 2px);\n      height: calc(100% - 2px);\n      transition: width 0.2s ease-out, height 0.2s ease-out;\n\n      &::before {\n        opacity: 1;\n        transition: opacity 1s ease;\n      }\n    }\n    & ~ ", " {\n      width: calc(100% - 12px);\n      height: calc(100% - 12px);\n      transition: width 0.2s ease-out, height 0.2s ease-out;\n\n      &::before {\n        opacity: 1;\n        transition: opacity 1s ease;\n      }\n    }\n  }\n"])), Fill, FillWhite);
export var RadioButton = function (_a) {
    var className = _a.className, title = _a.title, disabled = _a.disabled, subTitle = _a.subTitle, checked = _a.checked, _b = _a.onChange, onChange = _b === void 0 ? function () { } : _b, error = _a.error, size = _a.size, props = __rest(_a, ["className", "title", "disabled", "subTitle", "checked", "onChange", "error", "size"]);
    return (React.createElement(RadioButtonContainer, { disabled: disabled, onChange: function () { return onChange(); } },
        React.createElement(RadioButtonElement, { className: className },
            React.createElement(Root, { size: size !== null && size !== void 0 ? size : 20 },
                React.createElement(Input, __assign({ type: "radio", checked: checked, "aria-checked": checked }, props)),
                React.createElement(Fill, null),
                React.createElement(FillWhite, null))),
        React.createElement(RadioButtonLabel, null,
            title && React.createElement(StyledTitle, { style: { marginLeft: 8 } }, title),
            subTitle && (React.createElement(StyledTitle, { style: { marginLeft: 8 } }, subTitle)))));
};
export default RadioButton;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;

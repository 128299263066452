import React from 'react';
import { useUser } from 'context/UserContext';
import { OrderSummaryView } from './View';
export var OrderSummaryContainer = function (_a) {
    var _b;
    var orderId = _a.orderId;
    var account = useUser().account;
    var accountId = account === null || account === void 0 ? void 0 : account.id;
    var hasPastDue = false;
    var order = (_b = account === null || account === void 0 ? void 0 : account.orders) === null || _b === void 0 ? void 0 : _b.find(function (order) { return order.id === orderId; });
    return (React.createElement(OrderSummaryView, { orderId: orderId, error: undefined, loading: false, order: order, orderDisplay: order, refetch: function () { }, getInvoicePDF: function () { }, accountId: accountId, hasDue: hasPastDue }));
};
export default OrderSummaryContainer;

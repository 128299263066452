var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { Icon, Typography } from '../../designSystem';
import styled from 'styled-components';
export var PasswordRequirementsValidation = function (_a) {
    var minimum = _a.minimum, lowercase = _a.lowercase, uppercase = _a.uppercase, number = _a.number;
    return (React.createElement(RequirementsContainer, null,
        React.createElement(Typography, { variant: "headlineSmall", color: "alt" }, "Password Requirements"),
        React.createElement(RequirementItemsContainer, null,
            React.createElement(RequirementItem, null,
                minimum ? (React.createElement(Check, null,
                    React.createElement(CheckIcon, { name: "tick", size: 10 }))) : (React.createElement(Bullet, null)),
                React.createElement(Typography, { variant: "bodySmall" }, "8 character minimum")),
            React.createElement(RequirementItem, null,
                lowercase ? (React.createElement(Check, null,
                    React.createElement(CheckIcon, { name: "tick", size: 10 }))) : (React.createElement(Bullet, null)),
                React.createElement(Typography, { variant: "bodySmall" }, "At least 1 lowercase letter")),
            React.createElement(RequirementItem, null,
                uppercase ? (React.createElement(Check, null,
                    React.createElement(CheckIcon, { name: "tick", size: 10 }))) : (React.createElement(Bullet, null)),
                React.createElement(Typography, { variant: "bodySmall" }, "At least 1 uppercase letter")),
            React.createElement(RequirementItem, null,
                number ? (React.createElement(Check, null,
                    React.createElement(CheckIcon, { name: "tick", size: 10 }))) : (React.createElement(Bullet, null)),
                React.createElement(Typography, { variant: "bodySmall" }, "At least 1 number")))));
};
var RequirementsContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 32px;\n  @media only screen and (max-width: ", "px) {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    width: 100%;\n  }\n"], ["\n  margin-top: 32px;\n  @media only screen and (max-width: ", "px) {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    width: 100%;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var RequirementItemsContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: auto auto;\n  grid-row: auto;\n  grid-column-gap: 20px;\n  grid-row-gap: 20px;\n  margin-top: 16px;\n  margin-bottom: 32px;\n  @media only screen and (max-width: ", "px) {\n    grid-template-columns: 1fr;\n  }\n"], ["\n  display: grid;\n  grid-template-columns: auto auto;\n  grid-row: auto;\n  grid-column-gap: 20px;\n  grid-row-gap: 20px;\n  margin-top: 16px;\n  margin-bottom: 32px;\n  @media only screen and (max-width: ", "px) {\n    grid-template-columns: 1fr;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var RequirementItem = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n"])));
var Check = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: ", ";\n\n  margin-right: 8px;\n  width: 20px;\n  height: 20px;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: ", ";\n\n  margin-right: 8px;\n  width: 20px;\n  height: 20px;\n"])), function (props) { return props.theme.colors.border.success2; });
var CheckIcon = styled(Icon)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  height: 15px;\n"], ["\n  height: 15px;\n"])));
var Bullet = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 6px;\n  height: 6px;\n  border: 3px solid black;\n\n  margin-right: 12px;\n"], ["\n  width: 6px;\n  height: 6px;\n  border: 3px solid black;\n\n  margin-right: 12px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;

import React from 'react';
import { OffersView } from './View';
import { useUser } from 'context';
import { promotions } from 'context/mockData/data';
export var OffersContainer = function () {
    var _a, _b, _c;
    var account = useUser().account;
    var _d = (_a = account === null || account === void 0 ? void 0 : account.currentQuarterLoyalty) !== null && _a !== void 0 ? _a : {}, levels = _d.levels, pricedWith = _d.pricedWith;
    var currentLevel = levels === null || levels === void 0 ? void 0 : levels.find(function (l) { return l.name === (pricedWith === null || pricedWith === void 0 ? void 0 : pricedWith.level); });
    return (React.createElement(OffersView, { promotions: promotions || [], loading: false, currentLevel: currentLevel, vialsPurchased: {
            presentYearVialsPurchased: ((_b = account === null || account === void 0 ? void 0 : account.annualVialsPurchased) === null || _b === void 0 ? void 0 : _b.presentYearVialsPurchased) || 0,
            previousYearVialsPurchased: ((_c = account === null || account === void 0 ? void 0 : account.annualVialsPurchased) === null || _c === void 0 ? void 0 : _c.previousYearVialsPurchased) || 0,
        } }));
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { Typography } from 'designSystem';
export var PrivilegeCard = function (_a) {
    var header = _a.header, description = _a.description;
    return (React.createElement(CardContainer, null,
        React.createElement(Typography, { variant: "headlineSmall", display: "block" }, header),
        React.createElement(Typography, { variant: "footnote", color: "alt" }, description)));
};
var CardContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  background: ", ";\n  padding: 12px 24px;\n"], ["\n  ", ";\n  background: ", ";\n  padding: 12px 24px;\n"])), function (props) { return props.theme.borders.base; }, function (props) { return props.theme.colors.bg.list; });
var templateObject_1;

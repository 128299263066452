var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { Modal } from '../Modal';
import closeableBannerLoyalty from '../../../assets/closeable-banner-loyalty-1k.png';
import annualLoyaltyAchievedImg from '../../../assets/annual-loyalty-achieved-1k.png';
import { useModal, useUser } from 'context';
import { AnnualLoyaltyProgressBar } from 'components/AnnualLoyaltyProgressBar';
import { Typography, Button, Icon, LoadingBubbles } from 'designSystem';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router';
import query from './query.graphql';
import { useQuery } from '@apollo/client';
var AnnualCampaign = function () {
    var _a, _b, _c, _d, _e, _f;
    var account = useUser().account;
    var navigate = useNavigate();
    var closeModal = useModal().closeModal;
    var location = useLocation();
    var pathname = location.pathname;
    var _g = useQuery(query, {
        variables: {
            id: account === null || account === void 0 ? void 0 : account.id,
            input: {
                accountId: account === null || account === void 0 ? void 0 : account.id,
            },
        },
        skip: !(account === null || account === void 0 ? void 0 : account.id),
    }), data = _g.data, isLoading = _g.loading;
    if (isLoading) {
        return (React.createElement(Modal, { size: "large", scrollable: true },
            React.createElement(OuterContainer, null,
                React.createElement(LoadingBubbles, null))));
    }
    var vialsThreshold = 1000;
    var vialsPurchased = 0;
    var hasAchieved = false;
    var presentYearVialsPurchased = (_b = (_a = data === null || data === void 0 ? void 0 : data.accountById) === null || _a === void 0 ? void 0 : _a.annualVialsPurchased) === null || _b === void 0 ? void 0 : _b.presentYearVialsPurchased;
    var previousYearVialsPurchased = (_d = (_c = data === null || data === void 0 ? void 0 : data.accountById) === null || _c === void 0 ? void 0 : _c.annualVialsPurchased) === null || _d === void 0 ? void 0 : _d.previousYearVialsPurchased;
    var meta = (_f = (_e = data === null || data === void 0 ? void 0 : data.getAnnualCampaigns) === null || _e === void 0 ? void 0 : _e.annualCampaigns[0]) === null || _f === void 0 ? void 0 : _f.meta;
    if ((previousYearVialsPurchased > presentYearVialsPurchased &&
        previousYearVialsPurchased > vialsThreshold) ||
        presentYearVialsPurchased > vialsThreshold) {
        vialsPurchased = vialsThreshold;
        hasAchieved = true;
    }
    else {
        vialsPurchased = presentYearVialsPurchased;
    }
    var logoPath = hasAchieved
        ? annualLoyaltyAchievedImg
        : closeableBannerLoyalty;
    var typographyColor = hasAchieved ? 'alt' : 'base';
    var onOrderJeuveau = function (vials) {
        if (vials === void 0) { vials = 0; }
        navigate('/orders/buy?step=quantitySelection', {
            state: {
                autoContinue: !!vials,
                preFilledQuantity: vials || undefined,
            },
        });
    };
    var onReturnToDashboard = function () {
        if (pathname === '/home') {
            closeModal();
        }
        else {
            navigate('/home');
            closeModal();
        }
    };
    return (React.createElement(Modal, { size: "large", scrollable: true },
        React.createElement(OuterContainer, null,
            React.createElement(InnerRow, { style: { marginBottom: '50px' } },
                React.createElement(TwoThirdColumnContainer, null,
                    React.createElement(HeadingContainer, null,
                        React.createElement(Typography, { variant: "titleLarge" }, meta === null || meta === void 0 ? void 0 : meta.modalHeader)),
                    React.createElement(BodyContainer, null,
                        hasAchieved && (React.createElement(Typography, { variant: "body", color: "base" }, meta === null || meta === void 0 ? void 0 : meta.body)),
                        React.createElement(Typography, { color: typographyColor },
                            React.createElement(Typography, { color: typographyColor }, meta === null || meta === void 0 ? void 0 : meta.modalTitle),
                            React.createElement(UnorderedList, null, meta === null || meta === void 0 ? void 0 : meta.modalList.map(function (benefit, i) {
                                return React.createElement("li", { key: i }, benefit);
                            })),
                            !hasAchieved && (React.createElement(Typography, { variant: "body", color: "alt" }, meta === null || meta === void 0 ? void 0 : meta.body))),
                        React.createElement(Typography, { variant: "footnote", color: "alt5" }, meta === null || meta === void 0 ? void 0 : meta.terms))),
                React.createElement(OneThirdColumnContainer, null,
                    React.createElement(LogoImage, { src: logoPath, alt: "Campaign Logo" }))),
            !hasAchieved && (React.createElement(InnerRow, null,
                React.createElement(AnnualLoyaltyProgressBar, { vialsPurchased: vialsPurchased, vialsThreshold: vialsThreshold, counterText: meta === null || meta === void 0 ? void 0 : meta.counterText, counterSubText: meta === null || meta === void 0 ? void 0 : meta.counterSubText }))),
            React.createElement(ButtonInnerRow, null,
                React.createElement(TwoColumnContainer, null,
                    React.createElement(Button, { fullWidth: true, variant: "outlined", size: "xlarge", onClick: onReturnToDashboard }, "Return to Dashboard")),
                React.createElement(TwoColumnContainer, null,
                    React.createElement(CustomOrderButtonContainer, null,
                        React.createElement(Button, { fullWidth: true, variant: "primary", size: "xlarge", startIcon: React.createElement(Icon, { name: "orders", color: "#fff" }), onClick: onOrderJeuveau }, (meta === null || meta === void 0 ? void 0 : meta.cta) || 'Order')))))));
};
export default AnnualCampaign;
var OuterContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  overflow: auto;\n"], ["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  overflow: auto;\n"])));
var InnerRow = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n  gap: 32px;\n  @media screen and (max-width: ", "px) {\n    flex-direction: column-reverse;\n  }\n"], ["\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n  gap: 32px;\n  @media screen and (max-width: ", "px) {\n    flex-direction: column-reverse;\n  }\n"])), function (props) { return props.theme.breakpoints.md; });
var ButtonInnerRow = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n  gap: 12px;\n  margin-top: 32px;\n\n  @media screen and (max-width: ", "px) {\n    flex-direction: column-reverse;\n  }\n"], ["\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n  gap: 12px;\n  margin-top: 32px;\n\n  @media screen and (max-width: ", "px) {\n    flex-direction: column-reverse;\n  }\n"])), function (props) { return props.theme.breakpoints.md; });
var TwoThirdColumnContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  flex-basis: 60%;\n  @media screen and (max-width: ", "px) {\n    display: flex;\n    flex-direction: column;\n    flex-basis: 100%;\n    gap: 16px;\n  }\n"], ["\n  flex-basis: 60%;\n  @media screen and (max-width: ", "px) {\n    display: flex;\n    flex-direction: column;\n    flex-basis: 100%;\n    gap: 16px;\n  }\n"])), function (props) { return props.theme.breakpoints.md; });
var OneThirdColumnContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-basis: 40%;\n  @media screen and (max-width: ", "px) {\n    flex-basis: 100%;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-basis: 40%;\n  @media screen and (max-width: ", "px) {\n    flex-basis: 100%;\n  }\n"])), function (props) { return props.theme.breakpoints.md; });
var LogoImage = styled.img(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 95%;\n  @media screen and (max-width: ", "px) {\n    width: 50%;\n  }\n"], ["\n  width: 95%;\n  @media screen and (max-width: ", "px) {\n    width: 50%;\n  }\n"])), function (props) { return props.theme.breakpoints.md; });
var TwoColumnContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  flex-basis: 50%;\n"], ["\n  flex-basis: 50%;\n"])));
var HeadingContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  margin-bottom: 16px;\n  @media screen and (max-width: ", "px) {\n    text-align: center;\n  }\n"], ["\n  margin-bottom: 16px;\n  @media screen and (max-width: ", "px) {\n    text-align: center;\n  }\n"])), function (props) { return props.theme.breakpoints.md; });
var BodyContainer = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n"])));
var UnorderedList = styled.ul(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  margin: 0;\n"], ["\n  margin: 0;\n"])));
var CustomOrderButtonContainer = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  width: 100%;\n  margin-top: auto;\n"], ["\n  width: 100%;\n  margin-top: auto;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;

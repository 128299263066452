var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
function getWidth(span) {
    if (!span)
        return;
    var width = (+span / 12) * 100;
    return "width: ".concat(width, "%;");
}
export var Column = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-wrap: wrap;\n  margin-top: 12px;\n  flex: ", ";\n  float: ", ";\n\n  width: 100%;\n\n  ", ";\n  ", "\n  align-items: ", ";\n  justify-content: ", ";\n\n  ", "\n\n  ", "\n\n  ", "\n\n  ", "\n"], ["\n  display: flex;\n  flex-wrap: wrap;\n  margin-top: 12px;\n  flex: ", ";\n  float: ", ";\n\n  width: 100%;\n\n  ", ";\n  ", "\n  align-items: ", ";\n  justify-content: ", ";\n\n  ", "\n\n  ", "\n\n  ", "\n\n  ", "\n"])), function (_a) {
    var size = _a.size;
    return size;
}, function (_a) {
    var float = _a.float;
    return (float ? float : 'left');
}, function (_a) {
    var xs = _a.xs;
    return (xs ? getWidth(xs) : 'width: 100%');
}, function (_a) {
    var marginTop = _a.marginTop;
    return (marginTop ? 'margin-top: 12px;' : '');
}, function (props) { var _a; return (_a = props === null || props === void 0 ? void 0 : props.alignItems) !== null && _a !== void 0 ? _a : 'flex-start'; }, function (props) { var _a; return (_a = props === null || props === void 0 ? void 0 : props.justifyContent) !== null && _a !== void 0 ? _a : 'flex-start'; }, function (_a) {
    var xs = _a.xs;
    return xs && "".concat(getWidth(xs));
}, function (_a) {
    var sm = _a.sm;
    return sm &&
        "\n    @media only screen and (min-width: 768px) {\n      ".concat(getWidth(sm), "\n    }\n  ");
}, function (_a) {
    var md = _a.md;
    return md &&
        "\n    @media only screen and (min-width: 992px) {\n      ".concat(getWidth(md), "\n    }\n  ");
}, function (_a) {
    var lg = _a.lg;
    return lg &&
        "\n    @media only screen and (min-width: 1200px) {\n      ".concat(getWidth(lg), "\n    }\n  ");
});
export var Row = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: ", ";\n  align-items: ", ";\n  width: 100%;\n  &::after {\n    content: '';\n    clear: both;\n  }\n"], ["\n  display: flex;\n  flex-direction: ", ";\n  align-items: ", ";\n  width: 100%;\n  &::after {\n    content: '';\n    clear: both;\n  }\n"])), function (props) { var _a; return (_a = props === null || props === void 0 ? void 0 : props.flexDirection) !== null && _a !== void 0 ? _a : 'row'; }, function (props) { var _a; return (_a = props === null || props === void 0 ? void 0 : props.alignItems) !== null && _a !== void 0 ? _a : 'flex-start'; });
var templateObject_1, templateObject_2;

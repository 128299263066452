var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { Row } from '../Row/Row';
export var CardActionColumn = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-wrap: wrap;\n  width: 100%;\n  justify-content: space-between;\n  margin-top: 12px;\n"], ["\n  display: flex;\n  flex-wrap: wrap;\n  width: 100%;\n  justify-content: space-between;\n  margin-top: 12px;\n"])));
var CardAction = function (_a) {
    var children = _a.children;
    return (React.createElement(Row, null,
        React.createElement(CardActionColumn, null, children)));
};
export default CardAction;
var templateObject_1;

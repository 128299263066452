var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var _a;
import React from 'react';
import styled, { css } from 'styled-components';
import { cardStyles } from '../Dashboard/constants/CardStyles';
import { Button, Icon, PlaceholderLoading, Typography, } from 'designSystem';
import { useNavigate } from 'react-router';
import { PaymentStatus } from 'types/codegen/hooks';
import { currencyFormatter } from 'utils/currencyFormatter';
import { format } from 'date-fns';
import { InvoicesSettledTable } from '../../components/InvoicesSettledTable';
import { StatusChip } from '../../components/StatusChip';
import { renderPaymentName } from '../../constants';
import { useScreenSizing } from '../../../../hooks';
import { addMobileStyles } from '../../../../utils/addMobileStyles';
var StatusConfig = (_a = {},
    _a[PaymentStatus.Accepted] = {
        label: 'Payment Accepted',
        subLabel: 'Your payment has been charged.',
        typoColor: 'success',
        bgColor: 'success2',
    },
    _a[PaymentStatus.Pending] = {
        label: 'Payment Pending',
        subLabel: 'Your payment has been submitted and will be charged soon.',
        typoColor: 'alert',
        bgColor: 'alert',
    },
    _a[PaymentStatus.Declined] = {
        label: 'Payment Declined',
        subLabel: 'We were unable to successfully charge your payment method and this payment was declined.',
        typoColor: 'link',
        bgColor: 'error',
    },
    _a);
export var SinglePaymentView = function (_a) {
    var payment = _a.payment, loading = _a.loading;
    var navigate = useNavigate();
    var isMobile = useScreenSizing().isMobile;
    var _b = payment !== null && payment !== void 0 ? payment : {}, paymentId = _b.paymentId, status = _b.status, amount = _b.amount, date = _b.date, paidByUser = _b.paidByUser, paymentDetail = _b.paymentDetail, invoices = _b.invoices, invoicesApplied = _b.invoicesApplied;
    var onBack = function () { return navigate('/invoices/history'); };
    var renderContent = function () {
        if (loading) {
            return (React.createElement(LoadingContainer, null,
                React.createElement(PlaceholderLoading, { size: "large" })));
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(HeaderContainer, null,
                !isMobile && (React.createElement("div", null,
                    React.createElement(Button, { variant: "outlined", onClick: onBack, startIcon: React.createElement(Icon, { name: "chevron-left", color: "inherit" }) }, "Back to Invoice Payment History"))),
                React.createElement("div", null,
                    React.createElement(Typography, { align: "center", display: "block", mb: 4, color: isMobile ? 'alt5' : 'alt', variant: "sectionHeader" }, "PAYMENT SUMMARY"),
                    React.createElement(Typography, { align: "center", display: "block", variant: "titleLarge" }, paymentId)),
                !isMobile && React.createElement("div", null)),
            React.createElement(SummaryContainer, null,
                React.createElement(StatusContainer, { bgColor: StatusConfig[status].bgColor },
                    React.createElement(Typography, { display: "block", mb: 16, variant: "titleLarge", color: StatusConfig[status].typoColor }, StatusConfig[status].label),
                    React.createElement(Typography, { display: "block", color: "alt" }, StatusConfig[status].subLabel),
                    status === PaymentStatus.Declined && (React.createElement(Button, { variant: "primary", onClick: function () { } }, "Chat with us"))),
                React.createElement("div", null,
                    React.createElement(Typography, { display: "block", mb: 36, variant: "sectionHeader" }, "Payment Summary"),
                    React.createElement("div", null,
                        React.createElement(Typography, { variant: "headline", color: "alt" }, "Amount Paid"),
                        React.createElement(Typography, { variant: isMobile ? 'title' : 'titleLarge' }, currencyFormatter(amount, 2))),
                    React.createElement("div", null,
                        React.createElement(Typography, { variant: "headline", color: "alt" }, "Payment ID"),
                        React.createElement(Typography, { variant: "headline", color: "alt5" }, paymentId)),
                    React.createElement("div", null,
                        React.createElement(Typography, { variant: "headline", color: "alt" }, "Payment Info"),
                        React.createElement(Typography, { variant: "headline" },
                            "Payment made on ",
                            format(new Date(date), 'MM/dd/yyyy'),
                            paidByUser ? (React.createElement(React.Fragment, null,
                                "by ", paidByUser === null || paidByUser === void 0 ? void 0 :
                                paidByUser.firstName,
                                " ", paidByUser === null || paidByUser === void 0 ? void 0 :
                                paidByUser.lastName)) : null)),
                    React.createElement("div", null,
                        React.createElement(Typography, { variant: "headline", color: "alt" }, "Payment Method"),
                        React.createElement(Typography, { variant: "headline" }, renderPaymentName(paymentDetail))),
                    React.createElement("div", null,
                        React.createElement(Typography, { variant: "headline", color: "alt" }, isMobile ? (React.createElement(React.Fragment, null,
                            "Payment",
                            React.createElement("br", null),
                            "Status")) : ('Payment Status')),
                        React.createElement(StatusChip, { status: status }))),
                React.createElement(Divider, null),
                React.createElement("div", null,
                    React.createElement(InvoicesSettledTable, { invoices: invoices, invoicesApplied: invoicesApplied, status: status }))),
            isMobile && (React.createElement(ButtonContainer, null,
                React.createElement(Button, { fullWidth: true, size: "xlarge", onClick: onBack, variant: "outlined" }, "Back to Payment History")))));
    };
    return React.createElement(Container, null, renderContent());
};
var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  margin: 16px;\n  padding: 48px;\n  ", "\n"], ["\n  ", ";\n  margin: 16px;\n  padding: 48px;\n  ", "\n"])), cardStyles, addMobileStyles(css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    margin: 16px 0 0;\n    padding: 32px 0 32px;\n  "], ["\n    margin: 16px 0 0;\n    padding: 32px 0 32px;\n  "])))));
var LoadingContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  height: 100%;\n  width: 100%;\n  justify-content: center;\n  align-items: center;\n  & > div {\n    width: 100%;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  height: 100%;\n  width: 100%;\n  justify-content: center;\n  align-items: center;\n  & > div {\n    width: 100%;\n  }\n"])));
var HeaderContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: self-start;\n  justify-content: space-between;\n  gap: 21px;\n  margin-bottom: 32px;\n  & > * {\n    flex: 1;\n  }\n"], ["\n  display: flex;\n  align-items: self-start;\n  justify-content: space-between;\n  gap: 21px;\n  margin-bottom: 32px;\n  & > * {\n    flex: 1;\n  }\n"])));
var SummaryContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  border: ", ";\n  box-shadow: 0px -2px 4px rgba(236, 248, 246, 0.248416),\n    0px -20px 40px rgba(110, 145, 145, 0.04);\n  & > div:nth-child(2) {\n    padding: 64px 96px;\n    & > div {\n      display: flex;\n      align-items: center;\n      gap: 96px;\n      :not(:last-child) {\n        margin-bottom: 48px;\n      }\n      & > span:first-of-type {\n        width: 155px;\n      }\n    }\n  }\n  & > div:last-of-type {\n    margin: 0 96px;\n  }\n\n  ", "\n"], ["\n  border: ", ";\n  box-shadow: 0px -2px 4px rgba(236, 248, 246, 0.248416),\n    0px -20px 40px rgba(110, 145, 145, 0.04);\n  & > div:nth-child(2) {\n    padding: 64px 96px;\n    & > div {\n      display: flex;\n      align-items: center;\n      gap: 96px;\n      :not(:last-child) {\n        margin-bottom: 48px;\n      }\n      & > span:first-of-type {\n        width: 155px;\n      }\n    }\n  }\n  & > div:last-of-type {\n    margin: 0 96px;\n  }\n\n  ", "\n"])), function (props) { return props.theme.borders.base; }, addMobileStyles(css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    & > div:nth-child(2) {\n      padding: 24px 32px 0;\n      & > div {\n        gap: 8px;\n        & > span:first-of-type {\n          width: 120px;\n        }\n        &:not(:last-child) > span:last-of-type {\n          width: min-content;\n          flex: 1;\n        }\n      }\n    }\n    & > div:last-of-type {\n      margin: 0 32px;\n    }\n  "], ["\n    & > div:nth-child(2) {\n      padding: 24px 32px 0;\n      & > div {\n        gap: 8px;\n        & > span:first-of-type {\n          width: 120px;\n        }\n        &:not(:last-child) > span:last-of-type {\n          width: min-content;\n          flex: 1;\n        }\n      }\n    }\n    & > div:last-of-type {\n      margin: 0 32px;\n    }\n  "])))));
var StatusContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  padding: 32px 48px;\n  background: ", ";\n  & > button {\n    margin-top: 24px;\n  }\n"], ["\n  padding: 32px 48px;\n  background: ", ";\n  & > button {\n    margin-top: 24px;\n  }\n"])), function (props) { return props.theme.colors.bg[props.bgColor]; });
var Divider = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  border: 2px solid ", ";\n  box-sizing: border-box;\n  margin: 0 96px 48px;\n  ", "\n"], ["\n  border: 2px solid ", ";\n  box-sizing: border-box;\n  margin: 0 96px 48px;\n  ", "\n"])), function (props) { return props.theme.colors.white.white500; }, addMobileStyles(css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    margin: 32px;\n  "], ["\n    margin: 32px;\n  "])))));
var ButtonContainer = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  margin: 32px 32px 0;\n"], ["\n  margin: 32px 32px 0;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
var getColor = function (color) {
    if (color === void 0) { color = 'base'; }
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    color: ", ";\n  "], ["\n    color: ", ";\n  "])), function (props) { var _a; return (_a = props.theme.colors.text[color]) !== null && _a !== void 0 ? _a : color; });
};
export var getVariant = function (variant) {
    if (variant === void 0) { variant = 'body'; }
    switch (variant) {
        case 'displayExtraLarge':
            return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        font-size: ", "px;\n        font-weight: ", ";\n        line-height: 72px;\n      "], ["\n        font-size: ", "px;\n        font-weight: ", ";\n        line-height: 72px;\n      "])), function (props) { return props.theme.typography.size.l3; }, function (props) { return props.theme.typography.weight.medium; });
        case 'displayLarge':
            return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n        font-size: ", "px;\n        font-weight: ", ";\n        line-height: 48px;\n      "], ["\n        font-size: ", "px;\n        font-weight: ", ";\n        line-height: 48px;\n      "])), function (props) { return props.theme.typography.size.l2; }, function (props) { return props.theme.typography.weight.medium; });
        case 'display':
            return css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n        font-size: ", "px;\n        font-weight: ", ";\n        line-height: 40px;\n      "], ["\n        font-size: ", "px;\n        font-weight: ", ";\n        line-height: 40px;\n      "])), function (props) { return props.theme.typography.size.l1; }, function (props) { return props.theme.typography.weight.medium; });
        case 'titleLarge':
            return css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n        font-size: ", "px;\n        font-weight: ", ";\n        line-height: 32px;\n      "], ["\n        font-size: ", "px;\n        font-weight: ", ";\n        line-height: 32px;\n      "])), function (props) { return props.theme.typography.size.m3; }, function (props) { return props.theme.typography.weight.medium; });
        case 'title':
            return css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n        font-size: ", "px;\n        font-weight: ", ";\n        line-height: 24px;\n      "], ["\n        font-size: ", "px;\n        font-weight: ", ";\n        line-height: 24px;\n      "])), function (props) { return props.theme.typography.size.m2; }, function (props) { return props.theme.typography.weight.medium; });
        case 'subtitleLarge':
            return css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n        font-size: ", "px;\n        font-weight: ", ";\n        line-height: 32px;\n      "], ["\n        font-size: ", "px;\n        font-weight: ", ";\n        line-height: 32px;\n      "])), function (props) { return props.theme.typography.size.m3; }, function (props) { return props.theme.typography.weight.regular; });
        case 'subtitle':
            return css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n        font-size: ", "px;\n        font-weight: ", ";\n        line-height: 24px;\n      "], ["\n        font-size: ", "px;\n        font-weight: ", ";\n        line-height: 24px;\n      "])), function (props) { return props.theme.typography.size.m2; }, function (props) { return props.theme.typography.weight.regular; });
        case 'sectionHeader':
            return css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n        font-size: ", "px;\n        font-weight: ", ";\n        text-transform: uppercase;\n        line-height: 24px;\n      "], ["\n        font-size: ", "px;\n        font-weight: ", ";\n        text-transform: uppercase;\n        line-height: 24px;\n      "])), function (props) { return props.theme.typography.size.s3; }, function (props) { return props.theme.typography.weight.semibold; });
        case 'headline':
            return css(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n        font-size: ", "px;\n        font-weight: ", ";\n        line-height: 24px;\n      "], ["\n        font-size: ", "px;\n        font-weight: ", ";\n        line-height: 24px;\n      "])), function (props) { return props.theme.typography.size.m1; }, function (props) { return props.theme.typography.weight.medium; });
        case 'headlineSmall':
            return css(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n        font-size: ", "px;\n        font-weight: ", ";\n        line-height: 24px;\n      "], ["\n        font-size: ", "px;\n        font-weight: ", ";\n        line-height: 24px;\n      "])), function (props) { return props.theme.typography.size.s3; }, function (props) { return props.theme.typography.weight.medium; });
        case 'bodySmall':
            return css(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n        font-size: ", "px;\n        font-weight: ", ";\n        line-height: 24px;\n      "], ["\n        font-size: ", "px;\n        font-weight: ", ";\n        line-height: 24px;\n      "])), function (props) { return props.theme.typography.size.s3; }, function (props) { return props.theme.typography.weight.regular; });
        case 'caption':
            return css(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n        font-size: ", "px;\n        font-weight: ", ";\n        line-height: 16px;\n      "], ["\n        font-size: ", "px;\n        font-weight: ", ";\n        line-height: 16px;\n      "])), function (props) { return props.theme.typography.size.s2; }, function (props) { return props.theme.typography.weight.semibold; });
        case 'footnote':
            return css(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n        font-size: ", "px;\n        font-weight: ", ";\n        line-height: 16px;\n      "], ["\n        font-size: ", "px;\n        font-weight: ", ";\n        line-height: 16px;\n      "])), function (props) { return props.theme.typography.size.s2; }, function (props) { return props.theme.typography.weight.medium; });
        case 'overline':
            return css(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n        font-size: ", "px;\n        font-weight: ", ";\n        text-transform: uppercase;\n        line-height: 16px;\n      "], ["\n        font-size: ", "px;\n        font-weight: ", ";\n        text-transform: uppercase;\n        line-height: 16px;\n      "])), function (props) { return props.theme.typography.size.s1; }, function (props) { return props.theme.typography.weight.semibold; });
        case 'small':
            return css(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n        font-size: ", "px;\n        font-weight: ", ";\n      "], ["\n        font-size: ", "px;\n        font-weight: ", ";\n      "])), function (props) { return props.theme.typography.size.s1; }, function (props) { return props.theme.typography.weight.semibold; });
        case 'buttonLabel':
            return css(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n        font-size: ", ";\n        font-weight: ", ";\n        line-height: 12px;\n      "], ["\n        font-size: ", ";\n        font-weight: ", ";\n        line-height: 12px;\n      "])), function (props) { return props.theme.typography.size.s1; }, function (props) { return props.theme.typography.weight.semibold; });
        case 'action':
            return css(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n        font-size: ", "px;\n        font-weight: ", ";\n        line-height: 24px;\n      "], ["\n        font-size: ", "px;\n        font-weight: ", ";\n        line-height: 24px;\n      "])), function (props) { return props.theme.typography.size.m1; }, function (props) { return props.theme.typography.weight.semibold; });
        case 'inherit':
            return css(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n        font-size: inherit;\n        font-weight: inherit;\n        line-height: inherit;\n      "], ["\n        font-size: inherit;\n        font-weight: inherit;\n        line-height: inherit;\n      "])));
        default:
            return css(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n        font-size: ", "px;\n        font-weight: ", ";\n        line-height: 24px;\n      "], ["\n        font-size: ", "px;\n        font-weight: ", ";\n        line-height: 24px;\n      "])), function (props) { return props.theme.typography.size.m1; }, function (props) { return props.theme.typography.weight.regular; });
    }
};
export var Typography = styled.span(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  ", "\n  ", "\n  text-align: ", ";\n  margin-bottom: ", "px;\n  margin-top: ", "px;\n  display: ", ";\n  ", ";\n  ", ";\n"], ["\n  ", "\n  ", "\n  text-align: ", ";\n  margin-bottom: ", "px;\n  margin-top: ", "px;\n  display: ", ";\n  ", ";\n  ", ";\n"])), function (props) { return getVariant(props.variant); }, function (props) { return getColor(props.color); }, function (props) { var _a; return (_a = props === null || props === void 0 ? void 0 : props.align) !== null && _a !== void 0 ? _a : 'left'; }, function (props) { var _a; return (_a = props.mb) !== null && _a !== void 0 ? _a : 0; }, function (props) { var _a; return (_a = props.mt) !== null && _a !== void 0 ? _a : 0; }, function (props) { var _a; return (_a = props.display) !== null && _a !== void 0 ? _a : 'inline'; }, function (props) { return props.colorOverride && "color: ".concat(props.colorOverride); }, function (props) { return props.bold && "font-weight: 700"; });
Typography.displayName = 'Typography';
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21;

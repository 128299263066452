var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Button, Typography } from 'designSystem';
import React from 'react';
import { Modal } from '../Modal';
import { useModal } from 'context';
import Remove from 'assets/remove.png';
import styled from 'styled-components';
import { useScreenSizing } from 'hooks';
export var RemovePromoCode = function (_a) {
    var onRemove = _a.onRemove;
    var closeModal = useModal().closeModal;
    var isMobile = useScreenSizing().isMobile;
    return (React.createElement(Modal, { size: "small", showClose: false },
        React.createElement(Image, { alt: "remove-promo", src: Remove }),
        React.createElement(Typography, { mt: 32, mb: 32, align: "center", variant: "display" }, "Remove Promo Code?"),
        React.createElement(Typography, { align: "center", mb: 48 }, "Are you sure you want to remove the promo code applied?"),
        React.createElement(ActionsContainer, null,
            React.createElement(Button, { fullWidth: isMobile, variant: "primary", onClick: function () {
                    onRemove();
                    closeModal();
                } }, "Yes, remove!"),
            React.createElement(Button, { fullWidth: isMobile, variant: "tertiary", onClick: function () {
                    closeModal();
                } }, "No, keep the promo code"))));
};
var ActionsContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row-reverse;\n  align-items: center;\n  justify-content: flex-start;\n  width: 100%;\n  gap: 12px;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n  }\n"], ["\n  display: flex;\n  flex-direction: row-reverse;\n  align-items: center;\n  justify-content: flex-start;\n  width: 100%;\n  gap: 12px;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var Image = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  max-width: 300px;\n  height: auto;\n"], ["\n  width: 100%;\n  max-width: 300px;\n  height: auto;\n"])));
var templateObject_1, templateObject_2;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useUser } from 'context/UserContext';
import { useNavigate } from 'react-router';
import { Button, Dropdown } from 'designSystem';
import { PlaceholderLoading } from 'designSystem/components/PlaceholderLoading';
import WorkingGraphic from 'assets/facility-selection-graphic.svg';
import { AddressItem } from 'components/AddressItem';
import { useLocation } from 'react-router';
export var FacilitySelectionView = function (_a) {
    var children = _a.children, errors = _a.errors, facilities = _a.facilities, isLoading = _a.isLoading, setTabsDisabled = _a.setTabsDisabled, showFacilitySelect = _a.showFacilitySelect, setShowFacilitySelect = _a.setShowFacilitySelect;
    var _b = useUser(), facility = _b.facility, loaded = _b.loaded, switchFacility = _b.switchFacility;
    var _c = __read(useState(facility), 2), selectedFacility = _c[0], setSelectedFacility = _c[1];
    var _d = __read(useState(false), 2), isSwitchState = _d[0], setIsSwitchState = _d[1];
    var location = useLocation();
    var navigate = useNavigate();
    useEffect(function () {
        if ((facilities === null || facilities === void 0 ? void 0 : facilities.length) === 1) {
            switchFacility(facilities[0]);
            setShowFacilitySelect(false);
            setTabsDisabled(false);
        }
        if (!facility && loaded) {
            setIsSwitchState(false);
            setTabsDisabled(true);
            setShowFacilitySelect(true);
        }
        if (facility && !showFacilitySelect) {
            setIsSwitchState(true);
            setShowFacilitySelect(false);
            if (location.pathname === '/rewards') {
                navigate('/rewards/dashboard', {
                    replace: true,
                });
            }
            setTabsDisabled(false);
        }
    }, [facilities, facility, loaded, showFacilitySelect]);
    var showFacility = (selectedFacility === null || selectedFacility === void 0 ? void 0 : selectedFacility.id) ? selectedFacility : facility;
    var facilityOptions = useMemo(function () {
        if (!(facilities === null || facilities === void 0 ? void 0 : facilities.length)) {
            return [];
        }
        return facilities.map(function (facility) {
            return {
                value: facility === null || facility === void 0 ? void 0 : facility.id,
                label: facility === null || facility === void 0 ? void 0 : facility.name,
            };
        });
    }, [facilities]);
    var handleFacilityChange = function (value) {
        var chosenFacility = facilities.find(function (facility) { return (facility === null || facility === void 0 ? void 0 : facility.id) === (value === null || value === void 0 ? void 0 : value.value); }) || null;
        setSelectedFacility(chosenFacility);
    };
    if (showFacilitySelect) {
        return (React.createElement(OuterContainer, null, isLoading || errors ? (React.createElement(PlaceholderLoading, { size: "extra-small" })) : (React.createElement(ContentContainer, null,
            React.createElement(RightContainer, null,
                React.createElement(HeaderText, null,
                    isSwitchState ? 'Switch' : 'Choose a',
                    " Facility"),
                React.createElement(Subtext, null, "Select the facility where you're working."),
                React.createElement(Dropdown, { selected: facilityOptions.find(function (f) { return f.value === (selectedFacility === null || selectedFacility === void 0 ? void 0 : selectedFacility.id); }), label: "Facility", placeholder: "Facility Name", options: facilityOptions, onSelect: function (value) { return handleFacilityChange(value); }, "data-automation-id": "rewards-facility", searchable: true }),
                showFacility && (React.createElement(React.Fragment, null,
                    React.createElement(AddressItemLabel, null, "Selected Facility"),
                    React.createElement(AddressItem, { selectable: false, facility: showFacility }))),
                React.createElement(ButtonContainer, null,
                    isSwitchState && (React.createElement(CancelButton, { variant: "outlined", onClick: function () { return setShowFacilitySelect(false); }, "data-automation-id": "rewards-facility-selection-close" }, "Cancel")),
                    React.createElement(SelectButton, { onClick: function () {
                            switchFacility(selectedFacility);
                            setShowFacilitySelect(false);
                            setTabsDisabled(false);
                        }, disabled: !(selectedFacility === null || selectedFacility === void 0 ? void 0 : selectedFacility.id) ||
                            (selectedFacility === null || selectedFacility === void 0 ? void 0 : selectedFacility.id) === (facility === null || facility === void 0 ? void 0 : facility.id), "data-automation-id": "rewards-facility-selection-state-btn" },
                        isSwitchState ? 'Switch' : 'Select',
                        " Facility"))),
            React.createElement(LeftContainer, null,
                React.createElement(ImageContainer, null,
                    React.createElement(Image, { src: WorkingGraphic })))))));
    }
    return React.createElement(OutletContainer, null, children);
};
var SelectButton = styled(Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  @media screen and (max-width: ", "px) {\n    width: 100%;\n    margin-left: 0;\n  }\n"], ["\n  @media screen and (max-width: ", "px) {\n    width: 100%;\n    margin-left: 0;\n  }\n"])), function (props) { return props.theme.breakpoints.md; });
var OutletContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background: transparent;\n  @media screen and (max-width: ", "px) {\n    display: flex;\n    flex: 1;\n  }\n"], ["\n  background: transparent;\n  @media screen and (max-width: ", "px) {\n    display: flex;\n    flex: 1;\n  }\n"])), function (props) { return props.theme.breakpoints.md; });
var CancelButton = styled(Button)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-right: 12px;\n  @media screen and (max-width: ", "px) {\n    margin-right: 0;\n  }\n"], ["\n  margin-right: 12px;\n  @media screen and (max-width: ", "px) {\n    margin-right: 0;\n  }\n"])), function (props) { return props.theme.breakpoints.md; });
var OuterContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background-color: ", ";\n\n  flex: 1;\n  margin: 16px 16px 0 16px;\n  elevation: ", ";\n  padding: 64px;\n  min-height: 50vh;\n  @media screen and (max-width: ", "px) {\n    flex-direction: column;\n    margin: 32px 0 0;\n    padding: 32px;\n    box-shadow: 0px -2px 4px rgba(207, 208, 208, 0.248416),\n      0px -20px 40px rgba(144, 155, 165, 0.11);\n    height: 100%;\n  }\n"], ["\n  background-color: ", ";\n\n  flex: 1;\n  margin: 16px 16px 0 16px;\n  elevation: ", ";\n  padding: 64px;\n  min-height: 50vh;\n  @media screen and (max-width: ", "px) {\n    flex-direction: column;\n    margin: 32px 0 0;\n    padding: 32px;\n    box-shadow: 0px -2px 4px rgba(207, 208, 208, 0.248416),\n      0px -20px 40px rgba(144, 155, 165, 0.11);\n    height: 100%;\n  }\n"])), function (props) { return props.theme.colors.basic.white; }, function (props) { return props.theme.elevation.base.elevation1; }, function (props) { return props.theme.breakpoints.md; });
var ContentContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  border: 1px solid ", ";\n\n  padding: 64px;\n  @media screen and (max-width: ", "px) {\n    flex-direction: column-reverse;\n    border: none;\n    padding: 0;\n  }\n"], ["\n  display: flex;\n  flex-direction: row;\n  border: 1px solid ", ";\n\n  padding: 64px;\n  @media screen and (max-width: ", "px) {\n    flex-direction: column-reverse;\n    border: none;\n    padding: 0;\n  }\n"])), function (props) { return props.theme.colors.border.base; }, function (props) { return props.theme.breakpoints.md; });
var LeftContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex: 1.5;\n"], ["\n  display: flex;\n  flex: 1.5;\n"])));
var HeaderText = styled.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-size: 1.5rem;\n  margin-bottom: 16px;\n"], ["\n  font-size: 1.5rem;\n  margin-bottom: 16px;\n"])));
var Subtext = styled.span(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-size: 1rem;\n  color: ", ";\n  margin-bottom: 50px;\n"], ["\n  font-size: 1rem;\n  color: ", ";\n  margin-bottom: 50px;\n"])), function (props) { return props.theme.colors.text.alt; });
var AddressItemLabel = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  margin: 25px 0 0 0;\n"], ["\n  margin: 25px 0 0 0;\n"])));
var ImageContainer = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  justify-content: center;\n  align-items: center;\n"], ["\n  display: flex;\n  flex: 1;\n  justify-content: center;\n  align-items: center;\n"])));
var Image = styled.img(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  width: 50%;\n"], ["\n  width: 50%;\n"])));
var RightContainer = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  display: flex;\n  flex: 2;\n  flex-direction: column;\n  @media screen and (max-width: ", "px) {\n    margin-right: 0;\n  }\n"], ["\n  display: flex;\n  flex: 2;\n  flex-direction: column;\n  @media screen and (max-width: ", "px) {\n    margin-right: 0;\n  }\n"])), function (props) { return props.theme.breakpoints.md; });
var ButtonContainer = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-start;\n  margin-top: 48px;\n  @media screen and (max-width: ", "px) {\n    justify-content: center;\n    flex-direction: column-reverse;\n    & > button {\n      padding: 16px 12px;\n      margin: 0 0 16px 0;\n    }\n  }\n"], ["\n  display: flex;\n  justify-content: flex-start;\n  margin-top: 48px;\n  @media screen and (max-width: ", "px) {\n    justify-content: center;\n    flex-direction: column-reverse;\n    & > button {\n      padding: 16px 12px;\n      margin: 0 0 16px 0;\n    }\n  }\n"])), function (props) { return props.theme.breakpoints.md; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { Icon, Pagination, PlaceholderLoading, Table, TableBody, TableColumn, TableFooter, TableHead, TableHeadColumn, TableRow, Typography, } from 'designSystem';
import { ActivityType } from 'types/codegen/hooks';
import { format } from 'date-fns';
import { pluralize } from 'utils/pluralize';
export var renderActivityType = function (activity) {
    if ((activity === null || activity === void 0 ? void 0 : activity.activityType) === ActivityType.CreditReceived) {
        return "".concat(activity === null || activity === void 0 ? void 0 : activity.consumerFirstName, " ").concat(activity === null || activity === void 0 ? void 0 : activity.consumerLastName);
    }
    if ((activity === null || activity === void 0 ? void 0 : activity.activityType) === ActivityType.ManualCredit) {
        return 'Manual Credits';
    }
    if ((activity === null || activity === void 0 ? void 0 : activity.activityType) === (ActivityType === null || ActivityType === void 0 ? void 0 : ActivityType.VialRedeemed)) {
        return "".concat(activity === null || activity === void 0 ? void 0 : activity.redeemedForQuantity, " vial").concat(pluralize(activity === null || activity === void 0 ? void 0 : activity.redeemedForQuantity), " redeemed");
    }
    return activity === null || activity === void 0 ? void 0 : activity.activityType;
};
export var renderCredits = function (activity) {
    if ((activity === null || activity === void 0 ? void 0 : activity.activityType) === ActivityType.VialRedeemed ||
        (activity === null || activity === void 0 ? void 0 : activity.activityType) === ActivityType.EvolusAdjustment ||
        (activity === null || activity === void 0 ? void 0 : activity.activityType) === ActivityType.RewardVialExpiration) {
        return (React.createElement(Typography, { color: "link", variant: "headlineSmall" },
            "-", activity === null || activity === void 0 ? void 0 :
            activity.redeemedForPoints,
            " Credit",
            pluralize(activity === null || activity === void 0 ? void 0 : activity.redeemedForPoints)));
    }
    if ((activity === null || activity === void 0 ? void 0 : activity.activityType) === ActivityType.CreditReceived ||
        (activity === null || activity === void 0 ? void 0 : activity.activityType) === ActivityType.ManualCredit) {
        return (React.createElement(Typography, { color: "success", variant: "headlineSmall" },
            "+", activity === null || activity === void 0 ? void 0 :
            activity.creditsEarned,
            " Credit",
            pluralize(activity === null || activity === void 0 ? void 0 : activity.creditsEarned)));
    }
    return activity === null || activity === void 0 ? void 0 : activity.rewardsGiven;
};
var renderTableIcon = function (activity) {
    if ((activity === null || activity === void 0 ? void 0 : activity.activityType) === ActivityType.CreditReceived ||
        (activity === null || activity === void 0 ? void 0 : activity.activityType) === ActivityType.ManualCredit) {
        return 'user';
    }
    return 'evolus-rewards';
};
var RewardHistoryTable = function (_a) {
    var transactionHistory = _a.transactionHistory, page = _a.page, recordCount = _a.recordCount, setPage = _a.setPage, isLoading = _a.isLoading, error = _a.error, onSelectItem = _a.onSelectItem;
    if (isLoading || error) {
        return React.createElement(PlaceholderLoading, { size: "extra-small" });
    }
    return (React.createElement(Table, { fullWidth: true, style: { backgroundColor: 'white' } },
        React.createElement(TableHead, null,
            React.createElement(TableRow, null,
                React.createElement(TableHeadColumn, null,
                    React.createElement(Typography, null, "Facility")),
                React.createElement(TableHeadColumn, null,
                    React.createElement(Typography, null, "Activity")),
                React.createElement(TableHeadColumn, null,
                    React.createElement(Typography, null, "Credits")))),
        React.createElement(TableBody, { stripped: true, selectable: true },
            (transactionHistory === null || transactionHistory === void 0 ? void 0 : transactionHistory.length) === 0 && (React.createElement("tr", null,
                React.createElement(TableColumn, { colSpan: 3 },
                    React.createElement(Typography, { display: "block", variant: "headline", align: "center" }, "No results found within date range.")))), transactionHistory === null || transactionHistory === void 0 ? void 0 :
            transactionHistory.map(function (activity, index) {
                return (React.createElement(TableRow, { key: index, onClick: function () { return onSelectItem(activity); } },
                    React.createElement(NameContainer, null,
                        React.createElement(Icon, { name: renderTableIcon(activity) }),
                        React.createElement(Typography, { variant: "headline" }, activity === null || activity === void 0 ? void 0 : activity.facilityName)),
                    React.createElement(TableColumn, null,
                        React.createElement(ActivityTypeContainer, null,
                            React.createElement(Typography, { variant: "headlineSmall" }, renderActivityType(activity)),
                            React.createElement(Typography, { variant: "footnote", color: "alt" }, format(new Date(activity === null || activity === void 0 ? void 0 : activity.occurredOn), 'MMM d, yyyy')))),
                    React.createElement(TableColumn, null,
                        React.createElement(Typography, null, renderCredits(activity)))));
            })),
        React.createElement(TableFooter, null,
            React.createElement(TableRow, null,
                React.createElement(TableColumn, { colSpan: 6 },
                    React.createElement(Pagination, { page: page || 0, limit: 8, total: recordCount || 0, onPageChange: function (page) {
                            setPage(page);
                        }, buttonNavigation: true, size: "small", justifyContent: "end" }))))));
};
export default RewardHistoryTable;
var ActivityTypeContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
var NameContainer = styled(TableColumn)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 16px;\n"], ["\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 16px;\n"])));
var templateObject_1, templateObject_2;
